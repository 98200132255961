import { Container, Typography, Box, Grid, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


import Writemessage3 from '../../../Component/Writemessage3';

import Blogback from '../../../Component/Blogback'
import { Helmet } from 'react-helmet';

export default function Laplace_demon() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    return (
        <>


            <Helmet>
                <meta charset="UTF-8" />
                <h1>Laplace’s Demon and Limits to Central Banks’ Ability to Stabilize Economy </h1>
                <title>Laplace’s Demon and Limits to Central Banks’ Ability to Stabilize Economy</title>
                <meta name="description" content="Explore the limitations faced by central banks in stabilizing the economy during crises. Gain insights into the actions taken by major central banks." />
                <meta name="keywords" content="Laplace's Demon, central banks, economy, market stabilization, COVID-19 pandemic, junk bonds, sovereign credits, V-shaped recovery, perfect knowledge, analytical tools, limitations, social distancing, real economy, leveraged debt, market players, crisis, challenges, economic system." />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/blog/laplace-demon" />

                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            <Box id="blog-page" className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Typography variant='h3' className='subhead skblue al_center'>
                                Blog
                            </Typography>
                            <Typography variant='h1' className='blue mainhead al_center mb0'>
                                Laplace’s Demon and Limits to Central Banks’ Ability to Stabilize Economy
                            </Typography>
                            <Typography variant='h4' className='blue al_center'>
                                April 11, 2020
                            </Typography>
                        </Grid>
                    </Grid>

                    <Box className='whitebx'>



                        <Typography variant='h6' className='black jst'>In last three weeks, the major central banks in the world have gone in overdrive to stabilize the markets. Among other things, they are now buying junk bonds, highly rated bonds, MBS, sub-sovereign credits, apart from providing unlimited credit to their sovereigns, and refinancing SME loans. Markets are taking solace from this and are hoping for a V shaped recovery post Covid19 pandemic curve flattening.</Typography>

                        <Typography variant='h6' className='black jst'>In effect, markets are assuming that central banks have powers of Laplace’s Demon. What is Laplace’s Demon? Laplace, in his Philosophical Essay on Probabilities, in 1814, wrote the following:</Typography>

                        <Typography variant='h6' className='blue jst'>“Given for one instant an intelligence which could comprehend all the forces by which nature is animated and the respective situation of the beings who compose it, an intelligence sufficiently vast to submit these data to analysis, it would embrace in the same formula the movements of the greatest bodies of the universe and those of the lightest atom; for it, nothing would be uncertain and the future, as the past, would be present to its eyes.”</Typography>


                        <Typography variant='h6' className='black jst'>In other words, if central banks have perfect knowledge at minutest level and have access to all the analytical tools, central banks can know everything with certainty. However, given that they are reacting to events and are not proactive, shows that they do not have sufficient information at this stage.</Typography>

                        <Typography variant='h6' className='black jst'> Another analogy can be if a person is carrying on her head nine claypots of water stacked one above another, and if she stumbles, attempt to stabilize one or two claypots will not bring back stability to that stack of claypots. And if she keeps on stumbling, no amount of efforts to stabilize even all the nine claypots will keep the stack stable. (An excellent video from 1959 Bollywood movie Navrang on nine claypots dance can be found here <a href='https://bit.ly/2yMwmJC' target='_blank' rel="noopener noreferrer"> https://bit.ly/2yMwmJC</a>)</Typography>


                        <Typography variant='h6' className='black jst'>Right now, due to social distancing, the real economy is fumbling (the real economy can be imagined as dancer in the above song), and the real economy is carrying on its head a huge stack of leveraged debt positions of market players (the claypots are representing stacks of leverage). Given that fumble of real economy globally is going last for a few quarters, the attempts to stabilize the claypots, in the light of insufficient information with the entities entrusted with stabilizing the markets will not succeed.</Typography>

                        <Typography variant='h6' className='black jst'>Forewarned is forearmed. </Typography>

                        {/* <Box mt={5}>
                            <Subscribe />
                        </Box> */}

                    </Box>


                    <Box className='pages'>
                        <Writemessage3 pageVisited="Laplaces Demon and Limits to Central Banks Ability to Stabilize Economy" />
                    </Box>



                    <Blogback />

                </Container>
            </Box>

        </>
    );
}
