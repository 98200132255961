import React from "react";
import { Typography, Box } from "@mui/material";
import { Link } from "react-router-dom";

function Press_Release_design(props) {
    const { type, link,imgsrc, heading, date, subheading } = props;

    if (type === "external") {
        return (
            <a href={link} target="_blank" rel="noopener noreferrer">
                <Box className="reportm_bg">
                     <Box
                        className="reportimg_bg"
                        style={{
                            backgroundImage: `url(${imgsrc})`,
                        }}
                    ></Box>
                    <Box className="reportbox" mt={4} mb={2}>
                        <Typography className="reporttitle two" title={heading}>{heading}</Typography>
                    </Box>
                    <Typography variant='h6' className='skblue'>
                        {date}
                    </Typography>
                    <Box className="line"></Box>
                    <Box mt={2}>
                        <Typography variant='h6' className='black jst two' title={subheading}>{subheading}</Typography>
                    </Box>
                </Box>
            </a>
        );
    }

    // For internal links (using react-router-dom)
    return (
        <Link to={link}>
            <Box className="reportm_bg">
                 <Box
                        className="reportimg_bg"
                        style={{
                            backgroundImage: `url(${imgsrc})`,
                        }}
                    ></Box>
                <Box className="reportbox" mt={4} mb={2}>
                    <Typography className="reporttitle two" title={heading}>{heading}</Typography>
                </Box>
                <Typography variant='h6' className='skblue'>
                    {date}
                </Typography>
                <Box className="line"></Box>
                <Box mt={2}>
                    <Typography variant='h6' className='black jst two' title={subheading}>{subheading}</Typography>
                </Box>
            </Box>
        </Link>
    );
}

export default Press_Release_design;
