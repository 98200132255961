import React from "react";
import { Typography, Box} from "@mui/material";
import { Link } from "react-router-dom";


function ESG_roundtable_design(props) {

  return (
    <>
     
      <Link to={props.link}>
        <Box className="reportm_bg">
  

          <Box
            className="reportimg_bg"
            style={{
              backgroundImage: `url(${props.imgsrc})`,
            }}
          >

          </Box>


          <Box mt={4} mb={2}>
            <Typography className="reporttitle two" title={props.heading}>
              {props.heading}
            </Typography>
          </Box>
          <Box className="line"></Box>
        </Box>
      </Link>

    </>
  );
}

export default ESG_roundtable_design;