import { Container, Grid, Typography, Box } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Writemessage3 from '../../../../../../Component//Writemessage3';
import ESGBlogsBack from '../../../../../../Component/BackButtons/ESGBlogsBack';
import { Helmet } from 'react-helmet';
import EmployeeProfile from '../../../../../../Component/EmployeeProfile';
import AJ from '../../../../../../asset/images/team/ajay_jindal.webp'

export default function Article_8_declarations() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);


  return (
    <>

      <Helmet>
        <title>How to Avoid Repenting Your Article-8 Declarations</title>
        <meta name="description" content="Learn how to navigate Article 8 declarations effectively to avoid future regrets. Explore insights at Decimal Point Analytics." />
        <link rel="canonical" href="https://www.decimalpointanalytics.com/esg-insights/esg-blogs/how-to-avoid-repenting-your-article-8-declarations" />
      </Helmet>

      <Box className='section sec_bg' component='section'>

        <Container maxWidth='lg' className='bdr'>

          <Grid container spacing={3} alignItems="top">
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box className='al_center' mb={2}>
                <Typography variant='h3' className='subhead skblue al_center'>
                  ESG Insights
                </Typography>
                <Typography variant='h1' className='mainhead blue al_center'>
                  How to avoid repenting your Article-8 declarations
                </Typography>
              </Box>
            </Grid>
          </Grid>


          <Writemessage3 pageVisited="How to avoid repenting your Article-8 declarations" />



          <Box className='whitebx' mt={1}  >


            <Box pt={1}>
              <Typography variant='h6' className='black jst'>
                Overzealous approach to declarations towards pre-contractual disclosures (Regulation (EU) 2019 / 2088) can become hard to implement. A cautious, guarded approach is a much better idea.
              </Typography>
            </Box>

            <Box pt={1}>
              <Typography variant='h6' className='black jst'>
                Recently we came across an Asian fund struggling with trying to live up to the promises made to current and potential investors in its pre-contractual disclosures towards EU Regulation 2019 / 2088.
              </Typography>
            </Box>

            <Box pt={1}>
              <Typography variant='h6' className='black jst'>
                New to ESG, this fund went at Article 8 disclosures with the enthusiasm of a new convert. For the question: <strong> ‘What sustainability indicators are used to measure the attainment of each of the environmental or social characteristics promoted by this financial product?’  The fund had committed to a very specific approach.</strong>  <span className='blue'> It had committed to integrating ESG considerations into its investment decision-making process by using the ESG scores obtained from a specific third-party rating provider. It also then set a minimum threshold of rating below which it would not invest. </span>
              </Typography>
            </Box>


            {/*-------------------------------------------------------*/}


            <Box pt={1}>
              <Typography variant='h6' className='black jst'>
                Now contrast this with the responses given to the same question by European firms more seasoned to managing ESG policies and disclosures. A convertible fund by a large European asset manager commits to the following: <span className="blue"> Weighted average ESG rating better than that of the Investment Universe.  </span>
              </Typography>
            </Box>




            {/*-------------------------------------------------------*/}
            <Box pt={1}>
              <Typography variant='h6' className='black jst'> Note how nonspecific the text is. There is no mention of an evaluation framework or negative screening based on some minimum criteria. One can always include some of the worst polluters or other such extreme cases in the universe, to make its averages go down, and the portfolio look good by contrast.
              </Typography>
            </Box>

            <Box pt={1}>
              <Typography variant='h6' className='black jst'>Note how nonspecific the text is. There is no mention of an evaluation framework or negative screening based on some minimum criteria. One can always include some of the worst polluters or other such extreme cases in the universe, to make its averages go down, and the portfolio look good by contrast.
              </Typography>
            </Box>


            <Box pt={1}>
              <Typography variant='h6' className='black jst'> Another European fund has this to say to the above query:  <span className="blue"> With regard to sustainability indicators, the investment manager relies predominantly on quantitative and qualitative analyses by "Sustainalytics". The "ESG Risk Rating" from Sustainalytics determined from these analyses measures the extent to which the economic value of a company is endangered by ESG factors or the extent to which a company's ESG risks are not managed. </span>
              </Typography>
            </Box>

            {/*-------------------------------------------------------*/}


            <Box pt={1}>
              <Typography variant='h6' className='black jst'> While this fund declares usage of external ratings, it has kept no minimum cutoff below which it will not invest. It separately has also kept a leeway of investing in companies that may be at the bottom of the rating scale. Its exact language says: <span className="blue"> Up to 10% of the companies may be subsequently classified as "non-compliant" by "Sustainalytics". </span>  Since there is no cap on the weight of such companies in the portfolio, it is possible that the weight can exceed 10%.  </Typography>
            </Box>

            <Box pt={1}>
              <Typography variant='h6' className='black jst'> In other words, funds will be well served by being circumspect in the wordings they use in their declarations as part of SFDR compliance. The idea is not to encourage greenwashing, but if others are playing it safe, why commit more than needed. </Typography>
            </Box>


            {/*-------------------------------------------------------*/}





            {/*-------------------------------------------------------*/}
            <Box mt={1}>
              <EmployeeProfile imgSrc={AJ} name={" Mr. Ajay Jindal"} designation={"EVP,"} company={"Decimal Point Analytics Pvt. Ltd."} linkedinUrl={"https://www.linkedin.com/in/ajay-jindal-cfa-33aa961/"} />
            </Box>
            {/*-------------------------------------------------------*/}





          </Box>
          {/*-------------------------------------------------------*/}



          <Box className='graybbx' mt={1} >
            <Grid container spacing={1} alignItems="top" >
              <Grid item lg={12} md={12} sm={12} xs={12} >
                <Box className='al_left'>

                  <Typography variant='h6' className='black jst'>
                    <b>Tags:</b> SFDR, Article 8, EU Regulation 2019 / 2088
                  </Typography>

                </Box>
              </Grid>
            </Grid>
          </Box>



          <ESGBlogsBack />




        </Container>
      </Box>


    </>
  );
}

