import React from "react";
import Index from "../Pages/Index";
import { Redirect } from "react-router-dom";
import Articles from "../Pages/Insight/Articles";
import GHG_Emmission_Persifoni from "../Pages/Insight/Case_Studies/GHG_Emmission_Persifoni";
import SalesForce from "../Pages/Insight/Case_Studies/SalesForce";
import Fraud_detection from "../Pages/Insight/Case_Studies/Fraud_detection";
import Process_and_cost from "../Pages/Insight/Case_Studies/Process_and_cost";
import Automation_and_custom from "../Pages/Insight/Case_Studies/Automation_and_custom";
import Offering_solutions from "../Pages/Insight/Case_Studies/Offering_solutions";
import Nav_calculations from "../Pages/Insight/Case_Studies/Nav_calculations";
import Intelligent_doc_processing from "../Pages/Insight/Case_Studies/Intelligent_doc_processing";
import Data_managment_for_CRM from "../Pages/Insight/Case_Studies/Data_managment_for_CRM";
import Response_management_system from "../Pages/Insight/Case_Studies/Response_management_system";
import Portfolio_monitoring from "../Pages/Insight/Case_Studies/Portfolio_monitoring";
import Robotic_process_automation from "../Pages/Insight/Case_Studies/Robotic_process_automation";
import Customized_credit_platform from "../Pages/Insight/Case_Studies/Customized_credit_platform";
import Intelligent_dashboard from "../Pages/Insight/Case_Studies/Intelligent_dashboard";
import Tokenization_of_assets from "../Pages/Insight/Insightpages/Tokenization_of_assets";
import Regaining_inr_position from "../Pages/Insight/Insightpages/Regaining_inr_position";
import How_can_asset_managers from "../Pages/Insight/Insightpages/How_can_asset_managers";
import PE_funds from "../Pages/Insight/Insightpages/PE_funds";
import Challenges_in_ESG from "../Pages/Insight/Insightpages/Challenges_in_ESG";
import Solving_challenges from "../Pages/Insight/Insightpages/Solving_challenges";
import Data_visualization from "../Pages/Insight/Insightpages/Data_visualization";
import NLP_technique from "../Pages/Insight/Insightpages/NLP_technique";
import Roadmap_to_institutional from "../Pages/Insight/Insightpages/Roadmap_to_institutional";
import Way_forward_for_asset_management from "../Pages/Insight/Insightpages/Way_forward_for_asset_management";
import Disrupting_asset_management from "../Pages/Insight/Insightpages/Disrupting_asset_management";
import Data_analytics_in_asset from "../Pages/Insight/Insightpages/Data_analytics_in_asset";
import Role_of_advanced_analytics from "../Pages/Insight/Insightpages/Role_of_advanced_analytics";
import Interest_rates_burden from "../Pages/Insight/Insightpages/Interest_rates_burden";
import New_age_asset_managers from "../Pages/Insight/Insightpages/New_age_asset_managers";
import India_on_currency from "../Pages/Insight/Insightpages/India_on_currency";
import India_leverage from "../Pages/Insight/Insightpages/India_leverage";
import Rethinking_corporate_governance from "../Pages/Insight/Insightpages/Rethinking_corporate_governance";
import Robo_advisors from "../Pages/Insight/Insightpages/Robo_advisors";
import Indian_budget_17_18 from "../Pages/Insight/Insightpages/Indian_budget_17_18";
import Trumps_presidency from "../Pages/Insight/Insightpages/Trumps_presidency";
import Germany_and_upcoming_elections from "../Pages/Insight/Insightpages/Germany_and_upcoming_elections";
import Fork_wars from "../Pages/Insight/Insightpages/Fork_wars";
import Pursuit_of_alpha from "../Pages/Insight/Insightpages/Pursuit_of_alpha";
import Research_redux from "../Pages/Insight/Insightpages/Research_redux";
import Assessing_quality_of_research from "../Pages/Insight/Insightpages/Assessing_quality_of_research";
import BuildingProcessEfficiency from "../Pages/Insight/Blog/BuildingProcessEfficiency";
import The_great_skills from "../Pages/Insight/Blog/The_great_skills";
import A_cautionary_tale from "../Pages/Insight/Blog/A_cautionary_tale";
import Guiding_policy_making from "../Pages/Insight/Blog/Guiding_policy_making";
import Private_equity_global_pandamic from "../Pages/Insight/Blog/Private_equity_global_pandamic";
import Forging_ahead from "../Pages/Insight/Blog/Forging_ahead";
import Laplace_demon from "../Pages/Insight/Blog/Laplace_demon";
import Latrogenesis_of_social_distancing from "../Pages/Insight/Blog/Latrogenesis_of_social_distancing";
import Advances_in_ai from "../Pages/Insight/Blog/Advances_in_ai";
import Independant_research from "../Pages/Insight/Insightpages/Independant_research";
import Automated_data_warehouse from "../Pages/Insight/Case_Studies/Automated_data_warehouse";
import Beginning_of_ETF_shock from "../Pages/Insight/Blog/Beginning_of_ETF_shock";
import Extreme_funding_stress from "../Pages/Insight/Blog/Extreme_funding_stress";
import Covid_19 from "../Pages/Insight/Blog/Covid_19";
import Research_operations from "../Pages/Insight/Blog/Research_operations";
import From_us_to_yes_bank from "../Pages/Insight/Blog/From_us_to_yes_bank";
import Do_we_really_need_trade_wars from "../Pages/Insight/Blog/Do_we_really_need_trade_wars";
import Is_demonstration_respo from "../Pages/Insight/Blog/Is_demonstration_respo";
import Analyzing_GDP_nos from "../Pages/Insight/Blog/Analyzing_GDP_nos";
import Indias_fiscal_contraction from "../Pages/Insight/Blog/Indias_fiscal_contraction";
import Does_RBI_need_to_revise from "../Pages/Insight/Blog/Does_RBI_need_to_revise";
import India_deflation from "../Pages/Insight/Blog/India_deflation";
import Why_will_AI_emerge from "../Pages/Insight/Blog/Why_will_AI_emerge";
import India_heading_toward_deflation from "../Pages/Insight/Blog/India_heading_toward_deflation";
import Excessive_monetory from "../Pages/Insight/Blog/Excessive_monetory";
import Emergence_of_singularity from "../Pages/Insight/Blog/Emergence_of_singularity";
import The_fallacy_of_paradox from "../Pages/Insight/Blog/The_fallacy_of_paradox";
import AI_heuristics_and_bio from "../Pages/Insight/Blog/AI_heuristics_and_bio";
import Incentive_structure_for_AI from "../Pages/Insight/Blog/Incentive_structure_for_AI";
import Towards_collective_cons from "../Pages/Insight/Blog/Towards_collective_cons";
import Need_trade_wars2 from "../Pages/Insight/Blog/Need_trade_wars2";
 

export default function PageNotFound() {
    switch (window.location.pathname) {
        case "/insight":
            return <Redirect to="/articles" component={Articles} />;
        case "/insight/case-studies/automated-data-warehouse-and-back-testing-solutions-for-global-index-provider-to-achieve-financial-benefits":
            return <Redirect to="/case-studies/automated-data-warehouse-and-back-testing-solutions-for-global-index-provider-to-achieve-financial-benefits" component={Automated_data_warehouse} />;
        case "/insight/case-studies/extraction-of-ghg-emission-data-from-esg-disclosure-reports":
            return <Redirect to="/case-studies/extraction-of-ghg-emission-data-from-esg-disclosure-reports" component={GHG_Emmission_Persifoni} />;
        case "/insight/case-studies/process_automation":
            return <Redirect to="/case-studies/tailoring-process-automation-to-provide-quality-data-in-salesforce-crm" component={SalesForce} />;
        case "/insight/case-studies/fraud-detection-and-analytics-for-a-leading-german-reinsurer":
            return <Redirect to="/case-studies/fraud-detection-and-analytics-for-a-leading-german-reinsurer" component={Fraud_detection} />;
        case "/insight/case-studies/process-and-cost-efficiency-through-structured-data-management-solutions":
            return <Redirect to="/case-studies/process-and-cost-efficiency-through-structured-data-management-solutions" component={Process_and_cost} />;
        case "/insight/case-studies/salesforce-automation-to-simplify-your%20-complex-processes":
            return <Redirect to="/case-studies/salesforce-automation-to-simplify-your-complex-processes" component={Automation_and_custom} />;
        case "/insight/case-studies/offering-solutions-for-every-asset-managers":
            return <Redirect to="/case-studies/offering-solutions-for-every-asset-managers" component={Offering_solutions} />;
        case "/insight/case-studies/nav-calculations-for-portfolio-management":
            return <Redirect to="/case-studies/nav-calculations-for-portfolio-management" component={Nav_calculations} />;
        case "/insight/case-studies/intelligent-document-processing-of-rent-rolls-for-an-asset-manager":
            return <Redirect to="/case-studies/intelligent-document-processing-of-rent-rolls-for-an-asset-manager" component={Intelligent_doc_processing} />;
        case "/insight/case-studies/data-managment-for-efficient-crm-usage":
            return <Redirect to="/case-studies/data-managment-for-efficient-crm-usage" component={Data_managment_for_CRM} />;
        case "/insight/case-studies/response-management-system-for-fund-raising":
            return <Redirect to="/case-studies/response-management-system-for-fund-raising" component={Response_management_system} />;
        case "/insight/case-studies/automated-portfolio-monitoring-system-for-a-private-equity-firm":
            return <Redirect to="/case-studies/automated-portfolio-monitoring-system-for-a-private-equity-firm" component={Portfolio_monitoring} />;
        case "/insight/case-studies/robotic-process-automation-for-credit-funds":
            return <Redirect to="/case-studies/robotic-process-automation-for-credit-funds" component={Robotic_process_automation} />;
        case "/insight/case-studies/customized-credit-platform-for-financial-institution":
            return <Redirect to="/case-studies/customized-credit-platform-for-financial-institution" component={Customized_credit_platform} />;
        case "/insight/case-studies/intelligent-dashboard-solutions-for-banks":
            return <Redirect to="/case-studies/intelligent-dashboard-solutions-for-banks" component={Intelligent_dashboard} />;
        case "/insight/articles/tokenization-of-assets-a-new-paradigm-for-businesses":
            return <Redirect to="/articles/tokenization-of-assets-a-new-paradigm-for-businesses" component={Tokenization_of_assets} />;
        case "/insight/articles/regaining-inr-rightful-position-in-regional-trade":
            return <Redirect to="/articles/regaining-inr-rightful-position-in-regional-trade" component={Regaining_inr_position} />;
        case "/insight/articles/how-can-asset-managers-engage-with-businesses-to-accelerate-drive-towards-net-zero":
            return <Redirect to="/articles/how-can-asset-managers-engage-with-businesses-to-accelerate-drive-towards-net-zero" component={How_can_asset_managers} />;
        case "/insight/articles/pe-funds-and-esg-challenges":
            return <Redirect to="/articles/pe-funds-and-esg-challenges" component={PE_funds} />;
        case "/insight/articles/challenges-in-operationalizing-esg-norms-in-asset-management":
            return <Redirect to="/articles/challenges-in-operationalizing-esg-norms-in-asset-management" component={Challenges_in_ESG} />;
        case "/insight/articles/solving-challenges-in-esg-investing":
            return <Redirect to="/articles/solving-challenges-in-esg-investing" component={Solving_challenges} />;
        case "/insight/articles/data-visualization-and-the-art-of-effective-storytelling":
            return <Redirect to="/articles/data-visualization-and-the-art-of-effective-storytelling" component={Data_visualization} />;
        case "/insight/articles/how-asset-managers-are-leveraging-natural-language-processing-techniques":
            return <Redirect to="/articles/how-asset-managers-are-leveraging-natural-language-processing-techniques" component={NLP_technique} />;
        case "/insight/articles/private-equity-adopting-a-data-first-approach":
            return <Redirect to="/articles/private-equity-adopting-a-data-first-approach" component={NLP_technique} />;
        case "/insight/articles/adopting-changes-in-investment-research-methodologies-roadmap-to-institutional-transformation":
            return <Redirect to="/articles/adopting-changes-in-investment-research-methodologies-roadmap-to-institutional-transformation" component={Roadmap_to_institutional} />;
        case "/insight/articles/big-data-and-analytics-the-way-forward-for-asset-managers":
            return <Redirect to="/articles/big-data-and-analytics-the-way-forward-for-asset-managers" component={Way_forward_for_asset_management} />;
        case "/insight/articles/disrupting-investment-research-process":
            return <Redirect to="/articles/disrupting-investment-research-process" component={Disrupting_asset_management} />;
        case "/insight/articles/data-analytics-in-asset-backed-securities":
            return <Redirect to="/articles/data-analytics-in-asset-backed-securities" component={Data_analytics_in_asset} />;
        case "/insight/articles/role-of-advanced-analytics-in-redefining-retail-banking":
            return <Redirect to="/articles/role-of-advanced-analytics-in-redefining-retail-banking" component={Role_of_advanced_analytics} />;
        case "/insight/articles/real-interest-rates-burder-on-indian-businesses":
            return <Redirect to="/articles/real-interest-rates-burder-on-indian-businesses" component={Interest_rates_burden} />;
        case "/insight/articles/evolution-of-the-new-age-avatars-of-asset-managers":
            return <Redirect to="/articles/evolution-of-the-new-age-avatars-of-asset-managers" component={New_age_asset_managers} />;
        case "/insight/articles/how-can-india-benefit-from-being-on-the-currency-watchlist-of-usa":
            return <Redirect to="/articles/how-can-india-benefit-from-being-on-the-currency-watchlist-of-usa" component={India_on_currency} />;
        case "/insight/articles/india-leverage":
            return <Redirect to="/articles/india-leverage" component={India_leverage} />;
        case "/insight/articles/rethinking-corporate-governance":
            return <Redirect to="/articles/rethinking-corporate-governance" component={Rethinking_corporate_governance} />;
        case "/insight/articles/robo-advisors":
            return <Redirect to="/articles/robo-advisors" component={Robo_advisors} />;
        case "/insight/articles/independent_research":
            return <Redirect to="/articles/independant-research" component={Independant_research} />;
        case "/insight/articles/indian-budget-17-18":
            return <Redirect to="/articles/indian-budget-17-18" component={Indian_budget_17_18} />;
        case "/insight/articles/trumps-presidency":
            return <Redirect to="/articles/trumps-presidency" component={Trumps_presidency} />;
        case "/insight/articles/germany-and-upcoming-elections":
            return <Redirect to="/articles/germany-and-upcoming-elections" component={Germany_and_upcoming_elections} />;
        case "/insight/articles/fork-wars":
            return <Redirect to="/articles/fork-wars" component={Fork_wars} />;
        case "/insight/articles/pursuit-of-alpha":
            return <Redirect to="/articles/pursuit-of-alpha" component={Pursuit_of_alpha} />;
        case "/insight/articles/research-redux":
            return <Redirect to="/articles/research-redux" component={Research_redux} />;
        case "/insight/articles/assessing-quality-of-research":
            return <Redirect to="/articles/assessing-quality-of-research" component={Assessing_quality_of_research} />;
        case "/building-process-efficiency":
            return <Redirect to="/blog/building-process-efficiency" component={BuildingProcessEfficiency} />;
        case "/insight/blog/advances-in-ai":
            return <Redirect to="/blog/advances-in-ai" component={Advances_in_ai} />;
        case "/insight/blog/the-great-skills":
            return <Redirect to="/blog/the-great-skills" component={The_great_skills} />;
        case "/insight/blog/a-cautionary-tale":
            return <Redirect to="/blog/a-cautionary-tale" component={A_cautionary_tale} />;
        case "/insight/blog/guiding-policy-making":
            return <Redirect to="/blog/guiding-policy-making" component={Guiding_policy_making} />;
        case "/private-equity-global-pandamic":
            return <Redirect to="/blog/private-equity-global-pandamic" component={Private_equity_global_pandamic} />;
        case "/insight/blog/forging-ahead":
            return <Redirect to="/blog/forging-ahead" component={Forging_ahead} />;
        case "/insight/blog/laplace-demon":
            return <Redirect to="/blog/laplace-demon" component={Laplace_demon} />;
        case "/insight/blog/latrogenesis-of-social-distancing":
            return <Redirect to="/blog/latrogenesis-of-social-distancing" component={Latrogenesis_of_social_distancing} />;
        case "/insight/blog/beginning-of-etf-shock":
            return <Redirect to="/blog/beginning-of-etf-shock" component={Beginning_of_ETF_shock} />;
        case "/insight/blog/extreme-funding-stress":
            return <Redirect to="/blog/extreme-funding-stress" component={Extreme_funding_stress} />;
        case "/insight/blog/covid-19":
            return <Redirect to="/blog/covid-19" component={Covid_19} />;
        case "/research-operations":
            return <Redirect to="/blog/research-operations" component={Research_operations} />;
        case "/insight/blog/from-us-to-yes-bank":
            return <Redirect to="/blog/from-us-to-yes-bank" component={From_us_to_yes_bank} />;
        case "/insight/blog/do-we-really-need-trade-wars":
            return <Redirect to="/blog/do-we-really-need-trade-wars" component={Do_we_really_need_trade_wars} />;
        case "/insight/blog/is-demonstration-respo":
            return <Redirect to="/blog/is-demonstration-respo" component={Is_demonstration_respo} />;
        case "/insight/blog/analyzing-gdp-nos":
            return <Redirect to="/blog/analyzing-gdp-nos" component={Analyzing_GDP_nos} />;
        case "/insight/blog/indias-fiscal-contraction":
            return <Redirect to="/blog/indias-fiscal-contraction" component={Indias_fiscal_contraction} />;
        case "/insight/blog/does-rbi-need-to-revise":
            return <Redirect to="/blog/does-rbi-need-to-revise" component={Does_RBI_need_to_revise} />;
        case "/insight/blog/india-deflation":
            return <Redirect to="/blog/india-deflation" component={India_deflation} />;
        case "/insight/blog/why-will-ai-emerge":
            return <Redirect to="/blog/why-will-ai-emerge" component={Why_will_AI_emerge} />;
        case "/insight/blog/india-heading-toward-deflation":
            return <Redirect to="/blog/india-heading-toward-deflation" component={India_heading_toward_deflation} />;
        case "/insight/blog/excessive-monetory":
            return <Redirect to="/blog/excessive-monetory" component={Excessive_monetory} />;
        case "/insight/blog/emergence-of-singularity":
            return <Redirect to="/blog/emergence-of-singularity" component={Emergence_of_singularity} />;
        case "/insight/blog/the-fallacy-of-paradox":
            return <Redirect to="/blog/the-fallacy-of-paradox" component={The_fallacy_of_paradox} />;
        case "/insight/blog/ai-heuristics-and-bio":
            return <Redirect to="/blog/ai-heuristics-and-bio" component={AI_heuristics_and_bio} />;
        case "/insight/blog/incentive-structure-for-ai":
            return <Redirect to="/blog/incentive-structure-for-ai" component={Incentive_structure_for_AI} />;
        case "/insight/blog/towards-collective-cons":
            return <Redirect to="/blog/towards-collective-cons" component={Towards_collective_cons} />;
        case "/insight/blog/need-trade-wars-two":
            return <Redirect to="/blog/need-trade-wars-two" component={Need_trade_wars2} />;
        default:
            return <Redirect to="/" component={Index} />;
    }
}