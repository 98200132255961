import { Container, Grid, Typography, Box, Tabs, Tab, AppBar, TextField } from "@material-ui/core";
import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Helmet } from 'react-helmet';
import ESG_Newsletters_data from "./Newsletters/ESG_Newsletters_data";
import Design from "./Newsletters/ESG_Newsletters_design";

import { Autocomplete, ListItemButton } from "@mui/material";

const options = [
    { label: 'ESG', to: '/environmental-social-and-governance-for-financial-institutions-and-enterprise-businesses' },
    { label: 'ESG Insights', to: '/esg-insights/esg-whitepaper' },
    { label: 'ESG Roundtable', to: '/environmental-social-and-governance-for-financial-institutions-and-enterprise-businesses#esg-executable-roundtable' },
    { label: 'ESG Products', to: '/empowering-esg-investment-with-real-time-analytics' },
];

const options2 = [
    { label: 'Whitepaper', to: '/esg-insights/esg-whitepaper' },
    { label: 'Newsletter', to: '/esg-insights/esg-newsletter' },
    { label: 'Podcast', to: '/esg-insights/esg-podcast' },
    { label: 'Blogs', to: '/esg-insights/esg-blogs' },
];

export default function ESG_Newsletter() {

    // main tabs
    const [valueESG, setValueESG] = React.useState(1);

    const handleChangeESG = (event, newValue) => {
        window.scrollTo(0, 0);
        setValueESG(newValue);
    };
    // main tabs

    const [valueSub, setValueSub] = React.useState(1);

    const handleChangeSub = (event, newValue) => {
        window.scrollTo(0, 0);
        setValueSub(newValue);
    };



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    const [value1, setValue1] = React.useState(options[1]);

    const [value2, setValue2] = React.useState(options2[1]);

    return (
        <>


            <Helmet>
                <meta charset="UTF-8" />
                <title>Get daily ESG insights with Decimal Point Analytics</title>
                <meta name="description" content="Expert ESG insights for financial institutions and businesses from Decimal Point Analytics one of the leading ESG service provider in US" />
                <meta name="keywords" content="market research solutions
Research company
Sector research provider" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/esg-insights/esg-newsletter" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            <Box id="esg" className="stiky3">
                <Box id="investor_relations_tabs" className="maintabbedsection stiky linkedtabs">
                    <Container maxWidth="lg" className="bdr">
                        <Grid container spacing={1} alignItems="top" justifyContent="center">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <AppBar position="static" className="tabbedbar dgrid_center">
                                    <Tabs value={valueESG} onChange={handleChangeESG} variant="scrollable">
                                        <Tab
                                            label="ESG"
                                            component={Link}
                                            to="/environmental-social-and-governance-for-financial-institutions-and-enterprise-businesses"
                                            value={0}
                                        />
                                        <Tab
                                            label="ESG Insights"
                                            component={Link}
                                            to="/esg-insights/esg-whitepaper"
                                            value={1}
                                        />
                                        <Tab
                                            label="ESG Roundtable"
                                            component={Link}
                                            to="/environmental-social-and-governance-for-financial-institutions-and-enterprise-businesses#esg-executable-roundtable"
                                            value={2}
                                        />
                                        <Tab
                                            label="ESG Products"
                                            component={Link}
                                            to="/empowering-esg-investment-with-real-time-analytics"
                                            value={3}
                                        />
                                    </Tabs>
                                </AppBar>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>

                <Box id="investor_relations_tabs_mob" className="maintabbedsection stiky " mb={5} >
                    <Container maxWidth="lg" className="bdr">
                        <Box className="alltxfieldlg">
                            <Autocomplete
                                disablePortal
                                options={options}
                                value={value1}
                                onChange={(event, newValue1) => setValue1(newValue1)}
                                fullWidth
                                getOptionLabel={(option) => option.label}
                                renderInput={(params) => <TextField {...params} />}
                                renderOption={(props, option) => (
                                    <Link to={option.to}>
                                        <ListItemButton {...props}>
                                            <Typography variant="h6" className="mb0 black fw5">{option.label}</Typography>
                                        </ListItemButton>
                                    </Link>
                                )}
                            />
                        </Box>
                    </Container>
                </Box>
            </Box>

            <Box id="investor_relations_tabs" className="maintabbedsection stiky4 linkedtabs">
                <Container maxWidth="lg" className="bdr">
                    <Grid container spacing={1} alignItems="top">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <AppBar position="static" className="tabbedbar dgrid_center">
                                <Tabs value={valueSub} onChange={handleChangeSub} variant="scrollable">
                                    <Tab
                                        label="Whitepaper"
                                        component={Link}
                                        to="/esg-insights/esg-whitepaper"
                                        value={0}
                                    />
                                    <Tab
                                        label="Newsletter"
                                        component={Link}
                                        to="/esg-insights/esg-newsletter"
                                        value={1}
                                    />
                                    <Tab
                                        label="Podcast"
                                        component={Link}
                                        to="/esg-insights/esg-podcast"
                                        value={2}
                                    />
                                    <Tab
                                        label="Blogs"
                                        component={Link}
                                        to="/esg-insights/esg-blogs"
                                        value={3}
                                    />
                                </Tabs>
                            </AppBar>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box id="investor_relations_tabs_mob" className="maintabbedsection stiky4 esg_tabs">
                <Container maxWidth="lg" className="bdr">
                    <Box className="alltxfieldlg">
                        <Autocomplete
                            disablePortal
                            options={options2}
                            value={value2}
                            onChange={(event, newValue2) => setValue2(newValue2)}
                            fullWidth
                            getOptionLabel={(option) => option.label}
                            renderInput={(params) => <TextField {...params} />}
                            renderOption={(props, option) => (
                                <Link to={option.to}>
                                    <ListItemButton {...props}>
                                        <Typography variant="h6" className="mb0 black fw5">{option.label}</Typography>
                                    </ListItemButton>
                                </Link>
                            )}
                        />
                    </Box>
                </Container>
            </Box>



            <Box className="section sec_bg section_mob" component="section" style={{ paddingTop: "3%" }} >
                <Container maxWidth="lg" className="bdr">

                    <Grid container spacing={1} alignItems="top">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className="al_center" mt={5}>
                                <Typography className="black newh1 mb0" variant="h1">ESG Newsletter</Typography>
                            </Box>
                            <Box className="al_center" mt={0}>
                                <Typography variant="h2" className="black h2v2"> Building Analytical Solutions for a Sustainable Future </Typography>
                            </Box>
                        </Grid>
                    </Grid>

                    <Box className="reportinfo">

                        <Grid container spacing={1} alignItems="top" justifyContent="center">


                            {ESG_Newsletters_data.map((item, index) => (
                                <Grid item lg={4} md={4} sm={6} xs={12} key={index}>
                                    <Design
                                        type={item.type}
                                        link={item.link}
                                        imgsrc={item.imgsrc}
                                        heading={item.heading}
                                    />
                                </Grid>
                            ))}

                        </Grid>
                    </Box>

                </Container>
            </Box>
        </>
    );
}
