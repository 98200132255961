import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Eventback from '../../../Component/Eventback'
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';


export default function PareshSharmaRepresents() {


    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>Paresh Sharma at the 5th PICUP Fintech Conference</title>
                <meta name="description" content="Highlights from Paresh Sharma at the 5th PICUP Fintech Conference in New Delhi, focusing on the future of finance and fintech innovation by Decimal Point Analytics." />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/paresh-sharma-represents-decimal-point-analytics-at-the-5th-picup-fintech-conference" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>



            <Box id='event-details' className='section sec_bg' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={8} md={8} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Events
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center'>
                                    Paresh Sharma Represents Decimal Point Analytics at the 5th PICUP Fintech Conference
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>

                    <Box className='whitebx'>

                        <Typography variant='h6' className='black jst'>
                        <strong>Paresh Sharma</strong>, Managing Partner at <strong>Decimal Point Analytics</strong>, attended the <strong>5th PICUP Fintech Conference</strong> and Awards on August 7th in New Delhi. This prestigious event, hosted by IBA and FICCI, brought together industry leaders to explore the future of finance and discuss innovative trends shaping the fintech landscape. The conference provided valuable insights and opportunities for networking with key stakeholders in the fintech sector. It was a fantastic occasion to engage in meaningful discussions about the evolving world of finance.
                        </Typography>

                        <Typography variant='h6' className='black jst'>To learn more about how <strong>Decimal Point Analytics</strong> is driving innovation in fintech, discover <Link to="/contact-us#contact_form" className='link'>our solutions here</Link>.
                        </Typography>


                    </Box>

                    <Eventback />

                </Container>
            </Box>



        </>
    );
}
