import { IconButton, Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Eventback from '../../../Component/Eventback'
import { Helmet } from "react-helmet";
import { withStyles } from '@material-ui/core/styles';

import Carousel from "react-elastic-carousel";

import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined';

import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';

import { Link } from 'react-router-dom';

import Carouselimg1 from "../../../asset/images/media/event/america_east_meet/americaeastmeet1.webp"
import Carouselimg2 from "../../../asset/images/media/event/america_east_meet/americaeastmeet2.webp"


const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);


export default function AmericaEastMeet() {


    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };


    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>Nilanjan Das at America East Meet NYC</title>
                <meta name="description" content="Highlights from Nilanjan Das at America East Meet NYC, where he discussed data-driven solutions and explored partnerships with industry leaders." />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/decimal-point-analytics-at-america-east-meet-nyc-2024-advancing-data-driven-insights" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>



            <Box id='event-details' className='section sec_bg' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={9} md={9} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Events
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center'>
                                    Decimal Point Analytics at America East Meet NYC 2024: Advancing Data-Driven Insights
                                </Typography>

                            </Box>
                        </Grid>
                    </Grid>


                    <Box className='whitebx'>


                        <Typography variant='h6' className='black jst'>
                        <strong>Nilanjan Das</strong>, our Executive Vice President of Sales, represented <strong>Decimal Point Analytics</strong> at the prestigious <strong>America East Meet NYC</strong>, which took place from August 14-16. This annual gathering brought together top industry leaders, providing a valuable platform for networking, collaboration, and showcasing our innovative data-driven solutions.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            During the event, Nilanjan engaged in productive discussions with key decision-makers, exploring how our advanced analytics could address their most pressing challenges. The <strong>America East Meet NYC</strong> reinforced our commitment to being at the forefront of data-driven insights and highlighted the potential to partner with organizations eager to leverage data for growth and competitive advantage.
                        </Typography>

                        <Typography variant='h6' className='black jst'> To discover how <strong>Decimal Point Analytics</strong> can empower your business with cutting-edge data solutions, <Link to="/contact-us#contact_form" className='link'>reach out to us</Link>
                        </Typography>

                        <Box className='al_center'>
                            <IconButton className='blue' onClick={handleClickOpen}>
                                <CameraAltOutlinedIcon />
                            </IconButton>
                        </Box>

                        <Box className='al_center'>
                            <Typography className='blink_text'>Click Here...</Typography>
                        </Box>


                    </Box>
                    <Eventback />


                </Container>
            </Box>

            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} className="b_modal teammodal">
                <DialogTitle className="feedname" id="customized-dialog-title" onClose={handleClose}>
                </DialogTitle>
                <DialogContent>
                    <Box>
                        <Grid container spacing={2}>
                            <Carousel itemsToShow={1} animateOut="slideOutDown"
                                animateIn="flipInX">
                                <Box className="boxcontent" style={{ padding: '0px' }}>
                                    <Box className='eventbg' style={{ backgroundImage: 'url(' + Carouselimg1 + ')', }}></Box>
                                </Box>

                                <Box className="boxcontent" style={{ padding: '0px' }}>
                                    <Box className='eventbg' style={{ backgroundImage: 'url(' + Carouselimg2 + ')', }}></Box>
                                </Box>
                            </Carousel>
                        </Grid>
                    </Box>
                </DialogContent>
            </Dialog>


        </>
    );
}
