import { Container, Typography, Box, Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Insightback from '../../../Component/Insightback'

import Writemessage3 from '../../../Component/Writemessage3';
import { Helmet } from 'react-helmet';
import EmployeeProfile from '../../../Component/EmployeeProfile';
import SD from '../../../asset/images/team/shailesh_dhuri.webp'
import Banner from '../../../asset/images/insight/article_pages/digital_assets_2024_banner.webp'
import { Link } from 'react-router-dom';

export default function DigitalAssetsCanLookForward() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>Digital Assets Boom: 2024 Poised for Spot ETFs and RWAs</title>
                <meta name="description" content="Explore the future of digital assets, including Bitcoin ETFs and institutional involvement driving tokenized assets towards a $10 trillion market." />
                <meta name="keywords" content="Digital assets, cryptocurrency, Bitcoin, Blockchain, Real World Asset Tokens (RWAs), Spot Bitcoin ETFs, Institutional adoption, Fractional ownership, DeFi, Tokenized securities, Tokenized real estate, Stablecoins, Smart contracts, Traditional finance, Digital Asset future, Decimal Point Analytics" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/articles/digital-assets-can-look-forward-to-a-brighter-future-in-2024" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            <Box id="article-page" className='section sec_bg' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center' >
                        <Grid item lg={10} md={12} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Insight
                                </Typography>
                                <Typography variant='h1' className='blue mainhead al_center' style={{ textTransform: 'none' }}>
                                    Digital Assets can look forward to a brighter future in 2024.
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Box className="" pt={2}>
                        <img src={Banner} className='w100' alt='Banner' />
                    </Box>

                    <Box className='whitebx'>
                        <Box>

                            <Typography variant='h6' className='black jst' gutterBottom><em>After a turbulent 2022, digital assets had a much better year in 2023, and have ended the year with a bunch of positives: institutional interest is on the upswing, real world asset tokens are an emerging category that could see considerable action. </em></Typography>

                            <Typography variant='h6' className='black jst' gutterBottom>
                                While the initial few months of 2023 showed a residual impact of the torrid 2022, on the balance, the year has turned out to be quite positive for the digital asset world. As we head into 2024, the mood appears distinctly upbeat.
                            </Typography>


                            <Typography variant='h6' className='black jst' gutterBottom>
                                While the spillover of 2022 continued into 2023, the crypto currency and the broader digital asset world have had a much better year. The first quarter of 2023 had some rocky moments, led by the collapse of Silicon Valley Bank (SVB), Silvergate and Signature Bank, all of which had closed ties to the crypto world. These simultaneous bank failures temporarily rocked the digital world, with stablecoins like USD Coin (USDC), Terra (UST), Binance USD (BUSD), and DAI losing their dollar pegs by varying degrees in the turbulent March-July’23 phase.
                            </Typography>

                            <Typography variant='h6' className='black jst' gutterBottom>
                                Cut to now, and the situation is vastly different. The weakness in the traditional banking system, plus the cleansing from the system of rogue elements like FTX, became a blessing for bitcoin and other cryptocurrencies. The first application of a new technology attracts nefarious elements and the 2022-23 phase helped take out some of these actors from the market. It was heartening to see that investors could focus on the underlying strength of DLTs and ignore the noise. Bitcoin, and several other cryptos had a banner year, ignoring the residual crisis of 2023. Bitcoin gained over 150% in 2023. The broader crypto market did well, increasing 90% in size, from $840 billion to $1.6 trillion from January to December of 2023, according to The Block. The stablecoins have also recovered their pegs; the total market cap of stablecoins finally rose in Oct’23 after nearly 19 months of consecutive outflows.
                            </Typography>


                            <Typography variant='h6' className='black jst' gutterBottom>
                                Going into 2024, among the likely big events is the expected arrival of Spot BTC ETFs in Jan’24. So far, the crypto ETFs are based on futures, not spot. There are a total of 14 asset managers hoping to win SEC approval for a spot bitcoin ETF, including BlackRock, Fidelity, Franklin Templeton, Valkyrie, and VanEck.
                            </Typography>


                            <Typography variant='h6' className='black jst' gutterBottom>
                                The trend we are most excited about is the increasing interest in broader ‘digital assets’ class, particularly tradable tokens based on real world assets (RWAs). While the concept existed as far back as 2017, RWAs did not quite take off till 2023, when they seem to have finally caught investor interest, and rightly so, given the significant benefits they offer. Tokens are programmable, permissionless, trustless, and transparent. Their underlying protocols contain smart contracts codes that automate the actions required in an agreement or contract, typically stored on a blockchain and runs when predetermined conditions are met. They can automatically issue tokens when certain conditions are met, thus reducing the need for traditional intermediaries, arbitration costs, and fraud losses. They are ideally suited for real world areas like real estate, stocks and bonds, commodities and lending.
                            </Typography>


                            <Typography variant='h6' className='black jst' gutterBottom>
                                In 2023, there was a significant rise in the RWA assets. According to CCdata, the Total Value Locked (TVL) in RWA protocols experienced a 700% increase in 2023, nearing a level of USD 1B (not counting stablecoins). While this is still miniscule, the potential being talked about is immense. Boston Consulting Group estimates that tokenized security assets could represent approximately 10% of the global GDP by 2030. A report by 21.co estimates the market value for tokenized assets will be between $3.5 trillion in the bear-case scenario and $10 trillion in the bull case by 2030. These estimates represent a few orders of magnitude increase from the current size. This time, it appears that the uptick in RWA interest represents a fundamental change, particularly given the institutional interest in this space.
                            </Typography>


                            <Typography variant='h6' className='black jst' gutterBottom>
                                Let’s look deeper into what’s happening in the real-world digital asset word. Tokenised treasures have emerged as instruments with rising traction. Franklin OnChain U.S. Government Money Fund, launched in 2021, is the leader in this segment, with assets of over USD 330m. Several other issuers like Ondo, Mountain Protocol, Maple, Backed Finance, Open Eden have offerings that are steadily gaining in size.
                            </Typography>


                            <Typography variant='h6' className='black jst' gutterBottom>
                                RWAs are ideally suited for institutional participation, given that most of them promise regular yield. As a recent report ‘RWA Tokenization, The Next Generation of Capital Markets’ by DigiFT and HashKey Capital says: “RWA assets bridge the gap between the real world, particularly for the traditional financial sector, and the digital assets world. From the traditional financial industry, RWAs offer reduced time and cost of financial transaction settlements, transparency and traceability, enabling overall cost-efficiency. For the digital assets industry, RWAs offer stable returns, better liquidity, alternative yield sources, and portfolio and collateral diversification”. Leading traditional financial firms like JP Morgan, Goldman Sachs, UBS, HSBC and fund houses like Fidelity and Blackrock are developing programs to tap into digital assets. The key challenge to rapid adoption will be (lack of) regulation, particularly where participants span more than one jurisdiction.
                            </Typography>

                            <Typography variant='h6' className='black jst' gutterBottom>
                                RWAs are expected to rapidly move into other asset classes like private credit, real estate, municipal bonds, structured credit, carbon credits, commodities and even areas like art. The report by 21.co lists 9 asset classes (including stablecoins) where RWA already exist. Among the asset classes it lists are commodities, G-secs, asset backed finance, real estate, corporate bonds, private equity, public equity and private funds.
                            </Typography>

                            <Typography variant='h6' className='black jst' gutterBottom>
                                For <Link to="/" className='link'>Decimal Point Analytics (DPA)</Link>, these are exciting developments. Earlier in 2022, we had incorporated a subsidiary – DPA Smartcloud IFSC Pvt Ltd – in India’s international finance centre Gujarat International Finance Tech-city (GIFT) SEZ to drive our digital assets business. The company is now ready to roll out products based on tokenised real assets. With these, we will be able to offer high quality exposure to yield bearing tokenised assets to some our global alternative asset manager clients.
                            </Typography>
                        </Box>

                        <Box mt={2}>
                        <Box mt={1}>
                                <EmployeeProfile imgSrc={SD} name={"Mr. Shailesh Dhuri"} designation={"Chief Executive Officer,"} company={"Decimal Point Analytics Pvt. Ltd."} linkedinUrl={"https://www.linkedin.com/in/shaileshdhuri/"} />
                            </Box>
                        </Box>

                    </Box>

                    <Box className='pages'>
                        <Writemessage3 pageVisited="Digital Assets can look forward to a brighter future in 2024" />
                    </Box>

                    <Insightback />

                </Container>
            </Box>

        </>
    );
}
