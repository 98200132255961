import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import Business from '../../../asset/images/media/business_standard.webp';
import Yahoo from '../../../asset/images/media/yahoo.webp';
import US_World from '../../../asset/images/media/us_world.webp';
import NewsRoomBack from '../../../Component/NewsRoomBack';
import { Helmet } from 'react-helmet';



export default function Tokenization_Funding() {



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);



    return (
        <>

            <Helmet>
                <title>DPA Receives Grant Funding to Develop Tokenization Solutions </title>
                <meta name="description" content="Decimal Point Analytics receives grant funding from The Hashgraph Association to develop tokenization solutions for traditional asset markets. Learn more" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/decimal-point-analytics-receives-grant-funding-to-develop-tokenization-solutions" />
            </Helmet>


            <Box className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>


                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    News Room
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center'>
                                    Decimal Point Analytics receives grant funding to develop Tokenization solutions
                                </Typography>

                            </Box>
                        </Grid>
                    </Grid>

                    <Box>
                        <Grid container direction="row"
                            justifyContent="center"
                            alignItems="center">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='whitebx'>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Decimal Point Analytics announced to receive an esteemed grant of up to USD 7,50,000 from The Hashgraph Association (THA) to develop tokenization solutions for traditional asset markets.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Decimal Point Analytics (DPA), one of India's most trusted and reliable research and data Analytics Companies for the financial market, today announced receiving an esteemed grant of up to USD 750'000 from The Hashgraph Association (THA).
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        The Hashgraph Association is an independent and non-profit organization headquartered in Switzerland, established to facilitate the development of a vibrant and innovative ecosystem for startups, enterprises, and government institutions around the world using the Hedera network. Hedera is the fastest, greenest, and most cost-efficient enterprise-grade public DLT network, which is governed by the world's leading organisations and includeabrdn, Avery Dennison, Boeing, Chainlink, Dentons, Deutsche Telekom, Development Bank of Singapore (DBS), DLA Piper, EDF, eftpos, FIS (WorldPay), Google, IBM, Indian Institute of Technology (IIT), LG Electronics, Magalu, Nomura Holdings, ServiceNow, Shinhan Bank, Standard Bank, Swirlds Labs, Tata, Ubisoft, University College London (UCL), Wipro, and Zain Group.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        DPA has received in-principle approval from the International Financial Services Centres Authority (IFSCA) - the omnibus regulator of the financial SEZ at Gift City, Gujarat in India - to launch tokenization products within its sandbox environment. DPA will be utilizing the grant funding to build a first-of-its-kind digital assets platform in India for the regulatory-compliant issuance of digital assets and tokenized solutions for financial markets - powered by Hedera.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Speaking on the grant Shailesh Dhuri, CEO of Decimal Point Analytics, said, "Asset tokenisation is at a fairly nascent stage of development here in India but is definitely the future as far as the finance market goes. With internet penetration on the rise, the number of Indians investing in digital assets has risen considerably over the last two years. With asset tokenization, we can enable many investors to allocate their funds in smaller quantities across multiple asset classes, and that too in a safe and secure manner. India is in a growth phase and the country is making huge investments in everything from road, rail, port, health, renewables, power distribution, EV charging network, tourism, space, geotagging to massive next-generation data centres. Tokenization of these newly built government infrastructure assets will create new asset classes, which are stable in value and offer an immediate market size in excess of USD 100 billion."
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Kamal Youssefi, President of The Hashgraph Association, said, "We are excited to facilitate the development of DPA's sandbox-licensed project, which will spur important adoption in the Indian market. Hedera has long been the network of choice for tokenization use cases, a niche fueled by its powerful Hedera Token Service, which allows developers and enterprises to deploy fungible and non-fungible tokens, platforms, and marketplaces on the network. Looking ahead, The Hashgraph Association will continue to collaborate with and empower such projects of all sizes and stages as they build innovative use cases on Hedera network."
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Dhuri further continues, "We will be working closely with The Hashgraph Association and Swiss Digital Assets Institute (SDAI) to develop asset tokenization products on Hedera's distributed ledger technology (DLT). Unlike blockchain, the Hedera network is designed as a fast, secure, lowest-cost, and energy-efficient network, which is powered by the Hashgraph consensus, while achieving the highest degree of security possible through its asynchronous byzantine fault tolerance (ABFT), with blazing-fast transaction speeds and incredibly low bandwidth consumption. Additionally, its Hedera Token Service (HTS) has built-in desirable features such as digital KYC/AML verification and other features, making it most well-suited for providing institutional grade tokenized financial products."
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        In close collaboration with Swiss Digital Assets Institute (SDAI) - a professional training and innovation company backed by the Swiss Government (Canton Schwyz) and responsible for delivering the Hashgraph Innovation Program, DPA will receive professional support from a team of highly experienced subject matter experts with extensive practical knowledge in the tokenization of high-value assets, including assisting DPA to develop and launch regulatory compliant tokenization solutions for the financial markets in India through the GIFT City.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Stefan Deiss, Co-Founder & Director of SDAI, said, "With extensive expertise in tokenization of assets from around the world, we look forward to supporting DPA with the tokenization of its first Indian government-backed project under the Swiss DLT Act, and in compliance with the Swiss Financial Market Supervisory Authority (FINMA). With the creation of this Swiss blueprint for DPA under its new Swiss-based legal entity ("DPA Digital Assets AG") and using the "Hashgraph Digital Assets Platform", we will collaborate with and bring together both regulatory authorities of India (IFSCA) and Switzerland (FINMA) to replicate the regulatory framework (blueprint) for the compliant and secure issuance of digital assets under the DPA sandbox tokenization license in the GIFT City".
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Decimal Point Analytics was formed in 2003 by senior professionals from the Indian Capital Markets and is a global provider of Research and Data Analytics products/services, including Machine Learning and Artificial Intelligence-based solutions. Client categories include Data Providers, Asset Management Firms, Private Equity Firms, Hedge Funds, Banks, Insurance Companies, and Broking Houses. DPA's background and understanding of financial research and its strengths in technology provide it an edge in effectively understanding and solving client problems. DPA recently raised a round of funding from marquee institutional investors in the Middle East to support its decades-long profitable growth journey.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        The Hashgraph Association supports training and education programs across multiple industry verticals. At the forefront is the digital enablement and empowerment of the public through broad adoption of Hedera-powered enterprise-grade solutions and decentralized applications (dApps), which includes innovation, accreditation, and certification programs. As a non-profit organization headquartered in Switzerland, The Hashgraph Association promotes and provides funding for innovation, research and development for the benefit of economic inclusion and with a positive environmental, social, and governance (ESG) impact.
                                    </Typography>


                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Swiss Digital Assets Institute is a professional training and innovation company focused on enabling and empowering startups, enterprises, and government institutions from around the world to adapt and compete in the era of Digital Assets and Decentralized Finance (DeFi). SDAI is specialized in the custom design and delivery of professional training and innovation programs in close collaboration with leading academic institutions, multinational enterprises, system integrators, industry-leading practitioners, and government institutions globally.
                                    </Typography>



                                </Box>

                            </Grid>
                        </Grid>
                    </Box>

                    <Box>
                        <Grid container spacing={0} alignItems="center">
                            <Grid item lg={12} md={12} sm={12} xs={12} data-aos="fade-up">
                                <Box className='graybbx' mt={2}>
                                    <Grid container spacing={0} alignItems="center">
                                        <Grid item lg={4} md={4} sm={4} xs={6}>

                                            <a href="https://finance.yahoo.com/news/india-decimal-point-analytics-gets-115511260.html?guce_referrer=aHR0cHM6Ly9hcHAubWVsdHdhdGVyLmNvbS8&guce_referrer_sig=AQAAAKGmnVPTtOllMmWQvkq4-yJCexh92odlKQLW5Gm3_cw3izpACu7GqZtTVw-lFz-2GD48nUm0ZtEDs9D2sVY9ZXSTX68c1noG56MNw8SfB96IPzN1fPs1SsinMQgQvc_wqTgr7qFs0r4MyBRf3EXeXyWLlEH9cfSq1k2QLRztiWlZ&guccounter=2" target="_blank" rel="noopener noreferrer">
                                                <Box
                                                    className="awardsindexcircle"
                                                    style={{ backgroundImage: "url(" + Yahoo + ")" }}
                                                ></Box>
                                            </a>
                                        </Grid>
                                        <Grid item lg={4} md={4} sm={4} xs={6}>
                                            <a href="https://www.business-standard.com/content/press-releases-ani/india-s-decimal-point-analytics-receives-grant-funding-to-develop-tokenization-solutions-for-usd-100-billion-traditional-assets-market-123011100908_1.html" target="_blank" rel="noopener noreferrer">
                                                <Box
                                                    className="awardsindexcircle"
                                                    style={{ backgroundImage: "url(" + Business + ")" }}
                                                ></Box>
                                            </a>
                                        </Grid>
                                        <Grid item lg={4} md={4} sm={4} xs={6}>
                                            <a href="https://www.usworldtoday.com/news/indias-decimal-point-analytics-receives-grant-funding-to-develop-tokenization-solutions-for-usd-100-billion-traditional-assets-market20230111144949/" target="_blank" rel="noopener noreferrer">
                                                <Box
                                                    className="awardsindexcircle"
                                                    style={{ backgroundImage: "url(" + US_World + ")" }}
                                                ></Box>
                                            </a>
                                        </Grid>

                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box mt={2}>
                        <NewsRoomBack />
                    </Box>


                </Container>
            </Box>





        </>
    );
}
