import React, { useEffect } from "react";

import {
  TextField,
  Button,
  Grid,
  Typography,
  Box,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import PropTypes from "prop-types";
import Aos from "aos";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { Link, useHistory } from "react-router-dom";
import * as yup from "yup";
import { toast } from "react-toastify";
import LoadingButton from "@mui/lab/LoadingButton";
import flags from "react-phone-number-input/flags";
import { Alert as MuiAlert } from "@material-ui/lab";
import { Formik, Field, Form, } from "formik";
import { Helmet } from "react-helmet";
import { webinarRegistrationCall } from "../api/api";
import CustomePhoneNumber from "../CustomePhoneNumber_Not_Mandatory";

import "aos/dist/aos.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-number-input/style.css";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function WebinarRegistration({ webinar_title }) {
  const [phoneValueError, setPhoneValueError] = React.useState();
  const [phoneValue1, setPhoneValue1] = React.useState();
  const [loading, setLoading] = React.useState(false);
 
  const history = useHistory(); 
  const [firstNameError, setFirstNameError] = React.useState(""); 
  const [lastNameError, setLastNameError] = React.useState(""); 

  const nameRegexExp = /^[A-Za-z\s]+$/;
  const indiaPhoneRegex = /^[6-9]\d{9}$/;
  const emailreg =
    /^[a-zA-Z0-9_]+(?:[-._][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+(?:[-.][a-zA-Z0-9_]+)*\.[a-zA-Z]{2,}$/;

  const initialValues = {
    firstname: "",
    lastname: "",
    email: "",
    organization: "",
    phonevalue1: "",
    tandce: true,
  };

  const validationSchema = yup.object({
    firstname: yup
      .string()
      .required("First name is required")
      .typeError("Required feild")
      .test("not-only-spaces", "Enter valid first name", (value) =>
        value ? value.trim().length > 0 : true
      )
      .matches(nameRegexExp, "Enter valid first name"),
    lastname: yup
      .string()
      .required("Last name is required")
      .typeError("Required feild")
      .test("not-only-spaces", "Enter valid last name", (value) =>
        value ? value.trim().length > 0 : true
      )
      .matches(nameRegexExp, "Enter valid last name"),

    email: yup
      .string()
      .required("Email is required")
      .typeError("Required feild")
      .matches(emailreg, "Email is not valid"),
    phonevalue1: yup.string(),
    tandce: yup
      .bool()
      .oneOf([true], "You must accept the terms and conditions"),
  });

  const isPhoneValid = () => {
    if (!phoneValue1) {
      setPhoneValueError("");
      return;
    }

    var phoneValue2 = phoneValue1.includes("+91") ? phoneValue1.substring(3) : phoneValue1;
    if (phoneValue2.trim() === "") {
      setPhoneValueError("Enter phone number");
      return;
    }

    if (isValidPhoneNumber(phoneValue1)) {
      if (phoneValue1.includes("+91")) {
        if (!indiaPhoneRegex.test(phoneValue2)) {
          setPhoneValueError("Invalid phone number");
          return;  
        }
      }
      setPhoneValue1(phoneValue1);
      setPhoneValueError("");
    } else {
      setPhoneValueError("Invalid phone number");
    }
  };

  const onSubmit = (data) => {
    if (phoneValue1) {
      if (isValidPhoneNumber(phoneValue1)) {
        if (phoneValue1.includes("+91")) {
          var phoneValue2 = parseInt(phoneValue1.substring(3));
          if (!indiaPhoneRegex.test(phoneValue2)) {
            setPhoneValueError("Invalid phone number"); 
            return;
          }
        }
        setPhoneValue1(phoneValue1);
        setPhoneValueError("");  
      } else {
        setPhoneValueError("Invalid phone number"); 
        return;
      }
    }

    const formData = {
      first_name: data.firstname,
      last_name: data.lastname,
      contact_no: phoneValue1,
      email: data.email,
      organization: data.organization,
      webinar_name: webinar_title,
    };

    console.log(data.tandce);
    if (!data.tandce) {
      return;
    }
    handleFormSubmit(formData);
  };

  const post = async (formData) => {
    setLoading(true);
    const message = "Oops, something went wrong, Please try again leter.";
    try {
      const { data, status } = await webinarRegistrationCall(formData);
      if (status === 200) {
        if (data.status === true) {
          history.push("/form-submit");
        } else {
          toast.error(message, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
    } catch (error) {
   
      toast.error(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    setLoading(false);
  };

  const handleFormSubmit = (data) => {
    post(data);
  };

  const handleKeyUp = (event, fieldName) => {
    const value = event.target.value;
    if (/\d+/.test(value)) {
      if (fieldName === "firstname") {
        setFirstNameError("First name should not contain numbers");
      } else if (fieldName === "lastname") {
        setLastNameError("Last name should not contain numbers");
      }
    } else {
      if (fieldName === "firstname") {
        setFirstNameError("");
      } else if (fieldName === "lastname") {
        setLastNameError("");
      }
    }
  };

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <>
      <Helmet>
        <meta charset="UTF-8" />
 
        <meta name="author" content="Decimal Point Analytics" /> 

        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>

      <Box
        id="contact_form"
        className="webinarregistration"
        component="section"
      > 
        <Box p={6}>
          <Box className="whitebx">
            <Box mt={1} mb={2}>
              <Typography variant="h6" className="blue al_center fw5 h6bl">
                Registration Form
              </Typography>

              <Box className="flx_center ">
                <Box className="line2"></Box>
              </Box>
            </Box>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              validateOnChange={true}
            >
              {({
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldError,
                isSubmitting,
                touched,
                errors,
              }) => (
                <Form>
                  <Grid container spacing={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Box>
                        <Grid container spacing={2}>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className="alltxfieldlg " mt={1}>
                              <Field
                                as={TextField}
                                id="filled-basic1"
                                label="First Name *"
                                variant="standard"
                                fullWidth
                                name="firstname"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                onKeyUp={(event) =>
                                  handleKeyUp(event, "firstname")
                                }
                                error={
                                  touched.firstname && Boolean(errors.firstname)
                                }
                                helperText={
                                  touched.firstname && errors.firstname
                                    ? errors.firstname
                                    : firstNameError
                                }
                                FormHelperTextProps={{
                                  style: { color: "red" },
                                }}
                              />
                            </Box>
                          </Grid>

                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className="alltxfieldlg " mt={1}>
                              <Field
                                as={TextField}
                                id="filled-basic2"
                                label="Last Name *"
                                variant="standard"
                                fullWidth
                                name="lastname"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                onKeyUp={(event) =>
                                  handleKeyUp(event, "lastname")
                                }
                                error={
                                  touched.lastname && Boolean(errors.lastname)
                                }
                                helperText={
                                  touched.lastname && errors.lastname
                                    ? errors.lastname
                                    : lastNameError
                                }
                                FormHelperTextProps={{
                                  style: { color: "red" },
                                }}
                              />
                            </Box>
                          </Grid>

                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className="alltxfieldlg " mt={1}>
                              <Field
                                as={TextField}
                                label="Email Address *"
                                variant="standard"
                                fullWidth
                                name="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.email && Boolean(errors.email)}
                                helperText={touched.email && errors.email}
                              />
                            </Box>
                          </Grid>

                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className="alltxfieldlg " mt={1}>
                              <Field
                                as={TextField}
                                label="Company Name"
                                variant="standard"
                                fullWidth
                                name="organization"
                                onChange={handleChange}
                              />
                            </Box>
                          </Grid>

                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className="alltxfieldlg " mt={3}>
                              <Field
                                as={PhoneInput}
                                flags={flags}
                                international
                                name="phonevalue1"
                                placeholder="Enter phone number" 
                                value={values.phonevalue1}
                                inputComponent={CustomePhoneNumber}
                                onChange={(value) => {
                                  console.log("Phone number changed:", value);
                                  handleChange({
                                    target: {
                                      name: "phonevalue1",
                                      value: value,
                                    },
                                  });
                                  setPhoneValue1(value);
                                }}
                                onBlur={() => {
                                  handleBlur("phonevalue1");
                                  isPhoneValid();
                                }}
                                error={
                                  touched.phonevalue1 &&
                                  Boolean(errors.phonevalue1)
                                }
                                helperText={
                                  touched.phonevalue1 && errors.phonevalue1
                                    ? errors.phonevalue1
                                    : phoneValueError
                                }
                                FormHelperTextProps={{
                                  style: { color: "red" },
                                }}
                              />
                            </Box>
                            {/* <Typography
                                style={{ color: "red", fontSize: "13px" }}
                              >
                                {phoneValueError}
                              </Typography> */}
                          </Grid>

                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className="alltxfieldlg chkbx">
                              <FormControlLabel
                                control={
                                  <>
                                    <Field
                                      defaultChecked={true}
                                      as={Checkbox}
                                      name="tandce"
                                      color="primary"
                                      size="small"
                                    />
                                  </>
                                }
                                label={
                                  <Typography className="label2">
                                    By clicking submit, I agree to Decimal Point
                                    Analytics{" "}
                                    <Link to="/terms">Terms of Use</Link> and{" "}
                                    <Link to="/policy">Privacy Policy</Link>
                                  </Typography>
                                }
                              />
                            </Box>

                            <Box className="checkbx-error" pt={1}>
                              {touched.tandce && errors.tandce && (
                                <MuiAlert severity="error">
                                  You must accept the terms and conditions
                                </MuiAlert>
                              )}
                            </Box>
                          </Grid>

                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className="al_center" mt={2}>
                              {loading ? (
                                <LoadingButton
                                  loading={loading}
                                  variant="outlined"
                                  disabled
                                >
                                  disabled
                                </LoadingButton>
                              ) : (
                                <Button
                                  onClick={() => {
                                    handleSubmit(onSubmit)();
                                  }}
                                  variant="contained"
                                  className="dpabtn_sm1"
                                  fullWidth
                                >
                                  Submit
                                </Button>
                              )}
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
        {/* </Container> */}
      </Box>
    </>
  );
}
