import { Box, Container, Grid,  Typography } from '@material-ui/core'
import React, { useEffect } from 'react';
import icon1 from '../../asset/images/crm/crm_technology/flow_builder.png';
import icon2 from '../../asset/images/crm/crm_technology/visual_force.png';
import icon3 from '../../asset/images/crm/crm_technology/inspector.png';
import icon4 from '../../asset/images/crm/crm_technology/work_benchl.png';
import icon5 from '../../asset/images/crm/crm_technology/ncino.png';
import icon6 from '../../asset/images/crm/crm_technology/data_loader.png';
import icon7 from '../../asset/images/crm/crm_technology/conga.png';



export const ToolsTechnology = () => {

    

    useEffect(() => {
        const { hash } = window.location;
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                const offset = 170;
                const elementPosition = element.getBoundingClientRect().top;
                const offsetPosition = elementPosition - offset;
                window.scrollBy({
                    top: offsetPosition,
                    behavior: "smooth",
                });
            }
        }
    }, []);

    return (
        <>
            <Box className='section2' id='tools_technologies'>
                <Container>
                    <Grid container spacing={4} alignItems="top" justifyContent='center'>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box>
                                <Typography variant="h2" className="black al_center fw5">
                                    <span className='blue'> Tools & Technologies</span>  DPA Leverages for Salesforce Expertise
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item className="w40">
                            <Box className="crm_toolsbx">
                                <Box className='flx_center '>
                                    <img src={icon1} alt='Immediate Team Setup' className='sba_icon' />
                                </Box>
                                <Box mt={2}>
                                    <Typography variant="h6" className="blue al_center fw5 mb0 " gutterBottom>Flow Builder</Typography>
                                </Box> 

                            </Box>
                        </Grid> 
                        <Grid item className="w40">
                            <Box className="crm_toolsbx">
                                <Box className='flx_center '>
                                    <img src={icon2} alt='Immediate Team Setup' className='sba_icon' />
                                </Box>
                                <Box mt={2}>
                                    <Typography variant="h6" className="blue al_center fw5 mb0 " gutterBottom>VisualForce</Typography>
                                </Box> 

                            </Box>
                        </Grid> 
                        <Grid item className="w40">
                            <Box className="crm_toolsbx">
                                <Box className='flx_center '>
                                    <img src={icon3} alt='Immediate Team Setup' className='sba_icon' />
                                </Box>
                                <Box mt={2}>
                                    <Typography variant="h6" className="blue al_center fw5 mb0 " gutterBottom>Salesforce Inspector</Typography>
                                </Box> 

                            </Box>
                        </Grid> 
                        <Grid item className="w40">
                            <Box className="crm_toolsbx">
                                <Box className='flx_center '>
                                    <img src={icon4} alt='Immediate Team Setup' className='sba_icon' />
                                </Box>
                                <Box mt={2}>
                                    <Typography variant="h6" className="blue al_center fw5 mb0 " gutterBottom>Workbench</Typography>
                                </Box> 

                            </Box>
                        </Grid> 
                        <Grid item className="w40">
                            <Box className="crm_toolsbx">
                                <Box className='flx_center '>
                                    <img src={icon5} alt='Immediate Team Setup' className='sba_icon' />
                                </Box>
                                <Box mt={2}>
                                    <Typography variant="h6" className="blue al_center fw5 mb0 " gutterBottom>nCino</Typography>
                                </Box> 

                            </Box>
                        </Grid> 
                        <Grid item className="w40">
                            <Box className="crm_toolsbx">
                                <Box className='flx_center '>
                                    <img src={icon6} alt='Immediate Team Setup' className='sba_icon' />
                                </Box>
                                <Box mt={2}>
                                    <Typography variant="h6" className="blue al_center fw5 mb0 " gutterBottom>Data Loader</Typography>
                                </Box> 

                            </Box>
                        </Grid> 
                        <Grid item className="w40">
                            <Box className="crm_toolsbx">
                                <Box className='flx_center '>
                                    <img src={icon7} alt='Immediate Team Setup' className='sba_icon' />
                                </Box>
                                <Box mt={2}>
                                    <Typography variant="h6" className="blue al_center fw5 mb0 " gutterBottom>Conga Composer</Typography>
                                </Box> 

                            </Box>
                        </Grid> 
                        <Grid item className="w40">
                            <Box className="crm_toolsbx">
                                <Box className='flx_center '>
                                    <img src={icon6} alt='Immediate Team Setup' className='sba_icon' />
                                </Box>
                                <Box mt={2}>
                                    <Typography variant="h6" className="blue al_center fw5 mb0 " gutterBottom>Apex</Typography>
                                </Box> 

                            </Box>
                        </Grid> 
                        <Grid item className="w40">
                            <Box className="crm_toolsbx">
                                <Box className='flx_center '>
                                    <img src={icon6} alt='Immediate Team Setup' className='sba_icon' />
                                </Box>
                                <Box mt={2}>
                                    <Typography variant="h6" className="blue al_center fw5 mb0 " gutterBottom>Lightning Web Components</Typography>
                                </Box> 

                            </Box>
                        </Grid>  

                    </Grid>
                </Container>
            </Box>

        </>
    )
}

