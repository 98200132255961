import React from "react";
import { Typography, Box } from "@mui/material";
import { Link } from "react-router-dom";

function ESG_Blogs_design(props) {
  const { type, link, imgsrc, heading } = props;

  if (type === "external") {
    return (
      <a href={link} target="_blank" rel="noopener noreferrer">
        <Box className="reportm_bg">
          <Box
            className="reportimg_bg"
            style={{
              backgroundImage: `url(${imgsrc})`,
            }}
          ></Box>
          <Box className="reportbox" mt={4} mb={2}>
            <Typography className="reporttitle two" title={heading}>{heading}</Typography>
          </Box>
          <Box className="line"></Box>
        </Box>
      </a>
    );
  }

  // For internal links (using react-router-dom)
  return (
    <Link to={link}>
      <Box className="reportm_bg">
        <Box
          className="reportimg_bg"
          style={{
            backgroundImage: `url(${imgsrc})`,
          }}
        ></Box>
        <Box className="reportbox" mt={4} mb={2}>
          <Typography className="reporttitle two" title={heading}>{heading}</Typography>
        </Box>
        <Box className="line"></Box>
      </Box>
    </Link>
  );
}

export default ESG_Blogs_design;
