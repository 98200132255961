import {
  Divider,
  Button,
  Container,
  Typography,
  Box,
  IconButton,
} from "@material-ui/core";
import React from "react";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import { Link } from "react-router-dom";
import InstagramIcon from "@material-ui/icons/Instagram";
import YouTubeIcon from "@material-ui/icons/YouTube";
import FacebookIcon from '@material-ui/icons/Facebook';
import ThreadsIcon from '../asset/images/threads.svg';
import TwitterNewIcon from '../asset/images/twitter_new.webp';

import dpa_main_logo from '../asset/images/dpa_main_logo.svg';

import MailOutlineIcon from "@material-ui/icons/MailOutline";

export default function Footer_new() {


  return (
    <>
      <Box
        className="footer al_center"
        component="section"
        style={{ backgroundColor: "#fff" }}
      >
        <Container maxWidth="lg">
          <Box justifyContent="center al_center" pt={2} pb={3}>
            <img src={dpa_main_logo} className="foot_logo" alt="dpa_main_logo" />
          </Box>


          <Box className="newfooterlink">


            <Link to="/event">
              <Button variant="text" className="txt_btn" disableRipple>
                Events
              </Button>
            </Link>

            <span className="v_div_line">|</span>

            <Link to="/newsletter">
              <Button variant="text" className="txt_btn" disableRipple>
                Newsletter
              </Button>
            </Link>

            <span className="v_div_line">|</span>

            <Link to="/pressreleases">
              <Button variant="text" className="txt_btn" disableRipple>
                News Room
              </Button>
            </Link>

            {/* <span className="v_div_line">|</span>

            <Link to="paisapulse">
              <Button variant="text" className="txt_btn" disableRipple>
                Paisa Pulse
              </Button>
            </Link>

            <span className="v_div_line">|</span>

            <Link to="/singularity">
              <Button variant="text" className="txt_btn" disableRipple>
                Singularity Pulse
              </Button>
            </Link> */}


          </Box>


          <Box className="social-icons" mt={2} mb={2}>

            <a href="https://www.linkedin.com/company/decimalpointanalytics/" target="_blank" rel="noopener noreferrer">
              <IconButton size="medium" className="linkedin-icon">
                <LinkedInIcon fontSize="small" />
              </IconButton>
            </a>

            <a href="https://twitter.com/DecimalP" target="_blank" rel="noopener noreferrer">
              <IconButton size="medium" className="twitter-icon">
                <img src={TwitterNewIcon} alt="Twitter logo here" />
              </IconButton>
            </a>

            <a href="https://www.instagram.com/decimalpointanalytics/" target="_blank" rel="noopener noreferrer">
              <IconButton size="medium" className="instagram-icon">
                <InstagramIcon fontSize="small" />
              </IconButton>
            </a>

            <a href="https://www.youtube.com/c/DecimalPointAnalytics" target="_blank" rel="noopener noreferrer">
              <IconButton size="medium" className="youtube-icon">
                <YouTubeIcon fontSize="small" />
              </IconButton>
            </a>

            <a href="https://www.facebook.com/decimalpointanalyticspl" target="_blank" rel="noopener noreferrer">
              <IconButton size="medium" className="facebook-icon">
                <FacebookIcon fontSize="small" />
              </IconButton>
            </a>

            <a href="https://www.threads.net/@decimalpointanalytics" target="_blank" rel="noopener noreferrer">
              <IconButton size="medium" className="threads-icon">
                <img src={ThreadsIcon} alt="threds logo here" />
              </IconButton>
            </a>

          </Box>



          <Divider />

          <Box className="footer-base">

            <Box className="black al_center">
              <a href="mailto:info@decimalpointanalytics.com">
                <Button variant="text" className="txt_btn w100 flx_center" disableRipple fullWidth startIcon={<MailOutlineIcon />} size="medium">
                  info@decimalpointanalytics.com
                </Button>
              </a>
            </Box>

            <Typography variant="h6" className="black al_center">
              © Copyright {new Date().getFullYear()} Decimal Point Analytics Private Limited. All Rights
              Reserved.
              <span className="mob_block"><Link to="/policy"> Privacy</Link> and{" "}
                <Link to="/terms">Terms</Link>
                &nbsp; |  &nbsp;
                <Link to="/sitemap">
                  Sitemap
                </Link>
              </span>
            </Typography>

          </Box>
        </Container>
      </Box>


    </>
  );
}
