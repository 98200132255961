import { IconButton, Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Eventback from '../../../Component/Eventback'
import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdfOutlined';
import { Helmet } from 'react-helmet';




export default function Event_round_table() {


    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>
            <Helmet>
                <title>Portfolio Alignment Tools - Current Practices and Outlook </title>
                <meta name="description" content="Explore current practices and outlook of Portfolio Alignment Tools (PAT) at Decimal Point Analytics' executive roundtable. Join us for insights." />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/executive-roundtable-on-portfolio-alignment-tools" />
            </Helmet>


            <Box id='event-details' className='section sec_bg' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Events
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center mb0'>
                                    Executive Roundtable on Portfolio Alignment Tools: Current Practices and Outlook
                                </Typography>
                                <Typography className=' mainsmhead black al_center mb0'>
                                    Mar, 2023
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>





                    <Box className='whitebx'>


                        <Typography variant='h6' className='black jst'>
                            Asset managers are increasingly using Portfolio Alignment Tools (PAT) to align their portfolios with temperature goals outlined in the Paris Agreement. At Decimal Point Analytics, we recently hosted a closed-door Round Table discussion on "Portfolio Alignment Tools: Current practices and outlook," where we explored the challenges and best practices of PAT models.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            Our session highlighted several key takeaways, including regulatory compliance expectations, the use of publicly available data combined with internal methodology, investment funds embedding ESG experts in their products, and the challenge of data. We also discussed how regulations in Europe will force companies to disclose more ESG-related information.
                        </Typography>



                        <Typography variant='h6' className='black jst'>
                            We were thrilled to bring together a diverse group of industry experts to discuss the latest trends and best practices for portfolio alignment tools. The financial community plays a vital role in nudging corporates towards net-zero compliance, and we believe that PAT models will play a critical role in achieving that goal.
                        </Typography>


                        <Typography variant='h6' className='black jst'>
                            Want to be a part of our next exclusive roundtable meeting? Reach out to Sarath Nair at  <a href="mailto:sarath.nair@decimalpointanalytics.com" target="_blank" rel="noopener noreferrer"> sarath.nair@decimalpointanalytics.com </a> to express your interest!
                        </Typography>



                        <Box className='al_center'>
                            <IconButton className='blue'>
                                <a href='https://objectstorage.ap-mumbai-1.oraclecloud.com/n/bmexgnkisxsv/b/creative_dpa/o/Media%2Fuploaded_imgs%2FTask%2Fround_table_event.pdf' target='_blank' rel="noopener noreferrer">
                                    <PictureAsPdfOutlinedIcon />
                                </a>
                            </IconButton>
                        </Box>

                        <Box className='al_center'>
                            <Typography className='blink_text'>Click Here to View the Presentation...</Typography>
                        </Box>


                    </Box>




                    <Eventback />



                </Container>
            </Box>



        </>
    );
}
