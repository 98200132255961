import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Writemessage3 from '../../Component/Writemessage3'

import offering1 from "../../asset/images/services/fund_management/offering1.png";
import offering2 from "../../asset/images/services/fund_management/offering2.png";
import offering3 from "../../asset/images/services/fund_management/offering3.png";
import offering4 from "../../asset/images/services/fund_management/offering4.png";
import offering5 from "../../asset/images/services/fund_management/offering5.png";
import offering6 from "../../asset/images/services/fund_management/offering6.png";
import whyCP from "../../asset/images/product/creditpulse/why_credit_pulse.webp"

import { Helmet } from 'react-helmet';

import ResearchSolution from "../../asset/images/new_banner/research_solution_banner.webp"
import { Link } from 'react-router-dom/cjs/react-router-dom';
import GetInTouch from '../../Component/Get_in_touch';

export default function FundManagement() {



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);





    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>Fund Management & Private Equity Solutions | Optimize Performance & Maximize Returns</title>
                <meta name="description" content="Enhance fund management with our all-in-one solutions. From portfolio analytics to automated reporting, we streamline operations and optimize investment strategies." />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/fund-management-and-private-equity-support-solutions" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            <Box className="banner_bx" mb={"2px"}>
                <Box style={{ backgroundImage: "url(" + ResearchSolution + ")" }} className="banner_img">
                    <Box className="img_over bl_overlay">
                        <Grid container spacing={0} alignItems="center" justifyContent='center'>
                            <Grid item lg={5} md={7} sm={8} xs={10}>
                                <Box className="al_center">
                                    <Typography className="white" variant="h1">Fund Management & Private Equity Support Solutions</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>

            <Writemessage3 pageVisited="Research Solution" />

            <Box className='section2 sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} direction="row" justifyContent="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box mt={1} className='synopsic_bx'>
                                <Typography variant='h6' className='black jst' gutterBottom>
                                    In today’s fast-paced financial landscape, effective fund management is crucial for achieving sustainable growth and maximizing investor returns. Our Fund Management & Private Equity Support Solutions are designed to provide fund managers and private equity professionals with the tools, insights, and services they need to manage, monitor, and optimize their portfolios efficiently. Whether you're overseeing complex portfolios or seeking to improve operational efficiency, our solutions offer comprehensive support every step of the way.
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>


            <Box className='section mob_card' component='section'>
                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} direction="row" justifyContent="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='al_center'>
                                <Typography variant='h2' className='black'>
                                    Our Comprehensive Offering
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} alignItems="center">
                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Box className='boxbg mnhg5'>
                                <Box className='al_center'>
                                    <img src={offering1} className='allic' alt='Fund Administration & Accounting' />
                                </Box>
                                <Box>
                                    <Grid container spacing={3} alignItems="center" justifyContent='center'>
                                        <Grid item lg={7} md={9} sm={8} xs={12}>
                                            <Box>
                                                <Typography variant='h4' className='blue al_center'>
                                                    Fund Administration & Accounting
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Typography variant='h6' className='black jst'>
                                        Efficient fund administration is the backbone of successful fund management. Our service streamlines all aspects of fund operations, including accounting, NAV calculations, investor transactions, and regulatory compliance. With our expertise, you can ensure transparency, accuracy, and timely reporting, helping you focus on strategic decision-making while we handle the intricacies of administration.
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Box className='boxbg mnhg5'>
                                <Box className='al_center'>
                                    <img src={offering2} className='allic' alt='Fund & Portfolio Analytics' />
                                </Box>
                                <Box>
                                    <Grid container spacing={3} alignItems="center" justifyContent='center'>
                                        <Grid item lg={7} md={8} sm={7} xs={12}>
                                            <Box>
                                                <Typography variant='h4' className='blue al_center'>
                                                    Fund & Portfolio Analytics
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Typography variant='h6' className='black jst'>
                                        Gain a deeper understanding of your investment strategy with our advanced fund and portfolio analytics solutions. We provide comprehensive tools that enable real-time performance monitoring, risk assessment, and trend analysis. With access to these powerful insights, fund managers can make data-driven decisions, optimize their portfolios, and manage risk with confidence.
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Box className='boxbg mnhg5'>
                                <Box className='al_center'>
                                    <img src={offering3} className='allic' alt='Fund Level Performance Evaluation & Reporting' />
                                </Box>
                                <Box>
                                    <Grid container spacing={3} alignItems="center" justifyContent='center'>
                                        <Grid item lg={8} md={11} sm={10} xs={12}>
                                            <Box>
                                                <Typography variant='h4' className='blue al_center'>
                                                    Fund Level Performance Evaluation & Reporting
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Typography variant='h6' className='black jst'>
                                        Our performance evaluation services offer a clear and detailed picture of your fund’s progress, highlighting key metrics and trends. We provide customized reports that analyze fund-level returns, asset performance, and other critical indicators. This helps you make informed decisions, communicate effectively with investors, and demonstrate the value of your fund management efforts.
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Box className='boxbg mnhg5'>
                                <Box className='al_center'>
                                    <img src={offering4} className='allic' alt='Automated Factsheets & Reports' />
                                </Box>
                                <Box>
                                    <Grid container spacing={3} alignItems="center" justifyContent='center'>
                                        <Grid item lg={8} md={10} sm={9} xs={12}>
                                            <Box>
                                                <Typography variant='h4' className='blue al_center'>
                                                    Automated Factsheets & Reports
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Typography variant='h6' className='black jst'>
                                        With our automated factsheet and reporting solutions, you can effortlessly generate timely, accurate, and visually appealing reports. These automated systems reduce manual effort, ensuring consistency and accuracy across your investor communications. You can provide stakeholders with up-to-date and reliable performance data, allowing for better decision-making and enhanced trust.
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Box className='boxbg mnhg5'>
                                <Box className='al_center'>
                                    <img src={offering5} className='allic' alt='Portfolio Construction & Monitoring' />
                                </Box>
                                <Box>
                                    <Grid container spacing={3} alignItems="center" justifyContent='center'>
                                        <Grid item lg={8} md={10} sm={8} xs={12}>
                                            <Box>
                                                <Typography variant='h4' className='blue al_center'>
                                                    Portfolio Construction & Monitoring
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Typography variant='h6' className='black jst'>
                                        Construct and maintain a well-diversified portfolio that aligns with your fund’s objectives. Our portfolio construction services help you select the right mix of assets based on your strategy, risk profile, and market conditions. Additionally, our portfolio monitoring service tracks performance against benchmarks, giving you real-time insights and enabling proactive adjustments to your investment approach.
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Box className='boxbg mnhg5'>
                                <Box className='al_center'>
                                    <img src={offering6} className='allic' alt='Investment Teasers & Investor Reporting' />
                                </Box>
                                <Box>
                                    <Grid container spacing={3} alignItems="center" justifyContent='center'>
                                        <Grid item lg={7} md={10} sm={9} xs={12}>
                                            <Box>
                                                <Typography variant='h4' className='blue al_center'>
                                                    Investment Teasers & Investor Reporting
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Typography variant='h6' className='black jst'>
                                        Attract and engage potential investors with compelling investment teasers and comprehensive investor reporting. Our services ensure that your investment opportunities are presented effectively, providing investors with all the information they need to make informed decisions. With clear, consistent reporting, you can keep investors up to date on the performance and progress of your funds.
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>

                </Container>
            </Box>

            <Box className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr' style={{ position: 'relative', }}>

                    <Box className='mob_card' mt={3}>
                        <Grid container spacing={1} alignItems="top">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className="al_center">
                                    <Typography className="black" variant="h2">
                                        Why Choose Us?
                                    </Typography>
                                </Box>
                            </Grid>

                            <Typography variant='h6' className='black al_center' gutterBottom>
                                We provide a unique blend of technical expertise, industry knowledge, and cutting-edge technology to ensure that your fund management operations are streamlined and optimized. Here’s why our clients trust us:
                            </Typography>

                            <Grid item lg={5} md={5} sm={6} xs={12}>
                                <Box className='al_center'>
                                    <img src={whyCP} className='topbanner5' alt='Why Outsource CAT Modeling Services for Insurance? ' />
                                </Box>
                            </Grid>

                            <Grid item lg={7} md={7} sm={6} xs={12}>
                                <Box>
                                    <Typography className="blue" variant="h6">
                                        <ul>
                                            <li>
                                                <Typography variant="h6" className="black jst" gutterbottom>
                                                    <strong>Comprehensive Solutions:  </strong> From fund administration to portfolio monitoring, we offer a full suite of services tailored to your needs.
                                                </Typography>
                                            </li>

                                            <li>
                                                <Typography variant="h6" className="black jst" gutterbottom>
                                                    <strong>Advanced Technology:  </strong> Our tools leverage the latest in analytics, automation, and reporting technologies, allowing for enhanced efficiency and data-driven decision-making.
                                                </Typography>
                                            </li>

                                            <li>
                                                <Typography variant="h6" className="black jst" gutterbottom>
                                                    <strong>Expertise & Experience:  </strong> With years of experience in the financial services sector, we understand the nuances of fund management and PE support.
                                                </Typography>
                                            </li>

                                            <li>
                                                <Typography variant="h6" className="black jst" gutterbottom>
                                                    <strong>Customizable & Scalable:  </strong> Our solutions are designed to grow with your fund. We provide flexible and scalable offerings that adapt to your evolving needs.
                                                </Typography>
                                            </li>
                                        </ul>
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className="al_center" mt={5}>
                                    <Typography className="black" variant="h6">
                                        <Link className='link' to='/contact-us#contact_form'>Partner with us</Link>  today and streamline your fund management operations for enhanced efficiency and investor satisfaction.
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                </Container>
            </Box>

            <Box>
                <GetInTouch />
            </Box>


        </>
    );
}
