import { IconButton, Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'


import Eventback from '../../../Component/Eventback'
import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdfOutlined';
import { Helmet } from 'react-helmet';







export default function Event_01_Sep() {




    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>
            <Helmet>
                <title>Developed vs Developing Markets - Assessing Risk</title>
                <meta name="description" content="Explore insights from Decimal Point Analytics' CEO Shailesh Dhuri on 'Risk Management Landscape in 2022 and Beyond' at the PRMIA webinar." />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/are-developed-markets-riskier" />
            </Helmet>


            <Box id='event-details' className='section sec_bg' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Events
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center mb0'>
                                    Are Developed Markets Riskier Than Developing Markets?
                                </Typography>
                                <Typography className='mainsmhead black al_center mb0'>
                                    Sep, 2022
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>





                    <Box className='whitebx'>


                        <Typography variant='h6' className='black jst'>
                            “Risks arise when our perception is different from our reality” was the opening statement as our CEO
                            Shailesh Dhuri addressed a group of industry professionals and thought leaders as a keynote speaker at
                            PRMIA - Professional Risk Managers'​ International Association Webinar on "Risk Management Landscape in 2022 and Beyond”.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            In his keynote session, he shared a few industry insights and raised some important questions i.e. Do sovereign ratings reflect economic performance? Are developed markets more riskier than developing markets?
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            He concluded the session with some salient takeaways on Risks arising in most G7 countries. Most G7 Countries enjoy excellent sovereign credit ratings, but a closer look at their economic performance may tell another story. Inflation in G7 Countries has spiked to record highs while BRICS have managed to sail the boat while G7 countries’ 10-year Average GDP Growth is way lower than BRICS & is expected to falter further as per IMF Forecast. Access the complete presentation by clicking the button below the image on the left.
                        </Typography>



                        <Box className='al_center'>
                            <IconButton className='blue'>
                                <a href='https://objectstorage.ap-mumbai-1.oraclecloud.com/n/bmexgnkisxsv/b/creative_dpa/o/Media%2Fevent%2FPRMIA_Webinar_26AUG22_Are_Developed_Countries_Riskier_By_DPA.pdf' target='_blank' rel="noopener noreferrer">
                                    <PictureAsPdfOutlinedIcon />
                                </a>
                            </IconButton>
                        </Box>

                        <Box className='al_center'>
                            <Typography className='blink_text'>Click Here...</Typography>
                        </Box>

                        {/* <Subscribe /> */}

                    </Box>




                    <Eventback />




                </Container>
            </Box>



        </>
    );
}
