
import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Writemessage3 from '../../../../../../Component/Writemessage3';
import ESGBlogsBack from '../../../../../../Component/BackButtons/ESGBlogsBack';
import { Helmet } from 'react-helmet';
import EmployeeProfile from '../../../../../../Component/EmployeeProfile';
import AJ from '../../../../../../asset/images/team/ajay_jindal.webp'


export default function ESG_rating_methodology() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);


  return (
    <>

      <Helmet>
        <title>ESG Ratings Complexity - Navigating the Need for Simplicity</title>
        <meta name="description" content="Explore insights on the complexities of ESG ratings methodologies and their implications. Learn more at Decimal Point Analytics." />
        <link rel="canonical" href="https://www.decimalpointanalytics.com/esg-insights/esg-blogs/esg-ratings-muddle-created-by-needless-complexity-in-rating-methodologies" />
      </Helmet>

      <Box className='section sec_bg' component='section'>

        <Container maxWidth='lg' className='bdr'>

          <Grid container spacing={3} alignItems="top">
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box className='al_center' mb={2}>
                <Typography variant='h3' className='subhead skblue al_center'>
                  ESG Insights
                </Typography>
                <Typography variant='h1' className='mainhead blue al_center'>
                  ESG Ratings muddle created by needless complexity in rating methodologies
                </Typography>
              </Box>
            </Grid>
          </Grid>


          <Writemessage3 pageVisited="ESG Ratings muddle created by needless complexity in rating methodologies" />



          <Box className='whitebx' mt={1}  >

            <Box>

              <Typography variant='h4' className='blue'>
                Introduction
              </Typography>

              <Typography variant='h6' className='black jst'>
                Lack of reliability in ESG ratings is a problem pointed out in repeated studies. ‘Compass with no direction’ – that was the assessment of a recent academic article. What has caused it? Needless complexity in the methodologies could be the reason, believes Algo Circle
              </Typography>

            </Box>


            <Box pt={1} pb={2}>

              <Typography variant='h6' className='black jst'>
                Same data, vastly different results – how is that possible? But that is the situation with the global ESG rating business. Over the years, data users and academic papers have repeatedly pointed this out.
              </Typography>



              <Typography variant='h6' className='black jst'>
                An article in The Economist pointed out that credit ratings have a 99% correlation across rating agencies. By contrast, ESG ratings tally little more than half the time.” This is a stark difference that clearly shows the problem users are dealing with here.
              </Typography>



            </Box>



            {/*-------------------------------------------------------*/}

            <Box pt={1} pb={2}>
              <Typography variant='h4' className='blue jst'>
                Compass with no direction
              </Typography>

              <Typography variant='h6' className='black jst'>
                A recent article “ESG ratings—a compass without direction*”, from the Rock Center for Corporate Governance at Stanford University, reviewed ESG ratings and their providers, pointing out that significant shortcomings exist in their objectives, methodologies, and incentives which detract from the informativeness of their assessments.
              </Typography>

            </Box>




            {/*-------------------------------------------------------*/}


            <Box pt={1} pb={2}>
              <Typography variant='h4' className='blue jst'>
                Dubious Data Practices
              </Typography>

              <Typography variant='h6' className='black jst'> We have found some of the data practices of ESG raters rather dubious. As the Stanford paper points out, the data sources used may be public, quasi-public or private data, including company responses to solicited questionnaires. If a metric is being generated to influence investments of public money, to incorporate ‘private’ data sources – including asking companies for information that could be potentially non-public – is an unhealthy practice. This is one more example of needless complexity rating firms are wilfully inducing in their methodologies.
              </Typography>

              <Typography variant='h6' className='black jst'> Listed companies are being urged or mandated by respective regulators to release ESG information on certain parameters. Our view is that rating firms should operate within the confines of the disclosures companies have been asked to make.
              </Typography>


              <Typography variant='h6' className='black jst'> The basic disclosures firms are asked to make are quite adequate to the task at hand. However, in a game of one-upmanship, rating firms are claiming they are using more data sources than the next guy. Consider the wide disparity in the claims raters are making on the data sources they use:

                <ul>
                  <li> One ESG rater claims to use 630+ ESG metrics. Note the lame attempt at precision – not 600+, but 630+. </li>
                  <li> Not to be outdone, another data provider claims to use approximately 1000 data points. </li>
                </ul>
              </Typography>

            </Box>
            {/*-------------------------------------------------------*/}


            <Box pt={1} pb={2}>
              <Typography variant='h4' className='blue jst'>
                Subjectivity
              </Typography>

              <Typography variant='h6' className='black jst'> SEC Commissioner Hester Peirce has been on record stating what “good” means in the context of ESG is very subjective, and that is a key reason why ESG ratings firms are so inconsistent in their results. </Typography>


              <Typography variant='h6' className='black jst'>The Stanford authors observe: this huge number of variables creates issues by itself, requiring the ratings firms to make a variety of judgments, including materiality assessments and related “weighting” of factors, potential absence of relevant data and weighting of “both the variables in their importance to E, S, and G, and also the overall pillars of E, S, and G in relation to one another. (source:www.coolpubco.com).</Typography>

              <Typography variant='h6' className='black jst'> The race to include more factors than the other rater is likely a large cause of the muddled ratings. Several of these factors are qualitative, requiring analyst judgement.
              </Typography>

              <Typography variant='h6' className='black jst'>
                Materiality – a much celebrated concept in ESG – is another area where subjectivity can creep in. ESG raters try to apply materiality at two levels: weights of E, S and G, and also how they treat various sub-themes or factors of E, S and G. Materiality is a license for subjective creativity. One firm may treat a sub-theme as Governance, while another may treat it within Social. Clearly, results will differ between these firms. </Typography>

              <Typography variant='h6' className='black jst'>One rater has added another level of creativity: whether an issue will have an impact in the short term or over the long term. Now imagine applying this to 30+ dimensions and 50+ industry groups.</Typography>

            </Box>
            {/*-------------------------------------------------------*/}



            <Box pt={1} pb={2}>
              <Typography variant='h4' className='blue jst'>
                Treatment of missing data
              </Typography>


              <Typography variant='h6' className='black jst'>
                The more data items you try to use, the larger the problem of missing data. Different raters apply different approaches to tackle this pain point.
              </Typography>

              <Typography variant='h6' className='black jst'>
                The Stanford paper writes: When information is not available to populate a data point, MSCI appears to assume that the company’s performance is the industry average. By contrast, FTSE assumes that the company’s performance is the worst. Under the former approach, the worst company in an industry is better off not disclosing data on its weak areas.
              </Typography>
            </Box>
            {/*-------------------------------------------------------*/}

            <Box pt={1} pb={2}>
              <Typography variant='h4' className='blue jst'>
                Algo Circle Suggestion
              </Typography>


              <Typography variant='h6' className='black jst'>
                Clearly, it is not in the interest of the rating firms to reduce dissonance. However, we would still urge the raters to consider the following:

                <ul>
                  <li>  Stop this race to win the crown of the firm using the largest number of data points. It is detrimental to your output. In fact, cut down data points to a level where you can optimise missing data issue </li>

                  <li> Go easy with materiality. We think it is overhyped. Don’t use it at multiple levels  </li>
                  <li>  Reduce subjectivity. Anything which is subjective will induce errors </li>

                </ul>
              </Typography>

              <Typography variant='h6' className='black jst'>
                Most large firms are gravitating towards using their own frameworks. Firms that use their own frameworks use a much smaller number of data points compared to the third-party rating firms.
              </Typography>

              <Typography variant='body2' className='black jst'>
                * by Brian Tayan, David Larcker, Edward Watts and Lukasz Pomorski
              </Typography>
            </Box>
            {/*-------------------------------------------------------*/}




            {/*-------------------------------------------------------*/}
            <Box mt={1}>
              <EmployeeProfile imgSrc={AJ} name={" Mr. Ajay Jindal"} designation={"EVP,"} company={"Decimal Point Analytics Pvt. Ltd."} linkedinUrl={"https://www.linkedin.com/in/ajay-jindal-cfa-33aa961/"} />
            </Box>
            {/*-------------------------------------------------------*/}





          </Box>
          {/*-------------------------------------------------------*/}



          <Box className='graybbx' mt={1} >
            <Grid container spacing={1} alignItems="top" >
              <Grid item lg={12} md={12} sm={12} xs={12} >
                <Box className='al_left'>

                  <Typography variant='h6' className='black jst'>
                    <b>Category:</b>  ESG Ratings
                  </Typography>

                  <Typography variant='h6' className='black jst'>
                    <b>Tags:</b> Brian Tayan, David Larcker, Edward Watts, Lukasz Pomorski, Rock Center for Corporate Governance at Stanford University,
                  </Typography>

                </Box>
              </Grid>
            </Grid>
          </Box>



          <ESGBlogsBack />




        </Container>
      </Box>


    </>
  );
}

