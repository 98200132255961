import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Alert as MuiAlert } from "@material-ui/lab";
import React, { useState, useEffect } from 'react';


const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default function Uploadfile({ setResumeFile, setFileName }) {
  const [localFileName, setLocalFileName] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const allowedExtensions = ["pdf", "doc", "docx"];
      const extension = file.name.split('.').pop().toLowerCase();
      if (allowedExtensions.includes(extension)) {
        if (file.size <= 10 * 1024 * 1024) {
          setLocalFileName(file.name);
          setFileName(file.name); 
          setResumeFile(file);
        } else {
          alert("File size exceeds 10MB. Please upload a file smaller than 10MB.");
          setLocalFileName("");
          setFileName(""); 
        }
      } else {
        alert("Please upload a PDF or DOC file.");
        setLocalFileName("");
        setFileName(""); 
      }
    } else {
      setLocalFileName("");
      setFileName(""); 
    }
    setShowAlert(false);
  };
 

  useEffect(() => {
    if (submitClicked && !localFileName) {
      setShowAlert(true);
    }
  }, [submitClicked, localFileName]);

  return (
    <>
      <Button
        component="label"
        variant="contained"
        startIcon={<CloudUploadIcon />}
        className='upload_btn'
      >
        {localFileName ? localFileName : "Upload File"}
        <VisuallyHiddenInput id="fileInput" type="file" accept=".pdf,.doc,.docx" onChange={handleFileChange} />
      </Button>

      {showAlert && (
        <MuiAlert severity="error">
          Please upload your resume
        </MuiAlert>
      )}
    </>
  );
}
