import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Divider, Grid } from '@material-ui/core';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import ApiIcon from '@mui/icons-material/Api';
import TroubleshootOutlinedIcon from '@mui/icons-material/TroubleshootOutlined';
import EnergySavingsLeafOutlinedIcon from '@mui/icons-material/EnergySavingsLeafOutlined';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { IMAGES } from './TabIcon';


const Submenu = ({ LinkTo, SubMenuName, onClose }) => {
    return (
        <Link to={LinkTo} onClick={onClose}>
            <Typography variant='h6' className="menus"> {SubMenuName} </Typography>
        </Link>
    )
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box px={2}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

export default function WhoWeServe({ onClose }) {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (

        <>


            <Box
                sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}
            >

                <Grid container spacing={1} alignItems="top" justifyContent="flex-start">
                    <Grid item lg={3} md={3} sm={4} xs={4}>
                        <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={value}
                            onChange={handleChange}
                            aria-label="Vertical tabs example"
                            className='megamenu-tabs'
                        >
                            <Tab label={<><Box className='flx_fs'>
                                <img src={IMAGES.Asset1} className='bico' alt='icon' />
                                <img src={IMAGES.Asset2} className='wico' alt='icon' />
                                <Typography variant='h6' className='tab-label'>Asset Managers</Typography></Box> </>} disableRipple {...a11yProps(0)} />
                            <Tab label={<><Box className='flx_fs'>
                                <img src={IMAGES.Private1} className='bico' alt='icon' />
                                <img src={IMAGES.Private2} className='wico' alt='icon' />
                                <Typography variant='h6' className='tab-label'>Private Equity and Venture Capital</Typography></Box> </>} disableRipple {...a11yProps(1)} />
                            <Tab label={<><Box className='flx_fs'>
                                <img src={IMAGES.Investment1} className='bico' alt='icon' />
                                <img src={IMAGES.Investment2} className='wico' alt='icon' />
                                <Typography variant='h6' className='tab-label'> Investment Managers and Brokerage Firms</Typography></Box> </>} disableRipple {...a11yProps(2)} />
                            <Tab label={<><Box className='flx_fs'>
                                <img src={IMAGES.Lending1} className='bico' alt='icon' />
                                <img src={IMAGES.Lending2} className='wico' alt='icon' />
                                <Typography variant='h6' className='tab-label'>Lending Operations</Typography></Box> </>} disableRipple {...a11yProps(3)} />
                            <Tab label={<><Box className='flx_fs'>
                                <img src={IMAGES.Insurance1} className='bico' alt='icon' />
                                <img src={IMAGES.Insurance2} className='wico' alt='icon' />
                                <Typography variant='h6' className='tab-label'>Insurance Mid and Back-office Support</Typography></Box> </>} disableRipple {...a11yProps(4)} />
                            <Tab label={<><Box className='flx_fs'>
                                <img src={IMAGES.Data1} className='bico' alt='icon' />
                                <img src={IMAGES.Data2} className='wico' alt='icon' />
                                <Typography variant='h6' className='tab-label'>Data Providers/Aggregators </Typography></Box> </>} disableRipple {...a11yProps(5)} />
                            <Tab label={<><Box className='flx_fs'>
                                <img src={IMAGES.FMCG1} className='bico' alt='icon' />
                                <img src={IMAGES.FMCG2} className='wico' alt='icon' />
                                <Typography variant='h6' className='tab-label'>FMCG</Typography></Box> </>} disableRipple {...a11yProps(6)} />
                            <Tab label={<><Box className='flx_fs'>
                                <img src={IMAGES.Corporates1} className='bico' alt='icon' />
                                <img src={IMAGES.Corporates2} className='wico' alt='icon' />
                                <Typography variant='h6' className='tab-label'>Corporates</Typography></Box> </>} disableRipple {...a11yProps(7)} />
                            <Tab label={<><Box className='flx_fs'>
                                <img src={IMAGES.Real1} className='bico' alt='icon' />
                                <img src={IMAGES.Real2} className='wico' alt='icon' />
                                <Typography variant='h6' className='tab-label'>Real Estate</Typography></Box> </>} disableRipple {...a11yProps(8)} />
                            <Tab label={<><Box className='flx_fs'>
                                <img src={IMAGES.Index1} className='bico' alt='icon' />
                                <img src={IMAGES.Index2} className='wico' alt='icon' />
                                <Typography variant='h6' className='tab-label'>Index Providers</Typography></Box> </>} disableRipple {...a11yProps(9)} />

                        </Tabs>
                    </Grid>


                    <Grid item lg={7} md={7} sm={8} xs={8}>

                        <Box>
                            <Typography variant='h6' className="tabsheading menuheading"> What We Serve </Typography>
                        </Box>

                        <TabPanel value={value} index={0}>
                            <Grid container spacing={1} alignItems="top" justifyContent="flex-start">
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className=''>
                                        <Typography variant='h4' className=" blue">Asset Managers </Typography>
                                    </Box>
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Divider component="li" className='w100' />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="M & A Advisory" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Risk Management" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Asset Allocation Strategies" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Fund and Portfolio Monitoring" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Fund Accounting" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Reconciliation" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="NAV Calculation" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Fund and Portfolio Monitoring" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Fund Facesheet Generation" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Performance Monitoring and Attribution Analysis " onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="CRM Support" onClose={onClose} />
                                </Grid>

                            </Grid>
                        </TabPanel>

                        <TabPanel value={value} index={1}>
                            <Grid container spacing={1} alignItems="top" justifyContent="flex-start">
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className=''>
                                        <Typography variant='h4' className=" blue">Private Equity and Venture Capital</Typography>
                                    </Box>
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Divider component="li" className='w100' />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Deal Sourcing and Valuation" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Pre and Post-Deal Assistance" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Due Diligence Support" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Exit Strategy" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Funds Administration" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Fund and Portfolio Monitoring" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Fund Facesheet Generation" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Investor Reporting" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Fund of Funds Support" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="CMR Support" onClose={onClose} />
                                </Grid>
                            </Grid>
                        </TabPanel>

                        <TabPanel value={value} index={2}>
                            <Grid container spacing={1} alignItems="top" justifyContent="flex-start">
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className=''>
                                        <Typography variant='h4' className=" blue">Invesment Managers and Brokerage Firms</Typography>
                                    </Box>
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Divider component="li" className='w100' />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Equity Research" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Financial Modeling" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Sector and Company Coverage" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Fixed Income Research" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Credit Research" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="CMR Suppor" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Macroeconomic Research" onClose={onClose} />
                                </Grid>
                            </Grid>
                        </TabPanel>

                        <TabPanel value={value} index={3}>
                            <Grid container spacing={1} alignItems="top" justifyContent="flex-start">
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className=''>
                                        <Typography variant='h4' className=" blue">Lending Operations</Typography>
                                    </Box>
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Divider component="li" className='w100' />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Loan Underwriting" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Loan Processing" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Data Quality and Operations" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Loan Screening and Due Diligence" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Loan Document Management" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Data Transition Management" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Risk Assessment" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="CRM Support" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="ERC Tax Eligibility Check" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Digital Transformation" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Early Warning Signals" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="BI Consulting" onClose={onClose} />
                                </Grid>
                            </Grid>
                        </TabPanel>

                        <TabPanel value={value} index={4}>
                            <Grid container spacing={1} alignItems="top" justifyContent="flex-start">
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className=''>
                                        <Typography variant='h4' className=" blue">Insurance Mid and Back-office Support</Typography>
                                    </Box>
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Divider component="li" className='w100' />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Catastrophe Modelling" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Bordereaux Processing" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="IFRS 17 Implementation Support" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Investment Support" onClose={onClose} />
                                </Grid>
                            </Grid>
                        </TabPanel>

                        <TabPanel value={value} index={5}>
                            <Grid container spacing={1} alignItems="top" justifyContent="flex-start">
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className=''>
                                        <Typography variant='h4' className=" blue">Data Providers/Aggregators  </Typography>
                                    </Box>
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Divider component="li" className='w100' />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Data Acquisition" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Web Data Extraction" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Data Warehousing and Data Lakes" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Data Quality Management" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="ESG Data Provision Services" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Front-end Development" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="API Development" onClose={onClose} />
                                </Grid>
                            </Grid>
                        </TabPanel>

                        <TabPanel value={value} index={6}>
                            <Grid container spacing={1} alignItems="top" justifyContent="flex-start">
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className=''>
                                        <Typography variant='h4' className=" blue">FMCG</Typography>
                                    </Box>
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Divider component="li" className='w100' />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="FP&A Analytics" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="MIS Automation and Dashboards" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Product Portfolio Optimizationt" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Procurement Dashboards" onClose={onClose} />
                                </Grid>
                            </Grid>
                        </TabPanel>

                        <TabPanel value={value} index={7}>
                            <Grid container spacing={1} alignItems="top" justifyContent="flex-start">
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className=''>
                                        <Typography variant='h4' className=" blue">Corporates</Typography>
                                    </Box>
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Divider component="li" className='w100' />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Equity Research" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Fixed Income Research" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Credit Research" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="CRM Support" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Trade Finance" onClose={onClose} />
                                </Grid>
                            </Grid>
                        </TabPanel>

                        <TabPanel value={value} index={8}>
                            <Grid container spacing={1} alignItems="top" justifyContent="flex-start">
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className=''>
                                        <Typography variant='h4' className=" blue">Real Estate </Typography>
                                    </Box>
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Divider component="li" className='w100' />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Sector Coverage" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Tenant Credit Note" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Alternative Data Analytics" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Macroeconomic Research" onClose={onClose} />
                                </Grid>
                            </Grid>
                        </TabPanel>

                        <TabPanel value={value} index={9}>
                            <Grid container spacing={1} alignItems="top" justifyContent="flex-start">
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className=''>
                                        <Typography variant='h4' className=" blue">Index Providers</Typography>
                                    </Box>
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Divider component="li" className='w100' />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Index Ideation and Conceptualization" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Index Back-testing and Calculation" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Index Operations" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Index Performance Reporting" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Automated Factsheet Generation" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Index Development & Benchmarking" onClose={onClose} />
                                </Grid>
                            </Grid>
                        </TabPanel>

                    </Grid>


                    <Grid item lg={2} md={2} sm={12} xs={12}>
                        <Box className='Tabssidebx' mb={1}>

                            <Grid container spacing={3} alignItems="top" justifyContent="flex-start">
                                <Grid item lg={12} md={12} sm={6} xs={6}>

                                    <Link to="#" >
                                        <Box className='flx_fs menus' mt={0}>
                                            <TroubleshootOutlinedIcon fontSize='small' className='  typo-start-icon' />
                                            <Box>
                                                <Typography variant='h6' className='  fw5 mb0'>
                                                    Data Science & Data Mgnt.
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Link>
                                </Grid>

                                <Grid item lg={12} md={12} sm={6} xs={6}>
                                    <Link to="#">
                                        <Box className='flx_fs menus' mt={0}>
                                            <ManageSearchIcon fontSize='small' className='  typo-start-icon' />
                                            <Box>
                                                <Typography variant='h6' className=' fw5 mb0'>
                                                    BI Services & Data Analytics
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Link>
                                </Grid>


                                <Grid item lg={12} md={12} sm={6} xs={6}>
                                    <Link to="#">
                                        <Box className='flx_fs menus' mt={0}>
                                            <ApiIcon fontSize='small' className='  typo-start-icon' />
                                            <Box>
                                                <Typography variant='h6' className=' fw5 mb0'>
                                                    Technology & Automation
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Link>
                                </Grid>

                                <Grid item lg={12} md={12} sm={6} xs={6}>
                                    <Link to="#">
                                        <Box className='flx_fs menus' mt={0}>
                                            <EnergySavingsLeafOutlinedIcon fontSize='small' className='  typo-start-icon' />
                                            <Box>
                                                <Typography variant='h6' className=' fw5 mb0'>
                                                    ESG Services
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Link>
                                </Grid>

                            </Grid>

                        </Box>
                    </Grid>
                </Grid>
            </Box>

        </>

    );
}