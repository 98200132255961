import { Container, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
 import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Helmet } from 'react-helmet';
import OutsourceSBABanner from '../../asset/images/outsourcesba/outsourcesba_banner.webp'

import SBALoanPreApproval from './SBALoanPreApproval';
import { SBALendersBenefit } from './SBALendersBenefit';
import { SBAContactUs } from './SBAContactUs';
import SBADownloadForm from '../../Component/forms/SBADownloadForm';
// 
export default function OutsourceSBA() {



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []); 


    React.useEffect(() => {
        const { hash } = window.location;
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                const offset = 170;
                const elementPosition = element.getBoundingClientRect().top;
                const offsetPosition = elementPosition - offset;
                window.scrollBy({
                    top: offsetPosition,
                    behavior: "smooth",
                });
            }
        }
    }, []);


    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title> Comprehensive SBA Lending Services for NBFCs and SBA Lenders | Decimal Point Analytics</title>
                <meta name="description" content="Discover specialized SBA loan outsourcing for NBFCs and SBA lenders. Enhance efficiency, accuracy, and compliance with Decimal Point Analytics' expert team." />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/terms" />
                <meta name="keywords" content="SBA loan outsourcing, NBFC SBA lending, SBA loan processing, SBA loan underwriting, SBA loan closing, SBA 7(a) program, SBA lenders, Decimal Point Analytics" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/sba" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>




            <Box id='outsourcesba'>
                <Box className="outsourcesbabanner" component="section" style={{ backgroundImage: "url(" + OutsourceSBABanner + ")" }} pb={2}>
                    <Box>
                        <Container maxWidth="lg">
                            <Box className="text_animated" mb={5}>
                                <Typography variant="h2" className="white fw6 mb0" gutterBottom>
                                    <span className='sba_bannerheading'>Outsource your SBA loan processing with</span> <br /> Decimal Point Analytics
                                </Typography>
                            </Box>

                            <Box className='bannerbxsba' mt={5}>
                                {/* <Grid container spacing={1} alignItems="center" justifyContent='center'>

                                    <Grid item lg={3} md={3} sm={5} xs={12}>
                                        <Box className="sbapgeform">
                                            <TextField
                                                id="filled-basic4"
                                                variant="standard"
                                                fullWidth
                                                name="jobtitle"
                                                className='sbabanner_textfieldl'
                                                placeholder='Full Name'
                                            />
                                        </Box>
                                    </Grid>

                                    <Grid item lg={3} md={3} sm={5} xs={12}>
                                        <Box className="sbapgeform">
                                            <TextField
                                                id="filled-basic4"
                                                variant="standard"
                                                fullWidth
                                                name="jobtitle"
                                                className='sbabanner_textfield'
                                                placeholder='Email Id'
                                            />
                                        </Box>
                                    </Grid>

                                    <Grid item lg={3} md={3} sm={5} xs={12}>
                                        <Box className="sbapgeform">
                                            <TextField
                                                id="filled-basic4"
                                                variant="standard"
                                                fullWidth
                                                name="jobtitle"
                                                className='sbabanner_textfield'
                                                placeholder='Company Name'
                                            />
                                        </Box>
                                    </Grid>

                                    <Grid item lg={3} md={3} sm={5} xs={12}>
                                        <Box className="sbapgeform">
                                            <Button fullWidth
                                                variant="contained"
                                                className="sbabannerbtn"
                                                disableRipple
                                            >
                                                Submit
                                            </Button>
                                        </Box>
                                    </Grid>

                                </Grid> */}
                                <SBADownloadForm />
                            </Box>
                        </Container>


                        {/* <Box>
                            <img src={particalbottomimg} alt='partical image' className='particalbottomimg' />
                        </Box> */}

                    </Box>

                </Box>





                <Box className='section'>
                    <Container maxWidth="lg">
                        <Typography variant="h2" className="black al_center fw5 comprehensive_heading">
                            Comprehensive <span className='fw5 blue'>SBA Lending</span> Services for <span className='fw5 blue'> Non-Banking Financial Companies (NBFCs)</span> and <span className='fw5 blue'>SBA Lenders</span>
                        </Typography>

                        <Box>
                            <Typography variant="h6" className="black al_center">
                                At Decimal Point Analytics, we specialize in providing comprehensive SBA loan outsourcing services to non-banking financial companies (NBFCs) and SBA lenders. Our dedicated team of experts assists lenders in streamlining their SBA loan processes, ensuring efficiency, accuracy, and compliance with regulatory requirements. Outsource your SBA loan processing tasks to Decimal Point Analytics and experience unmatched efficiency and accuracy.
                            </Typography>
                        </Box>

                    </Container>
                </Box>

                <Box id="sbaservices">
                    <SBALoanPreApproval  />
                </Box>

                <Box id="sbabenefit" >
                    <SBALendersBenefit />
                </Box>

                <Box id="sbacontactus">
                    <SBAContactUs />
                </Box>
            </Box>


        </>
    );
}
