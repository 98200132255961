import { Container, Grid, Typography, Box, Divider, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Eventback from '../../../Component/Eventback'
import { Helmet } from "react-helmet";
import ESGDownload from '../../../Component/forms/ESG_download';

import CFA from "../../../asset/images/media/event/cfa.webp"

export default function UnveilingPowerofTokenization() {


    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    const link = "https://objectstorage.ap-mumbai-1.oraclecloud.com/n/bmexgnkisxsv/b/marketing_dpa/o/Transformative%20Power%20of%20Tokenization_FV.pptx"


    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>Power of Tokenization - Insights from 6th CFA India Fintech Conference</title>
                <meta name="description" content="Explore how tokenization transforms financial landscapes. Discover insights on democratized capital access, liquidity, and fraud reduction." />
                <meta name="keywords" content="tokenization, financial technology, fintech, capital markets, investment, DLT, blockchain, AI, CFA India Fintech Conference" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/unveiling-the-transformative-power-of-tokenization-insights-from-the-cfa-india-fintech-conference" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>



            <Box id='event-details' className='section sec_bg' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Events
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center'>
                                    Unveiling <span className='lower'>t</span>he Transformative Power <span className='lower'>o</span>f Tokenization: Insights <span className='lower'>f</span>rom <span className='lower'>t</span>he 6th CFA India Fintech Conference
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Box className='whitebx'>


                        <Typography variant='h6' className='black jst'>
                            <a href='https://www.linkedin.com/in/shaileshdhuri/' target='_blank' rel="noopener noreferrer">Shailesh Dhuri</a>, our CEO, took center stage at the prestigious 6th CFA India Fintech Conference to explore into the transformative power of tokenization. This insightful session explored how tokenization is poised to revolutionize the financial landscape. Here, we distill the key takeaways from this insightful discussion:
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            <ul type='1'>
                                <li>
                                    <Typography variant='h6' className='black jst'>
                                        <strong>Democratization of Capital Access: </strong>  Tokenization opens up new avenues for capital raising, making it easier for unlisted companies to access funding beyond traditional private financing methods.
                                    </Typography>
                                </li>

                                <li>
                                    <Typography variant='h6' className='black jst'>
                                        <strong>Market Liquidity and Investment Opportunities: </strong> By converting previously illiquid assets into tokenized forms, it not only improves market liquidity but also expands investment opportunities for smaller investors.
                                    </Typography>
                                </li>

                                <li>
                                    <Typography variant='h6' className='black jst'>
                                        <strong>Enhanced Information Symmetry and Fraud Reduction: </strong> The use of Distributed Ledger Technology (DLT) brings about greater transparency, daily information sharing, continuous auditing, and significantly reduces the risk of fraud.
                                    </Typography>
                                </li>

                                <li>
                                    <Typography variant='h6' className='black jst'>
                                        <strong> Made Possible by Synergy of AI(Yin) and DLT(Yang): </strong> Contrary to fears of job displacement, AI and tokenization are set to unlock new opportunities, fostering job creation in an evolving digital economy.
                                    </Typography>
                                </li>

                                <li>
                                    <Typography variant='h6' className='black jst'>
                                        <strong> Need for Regulatory and Technological Advancements: </strong> The successful implementation of tokenization requires the development of comprehensive regulatory frameworks and the adoption of advanced technologies for data protection and system integrity.
                                    </Typography>
                                </li>
                            </ul>
                        </Typography>

                        

                        <Grid container direction="row" justifyContent="center" spacing={2} alignItems="top">
                            <Grid item lg={7} md={8} sm={10} xs={12}>
                                <Box pt={3} pb={3}>
                                    <img src={CFA} className='fullimg' alt='CFA' />
                                </Box>
                            </Grid>
                        </Grid>

                        <Divider />

                        <Box mt={2} className='al_center'>
                            <Typography variant="h4" className="black">
                                Unlock <span className='lower'>t</span>he <span className='lower'>i</span>nsights: Download <span className='lower'>t</span>he <span className='lower'>e</span>vent <span className='lower'>p</span>resentation!
                            </Typography>
                        </Box>

                        <Box className='graybbx2222' mt={2}>
                            <ESGDownload reportby={"Unveiling the Transformative Power of Tokenization: Insights from the 6th CFA India Fintech Conference"} link={link} report_type="Event" />
                        </Box>



                    </Box>


                    <Eventback />


                </Container>
            </Box>

        </>
    );
}
