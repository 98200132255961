import CRM1 from '../../asset/images/megaheader/crm1.svg'
import CRM2 from '../../asset/images/megaheader/crm2.svg' 
import Asset1 from '../../asset/images/megaheader/asset1.svg'
import Asset2 from '../../asset/images/megaheader/asset2.svg'

import Private1 from '../../asset/images/megaheader/private1.svg'
import Private2 from '../../asset/images/megaheader/private2.svg'
import Investment1 from '../../asset/images/megaheader/investment1.svg'
import Investment2 from '../../asset/images/megaheader/investment2.svg'
import Lending1 from '../../asset/images/megaheader/lending1.svg'
import Lending2 from '../../asset/images/megaheader/lending2.svg'
import Insurance1 from '../../asset/images/megaheader/insurance1.svg'
import Insurance2 from '../../asset/images/megaheader/insurance2.svg'
import Data1 from '../../asset/images/megaheader/data1.svg'
import Data2 from '../../asset/images/megaheader/data2.svg'
import FMCG1 from '../../asset/images/megaheader/fmcg1.svg'
import FMCG2 from '../../asset/images/megaheader/fmcg2.svg'
import Corporates1 from '../../asset/images/megaheader/corporates1.svg'
import Corporates2 from '../../asset/images/megaheader/corporates2.svg'
import Real1 from '../../asset/images/megaheader/real1.svg'
import Real2 from '../../asset/images/megaheader/real2.svg'
import Index1 from '../../asset/images/megaheader/index1.svg'
import Index2 from '../../asset/images/megaheader/index2.svg'

import Research1 from '../../asset/images/megaheader/research1.svg'
import Research2 from '../../asset/images/megaheader/research2.svg'
import FundMangement1 from '../../asset/images/megaheader/fundmangement1.svg'
import FundMangement2 from '../../asset/images/megaheader/fundmangement2.svg'
import BI1 from '../../asset/images/megaheader/bi1.svg'
import BI2 from '../../asset/images/megaheader/bi2.svg'
import Datascience1 from '../../asset/images/megaheader/datasci1.svg'
import Datascience2 from '../../asset/images/megaheader/datasci2.svg'
import Technology1 from '../../asset/images/megaheader/technology1.svg'
import Technology2 from '../../asset/images/megaheader/technology2.svg'
import ESG1 from '../../asset/images/megaheader/esg1.svg'
import ESG2 from '../../asset/images/megaheader/esg2.svg'
import Product1 from '../../asset/images/megaheader/product1.svg'
import Product2 from '../../asset/images/megaheader/product2.svg'



export const IMAGES = { CRM1, CRM2, Investment1, Investment2, Lending1, Lending2, Insurance1, Insurance2, Data1, Data2, Corporates1, Corporates2, Real1, Real2, Private1, Private2, FMCG1, FMCG2, Index1, Index2, Research1, Research2, FundMangement1, FundMangement2, BI1, BI2, Datascience1, Datascience2, Asset1, Asset2, Technology1, Technology2, ESG1, ESG2, Product1, Product2 };