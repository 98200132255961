import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Helmet } from 'react-helmet';

import Casestudyback from '../../../../Component/Casestudyback'

import Banner from "../../../../asset/images/insight/case_studies/banner_boosting_sba.webp"
import Infographics from "../../../../asset/images/insight/case_studies/improving_sba.webp"
import GetInTouch from '../../../../Component/Get_in_touch';



export default function BoostingSBALoanApprovals() {



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet><meta charset="UTF-8" />
                <meta charset="UTF-8" />
                <title>Boosting SBA Loan Approvals through Precise Documentation and Compliance</title>
                <meta name="description" content="Learn how a finance firm achieved higher loan approval rates and 99% accuracy with a comprehensive documentation checklist and proactive compliance measures." />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/case-studies/boosting-sba-loan-approvals-by-30-with-99-documentation-accuracy" />
            </Helmet>

            <Box id="case-studies" className='section sec_bg new_cs' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={3} alignItems="top" justifyContent='center'>
                        <Grid item lg={9} md={9} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Success Stories
                                </Typography>
                                <Typography className='mainhead blue al_center'>
                                    Boosting SBA Loan Approvals <span className='lower'> by 30% with 99%</span> Documentation Accuracy
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Box >
                        <img src={Banner} className='w100' alt='Banner' />
                    </Box>


                    <Box className='whitebx'>
                        <Box>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Executive Summary
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                Frequent documentation errors, compliance gaps, and high rejection rates were impacting SBA loan approvals. A comprehensive documentation checklist, robust quality control measures, and proactive compliance tracking were implemented, resulting in 99% submission accuracy, a 30% reduction in rejections, and faster loan approvals, significantly improving compliance and approval rates.
                            </Typography>
                        </Box>


                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                The Challenge
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                The firm encountered multiple issues in its SBA loan documentation process:
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Non-Compliance: </strong>  Inadequate alignment with SBA guidelines led to delays and compliance risks.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Frequent Documentation Errors: </strong>  Inaccurate and incomplete loan packages resulted in high rejection rates.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Delayed Approvals: </strong>  Inaccurate filings caused delays in loan processing and approval timelines.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Audit Risks: </strong>  Compliance gaps increased audit risks, affecting overall operational confidence.
                                        </Typography>
                                    </li>
                                </ul>
                            </Typography>
                        </Box>

                        <Box mt={3} className='mob_card'>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Solutions: Improving SBA Documentation
                            </Typography>

                            <Grid container direction="row"
                                justifyContent="center"
                                alignItems="center">

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className="al_center" my={3}>
                                        <img src={Infographics} className="w100" alt="Solutions: Improving SBA Documentation" />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Results
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> 30% Reduction in Loan Rejections: </strong>  Improved documentation accuracy and compliance led to fewer rejections.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>99% Accuracy in Submissions: </strong> High-quality control ensured consistent accuracy across all documentation.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Faster Processing Times: </strong>  Streamlined processes accelerated loan approvals, improving client experience.
                                        </Typography>
                                    </li>
                                </ul>
                            </Typography>


                        </Box>

                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Key Takeaways
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Improved Compliance: </strong>   Comprehensive documentation processes reduced compliance risks.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Higher Approval Rates: </strong>  Accurate submissions increased approval rates and reduced delays.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Operational Confidence: </strong>  Proactive compliance tracking minimized audit risks and boosted approval timelines.
                                        </Typography>
                                    </li>
                                </ul>

                            </Typography>
                        </Box>

                        <Typography variant='h6' className='black jst' gutterBottom>
                            Higher approval rates and compliance accuracy are within reach when documentation processes are clear and proactive. Implementing thorough quality checks and compliance measures can drive better loan outcomes and regulatory alignment.
                        </Typography>

                        <Typography variant='h6' className='black jst' gutterBottom>
                            Need to enhance your loan approval rates? Explore comprehensive compliance solutions to improve documentation accuracy and SBA alignment, driving faster approvals and better results.
                        </Typography>
                    </Box>

                    <Box id="sba_success_stories">
                        <GetInTouch />
                    </Box>

                    <Casestudyback />

                </Container>
            </Box>


        </>
    );
}