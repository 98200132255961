import IMG1 from '../../../../../asset/images/insight/esg/sebastian.webp';
import IMG2 from '../../../../../asset/images/insight/esg/emmission_performance.webp';
import IMG3 from '../../../../../asset/images/insight/esg/denmark.webp';
import IMG4 from '../../../../../asset/images/insight/esg/unep.webp';
import IMG5 from '../../../../../asset/images/insight/esg/implementation_commit.webp';
import IMG6 from '../../../../../asset/images/insight/esg/decarbonisation.webp';
import IMG7 from '../../../../../asset/images/insight/esg/esg_ratings.webp';
import IMG8 from '../../../../../asset/images/insight/esg/indian_regulator.webp';
import IMG9 from '../../../../../asset/images/insight/esg/article_8.webp';
import IMG10 from '../../../../../asset/images/insight/esg/esg_impact.webp';
import IMG12 from '../../../../../asset/images/insight/esg/esg_strategies.webp';
import IMG15 from '../../../../../asset/images/insight/esg/climate_change.webp';
import IMG16 from '../../../../../asset/images/insight/esg/unlocking_success.webp';
import IMG17 from '../../../../../asset/images/esg/esg_blog_thumbnail/green_signal_esg.webp';
import IMG18 from '../../../../../asset/images/esg/esg_blog_thumbnail/indian_asset_managers.webp';

const ESG_Blogs_data = [
    {
        type: "internal",
        link: "/esg-insights/esg-blogs/indian-asset-managers-need-to-take-the-lead-in-net-zero-targeting",
        imgsrc: IMG18,
        heading: "Indian Asset Managers need to take the lead in Net Zero targeting",
    },
    {
        type: "internal",
        link: "/esg-insights/esg-blogs/giving-the-green-signalfor-esg-investing-with-the-right-insights",
        imgsrc: IMG17,
        heading: "Giving the Green Signal for ESG Investing with the Right Insights",
    },
    {
        type: "internal",
        link: "/esg-insights/esg-blogs/unlocking-success-2024-top-five-esg-trends-for-asset-managers",
        imgsrc: IMG16,
        heading: "Unlocking Success: 2024's Top Five ESG Trends for Asset Managers",
    },
    {
        type: "internal",
        link: "/esg-insights/esg-blogs/navigating-climate-change-how-the-financial-services-industry-can-adapt-and-thrive",
        imgsrc: IMG15,
        heading: "Navigating Climate Change: How the Financial Services Industry Can Adapt and Thrive",
    },
    {
        type: "internal",
        link: "/esg-insights/esg-blogs/what-is-esg-and-its-impact-on-investing",
        imgsrc: IMG10,
        heading: "What is ESG & Its Impact on Investing?",
    },
    {
        type: "internal",
        link: "/esg-insights/esg-blogs/how-portfolio-alignment-tools-implement-esg-strategies-for-investors",
        imgsrc: IMG12,
        heading: "How Portfolio Alignment Tools Implement ESG Strategies for Investors",
    },
    {
        type: "internal",
        link: "/esg-insights/esg-blogs/how-to-avoid-repenting-your-article-8-declarations",
        imgsrc: IMG9,
        heading: "How to avoid repenting your Article-8 declarations",
    },
    {
        type: "internal",
        link: "/esg-insights/esg-blogs/indian-regulator-wants-to-regulate-esg-ratings",
        imgsrc: IMG8,
        heading: "Indian regulator wants to regulate ESG ratings",
    },
    {
        type: "internal",
        link: "/esg-insights/esg-blogs/esg-ratings-muddle-created-by-needless-complexity-in-rating-methodologies",
        imgsrc: IMG7,
        heading: "ESG Ratings muddle created by needless complexity in rating methodologies",
    },
    {
        type: "internal",
        link: "/esg-insights/esg-blogs/masterplan-to-accelerate-decarbonisation-of-five-major-sectors-planned",
        imgsrc: IMG6,
        heading: "Masterplan to accelerate decarbonisation of five major sectors planned ",
    },
    {
        type: "internal",
        link: "/esg-insights/esg-blogs/implementation-commitments-loss-and-damage-to-be-the-focus-at-cop27",
        imgsrc: IMG5,
        heading: "Implementation commitments, Loss and Damage to be the focus at COP27",
    },
    {
        type: "internal",
        link: "/esg-insights/esg-blogs/unep-emissions-gap-report-for-2022-paints-a-grim-picture",
        imgsrc: IMG4,
        heading: "UNEP Emissions Gap Report for 2022 paints a grim picture",
    },
    {
        type: "internal",
        link: "/esg-insights/esg-blogs/denmark-sets-a-precedent-on-loss-and-damage-funding",
        imgsrc: IMG3,
        heading: "Denmark sets a precedent on loss and damage funding",
    },
    {
        type: "internal",
        link: "/esg-insights/esg-blogs/emission-performance-of-global-auto-a-mixed-bag-in-2021",
        imgsrc: IMG2,
        heading: "Emission Performance of Global Auto a mixed bag in 2021 ",
    },
    {
        type: "internal",
        link: "/esg-insights/esg-blogs/sebastian-vettel-and-net-zero",
        imgsrc: IMG1,
        heading: "Sebastian Vettel and Net Zero",
    },
];

export default ESG_Blogs_data;