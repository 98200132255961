import { IconButton, Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Eventback from '../../../Component/Eventback'
import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdfOutlined';
import { Helmet } from 'react-helmet';




export default function Quantifying_ESG_Risk() {


    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title> Quantifying ESG Risk: Insights and Challenges from the PRMIA Event </title>
                <meta name="description" content="Explore key takeaways from the 'Quantifying ESG Risk' event. Discuss ESG risk measurement and a unified approach with industry experts." />

                <meta name="keywords" content="ESG Risk, Quantifying ESG, ESG Measurement, PRMIA Event, ESG Reporting, ESG Standards, Financial Risk, Capital-at-Risk, Collaboration in ESG, Regulatory Intervention, Sustainable Future, Climate Change, Social Development, Governance, Stakeholder Engagement" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/quantifying-esg-risk" />

                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            <Box id='event-details' className='section sec_bg' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Events
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center mb0'>
                                    Quantifying ESG Risk
                                </Typography>
                                <Typography className=' mainsmhead black al_center mb0'>
                                    Jun, 2023
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>





                    <Box className='whitebx'>


                        <Typography variant='h6' className='black jst'>
                            “Quantifying ESG Risk," which was held in association with <a href="https://prmia.org/" target='_blank' rel="noopener noreferrer"> PRMIA </a> on June 1<sup>st</sup> in New York. This event brought together esteemed industry experts, practitioners, and thought leaders who shared their valuable insights on ESG risk measurement challenges and discussed potential paths toward a unified approach.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            Our CEO, Shailesh Dhuri, delivered a compelling presentation on the subject during the event. He followed this with an engaging discussion with an esteemed panel consisting of academicians, industry veterans, professional investors and market practitioners.
                        </Typography>


                        <Typography variant='h6' className='black jst'>
                            Some of the key takeaways from the presentation and panel discussion:

                            <ol>
                                <li>
                                    <Typography variant='h6' className='black jst'>
                                        <strong> The Complexity of ESG Risk:</strong> The event shed light on the complexity of ESG risk, emphasizing the multitude of factors and measures involved. It was acknowledged that the broad scope of environmental, social, and governance issues makes it challenging to develop a single unified measure, similar to Value-at-Risk (VaR) in financial risk management.
                                    </Typography>
                                </li>

                                <li>
                                    <Typography variant='h6' className='black jst'>
                                        <strong>The Need for a Unifying Measure:</strong> While there was no consensus on a single unifying measure, participants recognized that a common framework could help standardize ESG reporting and facilitate comparisons across companies and industries. However, it was also acknowledged that ESG risks are context-specific, and different stakeholders may prioritize other aspects of ESG, making a universal measure challenging to achieve.
                                    </Typography>
                                </li>


                                <li>
                                    <Typography variant='h6' className='black jst'>
                                        <strong> Evolution of Standards: </strong> The discussion explored the evolution of ESG standards and their acceptance by the industry. Participants emphasized the importance of collaboration among major participants, including businesses and investors, in driving the development of standards. However, it was also recognized that regulatory and legislative intervention will be necessary to establish widely accepted and consistent ESG reporting standards.
                                    </Typography>
                                </li>

                                <li>
                                    <Typography variant='h6' className='black jst'>
                                        <strong>ESG Risk as a Measure of Financial and Capital-at-Risk:</strong> There was a suggestion to view ESG risk as a measure of financial or capital-at-risk, emphasizing the potential financial implications of environmental, social, and governance factors. This approach aims to bridge the gap between different parties with varying views and priorities. Participants stressed the importance of collective action and collaboration, acknowledging the interconnectedness of climate, social development, and governance.
                                    </Typography>
                                </li>

                            </ol>
                        </Typography>



                        <Typography variant='h6' className='black jst'>
                            The event provided valuable insights into the challenges of measuring ESG risk and the potential avenues for progress. While a single unifying measure remains elusive, the discussion emphasized the need for continued collaboration among stakeholders and the potential role of regulatory intervention. We can work towards a more sustainable future by acknowledging the financial implications of ESG risk and promoting collective responsibility.
                        </Typography>



                        <Typography variant='h6' className='black jst'>
                            Want to be a part of our next exclusive event? Reach out to Sarath Nair at  <a href="mailto:sarath.nair@decimalpointanalytics.com" target="_blank" rel="noopener noreferrer"> sarath.nair@decimalpointanalytics.com </a> to express your interest!
                        </Typography>



                        <Box className='al_center'>
                            <IconButton className='blue'>
                                <a href='https://bmexgnkisxsv.objectstorage.ap-mumbai-1.oci.customer-oci.com/n/bmexgnkisxsv/b/creative_dpa/o/Media%2Fquantifying_esg_risk.pdf' target='_blank' rel="noopener noreferrer">
                                    <PictureAsPdfOutlinedIcon />
                                </a>
                            </IconButton>
                        </Box>

                        <Box className='al_center'>
                            <Typography className='blink_text'>Click Here to View the Presentation...</Typography>
                        </Box>


                    </Box>




                    <Eventback />



                </Container>
            </Box>



        </>
    );
}
