import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Helmet } from 'react-helmet';

import Casestudyback from '../../../../Component/Casestudyback'

import Banner from "../../../../asset/images/insight/case_studies/banner_currency_risk_model.webp"
import Infographics from "../../../../asset/images/insight/case_studies/achieving_50_improvement.webp"
import { Link } from 'react-router-dom';



export default function CurrencyRiskModel() {



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet><meta charset="UTF-8" />
                <meta charset="UTF-8" />
                <title>Boost Currency Risk Management Accuracy by 50% with FX Fair Value Model</title>
                <meta name="description" content="Discover how automated data extraction, statistical modeling, and real-time visualization tools achieved 50% improvement in currency risk management." />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/case-studies/achieving-50-improvement-in-currency-risk-model-accuracy" />
            </Helmet>

            <Box id="case-studies" className='section sec_bg new_cs' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={3} alignItems="top" justifyContent='center'>
                        <Grid item lg={9} md={9} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Success Stories
                                </Typography>
                                <Typography className='mainhead blue al_center'>
                                    Achieving 50% Improvement <span className='lower'>in</span> Currency Risk Model Accuracy
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Box >
                        <img src={Banner} className='w100' alt='Banner' />
                    </Box>


                    <Box className='whitebx'>
                        <Box>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Executive Summary
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                A US-based investment firm specializing in global asset management faced inefficiencies in their currency risk management process. Manual verification and data extraction led to delays and inaccuracies, affecting investment strategies. By implementing automated data processes and advanced statistical modeling using the FX fair value model, the firm significantly enhanced the accuracy and efficiency of its currency risk model.
                            </Typography>
                        </Box>


                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                The Challenge
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                The investment firm encountered several critical challenges:
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Reliance on Third-party Data: </strong> This led to inflated operating costs and concerns over data reliability.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Standardization Discrepancies: </strong> Delays in company classification revisions hindered performance and analysis.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Limited Customization Flexibility: </strong> Processing times were extended due to rigidity in data customization.
                                        </Typography>
                                    </li>

                                </ul>
                            </Typography>
                        </Box>

                        <Box mt={3} className='mob_card'>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Solution: Transforming Currency Risk Management
                            </Typography>

                            <Grid container direction="row"
                                justifyContent="center"
                                alignItems="center">

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className="al_center" my={3}>
                                        <img src={Infographics} className="topbanner" alt="Solution: Transforming Currency Risk Management" />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box mt={1}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Results
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Enhanced Accuracy: </strong> Improved model accuracy by 50% through elimination of input data gaps.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Faster Decision-Making: Real-time data visualization </strong>  facilitated quicker currency risk exposure decisions.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Operational Efficiency: </strong> Achieved a complete reduction in time taken for data sourcing and processing.
                                        </Typography>
                                    </li>

                                </ul>
                            </Typography>
                        </Box>

                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Key Takeaways
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Automated Solutions Drive Accuracy: </strong>  Automation significantly improved the reliability and efficiency of the currency risk model.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Data-Driven Insights Enhance Strategy: </strong>  Enhanced decision-making capabilities led to better investment strategies.
                                        </Typography>
                                    </li>
                                </ul>
                                <Typography variant='h6' className='black jst'>
                                    Are you ready to enhance your currency risk model?
                                </Typography>

                            </Typography>

                            <Typography variant='h6' className='black jst' gutterBottom>
                                <Link to="/contact-us#contact_form" className="link">Contact Us</Link> and explore how tailored automation and data-driven insights can elevate your investment strategies!
                            </Typography>

                        </Box>

                    </Box>


                    <Casestudyback />

                </Container>
            </Box>


        </>
    );
}