import { IconButton, Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Eventback from '../../../Component/Eventback'
import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdfOutlined';
import { Helmet } from "react-helmet";



export default function InnovatingFinancewithAI() {


    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>Executive Roundtable: Innovating Finance with AI – Key Insights & Future Outlook</title>
                <meta name="description" content="Key insights from our Executive Roundtable on 'Innovating Finance with AI,' including AI use cases, challenges, and security. Connect with experts for future events" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/executive-roundtable-on-innovating-finance-with-ai" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>



            <Box id='event-details' className='section sec_bg' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Events
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center'>
                                    Executive Roundtable on Innovating Finance with AI
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>

                    <Box className='whitebx'>

                        <Typography variant='h6' className='black jst'>
                            We recently hosted an exclusive Executive Roundtable on the topic of <strong>"Innovating Finance with AI,"</strong> bringing together industry leaders to discuss the transformative role AI is playing in financial services. The session was designed to provide valuable insights into how AI is revolutionizing processes across the finance sector, fostering innovation, and addressing challenges faced by businesses today.
                        </Typography>

                        <Box pt={1}>
                            <Typography variant='h4' className='blue jst'>Key Takeaways <span className='lower'>from the</span> Round Table Discussion:</Typography>
                        </Box>

                        <Typography variant='h6' className='black jst'>
                            <ol type='1'>
                                <li>
                                    <Typography variant='h6' className='black jst'>
                                        <strong>AI Use Cases and Applications: </strong> AI has emerged as a crucial tool in various domains, particularly in enhancing <strong>security and governance</strong>, <strong>document workflow management</strong>, and modeling <strong>global capital markets</strong>.
                                    </Typography>
                                </li>

                                <li>
                                    <Typography variant='h6' className='black jst'>
                                        <strong>Automated Code Development Challenges: </strong> Despite advancements, <strong>automated code development</strong> is still not fully reliable. Human oversight remains necessary to ensure the accuracy and robustness of code outputs.
                                    </Typography>
                                </li>

                                <li>
                                    <Typography variant='h6' className='black jst'>
                                        <strong>Data Warehousing in AI: </strong> <strong>Data warehousing applications</strong> play a significant role in AI implementations, providing the necessary infrastructure for data management and analysis. The future of AI depends heavily on the development of efficient data storage and retrieval systems.
                                    </Typography>
                                </li>

                                <li>
                                    <Typography variant='h6' className='black jst'>
                                        <strong>Security, Privacy, and Governance: </strong> Protecting sensitive information remains a top priority as AI applications become more widespread, emphasizing the need for strong data governance frameworks.
                                    </Typography>
                                </li>
                            </ol>
                        </Typography>

                        <Box pt={1}>
                            <Typography variant='h4' className='blue jst'>Future Outlook</Typography>
                        </Box>

                        <Typography variant='h6' className='black jst'>
                            The roundtable highlighted the necessity for businesses to remain agile and stay informed about the latest AI advancements to fully leverage its potential. AI is not only reshaping current operations but also driving the innovations of tomorrow.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            <strong>Interested in Future Events? </strong>To join our upcoming discussions or connect with industry experts, contact <strong>Sarath Nair</strong> at <a href="mailto:sarath.nair@decimalpointanalytics.com" target="_blank" rel="noopener noreferrer"> sarath.nair@decimalpointanalytics.com </a>
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            Stay tuned for more thought leadership events and insights from Decimal Point Analytics!
                        </Typography>

                        <Box className='al_center'>
                            <IconButton className='blue'>
                                <a href='https://objectstorage.ap-mumbai-1.oraclecloud.com/n/bmexgnkisxsv/b/marketing_dpa/o/Innovating%20Finance%20with%20AI%20-%20DPA%20Roundtable%20.pdf' target='_blank' rel="noopener noreferrer">
                                    <PictureAsPdfOutlinedIcon />
                                </a>
                            </IconButton>
                        </Box>

                        <Box className='al_center'>
                            <Typography className='blink_text'>Download the Complete Presentation from the Roundtable</Typography>
                        </Box>

                    </Box>

                    <Eventback />

                </Container>
            </Box>



        </>
    );
}
