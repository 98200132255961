import { Container, Typography, Box, Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Insightback from '../../../Component/Insightback'

import Writemessage3 from '../../../Component/Writemessage3';
import { Helmet } from 'react-helmet';
import EmployeeProfile from '../../../Component/EmployeeProfile';
import SD from '../../../asset/images/team/shailesh_dhuri.webp'


export default function IndianSovereignBondsInTheJPMorganIndex() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>Inclusion of Indian Sovereign Bonds in JP Morgan Index</title>
                <meta name="description" content="Explore JP Morgan's inclusion of Indian bonds in its indices and the potential consequences for India's economic landscape." />
                <meta name="keywords" content="Indian Sovereign Bonds, JP Morgan Index, Economic Impact, Sovereignty Concerns, Shailesh Dhuri, Decimal Point Analytics" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/articles/inclusion-indian-sovereign-bonds-jpmorgan-index" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            <Box id="article-page" className='section sec_bg' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center' >
                        <Grid item lg={10} md={12} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Insight
                                </Typography>
                                <Typography variant='h1' className='blue mainhead al_center'>
                                    Is the inclusion of Indian Sovereign Bonds in the JP Morgan Index a positive move?
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>




                    <Box className='whitebx'>



                        <Box pt={1} pb={1}>
                            {/* <Typography variant='h4' className='blue' gutterBottom> MRR – the way forward:  </Typography> */}

                            <Typography variant='h6' className='black jst' gutterBottom>
                                Mark Twain said, “A banker is a fellow who lends you his umbrella when the sun is shining but wants it back the minute it begins to rain.”
                            </Typography>

                            <Typography variant='h6' className='black jst' gutterBottom>
                                I remembered this quote, when I saw, that JP Morgan had decided to include India in its Bond Indices. Why is that so? It was year 1999 when in a brain-storming session with RBI, it was proposed that Indian sovereign bonds should be included in JP Morgan bond indices. I remember the swift reaction of the JP Morgan representative, an empathetic no, which resonated in the entire boardroom.
                            </Typography>


                            <Typography variant='h6' className='black jst' gutterBottom>
                                Now, India’s foreign exchange reserves are 5000% higher than that in 1999. India’s exports are booming. With Make in India expected to produce positive impacts through its Production Linked Incentive government policy; coupled with electrification and planned de-fossilization of transport, India will have a consistent current account surplus from 2025. I expect annual current account surpluses to exceed $100 billion from 2026-27 while maintaining high domestic growth and low inflation. Of course, this presupposes continued economic prudence and continuity in policies framework.
                            </Typography>

                            <Typography variant='h6' className='black jst' gutterBottom>
                                Therefore, the addition to the JP Morgan Index is not going to create any strong economic tailwinds for India beyond 2025. It can potentially create a problem of plenty.  In fact, the linking of the foreign flows MECHANICALLY to an Index produced by a foreign entity will significantly reduce the policy independence of RBI vis-à-vis G7 monetary policy, unless RBI is willing to implement MSS v2.0 and manage the variability of these flows as another unnecessary variable.
                            </Typography>

                            <Typography variant='h6' className='black jst' gutterBottom>
                                I have some concerns about potential concessions which may have been made on operational matters. It is important to note that a sovereign state is typically defined by three key elements:
                                <ol>
                                    <li>  It issues bonds in its own currency.  </li>
                                    <li> Its sovereign laws exclusively govern these bonds.  </li>
                                    <li> The settlement of these bonds occurs entirely within its borders.   </li>
                                </ol>
                            </Typography>


                            <Typography variant='h6' className='black jst' gutterBottom>
                                Tweaking the applicability of these elements may result in external interventions that can be perceived as a compromise to the state's sovereignty.
                            </Typography>


                        </Box>

                        <Box mt={2}>
                            <Box mt={1}>
                                <EmployeeProfile imgSrc={SD} name={"Mr. Shailesh Dhuri"} designation={"Chief Executive Officer,"} company={"Decimal Point Analytics Pvt. Ltd."} linkedinUrl={"https://www.linkedin.com/in/shaileshdhuri/"} />
                            </Box>
                        </Box>


                    </Box>


                    <Box className='pages'>
                        <Writemessage3 pageVisited="Implementing Machine Readable Regulation for Competitive Advantage" />
                    </Box>

                    <Insightback />


                </Container>
            </Box>


        </>
    );
}
