import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import MC from '../../../asset/images/media/money_control.webp';
import NewsRoomBack from '../../../Component/NewsRoomBack';
import { Helmet } from 'react-helmet';


export default function IndiaEmergingAsGlobalTrendsetterInESGDisclosures() {



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);



    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>India Emerging as a Global Trendsetter in ESG Disclosures </title>
                <meta name="description" content="Explore how SEBI's ESG disclosure guidelines set a global trend in corporate responsibility. Learn about India's focus on sustainability and innovation." />

                <meta name="keywords" content="ESG Disclosures, SEBI's ESG Reporting, India's Sustainability Initiatives, Business Responsibility and Sustainability Report (BRSR), Corporate ESG Trends, SEBI's Role in Environmental, Social, and Governance Reporting, Net Zero Targets in India, Carbon Intensity and Purchasing Power Parity (PPP), Corporate Responsibility in India, Sustainable Business Practices" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/india-emerging-as-a-global-trendsetter-in-esg-disclosures" />

                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            <Box className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>


                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    News Room
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center'>
                                    India emerging as a global trendsetter in ESG disclosures
                                </Typography>

                            </Box>
                        </Grid>
                    </Grid>

                    <Box>
                        <Grid container direction="row"
                            justifyContent="center"
                            alignItems="center">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='whitebx'>

                                    <Typography variant='h6' className='black jst' gutterBottom><em>
                                        SEBI’s aggressive stance has ensured India has among the most advanced ESG reporting regulatory regimes in the world, says Shailesh Dhuri, CEO, Decimal Point Analytics, and suggests areas for further action.
                                    </em></Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        The Indian ethos for millennia has considered the plants and animals as the cousins of humans and the earth as a nourishing mother.   The emerging ESG reporting landscape of the Indian market regular Securities Exchange Board of India (SEBI) seems to be intent on reflecting these ethos, leading to a search towards a golden balance between growth and environment, and social parameters, away from sterile shareholder capitalism which considers the ecology and the earth and the society as resources available for exploitation rather than for co-existence. This philosophical difference will eventually lead SEBI towards promulgating the world beating ESG disclosure requirements in its jurisdiction, without compromising on the short and long-term growth potential of the country.</Typography>



                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        The Business Responsibility and Sustainability Report (BRSR) format which was first introduced in 2021 and has become mandatory for the top 1000 companies from FY23, is by any yardstick the most comprehensive reporting guideline globally. SEBI’s construction of BRSR is painstakingly detailed, and not comparable to any other regulatory regime anywhere in the world.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        For example, for employee-related information points like coverage under health insurance, or different able employees, SEBI wants companies to split the data into as many as eight categories: permanent male employee, permanent female employee, other than permanent male employee, other than permanent female employee, permanent male worker, permanent female worker, other than permanent male employee and finally other than permanent female worker. A similar requirement for detail abounds in other categories. For example, one disclosure asked for is: ‘Number of grievances filed by investors other than shareholders’. </Typography>


                                    <Typography variant='h6' className='black jst' gutterBottom> Other national market regulators could learn a lot from India’s BRSR. Several of them have yet to come up with mandatory disclosure requirements. The USA is the biggest name here; the US SEC has still not made reporting mandatory. China also does not have any mandatory requirements yet. A study by ECGI pointed out that only 29 countries had some degree of mandatory ESG disclosure regulation in 2022.   </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>In Jul’23, SEBI introduced an additional disclosure requirement for corporates, called the BRSR Core. Here SEBI has taken out some of the existing information points or Key Performance Indicators (KPIs) in BRSR and has introduced some new ones. </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>A key part of BRSR Core is the requirement of ‘reasonable assurance’. Note, this is a higher level of assurance than ‘limited assurance’. The requirement for ‘reasonable assurance’ in the context of ESG data appears to be a global first. Even the EU Taxonomy disclosures as per Regulation (EU) 2020/852 so far call for only limited assurance.  The European Commission has said it will adopt assurance standards for reasonable assurance no later than October 1, 2028, a timeline that clearly lags SEBI's starting point of FY24.  </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>Another aspect of BRSR Core is innovation; it has created some KPIs to align ESG to the Indian ethos and context. For example, SEBI has introduced the concept of carbon intensity adjusted for Purchasing Power Parity (PPP), which appears to be a global first. The concept exists for sovereign emissions, we are yet to see it for corporate level emissions.  </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom> It is indeed laudable that SEBI has chosen to become an ESG trendsetter, in terms of the pace of disclosures, the level of disclosures, and innovative parameters.  </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>If that is the direction SEBI has chosen to be in, we can suggest some other measures that SEBI could introduce.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>The biggest area to focus on could be Net Zero. SEBI needs to prod companies to set Net Zero targets. Indian companies have begun to do so. In fact, close to 200 Indian companies have declared Net Zero ambitions and are working with the global organization – Science Based Targets Initiative, or SBTi, to get their targets validated. Here we have another suggestion: India needs to set up a SBTi like Indian organization that can vet and validate Net Zero targets. This may not be SEBI’s domain, but it can nudge relevant government and industry platforms to come up with an Indian alternative to SBTi.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>Another area SEBI could turn its attention to is regulation similar to the EU’s  2020/852, which asks companies to report ‘taxonomy aligned’ information on Revenue, Capex, and Opex. This is a very important step the EU has taken; this type of information clearly shows how much of a company’s business stands on the right side of climate objectives.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>While corporate India may at times struggle to cope with the pace of change demanded by SEBI, the overall effect is likely to be beneficial in several ways; improved sustainability of their businesses and ability to raise capital will be two big benefits. We hope corporate boards can follow SEBI’s ESG directives in letter and spirit.
                                    </Typography>




                                </Box>

                            </Grid>
                        </Grid>
                    </Box>

                    <Box>
                        <Box className='graybbx' mt={2}>
                            <Grid container spacing={0} alignItems="center" justifyContent='center'>

                                <Grid item lg={4} md={4} sm={4} xs={6}>
                                    <a href="https://www.moneycontrol.com/news/opinion/india-emerging-as-a-global-trendsetter-in-esg-disclosures-11594431.html" target="_blank" rel="noopener noreferrer">
                                        <Box
                                            className="awardsindexcircle"
                                            style={{ backgroundImage: "url(" + MC + ")" }}
                                        ></Box>
                                    </a>
                                </Grid>

                            </Grid>
                        </Box>
                    </Box>


                    <Box mt={2}>
                        <NewsRoomBack />
                    </Box>

                </Container>
            </Box>





        </>
    );
}
