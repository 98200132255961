import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import TH from '../../../asset/images/media/the_hindu.webp';
import NewsRoomBack from '../../../Component/NewsRoomBack';
import { Helmet } from 'react-helmet';

export default function DPAPlansOverseasExpansion() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>Decimal Point Analytics Plans Global Expansion for 2024</title>
                <meta name="description" content="Experience global excellence with Decimal Point Analytics in 2024. Specializing in financial research, data analytics, and AI consulting for various industries." />
                <meta name="keywords" content="Decimal Point Analytics, overseas expansion, financial research, data analytics, AI consulting, global expansion, industry specialization, client-centric approach, talent acquisition, technology-driven solutions" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/decimal-point-analytics-plans-overseas-expansion" />

                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            <Box className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>


                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    News Room
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center'>
                                    Decimal Point Analytics plans overseas expansion
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>

                    <Box>
                        <Grid container direction="row"
                            justifyContent="center"
                            alignItems="center">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='whitebx'>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Decimal Point Analytics (DPA), a full-service management consulting firm specialising in Financial Research, Data Analytics & AI, is planning to expand its base internationally in 2024 to serve its clients better, a top executive said.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        “We are committed to serving our clients wherever they operate. Beyond our existing offices in India and strategic overseas locations, we will explore further global expansion to bring DPA’s expertise closer to international clients,” Shailesh Dhuri, Chief Executive Officer, Decimal Point Analytics said.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        He did not name the locations.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        He said, during this year the company would double down on understanding each client’s unique needs and crafting bespoke service packages that deliver maximum value.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        “This personalised approach will ensure our offerings are relevant and impactful,” he added.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Besides this, the company has decided to strengthen its specialisation in select industries like finance, CPG, and manufacturing. “This vertical focus will allow us to develop industry-specific solutions and insights that resonate deeply with our clients’ challenges,” Mr. Dhuri said.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        He also said that the company would prioritize on attracting and retaining top talent in data science, analytics, and finance to ensure its delivers the highest caliber of service to its clients.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Emphasizing that harnessing technology would remain the company’s key strategy to add substantial value to customers, he said through innovative solutions like Generative AI, Machine Learning, and automation, the company would aim to streamline processes, provide personalized experiences, and proactively address customer pain points.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        “This tech-driven approach will ensure efficient problem-solving, ultimately elevating customer satisfaction and fostering long-term relationships,” he added.
                                    </Typography>
                                </Box>

                            </Grid>
                        </Grid>
                    </Box>

                    <Box>
                        <Box className='graybbx' mt={2}>
                            <Grid container spacing={0} alignItems="center" justifyContent='center'>


                                <Grid item lg={4} md={4} sm={4} xs={6}>
                                    <a href="https://www.thehindu.com/business/decimal-point-analytics-plans-overseas-expansion/article67746121.ece" target="_blank" rel="noopener noreferrer">
                                        <Box
                                            className="awardsindexcircle"
                                            style={{ backgroundImage: "url(" + TH + ")" }}
                                        ></Box>
                                    </a>
                                </Grid>

                            </Grid>
                        </Box>
                    </Box>


                    <Box mt={2}>
                        <NewsRoomBack />
                    </Box>

                </Container>
            </Box>





        </>
    );
}
