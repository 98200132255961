import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Helmet } from 'react-helmet';

import Casestudyback from '../../../../Component/Casestudyback'

import Banner from "../../../../asset/images/insight/case_studies/banner_improving_oas_bond.webp"
import Infographics from "../../../../asset/images/insight/case_studies/improving_oas_bond_pricing.webp"
import { Link } from 'react-router-dom';



export default function ImprovingOASBondPricing() {



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet><meta charset="UTF-8" />
                <meta charset="UTF-8" />
                <title>"Boosting Credibility through Accurate OAS Bond Price Analysis"</title>
                <meta name="description" content="Discover how a global investment firm improved its bond pricing accuracy with advanced OAS models, boosting client credibility and operational efficiency" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/case-studies/improving-oas-bond-pricing-accuracy-by-25-for-a-global-investment-firm" />
            </Helmet>

            <Box id="case-studies" className='section sec_bg new_cs' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={3} alignItems="top" justifyContent='center'>
                        <Grid item lg={8} md={8} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Success Stories
                                </Typography>
                                <Typography className='mainhead blue al_center'>
                                    Improving OAS Bond Pricing Accuracy <span className='lower'>by 25% for a</span> Global Investment Firm
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Box >
                        <img src={Banner} className='w100' alt='Banner' />
                    </Box>


                    <Box className='whitebx'>
                        <Box>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Executive Summary
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                A global investment firm needed to enhance its OAS bond pricing to provide more accurate analyses for its clients. The firm's existing models lacked precision, leading to inconsistent pricing and reduced client confidence. By developing advanced OAS models, the firm improved bond pricing accuracy, boosted credibility, and delivered more reliable insights to its clients.
                            </Typography>
                        </Box>


                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                The Challenge
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                The investment firm encountered several issues with their bond pricing models:
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Inaccurate Bond Pricing:</strong>  Existing OAS models provided inconsistent and inaccurate bond price analyses.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>  Reduced Client Confidence: </strong> Inaccuracies in pricing affected client trust and credibility.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>  Manual Data Handling:  </strong>  Manual processes for adjusting bond prices were inefficient and error prone.
                                        </Typography>
                                    </li>
                                </ul>
                            </Typography>
                        </Box>

                        <Box mt={3} className='mob_card'>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Solution: Advanced OAS Bond Pricing Models
                            </Typography>

                            <Grid container direction="row"
                                justifyContent="center"
                                alignItems="center">

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className="al_center" my={3}>
                                        <img src={Infographics} className="w100" alt="Solution: Advanced OAS Bond Pricing Models" />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box mt={1}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Results
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Improved Bond Pricing Accuracy:  </strong> The advanced OAS models improved bond pricing accuracy by 25%.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Boosted Client Credibility:  </strong> Accurate pricing enhanced client trust and confidence.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Increased Operational Efficiency:  </strong> Automation reduced manual errors and improved process efficiency.
                                        </Typography>
                                    </li>
                                </ul>
                            </Typography>
                        </Box>

                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Key Takeaways
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Accurate OAS Models: </strong>  Advanced models provided precise bond price analyses, improving client satisfaction.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Automation for Efficiency:  </strong> Automated pricing adjustments reduced errors and improved efficiency.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Client Credibility: </strong>  Accurate and reliable pricing improved the firm’s credibility with clients.
                                        </Typography>
                                    </li>
                                </ul>

                            </Typography>

                            <Typography variant='h6' className='black jst' gutterBottom>
                                <Link to="/contact-us#contact_form" className="link">Contact us</Link> to discover tailored solutions for your investment strategies.
                            </Typography>

                        </Box>

                    </Box>


                    <Casestudyback />

                </Container>
            </Box>


        </>
    );
}