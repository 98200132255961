import { Container, Grid, Typography, Box, Divider } from "@mui/material";
import React from "react";

export default function SmartCloudAnnexure() {
  return (
    <>
      <Box className="section " component="section">
        <Container maxWidth="lg" className="bdr">
          <Grid container spacing={1} alignItems="center">
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box className="al_center ">
                <Typography variant="h6" className="bannerhead_3" gutterBottom>
                  {" "}
                  ANNEXURE{" "}
                </Typography>
                <Box mt={2}>
                  <Typography variant="h2" className="bannerhead_a1">
                    Terms of Subscription and Payment
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Divider />

      <Box id="eazydata">
        <Container maxWidth="lg">
          <Box className="jst" pt={1}>
            <Typography variant="h6" className="pagesubhead col1">
              1. General Terms
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints"> 1.1 </Typography>
            <Typography className="pagecontent jst">
              These Terms of Subscription and Payment (hereinafter referred to
              as <strong>“Terms”</strong>) are in addition to the Terms of
              Service (hereinafter referred to as{" "}
              <strong>“Terms of Service”</strong>) provided above. All the
              capitalized terms used herein and not defined shall have the same
              meaning as ascribed in the Terms of Service.
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints"> 1.2 </Typography>
            <Typography className="pagecontent jst">
              Kindly note that the Terms may be subject to change from time to
              time pursuant Our business needs, need for legal compliance and
              any other reason that We deem necessary. The most current version
              of these Terms will supersede all previous versions. Therefore, it
              is advised that You should read the most updated version of these
              Terms before completing any commercial transaction with the
              Company.
            </Typography>
          </Box>

          <Divider />

          {/* -------------------------------------------------------- */}

          <Box className="jst" pt={1}>
            <Typography variant="h6" className="pagesubhead col1">
              2. Subscription Models
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent subpoints jst">
              To avail the Service on the Platform, You shall create a user
              account (<strong>“Account”</strong>) on Our Platform. Once You
              have created an Account, We provide our Service under three (3)
              separate subscription models, which are detailed herein below:
            </Typography>
            <Typography>
              <a
                href="https://saas.decimalpointanalytics.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                saas.decimalpointanalytics.com{" "}
              </a>
            </Typography>
          </Box>
          <Box className="flx_fs">
            <Typography>
              <a
                href="https://saas.decimalpointanalytics.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                {"    "}
                saas.decimalpointanalytics.com{" "}
              </a>
            </Typography>
          </Box>

          {/* <Box className="flx_fs" pt={2}>
            <Typography className="subpointhdd subpoints"> 2.1 </Typography>
            <Typography className="subpointhdd">Basic Account</Typography>
          </Box>

          <Box className="flx_fs" pt={1}>
            <Typography className="pagecontent jst subpoints">
              {" "}
              2.1.1{" "}
            </Typography>
            <Typography className="pagecontent  jst">
              Once You have created Your Account, You shall automatically be
              assigned with a free/basic Account (
              <strong>“Basic Account”</strong>). Some of the key features of the
              Basic Account shall be as follows:
              <ol type="a">
                <li>
                  The Basic account is free forever and does not require sharing
                  of credit card information.
                </li>

                <li>
                  This Basic Account shall be accessible only by You and any
                  third-party access to the Basic Account shall be strictly
                  restricted. You shall be responsible for any and all
                  activities carried in Your Basic Account.
                </li>

                <li>
                  Under the Basic Account, you shall have access towards such
                  number of pipes, rows and validations and connectors as
                  specified in{" "}
                  <a
                    href="https://app.eazydata.ai/pricing"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    https://app.eazydata.ai/pricing{" "}
                  </a>
                  .
                </li>

                <li>
                  You understand that all Your unused rows from the preceding
                  month shall not be carried forward to the subsequent month.
                </li>

                <li>
                  In this Basic Account, You shall receive email alerts for Your
                  usage of Our Service and You will have global email support
                  but not live chat support or managed services support.
                </li>
              </ol>
            </Typography>
          </Box> */}

          {/* -------------------------------------------------------- */}

          {/* <Box className="flx_fs" pt={0}>
            <Typography className="subpointhdd subpoints"> 2.2 </Typography>
            <Typography className="subpointhdd">Standard Account</Typography>
          </Box>

          <Box className="flx_fs" pt={1}>
            <Typography className="pagecontent jst subpoints">
              {" "}
              2.2.1{" "}
            </Typography>
            <Typography className="pagecontent jst">
              You shall, from a Basic Account, have the right to update Your
              Account to a standard account (<strong>“Standard Account”</strong>
              ). You can upgrade Your Account to a Standard Account at{" "}
              <a
                href="https://app.eazydata.ai/pricing"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://app.eazydata.ai/pricing
              </a>{" "}
              . Upon your request for upgradation, your Standard Account will
              get activated immediately.
            </Typography>
          </Box>

          <Box className="flx_fs" pt={1}>
            <Typography className="pagecontent jst subpoints">
              {" "}
              2.2.2{" "}
            </Typography>
            <Typography className="pagecontent  jst">
              The following are some of the key points to be noted prior to
              requesting for upgradation into Standard Account:
              <ol type="a" className="subpoints">
                <li>
                  While making a new subscription, You may be charged with a
                  rate as may be available at{" "}
                  <a
                    href="https://app.eazydata.ai/pricing"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://app.eazydata.ai/pricing
                  </a>
                  . Further, this rate shall be subject to modification solely
                  based on our determination and discretion.
                </li>

                <li>
                  You shall be required to provide the Company with Your debit /
                  credit card details (<strong>“Payment Information”</strong>)
                  as shall be required by the Company. You shall entitled to a
                  free trial period (<strong>“Free Trial Period”</strong>).
                  During this Free Trial Period, We shall not charge any amount
                  from You and You shall have the option to cancel Your present
                  subscription during the Free Trial Period and automatically
                  return to Basic Account subscription model. If, during the
                  Free Trial Period, You do not cancel Your subscription then
                  You shall automatically be subject payment of rate as may be
                  available at{" "}
                  <a
                    href="https://app.eazydata.ai/pricing"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    https://app.eazydata.ai/pricing{" "}
                  </a>{" "}
                  for continuance of your subscription to the Standard Account.
                </li>

                <li>
                  The billing cycle would commence from the day You upgrade to
                  the Standard Account and after the end of Free Trial Period.
                </li>

                <li>
                  You shall be able to download invoices (if any) from our
                  website.
                </li>

                <li>
                  Your Standard Account can be accessed by multiple / unlimited
                  users on Your behalf.
                </li>

                <li>
                  Under the Standard Account, you shall have access towards such
                  number of pipes, rows and validations and connectors as
                  specified in{" "}
                  <a
                    href="https://app.eazydata.ai/pricing"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    https://app.eazydata.ai/pricing{" "}
                  </a>
                  .
                </li>

                <li>
                  You understand that all Your unused rows from the preceding
                  month shall not be carried forward to the subsequent month.
                </li>

                <li>
                  You shall be able to manage Your team based on roles assigned.
                </li>

                <li>
                  You will have global email support as well as live chat
                  support.
                </li>
              </ol>
            </Typography>
          </Box>

          <Box className="flx_fs" pt={1}>
            <Typography className="pagecontent jst subpoints">
              {" "}
              2.2.3{" "}
            </Typography>
            <Typography className="pagecontent  jst">
              Upgrade terms from Standard Account:
              <ol type="a" className="subpoints">
                <li>
                  From a Standard Account, You can upgrade to Managed Service
                  Account (as defined below) at any time.
                </li>

                <li>
                  The Managed Service Account will be accessible immediately
                  upon subscription. However, the provision of managed service
                  support may begin after you submit a request for the same and
                  provide all the necessary information. Please note that there
                  may be a specified waiting period from the date of
                  subscription to allow for the processing of the requisite
                  information provided by you.
                </li>
              </ol>
            </Typography>
          </Box>

          <Box className="flx_fs" pt={1}>
            <Typography className="pagecontent jst subpoints">
              {" "}
              2.2.4{" "}
            </Typography>
            <Typography className="pagecontent  jst">
              Downgrade terms from Standard Account:
              <ol type="a" className="subpoints">
                <li>
                  From a Standard Account, You shall also be able to downgrade
                  to a Basic Account by clicking on the button ‘Switch to’
                  available at{" "}
                  <a
                    href="https://app.eazydata.ai/pricing"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://app.eazydata.ai/pricing
                  </a>
                  .
                </li>

                <li>
                  The aforesaid downgrade, though requested at any time on the
                  portal, will be effective only at the end of existing billing
                  cycle and starting of new billing cycle. For example, if a
                  user is paying monthly has his billing cycle at 17th of each
                  month. Requesting on 10th Feb of a month to downgrade will
                  result in change of plan on 17th Feb. For a user with an
                  annual commitment and a subscription starting at 10th of May
                  2023 - a request to downgrade made in the month of September
                  will be effective on 9th May 2024 (after the end of the annual
                  commitment period). Thus, You will not be refunded any money
                  after you downgrade to a Basic Account.
                </li>

                <li>
                  While downgrading to a Basic Account, if You have multiple
                  users on Your account, all of such users will cease to have
                  access except You.
                </li>

                <li>
                  Upon downgrading, you understand that the run frequency of
                  your selected pipes shall be limited to 10 times.
                </li>
              </ol>
            </Typography>
          </Box>

          <Box className="flx_fs" pt={0}>
            <Typography className="subpointhdd subpoints"> 2.3 </Typography>
            <Typography className="subpointhdd">
              Managed Service Account
            </Typography>
          </Box>

          <Box className="flx_fs" pt={1}>
            <Typography className="pagecontent jst subpoints">
              {" "}
              2.3.1{" "}
            </Typography>
            <Typography className="pagecontent jst">
              You have the right to upgrade Your Account to a managed service
              account (<strong>“Managed Service Account”</strong>) from a Basic
              Account or a Standard Account. by clicking on the button available
              at{" "}
              <a
                href="https://app.eazydata.ai/pricing"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://app.eazydata.ai/pricing
              </a>{" "}
              .
            </Typography>
          </Box> */}

          {/* <Box className="flx_fs" pt={1}>
            <Typography className="pagecontent jst subpoints">
              {" "}
              2.3.2{" "}
            </Typography>
            <Typography className="pagecontent  jst">
              The following are some of the key points to be noted prior to
              requesting for upgradation into Manages Service Account:
              <ol type="a" className="subpoints">
                <li>
                  Upon providing Your intimation to upgrade Your Account to a
                  Managed Service Account, You shall be required to provide us
                  with Your credit card details as per our requirement. Further,
                  You will have to fill in a form provided by us with regard to
                  your requirements.
                </li>

                <li>
                  Upon submission, you shall, within a reasonable time period,
                  receive communication from SMARTPiPE team as to whether your
                  requirement can be met or not.
                  <Box className="flx_fs">
                    <Typography className="pagecontent subpoints al_right">
                      {" "}
                      -{" "}
                    </Typography>
                    <Typography className="pagecontent  jst">
                      In case of positive response, the process of onboarding
                      will be completed within such number of days as may be
                      intimated to you.
                    </Typography>
                  </Box>
                  <Box className="flx_fs" pt={1}>
                    <Typography className="pagecontent subpoints al_right">
                      {" "}
                      -{" "}
                    </Typography>
                    <Typography className="pagecontent  jst">
                      {" "}
                      In case your requirement cannot be processed at that given
                      time, you may be notified likewise and will be asked to
                      cancel your subscription, or the team can cancel your
                      subscription plan.
                    </Typography>
                  </Box>
                </li>

                <li>
                  The Managed Service Account can be accessed by multiple /
                  unlimited users on Your behalf.
                </li>

                <li>
                  For Your access to the Managed Service Account, You shall be
                  subject to payment of charges as per the rate available at{" "}
                  <a
                    href="https://app.eazydata.ai/pricing"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://app.eazydata.ai/pricing
                  </a>
                  . In case where you chose to upgrade to a Managed Service
                  Account from a Standard Account, you shall be charged, with an
                  applicable rate in the manner stated in{" "}
                  <a
                    href="https://app.eazydata.ai/pricing"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    https://app.eazydata.ai/pricing{" "}
                  </a>
                  , on and from the date of completion of the onboarding process
                  in accordance with clause 2.3.2. (b) above and on a pro rata
                  basis for that particular month, however, the payment for the
                  same shall be considered in the payment of next month’s
                  billing cycle. Further, this rate shall be subject to
                  modification solely based on our determination and discretion.
                </li>

                <li>
                  The first base price charge will be processed immediately upon
                  subscription. The user will have access to the account and can
                  start using the service right away. The start of Managed
                  Service Support may vary depending on when the user submits
                  the request and provides the necessary information.
                </li>

                <li>
                  The concerned payment / charge will be auto debited from your
                  credit card. You will get the invoices via email for every
                  payment and be able to download invoices (if any) from the
                  website.
                </li>

                <li>
                  In case the prices change upon management’s decision, You
                  shall be notified and will be charged increased rate for
                  remaining part of the upcoming billing cycle.
                </li>

                <li>
                  Under the Managed Service Account, you shall have access
                  towards such number of pipes, rows and validations and
                  connectors as specified in{" "}
                  <a
                    href="https://app.eazydata.ai/pricing"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    https://app.eazydata.ai/pricing{" "}
                  </a>
                  .
                </li>

                <li>
                  Under Managed Service Account, You shall be able to manage
                  your team based on roles assigned.
                </li>

                <li>
                  You will have global email support as well as live chat
                  support.
                </li>
              </ol>
            </Typography>
          </Box> */}

          {/* <Box className="flx_fs" pt={1}>
            <Typography className="pagecontent jst subpoints">
              {" "}
              2.3.3{" "}
            </Typography>
            <Typography className="pagecontent  jst">
              Downgrade Terms:
              <ul type="a">
                <li>
                  You shall be able to downgrade from Managed Service Account to
                  Basic account or Standard account by clicking on the
                  appropriate button available at{" "}
                  <a
                    href="https://app.eazydata.ai/pricing"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    https://app.eazydata.ai/pricing{" "}
                  </a>
                  .
                </li>

                <li>
                  The downgrade, though requested at any time on the portal,
                  will be effective at the end of existing billing cycle and
                  starting of new billing cycle. For example, a user paying
                  monthly has his billing cycle at 17th of each month.
                  Requesting on 10th Feb of a month to downgrade will result in
                  change of plan on 17th Feb. For a user with annual commitment
                  with subscription starting at 10th of May 2023, a request to
                  downgrade is made in the month of September, the change of
                  plan will happen on 9th May 2024. Thus, You will not be
                  refunded any money after You downgrade.
                </li>

                <li>
                  While downgrading to a Basic Account, if You have multiple
                  users on Your account, all of such users will cease to have
                  access except You.
                </li>

                <li>
                  Upon down gradation, you understand that the run frequency of
                  your selected pipes shall be limited to ten (10) number of
                  times.
                </li>
              </ul>
            </Typography>
          </Box> */}

          {/* -------------------------------------------------------- */}

          <Divider />

          {/* -------------------------------------------------------- */}

          <Box className="jst" pt={1}>
            <Typography variant="h6" className="pagesubhead col1">
              3. Payment of Fees: Taxes
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints"> 3.1 </Typography>
            <Typography className="pagecontent jst">
              For Your access of Our Services through the Standard Account or
              the Managed Service Account or the Enterprise Account (hereinafter
              collectively referred to as <strong>“Paid Service”</strong>), You
              hereby agree and acknowledge that:
              <ol type="i" className="subpoints">
                <li>
                  You shall fulfill Your obligation to pay for the Paid Service
                  by the date on which payment is due;
                </li>

                <li>
                  any Payment Information provided by You is true and accurate;
                </li>

                <li>
                  You are authorized to use the payment method as You have
                  provided;
                </li>

                <li>
                  We have permission to retain all payment information and
                  methods, submitted by You and Your issuing bank or the
                  applicable payment network; and
                </li>

                <li>
                  We are authorized to charge You for the Paid Service using the
                  established payment method and the information that is stored
                  in Your Account as on the applicable date of payment.
                </li>
              </ol>
            </Typography>
          </Box>

          <Box className="flx_fs" pt={1}>
            <Typography className="pagecontent jst subpoints"> 3.2 </Typography>
            <Typography className="pagecontent  jst">
              Your hereby understand that there may be certain taxes and
              statutory charges applicable with respect to Our Paid Services in
              the jurisdiction from where You access. The subscription fees as
              mentioned above is exclusive of all such applicable taxes and
              statutory charges. Therefore, You shall also be responsible for
              the payment of such applicable taxes and statutory charges
              separately. Additionally, You agree that You will be responsible
              for any credit card chargeback fees as well as any reasonable
              collection costs that We incur as a result of Your failure to pay
              on a timely basis.
            </Typography>
          </Box>

          <Box className="flx_fs" pt={1}>
            <Typography className="pagecontent jst subpoints"> 3.3 </Typography>
            <Typography className="pagecontent  jst">
              You understand, accept and agree that the Company is merely a
              facilitator providing an electronic and automated payment facility
              for commercial transaction on the Platform. Please note that,
              nothing mentioned herein shall imply the Company to be a banking
              or a financial service provider. While We select Our resellers and
              payment processors carefully and enter into detailed agreements
              imposing performance obligations (including confidentiality) on
              them, We cannot and do not guarantee their performance in relation
              to any commercial transaction under these Terms. Therefore, We
              shall not be liable towards any dispute, losses and/or damages
              occurring due to any acts or omission of the payment processors or
              reseller as the case maybe.
            </Typography>
          </Box>

          <Box className="flx_fs" pt={1}>
            <Typography className="pagecontent jst subpoints"> 3.4 </Typography>
            <Typography className="pagecontent  jst">
              All payments made by You against the Paid Services shall be
              compulsorily in United States Dollar (USD). The Platform will not
              facilitate transactions with respect to any other form of currency
              with respect to the commercial transactions made on Platform (or
              through any reseller or payment processor from which you may
              purchase the Paid Service). If any tax or charge applicable to a
              purchase is not charged by Us, You acknowledge that You are solely
              responsible for paying the tax or other such charge.
            </Typography>
          </Box>

          <Box className="flx_fs" pt={1}>
            <Typography className="pagecontent jst subpoints"> 3.5 </Typography>
            <Typography className="pagecontent  jst">
              Except as expressly provided herein these Terms, any and all
              amounts paid by You to the Company shall be non-refundable.
            </Typography>
          </Box>

          <Box className="flx_fs" pt={1}>
            <Typography className="pagecontent jst subpoints"> 3.6 </Typography>
            <Typography className="pagecontent  jst">
              Our subscription prices for a Paid Service do not include any of
              the third-party fees that You may incur in connection with using a
              Paid Service including, without limitation, fees charged by Your
              Internet access provider, any wireless carrier charges for
              cellular or data services and any other third-party applications
              or services with which You may use the Paid Service. You are
              independently responsible for selecting and managing the other
              services and paying their respective fees separately.
            </Typography>
          </Box>

          {/* -------------------------------------------------------- */}

          <Divider />

          {/* -------------------------------------------------------- */}

          <Box className="jst" pt={1}>
            <Typography variant="h6" className="pagesubhead col1">
              4. Changes in Fees
            </Typography>
          </Box>

          <Box className="flx_fs" pt={1}>
            <Typography className="pagecontent jst subpoints">
              {" "}
              &nbsp;{" "}
            </Typography>
            <Typography className="pagecontent  jst">
              We may change the price of any Paid Service from time to time and
              add or modify Our fees and charges for certain features to reflect
              a change in business or legal rules. In this regard, We will
              provide You with an notify you with regard to such applicable
              changes. Any increase in charges for the same Paid Service would
              not apply until the expiration of Your then current billing cycle,
              unless otherwise specifically provided in Our notice to You, and
              would become effective from the subsequent billing cycle.
            </Typography>
          </Box>

          {/* -------------------------------------------------------- */}

          <Divider />

          {/* -------------------------------------------------------- */}

          <Box className="jst" pt={1}>
            <Typography variant="h6" className="pagesubhead col1">
              5. Autorenewal
            </Typography>
          </Box>

          <Box className="flx_fs" pt={1}>
            <Typography className="pagecontent jst subpoints"> 5.1 </Typography>
            <Typography className="pagecontent  jst">
              We accept certain debit and credit cards as payment methods for
              Paid Services (each, an <strong>“Accepted Payment Method”</strong>
              ), subject to the procedures and rules contained in the present
              Terms and any applicable contract with the Accepted Payment
              Method. Accepted Payment Methods may vary on the basis of the Paid
              Service opted by You and may change from time to time. When You
              purchase with an Accepted Payment Method, the fees for Your Paid
              Service will be billed on or near to the date of Your purchase.
            </Typography>
          </Box>

          <Box className="flx_fs" pt={1}>
            <Typography className="pagecontent jst subpoints"> 5.2 </Typography>
            <Typography className="pagecontent  jst">
              If You purchase a Paid Service subscription that renews
              automatically, such as monthly or annually, You agree that we may
              process your Accepted Payment Method on each monthly, annual or
              other renewal term (based on the applicable billing cycle), on or
              near to the calendar day corresponding to the commencement of Your
              Paid Service subscription, as applicable. In addition, You agree
              that We have permission to retain and/or share with financial
              institutions and payment processing firms (including any
              institutions or firms We retain in the future), information
              regarding Your purchase and Your submitted payment information in
              order to process Your purchase, and to use the email address
              submitted by You in order to provide You with notices and
              disclosures relating to renewals and recurring charges.
            </Typography>
          </Box>

          {/* -------------------------------------------------------- */}

          <Divider />

          {/* -------------------------------------------------------- */}

          <Box className="jst" pt={1}>
            <Typography variant="h6" className="pagesubhead col1">
              6. Changes to Payment Methods and Account Information
            </Typography>
          </Box>

          <Box className="flx_fs" pt={1}>
            <Typography className="pagecontent jst subpoints"> 6.1 </Typography>
            <Typography className="pagecontent  jst">
              You shall be responsible for ensuring that Your Account
              information, including Your email address and all payment
              information (address, debit or credit card number and expiration
              date), is accurate and up to date. You may change this information
              at any time and upon any such material change You shall be
              responsible to inform the same to Us at the earliest possible. If
              under any circumstance, You fail to provide Us information
              regarding the material change within the reasonable time period,
              then You shall solely be liable for any and all of the liability
              the Company or You may face due to Your failure to fulfill Your
              obligation.
            </Typography>
          </Box>

          <Box className="flx_fs" pt={1}>
            <Typography className="pagecontent jst subpoints"> 6.2 </Typography>
            <Typography className="pagecontent  jst">
              If Your credit card expires or the information that You have
              provided to Us is otherwise invalid, or You make any changes to
              Your billing account too close in time to when We charge for any
              Paid Service subscription (so we cannot reasonably act on your
              changes prior to billing), then You understand that We may
              probably not be aware of this and We will bill the current charges
              on the card which We have on record. The continuation of Your Paid
              Service subscription constitutes Your authorization for Us to do
              so, and You remain responsible for any uncollected amounts and any
              charges incurred by You or Us as a result of billing to an invalid
              card.
            </Typography>
          </Box>

          {/* -------------------------------------------------------- */}

          <Divider />

          {/* -------------------------------------------------------- */}

          <Box className="jst" pt={1}>
            <Typography variant="h6" className="pagesubhead col1">
              7. Cancellation of a Paid Service Subscription
            </Typography>
          </Box>

          <Box className="flx_fs" pt={1}>
            <Typography className="pagecontent jst subpoints"> 7.1 </Typography>
            <Typography className="pagecontent  jst">
              You may elect to cancel a Paid Service at any time and such
              cancellation shall only be effective as of the end of the
              then-current billing period.
            </Typography>
          </Box>

          <Box className="flx_fs" pt={1}>
            <Typography className="pagecontent jst subpoints"> 7.2 </Typography>
            <Typography className="pagecontent  jst">
              Wherein if you cancel Your Account, it may take up to two (2) days
              to delete all your data provided to us.
            </Typography>
          </Box>

          <Box className="flx_fs" pt={1}>
            <Typography className="pagecontent jst subpoints"> 7.3 </Typography>
            <Typography className="pagecontent  jst">
              If You wish to upgrade Your existing Paid Service subscription to
              a more expensive level of Paid Service, and if You purchased Your
              current Paid Service directly from the Company (and not from a
              reseller or payment processor), We will permit You to cancel Your
              existing Paid Service subscription prior to the end of the
              subscription term. Additionally, it may be noted that where you
              have already opted for an annual plan and intend to shift to a
              monthly plan (before the completion of such annual plan), you
              shall be subject to reversal of all the discounts (which were
              provided to you with regard to the pricing of the annual plan) for
              the months in which the discounts was previously provided. In this
              regard, the Company shall have the right to charge additional
              amount for the foregoing months in order to successfully complete
              such reversal of discounts. Please be advised that we will not
              provide any refund, unless the same is expressly provided for
              under these present Terms. If You have purchased a Paid Service
              from a reseller or payment processor, You may be required to
              contact the reseller or payment processor to process Your
              cancellation request. This present clause shall be applicable only
              in case of upgradation of the Paid Service subscription.
            </Typography>
          </Box>

          <Box className="flx_fs" pt={1}>
            <Typography className="pagecontent jst subpoints"> 7.4 </Typography>
            <Typography className="pagecontent  jst">
              You shall have the right to cancel your Account at any time.
              However, if You are taking part in the Free Trial Period that
              enables You to use a Paid Service without charge, and such trial
              period converts into a Paid Service upon the expiration of the
              trial period, You shall be responsible to cancel the Paid Service
              prior to the end of the trial period in order to avoid incurring
              charges for the Paid Service.
            </Typography>
          </Box>

          <Box className="flx_fs" pt={1}>
            <Typography className="pagecontent jst subpoints"> 7.5 </Typography>
            <Typography className="pagecontent  jst">
              If You are the authorized legal representative for a deceased or
              legally incapacitated individual or a legal entity that has been
              formally dissolved or otherwise ceased to be authorized to act for
              his, her, or its own account (without opportunity for revival),
              and You wish to cancel a current Paid Service subscription for
              such individual or entity, then You must notify Us (according to
              the requirements in the Terms of Use), identify your validity in
              question and request cancellation of the Paid Service. We will
              cancel the Paid Service subscription only if You demonstrate to
              Our satisfaction Your legal authority to act and be legally
              responsible for such individual or entity and Your ability to
              validate the details of the payment method used for such Paid
              Service.
            </Typography>
          </Box>

          <Box className="flx_fs" pt={1}>
            <Typography className="pagecontent jst subpoints"> 7.6 </Typography>
            <Typography className="pagecontent  jst">
              We may, at our discretion cancel any Paid Service subscription
              that You have purchased if you fail to pay the applicable
              subscription fees and any applicable taxes in full and in a timely
              manner according to these Terms. We may, in our discretion, elect
              not to cancel a Paid Service subscription immediately for
              non-payment, in which case You shall continue to be responsible
              for the fees due for the duration of the applicable subscription.
              In addition, if You fail to pay for a Paid Service, We may elect
              to suspend Your rights to use that Paid Service or We may change
              the type of privileges You have to a non-paid version of the
              service. We may provide You notice for any failure to make a
              payment to Us in full and on time, but we are not obligated to do
              so.
            </Typography>
          </Box>

          {/* -------------------------------------------------------- */}

          <Divider />

          {/* -------------------------------------------------------- */}

          <Box className="jst" pt={1}>
            <Typography variant="h6" className="pagesubhead col1">
              8. Costs of Collection; Credit Card Chargebacks
            </Typography>
          </Box>

          <Box className="flx_fs" pt={1}>
            <Typography className="pagecontent jst subpoints"> 8.1 </Typography>
            <Typography className="pagecontent  jst">
              In addition to the published fees for a Paid Service, You agree to
              pay any reasonable costs which we incur to collect with regard to
              any unpaid or past due amounts, including reasonable attorneys'
              fees and other associated costs. Delayed payments may, at our
              discretion, bear interest at the rate of{" "}
              <strong>
                12% per annum or the highest rate permitted under applicable
                law, whichever is less
              </strong>
              , from the payment due date until paid in full.
            </Typography>
          </Box>

          <Box className="flx_fs" pt={1}>
            <Typography className="pagecontent jst subpoints"> 8.2 </Typography>
            <Typography className="pagecontent  jst">
              If You purchase a Paid Service subscription with a credit card and
              then subsequently request Your credit card issuer to reverse that
              payment and We are charged a fee that may be a significant
              multiple of the amount which We initially received. Accordingly,
              in order to enable You to pay relatively minor fees with a credit
              card, You acknowledge and agree that We reserve the right to
              suspend Your access to Your Account until such time as You
              reimburse Us of the fee which We were charged by the card issuer.
            </Typography>
          </Box>
        </Container>
      </Box>
    </>
  );
}
