import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import tech1 from "../../asset/images/aboutus/tech1.svg";
import tech2 from "../../asset/images/aboutus/tech2.svg";
import tech3 from "../../asset/images/aboutus/tech3.svg";
import tech4 from "../../asset/images/aboutus/tech4.svg";
import tech5 from "../../asset/images/aboutus/tech5.svg";
import tech6 from "../../asset/images/aboutus/tech6.svg";
import banenr2 from "../../asset/images/animation/datasolutionmid.webp";

import machine_mind from "../../asset/images/new_banner/machine_mind_banner.webp"
import { Helmet } from 'react-helmet';

export default function Technologyedge() {



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);





    return (
        <>
            <Helmet>
                <title>AI & BI Technology in Financial Services | Decimal Point Analytics </title>
                <meta name="description" content="We assist financial firms by automating research intelligence extraction from vast structured and unstructured data using algorithmic solutions." />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/technologyedge" />
            </Helmet>


            {/* <Box className='mainsection' component='section'>
                <Container maxWidth='lg'>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item lg={5} md={6} sm={6} xs={12} data-aos="fade-up">
                            <Box>
                                <Typography variant='h1' className='blue'>
                                    Machine Mind Meld
                                </Typography>

                                <Typography variant='h4' className='black'>
                                    Using AI and BI to glean insights from Structured / Unstructured data
                                </Typography>

                            </Box>
                        </Grid>
                        <Grid item lg={7} md={6} sm={6} xs={12} data-aos="fade-up">
                            <Box className='al_right'>
                                <img src={indexbanner} className='topbanner' alt='Index Banner' />
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box> */}

            <Box className="banner_bx">
                <Box style={{ backgroundImage: "url(" + machine_mind + ")" }} className="banner_img">
                    <Box className="img_over bl_overlay">
                        <Grid container spacing={0} alignItems="center" justifyContent='center'>
                            <Grid item lg={3} md={4} sm={5} xs={8}>
                                <Box className="al_center">
                                    <Typography className="white" variant="h1">Machine Mind Meld</Typography>
                                    <Box mt={'10px'}>
                                        <Typography className="white" variant="h4">Using AI and BI to Glean Insights From Structured / Unstructured Data</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>

            <Box className='section2 sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>

                    {/* <Grid container spacing={1} alignItems="top">
                        <Grid item lg={12} md={12} sm={12} xs={12} data-aos="fade-up">
                            <Box className='al_center'>
                                <Typography variant='h2' className='black'>
                                    The Technology Edge
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid> */}


                    <Grid container direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12} data-aos="fade-up">
                            <Box className="al_center synopsic_bx">
                                <Typography variant='h6' className='black jst'>
                                    We strive to help firms operating in the financial services industry with algorithmic solutions which automate the extraction of research intelligence from an ocean of data, both structured and unstructured. We use the tools of machine learning and big data to glean insights that human researchers cannot independently generate! Our cutting-edge technologies are designed and delivered by a team of professionals with deep expertise in data, advanced statistics and large scale programming, as well as substantial experience of the financial markets.
                                </Typography>
                            </Box>

                        </Grid>
                    </Grid>
                </Container>
            </Box>



            <Box className='section mob_card' component='section'>
                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={3} alignItems="center">

                        <Grid item lg={3} md={3} sm={12} xs={12}>
                            <Grid container spacing={3} alignItems="center">
                                <Grid item lg={12} md={12} sm={12} xs={12} data-aos="fade-up">
                                    <Box className='boxbg'>
                                        <Box className='al_center'>
                                            <img src={tech1} className='allic' alt='Tech1' />
                                        </Box>
                                        <Box>
                                            <Typography variant='h4' className='black al_center'>Application Development</Typography>
                                            <Typography variant='h6' className='black al_center'>Application Development and Support across multiple technology and asset classes</Typography>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item lg={12} md={12} sm={12} xs={12} data-aos="fade-up">
                                    <Box className='boxbg'>
                                        <Box className='al_center'>
                                            <img src={tech2} className='allic' alt='Tech2' />
                                        </Box>
                                        <Box>
                                            <Typography variant='h4' className='black al_center'>Automation</Typography>
                                            <Typography variant='h6' className='black al_center'>Automation of research processes and reporting</Typography>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item lg={12} md={12} sm={12} xs={12} data-aos="fade-up">
                                    <Box className='boxbg'>
                                        <Box className='al_center'>
                                            <img src={tech3} className='allic' alt='Tech3' />
                                        </Box>
                                        <Box>
                                            <Typography variant='h4' className='black al_center'>Web Crawlers</Typography>
                                            <Typography variant='h6' className='black al_center'>Web Crawlers (including Real-time data fetching, mining and classification)</Typography>
                                        </Box>
                                    </Box>
                                </Grid>


                            </Grid>

                        </Grid>

                        <Grid item lg={6} md={6} sm={12} xs={12} data-aos="fade-up">
                            <Box className='al_center'>
                                <img src={banenr2} className='topbanner2' alt='Banner2' />
                            </Box>
                        </Grid>

                        <Grid item lg={3} md={3} sm={12} xs={12}>
                            <Grid container spacing={3} alignItems="center">
                                <Grid item lg={12} md={12} sm={12} xs={12} data-aos="fade-up">
                                    <Box className='boxbg'>
                                        <Box className='al_center'>
                                            <img src={tech4} className='allic' alt='Tech4' />
                                        </Box>
                                        <Box>
                                            <Typography variant='h4' className='black al_center'>Artificial Intelligence </Typography>
                                            <Typography variant='h6' className='black al_center'>Machine Learning (ML), Natural Language Processing (NLP)</Typography>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item lg={12} md={12} sm={12} xs={12} data-aos="fade-up">
                                    <Box className='boxbg'>
                                        <Box className='al_center'>
                                            <img src={tech5} className='allic' alt='Tech5' />
                                        </Box>
                                        <Box>
                                            <Typography variant='h4' className='black al_center'>Big Data</Typography>
                                            <Typography variant='h6' className='black al_center'>    creating pioneering products and improving current processes </Typography>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item lg={12} md={12} sm={12} xs={12} data-aos="fade-up">
                                    <Box className='boxbg'>
                                        <Box className='al_center'>
                                            <img src={tech6} className='allic' alt='Tech6' />
                                        </Box>
                                        <Box>
                                            <Typography variant='h4' className='black al_center'>Support </Typography>
                                            <Typography variant='h6' className='black al_center'> Internet is emerging as an important source of market dynamics information.</Typography>
                                        </Box>
                                    </Box>
                                </Grid>


                            </Grid>
                        </Grid>

                    </Grid>
                </Container>
            </Box>


        </>
    );
}
