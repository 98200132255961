import { Container, Grid, Typography, Box, Stepper, Step, StepLabel, StepContent } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import SBALoan from "../../asset/images/new_banner/banner_sba_loan_process.webp"

import ProductCTAA from '../../Component/ProductCTAA';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SBALoanUnderwritingFAQ from './SBALoanUnderwriting_Component/SBALoanUnderwritingFAQ';

import LoanUnderwriting from "../../asset/images/innovation/sba/sba_loan_process.webp"
import SBALoanUnderwritingContactUs from '../../Component/forms/SBALoanUnderwritingContactUs';

const steps = [
    {
        label: 'Loan Eligibility and Structuring',
        description: 'During this phase, we assist lenders in determining whether a potential transaction is eligible for the SBA 7(a) program and provide advice on structuring the transaction to fit SBA parameters. This phase is optional and available as needed.',
    },
    {
        label: 'Intake and Pre-Assessment',
        description: 'Once a new transaction is submitted, our Underwriting Manager conducts a preliminary review to ensure that sufficient information is available for formal analysis and underwriting.',
    },
    {
        label: 'Credit Analysis and Memo',
        description: 'Our underwriters perform a detailed credit analysis and prepare a memo summarizing the findings and recommendations.',
    },
    {
        label: 'Lender Approval',
        description: 'In the final phase, the lender reviews the underwriting memo and makes the approval decision.',
    },
];

const HeadingComponent = ({ heading, content }) => {
    return (
        <>
            <Box className='flx_fs' pt={1}>
                <CheckCircleIcon fontSize='small' className='blue typo-start-icon' />
                <Box>
                    <Typography variant='h6' className='black jst mb0'>
                        <strong>{heading}:</strong> {content}
                    </Typography>
                </Box>
            </Box>
        </>
    )
}

export default function SBALoanUnderwritingOutsourcing() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);



    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>Outsource SBA Loan Underwriting | Decimal Point Analytics</title>
                <meta name="description" content="Maximize profitability and ensure compliance by outsourcing your SBA loan underwriting to Decimal Point Analytics. Tailored solutions for efficient SBA lending." />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/sba-loan-underwriting-outsourcing" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            <Box className="banner_bx">
                <Box style={{ backgroundImage: "url(" + SBALoan + ")" }} className="banner_img">
                    <Box className="img_over bl_overlay">
                        <Grid container spacing={0} alignItems="center" justifyContent='center'>
                            <Grid item lg={6} md={7} sm={7} xs={11}>
                                <Box className="al_center" mt={3}>
                                    <Typography className="white" variant="h1">SBA Loan Underwriting Process | Decimal Point Analytics</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>

            <Box className='section2 sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>
                    <Grid container direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className="al_center synopsic_bx">
                                <Box className='al_center'>
                                    <Typography variant='h2' className='black'>
                                        Seamlessly Scale SBA Loan Production  <span className='lower'>with </span> Decimal Point Analytics
                                    </Typography>
                                </Box>
                                <Typography variant='h6' className='black jst' gutterBottom>
                                    Decimal Point Analytics empowers SBA loan service providers to underwrite SBA loans at scale, leveraging our specialized Loan Underwriting services. As a leading SBA Lender Service Provider (LSP), we partner with lenders across the nation to ensure confidence in the  <Link to="/small-business-administration" className='link'>SBA loan underwriting process</Link>, providing premium customer support for borrowers. Our commitment is to help you underwrite with confidence, safeguard compliance, and offer tailored solutions to your borrowers.
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box id='sba_solutions' className='section mob_card' component='section'>
                <Container maxWidth='lg' className='bdr'>
                    <Grid container spacing={3} alignItems="center" justifyContent='center'>

                        <Grid container spacing={1} alignItems="top">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='al_center'>
                                    <Typography variant='h2' className='black'>
                                        Benefits <span className='lower'>of</span> Leveraging <span className='lower'>an</span> LSP <span className='lower'>for</span> SBA Loan Underwriting
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h6' className='black jst'>
                                    At Decimal Point Analytics, we offer underwriting services designed to provide lending institutions with the expertise, infrastructure, and competitive edge necessary to excel in the SBA lending space. Our clients benefit from a fully staffed, fully compliant SBA team ready to facilitate immediate growth in SBA lending.
                                </Typography>

                                <Typography variant='h6' className='black jst'>
                                    <strong>Here's how we can help:</strong>
                                </Typography>
                            </Box>

                            <Box>
                                <Typography className="blue" variant="h6">

                                    <HeadingComponent heading={"Maximize Profitability with a Variable-Cost Solution"} content={"SBA lending can be a profitable component of your institution by leveraging our outsourced SBA underwriting services. Our team is well-versed in the SBA loan underwriting timeline and process, ensuring efficiency without overextending your internal resources. By partnering with us, you can offer SBA loans to your borrowers without taxing your team."} />

                                    <HeadingComponent heading={"Ensure SBA Compliance"} content={"The SBA loan underwriting process is highly regulated and constantly evolving. Our underwriting team stays at the forefront of SBA procedural changes, providing in-depth guidance to navigate the ever-changing SBA landscape. We help protect the interests of both borrowers and lenders by ensuring full compliance with the latest SBA standards."} />

                                    <HeadingComponent heading={"Tailored Solutions"} content={"Our approach is customized to suit your specific requirements in SBA lending. We understand the unique needs of your business and tailor our solutions accordingly, ensuring that you receive the support that aligns with your goals."} />

                                    <HeadingComponent heading={"Expert Team"} content={"Our seasoned professionals excel in SBA lending practices, including loan underwriting support and loan processing outsourcing. We provide comprehensive support at every stage of the lending process, helping you manage everything from loan application to approval."} />

                                    <HeadingComponent heading={"Innovation-Driven"} content={"We are constantly exploring and integrating the latest technological advancements to keep your SBA lending operations ahead of the curve. By staying at the forefront of innovation, we ensure that your lending process is both efficient and effective."} />

                                    <HeadingComponent heading={"Client-Centric Approach"} content={"Your success is our priority. We are committed to providing transparent, efficient, and supportive SBA lender services to help you achieve your financial goals. Our client-centric approach ensures that you receive the highest level of service and support throughout your lending journey."} />

                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='flx_center mt16'>
                                <ProductCTAA allignment="flx_fs" cta_btn_name="Start Now" pageVisited="SBA Loan Underwriting Process | Decimal Point Analytics" />
                            </Box>
                        </Grid>

                    </Grid>
                </Container>
            </Box>

            <Box id='sba_solutions' className='section sec_bg mob_card' component='section'>
                <Box className='over'></Box>
                <Container maxWidth='lg' className='bdr' style={{ position: 'relative', }}>

                    <Box>
                        <Grid container spacing={3} alignItems="top">

                            <Grid container spacing={1} alignItems="top">
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className='al_center'>
                                        <Typography variant='h2' className='black'>
                                            The SBA Loan Underwriting Process
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box>
                                    <Typography variant='h6' className='black al_center'>
                                        Our SBA Loan Underwriting Process is divided into four key phases to ensure a thorough and compliant approach:
                                    </Typography>
                                </Box>

                                <Grid container spacing={3} alignItems="top">
                                    <Grid item lg={4} md={4} sm={5} xs={12}>
                                        <Box className="al_center" >
                                            <img src={LoanUnderwriting} className="w100" alt="The SBA Loan Underwriting Process" />
                                        </Box>
                                    </Grid>

                                    <Grid item lg={8} md={8} sm={7} xs={12}>
                                        <Box>
                                            <Stepper activeStep={-1} orientation="vertical">
                                                {steps.map((step, index) => (
                                                    <Step key={index} expanded>
                                                        <StepLabel>
                                                            <Typography className='blue fw5'>{step.label}</Typography>
                                                        </StepLabel>
                                                        <StepContent>
                                                            <Typography className='black'>{step.description}</Typography>
                                                        </StepContent>
                                                    </Step>
                                                ))}
                                            </Stepper>
                                        </Box>
                                    </Grid>
                                </Grid>

                            </Grid>

                        </Grid>
                    </Box>
                </Container >

            </Box>

            <Box id='sba_solutions' className='section mob_card' component='section'>
                <Container maxWidth='lg' className='bdr'>
                    <Grid container spacing={3} alignItems="center" justifyContent='center'>

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='al_center'>
                                <Typography variant='h2' className='black'>
                                    Getting Started with Decimal Point Analytics
                                </Typography>
                            </Box>

                            <Box pt={1}>
                                <Typography variant='h4' className='blue jst'>
                                    Ready <span className='lower'>to scale your</span> SBA <span className='lower'>loan production with confidence?</span>
                                </Typography>

                                <Typography variant='h6' className='black jst'>
                                    Partner with Decimal Point Analytics for expert Loan Underwriting services tailored to your needs. Whether you’re navigating the SBA loan credit requirements or seeking to streamline your SBA loan underwriting process, our team is here to support your growth.
                                </Typography>
                            </Box>
                        </Grid>

                    </Grid>

                    <Box>
                        <SBALoanUnderwritingContactUs />
                    </Box>

                </Container>
            </Box>

            <Box id='sba_solutions' className='section sec_bg ' component='section'>
                <Container maxWidth='lg' className='bdr'>
                    <SBALoanUnderwritingFAQ />
                </Container>
            </Box>




        </>
    );
}
