import React, { Component } from "react";
import { Button, Container, Grid, Typography, Box, } from '@material-ui/core';
import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';


import web3 from "../../../asset/images/media/event/webinar3.webp";


export class VideoModal extends Component {

  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }


  render() {
    return (
      <>

        <Box className='mainsection' component='section' id='top'>
          <Container maxWidth='lg'>
            <Grid container spacing={1} alignItems="center">
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box>
                  <Typography variant='h3' className='skblue al_center'>
                    Webinar : Vol 1
                  </Typography>
                  <Typography variant='h1' className='blue al_center'>
                    Augmented Research - Webinar
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>



        <Box className='section sec_bg' component='section'>
          <Container maxWidth='lg' className='bdr'>
            <Grid container spacing={3} alignItems="center">
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Box>
                  <Typography variant='h6' className='black jst'>
                    This webinar 'Augmented Research-leveraging computational decision making' covers the company’s proprietary approaches towards utilizing Big Data, Machine Learning, etc. in traditional financial research and seeing the 'Big Picture' amidst exploding data on the Internet.
                  </Typography>

                </Box>
                <Box className='al_left' mt={3}>
                  <Link to='/webinar'>
                    <Button variant="contained" className='dpabtn_gr' startIcon={<ArrowBackIcon />}>
                      Back to Webinar
                    </Button>
                  </Link>
                </Box>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Box className='al_center' style={{ position: 'relative', }}>
                  <Box className='videobg_gr'></Box>
                  <Box className='videobga' style={{ backgroundImage: 'url(' + web3 + ')', }}>
                    <button onClick={this.openModal} class="pulse-button"></button>
                  </Box>
                </Box>

                <ModalVideo
                  channel="youtube"
                  isOpen={this.state.isOpen}
                  videoId="b7ziNDcYmAE"
                  onClose={() => this.setState({ isOpen: false })}
                />


              </Grid>
            </Grid>
          </Container>
        </Box>

      </>
    );
  }
}

export default VideoModal;
