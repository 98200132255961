import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Carousel from "react-elastic-carousel";

import thePrint from '../../../asset/images/media/the_print.webp';
import ANI from '../../../asset/images/media/ani.webp';
import Web_In from '../../../asset/images/media/web_india.webp';

import News1 from '../../../asset/images/media/press_release/news1.webp';
import News2 from '../../../asset/images/media/press_release/news2_a.webp';
import News4 from '../../../asset/images/media/press_release/news4.webp';
import News5 from '../../../asset/images/media/press_release/news5_a.webp';
import NewsRoomBack from '../../../Component/NewsRoomBack';
import { Helmet } from 'react-helmet';



export default function Inauguration_Nashik() {



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);



    return (
        <>

            <Helmet>
                <title>Big Data and Analytics - The Future for Sustainable Development | DPA</title>
                <meta name="description" content="Decimal Point Analytics inaugurates its 100% green energy office in Nashik, fostering a commitment to sustainability & professional excellence. Learn more." />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/decimal-point-analytics-inaugurated-its-green-energy-office-in-nashik" />
            </Helmet>


            <Box className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>


                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    News Room
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center'>
                                    Decimal Point Analytics inaugurated its 100% green energy office in Nashik
                                </Typography>

                            </Box>
                        </Grid>
                    </Grid>



                    <Box>
                        <Grid container direction="row"
                            justifyContent="center"
                            alignItems="center">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='whitebx'>


                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Decimal Point Analytics (DPA) inaugurates new green energy office space in Nashik. Speaking on the new office launch Mr. Shailesh Dhuri, CEO of Decimal Point Analytics said, "We believe our green energy office space would help us contribute to our commitment towards Net Zero emissions alongside cultivating a positive and professional work environment for our colleagues."
                                    </Typography>



                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        The greatest impact on employees and citizens of every city has been on stake due to the recent layoffs and finding a job has never been as hard as it has been after the outbreak of Covid pandemic. The event on career and Job opportunities held at Nashik by Decimal Point Analyst with an objective of complementing the talents of young people should remain uncompromised. Decimal Point Analyst offers job and career opportunities to empower young Middle-Class Youth to, aspirants of Data Analysts, bring both Job Seekers and Talents to be part of Nashik known for its simple living with huge success to the upcoming generation. The launching ceremony at Decimal Point Analytics, Tidke Colony, Hari Narayan Kute Marg, Nashik and the ceremony was inaugurated by the hand of Shailesh Dhuri (CEO – Decimal Point Analyst) at Nashik on January 13 from 12 pm to 5 pm.
                                    </Typography>



                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        The event has been successfully impactful with the presence of the Special guest MP Hemant Godse. Hemant Godse sharing his opinion states that “Past years has always brought thought to us of how having IT Park in Nasik can stand as an drastic factor to the city and now through Decimal point Analyst we have got this opportunity to build Nasik place where students and potential candidates will never have to migrate to Pune or Bangalore in search of Job. I with all pride can state the initiative taken by DPA with recruiting 400 candidates and also targeting more than 4000 will bring IT sector development and create the connectivity and infrastructure for IT companies to shift to Nashik automatically. The Industry minister and state government of Maharashtra is looking for 100 acres of land for IT parks and the work is under progress. This impactful change has been successful with the help of decimal point Analyst.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        DPA puts out an effort to connect organisations and businesses to those seeking employment opportunities. The Workforce organized a panel discussion on Opportunities in Data Analytics, AI and ML.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Also Distinguished Panelists’ association with the experts from this field, below mentioned through panel discussion, enlightened us with the world changing towards digitising with data analytics, Artificial Intelligence, Machine Learning are the current career opportunities that seem to rule in the coming years. For the youth to understand the importance of this new stream. to provide real-world insights on various applications of big data analytics , artificial intelligence and machine learning and how usage of fuel can lead to better decision-making.
                                    </Typography>


                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        <ul>
                                            <li>Dr Aurangabadkar (J D C Bytco IMR) </li>
                                            <li>Neelkanth Nikam (Guru Gobind Singh College of Engineering and Research Centre, Nashik )  </li>
                                            <li>Dr Wani (MET’s Institute of Engineering [ MET ], Nashik ) 5 Dr Dipak Patil (Sandip Institute of Engineering and Management – [ SIEM ], Nashik )  </li>
                                            <li> Dr Shirish Sane (K K Wagh Inst. of Engineering Education & Research – [ KKWIEER], Nashik ) </li>
                                        </ul>
                                    </Typography>




                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Being India’s most trusted research and data analytics firm for financial markets, announces the opening of a new office in Nashik. This is the second office opened this year after the successful launch of the Gift City office near Gandhinagar in Gujarat. Along with offices in Mumbai and Gift City, the new office will enable comprehensive services for the company’s global clients.Decimal Point Analytics’ business has grown exponentially over the years, opening new offices to more efficiently meet the growing needs of clients.
                                    </Typography>


                                    <Typography variant='h6' className='blue jst' gutterBottom>
                                        “Data is the oil required in almost every fuel”
                                    </Typography>



                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Dhuri announces, “The special feature of this office is that it will be a 100 per cent green office, with the electricity used for consumption coming from zero-carbon renewable energy.”
                                    </Typography>


                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Decimal Point Analytics was formed in 2003 by senior professionals from the Indian Capital Markets and is a global provider of Research and Data Analytics products and services, including Machine Learning and Artificial Intelligence-based solutions. Key client categories include Data Providers, Asset Management Companies, Private Equity Firms, Hedge Funds, Banks, Insurance Companies, and Broking Houses. DPA’s background and understanding of Financial Research and its strengths in Technology provide it an edge in effectively understanding and solving client problems. DPA has recently raised a round of funding from marquee institutional investors in the Middle east to support its decades-long profitable growth journey.
                                    </Typography>



                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        To conclude, all the speakers had one common thing to say – Build a town of growth and employment to create a foundation.
                                    </Typography>




                                    <Grid container spacing={1} alignItems="center" justifyContent='center'>
                                        <Grid item lg={12} md={12} sm={12} xs={12}>



                                            <Carousel
                                                itemsToShow={1}
                                                animateOut="slideOutDown"
                                                animateIn="flipInX"
                                            >
                                                <Box className="boxcontent">
                                                    <Box mt={4}>
                                                        <img src={News1} style={{ width: "500px" }} alt="Comma" />
                                                    </Box>
                                                </Box>

                                                <Box className="boxcontent">
                                                    <Box mt={4}>
                                                        <img src={News2} style={{ width: "500px" }} alt="Comma" />
                                                    </Box>
                                                </Box>

                                                {/* <Box className="boxcontent">
<Box mt={4}>
<img src={News3} style={{ width: "500px" }} alt="Comma" />
</Box>
</Box> */}

                                                <Box className="boxcontent">
                                                    <Box mt={4}>
                                                        <img src={News4} style={{ width: "500px" }} alt="Comma" />
                                                    </Box>
                                                </Box>

                                                <Box className="boxcontent">
                                                    <Box mt={4}>
                                                        <img src={News5} style={{ width: "500px" }} alt="Comma" />
                                                    </Box>
                                                </Box>

                                                {/* <Box className="boxcontent">
<Box mt={4}>
<img src={News6} style={{ width: "500px" }} alt="Comma" />
</Box>
</Box> */}
                                            </Carousel>


                                        </Grid>
                                    </Grid>




                                </Box>

                            </Grid>
                        </Grid>
                    </Box>

                    <Box>
                        <Grid container spacing={0} alignItems="center">
                            <Grid item lg={12} md={12} sm={12} xs={12} data-aos="fade-up">
                                <Box className='graybbx' mt={2}>
                                    <Grid container spacing={0} alignItems="center">

                                        <Grid item lg={4} md={4} sm={4} xs={6}>
                                            <a href="https://theprint.in/ani-press-releases/decimal-point-analytics-dpa-with-400-candidates-targets-for-4000-mega-job-and-career-opportunities/1321009/" target="_blank" rel="noopener noreferrer">
                                                <Box
                                                    className="awardsindexcircle"
                                                    style={{ backgroundImage: "url(" + thePrint + ")" }}
                                                ></Box>
                                            </a>
                                        </Grid>



                                        <Grid item lg={4} md={4} sm={4} xs={6}>
                                            <a href="https://www.aninews.in/news/business/business/decimal-point-analytics-dpa-with-400-candidates-targets-for-4000-mega-job-and-career-opportunities20230118122601/" target="_blank" rel="noopener noreferrer">
                                                <Box
                                                    className="awardsindexcircle"
                                                    style={{ backgroundImage: "url(" + ANI + ")" }}
                                                ></Box>
                                            </a>
                                        </Grid>
                                        <Grid item lg={4} md={4} sm={4} xs={6}>
                                            <a href="https://news.webindia123.com/news/articles/Business/20230118/4028012.html" target="_blank" rel="noopener noreferrer">
                                                <Box
                                                    className="awardsindexcircle"
                                                    style={{ backgroundImage: "url(" + Web_In + ")" }}
                                                ></Box>
                                            </a>
                                        </Grid>


                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>


                    <Box mt={2}>
                        <NewsRoomBack />
                    </Box>

                </Container>
            </Box>





        </>
    );
}
