import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Eventback from '../../../Component/Eventback'
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';


export default function FinovateFall2024() {


    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>Nilanjan Das at FinovateFall 2024</title>
                <meta name="description" content="Highlights from Nilanjan Das at FinovateFall 2024, where he engaged with industry leaders on fintech trends and collaboration opportunities. Connect with us to learn more!" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/finovate-fall-2024-connecting-with-industry-leaders" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>



            <Box id='event-details' className='section sec_bg' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={8} md={8} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Events
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center'>
                                    Finovate Fall 2024: Connecting with Industry Leaders
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>

                    <Box className='whitebx'>

                        <Typography variant='h6' className='black jst'>
                            At <strong>FinovateFall 2024</strong>, our Executive Vice President of Sales, <strong>Nilanjan Das, CFA,</strong> engaged with industry leaders and explored the latest trends shaping the future of financial technology.
                        </Typography>

                        <Typography variant='h6' className='black jst'> This premier fintech event featured innovative presentations and discussions that highlighted cutting-edge solutions in the fintech space. Nilanjan connected with fellow professionals, exchanged valuable insights, and explored new avenues for collaboration that can propel the industry forward.
                        </Typography>

                        <Typography variant='h6' className='black jst'> <Link to="/contact-us#contact_form" className='link'>Connect</Link> with us to learn how <strong>Decimal Point Analytics</strong> continues to lead in fintech innovation!
                        </Typography>



                    </Box>

                    <Eventback />

                </Container>
            </Box>



        </>
    );
}
