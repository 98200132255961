import { Container, Typography, Box, Grid, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Insightback from '../../../Component/Insightback'
import Writemessage3 from '../../../Component/Writemessage3';
import { Helmet } from 'react-helmet';

import Reg1 from "../../../asset/images/insight/insight_pages/Tokenization_of_assets/tokenization.webp";


export default function Tokenization_of_assets() {


    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);



    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <h1>Tokenization Of Assets A New Paradigm For Businesses" </h1>
                <title>Role & Benefits of Assest Tokenization in Stock Market</title>
                <meta name="description" content="Digital Revolution: Tokenization of Assets Unlocks New Avenues for growth, prevention of unethical activites while trading, insider trading etc in stock market." />
                <meta name="keywords" content="digital assets
asset tokenization
blockchain investment
tokenized assets
tokenization blockchain
asset token
real world asset tokenization" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/articles/tokenization-of-assets-a-new-paradigm-for-businesses" />

                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            <Box id="article-page" className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center' >
                        <Grid item lg={10} md={12} sm={12} xs={12}>
                            <Typography variant='h3' className='subhead skblue al_center'>
                                Insight
                            </Typography>
                            <Typography variant='h1' className='blue mainhead al_center'>
                                Tokenization of assets a  new paradigm for businesses
                            </Typography>
                        </Grid>
                    </Grid>

                    <Box className='whitebx'>


                        <Typography variant='h6' className='black jst ' gutterBottom> In March 2018, our CEO Shailesh Dhuri wrote in his
                            <a href='http://shailesh.dhuri.me/' target="_blank" rel="noopener noreferrer"> Personal Blog </a>    </Typography>


                        <Typography variant='h6' className='black jst ' gutterBottom> “A stock in a company changes hands a million times every day based on information on the financial health of the company, which is at best 40 days old and is 80 days old for most cases. Since this is the only world that we know, we find no irony that stock trading moved from an open outcry system a quarter century ago to HFT algorithms while the information dissemination frequency has not kept pace with the falling cost of information processing. Also, we have come to accept high-cost legal monitoring mechanisms for the prevention of insider trading emanating as a result of the unnecessary delay in the publication of information. We also accept the unreasonable demands that are placed on the board members as a result of this undue lack of transparency enshrined in the extant laws. Of course, the above is just one example of what is possible in the field of corporate governance through the use of technology. More such applications can be thought about and deployed in all aspects of corporate governance.”    </Typography>

                        <Typography variant='h6' className='black jst' >In the modern technology paradigm, we have two sets of technologies, Artificial Intelligence on the one hand and Distributed Ledger Technologies on the other hand. These two sets are like yin and yang to achieve the next level of nirvana in corporate disclosures and governance practices.   </Typography>



                        <Typography variant='h6' className='black jst' gutterBottom> As explained in the exhibit above AI enables labeling the reality, and DLT will allow the creation of a permanent record of the same, which the society can access at near zero cost in zero-trust architecture.  </Typography>



                        <Box className='al_center' mt={4} mb={4}>
                            <img src={Reg1} className='topbanner2' alt='asset tokenization' />
                        </Box>

                        <Typography variant='h6' className='black jst' gutterBottom>Tokenization of tangible and financial assets is looking to make the most of this yin-yang harmony between two technologies and create the next set of disclosures, transparency, and frequency of information dissemination. This will eventually lead to reduced perceived risk and better managing of actual risks, resulting in better pricing of asset owners on the one hand, and better diversification and higher risk-reward tradeoffs for investors.   </Typography>

                        <Typography variant='h6' className='black jst' gutterBottom> Decimal Point Analytics is glad to be one of the early movers in this game-changing paradigm of tokenization of assets. We will be working with our technology partners, <a href='https://www.linkedin.com/company/the-hashgraph-association/' target='_blank' rel="noopener noreferrer"> The Hashgraph Association </a>  and The Swiss Digital Assets Institute, backed by the Swiss Government, to trailblaze a path in the new landscape of tokenization of assets.     </Typography>


                    </Box>

                    <Box className='pages'>
                        <Writemessage3 pageVisited="Tokenization of assets a new paradigm for businesses" />
                    </Box>





                    <Insightback />


                </Container>
            </Box>

        </>
    );
}
