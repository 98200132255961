import React from 'react';
import { Button, Box } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import CallIcon from '@material-ui/icons/Call';

const FloatingContactForm = () => {
  const location = useLocation();
 
  const shouldHideButton = location.pathname.includes('/contact-us') || location.pathname.includes('/sba') || location.pathname.includes('/crm');

  return (
    <>
      {!shouldHideButton && (
        <Box className='floating-btn'>
          <Link to='/contact-us' onClick={() => { window.location.href = "/contact-us#contact_form" }}>
            <Button variant="contained" className='float_btn' startIcon={<CallIcon className='clico' />} >
              Contact Us
            </Button>
          </Link>
        </Box>
      )}
    </>
  );
};

export default FloatingContactForm;
