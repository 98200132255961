import { Container, Typography, Box, Grid, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


import Writemessage3 from '../../../Component/Writemessage3';

import Blogback from '../../../Component/Blogback'
import { Helmet } from 'react-helmet';
import ana_GDP from "../../../asset/images/insight/blog_pages/analysing_GDP.webp";

export default function Analyzing_GDP_nos() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    return (
        <>


            <Helmet>
                <meta charset="UTF-8" />
                <h1>Analyzing The GDP Numbers </h1>
                <title>Analyzing The GDP Numbers | Decimal Point Analytics</title>
                <meta name="description" content="Analyze India's Q1FY18 GDP growth numbers and the contribution of different expenditure types. Gain insights into government expenditure impacts on growth." />
                <meta name="keywords" content="GDP analysis, Q1FY18, India, GDP growth rate, private consumption expenditure, government expenditure, capital formation, incremental GDP, fiscal deficit, net imports, economic challenges, economic outlookv" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/blog/analyzing-gdp-nos" />

                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>



            <Box id="blog-page" className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Typography variant='h3' className='subhead skblue al_center'>
                                Blog
                            </Typography>
                            <Typography variant='h1' className='blue mainhead al_center mb0'>
                                Analyzing The GDP Numbers
                            </Typography>
                            <Typography variant='h4' className='blue al_center'>
                                September 01, 2017
                            </Typography>
                        </Grid>
                    </Grid>


                    <Box className='whitebx'>



                        <Typography variant='h6' className='black jst'>The Indian GDP growth rate came significantly below expectation at 5.7% in the Q1FY18 (Apr-June 2017).</Typography>

                        <Typography variant='h6' className='black jst'>The table below shows the contribution to the GDP growth by type of expenditure.</Typography>

                        <Typography variant='h4' className='blue jst'>Table 1: Expenditure Analysis of GDP Q1FY18 Growth</Typography>

                        <Box className='al_left' mt={4} mb={4}>
                            <img src={ana_GDP} className='topbanner2' alt='Analytics GDP' />
                        </Box>

                        <Typography variant='h6' className='black jst'>Source: Decimal Point Analytics</Typography>

                        <Typography variant='h6' className='black jst'>The above table shows that 3.6% of 5.7% growth was accounted by increase in private consumption expenditure in Q1FY18. Similarly, 1.9% of 5.7% was accounted by increase in government expenditure. In other words, nearly 35% of the GDP growth can be accounted by increase in government expenditure. It is interesting to note that government expenditure accounted for only 11.3% of GDP in the base period of Q1FY17, which increased to 12.6% in the latest quarter as government expenditure jumped. This indicates that if the government expenditure were to grow at the same speed as remaining economy to maintain its 11.3% share of GDP, the GDP growth would have been lower at 4.3% to 4.4%.</Typography>

                        <Typography variant='h6' className='black jst'>Also noteworthy is the fact that capital formation has accounted for only 0.5% out of 5.7% growth, a contribution of 9% to the incremental GDP, as against 31% share of capital formation in the base quarter GDP of Q1FY17.</Typography>


                        <Typography variant='h6' className='black jst'>Finally, Valuables + Net Imports are contributing to (2.4%-2.6%)=-0.2% of 5.7% growth. This underscores, yet again, the fact that for recent years net imports are mostly accounted by Gold, in spite of emphasis on financial assets given by government.</Typography>

                        <Typography variant='h6' className='black jst'>Now that nearly 90% of fiscal deficit headroom is used in April-June period, the government expenditure is going to contract significantly in next quarters, giving significant headwinds to the GDP growth rate unless capital formation picks up in earnest at private sector, which looks doubtful. We should be ready for sub 5 percent GDP growth readings in next few quarters.
                        </Typography>




                        {/* <Box mt={5}>
                            <Subscribe />
                        </Box> */}



                    </Box>


                    <Box className='pages'>
                        <Writemessage3 pageVisited="Analyzing The GDP Numbers" />
                    </Box>

                    <Blogback />


                </Container>
            </Box>

        </>
    );
}
