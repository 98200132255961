import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Eventback from '../../../Component/Eventback'
import { Helmet } from "react-helmet";



export default function YinandYangodweb3() {


    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>Web3 & AI Transforming Insurance Processes | Decimal Point Analytics</title>
                <meta name="description" content="Gain valuable insights into the dynamic relationship between Web3 and AI technologies and their profound impact on the insurance industry." />
                <meta name="keywords" content=" Web3, AI, Insurance processes Yin and Yang,Data Science, Analytics, Shailesh Dhuri, Institute of Actuaries of India Presentation, Decentralized blockchains, Fraud risk, Transaction costs, Claim assessments, Tokenization Geolocation data, Climate change, Insurance industry, Webinar, Transformative potential" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/yin-&-yang-of-web3-&-ai-to-transform-insurance-processes" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>



            <Box id='event-details' className='section sec_bg' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Events
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center'>
                                    Yin & Yang of Web3 & AI to Transform Insurance Processes
                                </Typography>

                            </Box>
                        </Grid>
                    </Grid>





                    <Box className='whitebx'>


                        <Typography variant='h6' className='black jst'>
                            Our CEO, Shailesh Dhuri, had the privilege of participating in the 14th Webinar on Data Science & Analytics, organized by the Institute of Actuaries of India. Mr. Dhuri's presentation, titled <strong>"Yin & Yang of Web3 & AI to Transform Insurance Processes,"</strong> offered valuable insights into the dynamic relationship between Web3 and AI technologies and their profound impact on the insurance industry.
                        </Typography>

                        <Typography variant='h6' className='blue jst'>
                            <strong> Session Overview </strong>
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            Yin and Yang is a Chinese Philosophical concept that believes the two complementary forces make up all aspects and phenomena of life. Web3 is the new generation of internet service built using decentralized blockchains. Web3 and Artificial Intelligence (AI), like Yin and Yang, are complementarily working to streamline insurance transaction processes, mitigate fraud risk, and reduce transaction costs. While AI helps establish the reality by way of claim assessments and fact checks, tokenization of established facts seals the information and makes it tamper-proof. In another tech revolution, geolocation data provide inputs about the location-wise impact of climate change and help insurance companies charge higher premiums or pull out from high-risk areas.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            To gain deeper insights into Mr. Dhuri's presentation and explore the transformative potential of Web3 and AI in the insurance industry, <a href="https://objectstorage.ap-mumbai-1.oraclecloud.com/n/bmexgnkisxsv/b/creative_dpa/o/Media%2Fevent%2Fyin-and-yang-of-web3-and-ai-insurance-industry.pdf" target="_blank" rel="noopener noreferrer"> Click here</a> to download the presentation.
                        </Typography>

                    </Box>




                    <Eventback />



                </Container>
            </Box>



        </>
    );
}
