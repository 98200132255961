import { Container, Grid, Typography, Box, Button } from '@material-ui/core';
import React, {  useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { Helmet } from 'react-helmet';

import cloud_services1 from "../../asset/images/innovation/cloud_services/cloud_services3.webp";
import cloud_services2 from "../../asset/images/innovation/cloud_services/cloud_services2.webp";
import cloud_services3 from "../../asset/images/innovation/cloud_services/cloud_services1.webp";
import cloud_services4 from "../../asset/images/innovation/cloud_services/cloud_services4.webp";
import cloud_services5 from "../../asset/images/innovation/cloud_services/cloud_services5.webp";
import cloud_services6 from "../../asset/images/innovation/cloud_services/cloud_services6.webp";
import patternbg from "../../asset/images/innovation/cloud_services/cloud_services_bg1.webp";
import patternbg1 from "../../asset/images/innovation/cloud_services/cloud_services_bg.webp";
import patternbg2 from "../../asset/images/innovation/cloud_services/cloud_services_bg2.webp";



import SS from "../../asset/images/insight/podcast_new/suyash_shrivastava.webp"
import referance_arch from "../../asset/images/oracle/referance_arch.webp";

import StreamliningOperation from "../../asset/images/insight/case_studies/case_studies_thumbnails/streamlining_operations.webp";
import anonymous_database from "../../asset/images/oracle/anonymous_database.webp";


import "react-modal-video/scss/modal-video.scss";

import Cloud_Services from "../../asset/images/new_banner/data_solution_banner.webp"
import { Link } from 'react-router-dom';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ForwardIcon from '@mui/icons-material/Forward';
import WritemessageSBA from '../../Component/WritemessageSBA';



export default function CloudServices() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []); 

    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                {/* <h1>Elevate Your Data Analytics with Leading AI & Generative Insights Solutions</h1> */}
                <title>Transformative Cloud Services | Migration, Optimization, Monitoring & Multi-Cloud Solutions</title>
                <meta name="description" content="Discover expert cloud services tailored to your business needs. From seamless migrations to cost-effective optimization, we specialize in CI/CD implementation, cloud monitoring, multi-cloud strategies, and Infrastructure as Code. Unlock scalable, secure, and efficient cloud solutions with Decimal Point Analytics today!" />
                {/* <meta name="keywor ds" content="AI in Data Analytics, Generative Insights Solutions, Data-Driven Business, AI-Powered Analytics, Custom AI Solutions, Advanced Data Analysis, Demo for AI Analytics Future of Analytics, Alternate Data Sources, Data Analytics Expertise" /> */}
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/cloud-services" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>




            <Box id='cloud-services'>

                <Box className="banner_bx" mb={"2px"}>
                    <Box style={{ backgroundImage: "url(" + Cloud_Services + ")" }} className="banner_img">
                        <Box className="img_over bl_overlay">
                            <Grid container spacing={0} alignItems="center" justifyContent='center'>
                                <Grid item lg={4} md={4} sm={6} xs={10}>
                                    <Box className="al_center" mt={3}>
                                        <Typography className="white" variant="h1">Cloud Services</Typography> 
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>

                {/* <Writemessage3 pageVisited="Cloud Services" /> */}
                <WritemessageSBA pageVisited="Cloud Services" />

                <Box className='section2 sec_bg' component='section'>
                    <Container maxWidth='lg' className='bdr'>


                        <Grid container direction="row"
                            justifyContent="center"
                            alignItems="center">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className="al_center synopsic_bx">
                                    <Typography variant='h6' className='black jst'>
                                        The future of business lies in the cloud. At Decimal Point Analytics, we specialize in crafting cloud solutions that drive growth, reduce operational complexities, and enhance agility. From seamless migrations to cost-effective optimizations, our expertise ensures your cloud investments deliver measurable value. Let us help you harness the power of the cloud for a more scalable, secure, and innovative future.
                                    </Typography>
                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Get Started Today - <Link to="/contact-us#contact_form" className="link"> Contact Us for a Free Cloud Assessment</Link>
                                    </Typography>
                                </Box>


                            </Grid>
                        </Grid>
                    </Container>
                </Box>


                <Box className='section pt' component='section'>
                    <Container maxWidth='lg' className='bdr'>
                        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={3}>

                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <Box className='al_center'>
                                    <img src={cloud_services1} className='topbanner2' alt='Cloud Services' />
                                </Box>
                            </Grid>

                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <Box mt={0}  data-aos="fade-up" >
                                    <Typography variant="h2" className="blue">
                                        CI/CD Implementation with DevOps
                                    </Typography>
                                    <Typography variant='h6' className='black al_left'>
                                        Achieve unparalleled efficiency in software development and delivery with our robust CI/CD solutions. We streamline deployment pipelines, integrate automation tools, and implement best practices to enhance code quality and accelerate delivery cycles. Experience reduced downtime, faster time-to-market, and more consistent outcomes with every deployment.
                                    </Typography>
                                    <Typography variant='h6' className='black al_left'>
                                        <ul>
                                            <li> Simplified, automated deployment workflows</li>
                                            <li> Higher code quality through seamless integration </li>
                                            <li>Continuous integration and deployment for improved reliability </li>
                                        </ul>
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        <strong>Transform Your Development Process - </strong>  <Link to="/contact-us#contact_form" className="link"> Schedule a Consultation</Link>
                                    </Typography>

                                </Box>
                            </Grid>


                        </Grid>
                    </Container>
                </Box> 

                <Box className="cloud_services banner_bx ">
                    <Box style={{ backgroundImage: "url(" + patternbg + ")" }} className="banner_img">
                        <Box className="img_over bl_overlay3 ">
                            <Container maxWidth='lg' className='bdr'>
                                <Grid container direction="row" justifyContent="center" alignItems="center" spacing={3}>

                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <Box mt={0} data-aos="fade-up"  >
                                            <Typography variant="h2" className="white">
                                                Cloud Implementation with AWS, Azure, or Oracle
                                            </Typography>
                                            <Typography variant='h6' className='white al_left'>
                                                Seamlessly transition to the cloud with our custom implementation services tailored to your unique needs. Whether you’re adopting AWS, Azure, or Oracle, we design secure, scalable, and efficient architectures that empower your business. Our team ensures smooth migrations, robust integrations, and unparalleled operational efficiency.
                                            </Typography>
                                            <Typography variant='h6' className='white al_left'>
                                                <ul>
                                                    <li> Tailored architectures for business-specific requirements</li>
                                                    <li> Expertise in leading cloud platforms </li>
                                                    <li> Secure and scalable solutions to future-proof your business</li>
                                                </ul>
                                            </Typography>

                                            <Typography variant='h6' className='white jst' gutterBottom>
                                                <strong>Begin Your Cloud Journey -</strong>  <Link to="/contact-us#contact_form" className="link white"> Talk to Our Cloud Experts</Link>
                                            </Typography>

                                        </Box>
                                    </Grid>

                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <Box className='al_center'>
                                            <img src={cloud_services2} className='topbanner2' alt='Cloud Services' />
                                        </Box>
                                    </Grid>


                                </Grid>
                            </Container>
                        </Box>
                    </Box>
                </Box>

                <Box className='section pt' component='section'>
                    <Container maxWidth='lg' className='bdr'>

                        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={3}>

                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <Box className='al_center'>
                                    <img src={cloud_services3} className='topbanner2' alt='Cloud Services' />
                                </Box>
                            </Grid>

                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <Box mt={0} data-aos="fade-up" >
                                    <Typography variant="h2" className="blue">
                                        Cloud Optimization Services
                                    </Typography>
                                    <Typography variant='h6' className='black al_left'>
                                        Transform your cloud strategy with optimization solutions that maximize performance and minimize costs. We fine-tune your cloud resources, right size allocations, and implement best practices to ensure you extract every ounce of value from your investment. Stay ahead of the curve with our tailored optimization strategies.
                                    </Typography>
                                    <Typography variant='h6' className='black al_left'>
                                        <ul>
                                            <li> Enhanced resource efficiency for peak performance</li>
                                            <li> Significant cost savings with smarter allocations</li>
                                            <li>Proven best practices for effective cloud management </li>
                                        </ul>
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        <strong> Reduce Costs, Boost Performance  -</strong>  <Link to="/contact-us#contact_form" className="link"> Request a Cost Analysis</Link>
                                    </Typography>

                                </Box>
                            </Grid>


                        </Grid>
                    </Container>
                </Box> 
                

                 <Box className="cloud_services banner_bx">
                    <Box style={{ backgroundImage: "url(" + patternbg1 + ")" }} className="banner_img">
                        <Box className="img_over bl_overlay3 section">
                            <Container maxWidth='lg' className='bdr'>
                                <Grid container direction="row" justifyContent="center" alignItems="center" spacing={3}>

                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <Box mt={0} data-aos="fade-up" >
                                            <Typography variant="h2" className="white">
                                                Cloud Monitoring Services
                                            </Typography>
                                            <Typography variant='h6' className='white al_left'>
                                                Gain peace of mind with real-time cloud monitoring and proactive issue resolution. Our services provide 24/7 visibility into system health, enabling you to identify potential issues before they impact your operations. With centralized dashboards and intelligent alerting, managing your cloud environment has never been easier.
                                            </Typography>
                                            <Typography variant='h6' className='white al_left'>
                                                <ul>
                                                    <li> Proactive monitoring to minimize disruptions</li>
                                                    <li> Intelligent alerts for faster issue resolution</li>
                                                    <li>Centralized insights for complete visibility</li>
                                                </ul>
                                            </Typography>

                                            <Typography variant='h6' className='white jst' gutterBottom>
                                                <strong>Keep Your Cloud in Check  -</strong>  <Link to="/contact-us#contact_form" className="link white"> Explore Monitoring Solutions </Link>
                                            </Typography>
                                        </Box>
                                    </Grid>

                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <Box className='al_center'>
                                            <img src={cloud_services4} className='topbanner2' alt='Cloud Services' />
                                        </Box>
                                    </Grid>


                                </Grid>
                            </Container>
                        </Box>
                    </Box>
                </Box>   

                <Box className='section pt' component='section'>
                    <Container maxWidth='lg' className='bdr'>

                        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={3}>

                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <Box className='al_center'>
                                    <img src={cloud_services5} className='topbanner2' alt='Cloud Services' />
                                </Box>
                            </Grid>

                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <Box mt={0} data-aos="fade-up" >
                                    <Typography variant="h2" className="blue">
                                        Multi-Cloud Strategy
                                    </Typography>
                                    <Typography variant='h6' className='black al_left'>
                                        Diversify and future-proof your cloud infrastructure with our multi-cloud strategy services. We help you leverage the best of multiple cloud providers, ensuring flexibility, redundancy, and optimized performance. Our unified approach simplifies governance, enhances security, and unlocks the full potential of your cloud investments.
                                    </Typography>
                                    <Typography variant='h6' className='black al_left'>
                                        <ul>
                                            <li> Seamless integration across multiple cloud platforms</li>
                                            <li> Strategic planning for flexibility and redundancy</li>
                                            <li>Unified management for streamlined operations</li>
                                        </ul>
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        <strong>Optimize Your Multi-Cloud Setup   -</strong>  <Link to="/contact-us#contact_form" className="link">Let’s Plan Together </Link>
                                    </Typography>

                                </Box>
                            </Grid>


                        </Grid>
                    </Container>
                </Box> 

                 <Box className="cloud_services banner_bx">
                    <Box style={{ backgroundImage: "url(" + patternbg2 + ")" }} className="banner_img">
                        <Box className="img_over bl_overlay3 section">
                            <Container maxWidth='lg' className='bdr'>
                                <Grid container direction="row" justifyContent="center" alignItems="center" spacing={3}>

                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <Box mt={0} data-aos="fade-up" >
                                            <Typography variant="h2" className="white">
                                                Infrastructure as Code (IaC)
                                            </Typography>
                                            <Typography variant='h6' className='white al_left'>
                                                Transform your infrastructure management with our Infrastructure as Code (IaC) solutions. Automate provisioning, ensure consistency, and accelerate deployments using industry-leading tools like Terraform, CloudFormation, and Azure Resource Manager. Our IaC solutions reduce risks, improve traceability, and enable efficient collaboration.
                                            </Typography>
                                            <Typography variant='h6' className='white al_left'>
                                                <ul>
                                                    <li> Automated infrastructure for faster deployments</li>
                                                    <li> Reusable, version-controlled templates</li>
                                                    <li> Reduced risk and enhanced collaboration</li>
                                                </ul>
                                            </Typography>

                                            <Typography variant='h6' className='white jst' gutterBottom>
                                                <strong>Modernize Your Infrastructure    -</strong>  <Link to="/contact-us#contact_form" className="link white">Get a Demo</Link>
                                            </Typography>


                                        </Box>
                                    </Grid>

                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <Box className='al_center'>
                                            <img src={cloud_services6} className='topbanner2' alt='Cloud Services' />
                                        </Box>
                                    </Grid>


                                </Grid>
                            </Container>
                        </Box>
                    </Box>
                </Box>  

                <Box className='section2 pt' component='section'>
                    <Container maxWidth='lg' className='bdr'>


                        <Grid container direction="row"
                            justifyContent="center"
                            alignItems="center">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className="al_left synopsic_bx">
                                    <Typography variant="h4" className="blue">
                                        Why Choose Us?
                                    </Typography>
                                    <Typography variant='h6' className='black jst'>
                                        At Decimal Point Analytics, we bring unmatched expertise to every aspect of cloud transformation. Our certified professionals, industry-best practices, and innovative tools ensure your business achieves maximum value from its cloud journey. Whether it’s migrating workloads, optimizing resources, or monitoring performance, we deliver results that matter.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        <strong>Ready to Elevate Your Cloud Game?  -</strong>  <Link to="/contact-us#contact_form" className="link">Contact Us Today!</Link>
                                    </Typography> 

                                </Box>

                            </Grid>
                        </Grid>
                    </Container>
                </Box>

              <Box className="section sec_bg reportinfo" component="section">
                    <Container maxWidth="lg" className="bdr">
                        <Grid container spacing={2} alignItems="center" justifyContent='center'>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className="al_center">
                                    <Typography className="black" variant="h2">
                                        Explore our Resources
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} alignItems="center" justifyContent='center'>
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Box className='reportinfo'>
                                    <Box className="reportm_bg">
                                        <Box
                                            className="reportimg_bg"
                                            style={{
                                                backgroundImage: 'url(' + StreamliningOperation + ')',
                                            }}
                                        >
                                            <Button variant="contained" className='ora_title_btn'>
                                                Case Study
                                            </Button>
                                        </Box>

                                        <Box my={2}>
                                            <Typography className="reporttitle">
                                                Streamlining Operations <span className='lower'>with</span> Oracle APEX <span className='lower'>and 40%</span> Cost Reduction <span className='lower'>in</span> Inventory Management
                                            </Typography>
                                        </Box>
                                        <Box className="line"></Box>
                                        <Box mt={2}>
                                            <Link to="/case-studies/streamlining-operations-with-oracle-apex-and-40-cost-reduction-in-inventory-management">
                                                <Button variant="contained" className='dpabtn_sm1' endIcon={<NavigateNextIcon />}>
                                                    Read More
                                                </Button>
                                            </Link>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Box className='reportinfo'>
                                    <Box className="reportm_bg">
                                        <Box
                                            className="reportimg_bg"
                                            style={{
                                                backgroundImage: 'url(' + anonymous_database + ')',
                                            }}
                                        >
                                            <Button variant="contained" className='ora_title_btn'>
                                                Case Study
                                            </Button>
                                        </Box>

                                        <Box my={2}>
                                            <Typography className="reporttitle">
                                                DPA overcomes project complexity, improves performance with Autonomous Database
                                            </Typography>
                                        </Box>
                                        <Box className="line"></Box>
                                        <Box mt={2}>
                                            <a href='https://www.oracle.com/customers/dpa-case-study/' target='_blank' rel="noopener noreferrer">
                                                <Button variant="contained" className='dpabtn_sm1' endIcon={<NavigateNextIcon />}>
                                                    Read More
                                                </Button>
                                            </a>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Box className='reportinfo'>
                                    <Box className="reportm_bg">
                                        <Box
                                            className="reportimg_bg"
                                            style={{
                                                backgroundImage: 'url(' + referance_arch + ')',
                                            }}
                                        >
                                            <Button variant="contained" className='ora_title_btn'>
                                                Reference Architecture
                                            </Button>
                                        </Box>

                                        <Box my={2}>
                                            <Typography className="reporttitle">
                                                Modernize and consolidate AWS RDS for MySQL and MongoDB Atlas on Oracle Autonomous Database
                                            </Typography>
                                        </Box>
                                        <Box className="line"></Box>
                                        <Box mt={2}>
                                            <a href='https://docs.oracle.com/en/solutions/migrate-aws-rds-to-adb/index.html#GUID-66B461FC-C64F-445E-A9B8-DBAF5B0AD3BD' target='_blank' rel="noopener noreferrer">
                                                <Button variant="contained" className='dpabtn_sm1' endIcon={<NavigateNextIcon />}>
                                                    Read More
                                                </Button>
                                            </a>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>

                      <Box mt={4}>
                      <Grid container spacing={2} alignItems="center" justifyContent='center'>
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Box className='reportinfo'>
                                    <a href="https://www.youtube.com/watch?v=WRyLIRguWMw&t=2s" target="_blank" rel="noopener noreferrer">
                                        <Box className="reportm_bg">
                                            <Box
                                                className="reportimg_bg"
                                                style={{ backgroundImage: "url(" + SS + ")" }}
                                            >
                                                <Button variant="contained" className='ora_title_btn'>
                                                    Podcast
                                                </Button>
                                            </Box>
                                            <Box className="reporttitle_box">
                                                <Typography className="reporttitle two">
                                                    Data warehousing Cloud and Beyond: Exploring the Future with Suyash
                                                </Typography>
                                            </Box>
                                            <Typography variant='h6' className='skblue'>
                                                Jul 11, 2023
                                            </Typography>
                                            <Box className="line"></Box>
                                            <Box className="reporttitle_box">
                                                <Typography variant="h6" className="black al_left thr">
                                                    Suyash explores cloud data warehousing benefits, focusing on scalability, cost efficiency, and enhanced security.
                                                </Typography>
                                            </Box>
                                            <Box className='flx_fs al_left'>
                                                <Box>
                                                    <Typography variant='h6' className='skblue lh20' gutterBottom>
                                                        Watch Now
                                                    </Typography>
                                                </Box>
                                                <Box mr={3}>
                                                    <ForwardIcon className='skblue fast_forward' fontSize='small' />
                                                </Box>
                                            </Box>
                                        </Box>
                                    </a>
                                </Box>
                            </Grid>
                        </Grid>
                      </Box>

                    </Container>
                </Box > 


            </Box>

        </>
    );
}
