import { Divider, Container, Grid, Typography, Box } from '@material-ui/core';
import React, { useEffect , useLayoutEffect} from 'react';
import { Link } from "react-router-dom";
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import awards1 from "../../asset/images/aboutus/about_us_award1.webp";
import awards2 from "../../asset/images/aboutus/about_us_award2.webp";
import awards3 from "../../asset/images/aboutus/hackathon.webp";
import awards4 from "../../asset/images/aboutus/award4.webp";
import EII from "../../asset/images/aboutus/excellance_in_innovation.webp";
import awards5 from "../../asset/images/aboutus/db_award.webp"

import ISV from "../../asset/images/aboutus/isv-oracle-award.webp";
import RegTech from "../../asset/images/aboutus/canadian-regtech.webp";

import awards from "../../asset/images/new_banner/awards_banner.webp"
import { Helmet } from 'react-helmet';

import db_2024 from "../../asset/images/aboutus/db_2024.webp"


export default function Awards() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);



    // This Function used to set top offset to id 
    // after clicking on the banner links
    const scrollToElement = (id) => {
        const element = document.getElementById(id);
        if (element) {
            const stickyItemsHeight = 170;
            const offset = element.getBoundingClientRect().top - stickyItemsHeight;


            setTimeout(() => {
                window.scrollBy({ top: offset, behavior: 'smooth' });
            }, 50);
        }
    };


    // this useeffect used to set Top offset to id 
    // after redirecting from another page
    // useEffect(() => {
    //     const { hash } = window.location;
    //     if (hash) {
    //         const element = document.querySelector(hash);
    //         if (element) {
    //             const offset = 170;
    //             const elementPosition = element.getBoundingClientRect().top;
    //             const offsetPosition = elementPosition - offset;
    //             window.scrollBy({
    //                 top: offsetPosition,
    //                 behavior: "smooth",
    //             });
    //         }
    //     }
    // }, []);

    // Scroll to the element after the page has rendered
    useLayoutEffect(() => {
        const { hash } = window.location;
        if (hash) {
            // Adding a small delay to ensure content is fully loaded
            setTimeout(() => {
                const element = document.querySelector(hash);
                if (element) {
                    const offset = 170;
                    const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
                    const offsetPosition = elementPosition - offset;
                    window.scrollTo({
                        top: offsetPosition,
                        behavior: "smooth",
                    });
                }
            }, 100); // Adjust delay as needed
        }
    }, []);



    return (
        <>

            <Helmet>
                <title>Awards And Accolades | Decimal Point Analytics </title>
                <meta name="description" content="Discover our accolades at Decimal Point Analytics. Explore our awards recognizing excellence in finance and analytics. Partner with us today." />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/awards" />
            </Helmet>


            <Box className="banner_bx">
                <Box style={{ backgroundImage: "url(" + awards + ")" }} className="banner_img">
                    <Box className="img_over bl_overlay">
                        <Grid container spacing={0} alignItems="center" justifyContent='center'>
                            <Grid item lg={5} md={6} sm={7} xs={10}>
                                <Box className="al_center">
                                    <Typography className="white" variant="h1">Awards and Accolades</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>



            <Box id='awards-owl' className="sticky_top" component="section">
                <Container maxWidth="lg" className="bdr">

                    <Grid container spacing={0} alignItems="top">

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <ul id="award_items" className="al_center">

                                <li className="bt_button al_center">
                                    <Link to="/awards#listed-in-db" onClick={() => scrollToElement('listed-in-db')}>
                                        <Box
                                            className="awardsindex"
                                            style={{ backgroundImage: "url(" + db_2024 + ")" }}
                                        ></Box>
                                    </Link>
                                </li>

                                <li className="bt_button al_center">
                                    <Link to="/awards#canadian-regtech-award" onClick={() => scrollToElement('canadian-regtech-award')}>
                                        <Box
                                            className="awardsindex"
                                            style={{ backgroundImage: "url(" + RegTech + ")" }}
                                        ></Box>
                                    </Link>
                                </li>

                                <li className="bt_button al_center">
                                    <Link to="/awards#isv-of-the-year-2023" onClick={() => scrollToElement('isv-of-the-year-2023')}>
                                        <Box
                                            className="awardsindex"
                                            style={{ backgroundImage: "url(" + ISV + ")" }}
                                        ></Box>
                                    </Link>
                                </li>

                                <li className="bt_button al_center">
                                    <Link to="/awards#excellence-in-innovation" onClick={() => scrollToElement('excellence-in-innovation')}>
                                        <Box
                                            className="awardsindex"
                                            style={{ backgroundImage: "url(" + EII + ")" }}
                                        ></Box>
                                    </Link>
                                </li>

                                <li className="bt_button al_center">
                                    <Link to="/awards#featured-in-db" onClick={() => scrollToElement('featured-in-db')}>
                                        <Box
                                            className="awardsindex"
                                            style={{ backgroundImage: "url(" + awards5 + ")" }}
                                        ></Box>
                                    </Link>
                                </li>

                                <li className="bt_button al_center">
                                    <Link to="/awards#ceo-insights-magazine" onClick={() => scrollToElement('ceo-insights-magazine')}>
                                        <Box
                                            className="awardsindex"
                                            style={{ backgroundImage: "url(" + awards4 + ")" }}
                                        ></Box>
                                    </Link>
                                </li>

                                <li className="bt_button al_center">
                                    <Link to="/awards#mark-tech-hackthon" onClick={() => scrollToElement('mark-tech-hackthon')}>
                                        <Box
                                            className="awardsindex"
                                            style={{ backgroundImage: "url(" + awards3 + ")" }}
                                        ></Box>
                                    </Link>
                                </li>

                                <li className="bt_button al_center">
                                    <Link to="/awards#graham-bell-awards" onClick={() => scrollToElement('graham-bell-awards')}>
                                        <Box
                                            className="awardsindex"
                                            style={{ backgroundImage: "url(" + awards1 + ")" }}
                                        ></Box>
                                    </Link>
                                </li>

                                <li className="bt_button al_center">
                                    <Link to="/awards#indian-sme-of-the-year" onClick={() => scrollToElement('indian-sme-of-the-year')}>
                                        <Box
                                            className="awardsindex"
                                            style={{ backgroundImage: "url(" + awards2 + ")" }}
                                        ></Box>
                                    </Link>
                                </li>

                            </ul>
                        </Grid>

                    </Grid>
                </Container>
            </Box>


            <Box className='award-details-section'>
                {/* Awards Discription Started */}

                <Box id='listed-in-db' className='section' component='section'>
                    <Container maxWidth='lg' className='bdr'>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item lg={2} md={2} sm={2} xs={12} data-aos="fade-up">
                                <Box className='al_left'>
                                    <img src={db_2024} className='topbanner' alt='Award' />
                                </Box>
                            </Grid>
                            <Grid item lg={10} md={10} sm={10} xs={12} data-aos="fade-up">
                                <Box>
                                    <Typography variant='h5' className='black'>
                                        Dun & Bradstreet Lists Decimal Point Analytics in Prestigious "Leading SMEs of India 2024" Publication
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>

                        <Divider className='div1' />

                        <Grid container spacing={3} alignItems="top">
                            <Grid item lg={12} md={12} sm={12} xs={12} data-aos="fade-up">
                                <Box>


                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Decimal Point Analytics is proud to announce its recognition as a Leading SME in India by Dun & Bradstreet, a global leader in business insights. Featured in their esteemed publication, "Leading SMEs of India 2024," this accolade highlights our company’s significant contributions to the industry.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        We extend our sincere gratitude to Dun & Bradstreet for this honor and to our valued clients, partners, and team members for their unwavering support. Together, we will continue to drive innovation and excellence in the financial analytics sector.
                                    </Typography>


                                </Box>
                            </Grid>

                        </Grid>
                    </Container>
                </Box>

                <Box id='canadian-regtech-award' className='section sec_bg' component='section'>
                    <Container maxWidth='lg' className='bdr'>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item lg={2} md={2} sm={2} xs={12} data-aos="fade-up">
                                <Box className='al_left'>
                                    <img src={RegTech} className='topbanner' alt='Award' />
                                </Box>
                            </Grid>
                            <Grid item lg={10} md={10} sm={10} xs={12} data-aos="fade-up">
                                <Box>
                                    <Typography variant='h5' className='black'>
                                        Decimal Point Analytics Wins Best ESG Solution at Canadian RegTech Awards 2023
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>

                        <Divider className='div1' />

                        <Grid container spacing={3} alignItems="top">
                            <Grid item lg={12} md={12} sm={12} xs={12} data-aos="fade-up">
                                <Box>


                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        We are delighted to announce that Decimal Point Analytics has been recognized with the esteemed Canadian RegTech Award for Best ESG Solution 2023. This accolade is a testament to our unwavering commitment to providing innovative solutions that empower financial institutions in navigating the complexities of Environmental, Social, and Governance (ESG) considerations.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        At Decimal Point Analytics, we understand the evolving landscape of the financial industry and the growing importance of ESG factors. Our award-winning solution is designed to address the unique needs of financial institutions, offering enhanced capabilities in ESG data collection, consolidation, and management. Additionally, our platform provides robust data analysis, risk management tools, and facilitates seamless compliance with ESG and Climate risk reporting obligations.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        This recognition underscores our dedication to driving positive change within the industry and reinforces Decimal Point Analytics as a leader in delivering cutting-edge solutions that empower financial institutions to navigate the intricate landscape of ESG considerations effectively. We extend our gratitude to the Canadian RegTech Awards for this honor and look forward to continuing our journey of innovation in the realm of ESG solutions.
                                    </Typography>


                                </Box>
                            </Grid>

                        </Grid>
                    </Container>
                </Box>

                <Box id='isv-of-the-year-2023' className='section' component='section'>
                    <Container maxWidth='lg' className='bdr'>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item lg={2} md={2} sm={2} xs={12} data-aos="fade-up">
                                <Box className='al_left'>
                                    <img src={ISV} className='topbanner' alt='Award' />
                                </Box>
                            </Grid>
                            <Grid item lg={10} md={10} sm={10} xs={12} data-aos="fade-up">
                                <Box>
                                    <Typography variant='h5' className='black'>
                                        Oracle Global Leaders Recognizes Decimal Point Analytics As ISV Of The Year 2023
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>

                        <Divider className='div1' />

                        <Grid container spacing={3} alignItems="top">
                            <Grid item lg={12} md={12} sm={12} xs={12} data-aos="fade-up">
                                <Box>


                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Decimal Point Analytics has achieved another milestone as it proudly accepts the Intel Oracle Global Leaders Award for Independent Software Vendors (ISVs) of the Year in the APAC region for 2023. This prestigious accolade recognizes the company's outstanding contributions in harnessing Oracle cloud technologies to foster innovation and provide exemplary solutions to clients worldwide. The Intel Oracle Global Leaders Award is a distinguished honor that applauds champions in the industry for their exceptional achievements in deploying Oracle Data platform solutions.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Decimal Point Analytics particularly stands out this year as a shining example of excellence in the implementation and migration of innovative Oracle solutions. The Intel Oracle Global Leaders ISV award specifically acknowledges Independent Software Vendors (ISVs) that demonstrate remarkable dedication to embracing the latest Oracle Database and cloud technologies to deliver cutting-edge services to their clientele. Decimal Point Analytics has consistently showcased an unwavering commitment to excellence, offering state-of-the-art solutions that empower businesses across various sectors to unlock the full potential of Oracle’s JSON Document Store and autonomous Database.   </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        This accolade is a testament to Decimal Point Analytics' relentless pursuit of excellence and its significant impact on the industry through the effective utilization of Oracle technologies.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Discover more details in the full press release. <Link to="/oracle-global-leaders-recognizes-decimal-point-analytics-as-isv-of-the-year-2023" className="link">Click here.</Link>
                                    </Typography>


                                </Box>
                            </Grid>

                        </Grid>
                    </Container>
                </Box>

                <Box id='excellence-in-innovation' className='section sec_bg' component='section'>
                    <Container maxWidth='lg' className='bdr'>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item lg={2} md={2} sm={2} xs={12} data-aos="fade-up">
                                <Box className='al_left'>
                                    <img src={EII} className='topbanner' alt='Award' />
                                </Box>
                            </Grid>
                            <Grid item lg={10} md={10} sm={10} xs={12} data-aos="fade-up">
                                <Box>
                                    <Typography variant='h5' className='black'>
                                        Decimal Point Analytics Recognized by ET Edge for Excellence in Innovation 2023
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>

                        <Divider className='div1' />

                        <Grid container spacing={3} alignItems="top">
                            <Grid item lg={12} md={12} sm={12} xs={12} data-aos="fade-up">
                                <Box>


                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        We are immensely proud to share that Decimal Point Analytics has been bestowed with the esteemed "Excellence in Innovation 2023" award by ET Edge. This award is a testament to our consistent commitment to excellence and our untiring efforts in driving innovation in our solutions.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Our culture, which values collaboration and encourages novel thinking, enables us to push boundaries and thrive in innovation. We take pride in fostering a diverse and inclusive work environment where ideas thrive, and solutions evolve.   </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        To stay ahead of the rapidly changing data analytics and financial technology sectors, we emphasize continuous learning and development. Through substantial investments in employee training, we ensure our team is equipped with the latest knowledge and skills, empowering us to deliver pioneering solutions that fuel our clients' success.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        We wish to express our heartfelt appreciation to our exceptional team and valued clients, whose contribution has been integral to our remarkable journey. At DPA, we remain steadfast in our mission to lead the industry and unlock unprecedented opportunities in data analytics and financial technology.   </Typography>


                                </Box>
                            </Grid>

                        </Grid>
                    </Container>
                </Box>

                <Box id='featured-in-db' className='section' component='section'>
                    <Container maxWidth='lg' className='bdr'>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item lg={2} md={2} sm={2} xs={12} data-aos="fade-up">
                                <Box className='al_left'>
                                    <img src={awards5} className='topbanner' alt='Award' />
                                </Box>
                            </Grid>
                            <Grid item lg={10} md={10} sm={10} xs={12} data-aos="fade-up">
                                <Box>
                                    <Typography variant='h5' className='black'>
                                        Featured in Dun & Bradstreet's premier publication 'Leading SMEs of India 2022’
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>

                        <Divider className='div1' />

                        <Grid container spacing={3} alignItems="top">
                            <Grid item lg={12} md={12} sm={12} xs={12} data-aos="fade-up">
                                <Box>


                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        We are thrilled and honored to announce that Decimal Point Analytics has been prominently featured in Dun & Bradstreet's premier publication, "Leading SMEs of India 2022." This recognition holds special significance for us as it underscores our unwavering commitment to pushing the boundaries of innovation in the realms of research and analytics.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Being listed in this premier publication is a testament to the hard work, dedication, and expertise of our team. It showcases our ability to consistently deliver valuable insights and solutions that make a positive impact in the industry. This achievement resonates with our core values of excellence and continuous improvement, and it motivates us to continue raising the bar in everything we do. As we celebrate this accomplishment, we eagerly anticipate more opportunities for recognition and features in the future. This achievement fuels our enthusiasm to keep pushing boundaries, embracing challenges, and contributing to the advancement of the industry.
                                    </Typography>


                                </Box>
                            </Grid>

                        </Grid>
                    </Container>
                </Box>

                <Box id='ceo-insights-magazine' className='section sec_bg' component='section'>
                    <Container maxWidth='lg' className='bdr'>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item lg={2} md={2} sm={2} xs={12} data-aos="fade-up">
                                <Box className='al_left'>
                                    <img src={awards4} className='topbanner' alt='Award' />
                                </Box>
                            </Grid>
                            <Grid item lg={10} md={10} sm={10} xs={12} data-aos="fade-up">
                                <Box>
                                    <Typography variant='h5' className='black'>
                                        Featured in Top 10 brands in Finance 2022 by CEO Insights magazine
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>

                        <Divider className='div1' />

                        <Grid container spacing={3} alignItems="top">
                            <Grid item lg={12} md={12} sm={12} xs={12} data-aos="fade-up">
                                <Box>


                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        We are delighted to announce that our CEO, Shailesh Dhuri, has been featured in CEOInsights Magazine, which illuminates Decimal Point Analytics’ commitment to utilizing data as a critical asset for organizational growth. Under Shailesh's leadership, we have attained significant milestones, such as the development of proprietary AI-ML products and earning the prestigious SME of the Year award 2021 by the Indo French Chamber of Commerce and Industry.  </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Shailesh’s dialogue with CEOInsights amplifies our philosophy of innovation, customer centricity, integrity, and continuous improvement. We take pride in offering tailored data and research solutions, contributing substantially to our clients' success. Our firm commitment to society and the environment is manifested in our CSR initiatives and sustainable operational practices.  </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        As we envisage the future, DPA is centered on developing a cloud-based platform for our solutions and probing the realms of DLT and Quantum Computing. As we continue to grow, we are on the lookout for professionals who resonate with our values of self-awareness, agility, and accountability. This recognition reaffirms our commitment to fostering innovation and making a significant impact in Data Management, Analytics, AI, and ML.  </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Click here to read the full article from the CEOInsight Magazine - <a href="https://www.ceoinsightsindia.com/magazines/brands-in-finance-december-2022/#page=24" target="_blank" rel="noopener noreferrer"> https://www.ceoinsightsindia.com/magazines/brands-in-finance-december-2022/#page=24 </a>   </Typography>




                                </Box>
                            </Grid>

                        </Grid>
                    </Container>
                </Box>

                <Box id="mark-tech-hackthon" className='section' component='section'>
                    <Container maxWidth='lg' className='bdr'>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item lg={2} md={2} sm={2} xs={12} data-aos="fade-up">
                                <Box className='al_left'>
                                    <img src={awards3} className='topbanner' alt='Award' />
                                </Box>
                            </Grid>
                            <Grid item lg={10} md={10} sm={10} xs={12} data-aos="fade-up">
                                <Box>
                                    <Typography variant='h5' className='black'>
                                        Proud Winners of Mark-Tech Hackathon Hosted by International Financial Services Centers Authority (IFSCA) and GIFT City
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>

                        <Divider className='div1' />

                        <Grid container spacing={3} alignItems="top">
                            <Grid item lg={12} md={12} sm={12} xs={12} data-aos="fade-up">
                                <Box>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        We are delighted to share that Decimal Point Analytics has earned the esteemed IFSCA Hackathon award for our innovative concept of tokenization of physical assets and philanthropy, powered by advanced DLT technologies. This event was conducted by IFSCA and GIFT City, in association with the National Stock Exchange of India. </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Our forward-thinking solution holds the potential to significantly reshape the social and financial sectors of an economy. This notable recognition from regulators signifies a key milestone in our journey to foster technology-driven ideas that can transform society. </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        This victory has also provided DPA with direct access to the IFSCA’s Regulatory / Innovation Sandbox, a conducive regulatory environment where we are eager to actualize this concept. </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        The award is a testament to our capacity for innovation and our ability to garner trust among global asset managers, financial data providers, banks, insurance companies, and other stakeholders. As we progress, DPA is committed to escalating our efforts to drive innovation, reliability, and efficiency in client engagement.
                                    </Typography>


                                </Box>
                            </Grid>

                        </Grid>
                    </Container>
                </Box>

                <Box id='graham-bell-awards' className='section sec_bg' component='section'>
                    <Container maxWidth='lg' className='bdr'>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item lg={2} md={2} sm={2} xs={12} data-aos="fade-up">
                                <Box className='al_left'>
                                    <img src={awards1} className='topbanner' alt='Award' />
                                </Box>
                            </Grid>
                            <Grid item lg={10} md={10} sm={10} xs={12} data-aos="fade-up">
                                <Box>
                                    <Typography variant='h5' className='black'>
                                        Recognized as the Top Innovative Company in the 12th Graham Bell Awards
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>

                        <Divider className='div1' />

                        <Grid container spacing={3} alignItems="top">
                            <Grid item lg={12} md={12} sm={12} xs={12} data-aos="fade-up">
                                <Box>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        We are extremely proud and humbled to announce that Decimal Point Analytics has been awarded the prestigious Aegis Graham Bell Award for "Innovation in Analytics". This significant recognition, supported by the Ministry of Electronics and Information Technology, Government of India, is for our real-time fraud analytics product, Fidelity Pulse. </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Our dedication to innovation has earned us the trust of our discerning client base that includes leading global asset managers, financial data providers, banks, and insurance companies. As we advance, DPA will redouble its efforts to foster innovation, reliability, and efficiency in our client relationships. </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Chosen as India’s Top Analytics Innovator by a respected cross-border jury of esteemed professionals, this victory represents a major achievement for our team. Our CEO, Shailesh Dhuri, was honored in a virtual ceremony chaired by the Union Minister of State for External Affairs, Dr. Rajkumar Ranjan Singh. </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Mr. Dhuri, reflecting on the award, stated, "At DPA, our passion for solving customer problems fuels us, and I personally find motivation in addressing societal issues. These awards are delightful affirmations of that passion. We remain steadfast in our drive and passion, which is unending."
                                    </Typography>


                                </Box>
                            </Grid>

                        </Grid>
                    </Container>
                </Box>

                <Box id='indian-sme-of-the-year' className='section' component='section'>
                    <Container maxWidth='lg' className='bdr'>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item lg={2} md={2} sm={2} xs={12} data-aos="fade-up">
                                <Box className='al_left'>
                                    <img src={awards2} className='topbanner' alt='Award' />
                                </Box>
                            </Grid>
                            <Grid item lg={10} md={10} sm={10} xs={12} data-aos="fade-up">
                                <Box>
                                    <Typography variant='h5' className='black'>
                                        Acknowledged as Indian SME of the Year -2021
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>

                        <Divider className='div1' />

                        <Grid container spacing={3} alignItems="top">
                            <Grid item lg={12} md={12} sm={12} xs={12} data-aos="fade-up">
                                <Box>


                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        We are thrilled to announce that Decimal Point Analytics (DPA) has been honored as the "Indian SME of the Year" at the third edition of the highly esteemed "Indo French Business Awards 2021". These awards, organized by the Indo-French Chamber of Commerce and Industry (IFCCI), celebrate the exceptional achievements of both Indian and French companies operating in each other's nations. </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Our CEO, Mr. Shailesh Dhuri, graciously accepted the award in the presence of the French Ambassador to India and Union Minister Mr. Nitin Gadkari. He noted, "Since Decimal Point Analytics' inception, we have relentlessly pursued excellence in financial research and consulting. It's a great honor to have our consistent efforts recognized by such a prestigious industry platform." </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        This recognition does not just represent a proud achievement, but also serves as an inspiration for us to reach even greater heights. As we look forward, this award motivates us to keep pushing the boundaries, continuously improving our services, and striving to create more value for our clients and society at large.
                                    </Typography>

                                </Box>
                            </Grid>

                        </Grid>
                    </Container>
                </Box>

            </Box>

        </>
    );
}
