import { IconButton, Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'


import Eventback from '../../../Component/Eventback'
import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdfOutlined';
import { Helmet } from 'react-helmet';







export default function Event_10_Oct() {




    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet>
                <title>Data and Modeling for ESG Investment Workflows </title>
                <meta name="description" content="Discover insights on ESG investment workflows at Decimal Point Analytics' closed-door roundtable. Explore data analytics challenges and its best practices " />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/round-table-by-dpa" />
            </Helmet>

            <Box id='event-details' className='section sec_bg' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Events
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center mb0'>
                                    Round Table by DPA - Is Net zero still a myth?
                                </Typography>
                                <Typography className=' mainsmhead black al_center mb0'>
                                    Oct, 2022
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>





                    <Box className='whitebx'>


                        <Typography variant='h6' className='black jst'>
                            Focus on sustainable investing is the fastest-growing segment in the current economy, as widely agreed upon by governments, authorities, businesses, and investors alike.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            Investors and financial institutions have been paying attention to ESG investment for a while now. However, their effects on the financial performance of firms have come back into the spotlight in the wake of the financial crisis and the associated heightened restrictions.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            ESG variables have both sound and adverse effects on ROE and ROI. However, there are still issues with data and models in the realm of ESG investment.
                        </Typography>


                        <Typography variant='h6' className='black jst'>
                            The global transition to Net Zero advanced with the COP26 in Glasgow, making ESG consideration a more vital need while making investment plans.
                        </Typography>


                        <Typography variant='h6' className='black jst'>
                            Nearly all fund houses are now attempting to solve the concerns of measuring Net Zero alignment and ESG integration. Most alternative funds currently have transparent Net Zero or temperature alignment goals.
                        </Typography>


                        <Typography variant='h6' className='black jst'>
                            Additionally, investors now want to know how ESG factors can be taken into account during the investment process, as these funds - among the front-runners in ESG-related modeling- have significantly greater data and framework difficulties.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            Keeping the above challenges in mind, Decimal point analytics hosted a closed-door roundtable discussion on <strong> “Data and Modeling for ESG Investment Workflows": </strong> Challenges and emerging Best Practices moderated by Gaurav Gupta, Managing Director DPA and Ajay Jindal, ESG lead, Executive Vice-President, DPA.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            The industry experts gathered to discuss various approaches to get through the challenges of data analytics and modeling techniques of ESG Investment workflows available today.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            How professionals can keep pace with sustainable investment in the ever-evolving industries was discussed. <strong> Following a productive conversation, numerous conclusions from the discussion were drawn. Below are some of the primary key take awats. </strong>
                        </Typography>


                        <Typography variant='h6' className='black jst'>
                            <ul>
                                <li> The ESG reporting has not yet been standardized, despite efforts by the biggest asset managers. </li>
                                <li>For ESG data, the majority of companies are going in-house. As ESG teams get leaner, automation is becoming a popular trend.</li>
                                <li> Data consolidation and the level of detail needed are currently being considered for regular implementation. </li>
                            </ul>
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            Corporations today understand that while investing, they should take ESG into account. ESG challenges can be complicated and span a variety of sectors and regions. However, they should be seen as an opportunity to enhance a company's success rather than a risk. Hence meaningful insights and substantial conclusions for transparent proceedings are a mandate in the current scenario.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            Round table is a closed-door meeting by special invitation. To be considered for the next meeting, don't hesitate to contact Sarath Nair at <a href='mailto:sarath.nair@decimalpointanalytics.com'> sarath.nair@decimalpointanalytics.com.</a> </Typography>



                        <Box className='al_center'>
                            <IconButton className='blue'>
                                <a href='https://decimalpointanalytics.com/media/event_imgs/ESGRound_Table_PPT_DPA.pdf' target='_blank' rel="noopener noreferrer">
                                    <PictureAsPdfOutlinedIcon />
                                </a>
                            </IconButton>
                        </Box>

                        <Box className='al_center'>
                            <Typography className='blink_text'>Click Here...</Typography>
                        </Box>

                        {/* <Subscribe /> */}

                    </Box>




                    <Eventback />




                </Container>
            </Box>



        </>
    );
}
