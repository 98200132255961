import { Container, Grid, Typography, Box, Button } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
 

import ibmbanner from "../../asset/images/ibm/ibm_banner.webp";

import Improve from "../../asset/images/ibm/Improve.webp";
import Streamline from "../../asset/images/ibm/Operations.webp";
import Security from "../../asset/images/ibm/Security.webp";

import Support from "../../asset/images/ibm/Support.webp";
import Expertise from "../../asset/images/ibm/Expertise.webp";
import Technology from "../../asset/images/ibm/Technology.webp";

import IbmContactUs from '../../Component/forms/IbmContactUs';
import bgimg from "../../asset/images/ibm/about_ibm.webp";
import ibm_shape from "../../asset/images/ibm/leadspace.png"


export default function IBM() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);
 

    // Scroll to form started
    const scrollToElement = (id) => {
        const element = document.getElementById(id);
        if (element) {
            const stickyItemsHeight = 30;
            const offset = element.getBoundingClientRect().top - stickyItemsHeight;


            setTimeout(() => {
                window.scrollBy({ top: offset, behavior: 'smooth' });
            }, 50);
        }
    };

    useEffect(() => {
        const { hash } = window.location;
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                const offset = 50;
                const elementPosition = element.getBoundingClientRect().top;
                const offsetPosition = elementPosition - offset;
                window.scrollBy({
                    top: offsetPosition,
                    behavior: "smooth",
                });
            }
        }
    }, []);
    // Scroll to form ended 

    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title> Enhancing AI Capabilities with IBM WatsonX | Decimal Point Analytics </title>
                <meta name="description" content="Explore how Decimal Point Analytics and IBM WatsonX enhance business operations with AI-powered insights and predictive models, driving innovation and compliance." />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/ibm" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            <Box id="new_landing_carousel" className='ibmbanner'>
                <Box className="mainsection1" component="section" style={{ backgroundImage: "url(" + ibmbanner + ")" }} pb={2}>
                    <Container maxWidth="lg">
                        <Grid container spacing={1} alignItems="center" justifyContent='center'>
                            <Grid item lg={12} md={12} sm={11} xs={10}>
                                <Box className="text_animated mb0">
                                    <Typography variant="h2" className="blue fw6 mb0" gutterBottom>
                                        Decimal Point Analytics <br />
                                        Partners with IBM <span className='dblack'><span className='lower'>w</span>atson<span className='ibmtitle_blue'>x<sup className='dblack'>TM</sup></span></span>
                                    </Typography>

                                    <Box>
                                        <Typography variant="h2" className="black3" gutterBottom>
                                            to Unlock AI-Powered Business Insights
                                            for Next-Gen Solutions!
                                        </Typography>
                                    </Box>

                                    <Box mt={2}>
                                        <Link to="/ibm#ibmcontactus" onClick={() => scrollToElement('ibmcontactus')}>
                                            <Button variant="contained" className='dpabtn_sm1'>
                                                Learn More
                                            </Button>
                                        </Link>
                                    </Box>

                                </Box>
                            </Grid>

                        </Grid>
                    </Container>
                </Box>
            </Box>

            <Box className='section2' component='section'>
                <Container maxWidth='lg' className='bdr'>
                    <Grid container direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className="al_center">

                                <Typography variant='h2' className='black'>
                                    Enhancing AI Capabilities with IBM <span className='dblack fw7'><span className='lower'>w</span>atson<span className='ibmtitle_blue'>x<sup className='dblack'>TM</sup></span></span>
                                </Typography>

                                <Typography variant='h6' className='black al_center' gutterBottom>
                                    At Decimal Point Analytics (DPA), we’re excited to announce a strategic partnership with IBM watsonx, focused on delivering advanced AI-powered business insights. This collaboration brings together our expertise in Generative AI (GenAI) with watsonx’s advanced technology, providing our clients with real-time predictive models and automated data processing tools to unlock actionable insights.
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box id='sba_solutions' className='section mob_card sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>

                    <Typography variant='h2' className='black al_center'>
                        Unleashing GenAI Potential with <span className='dblack fw7'><span className='lower'>w</span>atson<span className='ibmtitle_blue'>x<sup className='dblack'>TM</sup></span></span>
                    </Typography>

                    <Grid container spacing={4} alignItems="top" justifyContent='center'>

                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Box className='al_center oraContact'>
                                <img src={bgimg} className='topbanner2' alt='SBA Loan Pre-Approval' />
                            </Box>
                        </Grid>

                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Box>

                                <Box py={1}>
                                    <Typography variant="h4" className="black aboutibm" gutterBottom>About IBM <span className='dblack fw7'><span className='lower'>w</span>atson<span className='ibmtitle_blue'>x<sup className='dblack'>TM</sup></span></span></Typography>

                                    <Typography variant='h6' className='black jst mb0'>
                                        IBM watsonx is a robust AI governance and compliance solution designed to help businesses maximize the impact of AI with trusted data. Its core components – watsonx.ai, watsonx.data, and watsonx.governance – empower businesses to create tailored predictive analytics for decision-making, manage data efficiently and ensure AI operations are secure and compliant.
                                    </Typography>
                                </Box>

                            </Box>


                        </Grid>

                    </Grid>



                </Container>
            </Box>

            <Box id='sba_solutions' className='section mob_card' component='section'>
                <Container maxWidth='lg' className='bdr'>
                    

                        <Grid container spacing={1} alignItems="top">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='al_center'>
                                    <Typography variant='h2' className='black'>
                                        A Powerful Partnership
                                    </Typography>

                                    <Typography variant='h6' className='black al_center'>
                                        By combining DPA’s experience in AI-powered automation with the strengths of watsonx, we’re enabling businesses to:
                                    </Typography>
                                </Box>

                            </Grid>
                        </Grid>

                        <Grid container spacing={3} alignItems="center" justifyContent='center'>
                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Box className="ora_experties">
                                <Box className='ora_iconbx'>
                                    <img src={Improve} className='allic' alt='Improve Decision-Making' />
                                </Box>
                                <Box my={2}>
                                    <Typography variant="h4" className="black al_center" gutterBottom> Improve Decision-Making  </Typography>
                                </Box>
                                <Typography variant="h6" className="black al_center">
                                    Utilize real-time predictive models and actionable insights powered by watsonx.
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Box className="ora_experties">
                                <Box className='ora_iconbx'>
                                    <img src={Streamline} className='allic' alt='Streamline Operations' />
                                </Box>
                                <Box my={2}>
                                    <Typography variant="h4" className="black al_center" gutterBottom> Streamline Operations </Typography>
                                </Box>
                                <Typography variant="h6" className="black al_center">
                                    Leverage automated data processing tools and watsonx Assistants to free up teams for strategic work.
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Box className="ora_experties">
                                <Box className='ora_iconbx'>
                                    <img src={Security} className='allic' alt='Ensure Compliance and Security' />
                                </Box>
                                <Box my={2}>
                                    <Typography variant="h4" className="black al_center" gutterBottom> Ensure Compliance and Security </Typography>
                                </Box>
                                <Typography variant="h6" className="black al_center">
                                    Use watsonx’s AI governance solutions to maintain transparency, accountability, and regulatory compliance across all AI operations.
                                </Typography>
                            </Box>
                        </Grid>

                    </Grid>
                </Container>
            </Box>

            <Box className='section2 sec_bg ibm_sucessbgimg' component='section'>
                <Container maxWidth='lg' className='bdr'>
                    <Box className='ibm_textimg1'>
                        <img src={ibm_shape} alt='left side shape' />
                    </Box>

                    <Box className='ibm_textimg2'>
                        <img src={ibm_shape} alt='right side shape'/>
                    </Box>
                    <Grid container direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Grid item lg={10} md={10} sm={10} xs={10}>
                            <Box className="al_center className='ibm_textbx'">

                                <Typography variant='h2' className='black ibmrelative'>
                                    Our Commitment to Your Success
                                </Typography>

                                <Typography variant='h6' className='black al_center ibmrelative' gutterBottom>
                                    At Decimal Point Analytics, we are committed to delivering innovative, high-quality research and analytics. Partnering with IBM watsonx allows us to offer advanced AI capabilities that enhance operational efficiency and drive real-world results for our clients.
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box id='sba_solutions' className='section mob_card' component='section'>
                <Container maxWidth='lg' className='bdr'>

                        <Grid container spacing={1} alignItems="top">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='al_center'>
                                    <Typography variant='h2' className='black'>
                                        Why Choose Us?
                                    </Typography>

                                </Box>

                            </Grid>
                        </Grid>

                        <Grid container spacing={3} alignItems="top" justifyContent='center'> 

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Box className="ibm_experties boxbg">
                                <Box className='ora_iconbx al_left'>
                                    <img src={Support} className='allic' alt='End-to-End Support' />
                                </Box>
                                <Box my={2}>
                                    <Typography variant="h4" className="black al_left" gutterBottom> End-to-End Support  </Typography>
                                    <Typography variant="h6" className="black al_left mb0">
                                        We offer complete services, from initial consultation to implementation and ongoing support.
                                    </Typography>
                                </Box>

                            </Box>
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Box className="ibm_experties boxbg">
                                <Box className='ora_iconbx al_left'>
                                    <img src={Expertise} className='allic' alt='Proven Expertise' />
                                </Box>
                                <Box my={2}>
                                    <Typography variant="h4" className="black al_left" gutterBottom> Proven Expertise  </Typography>
                                    <Typography variant="h6" className="black al_left mb0">
                                        With over two decades of experience in AI, fund management, and data analytics, our solutions deliver impact.
                                    </Typography>
                                </Box>

                            </Box>
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Box className="ibm_experties boxbg">
                                <Box className='ora_iconbx al_left'>
                                    <img src={Technology} className='allic' alt='Advanced Technology' />
                                </Box>
                                <Box my={2}>
                                    <Typography variant="h4" className="black al_left" gutterBottom> Advanced Technology  </Typography>
                                    <Typography variant="h6" className="black al_left mb0">
                                        Stay ahead with the latest in AI-powered business insights and foundation models for business.
                                    </Typography>
                                </Box>

                            </Box>
                        </Grid>

                    </Grid>
                </Container>
            </Box>

            <Box id='ibmcontactus' className='sec_bg'>
                <IbmContactUs />
            </Box>

        </>
    );
}
