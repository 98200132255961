import {  Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Eventback from '../../../Component/Eventback'
import { Helmet } from "react-helmet";


export default function AvantikaUniversity() {


    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>Arun Singh as Guest of Honour at Avantika University's Orientation Ceremony</title>
                <meta name="description" content="Arun Singh, Chief People Officer, inspired new students at Avantika University's &quot;Aarambh&quot; orientation ceremony, sharing insights to motivate them on their academic journey" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/paresh-sharma-represents-decimal-point-analytics-at-the-5th-picup-fintech-conference" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>



            <Box id='event-details' className='section sec_bg' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={8} md={8} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Events
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center'>
                                    Arun Singh at Avantika University’s Student Orientation Ceremony "Aarambh"
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>

                    <Box className='whitebx'>

                        <Typography variant='h6' className='black jst'>
                            We were pleased to announce that our <strong>Chief People Officer, Arun Singh</strong>, was invited as the Guest of Honour for <strong>Avantika University's Student Orientation Inaugural Ceremony, "Aarambh,"</strong> which took place on August 14th in <strong>Ujjain, Indore.</strong> This ceremony marked an important milestone for new students as they began their academic journey at the university.
                        </Typography>

                        <Typography variant='h6' className='black jst'> During the event, Arun shared his insights and experiences, providing guidance that aimed to inspire and motivate students to embrace the opportunities and challenges that lay ahead. His presence served as a source of encouragement as students prepared to immerse themselves in their studies and campus life.
                        </Typography>

                        <Typography variant='h6' className='black jst'> The event provided a meaningful and enriching experience, strengthening the sense of community and ambition among the students as they embarked on their educational journey.
                        </Typography>



                    </Box>

                    <Eventback />

                </Container>
            </Box>



        </>
    );
}
