import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Eventback from '../../../Component/Eventback'
import { Helmet } from "react-helmet";


export default function IFSCConference2024() {


    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>Decimal Point Analytics attended the IFSCA San Francisco</title>
                <meta name="description" content="Decimal Point Analytics participated in IFSCA San Francisco 2024, engaging with industry leaders and exploring future trends." />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/decimal-point-analytics-at-ifsca-san-francisco-conference-2024" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>



            <Box id='event-details' className='section sec_bg' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={8} md={8} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Events
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center'>
                                    Decimal Point Analytics at IFSCA San Francisco Conference 2024
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Box className='whitebx'>

                        <Typography variant='h6' className='black jst'>
                            We were honored to attend the <strong>IFSCA San Francisco Conference 2024</strong>, a distinguished event that convened global finance leaders and industry experts to discuss the latest advancements in <strong>Capital Markets</strong> and <strong>Fund Management</strong>. Hosted by <strong>IFSCA Official</strong>, the conference offered a collaborative platform for sharing insights and strategies that are driving growth and innovation in the financial sector.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            <strong>Representing Decimal Point Analytics</strong> was <strong>David Riedel, Strategic Sales Partner</strong>, who engaged with peers and explored emerging trends that are reshaping finance and investment. David’s participation highlighted Decimal Point Analytics' commitment to supporting the industry's evolving needs with data-driven solutions and strategic insights.
                        </Typography>

                        <Typography variant='h6' className='black jst' gutterBottom>
                            The event provided an enriching experience, fostering new connections and reinforcing our dedication to advancing the future of finance.
                        </Typography>

                    </Box>

                    <Eventback />

                </Container>

            </Box>

        </>
    );
}
