import { Container, Typography, Box, Grid, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


import Writemessage3 from '../../../Component/Writemessage3';

import Blogback from '../../../Component/Blogback'

import { Helmet } from 'react-helmet';

export default function Indias_fiscal_contraction() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <h1>India’s coming fiscal contraction – effect on growth and inflation </h1>
                <title>India’s coming fiscal contraction – effect on growth and inflation</title>
                <meta name="description" content="Analyze the potential impact of India's fiscal contraction on economic growth and inflation. Understand the relationship between fiscal policy and inflation." />
                <meta name="keywords" content="India, fiscal contraction, GDP growth, inflation, Central Fiscal Deficit, capital expenditure, fiscal deficit target, economic analysis, front-ending, net government expenditure, growth impetus, inflation forecast" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/blog/indias-fiscal-contraction" />

                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            <Box id="blog-page" className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Typography variant='h3' className='subhead skblue al_center'>
                                Blog
                            </Typography>
                            <Typography variant='h1' className='blue mainhead al_center mb0'>
                                India’s coming fiscal contraction – effect  on growth and inflation
                            </Typography>
                            <Typography variant='h4' className='blue al_center'>
                                August 09, 2017
                            </Typography>
                        </Grid>
                    </Grid>

                    <Box className='whitebx'>



                        <Typography variant='h6' className='black jst'>We estimate Central Fiscal Deficit for April-June 2017 quarter for India to be at approximately 10.75% of the GDP of that quarter. This is because of planned increase in capital expenditure by the central government in that quarter. To maintain the preannounced target of 3.20% of GDP for fiscal deficit for the year April 2017 till March 2018, the deficit for the nine-month period July 2017 till March 2018 should not significantly exceed 0.75% of GDP of that period.</Typography>

                        <Typography variant='h6' className='black jst'>By itself it not unnatural to see this seasonal variation in fiscal deficit in India. However, we believe, this year, due to planned front ending of capital expenditures, the ramp down will be more severe as compared to the past, at 10% of GDP in contraction of net Central government expenditure.</Typography>

                        <Typography variant='h6' className='black jst'>The growth numbers for the June 2017 are not yet published. But, they can be deemed to be good, given this front-ending of the expenditure, boosting GDP growth rate by 1.25% at least. However, on the flip side, the fiscal contraction predicted in the near future will drag the GDP growth for those three quarters.</Typography>

                        <Typography variant='h6' className='black jst'>Also, the inflation at the end of June was 1.5%, in spite of growth impetus by the higher fiscal deficit. Other things being equal, the contraction in net government expenditure should provide headwinds for inflation in the coming quarters.</Typography>



                        {/* <Box mt={5}>
                            <Subscribe />
                        </Box> */}



                    </Box>


                    <Box className='pages'>
                        <Writemessage3 pageVisited="Indias coming fiscal contraction effect on growth and inflation" />
                    </Box>



                    <Blogback />

                </Container>
            </Box>

        </>
    );
}
