import { Container, Typography, Box, Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Helmet } from 'react-helmet';
import Writemessage3 from '../../../../../../Component/Writemessage3';
import { Link } from 'react-router-dom';
import ESGBlogsBack from '../../../../../../Component/BackButtons/ESGBlogsBack';

export default function ESGPortfolioAllignmentToolsImplement() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title> Portfolio Alignment Tools to Implement ESG Strategies for Investors </title>
                <meta name="description" content="Discover how portfolio alignment tools implement ESG strategies for investors. Align your portfolio with ESG values that matter." />
                <meta name="keywords" content="portfolio alignment tools, ESG strategies, investors, TCFD reporting, Net Zero alignment tracking, SFDR compliance, scoring frameworks, ESG metrics, Decimal Point Analytics." />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/esg-insights/esg-blogs/how-portfolio-alignment-tools-implement-esg-strategies-for-investors" />

                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>




            <Box className='section sec_bg' component='section' id='bank_runs'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={3} alignItems="top">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='al_center' mb={2}>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    ESG Insights
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center'>
                                    How Portfolio Alignment Tools Implement ESG Strategies for Investors
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    {/* <Typography variant='h3' className='subhead skblue al_center'>
                    ESG Insights
                </Typography>
                <Typography variant='h1' className='blue mainhead al_center'>
                    How Portfolio Alignment Tools Implement <br /> ESG Strategies for Investors
                </Typography> */}




                    <Writemessage3 pageVisited="How Portfolio Alignment Tools Can Help Investors Implement ESG Strategies for Investors" />


                    <Box className='whitebx' mt={1}>


                        <Typography variant='h6' className='black jst' gutterBottom>
                            To meet Paris Climate Agreement objectives and prevent a significant rise in global average temperatures, human society must achieve net-zero emissions of long-lasting greenhouse gases by mid-century. The most common method of measuring climate impact in the global financial industry is calculating financed emissions, which involves adding up the emissions funded by an organization in its lending, investment, or insurance portfolio from all the companies involved.
                        </Typography>




                        <Typography variant='h6' className='black jst' gutterBottom>
                            According to <a href='https://www.mckinsey.com/capabilities/risk-and-resilience/our-insights/aligning-portfolios-with-climate-goals-a-new-approach-for-financial-institutions' target='_blank' rel="noreferrer"> Mckinsey's 2021 report </a>, there are 3 critical challenges related to developing effective climate strategies.

                            <ol>
                                <li>
                                    <Typography variant='h6' className='black jst'>
                                        To achieve the Paris Agreement goals and prevent temperature increases, institutions should develop a climate strategy considering the total carbon budget instead of just monitoring their financed emissions.
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant='h6' className='black jst'>
                                        Achieving net zero requires different regions and industries to reduce carbon emissions at varying rates.
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant='h6' className='black jst'>
                                        Measuring emissions may deter financial institutions from supporting carbon reduction and responsible disposal efforts, causing them to favor smaller emitters without considering decarbonization efforts.
                                    </Typography>
                                </li>

                            </ol>
                        </Typography>




                        <Box pt={1} pb={1}>
                            <Typography variant='h4' className='blue jst'> What are ESG Strategies and their importance to investors?  </Typography>
                        </Box>

                        <Typography variant='h6' className='black jst' gutterBottom>
                            ESG strategies allow investors to incorporate ESG considerations into their investments. Investing in companies committed to environmental sustainability, social responsibility, and good corporate governance can help make a positive impact on the world.</Typography>

                        <Typography variant='h6' className='black jst' gutterBottom>
                            ESG strategies are also becoming increasingly important for investors because they can help to improve investment performance. Studies have shown that ESG-focused portfolios outperform traditional portfolios over the long term, particularly with respect to downside risk.</Typography>




                        <Box pt={1} pb={1}>
                            <Typography variant='h4' className='blue jst'> Portfolio Alignment Tools for Investors </Typography>
                        </Box>

                        <Typography variant='h6' className='black jst' gutterBottom>
                            There are several portfolio alignment tools available to help investors track the result of their ESG strategies. Funds are declaring future goals and targets regarding Net Zero; thematic ESG funds may have targets on Diversity, or UN SGDs. These tools measure the ESG metrics of investors' current portfolios against their targets and track the progress over time.
                        </Typography>

                        <Typography variant='h6' className='black jst' gutterBottom>
                            Some of the most popular portfolio alignment tools include:
                            <ul>
                                <li>
                                    <Typography variant='h6' className='black jst'>
                                        <strong> TCFD Reporting: </strong>  The Task Force on Climate-related Financial Disclosures (TCFD) is an international body that has developed a set of recommendations for companies to disclose their climate-related financial information. Portfolio alignment tools assess how thriving companies are complying with the TCFD recommendations.
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant='h6' className='black jst'>
                                        <strong> Net Zero Alignment Tracking: </strong>   This tool tracks how portfolios are aligned with Paris goals in terms of their future emission targets.
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant='h6' className='black jst'>
                                        <strong>	SFDR:  </strong>  The Sustainable Finance Disclosure Regulation (SFDR) is a European Union regulation that requires financial institutions to disclose information about their sustainable investment activities. Portfolio alignment tools assess how well financial institutions comply with the SFDR requirements.</Typography>
                                </li>
                                <li>
                                    <Typography variant='h6' className='black jst'> <strong> Scoring Framework and Reports: </strong>  Some portfolio alignment tools provide investors with a scoring framework that can be used to assess the ESG performance of companies. These tools also typically provide investors with reports summarizing their portfolios' ESG performance.
                                    </Typography>
                                </li>

                            </ul>
                        </Typography>



                        <Box pt={1} pb={1}>
                            <Typography variant='h4' className='blue jst'> Benefits of using portfolio alignment tools </Typography>
                        </Box>



                        <Typography variant='h6' className='black jst' gutterBottom>
                            There are several benefits to using portfolio alignment tools. These benefits include:
                            <ul>
                                <li>
                                    <Typography variant='h6' className='black jst'>
                                        <strong>  	Identifying companies aligned with ESG goals and values:  </strong> Portfolio alignment tools identify companies aligned with investors' ESG goals and values. This allows investors to make more informed investment decisions.
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant='h6' className='black jst'>
                                        <strong>  Measuring the ESG metrics of investors' current portfolios:  </strong> Portfolio alignment tools measure the ESG metrics of investors' existing portfolios. This allows investors to identify areas where their portfolios could be improved.
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant='h6' className='black jst'>
                                        <strong> Tracking the progress of investors' investments:  </strong>  Portfolio alignment tools track the progress of investors' assets. This allows investors to see how their investments perform against their ESG goals and values.
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant='h6' className='black jst'> <strong> Reduced risk: </strong>   Portfolio alignment tools help investors reduce risk. Investing in companies committed to environmental sustainability, social responsibility, and good corporate governance can help reduce their exposure to ESG risks.
                                    </Typography>
                                </li>

                            </ul>
                        </Typography>

                        <Box pt={1} pb={1}>
                            <Typography variant='h4' className='blue jst'> Conclusion </Typography>
                        </Box>

                        <Typography variant='h6' className='black jst' gutterBottom>
                            Portfolio alignment tools provided by Decimal Point Analytics will equip investors with robust ESG strategies in 2023. We help you identify companies aligned with investors' ESG goals and values, measure the ESG of investors' current portfolios, track the progress of investors' investments, and reduce risk.
                        </Typography>

                        <Typography variant='h6' className='black jst' gutterBottom>
                            <Link to="/contact-us"> Get in touch</Link> with Decimal Point Team today and align your portfolio with ESG values that matter! </Typography>



                    </Box>



                    <ESGBlogsBack />


                </Container>
            </Box>

        </>
    );
}
