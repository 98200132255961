import { IconButton, Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Eventback from '../../../Component/Eventback'
import { Helmet } from "react-helmet";
import { withStyles } from '@material-ui/core/styles';

import Carousel from "react-elastic-carousel";

import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined';

import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';


import Carouselimg1 from "../../../asset/images/media/event/gen_ai/genai1.webp"
import Carouselimg2 from "../../../asset/images/media/event/gen_ai/genai2.webp"
import Carouselimg3 from "../../../asset/images/media/event/gen_ai/genai3.webp"
import Carouselimg4 from "../../../asset/images/media/event/gen_ai/genai4.webp"



const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);


export default function GenAIConclaveAwards2024() {


    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };


    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>Decimal Point Analytics at Gen.ai Conclave & Awards 2024: Empowering AI Innovation</title>
                <meta name="description" content="Decimal Point Analytics was the Presenting Partner at Gen.ai Conclave & Awards 2024, where CEO Shailesh Dhuri discussed key Generative AI use cases." />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/decimal-point-analytics-at-gen-ai-conclave-awards-2024" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>



            <Box id='event-details' className='section sec_bg' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={8} md={8} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Events
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center'>
                                    Decimal Point Analytics at Gen.ai Conclave & Awards 2024
                                </Typography>

                            </Box>
                        </Grid>
                    </Grid>


                    <Box className='whitebx'>


                        <Typography variant='h6' className='black jst'>
                            We were proud to be the  <strong>Presenting Partner</strong> at the <a href='https://generativeaisummit.in/' target='_blank' rel="noopener noreferrer"> Generative AI Conclave & Awards 2024</a> hosted by <strong>UBS Forums Pvt. Ltd.</strong>, in partnership with  <strong>IBM</strong> and <strong>Redington Limited</strong>, on <strong>October 18th</strong>.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            Our CEO, <strong>Shailesh Dhuri</strong>, moderated a high-impact panel discussion titled <strong>"Approaches to Implementation: Right Generative AI Use Cases for Businesses."</strong>
                        </Typography>

                        <Typography variant='h6' className='black jst' gutterBottom>
                            The session explored:
                            <ul>
                                <li>
                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        How leaders identified and prioritized the most effective AI use cases.
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Strategies that simplified Generative AI implementation.
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Practical ways businesses optimized processes and drove innovation using AI.
                                    </Typography>
                                </li>
                            </ul>
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            As the presenting partner, Decimal Point Analytics reaffirmed its commitment to pioneering AI-driven solutions that empower businesses to maximize Generative AI’s transformative potential.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            It was an inspiring event filled with thought leadership, engaging discussions, and forward-looking insights on the future of Generative AI.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            <strong>Catch the Highlights!</strong>
                        </Typography>

                        <Box className='al_center'>
                            <IconButton className='blue' onClick={handleClickOpen}>
                                <CameraAltOutlinedIcon />
                            </IconButton>
                        </Box>

                        <Box className='al_center'>
                            <Typography className='blink_text'>Click Here...</Typography>
                        </Box>


                    </Box>
                    <Eventback />


                </Container>
            </Box>

            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} className="b_modal teammodal">
                <DialogTitle className="feedname" id="customized-dialog-title" onClose={handleClose}>
                </DialogTitle>
                <DialogContent>
                    <Box>
                        <Grid container spacing={2}>
                            <Carousel itemsToShow={1} animateOut="slideOutDown"
                                animateIn="flipInX">
                                <Box className="boxcontent" style={{ padding: '0px' }}>
                                    <Box className='eventbg' style={{ backgroundImage: 'url(' + Carouselimg1 + ')', }}></Box>
                                </Box>

                                <Box className="boxcontent" style={{ padding: '0px' }}>
                                    <Box className='eventbg' style={{ backgroundImage: 'url(' + Carouselimg2 + ')', }}></Box>
                                </Box>

                                <Box className="boxcontent" style={{ padding: '0px' }}>
                                    <Box className='eventbg' style={{ backgroundImage: 'url(' + Carouselimg3 + ')', }}></Box>
                                </Box>

                                <Box className="boxcontent" style={{ padding: '0px' }}>
                                    <Box className='eventbg' style={{ backgroundImage: 'url(' + Carouselimg4 + ')', }}></Box>
                                </Box>

                            </Carousel>
                        </Grid>
                    </Box>
                </DialogContent>
            </Dialog>


        </>
    );
}
