import { Container, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';

import Infoback from '../../../Component/Infoback'
import Writemessage3 from '../../../Component/Writemessage3';
import Aos from 'aos'
import laserpdf from '../../../asset/images/insight/pdf/laser_therapy.jpg'
import { Helmet } from "react-helmet";


export default function Laser_Therapy() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    return (
        <>

<Helmet>
<meta charset="UTF-8"/>
<h1>Laser Therapy Infographics - Understanding the Benefits and Applications" </h1>
<title>Laser Therapy Infographics - Understanding the Benefits & Applications </title>
<meta name="description" content="Discover the benefits of Laser Therapy with our infographics. Get an overview of the latest research, treatment options, and patient outcomes." />
<meta name="keywords" content="cancer terms
low level laser therapy
cold laser therapy
cold laser
cold laser therapy device
low level laser therapy devices
cold laser treatment" />
<meta name="author" content="Decimal Point Analytics" />
<link rel="canonical" href="https://www.decimalpointanalytics.com/infographics/laser-therapy" />

<meta name="viewport" content="width=device-width, initial-scale=1.0"/>
</Helmet>




            <Box className='section sec_bg' component='section'>
                <Typography variant='h3' className='subhead skblue al_center'>
                    Infographic
                </Typography>
                <Typography variant='h1' className='blue mainhead al_center'>
                    Laser Therapy
                </Typography>
                <Container maxWidth='lg' className='bdr'>

                    <Writemessage3 pageVisited="Laser Therapy"/>

                    <Box className='whitebx'>

                        <Box className='al_center'>
                            <img src={laserpdf} className='topbanner' alt='Laser PDF' />
                        </Box>

 
 
                    </Box>


                    <Infoback />



                </Container>
            </Box>


        </>
    );
}
