import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Helmet } from 'react-helmet';

import Casestudyback from '../../../../Component/Casestudyback'

import Banner from "../../../../asset/images/insight/case_studies/banner_automating_fund.webp"
import Infographics from "../../../../asset/images/insight/case_studies/automating_fund_classification.webp"
import { Link } from 'react-router-dom';



export default function AutomatingFund() {



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet><meta charset="UTF-8" />
                <meta charset="UTF-8" />
                <title>Automate Fund Classification for 2,600+ Funds with Insights and Efficiency</title>
                <meta name="description" content="Discover how financial data automation improved data accuracy and efficiency for a leading fund provider in this insightful case study." />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/case-studies/automating-fund-classification-for-2600-funds-enhancing-insights-and-efficiency" />
            </Helmet>

            <Box id="case-studies" className='section sec_bg new_cs' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={3} alignItems="top" justifyContent='center'>
                        <Grid item lg={9} md={9} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Success Stories
                                </Typography>
                                <Typography className='mainhead blue al_center'>
                                    Automating Fund Classification <span className='lower'>for</span> 2,600+ Funds, Enhancing Insights <span className='lower'>and</span> Efficiency
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Box >
                        <img src={Banner} className='w100' alt='Banner' />
                    </Box>


                    <Box className='whitebx'>
                        <Box>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Executive Summary
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                A leading fund database provider, managing approximately $37 trillion in AUM, needed precise classifications for various fund types. By implementing financial data automation for fund classifications, Decimal Point Analytics improved efficiency and accuracy in delivering insights to high-net-worth individuals and institutional clients.
                            </Typography>
                        </Box>


                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                The Challenge
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                The provider faced several challenges in fund classification:
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Inaccurate Classifications:</strong> Precise classifications of bond funds were necessary to differentiate between corporate and government investments.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Demand for Compliance: </strong> Increasing demand for SFDR reporting and regulatory compliance required extensive data collection and analysis.
                                        </Typography>
                                    </li>

                                </ul>
                            </Typography>
                        </Box>

                        <Box mt={3} className='mob_card'>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Solution: Automated Fund Classification
                            </Typography>

                            <Grid container direction="row"
                                justifyContent="center"
                                alignItems="center">

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className="al_center" my={3}>
                                        <img src={Infographics} className="topbanner" alt="Solution: Automated Fund Classification" />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box mt={1}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Results
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Enhanced Operational Efficiency: Automation </strong>  significantly increased productivity while ensuring unparalleled accuracy.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Faster Insights Delivery:  </strong> The <strong>SFDR reporting</strong> task was completed in 2.5 weeks, cutting the original 6-week timeline.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Reliable Data for Decision-Making:   </strong>  Improved data reliability enabled better tracking of trends and flows derived from the dataset.
                                        </Typography>
                                    </li>

                                </ul>
                            </Typography>
                        </Box>

                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Key Takeaways
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Superior Automation: Financial data automation </strong> enhanced operational efficiency and accuracy in fund classifications.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Faster Data Insights:  </strong> Accelerated delivery of SFDR compliance classifications provided timely insights for clients.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Informed Decision-Making: </strong>  Reliable data enabled high-net-worth individuals and institutions to make informed investment decisions.
                                        </Typography>
                                    </li>
                                </ul>
                                <Typography variant='h6' className='black jst'>
                                    With <Link to="/" className='link'>Decimal Point Analytics</Link>’ innovative solutions, they improved compliance and expedited insights delivery, ultimately supporting informed investment decisions.
                                </Typography>

                            </Typography>

                            <Typography variant='h6' className='black jst' gutterBottom>
                                <Link to="/contact-us#contact_form" className="link">Reach out now</Link>  to explore how we can help your organization streamline operations and enhance data accuracy!
                            </Typography>

                        </Box>

                    </Box>


                    <Casestudyback />

                </Container>
            </Box>


        </>
    );
}