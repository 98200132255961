import { Container, Typography, Box, Grid, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


import Writemessage3 from '../../../Component/Writemessage3';

import Blogback from '../../../Component/Blogback'

import { Helmet } from 'react-helmet';
export default function Incentive_structure_for_AI() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>


<Helmet>
<meta charset="UTF-8"/>
<h1>Incentive Structure For Building Artificial Intelligence With A Sense Of Morality </h1>
<title>Incentive Structure For Building AI With A Sense Of Morality</title>
<meta name="description" content="Discover the importance of incorporating morality into AI systems. Explore the potential risks of AI without moral context and the role of government support." />
<meta name="keywords" content="Artificial Intelligence, AI, morality, biases, chatbots, societal usefulness, moral behavior, commercial benefit, government support, open-source AI APIs, moral schools, tax revenues, governance, implementation" />
<meta name="author" content="Decimal Point Analytics" />
<link rel="canonical" href="https://www.decimalpointanalytics.com/blog/incentive-structure-for-ai"/>

<meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        </Helmet>


        <Box id="blog-page" className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>

                <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                <Typography variant='h3' className='subhead skblue al_center'>
                    Blog
                </Typography>
                <Typography variant='h1' className='blue mainhead al_center mb0'>
                    Incentive Structure For  Building Artificial Intelligence   With A Sense Of Morality
                </Typography>
                <Typography variant='h4' className='blue al_center'>
                    September 01, 2020
                </Typography>
                    </Grid>
                    </Grid>

                    <Box className='whitebx'>


                        <Typography variant='h6' className='black jst'>Recently, our Head of Talent Management pointed out to me that AI applications, mainly chatbots, seem carry the same biases that humans display.</Typography>

                        <Typography variant='h6' className='black jst'>The irony is that it is possible, using current technologies, to engrain in AI a superior sense of morality, with logically consistent and hierarchical set of goals, and hence it is possible that AI to have minimal biases even now.</Typography>

                        <Typography variant='h6' className='black jst'>Before we move further, it would not be out of place here to point out that The Three Laws of Robotics propounded by Asimov are sufficient for a Sci-Fi plot; but in real life, the societal usefulness of AI will require a far complex set of rules of moral behavior and political correctness; which will vary according to context of time, geography and intended audience.</ Typography>

                        <Typography variant='h6' className='black jst'>  However, most applications of AI are currently built without any moral context supplied to it. This carries a danger that these AI tools may behave inappropriately in the wild. Given that AI’s response times are a fraction of second, it may be too late for humans to notice the faults and take corrective actions.</Typography>

                        <Typography variant='h6' className='black jst'>As mentioned earlier, it is possible to engrain the AI with a system of morality which can be superior to the morality of a median human being. AI can be taught modern philosophers such as Nietzsche and Ayn Rand, if a strong enough commercial case can be made. However, for most applications (certainly the applications that my firms are developing), the commercial benefit of adding moral dimensions fall short of the cost of doing so.</Typography>

                        <Typography variant='h6' className='black jst'>It is here that the government can prove itself useful for once. Instead of wasting precious tax revenue on providing subsidies for the vote bank or to the financial backers; those revenues, as second best alternative application of tax revenues can be used to support various university groups who can develop open source AI APIs in various moral schools which can be plugged by commercial entities as per their preference in their AI application, as a set of limiting conditions for their AI app.</ Typography>

                        <Typography variant='h6' className='black jst'> AI is the most important tool ever that humans have developed. Frankly, I find, the current debate surrounding the effect of AI on human society shallow, since it assumes that we humans cannot or will not preemptively change social, economic, governance and moral structure to ensure smooth adoption of AI for greater good of humanity.</Typography>

                        <Typography variant='h6' className='black jst'> In these pages, I will be discussing on weekly basis, the all-encompassing changes we need to engineer to ensure successful implementing of AI, after introducing today once such change that may be deemed necessary.</Typography>

                        <Typography variant='h6' className='black jst'>The first best use of tax revenues is to reduce incidence of tax revenues.</Typography>

                        {/* <Box mt={5}>
                            <Subscribe />
                        </Box> */}



                    </Box>


                    <Box className='pages'>
                    <Writemessage3 pageVisited="Incentive Structure For Building Artificial Intelligence With A Sense Of Morality"/>
                    </Box>



                    <Blogback />

                </Container>
            </Box>

        </>
    );
}
