import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Helmet } from 'react-helmet';

import Casestudyback from '../../../../Component/Casestudyback'

import Banner from "../../../../asset/images/insight/case_studies/delivering_public.webp"
import Infographics from "../../../../asset/images/insight/case_studies/delivering_public_info.webp"
import { Link } from 'react-router-dom';



export default function DeliveringPublicFloatData() {



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet><meta charset="UTF-8" />
                <meta charset="UTF-8" />
                <title>Stay Ahead with 50-Hour Faster Public Float Data for Informed Decisions</title>
                <meta name="description" content="Discover how timely public float data transformed investment strategies, reducing delays and empowering smarter decisions in this detailed case study." />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/case-studies/delivering-public-float-data-50-hours-faster-empowering-smarter-investment-decisions" />
            </Helmet>

            <Box id="case-studies" className='section sec_bg new_cs' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={3} alignItems="top" justifyContent='center'>
                        <Grid item lg={9} md={9} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Success Stories
                                </Typography>
                                <Typography className='mainhead blue al_center'>
                                    Delivering Public Float Data 50 Hours Faster, Empowering Smarter Investment Decisions
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Box >
                        <img src={Banner} className='w100' alt='Banner' />
                    </Box>


                    <Box className='whitebx'>
                        <Box>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Executive Summary
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                A leading investment firm needed a streamlined process to calculate public float values to keep pace with market shifts. By implementing an efficient calculation system, Decimal Point Analytics provided timely updates on stock splits and market changes, empowering clients with precise insights for smarter, faster decision-making.
                            </Typography>
                        </Box>


                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                The Challenge
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                The firm faced several challenges related to <strong>public float data</strong>:
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Inaccurate Data:</strong>Delays between corporate actions and updates led to discrepancies in data representation, particularly during events like stock splits and buybacks.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Timeliness Issues: </strong> Investors faced delayed and inaccurate <strong>float values</strong>, hindering effective decision-making.
                                        </Typography>
                                    </li>

                                </ul>
                            </Typography>
                        </Box>

                        <Box mt={3} className='mob_card'>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Solution: Streamlined Public Float Calculation
                            </Typography>

                            <Grid container direction="row"
                                justifyContent="center"
                                alignItems="center">

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className="al_center" my={3}>
                                        <img src={Infographics} className="topbanner" alt="Solution: Streamlined Public Float Calculation" />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box mt={1}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Results
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Faster Decision-Making:  </strong> Clients experienced quicker access to accurate public float values, enabling timely and informed decisions.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Enhanced Competitive Edge:  </strong> The firm gained a significant advantage in the market due to faster data delivery.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Improved Client Satisfaction:   </strong> The commitment to speed and reliability redefined service standards.
                                        </Typography>
                                    </li>

                                </ul>
                            </Typography>
                        </Box>

                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Key Takeaways
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Timely Data Access: </strong> Clients benefitted from updates delivered significantly faster than competitors.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Informed Decisions:  </strong> Accurate public float values empowered clients to make informed decisions quickly.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Elevated Client Trust: </strong> Reliability of data boosted overall client satisfaction.
                                        </Typography>
                                    </li>
                                </ul>
                                <Typography variant='h6' className='black jst'>
                                <Link to="/" className='link'>Decimal Point Analytics</Link> streamlined their calculation process, enhancing accuracy and responsiveness to market changes.
                                </Typography>

                            </Typography>

                            <Typography variant='h6' className='black jst' gutterBottom>
                                <Link to="/contact-us#contact_form" className="link">Contact us</Link> to discover how we can help your organization gain a competitive edge with timely data!
                            </Typography>

                        </Box>

                    </Box>


                    <Casestudyback />

                </Container>
            </Box>


        </>
    );
}