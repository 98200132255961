import { Grid, Typography, Box, Button, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';



export default function LoanUnderwriting() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);



    // This Function used to set top offset to id 
    // after clicking on the banner links
    const scrollToElement = (id) => {
        const element = document.getElementById(id);
        if (element) {
            const stickyItemsHeight = 70;
            const offset = element.getBoundingClientRect().top - stickyItemsHeight;


            setTimeout(() => {
                window.scrollBy({ top: offset, behavior: 'smooth' });
            }, 50);
        }
    };




    useEffect(() => {
        const { hash } = window.location;
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                const offset = 70;
                const elementPosition = element.getBoundingClientRect().top;
                const offsetPosition = elementPosition - offset;
                window.scrollBy({
                    top: offsetPosition,
                    behavior: "smooth",
                });
            }
        }
    }, []);


    return (
        <>



            <Box id='sba_solutions' mt={4}>

            <Box pb={3}>
                                    <Typography variant='h6' className='black jst mb0'>
                                        SBA Loan Underwriting is available as a stand-alone service or can be added to a full-service lender partnership. Our Loan Underwriting Process is divided into four phases:
                                    </Typography>
                                </Box>
                                
                <Grid container spacing={4} alignItems="top" justifyContent='center'>

                    <Grid item lg={3} md={3} sm={6} xs={12}>
                        <Box className='loan_underwritingcardbx'>
                            <Box className='al_right'>
                                <Typography variant='h1' className='black2 fw6'>
                                    01
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant="h4" className="black al_left two">Loan Eligibility and Structuring</Typography>
                                <Box>
                                    <Typography variant='h6' className='black al_left'>
                                        <ul>
                                            <li> Assistance in determining eligibility for the SBA 7(a) program.</li>
                                            <li>	Advice on properly structuring the transaction to fit the SBA 7(a) program parameters</li>
                                        </ul>
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item lg={3} md={3} sm={6} xs={12}>
                        <Box className='loan_underwritingcardbx'>
                            <Box className='al_right'>
                                <Typography variant='h1' className='black2 fw6'>
                                    02
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant="h4" className="black al_left two">Intake and Pre-Assessment</Typography>
                                <Box>
                                    <Typography variant='h6' className='black al_left'>
                                        <ul>
                                            <li> Initial submission review and cursory analysis to ensure enough information for formal underwriting.</li>
                                        </ul>
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item lg={3} md={3} sm={6} xs={12}>
                        <Box className='loan_underwritingcardbx'>
                            <Box className='al_right'>
                                <Typography variant='h1' className='black2 fw6'>
                                    03
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant="h4" className="black al_left two">	Credit Analysis and Memo</Typography>
                                <Box>
                                    <Typography variant='h6' className='black al_left'>
                                        <ul>
                                            <li> Thorough review, financial spreading, and crafting of an SBA-compliant draft credit memorandum.</li>
                                        </ul>
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item lg={3} md={3} sm={6} xs={12}>
                        <Box className='loan_underwritingcardbx'>
                            <Box className='al_right'>
                                <Typography variant='h1' className='black2 fw6'>
                                    04
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant="h4" className="black al_left two">Lender Approval</Typography>
                                <Box>
                                    <Typography variant='h6' className='black al_left'>
                                        <ul>
                                            <li> Final review and approval process, transitioning to our Processing department for SBA authorization and closing</li>
                                        </ul>
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>


                </Grid>


            </Box>

            <Box my={3} className='al_center'>
                <Link to="/sba#sbacontactus" onClick={() => scrollToElement('sbacontactus')}>
                    <Button variant="contained" className='dpabtn_sm1'>
                        Start Now
                    </Button>
                </Link>
            </Box>


        </>
    );
}