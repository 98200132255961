import { Divider, Container, Grid, Typography, Box } from "@material-ui/core";
import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import terms from "../../../asset/images/new_banner/terms_banner.webp";
import Annexure from "./SmartCloudAnnexure";

export default function SmartCloudTerms() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <>
      {/* <Box className='mainsection' component='section'>
                <Container maxWidth='lg'>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item lg={5} md={6} sm={6} xs={12}>
                            <Box>
                                <Typography variant='h1' className='blue'>
                                    Terms
                                </Typography>


                            </Box>
                        </Grid>
                        <Grid item lg={7} md={6} sm={6} xs={12}>
                            <Box className='al_right'>
                                <img src={indexbanner} className='topbanner' alt='Index Banner' />
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box> */}

      <Box className="banner_bx">
        <Box
          style={{ backgroundImage: "url(" + terms + ")" }}
          className="banner_img"
        >
          <Box className="img_over bl_overlay">
            <Grid
              container
              spacing={0}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item lg={4} md={5} sm={6} xs={8}>
                <Box className="al_center">
                  <Typography className="white" variant="h1">
                    Terms
                  </Typography>
                  {/* <Box mt={'10px'}>
                                        <Typography className="white" variant="h4">&ensp;</Typography>
                                    </Box> */}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      <Box id="eazydata">
        <Container maxWidth="lg">
          <Typography className="pagecontent jst">
            This Terms of Service (“<strong>Terms</strong>”) has been last
            updated on 06/05/2023.
          </Typography>

          <Divider />

          <Box className="jst" pt={1}>
            <Typography variant="h6" className="pagesubhead col1">
              1. General Terms
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints"> 1.1 </Typography>
            <Typography className="pagecontent jst">
              These Terms create a binding agreement between you and/or your
              representatives (collectively, “<strong>You</strong>”, “
              <strong>Your</strong>” and “<strong>User</strong>”) and M/S.
              Decimal Point Analytics Private Limited (Decimal Point), a private
              company incorporated under provisions of the Companies Act, 2013,
              and having its registered office at 5A, B WING TRADESTAR BUILDING,
              ANDHERI KURLA ROAD J.B NAGAR, ANDHERI EAST MUMBAI Mumbai City MH
              IN 400059(“
              <strong>Company</strong>”, “<strong>We</strong>”, “
              <strong>Us</strong>” and “<strong>Our</strong>”).
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints"> 1.2 </Typography>
            <Typography className="pagecontent jst">
              These Terms will govern Your use of, including any other website,
              as well as media channel, mobile website or mobile application
              related, linked or other connected thereto (“
              <strong>Platform</strong>”), which is owned by Us, and
              subsequently the use of Services as available to You through Our
              Platform.
            </Typography>
          </Box>

          <Divider />

          <Box className="jst" pt={1}>
            <Typography variant="h6" className="pagesubhead col1">
              2. Definations
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints"> 2.1 </Typography>
            <Typography className="pagecontent jst">
              “<strong>Account</strong>” means any accounts or instances created
              by the User or on User’s behalf for access to the Platform and use
              of the Services.
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints"> 2.2 </Typography>
            <Typography className="pagecontent jst">
              “<strong>Company</strong>” shall have the meaning ascribed to it
              in clause 1.1 of these Terms.
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints"> 2.3 </Typography>
            <Typography className="pagecontent jst">
              “<strong>Feedback</strong>” means feedback, innovations or
              suggestions sent by You regarding the attributes, performance or
              features of Our Platform.
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints"> 2.4 </Typography>
            <Typography className="pagecontent jst">
              “<strong>Platform</strong>” shall have the meaning ascribed to it
              in clause 1.2 of these Terms.
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints"> 2.5 </Typography>
            <Typography className="pagecontent jst">
              “<strong>Service</strong>” means pipeline cleaning services and
              other ancillary services, more particularly described under clause
              4 of these Terms.
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints"> 2.6 </Typography>
            <Typography className="pagecontent jst">
              “<strong>Terms</strong>” shall have the meaning ascribed to it
              hereinabove.
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints"> 2.7 </Typography>
            <Typography className="pagecontent jst">
              “<strong>User</strong>” shall have the meaning ascribed to it in
              clause 1.1 of these Terms.
            </Typography>
          </Box>

          <Divider />

          <Box className="jst" pt={1}>
            <Typography variant="h6" className="pagesubhead col1">
              3. Acknowledgment
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints"> 3.1 </Typography>
            <Typography className="pagecontent jst">
              These Terms are made in consonance with the (Indian) Information
              Technology Act, 2000 read with applicable rules; General Data
              Protection Regulation, 2016, The California Consumer Privacy Act
              of 2018, and the applicable laws of UAE, Qatar, and Saudi Arabia.
              These Terms additionally set out the rights and obligations of all
              Users regarding access to the Platform and use of the Service.
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints"> 3.2 </Typography>
            <Typography className="pagecontent jst">
              The User’s access to the Platform and use of the Service is
              conditioned on acceptance of and compliance with these Terms.
              These Terms apply to all Users who access Our Platform and/or use
              Our Services.
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints"> 3.3 </Typography>
            <Typography className="pagecontent jst">
              For the User to accept these Terms, the User shall
              <ol type="i">
                <li>
                  be of majority age (i.e., 18 years of age and above or as may
                  be the age of majority in Your jurisdiction); and
                </li>

                <li>
                  not already be restricted by Us from using Our Platform and
                  Services.
                </li>
              </ol>
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints"> 3.4 </Typography>
            <Typography className="pagecontent jst">
              You can accept these Terms by clicking on “I AGREE” or by actually
              availing Our Service. In the case that You do not accept these
              Terms, You shall not avail Our Services as provided through Our
              Platform.
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints"> 3.5 </Typography>
            <Typography className="pagecontent jst">
              Access to the Platform and use of the Service is also conditioned
              on the Users acceptance of and compliance with the Privacy Policy
              of the Company available at{" "}
              <a
                href="https://saas.decimalpointanalytics.com/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                https://saas.decimalpointanalytics.com/privacy
              </a>
              . The Privacy Policy describes the policies and procedures for the
              collection, use, and disclosure of the Users personal information
              when the User uses the Platform.
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints"> 3.6 </Typography>
            <Typography className="pagecontent jst">
              These Terms are subject to modifications, at Our sole and absolute
              discretion. Further, We may from time to time formulate new
              policies for availing all or any of the Services. The most current
              version of these Terms will supersede all previous versions. You
              understand that Your continued use of the Services after any
              changes to these Terms or addition of new policies constitutes
              Your acceptance to be bound by the most recent version of these
              Terms. If these Terms (including changes and additions thereto)
              are not acceptable to You, You have the absolute right to
              terminate Your Account in accordance with terms.
            </Typography>
          </Box>

          <Divider />

          <Box className="jst" pt={1}>
            <Typography variant="h6" className="pagesubhead col1">
              4. Description of Services
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints"> 4.1 </Typography>
            <Typography className="pagecontent jst">
              {" "}
              Our Platform is a SaaS application, accessible via web and APIs.
              We are engaged in providing the following services (collectively,{" "}
              <strong>“Services”</strong>):
              {/* <ol type="a">
                <li>
                  <strong> Standard Service:</strong> We aim at providing
                  services related to data pipelines. A data pipeline downloads
                  data from a source, cleans it and uploads it to the
                  destination on an automated schedule.
                </li>

                <li>
                  <strong> Managed Service:</strong> We aim to analyze and
                  manage the data pipelines for the Users and ancillary services
                  related thereto.
                </li>
              </ol> */}
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints"> 4.2 </Typography>
            <Typography className="pagecontent jst">
              The Company shall offer the aforementioned Services in the form of
              subscription model and as per the terms available at Annexure here
              under.
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints"> 4.3 </Typography>
            <Typography className="pagecontent jst">
              {" "}
              The User hereby agrees and acknowledges that We reserve the right
              to modify, remove or rename Our Services at Our sole discretion.{" "}
            </Typography>
          </Box>

          <Divider />

          <Box className="jst" pt={1}>
            <Typography variant="h6" className="pagesubhead col1">
              5. Sevices Updates{" "}
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints"> 5.1 </Typography>
            <Typography className="pagecontent jst">
              {" "}
              The Services may, at any time, temporarily be unavailable due to
              scheduled downtime for upgrades and maintenance, any technical
              faults or interruptions, User’s geographical location, or
              prohibitions/restrictions under applicable law or policy in which
              case the Company shall use commercially reasonable endeavors to
              notify the User in advance.{" "}
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints"> 5.2 </Typography>
            <Typography className="pagecontent jst">
              {" "}
              Notwithstanding anything to the contrary contained elsewhere, the
              Company shall not be liable for unavailability of the Services
              caused by circumstances beyond its reasonable control, such as,
              but not limited to, acts of God, acts of government, acts of
              terror or civil unrest, or any reason as stipulated in clause 5.1
              of these Terms.{" "}
            </Typography>
          </Box>

          <Divider />

          <Box className="jst" pt={1}>
            <Typography variant="h6" className="pagesubhead col1">
              6. User Accounts
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints"> 6.1 </Typography>
            <Typography className="pagecontent jst">
              {" "}
              We believe in providing simple and easy access to Our Services.
              The Platform is offering multiple types of Services as provided
              under clause 4 of these Terms.{" "}
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints"> 6.2 </Typography>
            <Typography className="pagecontent jst">
              {" "}
              The Standard Services (as provided through the Basic Account and
              its terms available at Annexure hereunder) are easily accessible
              without a subscription and does not involve sharing any payment
              details. However, for any additional services on the Standard
              Services and for the procurement of Managed Services (collectively
              referred to as <strong> “Paid Services” </strong> and which
              basically refers to the services offered though Standard Account
              And Managed Service Account as per its terms available at Annexure
              hereunder), the User shall be subject to the terms as provided
              under Annexure hereunder.{" "}
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints"> 6.3 </Typography>
            <Typography className="pagecontent jst">
              {" "}
              To access any of the Paid Services, You can sign-up by using the
              online sign-up process available at our product website After
              taking all the relevant information from You, We will register and
              create an Account on the Platform for Your access to the Platform
              and to avail and use the Services.{" "}
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints"> 6.4 </Typography>
            <Typography className="pagecontent jst">
              {" "}
              Pursuant to the creation of an Account, We will provide an
              one-time password, password and third party authentication option
              such as google sign to You for Your access to the Platform and
              Services provided thereof.
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints"> 6.5 </Typography>
            <Typography className="pagecontent jst">
              {" "}
              When the User creates an Account, the User must provide
              information that is accurate, complete, and current at all times.
              Further, the User shall be obligated to share only such
              information which it is lawfully authorized to disclose. In the
              event that the User provides any information which
              <ol>
                <li>is inaccurate, incomplete, or not updated; and/or</li>

                <li>
                  is not authorized to disclose, it shall constitute a breach of
                  these Terms.
                </li>
              </ol>{" "}
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints"> 6.6 </Typography>
            <Typography className="pagecontent jst">
              {" "}
              In the event of a breach of these Terms in the manner stipulated
              under clause 6.5 of these Terms, the Company reserves the right to
              <ol>
                <li>
                  suspend Your access to the Platform and the Services provided
                  thereof;
                </li>
                <li>immediately terminate Your Account; and/or</li>
                <li>
                  report You to law enforcement authorities or initiate
                  necessary legal actions / appropriate legal proceedings
                  against You in accordance with the applicable laws.
                </li>
              </ol>
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints"> 6.7 </Typography>
            <Typography className="pagecontent jst">
              {" "}
              User will be provided with only one (1) Account that will be in
              Users’ name.
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints"> 6.8 </Typography>
            <Typography className="pagecontent jst">
              {" "}
              The User will not disclose the Credentials to any third party.
              Further, the User is solely responsible for safeguarding the
              Credentials to access the Platform and use the Services and for
              any activities or actions under the User’s Account.
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints"> 6.9 </Typography>
            <Typography className="pagecontent jst">
              {" "}
              The User must notify the Company immediately upon becoming aware
              of any breach of security or unauthorized use of the Account.
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints">
              {" "}
              6.10{" "}
            </Typography>
            <Typography className="pagecontent jst">
              The User hereby expressly consents to receive communications and
              newsletters from the Company by SMS, e-mails, phone calls, in-app
              notifications or any other means. You can restrict such
              communications and newsletters only by de-registering Your
              Account.
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints">
              {" "}
              6.11{" "}
            </Typography>
            <Typography className="pagecontent jst">
              {" "}
              Any person who is Our competitor and a representative of Our
              competitors (in any capacity) is not allowed to access or use the
              Services in any case.
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints">
              {" "}
              6.12{" "}
            </Typography>
            <Typography className="pagecontent jst">
              {" "}
              You can always choose to terminate and once You terminate Your
              Account, You shall cease to have access to any of Our Services
              through the Platform.
            </Typography>
          </Box>

          <Divider />

          <Box className="jst" pt={1}>
            <Typography variant="h6" className="pagesubhead col1">
              7. Charges and Payment
            </Typography>
          </Box>

          <Typography className="pagecontent jst">
            For the purposes of availing the Paid Services, the User will be
            liable to pay such fees as specified in Our pricing plan available
            on our products and pursuant to the terms specified in Annexure
            hereunder. The pricing plan shall be subject to modification which
            shall be promptly communicated to the User.{" "}
          </Typography>

          <Divider />

          <Box className="jst" pt={1}>
            <Typography variant="h6" className="pagesubhead col1">
              8. Intellectual Property
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints"> 8.1 </Typography>
            <Typography className="pagecontent jst">
              {" "}
              The Platform and its original content, including but not limited
              to all source code, databases, functionality, software, any
              information related to the Services, website designs, audio,
              video, text, photographs, and graphics, excluding any content
              provided by the User or other users (<strong>“Content”</strong>)
              and the trademarks, service marks, and logos contained therein (
              <strong>“Marks”</strong>) are owned or controlled by Us or
              licensed to Us, and are protected by copyright and trademark laws
              and various other intellectual property rights, unfair competition
              laws and other applicable national and international laws and
              conventions and the same shall remain the exclusive property of
              the Company and its licensors.{" "}
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints"> 8.2 </Typography>
            <Typography className="pagecontent jst">
              The Company cannot and does not guarantee or warrant that files
              available for downloading through the Platform will be free of
              infection by software viruses or other harmful computer code,
              files or programs.{" "}
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints"> 8.3 </Typography>
            <Typography className="pagecontent jst">
              The User agrees and acknowledges that the unauthorized use of the
              Content may violate copyright, trademark, and other applicable
              laws and shall cause irreplaceable damage to the Company.
              Therefore, the Company shall have right to initiate any
              proceedings or any other process under applicable law which is
              available to it and the same shall be in addition to recovery of
              damages for such unauthorized use of the Content.
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints"> 8.4 </Typography>
            <Typography className="pagecontent jst">
              {" "}
              The Content shall not be reverse-engineered, disassembled,
              decompiled, reproduced, transcribed, stored in a retrieval system,
              translated into any language or computer language, re-transmitted
              in any form or by any means (electronic, mechanical,
              photo-reproduction, recordation or otherwise), resold or
              redistributed for any commercial purpose whatsoever, without Our
              express prior written permission.{" "}
            </Typography>
          </Box>

          <Divider />

          <Box className="jst" pt={1}>
            <Typography variant="h6" className="pagesubhead col1">
              9. Restriction on Use
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints"> 9.1 </Typography>
            <Typography className="pagecontent jst">
              {" "}
              The User shall not
              <ol type="i">
                <li>
                  license, sublicense, sell, resell, rent, lease, transfer,
                  assign, distribute, time share or otherwise commercially
                  exploit or make the Platform and the Services provided thereof
                  available to any third-party;
                </li>

                <li>
                  modify, adapt, or hack the Platform or otherwise attempt to
                  gain or gain unauthorized access to the Platform and the
                  Services provided thereof or related systems or networks;
                </li>
                <li>
                  violate any law or regulation in connection with the Platform;
                </li>
                <li>
                  use the Platform to store or process any content that
                  infringes upon any person’s intellectual property rights or is
                  unlawful, racist, hateful, abusive, libelous, obscene, or
                  discriminatory; or “crawl,” “scrape,” or “spider” any page,
                  data, or portion of or relating to the Platform (through use
                  of manual or automated means); and/or
                </li>
                <li>
                  use the Platform and the Services provided thereof for any
                  other purpose not reasonably intended by the Company.
                </li>
              </ol>
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints"> 9.2 </Typography>
            <Typography className="pagecontent jst">
              {" "}
              The User agrees to access the Platform and use the Services solely
              for its own benefit, and not for the purpose of resale or other
              transfer or disposition to, or use by or for the benefit of, any
              other person or entity.{" "}
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints"> 9.3 </Typography>
            <Typography className="pagecontent jst">
              {" "}
              The User agrees not to use, transfer, distribute, or dispose of
              any information contained on the Platform in any manner that could
              compete with the Company’s Service.{" "}
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints"> 9.4 </Typography>
            <Typography className="pagecontent jst">
              {" "}
              The User shall not copy, reproduce, recompile, decompile,
              disassemble, reverse engineer, distribute, modify, upload to,
              create derivative works from, transmit, or in any way exploit any
              part of the Platform and/or its Content.{" "}
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints"> 9.5 </Typography>
            <Typography className="pagecontent jst">
              {" "}
              Modification of the Content and/or Trademarks would be a violation
              of the copyrights and other proprietary rights of the Company.
              Further, the User may not offer any part of or the whole Platform
              and the Services provided thereof for sale or distribute it for
              monetary considerations or otherwise over any other medium
              including but not limited to television, radio broadcast, any
              other website on the Internet.{" "}
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints"> 9.6 </Typography>
            <Typography className="pagecontent jst">
              {" "}
              The Platform and the Content contained therein shall not be used
              to construct a database of any kind. Further, the Content on the
              Platform shall not be stored (in its entirety or any part) in
              databases for access by any third party or distributed to any
              third party.{" "}
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints"> 9.7 </Typography>
            <Typography className="pagecontent jst">
              The User shall not use the Platform and the Services provided
              thereof for any illegal purpose or in any manner inconsistent with
              these Terms. The User will not use the Platform or the Content
              contained therein in unsolicited mailings or spam material.{" "}
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints"> 9.8 </Typography>
            <Typography className="pagecontent jst">
              {" "}
              The Company reserves the right, but shall not be obligated, to:
              <ol>
                <li>monitor the Platform for violations of these Terms;</li>

                <li>
                  take appropriate legal action against anyone who, at its sole
                  discretion, violates the applicable law or these Terms,
                  including without limitation, reporting such User to law
                  enforcement authorities;
                </li>

                <li>
                  refuse, restrict access to, limit the availability of, or
                  disable the access of the Platform and any Services or any
                  portion thereof without any liability and at its sole
                  discretion;
                </li>
                <li>
                  remove from the Platform or otherwise disable all files and
                  content that are excessive in size or are in any way
                  burdensome to the Company’s systems without any limitation,
                  notice, or liability and at its sole discretion; and
                </li>

                <li>
                  {" "}
                  manage the Platform in a manner designed to protect the
                  Company’s rights and property and to facilitate the proper
                  functioning of the Platform.
                </li>
              </ol>{" "}
            </Typography>
          </Box>

          <Divider />

          <Box className="jst" pt={1}>
            <Typography variant="h6" className="pagesubhead col1">
              10. License
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints">
              {" "}
              10.1{" "}
            </Typography>
            <Typography className="pagecontent jst">
              The User acquires absolutely no rights or licenses in or to the
              Platform and materials contained within the Platform other than
              the limited, non-commercial, revocable, personal,
              non-sub-licensable, non-transferable, non-exclusive right to
              utilize the Platform in accordance with these Terms.{" "}
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints">
              {" "}
              10.2{" "}
            </Typography>
            <Typography className="pagecontent jst">
              In the event that the User chooses to download any content from
              the Platform, the User must do so in accordance with these Terms
              and such download is licensed to the User by the Company only for
              the User’s own use in accordance with these Terms and does not
              transfer any other rights to the User.{" "}
            </Typography>
          </Box>

          <Divider />

          <Box className="jst" pt={1}>
            <Typography variant="h6" className="pagesubhead col1">
              11. Feedback
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst">
              If the User submits suggestions, feedbacks, ideas, comments,
              questions, or posts any information through the Platform (
              <strong>“Feedback”</strong>), the User agrees to grant the Company
              a non-exclusive, perpetual, irrevocable, royalty free,
              transferable, sub-licensable, worldwide right and license to use,
              reproduce, disclose, sub-license, distribute, modify and exploit
              such Feedback without restriction.
            </Typography>
          </Box>

          <Divider />

          <Box className="jst" pt={1}>
            <Typography variant="h6" className="pagesubhead col1">
              12. Confidentiality
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints">
              {" "}
              12.1{" "}
            </Typography>
            <Typography className="pagecontent jst">
              {" "}
              To the extent that the User receives or possesses any non-public
              information from the Company that is confidential or, given the
              nature of the information or circumstances surrounding its
              disclosure, reasonably should be considered to be confidential,
              such information is <strong>
                {" "}
                “Confidential Information”{" "}
              </strong>{" "}
              and must be handled according to these Terms. For the purpose of
              these Terms, <strong> “Confidential Information” </strong>{" "}
              includes but is not limited to:
              <ol type="a">
                <li>
                  {" "}
                  non-public information about the technology, customers,
                  business plans, marketing and sales activities, finances,
                  operations and other business information; and
                </li>
                <li>
                  {" "}
                  the existence and content of any discussions or negotiations
                  regarding the User’s Account and/or access of the Platform and
                  the Services provided thereof.
                </li>
              </ol>
              <br />
              ‘Confidential Information’ does not include any information that:
              <ol type="1">
                <li>
                  {" "}
                  is publicly available or becomes publicly available through no
                  action or fault of the User,
                </li>
                <li>
                  {" "}
                  was already in the User’s possession or known to the User
                  prior to being disclosed or provided to the User by or on
                  behalf of the other party, provided, that, the source of such
                  information or material was not bound by a contractual, legal
                  or fiduciary obligation of confidentiality to the
                  non-disclosing party or any other party with respect thereto,
                </li>
                <li>
                  {" "}
                  was or is obtained by the User from a third party, provided,
                  that, such third party was not bound by a contractual, legal
                  or fiduciary obligation of confidentiality to the
                  non-disclosing party or any other party with respect to such
                  information or material, or
                </li>
                <li>
                  {" "}
                  is independently developed by the User without reference to
                  the Confidential Information (in which case the onus of proof
                  shall lie solely with the User).
                </li>
              </ol>
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints">
              {" "}
              12.2{" "}
            </Typography>
            <Typography className="pagecontent jst">
              {" "}
              The User may not use Confidential Information except in connection
              with the access to the Platform and the Services provided thereof
              as permitted under these Terms or as separately authorized in a
              signed writing by the Company.
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints">
              {" "}
              12.3{" "}
            </Typography>
            <Typography className="pagecontent jst">
              {" "}
              The User agrees to not disclose Confidential Information after the
              cessation of usage of the Services through the Platform and/or
              after these Terms otherwise no longer apply. The User will take
              all reasonable measures to avoid disclosure or unauthorized use of
              Confidential Information.{" "}
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints">
              {" "}
              12.4{" "}
            </Typography>
            <Typography className="pagecontent jst">
              {" "}
              If the Company permits the User to use its Platform and the
              Services provided thereof, the User agrees that information
              provided relating to the Platform and the Services is Confidential
              Information, and the User specifically agrees, in addition to the
              other requirements set forth in these Terms, to not:
              <ol type="I">
                <li>
                  {" "}
                  access and use the Platform and/or the Service for
                  benchmarking or performance testing or to publicly disseminate
                  performance information or analysis from any source relating
                  to the Platform and/or the Services;{" "}
                </li>

                <li>
                  {" "}
                  modify or create derivative works of the Platform and/or the
                  Service thereof or remove any product identification,
                  proprietary, copyright or other notices contained in the
                  Platform and/or the Services; or{" "}
                </li>

                <li>
                  {" "}
                  allow any other individual to access the Platform or use the
                  Service.
                </li>
              </ol>
            </Typography>
          </Box>

          <Divider />

          <Box className="jst" pt={1}>
            <Typography variant="h6" className="pagesubhead col1">
              13. Third Party Applications
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints">
              {" "}
              13.1{" "}
            </Typography>
            <Typography className="pagecontent jst">
              {" "}
              You acknowledge that the Company’s Services integrate with certain
              third-party applications (
              <strong>"Third-Party Application(s)"</strong>) and that access and
              use of the Third-Party Applications may require acceptance of
              terms of service and privacy policies applicable to such
              Third-Party Applications (<strong>"Third-Party Terms"</strong>).
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints">
              {" "}
              13.2{" "}
            </Typography>
            <Typography className="pagecontent jst">
              {" "}
              The User is responsible for reading and understanding the
              Third-Party Terms before accessing or using any Third-Party
              Application. The User acknowledges and agrees that the Company is
              not liable for any Third-Party Applications.
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints">
              {" "}
              13.3{" "}
            </Typography>
            <Typography className="pagecontent jst">
              {" "}
              While the Company will try to provide the User with advance
              notice, whenever reasonably possible, the User acknowledges and
              agrees that the Company may, at any time, at its sole discretion
              and without any notice to the User, suspend, restrict or disable
              access to or remove from Services, any Third-Party Application,
              without any liability to the User, including without limitation
              for any loss of profits, revenue, data, goodwill or other
              intangible losses.
            </Typography>
          </Box>

          <Divider />

          <Box className="jst" pt={1}>
            <Typography variant="h6" className="pagesubhead col1">
              14. Privacy Policy
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints">
              {" "}
              14.1{" "}
            </Typography>

            <Typography className="pagecontent jst">
              {" "}
              By using the Platform, You agree to be bound by Our Privacy
              Policy, which is deemed to be incorporated into these Terms.{" "}
            </Typography>
          </Box>

          {/* <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints">
              {" "}
              14.2{" "}
            </Typography>
            <Typography className="pagecontent jst">
              {" "}
              Please be advised that the Platform is hosted in the United States
              of America or in such country as have specifically opted by you{" "}
              <strong>(“Host Nation”)</strong>. If You access the Platform from
              any other region of the world with laws or other requirements
              governing personal data collection, use, or disclosure that differ
              from applicable laws in the Host Nation, then through Your
              continued use of the Platform, You are transferring Your data to
              the Host Nation, and You agree to have Your data transferred to
              and processed in the Host Nation.
            </Typography>
          </Box>

          <Divider /> */}

          <Box className="jst" pt={1}>
            <Typography variant="h6" className="pagesubhead col1">
              15. Disclaimer and limitation liability
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints">
              {" "}
              15.1{" "}
            </Typography>

            <Typography className="pagecontent jst">
              {" "}
              <Typography className="pagecontent jst">
                {" "}
                YOU EXPRESSLY UNDERSTAND AND AGREE THAT THE USE OF THE SERVICES
                IS AT YOUR SOLE RISK. THE SERVICES ARE PROVIDED ON AN
                AS-IS-AND-AS-AVAILABLE BASIS. THE COMPANY EXPRESSLY DISCLAIMS
                ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED,
                INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF
                MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. THE
                COMPANY MAKES NO WARRANTY THAT THE SERVICES WILL BE
                UNINTERRUPTED, TIMELY, SECURE, OR ERROR FREE. USE OF ANY
                MATERIAL DOWNLOADED OR OBTAINED THROUGH THE USE OF THE SERVICES
                SHALL BE AT YOUR OWN DISCRETION AND RISK AND YOU WILL BE SOLELY
                RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM, MOBILE
                TELEPHONE, WIRELESS DEVICE OR DATA THAT RESULTS FROM THE USE OF
                THE SERVICES OR THE DOWNLOAD OF ANY SUCH MATERIAL. TO THE EXTENT
                PERMITTED BY APPLICABLE LAW, WE MAKE NO WARRANTIES OR
                REPRESENTATIONS ABOUT THE ACCURACY, RELIABILITY, TIMELINESS OR
                COMPLETENESS OF THE SERVICE’S CONTENT, THE CONTENT OF ANY SITE
                LINKED TO THE SERVICE, CONTRIBUTIONS, INFORMATION OR ANY OTHER
                ITEMS OR MATERIALS ON THE SERVICE OR LINKED TO BY THE SERVICE.
                WE ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY
                <ol type="A">
                  <li>
                    {" "}
                    ERRORS, MISTAKES OR INACCURACIES OF CONTENT AND MATERIALS,
                  </li>
                  <li>
                    {" "}
                    PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE
                    WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE
                    SERVICE,
                  </li>
                  <li>
                    {" "}
                    ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS
                    AND/OR ANY AND ALL PERSONAL INFORMATION STORED THEREIN,
                  </li>
                  <li>
                    {" "}
                    ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE
                    SERVICE,
                  </li>
                  <li>
                    {" "}
                    ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE, WHICH MAY BE
                    TRANSMITTED TO OR THROUGH THE SERVICE BY ANY THIRD PARTY,
                    AND/OR
                  </li>
                  <li>
                    {" "}
                    ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR
                    ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE
                    USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE
                    AVAILABLE VIA THE SERVICE.{" "}
                  </li>
                </ol>
              </Typography>
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints">
              {" "}
              15.2{" "}
            </Typography>
            <Typography className="pagecontent jst">
              {" "}
              THE USER AGREES THAT THE COMPANY SHALL, IN NO EVENT, BE LIABLE FOR
              ANY CONSEQUENTIAL, INCIDENTAL, INDIRECT, SPECIAL, PUNITIVE, OR
              OTHER LOSS OR DAMAGE WHATSOEVER OR FOR LOSS OF BUSINESS PROFITS,
              BUSINESS INTERRUPTION, COMPUTER FAILURE, LOSS OF BUSINESS
              INFORMATION, OR OTHER LOSS ARISING OUT OF OR CAUSED BY THE USERS
              USE OF OR INABILITY TO USE THE SERVICE AND THE SERVICES OF ANY
              THIRD-PARTY OR SERVICE PROVIDER SO ENGAGED UNDER THESE TERMS OF
              SERVICE, EVEN IF THE COMPANY HAS BEEN ADVISED OF THE POSSIBILITY
              OF SUCH DAMAGE. IN NO EVENT SHALL THE COMPANY’S ENTIRE LIABILITY
              TO YOU IN RESPECT OF ANY SERVICE, WHETHER DIRECT OR INDIRECT,
              EXCEED THE FEES PAID BY YOU.
            </Typography>
          </Box>

          <Divider />

          <Box className="jst" pt={1}>
            <Typography variant="h6" className="pagesubhead col1">
              16. Indemnity
            </Typography>
          </Box>

          <Typography className="pagecontent jst">
            The User agrees to indemnify and hold harmless the Company, its
            officers, directors, employees, suppliers, and affiliates, from and
            against any losses, damages, fines and expenses (including
            attorney's fees and costs) arising out of or relating to any claims
            that You have used the Platform and/or the Services in violation of
            another party's rights, in violation of any law, in violations of
            any provisions of these Terms herein, or any other claim related to
            Your use of the Platform and/or the Services, except where such use
            is authorized by the Company.
          </Typography>

          <Divider />

          <Box className="jst" pt={1}>
            <Typography variant="h6" className="pagesubhead col1">
              17. User Representations & Warranties
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst">
              The User acknowledges that:
              <ol type="a">
                <li>
                  {" "}
                  the Company does not guarantee the sequence, accuracy,
                  completeness, or timeliness of the information contained on
                  the Platform and/or provided through Our Service;{" "}
                </li>

                <li>
                  {" "}
                  the provision of certain parts of the Platform and/or the
                  Services is subject to the terms and conditions of other
                  agreements to which the Company is a party;{" "}
                </li>

                <li>
                  {" "}
                  none of the information contained on the Platform and/or
                  provided through Our Service constitutes a solicitation,
                  offer, opinion, or recommendation by the Company to buy or
                  sell any security, or to provide legal, tax, accounting, or
                  investment advice or services regarding the profitability or
                  suitability of any security or investment;{" "}
                </li>

                <li>
                  {" "}
                  the information provided on the Platform and/or provided
                  through Our Service is not intended for use by, or
                  distribution to, any person or entity in any jurisdiction or
                  country where such use or distribution would be contrary to
                  law or regulation. Accordingly, anything to the contrary
                  herein set forth notwithstanding, the Company, its suppliers,
                  agents, directors, officers, employees, representatives,
                  successors, and assigns shall not, directly or indirectly, be
                  liable, in any way, to the User or any other person for any:
                  <ol type="I">
                    <li>
                      inaccuracies or errors in or omissions on the Platform
                      and/or the Services including, but not limited to, quotes
                      and financial data;
                    </li>

                    <li>
                      delays, errors, or interruptions in the transmission or
                      delivery of Our Service provided through the Platform; or
                    </li>

                    <li>
                      loss or damage arising there from or occasioned thereby,
                      or by any reason of non-performance.
                    </li>
                  </ol>
                </li>
              </ol>
            </Typography>
          </Box>

          <Divider />

          <Box className="jst" pt={1}>
            <Typography variant="h6" className="pagesubhead col1">
              18 Termination
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints">
              {" "}
              18.1{" "}
            </Typography>
            <Typography className="pagecontent jst">
              {" "}
              The User agrees that the Company may terminate Your Account at any
              time with ninety (90) days’ notice. The User further agrees that
              the Company may terminate Your Account, without notice to the
              User, and/or suspend the Users access to the Platform and the
              Services provided thereof, at any time, effective immediately if
              the User violates any provisions of these Terms.
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints">
              {" "}
              18.2{" "}
            </Typography>
            <Typography className="pagecontent jst">
              {" "}
              The Company shall not be liable to the User or any third party for
              the termination or suspension of the Service, or any claims
              related to the termination or suspension of the Service. Upon
              termination of these Terms by the User or the Company, the User
              must discontinue its use of the Platform and the Services and
              destroy promptly all materials obtained from the Platform and the
              Services and any copies thereof.
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints">
              {" "}
              18.3{" "}
            </Typography>
            <Typography className="pagecontent jst">
              {" "}
              In the event of termination of Your Account, Users will not
              receive any credit or payment or refund from the Company.
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints">
              {" "}
              18.4{" "}
            </Typography>
            <Typography className="pagecontent jst">
              {" "}
              In the event of termination, You will have no claim whatsoever
              against the Company in respect of any such suspension or
              termination of Your subscription, but You shall settle all dues
              owed to the Company upon the termination of Your Account.
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints">
              {" "}
              18.5{" "}
            </Typography>
            <Typography className="pagecontent jst">
              {" "}
              Without limiting the Company of other remedies, You must pay the
              Company for all fees owed to Us and reimburse the Company for all
              losses and costs (including any and all the Company associate,
              officers or representatives time) and reasonable expenses
              (including but not limited to legal fees) related to investigating
              such breach and collecting such fees.
            </Typography>
          </Box>

          <Divider />

          <Box className="jst" pt={1}>
            <Typography variant="h6" className="pagesubhead col1">
              19. Governing law & Arbitration
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints">
              {" "}
              &nbsp;{" "}
            </Typography>
            <Typography className="pagecontent jst">
              These Terms shall be governed and construed according to English
              Laws and the Parties hereby submit to the exclusive jurisdiction
              of the courts in the city of London, United Kingdom. In case of a
              dispute under these Terms, both Parties may agree to resolve their
              disputes by means of arbitration in place of litigation. Any such
              arbitration proceedings shall be conducted under the Arbitration
              Act, 1996 of the United Kingdom and the award of such arbitration
              shall be binding on both parties.
            </Typography>
          </Box>

          <Divider />

          <Box className="jst" pt={1}>
            <Typography variant="h6" className="pagesubhead col1">
              20. Miscellaneous
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints">
              {" "}
              20.1{" "}
            </Typography>
            <Typography className="pagecontent jst">
              {" "}
              The User accepts that the Company has the right to change the
              content or technical specifications of any aspect of its Platform
              and/or Services at any time and at the Company’s sole discretion.
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints">
              {" "}
              20.2{" "}
            </Typography>
            <Typography className="pagecontent jst">
              {" "}
              The failure of the Company to exercise or enforce any right or
              provision of these Terms shall not constitute a waiver of such
              right or provision.
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints">
              {" "}
              20.3{" "}
            </Typography>
            <Typography className="pagecontent jst">
              {" "}
              Any violation of these Terms or any indecent action towards
              third-party service provider will not be tolerated and shall
              attract prompt and punitive disciplinary action as per applicable
              laws enforced in United Kingdom and including but not limited to
              termination of access to the Platform and the Services provided
              thereof as well as initiation of appropriate legal action.
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints">
              {" "}
              20.4{" "}
            </Typography>
            <Typography className="pagecontent jst">
              {" "}
              You, acknowledge that no joint venture, partnership, employment,
              or agency or “service provider-consumer” relationship is created
              or exists between You and the Company, as a result of the Your use
              of the Platform.
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints">
              {" "}
              20.5{" "}
            </Typography>
            <Typography className="pagecontent jst">
              {" "}
              The Company’s performance under these Terms is subject to existing
              laws and legal process and nothing contained in these Terms is in
              derogation of the Company's right to comply with law enforcement
              requests or requirements relating to the User’s use of the Company
              or information provided to or gathered by the Company with respect
              to such use.
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints">
              {" "}
              20.6{" "}
            </Typography>
            <Typography className="pagecontent jst">
              {" "}
              20.6. You shall not assign any rights or obligations arising under
              these Terms, whether by operation of law or otherwise, without the
              prior written consent of the Company. You agree that the Company
              may subcontract certain aspects of the Services to third-party
              service providers as per these Terms.{" "}
            </Typography>
          </Box>

          <Divider />

          <Box className="jst" pt={1}>
            <Typography variant="h6" className="pagesubhead col1">
              21. Headings
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints">
              {" "}
              &nbsp;{" "}
            </Typography>
            <Typography className="pagecontent jst">
              The section titles in these Terms are used solely for the
              convenience of the User and the Company and have no legal or
              contractual significance.
            </Typography>
          </Box>

          <Divider />

          <Box className="jst" pt={1}>
            <Typography variant="h6" className="pagesubhead col1">
              22. Severability
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints">
              {" "}
              &nbsp;{" "}
            </Typography>
            <Typography className="pagecontent jst">
              If any provision of these Terms is found invalid or unenforceable,
              that provision will be enforced to the maximum extent permissible,
              and the other provisions of these Terms will remain in force.
            </Typography>
          </Box>

          <Divider />

          <Box className="jst" pt={1}>
            <Typography variant="h6" className="pagesubhead col1">
              23. Grievance Redressal
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints">
              {" "}
              23.1{" "}
            </Typography>
            <Typography className="pagecontent jst">
              {" "}
              Any grievances in relation to these Terms can be sent to Mr.
              Narendra Joshi.{" "}
            </Typography>
          </Box>

          <Box className="flx_fs">
            <Typography className="pagecontent jst subpoints">
              {" "}
              23.2{" "}
            </Typography>
            <Typography className="pagecontent jst mobviewallign">
              {" "}
              Grievance letters can be mailed at{" "}
              <a
                href="mailto:narendra.joshi@decimalpointanalytics.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                narendra.joshi@decimalpointanalytics.com.{" "}
              </a>{" "}
            </Typography>
          </Box>

          <Divider />

          <Box className="jst" pt={1}>
            <Typography variant="h6" className="pagesubhead col1">
              24. Contact Us
            </Typography>
          </Box>

          <Box className="flx_fs" pb={"3%"}>
            <Typography className="pagecontent jst subpoints">
              {" "}
              &nbsp;{" "}
            </Typography>
            <Typography className="pagecontent jst">
              If You have any questions about these Terms, You can contact Us:
              <ol>
                <li>
                  By visiting this page on Our website:
                  <a
                    href="https://www.decimalpointanalytics.com/contact-us#contact_form "
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    https://saas.decimalpointanalytics.com/contact_us{" "}
                  </a>{" "}
                  {/* or{" "} */}
                </li>
                {/* <li>
                  By sending Us an email:{" "}
                  <a
                    href="mailto:support@eazydata.ai"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    support@eazydata.ai.
                  </a>
                </li> */}
              </ol>
            </Typography>
          </Box>
        </Container>
      </Box>
      <Box>
        <Annexure />
      </Box>
    </>
  );
}
