import React, { useEffect, } from "react";
import { AppBar, Container, Grid, Tabs, Tab, Typography, Box, TextField, } from "@material-ui/core";
import PropTypes from "prop-types";
import Aos from "aos";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom/cjs/react-router-dom";
import "aos/dist/aos.css";
import whyCP from "../../../asset/images/product/creditpulse/why_credit_pulse.webp"

import solution1 from "../../../asset/images/innovation/consultant/solutions/solution1.svg";
import solution2 from "../../../asset/images/innovation/consultant/solutions/solution2.svg";
import solution3 from "../../../asset/images/innovation/consultant/solutions/solution3.svg";
import solution4 from "../../../asset/images/innovation/consultant/solutions/solution4.svg";
import solution5 from "../../../asset/images/innovation/consultant/solutions/solution5.svg";
import solution6 from "../../../asset/images/innovation/consultant/solutions/solution6.svg";

import management1 from "../../../asset/images/innovation/consultant/management/management1.svg";
import management2 from "../../../asset/images/innovation/consultant/management/management2.svg";
import management3 from "../../../asset/images/innovation/consultant/management/management3.svg";
import management4 from "../../../asset/images/innovation/consultant/management/management4.svg";
import management5 from "../../../asset/images/innovation/consultant/management/management5.svg";
import management6 from "../../../asset/images/innovation/consultant/management/management6.svg";
import management7 from "../../../asset/images/innovation/consultant/management/management7.svg";
import management8 from "../../../asset/images/innovation/consultant/management/management8.svg";
import management9 from "../../../asset/images/innovation/consultant/management/management9.svg";

import Success from "../../../asset/images/innovation/consultant/success.webp";

import ProductCTAA from "../../../Component/ProductCTAA";
import { Autocomplete, ListItemButton } from "@mui/material";
import WritemessageSBA from "../../../Component/WritemessageSBA";

const options = [
    { label: 'Investor Relations Solutions', to: '/investor-relations-solutions' },
    { label: 'RFP Management Solutions', to: '/request-for-proposals-solutions' },
    { label: 'Due Diligence Questionnaires', to: '/due-diligence-questionnaires-solutions' },
    { label: 'Consultant Database Management', to: '/consultant-database-management-solutions' },
    { label: 'Customer Relationship Management', to: '/customer-relationship-management-solutions' },
];

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box mt={5}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
}

export default function CDM() {
    const [value, setValue] = React.useState(3);

    const handleChange = (event, newValue) => {
        window.scrollTo(0, 0);
        setValue(newValue);
    };

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    const [value1, setValue1] = React.useState(options[3]);


    return (
        <>
            <Helmet>
                <title>Consultant Database Support for Asset Managers | DPA </title>
                <meta name="description" content="Streamline consultant database updates with DPA's tailored solutions. Automate processes, reduce turnaround times, and enhance efficiency for asset managers." />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/consultant-database-management-solutions" />
            </Helmet>

            <Box id='esg'>
                <Box id="investor_relations_tabs" className="maintabbedsection stiky">
                    <Container maxWidth="lg" className="bdr">
                        <Grid container spacing={1} alignItems="top">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <AppBar position="static" className="tabbedbar">
                                    <Tabs
                                        value={value}
                                        onChange={handleChange}
                                        indicatorColor="none"
                                        textColor="primary"
                                        variant="fullWidth"
                                        scrollButtons="off"
                                        aria-label="fullWidth auto tabs example"
                                        centered
                                    >
                                        <Tab label="Investor Relations Solutions" {...a11yProps(0)} component={Link} to="/investor-relations-solutions" />
                                        <Tab label="RFP Management Solutions" {...a11yProps(1)} component={Link} to="/request-for-proposals-solutions" />
                                        <Tab label="Due Diligence Questionnaires" {...a11yProps(2)} component={Link} to="/due-diligence-questionnaires-solutions" />
                                        <Tab label="Consultant Database Management" {...a11yProps(3)} component={Link} to="/consultant-database-management-solutions" />
                                        <Tab label="Customer Relationship Management" {...a11yProps(4)} component={Link} to="/customer-relationship-management-solutions" />
                                    </Tabs>
                                </AppBar>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>

                <Box id="investor_relations_tabs_mob" className="maintabbedsection stiky" mb={2}  >
                    <Container maxWidth="lg" className="bdr">
                        <Box className="alltxfieldlg">
                            <Autocomplete
                                disablePortal
                                options={options}
                                value={value1}
                                onChange={(event, newValue1) => setValue1(newValue1)}
                                fullWidth
                                getOptionLabel={(option) => option.label}
                                renderInput={(params) => <TextField {...params} />}
                                renderOption={(props, option) => (
                                    <Link to={option.to}>
                                        <ListItemButton {...props}>
                                            <Typography variant="h6" className="mb0 black fw5">{option.label}</Typography>
                                        </ListItemButton>
                                    </Link>
                                )}
                            />
                        </Box>
                    </Container>
                </Box>

                <WritemessageSBA pageVisited="Consultant Database Management" />

                <TabPanel value={value} index={3}>
                    <Box id="investor_relations">
                        <Box className='section sec_bg' component='section'>
                            <Container maxWidth='lg' className='bdr'>
                                <Grid container direction="row"
                                    justifyContent="center"
                                    alignItems="center">
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Box className="al_center synopsic_bx">
                                            <Box className='al_center'>
                                                <Typography variant='h1' className='black'>
                                                    Consultant Database Management
                                                </Typography>
                                            </Box>
                                            <Typography variant='h6' className='black al_center' gutterBottom>
                                                Transform your consultant database management to save time, cut costs, and boost investor engagement
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Container>
                        </Box>

                        {/* ------------------------------------------------------------------------------------------------------ */}




                        {/* ------------------------------------------------------------------------------------------------------ */}

                        <Box className="section2" component="section">
                            <Container maxWidth="lg" className="bdr">

                                <Box mb={2}>
                                    <Typography variant="h2" className="black al_left fw6 al_center" gutterBottom>
                                        Enhancing Efficiency <span className='lower'> with</span> Consultant Database Solutions <span className='lower'> for</span> Asset Managers
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        In today's competitive investment market, consultant database solutions for asset managers are indispensable for connecting potential investors with fund managers. Accurate, timely updates of these databases are essential for attracting and retaining investors. However, managing this data across multiple platforms presents significant challenges—manual processes, diverse requirements, and frequent updates can drain valuable resources and lead to inefficiencies.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Decimal Point Analytics (DPA) addresses these challenges with advanced consultant database management solutions designed to streamline workflows. By optimizing processes and automating tasks, DPA helps investment managers enhance efficiency, reduce errors, and maximize investor engagement.
                                    </Typography>
                                </Box>

                                <Box className='flx_center mt16'>
                                    <ProductCTAA allignment="flx_fs" cta_btn_name="Talk to us" pageVisited="Consultant Database Management" />
                                </Box>

                            </Container>
                        </Box>

                        <Box className="section2 sec_bg mob_card" component="section">
                            <Container maxWidth='lg' className='bdr'>
                                <Grid container spacing={2} alignItems="center" justifyContent='center'>

                                    <Grid container spacing={1} alignItems="top">
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Box className='al_center'>
                                                <Typography variant='h2' className='black'>
                                                    DPA's Comprehensive Consultant Database Management Solutions
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>

                                    <Typography variant='h6' className='black al_center' gutterBottom>
                                        Decimal Point Analytics offers tailored CDB services to overcome the challenges of managing consultant databases. Our approach focuses on optimizing workflows, automating processes, and ensuring data accuracy to enhance investor engagement and operational efficiency.
                                    </Typography>

                                    <Grid item lg={4} md={4} sm={6} xs={12}>
                                        <Box className='boxbg mnhg4'>
                                            <Box className='al_center'>
                                                <img src={solution1} className='allic' alt='Assessment and Customization' />
                                            </Box>
                                            <Box>
                                                <Grid container spacing={1} alignItems="top" justifyContent='center'>
                                                    <Grid item lg={12} md={9} sm={12} xs={12}>
                                                        <Box className='cat_m'>
                                                            <Typography variant='h3' className='blue al_center fw6'>
                                                                Assessment and Customization
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <Box>
                                                    <Typography variant='h6' className='black al_left'>
                                                        <ul>
                                                            <li>Conduct a thorough assessment of data requirements across various consultant platforms. </li>
                                                            <li>Customize data management solutions for investment firms to meet platform-specific needs. </li>
                                                        </ul>
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Grid>

                                    <Grid item lg={4} md={4} sm={6} xs={12}>
                                        <Box className='boxbg mnhg4'>
                                            <Box className='al_center'>
                                                <img src={solution2} className='allic' alt='Automation Pipelines' />
                                            </Box>
                                            <Box>
                                                <Grid container spacing={1} alignItems="top" justifyContent='center'>
                                                    <Grid item lg={12} md={6} sm={12} xs={12}>
                                                        <Box className='cat_m'>
                                                            <Typography variant='h3' className='blue al_center fw6'>
                                                                Automation Pipelines
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <Box>
                                                    <Typography variant='h6' className='black al_left'>
                                                        <ul>
                                                            <li>Set up automation pipelines to streamline data sharing processes.</li>
                                                            <li>Reduce manual intervention and enhance efficiency.</li>
                                                        </ul>
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Grid>

                                    <Grid item lg={4} md={4} sm={6} xs={12}>
                                        <Box className='boxbg mnhg4'>
                                            <Box className='al_center'>
                                                <img src={solution3} className='allic' alt='Data Cleansing Services for Private Equity' />
                                            </Box>
                                            <Box>
                                                <Grid container spacing={1} alignItems="top" justifyContent='center'>
                                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                                        <Box className='cat_m'>
                                                            <Typography variant='h3' className='blue al_center fw6'>
                                                                Data Cleansing Services for Private Equity
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <Box>
                                                    <Typography variant='h6' className='black al_left'>
                                                        <ul>
                                                            <li>Implement data cleansing services and standardization mechanisms.</li>
                                                            <li>Ensure consistent and accurate information across all platforms.</li>
                                                        </ul>
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Grid>

                                    <Grid item lg={4} md={4} sm={6} xs={12}>
                                        <Box className='boxbg mnhg4'>
                                            <Box className='al_center'>
                                                <img src={solution4} className='allic' alt='Structured SOPs and Checklists' />
                                            </Box>
                                            <Box>
                                                <Grid container spacing={1} alignItems="top" justifyContent='center'>
                                                    <Grid item lg={12} md={12} sm={9} xs={12}>
                                                        <Box className='cat_m'>
                                                            <Typography variant='h3' className='blue al_center fw6'>
                                                                Structured SOPs and Checklists
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <Box>
                                                    <Typography variant='h6' className='black al_left'>
                                                        <ul>
                                                            <li>Develop structured Standard Operating Procedures (SOPs) and checklists.</li>
                                                            <li>Maintain high-quality consultant database management and updates.</li>
                                                        </ul>
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Grid>

                                    <Grid item lg={4} md={4} sm={6} xs={12}>
                                        <Box className='boxbg mnhg4'>
                                            <Box className='al_center'>
                                                <img src={solution5} className='allic' alt='Regular Interactions and Updates' />
                                            </Box>
                                            <Box>
                                                <Grid container spacing={1} alignItems="top" justifyContent='center'>
                                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                                        <Box className='cat_m'>
                                                            <Typography variant='h3' className='blue al_center fw6'>
                                                                Regular Interactions and Updates
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <Box>
                                                    <Typography variant='h6' className='black al_left'>
                                                        <ul>
                                                            <li>Engage in regular interactions with clients to address unanswered questions.</li>
                                                            <li>Provide timely updates to keep data current and relevant.</li>
                                                        </ul>
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Grid>

                                    <Grid item lg={4} md={4} sm={6} xs={12}>
                                        <Box className='boxbg mnhg4'>
                                            <Box className='al_center'>
                                                <img src={solution6} className='allic' alt='Advanced Reporting' />
                                            </Box>
                                            <Box>
                                                <Grid container spacing={1} alignItems="top" justifyContent='center'>
                                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                                        <Box className='cat_m'>
                                                            <Typography variant='h3' className='blue al_center fw6'>
                                                                Advanced Reporting
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <Box>
                                                    <Typography variant='h6' className='black al_left'>
                                                        <ul>
                                                            <li>Curate specific responses with data for RFPs in customized formats.</li>
                                                            <li>Generate detailed reports on firm governance, investment team members, and organization structure.</li>
                                                        </ul>
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Grid>

                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Box className='flx_center mob_center mt16'>
                                            <ProductCTAA allignment="flx_fs" cta_btn_name="Transform Your Database Management" pageVisited="Consultant Database Management" />
                                        </Box>
                                    </Grid>

                                </Grid>
                            </Container>
                        </Box>

                        <Box className="section2 mob_card" component="section">
                            <Container maxWidth="lg" className="bdr">
                                <Grid container spacing={0} alignItems="center" justifyContent='center'>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Box className="al_center">
                                            <Typography className="black" variant="h2">
                                                Why Choose Us <span className='lower'>for</span> Consultant Database Management?
                                            </Typography>
                                        </Box>
                                    </Grid>

                                    <Grid item lg={5} md={5} sm={6} xs={12}>
                                        <Box className='al_center'>
                                            <img src={whyCP} className='topbanner4' alt='Why Choose Us for Consultant Database Management?' />
                                        </Box>
                                    </Grid>

                                    <Grid item lg={7} md={7} sm={6} xs={12}>
                                        <Box>
                                            <Typography className="black" variant="h6">
                                                <ul>
                                                    <li>
                                                        <Typography variant="h6" className="black jst" gutterbottom>
                                                            <strong> Proven Expertise: </strong> DPA's extensive experience with multiple consultants and data platforms ensures a deep understanding of the data management needs for investment firms.
                                                        </Typography>
                                                    </li>

                                                    <li>
                                                        <Typography variant="h6" className="black jst" gutterbottom>
                                                            <strong> Comprehensive Solutions: </strong> We offer tailored services, from data cleansing services for private equity to automation and advanced reporting, designed to meet your specific requirements.
                                                        </Typography>
                                                    </li>

                                                    <li>
                                                        <Typography variant="h6" className="black jst" gutterbottom>
                                                            <strong> Efficiency and Accuracy:  </strong> Automated processes and data standardization save time, reduce errors, and ensure consistent information across all platforms.
                                                        </Typography>
                                                    </li>

                                                    <li>
                                                        <Typography variant="h6" className="black jst" gutterbottom>
                                                            <strong> Enhanced Visibility: </strong> Maximize your platform visibility with enhanced data points and regular updates to keep your information current and appealing to investors.
                                                        </Typography>
                                                    </li>

                                                    <li>
                                                        <Typography variant="h6" className="black jst" gutterbottom>
                                                            <strong> Cost Savings: </strong> Our solutions free up your team from manual tasks, allowing them to focus on core activities while reducing administrative costs.
                                                        </Typography>
                                                    </li>

                                                    <li>
                                                        <Typography variant="h6" className="black jst" gutterbottom>
                                                            <strong> Reduced Risk: </strong> Minimize errors and protect your organization's value with our robust CDB services, ensuring accurate data representation.
                                                        </Typography>
                                                    </li>

                                                    <li>
                                                        <Typography variant="h6" className="black jst" gutterbottom>
                                                            <strong> Streamlined Fundraising: </strong> Facilitate faster due diligence and attract investors with accurate, readily available information and customized RFP responses.
                                                        </Typography>
                                                    </li>
                                                </ul>
                                            </Typography>
                                        </Box>

                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Box className='flx_fs mob_center'>
                                                <ProductCTAA allignment="flx_fs" cta_btn_name="Talk to us" pageVisited="Consultant Database Management" />
                                            </Box>
                                        </Grid>

                                    </Grid>


                                </Grid>
                            </Container>
                        </Box>

                        <Box className="section2 sec_bg mob_card" component="section">
                            <Container maxWidth='lg' className='bdr' style={{ position: 'relative', }}>
                                <Box>
                                    <Grid container spacing={1} alignItems="top">

                                        <Grid container spacing={1} alignItems="top">
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <Box className='al_center'>
                                                    <Typography variant='h2' className='black'>
                                                        How DPA Streamlines Consultant Database Management
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>

                                        <Typography variant='h6' className='black al_center' gutterBottom>
                                            At Decimal Point Analytics (DPA), our meticulous process ensures that consultant database management is efficient, accurate, and tailored to your specific needs. Here’s a step-by-step overview of how we transform your data management:
                                        </Typography>

                                        <Grid container spacing={3} alignItems="top" justifyContent='center'>
                                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                                <Box className='boxbg mnhg8'>
                                                    <Box className='al_center'>
                                                        <img src={management1} className='allic' alt='Initial Assessment' />
                                                    </Box>
                                                    <Box>
                                                        <Box className='cat_m'>
                                                            <Typography variant='h3' className='blue al_center fw6'>
                                                                Initial Assessment
                                                            </Typography>
                                                        </Box>
                                                        <Box>
                                                            <Typography variant='h6' className='black al_left'>
                                                                <ul>
                                                                    <li>Evaluate current data management practices and requirements.</li>
                                                                    <li>Identify specific needs and challenges.</li>
                                                                </ul>
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Grid>

                                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                                <Box className='boxbg mnhg8'>
                                                    <Box className='al_center'>
                                                        <img src={management2} className='allic' alt='Custom Solution Design' />
                                                    </Box>
                                                    <Box>
                                                        <Box className='cat_m'>
                                                            <Typography variant='h3' className='blue al_center fw6'>
                                                                Custom Solution Design
                                                            </Typography>
                                                        </Box>
                                                        <Box>
                                                            <Typography variant='h6' className='black al_left'>
                                                                <ul>
                                                                    <li>Develop tailored consultant database solutions.</li>
                                                                    <li>Plan automation and standardization protocols. </li>
                                                                </ul>
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Grid>

                                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                                <Box className='boxbg mnhg8'>
                                                    <Box className='al_center'>
                                                        <img src={management3} className='allic' alt='Data Collection and Cleaning' />
                                                    </Box>
                                                    <Box>
                                                        <Box className='cat_m'>
                                                            <Typography variant='h3' className='blue al_center fw6'>
                                                                Data Collection and Cleaning
                                                            </Typography>
                                                        </Box>
                                                        <Box>
                                                            <Typography variant='h6' className='black al_left'>
                                                                <ul>
                                                                    <li>Gather necessary data from multiple sources. </li>
                                                                    <li>Clean data to ensure accuracy and consistency. </li>
                                                                </ul>
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Grid>

                                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                                <Box className='boxbg mnhg8'>
                                                    <Box className='al_center'>
                                                        <img src={management4} className='allic' alt='Automation Implementation' />
                                                    </Box>
                                                    <Box>
                                                        <Box className='cat_m'>
                                                            <Typography variant='h3' className='blue al_center fw6'>
                                                                Automation Implementation
                                                            </Typography>
                                                        </Box>
                                                        <Box>
                                                            <Typography variant='h6' className='black al_left'>
                                                                <ul>
                                                                    <li>Set up automation pipelines for streamlined updates. </li>
                                                                    <li>Reduce manual intervention and errors. </li>
                                                                </ul>
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Grid>

                                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                                <Box className='boxbg mnhg8'>
                                                    <Box className='al_center'>
                                                        <img src={management5} className='allic' alt='Standardization and Integration' />
                                                    </Box>
                                                    <Box>
                                                        <Box className='cat_m'>
                                                            <Typography variant='h3' className='blue al_center fw6'>
                                                                Standardization and Integration
                                                            </Typography>
                                                        </Box>
                                                        <Box>
                                                            <Typography variant='h6' className='black al_left'>
                                                                <ul>
                                                                    <li>Standardize data formats for consistency. </li>
                                                                    <li>Seamlessly integrate data into consultant platforms.</li>
                                                                </ul>
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Grid>

                                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                                <Box className='boxbg mnhg8'>
                                                    <Box className='al_center'>
                                                        <img src={management6} className='allic' alt='Regular Updates and Maintenance' />
                                                    </Box>
                                                    <Box>
                                                        <Box className='cat_m'>
                                                            <Typography variant='h3' className='blue al_center fw6'>
                                                                Regular Updates and Maintenance
                                                            </Typography>
                                                        </Box>
                                                        <Box>
                                                            <Typography variant='h6' className='black al_left'>
                                                                <ul>
                                                                    <li>Perform routine data updates and maintenance.</li>
                                                                    <li>Address emerging issues promptly.</li>
                                                                </ul>
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Grid>

                                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                                <Box className='boxbg mnhg8'>
                                                    <Box className='al_center'>
                                                        <img src={management7} className='allic' alt='Client Collaboration' />
                                                    </Box>
                                                    <Box>
                                                        <Box className='cat_m'>
                                                            <Typography variant='h3' className='blue al_center fw6'>
                                                                Client Collaboration
                                                            </Typography>
                                                        </Box>
                                                        <Box>
                                                            <Typography variant='h6' className='black al_left'>
                                                                <ul>
                                                                    <li>Engage regularly with clients for feedback.</li>
                                                                    <li>Adjust processes based on client input.</li>
                                                                </ul>
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Grid>

                                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                                <Box className='boxbg mnhg8'>
                                                    <Box className='al_center'>
                                                        <img src={management8} className='allic' alt='Advanced Reporting' />
                                                    </Box>
                                                    <Box>
                                                        <Box className='cat_m'>
                                                            <Typography variant='h3' className='blue al_center fw6'>
                                                                Advanced Reporting
                                                            </Typography>
                                                        </Box>
                                                        <Box>
                                                            <Typography variant='h6' className='black al_left'>
                                                                <ul>
                                                                    <li>Generate detailed, customized reports.</li>
                                                                    <li>Provide insightful analytics for strategic decisions. </li>
                                                                </ul>
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Grid>

                                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                                <Box className='boxbg mnhg8'>
                                                    <Box className='al_center'>
                                                        <img src={management9} className='allic' alt='Continuous Improvement' />
                                                    </Box>
                                                    <Box>
                                                        <Box className='cat_m'>
                                                            <Typography variant='h3' className='blue al_center fw6'>
                                                                Continuous Improvement
                                                            </Typography>
                                                        </Box>
                                                        <Box>
                                                            <Typography variant='h6' className='black al_left'>
                                                                <ul>
                                                                    <li>Monitor and refine processes for efficiency. </li>
                                                                    <li>Stay updated with industry best practices. </li>
                                                                </ul>
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Grid>

                                        </Grid>

                                    </Grid>
                                </Box>
                            </Container>
                        </Box>

                        <Box className="section2" component="section">
                            <Container maxWidth='lg' className='bdr'>
                                <Grid container spacing={2} alignItems="center" justifyContent='center'>

                                    <Grid container spacing={1} alignItems="top">
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Box className='al_center'>
                                                <Typography variant='h2' className='black'>
                                                    Consultant Database Transformation: A Success Story
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>

                                    <Typography variant='h6' className='black al_center' gutterBottom>
                                        When a leading US-based Asset Management Company faced challenges with managing multiple data platforms and frequent updates, Decimal Point Analytics delivered a comprehensive solution by:
                                    </Typography>

                                    <Grid container direction="row"
                                        justifyContent="center"
                                        alignItems="center">

                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Box className="al_center" my={3}>
                                                <img src={Success} className="w100" alt="Consultant Database Transformation: A Success Story" />
                                            </Box>
                                        </Grid>
                                    </Grid>

                                    <Box mt={2}>
                                        <Typography variant='h6' className='black al_center' gutterBottom >
                                            Our collaboration not only addressed their immediate challenges but also empowered the client with improved efficiency, enhanced accuracy, and better investor engagement, significantly boosting their operational success and market positioning.
                                        </Typography>
                                    </Box>

                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Box className='flx_center mt16'>
                                            <ProductCTAA allignment="flx_fs" cta_content="Uncover the Impact of Our Expertise Today!" cta_btn_name="Contact Us" pageVisited="Consultant Database Management" />
                                        </Box>
                                    </Grid>

                                </Grid>
                            </Container>
                        </Box>

                        <Box className="section2 sec_bg" component="section">
                            <Container maxWidth="lg" className="bdr">
                                <Grid container spacing={0} alignItems="center">
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Box className="synopsic_bx">
                                            <Typography variant='h6' className='black jst'>
                                                Choose Decimal Point Analytics as a dedicated partner focused on transforming your consultant database management. Our comprehensive, efficient, and tailored approach ensures that your data is always accurate, up-to-date, and strategically aligned with your goals. Let us help you unlock new levels of operational efficiency and investor engagement.
                                            </Typography>
                                        </Box>
                                    </Grid>

                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Box className='flx_center mt16'>
                                            <ProductCTAA allignment="flx_fs" cta_content="Discover how we can elevate your data management processes and drive your success." cta_btn_name="Contact us today!" pageVisited="Consultant Database Management" />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Container>
                        </Box>
                    </Box>
                </TabPanel>
            </Box>
        </>
    );
}
