import { Container, Typography, Box, Button } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import { Helmet } from 'react-helmet';
import CAT from "../../asset/images/animation/bimid.webp"
import ProductCTAA from '../../Component/ProductCTAA';
import DPACore from "../../asset/images/product/bi/core-competencies.webp";

import CC1 from "../../asset/images/product/bi/core-competencies/dashboard.png";
import CC2 from "../../asset/images/product/bi/core-competencies/automation.png";
import CC3 from "../../asset/images/product/bi/core-competencies/kpi.png";

import { Grid } from '@mui/material';

import solution1 from "../../asset/images/product/bi/data-integration.png";
import solution2 from "../../asset/images/product/bi/interactive-visualization.png";
import solution3 from "../../asset/images/product/bi/actionable-insights.png";
import solution4 from "../../asset/images/product/bi/end-to-end-support.png";

import GetInTouch from '../../Component/Get_in_touch';

import EastIcon from '@mui/icons-material/East';
import SouthIcon from '@mui/icons-material/South';
import WestIcon from '@mui/icons-material/West';


import Step1 from "../../asset/images/product/bi/steps/step1.png";
import Step2 from "../../asset/images/product/bi/steps/step2.png";
import Step3 from "../../asset/images/product/bi/steps/step3.png";
import Step4 from "../../asset/images/product/bi/steps/step4.png";
import Step5 from "../../asset/images/product/bi/steps/step5.png";
import Step6 from "../../asset/images/product/bi/steps/step6.png";

import CaseStudy1 from "../../asset/images/product/bi/sample-dashboards/case-study-1.webp";
import CaseStudy2 from "../../asset/images/product/bi/sample-dashboards/case-study-2.webp";
import Tableau1 from "../../asset/images/product/bi/sample-dashboards/sample-tableau-1.webp";
import Tableau2 from "../../asset/images/product/bi/sample-dashboards/sample-tableau-2.webp";
import PowerBI from "../../asset/images/product/bi/sample-dashboards/sample-powerbi.webp";

import { useMediaQuery } from '@mui/material';
import Writemessage3 from '../../Component/Writemessage3';



export default function BusinessIntelligence() {
    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    const isMobile = useMediaQuery('(max-width:767px)');



    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>Data Visualization & BI Dashboards | Decimal Point Analytics</title>
                <meta name="description" content="Unlock data-driven insights with BI Dashboard Solutions from Decimal Point Analytics. We provide automated data integration, interactive visualizations, and actionable insights using Tableau and Power BI. Tailored to meet diverse business needs, our end-to-end services enhance decision-making and operational efficiency." />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/business-intelligence-solution" />
                <meta name="robots" content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            <Box className="banner_bx" mb={"2px"}>
                <Box style={{ backgroundImage: "url(" + CAT + ")" }} className="banner_img">
                    <Box className="img_over bl_overlay">
                        <Grid container spacing={0} alignItems="center" justifyContent='center'>
                            <Grid item lg={5} md={7} sm={10} xs={10}>
                                <Box className="al_center" mt={3}>
                                    <Typography className="white" variant="h1">Data Visualization & BI Dashboards </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>

                  <Writemessage3 pageVisited="Data Visualization & BI Dashboards" />


            <Box id='sba_solutions' className='section2 sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>
                    <Grid container direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className="al_center synopsic_bx">
                                <Box className='al_center'>
                                    <Typography variant='h2' className='black'>
                                        Data-Driven Insights with BI Dashboards
                                    </Typography>
                                </Box>
                                <Typography variant='h6' className='black jst' gutterBottom>
                                    Uncover the power of your data with Decimal Point Analytics’ BI Dashboard solutions. With nearly two decades of expertise in data analytics, we offer comprehensive data visualization services tailored for global businesses. Using advanced tools like Tableau and Power BI, our solutions are designed to meet each client’s unique needs and foster impactful business decisions.
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='flx_center mt16'>
                                <ProductCTAA allignment="al_center" cta_content="Start Your Journey with Data-Driven Insights" cta_btn_name="Contact Us Today!" pageVisited="Data-Driven Insights with BI Dashboards" />
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>


            <Box id='sba_solutions' className='section mob_card' component='section'>
                <Container maxWidth='lg' className='bdr' style={{ position: 'relative', }}>
                    <Box >

                        <Grid container spacing={1} alignItems="center" justifyContent='center'>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='al_center'>
                                    <Typography variant='h2' className='black'>
                                        DPA Capabilities:
                                        <Typography variant='h2' className='black h2v2 al_center' gutterBottom>
                                            End-to-end Services for Data Visualization with BI Tools
                                        </Typography>
                                    </Typography>
                                </Box>
                            </Grid>

                        </Grid>


                        <Grid container spacing={1} alignItems="flex-start" justifyContent='center'>
                            <Grid item lg={2} md={4} sm={6} xs={12}>
                                <Box className='capabilities_card'>
                                    <Box>
                                        <Typography variant='h3' className='step-number'>Step: 1</Typography>
                                    </Box>

                                    <Box py={3}>
                                        <img src={Step1} alt='Requirement gathering' draggable={false} />
                                    </Box>

                                    <Box>
                                        <Typography variant='h6' className='black fw6'>Requirement gathering</Typography>
                                    </Box>

                                    <Box className='direction-arrow'>
                                        <EastIcon />
                                    </Box>
                                </Box>
                            </Grid>


                            <Grid item lg={2} md={4} sm={6} xs={12}>
                                <Box className='capabilities_card'>
                                    <Box>
                                        <Typography variant='h3' className='step-number'>Step: 2</Typography>
                                    </Box>

                                    <Box py={3}>
                                        <img src={Step2} alt='Input data analysis' draggable={false} />
                                    </Box>

                                    <Box>
                                        <Typography variant='h6' className='black fw6'>Input data analysis</Typography>
                                    </Box>

                                    <Box className='direction-arrow'>
                                        <EastIcon />
                                    </Box>
                                </Box>
                            </Grid>


                            <Grid item lg={2} md={4} sm={6} xs={12}>
                                <Box className='capabilities_card'>
                                    <Box>
                                        <Typography variant='h3' className='step-number'>Step: 3</Typography>
                                    </Box>

                                    <Box py={3}>
                                        <img src={Step3} alt='Data modelling /scripting, transformation & cleaning' draggable={false} />
                                    </Box>

                                    <Box>
                                        <Typography variant='h6' className='black fw6'>Data modelling /scripting, transformation & cleaning</Typography>
                                    </Box>

                                    <Box className='direction-arrow'>
                                        <EastIcon />
                                    </Box>
                                </Box>
                            </Grid>


                            <Grid item lg={2} md={4} sm={6} xs={12}>
                                <Box className='capabilities_card'>
                                    <Box>
                                        <Typography variant='h3' className='step-number'>Step: 4</Typography>
                                    </Box>

                                    <Box py={3}>
                                        <img src={Step4} alt='Centralized data repository' draggable={false} />
                                    </Box>

                                    <Box>
                                        <Typography variant='h6' className='black fw6'>Centralized data repository</Typography>
                                    </Box>

                                    <Box className='direction-arrow'>
                                        <EastIcon />
                                    </Box>
                                </Box>
                            </Grid>


                            <Grid item lg={2} md={4} sm={6} xs={12}>
                                <Box className='capabilities_card'>
                                    <Box>
                                        <Typography variant='h3' className='step-number'>Step: 5</Typography>
                                    </Box>
                                    <Box py={3}>
                                        <img src={Step5} alt='Dashboard development & enhancement' draggable={false} />
                                    </Box>

                                    <Box>
                                        <Typography variant='h6' className='black fw6'>Dashboard development & enhancement</Typography>
                                    </Box>

                                    <Box className='direction-arrow'>
                                        <EastIcon />
                                    </Box>
                                </Box>
                            </Grid>


                            <Grid item lg={2} md={4} sm={6} xs={12}>
                                <Box className='capabilities_card'>
                                    <Box>
                                        <Typography variant='h3' className='step-number'>Step: 6</Typography>
                                    </Box>

                                    <Box py={3}>
                                        <img src={Step6} alt='Role level security implementation' draggable={false} />
                                    </Box>

                                    <Box>
                                        <Typography variant='h6' className='black fw6'>Role level security implementation</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>



                        {false ?
                            <>
                                <Box id='business_intelligence' className='desktop-onlyww11' mb={2} mt={4}>
                                    <Grid container spacing={1} alignItems="center" justifyContent='center'>
                                        <Grid item lg={3} md={3} sm={8} xs={11}>
                                            <Box className='al_left'>
                                                <Typography variant='h6' className='step-label' gutterBottom>
                                                    Step 1
                                                </Typography>
                                            </Box>
                                            <Box className='boxbg2 flx_fs ai_center'>
                                                <Box className='al_center bi_icon'>
                                                    <img src={Step1} alt='Requirement gathering' draggable={false} />
                                                </Box>

                                                <Box className='al_left step-heading'>
                                                    <Typography variant='h6' className='black fw6 al_left mb0' gutterBottom>
                                                        Requirement gathering
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>

                                        <Grid item lg={1} md={1} sm={8} xs={11}>
                                            <Box className='step-arrow rotateClockwise'>
                                                <EastIcon fontSize='large' className='black' />
                                            </Box>
                                        </Grid>

                                        <Grid item lg={3} md={3} sm={8} xs={11}>
                                            <Box className='al_left'>
                                                <Typography variant='h6' className='step-label' gutterBottom>
                                                    Step 2
                                                </Typography>
                                            </Box>
                                            <Box className='boxbg2 flx_fs ai_center'>
                                                <Box className='al_center bi_icon'>
                                                    <img src={Step2} alt='Input data analysis' draggable={false} />
                                                </Box>

                                                <Box className='al_left step-heading'>
                                                    <Typography variant='h6' className='black fw6 al_left mb0' gutterBottom>
                                                        Input data analysis
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>

                                        <Grid item lg={1} md={1} sm={8} xs={11}>
                                            <Box className='step-arrow rotateClockwise'>
                                                <EastIcon fontSize='large' className='black' />
                                            </Box>
                                        </Grid>

                                        <Grid item lg={3} md={3} sm={8} xs={11}>
                                            <Box className='al_left'>
                                                <Typography variant='h6' className='step-label' gutterBottom>
                                                    Step 3
                                                </Typography>
                                            </Box>
                                            <Box className='boxbg2 flx_fs ai_center'>
                                                <Box className='al_center bi_icon'>
                                                    <img src={Step3} alt='Data modelling /scripting, transformation & cleaning' draggable={false} />
                                                </Box>

                                                <Box className='al_left step-heading'>
                                                    <Typography variant='h6' className='black fw6 al_left mb0' gutterBottom>
                                                        Data modelling /scripting, transformation & cleaning
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={1} alignItems="center" justifyContent='center'>
                                        <Grid item lg={8} md={8} sm={8} xs={11}>
                                            <Box />
                                        </Grid>
                                        <Grid item lg={3} md={3} sm={8} xs={11}>
                                            <Box className='step-arrow' mb={1}>
                                                <SouthIcon fontSize='large' className='black' />
                                            </Box>
                                        </Grid>
                                    </Grid>


                                    <Grid container spacing={1} alignItems="center" justifyContent='center' className='grid-reverse'>
                                        <Grid item lg={3} md={3} sm={8} xs={11}>
                                            <Box className='al_left'>
                                                <Typography variant='h6' className='step-label' gutterBottom>
                                                    Step 6
                                                </Typography>
                                            </Box>
                                            <Box className='boxbg2 flx_fs ai_center'>
                                                <Box className='al_center bi_icon'>
                                                    <img src={Step6} alt='Role level security implementation' draggable={false} />
                                                </Box>

                                                <Box className='al_left step-heading'>
                                                    <Typography variant='h6' className='black fw6 al_left mb0' gutterBottom>
                                                        Role level security implementation
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>

                                        <Grid item lg={1} md={1} sm={8} xs={11}>
                                            <Box className='step-arrow rotateAntiClockwise'>
                                                <WestIcon fontSize='large' className='black' />
                                            </Box>
                                        </Grid>

                                        <Grid item lg={3} md={3} sm={8} xs={11}>
                                            <Box className='al_left'>
                                                <Typography variant='h6' className='step-label' gutterBottom>
                                                    Step 5
                                                </Typography>
                                            </Box>
                                            <Box className='boxbg2 flx_fs ai_center'>
                                                <Box className='al_center bi_icon'>
                                                    <img src={Step5} alt='Dashboard development & enhancement' draggable={false} />
                                                </Box>

                                                <Box className='al_left step-heading'>
                                                    <Typography variant='h6' className='black fw6 al_left mb0' gutterBottom>
                                                        Dashboard development & enhancement
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>

                                        <Grid item lg={1} md={1} sm={8} xs={11}>
                                            <Box className='step-arrow rotateAntiClockwise'>
                                                <WestIcon fontSize='large' className='black' />
                                            </Box>
                                        </Grid>

                                        <Grid item lg={3} md={3} sm={8} xs={11}>
                                            <Box className='al_left'>
                                                <Typography variant='h6' className='step-label' gutterBottom>
                                                    Step 4
                                                </Typography>
                                            </Box>
                                            <Box className='boxbg2 flx_fs ai_center'>
                                                <Box className='al_center bi_icon'>
                                                    <img src={Step4} alt='Centralized data repository' draggable={false} />
                                                </Box>

                                                <Box className='al_left step-heading'>
                                                    <Typography variant='h6' className='black fw6 al_left mb0' gutterBottom>
                                                        Centralized data repository
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </> : ''}

                    </Box>
                </Container >
            </Box >



            <Box id='sba_solutions' className='section mob_card sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>
                    <Grid container spacing={2} alignItems="center" justifyContent='center'>

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='al_center'>
                                <Typography variant='h2' className='black'>
                                    Why Choose DPA’s BI Dashboard Services?

                                    <Typography variant='h2' className='black h2v2 al_center' gutterBottom>
                                        Our BI Dashboard Solutions include:
                                    </Typography>

                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <Box className='boxbg mnhg4'>
                                <Box className='al_center'>
                                    <img src={solution1} className='allic' alt='Automated Data Integration' />
                                </Box>
                                <Box>
                                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                                        <Grid item lg={8} md={12} sm={12} xs={12}>
                                            <Box className='cat_m'>
                                                <Typography variant='h3' className='blue al_center fw6'>
                                                    Automated Data Integration
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>

                                    <Typography variant='h6' className='black al_center mb0'>
                                        Say goodbye to time-consuming manual data handling. Our solution automatically collates and standardizes data from multiple sources.
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <Box className='boxbg mnhg4'>
                                <Box className='al_center'>
                                    <img src={solution2} className='allic' alt='Interactive Visualizations' />
                                </Box>
                                <Box>
                                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                                        <Grid item lg={8} md={10} sm={10} xs={12}>
                                            <Box className='cat_m'>
                                                <Typography variant='h3' className='blue al_center fw6'>
                                                    Interactive Visualizations
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Typography variant='h6' className='black al_center mb0'>
                                        From KPI tracking to trend analysis, our dashboards are tailored to present data intuitively, with drill-down features and flexible navigation.
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <Box className='boxbg mnhg4'>
                                <Box className='al_center'>
                                    <img src={solution3} className='allic' alt='Actionable Insights' />
                                </Box>
                                <Box>
                                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                                        <Grid item lg={10} md={12} sm={12} xs={12}>
                                            <Box className='cat_m'>
                                                <Typography variant='h3' className='blue al_center fw6'>
                                                    Actionable <br /> Insights
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Typography variant='h6' className='black al_center mb0'>
                                        With our expertise, clients gain meaningful insights through dashboards, enhancing decision-making capabilities across all levels of management.
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <Box className='boxbg mnhg4'>
                                <Box className='al_center'>
                                    <img src={solution4} className='allic' alt='End-to-End Support' />
                                </Box>
                                <Box>
                                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                                        <Grid item lg={8} md={9} sm={8} xs={12}>
                                            <Box className='cat_m'>
                                                <Typography variant='h3' className='blue al_center fw6'>
                                                    End-to-End <br /> Support
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Typography variant='h6' className='black al_center mb0'>
                                        We guide our clients from data sourcing and preparation to seamless migration into production environments.
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='flx_center mob_center mt16'>
                                <ProductCTAA allignment="al_center" cta_content="Ready to transform your data?" cta_btn_name="Book a Free Demo" pageVisited="Data Visualization & BI Dashboards" />
                            </Box>
                        </Grid>

                    </Grid>
                </Container>
            </Box>

            <Box id='sba_solutions' className='section mob_card' component='section'>
                <Container maxWidth='lg' className='bdr' style={{ position: 'relative', }}>
                    <Box>
                        <Grid container spacing={1} alignItems="top">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='al_center'>
                                    <Typography variant='h2' className='black'>
                                        DPA’s Core Competencies in BI Solutions
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item lg={12} md={12} sm={12} xs={12}>


                                {isMobile ? (
                                    <>

                                        <Box className='boxbg' mt={2}>
                                            <Box className='al_center'>
                                                <img src={CC1} className='allic' alt='End-to-End Support' />
                                            </Box>
                                            <Box>
                                                <Grid container spacing={1} alignItems="top" justifyContent='center'>
                                                    <Grid item lg={8} md={9} sm={8} xs={12}>
                                                        <Box className='cat_m'>
                                                            <Typography variant='h3' className='blue al_center fw6 mb0'>
                                                                Customized Dashboard Design
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <Typography variant='h6' className='black al_center mb0'>
                                                    Tailored dashboards in Tableau and Power BI deliver specific insights aligned with each client’s strategic objectives.
                                                </Typography>
                                            </Box>
                                        </Box>


                                        <Box className='boxbg' mt={2}>
                                            <Box className='al_center'>
                                                <img src={CC2} className='allic' alt='End-to-End Support' />
                                            </Box>
                                            <Box>
                                                <Grid container spacing={1} alignItems="top" justifyContent='center'>
                                                    <Grid item lg={8} md={9} sm={8} xs={12}>
                                                        <Box className='cat_m'>
                                                            <Typography variant='h3' className='blue al_center fw6 mb0'>
                                                                Data Transformation Automation
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <Typography variant='h6' className='black al_center mb0'>
                                                    With automated workflows, such as VBA macros, raw data is transformed into actionable metrics, saving time and reducing errors.
                                                </Typography>
                                            </Box>
                                        </Box>


                                        <Box className='boxbg' mt={2}>
                                            <Box className='al_center'>
                                                <img src={CC3} className='allic' alt='End-to-End Support' />
                                            </Box>
                                            <Box>
                                                <Grid container spacing={1} alignItems="top" justifyContent='center'>
                                                    <Grid item lg={8} md={9} sm={8} xs={12}>
                                                        <Box className='cat_m'>
                                                            <Typography variant='h3' className='blue al_center fw6 mb0'>
                                                                KPI and Performance Tracking
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <Typography variant='h6' className='black al_center mb0'>
                                                    Our dashboards support clients in monitoring key metrics, enabling efficient operations and rapid course correction.
                                                </Typography>
                                            </Box>
                                        </Box>

                                    </>
                                ) : (
                                    <>
                                        <Box mt={3}>
                                            <img src={DPACore} className='topbanner1' alt='DPA’s Core Competencies in BI Solutions' />
                                        </Box>
                                    </>
                                )}

                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Box>


            <Box id='sba_solutions' className='section mob_card sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>
                    <Grid container spacing={2} alignItems="center" justifyContent='center'>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='al_center'>
                                <Typography variant='h2' className='black'>
                                    Success Stories
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box id="new_innovation_design" className="reportinfo">
                                <Grid container spacing={2} alignItems="top" justifyContent="center">
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <Box className='reportinfo'>
                                            <Box className="reportm_bg">
                                                <Box pb={2}>
                                                    <Button variant="contained" className='ora_title_btn'>
                                                        Case Study
                                                    </Button>
                                                </Box>

                                                <Box mt={2} mb={1}>
                                                    <Typography className="reporttitle blue">
                                                        KPI Monitoring for a <br /> Large FMCG Company
                                                    </Typography>
                                                </Box>
                                                <Box className="line"></Box>

                                                <Box mt={2}>
                                                    <Box py={'4px'}>
                                                        <Typography variant='h4' className='blue' gutterBottom>
                                                            Overview
                                                        </Typography>
                                                        <Typography variant='h6' className='black jst'>
                                                            A large GCC-based FMCG client transformed its KPI tracking and budget management processes through automated, interactive dashboards developed in Power BI. These dashboards enabled seamless monitoring and comparison of actual performance against budgeted values, enhancing decision-making for product portfolio optimization (PPO).
                                                        </Typography>
                                                    </Box>

                                                    <Box py={'4px'}>
                                                        <Typography variant='h4' className='blue' gutterBottom>
                                                            Client Challenges
                                                        </Typography>
                                                        <Typography variant='h6' className='black jst'>
                                                            The FMCG company faced challenges with manual data handling in Excel, leading to inefficiencies, inaccuracies, and fragmented data across sources. The lack of an integrated dashboard solution hindered effective data consumption and strategic planning.
                                                        </Typography>
                                                    </Box>


                                                    <Box py={'4px'}>
                                                        <Typography variant='h4' className='blue' gutterBottom>
                                                            Solution
                                                        </Typography>
                                                        <Typography variant='h6' className='black jst'>
                                                            DPA’s end-to-end BI solution standardized data, automated reporting processes, and introduced a drill-down dashboard interface that provided flexible navigation and detailed insights for KPI and budget tracking.
                                                        </Typography>
                                                    </Box>


                                                    <Box className="">
                                                        <img src={CaseStudy1} alt='sample Dashboard' className='sample_dash_img' />
                                                    </Box>



                                                    <Box py={'4px'}>
                                                        <Typography variant='h4' className='blue' gutterBottom>
                                                            Results
                                                        </Typography>
                                                        <Typography variant='h6' className='black jst'>
                                                            <ul className='m0'>
                                                                <li>
                                                                    <strong>Processing Efficiency: </strong>
                                                                    Reduced processing time by over 75%, from nearly two days.
                                                                </li>
                                                                <li>
                                                                    <strong>Financial Impact: </strong>
                                                                    Enabled the PPO module to potentially increase EBITDA by up to $1.5 million per month.
                                                                </li>
                                                                <li>
                                                                    <strong>Strategic Insights: </strong>
                                                                    Delivered granular-level understanding of SKU contributions, optimizing budget planning and highlighting low-performance categories for improved profitability.
                                                                </li>
                                                            </ul>
                                                        </Typography>

                                                        <Typography variant='h6' className='black jst'>
                                                            This solution empowered the FMCG company with reliable, actionable insights for effective management reporting and informed decision-making.
                                                        </Typography>
                                                    </Box>

                                                </Box>


                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <Box className='reportinfo'>
                                            <Box className="reportm_bg">
                                                <Box pb={2}>
                                                    <Button variant="contained" className='ora_title_btn'>
                                                        Case Study
                                                    </Button>
                                                </Box>

                                                <Box mt={2} mb={1}>
                                                    <Typography className="reporttitle blue">
                                                        Automated Portfolio Performance Monitoring <br /> for a Private Equity Firm
                                                    </Typography>
                                                </Box>
                                                <Box className="line"></Box>

                                                <Box mt={2}>
                                                    <Box py={'4px'}>
                                                        <Typography variant='h4' className='blue' gutterBottom>
                                                            Overview
                                                        </Typography>
                                                        <Typography variant='h6' className='black jst'>
                                                            A private equity firm transformed its portfolio performance monitoring with a centralized, automated system. The solution integrated data from multiple sources into a standardized format, creating a consolidated database with intuitive Power BI dashboards to streamline analysis and reporting.
                                                        </Typography>
                                                    </Box>

                                                    <Box py={'4px'}>
                                                        <Typography variant='h4' className='blue' gutterBottom>
                                                            Client Challenges
                                                        </Typography>
                                                        <Typography variant='h6' className='black jst'>
                                                            The firm struggled with managing scattered data across various formats and sources, with no unified repository or visualization tools, making performance tracking time-consuming and inefficient.
                                                        </Typography>
                                                    </Box>



                                                    <Box py={'4px'}>
                                                        <Typography variant='h4' className='blue' gutterBottom>
                                                            Solution
                                                        </Typography>
                                                        <Typography variant='h6' className='black jst'>
                                                            <strong>The project implemented:</strong>
                                                            <ul className='m0'>
                                                                <li>A standardized data input template to handle various reporting formats.</li>
                                                                <li>A consolidated, automated database updated monthly, supporting consistent portfolio monitoring.</li>
                                                                <li>Power BI dashboards for individual company analysis and consolidated group-level insights, with interactive slicing and dicing features for ease of use.</li>
                                                            </ul>
                                                        </Typography>
                                                    </Box>

                                                    <Box className="">
                                                        <img src={CaseStudy2} alt='sample Dashboard' className='sample_dash_img' />
                                                    </Box>

                                                    <Box py={'4px'}>
                                                        <Typography variant='h4' className='blue' gutterBottom>
                                                            Results
                                                        </Typography>
                                                        <Typography variant='h6' className='black jst'>
                                                            <ul className='m0'>
                                                                <li>
                                                                    <strong>Enhanced Data Accessibility: </strong> Enabled seamless access to standardized data for all portfolio companies in one centralized location.
                                                                </li>
                                                                <li>
                                                                    <strong>Efficient Reporting: </strong> Created dashboards optimized for management presentations, simplifying the decision-making process.
                                                                </li>
                                                                <li>
                                                                    <strong>Improved Analytics: </strong> Provided end-to-end services from data management to dashboard delivery, supporting rapid insights and enabling informed investment decisions.
                                                                </li>
                                                            </ul>
                                                        </Typography>

                                                        <Typography variant='h6' className='black jst'>
                                                            This solution empowered the private equity firm with streamlined portfolio tracking and deeper insights, driving efficient and informed financial analysis.
                                                        </Typography>
                                                    </Box>

                                                </Box>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='flx_center mob_center mt16'>
                                <ProductCTAA allignment="al_center" cta_content="Discover How Our BI Solutions Can Work for You" cta_btn_name="Know More About the Case Study" pageVisited="Data Visualization & BI Dashboards" />
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box >



            <Box id='sba_solutions' className='section mob_card' component='section'>
                <Container maxWidth='lg' className='bdr'>
                    <Grid container spacing={2} alignItems="center" justifyContent='center'>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='al_center'>
                                <Typography variant='h2' className='black'>
                                    Sample Screens of Dashboards
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item lg={8} md={8} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h4' className='black fw6'>
                                    Tableau Dashboard
                                </Typography>
                            </Box>

                            <Box className="reportinfo">
                                <Grid container spacing={4} alignItems="top" justifyContent="center">
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <Box className="">
                                            <img src={Tableau1} alt='sample Dashboard' className='sample_dash_img' />
                                        </Box>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <Box className="">
                                            <img src={Tableau2} alt='sample Dashboard' className='sample_dash_img' />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>

                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h4' className='black fw6'>
                                    Power BI Dashboard
                                </Typography>
                            </Box>

                            <Box className="reportinfo">
                                <Grid container spacing={4} alignItems="top" justifyContent="center">
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Box className="">
                                            <img src={PowerBI} alt='sample Dashboard' className='sample_dash_img' />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>


                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='al_center'>
                                <Typography variant='h6' className='black'>
                                    Learn more about how <strong>Decimal Point Analytics</strong> can elevate your data capabilities through intuitive, scalable BI solutions.
                                </Typography>
                            </Box>
                            <Box className='flx_center mob_center mt16'>
                                <ProductCTAA allignment="al_center" cta_content="Let’s Talk" cta_btn_name="Contact Us to Get Started" pageVisited="Data Visualization & BI Dashboards" />
                            </Box>
                        </Grid>

                    </Grid>
                </Container>
            </Box>


            <Box className="sec_bg">
                <GetInTouch />
            </Box>


        </>
    );
}
