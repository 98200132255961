import { Container, Grid, Typography, Box, Button } from '@material-ui/core';
import React, { useEffect } from 'react';
import Writemessage3 from '../../Component/Writemessage3'
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom/cjs/react-router-dom';

import offering1 from "../../asset/images/services/data_solutions/offering1.png";
import offering2 from "../../asset/images/services/data_solutions/offering2.png";
import offering3 from "../../asset/images/services/data_solutions/offering3.png";
import offering4 from "../../asset/images/services/data_solutions/offering4.png";
import offering5 from "../../asset/images/services/data_solutions/offering5.png";
import whyCP from "../../asset/images/product/creditpulse/why_credit_pulse.webp"

import viewofcustomerdata from "../../asset/images/insight/blog_thumbnails/360_degree.webp";
import AchievingData from "../../asset/images/insight/case_studies/case_studies_thumbnails/global_alternative_firm.webp";
import DataExtraction from "../../asset/images/insight/blog_thumbnails/data_extraction.webp";
import DeliveringComprehensive from "../../asset/images/insight/case_studies/case_studies_thumbnails/quotemedia.webp";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import GetInTouch from '../../Component/Get_in_touch';

import { Helmet } from 'react-helmet';

import DataSolution from "../../asset/images/new_banner/data_solution_banner.webp"

export default function Datasolution() {



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);





    return (
        <>


            <Helmet>
                <meta charset="UTF-8" />
                <title>End-to-End Data Management Solution | Data Acquisition, Cleaning & Web Extraction</title>
                <meta name="description" content="Streamline your data with our end-to-end solution, covering ingestion, cleaning, centralized storage, and web extraction for clean, standardized data." />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/data-solution-in-us" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />

            </Helmet>


            <Box className="banner_bx" mb={"2px"} >
                <Box style={{ backgroundImage: "url(" + DataSolution + ")" }} className="banner_img">
                    <Box className="img_over bl_overlay">
                        <Grid container spacing={0} alignItems="center" justifyContent='center'>
                            <Grid item lg={3} md={4} sm={5} xs={8}>
                                <Box className="al_center">
                                    <Typography className="white" variant="h1">Data Solution</Typography>
                                    <Box mt={'10px'}>
                                        <Typography className="white " variant="h4">Customized, Optimized And Enhanced Research Operations</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>

            <Writemessage3 pageVisited="Data Solution" />

            <Box className='section2 sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>


                    <Grid container direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='al_center'>
                                <Typography variant='h2' className='black'>
                                    End-to-End Data Management Solution
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className="al_center synopsic_bx">
                                <Typography variant='h6' className='black jst'>
                                    In today’s data-driven landscape, organizations are overwhelmed with massive amounts of data that need to be efficiently managed, processed, and leveraged for strategic decision-making. Our <strong>End-to-End Data Management Solution</strong> offers a comprehensive approach to capturing, organizing, and optimizing data from various sources. Whether you're dealing with structured or unstructured data, our solution ensures that your data is cleaned, validated, and stored in a centralized, accessible location—empowering your team to extract valuable insights and drive business growth.
                                </Typography>
                            </Box>

                        </Grid>
                    </Grid>
                </Container>
            </Box>


            <Box className='section mob_card' component='section'>
                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} direction="row" justifyContent="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='al_center'>
                                <Typography variant='h2' className='black'>
                                    Our Comprehensive Offering
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} alignItems="center" justifyContent='center'>
                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Box className='boxbg mnhg5'>
                                <Box className='al_center'>
                                    <img src={offering1} className='allic' alt='Data Acquisition & Ingestion' />
                                </Box>
                                <Box>
                                    <Grid container spacing={3} alignItems="center" justifyContent='center'>
                                        <Grid item lg={7} md={9} sm={7} xs={12}>
                                            <Box>
                                                <Typography variant='h4' className='blue al_center'>
                                                    Data Acquisition & Ingestion
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Typography variant='h6' className='black jst'>
                                        We provide seamless data acquisition and ingestion services, enabling you to collect data from various sources—whether internal, external, or real-time feeds. Our solution is tailored to capture high-quality data in various formats, ensuring that you can harness the full potential of your data from day one.
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Box className='boxbg mnhg5'>
                                <Box className='al_center'>
                                    <img src={offering2} className='allic' alt='Capture & Manage Structured & Unstructured Data' />
                                </Box>
                                <Box>
                                    <Grid container spacing={3} alignItems="center" justifyContent='center'>
                                        <Grid item lg={10} md={12} sm={11} xs={12}>
                                            <Box>
                                                <Typography variant='h4' className='blue al_center'>
                                                    Capture & Manage Structured & Unstructured Data
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Typography variant='h6' className='black jst'>
                                        Our platform supports the efficient capture and management of both structured and unstructured data. Whether it's transactional data from your systems or social media feeds, we help you store, organize, and process the data in a way that makes it actionable and ready for analysis.
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Box className='boxbg mnhg5'>
                                <Box className='al_center'>
                                    <img src={offering3} className='allic' alt='Data Cleaning, Standardization & Validation' />
                                </Box>
                                <Box>
                                    <Grid container spacing={3} alignItems="center" justifyContent='center'>
                                        <Grid item lg={10} md={12} sm={11} xs={12}>
                                            <Box>
                                                <Typography variant='h4' className='blue al_center'>
                                                    Data Cleaning, Standardization & Validation
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Typography variant='h6' className='black jst'>
                                        Data integrity is key to decision-making. We provide comprehensive data cleaning, standardization, and validation services to ensure that your data is free from errors, inconsistencies, and duplicates. Our solution helps you maintain high-quality data that is accurate, consistent, and reliable across your organization.
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Box className='boxbg mnhg5'>
                                <Box className='al_center'>
                                    <img src={offering4} className='allic' alt='Centralized Data Warehouse' />
                                </Box>
                                <Box>
                                    <Grid container spacing={3} alignItems="center" justifyContent='center'>
                                        <Grid item lg={6} md={8} sm={8} xs={12}>
                                            <Box>
                                                <Typography variant='h4' className='blue al_center'>
                                                    Centralized Data Warehouse
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Typography variant='h6' className='black jst'>
                                        Our centralized data warehouse solution consolidates all your data in one place, making it easy to access, analyze, and share across departments. We provide a scalable, secure platform that ensures your data is always available and optimized for decision-making, helping you drive business intelligence and insights.
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Box className='boxbg mnhg5'>
                                <Box className='al_center'>
                                    <img src={offering5} className='allic' alt='Web Data Extraction' />
                                </Box>
                                <Box>
                                    <Grid container spacing={3} alignItems="center" justifyContent='center'>
                                        <Grid item lg={6} md={6} sm={8} xs={12}>
                                            <Box>
                                                <Typography variant='h4' className='blue al_center'>
                                                    Web Data Extraction
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Typography variant='h6' className='black jst'>
                                        Leverage the power of web data with our web scraping and extraction services. We help you capture valuable insights from websites, online platforms, and social media, turning unstructured web data into structured, usable information. Whether for competitive analysis or market trends, our web data extraction services support your business intelligence needs.
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>

                </Container>
            </Box>

            <Box className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr' style={{ position: 'relative', }}>

                    <Box className='mob_card' mt={3}>
                        <Grid container spacing={1} alignItems="top">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className="al_center">
                                    <Typography className="black" variant="h2">
                                        Why Choose Us?
                                    </Typography>
                                </Box>

                                <Typography variant='h6' className='black al_center' gutterBottom>
                                    We provide a holistic, scalable, and secure data management solution that is designed to grow with your business. Here’s why our clients trust us:
                                </Typography>
                            </Grid>



                            <Grid item lg={5} md={5} sm={6} xs={12}>
                                <Box className='al_center'>
                                    <img src={whyCP} className='topbanner5' alt='Why Choose Us?' />
                                </Box>
                            </Grid>

                            <Grid item lg={7} md={7} sm={6} xs={12}>
                                <Box>
                                    <Typography className="blue" variant="h6">
                                        <ul>
                                            <li>
                                                <Typography variant="h6" className="black jst" gutterbottom>
                                                    <strong>Comprehensive Approach:   </strong> We cover the entire data lifecycle, from ingestion to extraction, ensuring end-to-end support.
                                                </Typography>
                                            </li>

                                            <li>
                                                <Typography variant="h6" className="black jst" gutterbottom>
                                                    <strong>Data Quality & Integrity:  </strong> With our advanced cleaning, validation, and standardization processes, we ensure your data is always accurate and reliable.
                                                </Typography>
                                            </li>

                                            <li>
                                                <Typography variant="h6" className="black jst" gutterbottom>
                                                    <strong>Scalable Solutions:  </strong> Our platform adapts to your business needs, enabling seamless integration as your data grows.
                                                </Typography>
                                            </li>

                                            <li>
                                                <Typography variant="h6" className="black jst" gutterbottom>
                                                    <strong>Expertise & Innovation:   </strong> With years of experience in data management, we combine cutting-edge technology with industry best practices to deliver the best results.
                                                </Typography>
                                            </li>
                                        </ul>
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                </Container>
            </Box>

            <Box className='section mob_card' component='section'>
                <Container maxWidth='lg' className='bdr'>
                    <Grid container spacing={2} alignItems="center" justifyContent='center'>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className="synopsic_bx al_center">
                                <Typography className="black" variant="h4">
                                    Get Started Today
                                </Typography>

                                <Typography className="black" variant="h6">
                                    Unlock the full potential of your data with our End-to-End Data Management Solution. <Link className='link' to='/contact-us#contact_form'>Contact Us</Link> to learn how we can help you streamline your data processes and drive better decision-making.
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box className='section mob_card sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>
                    <Grid container spacing={2} alignItems="center" justifyContent='center'>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className="al_center">
                                <Typography className="black" variant="h2">
                                    Explore <span className='lower'>our</span> Resources
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Box className='reportinfo'>
                                <Box className="reportm_bg">
                                    <Box
                                        className="reportimg_bg"
                                        style={{
                                            backgroundImage: 'url(' + DataExtraction + ')',
                                        }}
                                    >
                                        <Button variant="contained" className='ora_title_btn'>
                                            Blog
                                        </Button>
                                    </Box>

                                    <Box my={2}>
                                        <Typography className="reporttitle blue">
                                            Data Extraction from Complex Documents: Enhancing Efficiency with Decimal Point Analytics
                                        </Typography>
                                    </Box>
                                    <Box className="line"></Box>
                                    <Box mt={2}>
                                        <Link to="/blog/data-extraction-complex-documents">
                                            <Button variant="contained" className='dpabtn_sm1' endIcon={<NavigateNextIcon />}>
                                                Read More
                                            </Button>
                                        </Link>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Box className='reportinfo'>
                                <Box className="reportm_bg">
                                    <Box
                                        className="reportimg_bg"
                                        style={{
                                            backgroundImage: 'url(' + viewofcustomerdata + ')',
                                        }}
                                    >
                                        <Button variant="contained" className='ora_title_btn'>
                                            Blog
                                        </Button>
                                    </Box>

                                    <Box my={2}>
                                        <Typography className="reporttitle blue">
                                            How a 360-degree view of customer data can help companies anticipate changing preferences
                                        </Typography>
                                    </Box>
                                    <Box className="line"></Box>
                                    <Box mt={2}>
                                        <Link to="/blog/how-a-360-degree-view-of-customer-data-can-help-companies-anticipate-changing-preferences">
                                            <Button variant="contained" className='dpabtn_sm1' endIcon={<NavigateNextIcon />}>
                                                Read More
                                            </Button>
                                        </Link>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Box className='reportinfo'>
                                <Box className="reportm_bg">
                                    <Box
                                        className="reportimg_bg"
                                        style={{
                                            backgroundImage: 'url(' + AchievingData + ')',
                                        }}
                                    >
                                        <Button variant="contained" className='ora_title_btn'>
                                            Case Study
                                        </Button>
                                    </Box>

                                    <Box my={2}>
                                        <Typography className="reporttitle blue">
                                            Achieving 99% Data Accuracy in Contact Management for Global Alternatives Firm
                                        </Typography>
                                    </Box>
                                    <Box className="line"></Box>
                                    <Box mt={2}>
                                        <Link to="/case-studies/achieving-99-data-accuracy-in-contact-management-for-global-alternatives-firm">
                                            <Button variant="contained" className='dpabtn_sm1' endIcon={<NavigateNextIcon />}>
                                                Read More
                                            </Button>
                                        </Link>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Box className='reportinfo'>
                                <Box className="reportm_bg">
                                    <Box
                                        className="reportimg_bg"
                                        style={{
                                            backgroundImage: 'url(' + DeliveringComprehensive + ')',
                                        }}
                                    >
                                        <Button variant="contained" className='ora_title_btn'>
                                            Case Study
                                        </Button>
                                    </Box>

                                    <Box my={2}>
                                        <Typography className="reporttitle blue">
                                            Delivering Comprehensive Data Solutions for a Leading Financial Data Aggregator and Provider
                                        </Typography>
                                    </Box>
                                    <Box className="line"></Box>
                                    <Box mt={2}>
                                        <Link to="/case-studies/delivering-comprehensive-data-solutions-for-a-leading-financial-data-aggregator-and-provider">
                                            <Button variant="contained" className='dpabtn_sm1' endIcon={<NavigateNextIcon />}>
                                                Read More
                                            </Button>
                                        </Link>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box>
                <GetInTouch />
            </Box>

        </>
    );
}
