import { Container, Grid, Typography, Box, Button } from "@material-ui/core";
import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";


import dash1 from "../asset/images/index/dash01.webp";
import dash2 from "../asset/images/index/dash02.webp";
import dash3 from "../asset/images/index/dash03.webp";


import ds from "../asset/images/index/hm_ds.svg";
import rs from "../asset/images/index/hm_rs.svg";
import ai from "../asset/images/index/hm_ai.svg";
import bi from "../asset/images/index/hm_bi.svg";
import pd from "../asset/images/index/hm_pd.svg";
import md from "../asset/images/index/hm_md.svg";
import wd from "../asset/images/index/hm_wd.svg";
import uiux from "../asset/images/index/hm_uiux.svg";

import integrity from "../asset/images/index/integrity.svg";
import innovation from "../asset/images/index/innovation.svg";
import makediff from "../asset/images/index/makingdiff.svg";
import customer from "../asset/images/index/customer.svg";
import rest from "../asset/images/index/respandcare.svg";
import growth from "../asset/images/index/growth.svg";

import { Helmet } from "react-helmet";

import CarouselMulti from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import AIbanner from "../asset/images/landing_page/generative_ai.webp";
import RegTechBanner from "../asset/images/landing_page/canedian-regtech-banner.webp";

import UnlockingSuccess from "../asset/images/landing_page/banners/unlocking-success.webp";

import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import LandingAwards from "./LandingSections/LandingAwards";




export default function Index() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);


  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };


  return (
    <>


      <Helmet>
        <meta charset="UTF-8" />
        <h1>Decimal Point Analytics | Data Analytics | AI Consulting | Market Research</h1>
        <title>Decimal Point Analytics | Data Analytics | AI Consulting | Market Research </title>
        <meta name="description" content="Decimal Point Analytics is a leading data analytics, AI consulting, and market research firm that delivers actionable insights to businesses. We provide customized solutions to help organizations make data-driven decisions." />
        <meta name="keywords" content="data analytics, AI consulting, market research, data-driven decisions, customized solutions, actionable insights, Decimal Point Analytics" />
        <meta name="author" content="Decimal Point Analytics" />
        <link rel="canonical" href="https://www.decimalpointanalytics.com" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />

        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org/",
              "@type": "WebSite",
              "name": "Decimal Point Analytics",
              "url": "https://www.decimalpointanalytics.com/",
              "potentialAction": {
                "@type": "SearchAction",
                "target": "https://www.decimalpointanalytics.com/search?q={search_term_string}https://www.decimalpointanalytics.com/insight",
                "query-input": "required name=search_term_string"
              }
            }
          `}
        </script>
      </Helmet>




      <Box id="landing_carousel">
        <CarouselMulti
          responsive={responsive}
          arrows={false}
          swipeable={true}
          draggable={true}
          showDots={true}
          infinite={true}
          autoPlay={true}
          pauseOnHover={false}
          keyBoardControl={true}
          // transitionDuration={1500}
          customTransition="opacity 200ms ease-in-out"
          autoPlaySpeed={9500}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-0-px"
        >





          {/* <>
            <Box className="mainsection1" component="section" style={{ backgroundImage: "url(" + USAWhitepaperBanner + ")" }}>
              <Container maxWidth="lg">
                <Grid container spacing={1} alignItems="flex-start" justifyContent='center'>
                  <Grid item lg={5} md={6} sm={10} xs={10}>
                    <Box className="text_animated">
                      <Typography variant="h2" className="white mb0" gutterBottom>
                        Navigating Sustainability:
                      </Typography>

                      <Box>
                        <Typography variant="h1" className="white" gutterBottom>
                          USA's ESG Landscape in 2023
                        </Typography>
                      </Box>

                      <Box mt={2}>
                        <Link to="/esg-insights/esg-whitepaper/navigating-sustainability-usa-esg-landscape-in-2023">
                          <Button variant="contained" className='dpabtn_sm1' endIcon={<NavigateNextIcon />}>
                            Download Now
                          </Button>
                        </Link>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item lg={7} md={6} sm={10} xs={10}>
                  </Grid>
                </Grid>
              </Container>
            </Box>
          </> */}

          <>
            <Box className="mainsection1" component="section" style={{ backgroundImage: "url(" + UnlockingSuccess + ")" }}>
              <Container maxWidth="lg">
                <Grid container spacing={1} alignItems="flex-start" justifyContent='center'>
                  <Grid item lg={7} md={6} sm={10} xs={10}>
                    <Box className="text_animated">
                      <Typography variant="h2" className="blue mb0" gutterBottom>
                        Unlocking Success:
                      </Typography>

                      <Box>
                        <Typography variant="h1" className="blue" gutterBottom>
                          2024'<span className="lower">s</span> Top 5 ESG Trends for Asset Managers
                        </Typography>
                      </Box>

                      <Box mt={2}>
                        <Link to="/esg-insights/esg-blogs/unlocking-success-2024-top-five-esg-trends-for-asset-managers">
                          <Button variant="contained" className='dpabtn_sm1' endIcon={<NavigateNextIcon />}>
                            Download Now
                          </Button>
                        </Link>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item lg={5} md={6} sm={10} xs={10}>
                  </Grid>
                </Grid>
              </Container>
            </Box>
          </>

          <>
            <Box className="mainsection1" component="section" style={{ backgroundImage: "url(" + AIbanner + ")" }}>
              <Container maxWidth="lg">
                <Grid container spacing={1} alignItems="flex-start" justifyContent='center'>
                  <Grid item lg={5} md={6} sm={10} xs={10}>
                    <Box className="text_animated">
                      <Typography variant="h1" className="white" gutterBottom>
                        Generative AI:
                      </Typography>
                      <Typography variant="h2" className="white" gutterBottom>
                        Empower Your Enterprise with Data-Driven Decisions
                      </Typography>

                      <Box mt={2}>
                        <Link to="/artificial-intelligence-solutions">
                          <Button variant="contained" className='dpabtn_sm1'>
                            Learn More
                          </Button>
                        </Link>
                      </Box>

                    </Box>
                  </Grid>

                  <Grid item lg={7} md={6} sm={10} xs={10}>
                  </Grid>
                </Grid>
              </Container>
            </Box>
          </>

          <>
            <Box className="mainsection1" component="section" style={{ backgroundImage: "url(" + RegTechBanner + ")" }}>
              <Container maxWidth="lg">
                <Grid container spacing={1} alignItems="flex-start" justifyContent='center'>
                  <Grid item lg={5} md={6} sm={10} xs={10}>
                    <Box className="text_animated">
                      <Typography variant="h2" className="white mb0" gutterBottom>
                        Decimal Point Analytics Wins Canadian Reg Tech Award for
                      </Typography>

                      <Box>
                        <Typography variant="h1" className="white" gutterBottom>
                          Best ESG Solution 2023
                        </Typography>
                      </Box>

                      <Box mt={2}>
                        <Link to="/awards#canadian-regtech-award">
                          <Button variant="contained" className='dpabtn_sm1' endIcon={<NavigateNextIcon />}>
                            Read More
                          </Button>
                        </Link>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item lg={7} md={6} sm={10} xs={10}>
                  </Grid>
                </Grid>
              </Container>
            </Box>
          </>


          {/* <>
            <Box className="mainsection1" component="section" style={{ backgroundImage: "url(" + Oracle + ")" }}>
              <Container maxWidth="lg">
                <Grid container spacing={1} alignItems="flex-start" justifyContent='center'>
                  <Grid item lg={7} md={7} sm={10} xs={10}>
                    <Box className="text_animated">
                      <Typography variant="h2" className="white mb0" gutterBottom>
                        Oracle Global Leaders Recognizes <br /> Decimal Point Analytics as a
                      </Typography>

                      <Box>
                        <Typography variant="h1" className="white" gutterBottom>
                          Independent Software Vendor (ISV) of the Year 2023
                        </Typography>
                      </Box>

                      <Box mt={2}>
                        <Link to="/oracle-global-leaders-recognizes-decimal-point-analytics-as-isv-of-the-year-2023">
                          <Button variant="contained" className='dpabtn_sm1' endIcon={<NavigateNextIcon />}>
                            Read More
                          </Button>
                        </Link>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item lg={5} md={5} sm={10} xs={10}>
                  </Grid>
                </Grid>
              </Container>
            </Box>
          </> */}




          {/* <>
            <Box className="mainsection1" component="section" style={{ backgroundImage: "url(" + SouthAfricaESG + ")" }}>
              <Container maxWidth="lg">
                <Grid container spacing={1} alignItems="flex-start" justifyContent='center'>
                  <Grid item lg={7} md={7} sm={10} xs={10}>
                    <Box className="text_animated">
                      <Typography variant="h1" className="skblue" gutterBottom>
                        South Africa ESG Review 2023 Whitepaper
                      </Typography>
                      <Typography variant="h2" className="white" gutterBottom>
                        Navigating South Africa's ESG Terrain Towards a Resilient Future
                      </Typography>

                      <Box mt={2}>
                        <Link to="/esg-insights/esg-whitepaper/esg-south-africa-review-2023-whitepaper">
                          <Button variant="contained" className='dpabtn_sm1' endIcon={<NavigateNextIcon />}>
                            Download Now
                          </Button>
                        </Link>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item lg={5} md={5} sm={10} xs={10}>
                  </Grid>
                </Grid>
              </Container>
            </Box>
          </> */}

          {/* <>
            <Box className="mainsection1" component="section" style={{ backgroundImage: "url(" + indexbanner3 + ")" }}>
              <Container maxWidth="lg">
                <Grid container spacing={1} alignItems="flex-start" justifyContent='center'>
                  <Grid item lg={6} md={6} sm={10} xs={10}>
                    <Box className="text_animated">
                      <Typography variant="h1" className="white" gutterBottom>
                        Empowering Sustainable <span className="skblue"> Investment </span>
                      </Typography>

                      <Link to="/environmental-social-and-governance-for-financial-institutions-and-enterprise-businesses">
                        <Button variant="contained" className='dpabtn_sm1'>
                          Learn More
                        </Button>
                      </Link>
                    </Box>
                  </Grid>

                  <Grid item lg={6} md={6} sm={10} xs={10}>
                  </Grid>
                </Grid>
              </Container>
            </Box>
          </> */}

          {/* <>
            <Box className="mainsection1" component="section" style={{ backgroundImage: "url(" + AIDrivenAutomation + ")" }}>
              <Container maxWidth="lg">
                <Grid container spacing={1} alignItems="flex-start" justifyContent='center'>
                  <Grid item lg={6} md={6} sm={10} xs={10}>
                    <Box className="text_animated">
                      <Typography variant="h1" className="white" gutterBottom>
                        <span className="skblue"> Empowering </span> AI-Driven Automation
                      </Typography>

                      <Link to="/case-studies/how-ai-driven-automation-revolutionizes-a-leading-european-industrial-company">
                        <Button variant="contained" className='dpabtn_sm1'>
                          Learn More
                        </Button>
                      </Link>
                    </Box>
                  </Grid>

                  <Grid item lg={6} md={6} sm={10} xs={10}>
                  </Grid>
                </Grid>
              </Container>
            </Box>
          </> */}




          {/* <>
            <Box className="mainsection1" component="section" style={{ backgroundImage: "url(" + indexbanner1 + ")" }}>
              <Container maxWidth="lg">
                <Grid container spacing={1} alignItems="flex-start" justifyContent='center'>
                  <Grid item lg={7} md={7} sm={10} xs={10}>
                    <Box className="text_animated">
                      <Typography variant="h1" className="white" gutterBottom>
                        Empowering <span className="skblue"> Smarter Business </span> Decision with Data-Driven Solutions
                      </Typography>

                      <Link to="/creditpulse">
                        <Button variant="contained" className='dpabtn_sm1'>
                          Learn More
                        </Button>
                      </Link>
                    </Box>
                  </Grid>

                  <Grid item lg={5} md={5} sm={10} xs={10}>
                  </Grid>
                </Grid>
              </Container>
            </Box>
          </> */}


        </CarouselMulti>
      </Box>


      <Box className="section" component="section">
        <Container maxWidth="lg" className="bdr">
          <Grid container spacing={1} alignItems="top">
            <Grid item lg={5} md={6} sm={6} xs={12} >
              <Box>
                <Typography variant="h2" className="black">
                  Decimal Point Analytics
                </Typography>
                <Typography variant="h6" className="black jst">
                  Decimal Point Analytics is a modern, technology enabled
                  financial research consulting company. Our combination of
                  machine learning algorithms with talented professional team
                  enables us to create unique, tailor-made-products, real-time
                  services and solutions for our clients. Our unmatched value
                  proposition has been increasingly appreciated by clients
                  across the globe.
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={7} md={6} sm={6} xs={12} >
              <Box className="al_center dpa_caro">
                <Box className="custom1 owl-carousel owl-carousel owl-theme">
                  <Box className="item ">
                    <img src={dash1} className="slideimg" alt="Dash1" />
                  </Box>
                  <Box className="item">
                    <img src={dash2} className="slideimg" alt="Dash2" />
                  </Box>
                  <Box className="item">
                    <img src={dash3} className="slideimg" alt="Dash3" />
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box className="section whydecimal owldot" component="section">
        <Container maxWidth="lg" className="bdr">
          <Grid container spacing={1} alignItems="top">
            <Grid item lg={12} md={12} sm={12} xs={12} >
              <Box className="al_center">
                <Typography variant="h2" className="black">
                  So, Why Decimal
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={0} alignItems="top">
            <Grid item lg={12} md={12} sm={12} xs={12} >
              <Box id="why_decimal_owl" className="owl-carousel loop">
                <Box>
                  <img src={ds} className="caroic" alt="DS" />
                  <Typography variant="h4" className="black hoverwh">
                    Data Solution
                  </Typography>
                  <Typography variant="h6" className="black hoverwh">
                    Customized, Optimized and Enhanced research operations
                  </Typography>
                </Box>

                <Box>
                  <img src={rs} className="caroic" alt="RS" />
                  <Typography variant="h4" className="black hoverwh">
                    Research Solution
                  </Typography>
                  <Typography variant="h6" className="black hoverwh">
                    Augment your resources, Bespoke Research for all your needs
                  </Typography>
                </Box>

                <Box>
                  <img src={ai} className="caroic" alt="AI" />
                  <Typography variant="h4" className="black hoverwh">
                    <span className="upp">AI</span> Solution
                  </Typography>
                  <Typography variant="h6" className="black hoverwh">
                    Stay Ahead on the AI Curve, Increase Productivity and
                    Efficiency
                  </Typography>
                </Box>

                <Box>
                  <img src={bi} className="caroic" alt="BI" />
                  <Typography variant="h4" className="black hoverwh">
                    <span className="upp">BI</span> Solution
                  </Typography>
                  <Typography variant="h6" className="black hoverwh">
                    Make Informed business decision, overcome the analytical
                    bottleneck
                  </Typography>
                </Box>

                <Box>
                  <img src={pd} className="caroic" alt="PD" />
                  <Typography variant="h4" className="black hoverwh">
                    Product Development
                  </Typography>
                  <Typography variant="h6" className="black hoverwh">
                    Innovative solutions from ideation to launch
                  </Typography>
                </Box>

                <Box>
                  <img src={md} className="caroic" alt="MD" />
                  <Typography variant="h4" className="black hoverwh">
                    App Development
                  </Typography>
                  <Typography variant="h6" className="black hoverwh">
                    Delivering digital success through intuitive solutions
                  </Typography>
                </Box>

                <Box>
                  <img src={wd} className="caroic" alt="WD" />
                  <Typography variant="h4" className="black hoverwh">
                    Website Development
                  </Typography>
                  <Typography variant="h6" className="black hoverwh">
                    State-of-the-art and modern web design with simplicity in
                    mind
                  </Typography>
                </Box>

                <Box>
                  <img src={uiux} className="caroic" alt="UI" />
                  <Typography variant="h4" className="black hoverwh">
                    <span className="upp">UI/UX</span>
                  </Typography>
                  <Typography variant="h6" className="black hoverwh">
                    Human-Centric approach to design transformation
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box className="section" component="section" id="corevalue">
        <Container maxWidth className="fullcontainer">
          <Grid
            container
            spacing={0}
            alignItems="center"
            className="rowfullheight"
          >
            <Grid item lg={6} md={6} sm={12} xs={12} id="left" >
              <Box>
                <Typography className="white" variant="h2">
                  Our Core Value
                </Typography>
                <Typography className="white jst" variant="h6">
                  Our commitment to delivering excellence is driven by our ethos
                  and an even stronger commitment towards our core values. We at
                  DPA are pledged to hold our core values above all and at all
                  times, as they are the foundation of our approach towards
                  every solution.
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12} id="red" >
              <Box className="custom2 owl-carousel owl-carousel owl-theme">
                <Box className="item">
                  <Box className="corvaluebx">
                    <img src={integrity} className="caroic" alt="Integrity" />
                    <Box mb={3}>
                      <Typography className="blue" variant="h2">
                        Integrity
                      </Typography>
                    </Box>
                    <Typography className="black jst" variant="h6">
                      We believe in being transparent in the work we do and the
                      approach we take. We have honest dialogues with our
                      clients and with each other. We are accountable, we
                      challenge unacceptable behaviour,create better
                      understanding and trust.
                    </Typography>
                  </Box>
                </Box>

                <Box className="item ">
                  <Box className="corvaluebx">
                    <img src={innovation} className="caroic" alt="Innovation" />
                    <Box mb={3}>
                      <Typography className="blue" variant="h2">
                        Innovation
                      </Typography>
                    </Box>
                    <Typography className="black jst" variant="h6">
                      We support creativity and innovation to achieve better
                      outcomes. We believe in having an entrepreneurial spirit,
                      being agile, adaptable, and innovative. We are empowered
                      to continuously review.
                    </Typography>
                  </Box>
                </Box>

                <Box className="item ">
                  <Box className="corvaluebx">
                    <img src={makediff} className="caroic" alt="Make Diff" />
                    <Box mb={3}>
                      <Typography className="blue" variant="h2">
                        Making a Difference
                      </Typography>
                    </Box>
                    <Typography className="black jst" variant="h6">
                      We aim to create long-term success for our clients, other
                      stakeholders, and society. We are passionate that our work
                      impacts lives for the better. We believe in being
                      competitive while showing humility and hunger.
                    </Typography>
                  </Box>
                </Box>

                <Box className="item ">
                  <Box className="corvaluebx">
                    <img src={customer} className="caroic" alt="Customer" />
                    <Box mb={3}>
                      <Typography className="blue" variant="h2">
                        Customer Centricity
                      </Typography>
                    </Box>
                    <Typography className="black jst" variant="h6">
                      We take collective ownership of outcomes, always suggest
                      what is right for the client and do it right the first
                      time, and always strive to surpass the expectations of the
                      client.
                    </Typography>
                  </Box>
                </Box>

                <Box className="item ">
                  <Box className="corvaluebx">
                    <img src={rest} className="caroic" alt="Rest" />
                    <Box mb={3}>
                      <Typography className="blue" variant="h2">
                        Respect and Care
                      </Typography>
                    </Box>
                    <Typography className="black jst" variant="h6">
                      We are thoughtful, empathetic, and compassionate. We
                      exhibit care and respect for each other and our clients.
                      We create fulfilling work, recognize and celebrate
                      achievements, integrate work-life balance, and have fun.
                    </Typography>
                  </Box>
                </Box>

                <Box className="item ">
                  <Box className="corvaluebx">
                    <img src={growth} className="caroic" alt="Growth" />
                    <Box mb={3}>
                      <Typography className="blue" variant="h2">
                        Constant Learning and Growth
                      </Typography>
                    </Box>
                    <Typography className="black jst" variant="h6">
                      We get motivated by constantly growing and so we always
                      strive to upgrade knowledge and skills. We constantly
                      learn new things for personal and professional growth to
                      remain relevant in the fast-changing environment.
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box className="section  ourstrength" component="section">
        <Container maxWidth="lg" className="bdr">
          <Grid container spacing={1} alignItems="top">
            <Grid item lg={12} md={12} sm={12} xs={12} >
              <Box className="al_center">
                <Typography className="black" variant="h2">
                  Our Strength
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Box mt={3}>
            <Grid container spacing={1} alignItems="top">
              <Grid item lg={3} md={6} sm={6} xs={6} >
                <Box className="str_bg">
                  <Box className="numcircle" mb={2}>
                    350 +
                  </Box>
                  <Typography className="black" variant="h4" style={{ textAlign: 'center' }}>
                    Happy Customers
                  </Typography>
                </Box>
              </Grid>

              <Grid item lg={3} md={6} sm={6} xs={6} >
                <Box className="str_bg">
                  <Box className="numcircle" mb={2}>
                    700 +
                  </Box>
                  <Typography className="black" variant="h4" style={{ textAlign: 'center' }}>
                    Projects Completed
                  </Typography>
                </Box>
              </Grid>

              <Grid item lg={3} md={6} sm={6} xs={6} >
                <Box className="str_bg">
                  <Box className="numcircle" mb={2}>
                    05
                  </Box>
                  <Typography className="black" variant="h4" style={{ textAlign: 'center' }}>
                    Offices Worldwide
                  </Typography>
                </Box>
              </Grid>

              <Grid item lg={3} md={6} sm={6} xs={6} >
                <Box className="str_bg">
                  <Box className="numcircle" mb={2}>
                    80 +
                  </Box>
                  <Typography className="black" variant="h4" style={{ textAlign: 'center' }}>
                    Expert Advisors
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>



      <Box>
        <LandingAwards />
      </Box>





    </>
  );
}
