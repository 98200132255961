import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Helmet } from 'react-helmet';

import Casestudyback from '../../../../Component/Casestudyback'

import Banner from "../../../../asset/images/insight/case_studies/banner_improving_customer.webp"
import Infographics from "../../../../asset/images/insight/case_studies/accelerated_loan.webp"
import GetInTouch from '../../../../Component/Get_in_touch';



export default function ImprovingCustomerAcquisition() {



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet><meta charset="UTF-8" />
                <meta charset="UTF-8" />
                <title>Reducing Decision-Making Time by 25% for Faster Customer Acquisition</title>
                <meta name="description" content="Discover how a finance firm improved customer acquisition by streamlining loan screening, reducing decision times by 25%, and enhancing operational efficiency." />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/case-studies/improving-customer-acquisition-by-25-faster-screening-and-40-increased-efficiency" />
            </Helmet>

            <Box id="case-studies" className='section sec_bg new_cs' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={3} alignItems="top" justifyContent='center'>
                        <Grid item lg={9} md={9} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Success Stories
                                </Typography>
                                <Typography className='mainhead blue al_center'>
                                    Improving Customer Acquisition  <span className='lower'> by</span> 25% Faster Screening  <span className='lower'>and</span> 40% Increased Efficiency
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Box >
                        <img src={Banner} className='w100' alt='Banner' />
                    </Box>


                    <Box className='whitebx'>
                        <Box>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Executive Summary
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                Delays in loan screening slowed decision-making and customer acquisition, limiting competitiveness in the market. By optimizing the screening process, leveraging time-zone advantages, and implementing advanced tools, decision times decreased by 25%, operational efficiency increased by 40%, and customer acquisition accelerated, driving growth and improved market positioning.
                            </Typography>
                        </Box>


                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                The Challenge
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                The firm faced several issues impacting customer acquisition:
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Slow Loan Screening: </strong>  Delays in the screening process affected decision-making speed and slowed customer onboarding.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Delayed Approvals: </strong>  Slow pre-underwriting processes reduced loan approval speed, impacting customer acquisition growth.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Operational Inefficiencies: </strong>  Inefficient pre-underwriting workflows limited the firm’s ability to process increased volumes effectively.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Competitive Disadvantage: </strong>   Slow processing times hindered the firm’s competitiveness in the market.
                                        </Typography>
                                    </li>
                                </ul>
                            </Typography>
                        </Box>

                        <Box mt={3} className='mob_card'>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Solutions: Accelerated Loan Screening
                            </Typography>

                            <Grid container direction="row"
                                justifyContent="center"
                                alignItems="center">

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className="al_center" my={3}>
                                        <img src={Infographics} className="w100" alt="Solutions: Accelerated Loan Screening" />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Results
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>25% Reduction in Decision-Making Time: </strong>  Faster screening led to quicker decisions and approvals.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>40% Improvement in Efficiency: </strong>  Streamlined processes allowed for faster customer acquisition.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Increased Market Share: </strong>   Enhanced processing speed and faster approvals improved competitiveness.
                                        </Typography>
                                    </li>
                                </ul>
                            </Typography>


                        </Box>

                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Key Takeaways
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Faster Approvals: </strong>   Streamlined screening reduced decision times, improving customer acquisition.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Improved Efficiency: </strong>   Enhanced workflows increased processing capacity and market share.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Competitive Advantage: </strong>   Faster decisions strengthened market competitiveness and customer satisfaction.
                                        </Typography>
                                    </li>
                                </ul>

                            </Typography>
                        </Box>

                        <Typography variant='h6' className='black jst' gutterBottom>
                            Faster loan screening and decision-making are key drivers of customer acquisition and market competitiveness. Implement streamlined processes and utilize time-zone advantages to maximize efficiency and growth.
                        </Typography>

                        <Typography variant='h6' className='black jst' gutterBottom>
                            Looking to accelerate customer acquisition? Explore solutions that enhance loan screening and drive quicker decisions to boost market growth.
                        </Typography>
                    </Box>

                    <Box id="sba_success_stories">
                        <GetInTouch />
                    </Box>

                    <Casestudyback />

                </Container>
            </Box>


        </>
    );
}