import React, { useEffect, useState } from "react";

import {
  Checkbox,
  FormControlLabel,
  TextField,
  Button,
  Grid,
  Typography,
  Box,
} from "@material-ui/core";
import { useHistory, Link } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import LoadingButton from "@mui/lab/LoadingButton";
import flags from "react-phone-number-input/flags";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { Alert as MuiAlert } from "@material-ui/lab";
import CustomePhoneNumber from "../CustomePhoneNumber_Not_Mandatory";
import { currentApplicationApi } from "../api/api";
import Uploadfile from "../Uploadfile";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-number-input/style.css";
import "aos/dist/aos.css";

export default function CandidateRegistration({ jobData }) {
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loader, setLoader] = React.useState(false);
  const indiaPhoneRegex = /^[6-9]\d{9}$/;
  const [open, setOpen] = React.useState(false);
  const [checkboxFlag, setCheckboxFlag] = React.useState(false);
  const [phoneValueError, setPhoneValueError] = React.useState();
  const [phoneValue1, setPhoneValue1] = React.useState();
  const [checkboxError, setCheckboxError] = React.useState("");
  const [resumeFile, setResumeFile] = React.useState(null);
  const [resumeError, setResumeError] = React.useState(false);
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [buttonClicked, setButtonClicked] = useState(false);
  const [fileName, setFileName] = useState("");
  const [submitClicked, setSubmitClicked] = useState(false);
  const [buttonClickedWhileDisabled, setButtonClickedWhileDisabled] =
    useState(false);
  const emailreg =
    /^[a-zA-Z0-9_]+(?:[-._][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+(?:[-.][a-zA-Z0-9_]+)*\.[a-zA-Z]{2,}$/;
  const nameValidation = /^[A-Za-z\s]+$/;
  const history = useHistory();
  const initialValues = {
    firstname: "",
    lastname: "",
    email: "",
    phonevalue1: "",
    tandc: true,
    resumeFile: "",
  };
  const validationSchema = yup.object().shape({
    firstname: yup
      .string()
      .typeError("Required field")
      .required("First name is required")
      .max(100)
      .matches(nameValidation, "Enter valid first name"),
    lastname: yup
      .string()
      .typeError("Required field")
      .required("Last name is required")
      .max(100)
      .matches(nameValidation, "Enter valid last name"),
    email: yup
      .string()
      .typeError("Required field")
      .required("Email is required")
      .matches(emailreg, "Email is not valid"),
    phonevalue1: yup.string(),
    tandc: yup.bool().oneOf([true], "You must accept the terms and conditions"),
  });

  const handleKeyUp = (event, fieldName) => {
    const value = event.target.value;
    if (/\d+/.test(value)) {
      if (fieldName === "firstname") {
        setFirstNameError("First name should not contain numbers");
      } else if (fieldName === "lastname") {
        setLastNameError("Last name should not contain numbers");
      }
    } else {
      if (fieldName === "firstname") {
        setFirstNameError("");
      } else if (fieldName === "lastname") {
        setLastNameError("");
      }
    }
  };
  const handleDisabledButtonClick = () => {
    setButtonClickedWhileDisabled(true);
  };

  useEffect(() => {}, [fileName]);
  const onSubmit = (values) => {
    setButtonClicked(true);
    setResumeError(false);
    setButtonClickedWhileDisabled(false);

    
    if (phoneValueError) {
      return;
    }
    if (!resumeFile) {
      setResumeError(true);
      return;
    }
    const formData = {
      first_name: values.firstname,
      last_name: values.lastname,
      email: values.email,
      current_opening_id: jobData.id,
      contact_no: values.phonevalue1,
      resume_file: resumeFile,
      accept_terms_condition: values.tandc,
    };
    console.log("........formdata", formData);
    setCheckboxError("");
    handleFormSubmit(formData);
  };
  const handleFormSubmit = (values) => {
    setSubmitClicked(true);

    if (!values.current_opening_id) {
      console.error('Missing job ID in submission');
      return;
    }
    
    if (!resumeFile) {
      setResumeError(true);
      return;
    }
    post(values);
  };

  const isPhoneValid = () => {
    if (!phoneValue1) {
      setPhoneValueError("");
      return;
    }

    var phoneValue2 = phoneValue1.includes("+91")
      ? phoneValue1.substring(3)
      : phoneValue1;
    if (phoneValue2.trim() === "") {
      setPhoneValueError("Enter phone number");
      return;
    }

    if (isValidPhoneNumber(phoneValue1)) {
      if (phoneValue1.includes("+91")) {
        if (!indiaPhoneRegex.test(phoneValue2)) {
          setPhoneValueError("Invalid phone number");
          return;
        }
      }
      setPhoneValue1(phoneValue1);
      setPhoneValueError("");
    } else {
      setPhoneValueError("Invalid phone number");
    }
  };

  const onSucccessCall = () => {
    setCheckboxFlag(false);
    handleClose();
    history.push("/successfully-connected");
  };
  const post = async (formData) => {
    setLoader(true);
    const message = "Oops, something went wrong, Please try again later.";
    try {
      const { data, status } = await currentApplicationApi(formData);
      if (status === 200) {
        if (data.status === true) {
          onSucccessCall();
        } else {
          toast.error(data.message, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
    } catch (error) {
      toast.error(message, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    setLoader(false);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Box className="whitebx">
        <Box className="al_center">
          <Typography variant="h4" className="blue">
            Candidate Registration
          </Typography>
        </Box>

        <Box>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            validateOnChange={true}
          >
            {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldError,
              isSubmitting,
              touched,
              errors,
            }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Box className="alltxfieldlg">
                      <Field
                        as={TextField}
                        id="filled-basic1"
                        label="First Name *"
                        variant="standard"
                        fullWidth
                        inputProps={{ maxLength: 100 }}
                        name="firstname"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onKeyUp={(event) => handleKeyUp(event, "firstname")}
                        error={touched.firstname && Boolean(errors.firstname)}
                        helperText={
                          touched.firstname && errors.firstname
                            ? errors.firstname
                            : firstNameError
                        }
                        FormHelperTextProps={{ style: { color: "red" } }}
                      />
                    </Box>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Box className="alltxfieldlg">
                      <Field
                        as={TextField}
                        id="filled-basic2"
                        label="Last Name *"
                        variant="standard"
                        fullWidth
                        inputProps={{ maxLength: 100 }}
                        name="lastname"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onKeyUp={(event) => handleKeyUp(event, "lastname")}
                        error={touched.lastname && Boolean(errors.lastname)}
                        helperText={
                          touched.lastname && errors.lastname
                            ? errors.lastname
                            : lastNameError
                        }
                        FormHelperTextProps={{ style: { color: "red" } }}
                      />
                    </Box>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Box className="alltxfieldlg ">
                      <Field
                        as={TextField}
                        id="filled-basic3"
                        label="Email Address *"
                        variant="standard"
                        name="email"
                        inputProps={{ maxLength: 100 }}
                        error={touched.email && Boolean(errors.email)}
                        helperText={touched.email && errors.email}
                        fullWidth
                      />
                    </Box>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Box className="alltxfieldlg " mt={2}>
                      <Field
                        as={PhoneInput}
                        flags={flags}
                        international
                        name="phonevalue1"
                        placeholder="Enter phone number"
                        value={values.phonevalue1}
                        inputComponent={CustomePhoneNumber}
                        onChange={(value) => {
                          handleChange({
                            target: { name: "phonevalue1", value: value },
                          });
                          setPhoneValue1(value);
                        }}
                        onBlur={() => {
                          handleBlur("phonevalue1");
                          isPhoneValid();
                        }}
                        error={
                          touched.phonevalue1 && Boolean(errors.phonevalue1)
                        }
                        helperText={
                          touched.phonevalue1 && errors.phonevalue1
                            ? errors.phonevalue1
                            : phoneValueError
                        }
                        FormHelperTextProps={{ style: { color: "red" } }}
                      />
                    </Box>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Box className="alltxfieldlg flx_al_b" mt={2}>
                      <Typography variant="h6" className="MuiFormLabel-root">
                        Resume *
                      </Typography>
                      <Box className="upload_box">
                        <Uploadfile
                          setResumeFile={setResumeFile}
                          setFileName={setFileName}
                          submitClicked={submitClicked}
                        />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Box className="alltxfieldlg chkbx" mt={2}>
                      <FormControlLabel
                        control={
                          <>
                            <Field
                              defaultChecked={true}
                              as={Checkbox}
                              name="tandc"
                              color="primary"
                              size="small"
                            />
                          </>
                        }
                        label={
                          <Typography className="label2">
                            By clicking submit, I agree to Decimal Point
                            Analytics <Link to="/terms">Terms of Use</Link> and{" "}
                            <Link to="/policy">Privacy Policy</Link>
                          </Typography>
                        }
                      />
                    </Box>
                    <Box className="checkbx-error">
                      {touched.tandc && errors.tandc && (
                        <MuiAlert severity="error">{errors.tandc}</MuiAlert>
                      )}
                    </Box>
                    <Box className="checkbx-error " pt={1}>
                      <Typography
                        variant="body2"
                        style={{ color: "red", fontSize: "12px" }}
                      >
                        {errors?.tandc?.message}
                      </Typography>
                    </Box>
                    <Box className="checkbx-error" pt={1}>
                      {buttonClickedWhileDisabled && !fileName && (
                        <MuiAlert severity="error">
                          {" "}
                          Resume file is required
                        </MuiAlert>
                      )}
                    </Box>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Box className="al_center" mt={1}>
                      <Link>
                        {loader ? (
                          <LoadingButton
                            loading={true}
                            variant="outlined"
                            disabled
                          >
                            disabled
                          </LoadingButton>
                        ) : (
                          <>
                            <div onClick={handleDisabledButtonClick}>
                              <Button
                                onClick={() => {
                                  handleSubmit(onSubmit)();
                                }}
                                variant="contained"
                                className="dpabtn_1 mobbtn"
                                disabled={!fileName}
                              >
                                Submit
                              </Button>
                            </div>
                          </>
                        )}
                      </Link>
                    </Box>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Box className="alltxfieldlg graybbx" p={2}>
                      <Typography variant="h6" className="jst">
                        Decimal Point Analytics (DPA) will process the
                        information in this form to share information as
                        requested. By checking the above box you confirm your
                        acceptance to receive the communication.
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Box>
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </Box>
    </>
  );
}
