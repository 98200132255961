import { TextField, Button, Grid, Box } from "@material-ui/core";
import React from "react";
// import { Formik, Field, Form } from "formik";
import { esgFormApi } from "../api/api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";

export default function BlogSubmitForm(props) {
  const history = useHistory();
  const emailreg =
    /^[a-zA-Z0-9_]+(?:[-._][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+(?:[-.][a-zA-Z0-9_]+)*\.[a-zA-Z]{2,}$/;
  const nameValidation = /^[A-Za-z\s]+$/;
  const organizationReg = /^[a-zA-Z0-9 ,-./!@#$%^&*~]*$/;

  const { handleSubmit, control } = useForm();
  const [loader, setLoader] = React.useState(false);

  const onSubmit = async (data) => {
    
    let isValid = true;
    const newErrors = {};

    if (!data.name || data.name.trim() === "") {
      newErrors.name = "Name is required";
      isValid = false;
    } else if (!nameValidation.test(data.name)) {
      newErrors.name = "Name is not valid";
      isValid = false;
    } else if (!data.email || data.email.trim() === "") {
      newErrors.email = "Email is required";
      isValid = false;
    } else if (!emailreg.test(data.email)) {
      newErrors.email = "Email is not valid";
      isValid = false;
    } else if (!data.company || data.company.trim() === "") {
      newErrors.company = "Company name is required";
      isValid = false;
    } else if (!organizationReg.test(data.company)) {
      newErrors.company = "Company name is not valid";
      isValid = false;
    }

    if (!isValid) {
      Object.values(newErrors).forEach((error) => {
        toast.info(error, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
      return;
    }

    const payload = {
      email: data.email,
      report_type: props.reportby,
    };

    if (data.name.trim() !== "") {
      payload.name = data.name;
    }

    if (data.company.trim() !== "") {
      payload.company = data.company;
    }
    setLoader(true);
    const d1 = await esgFormApi(payload);

    if (d1.data.status === true) {
      history.push("/form-submit");
    } else {
      toast.error("Please Enter Valid details", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    setLoader(false);
  };

  return (
    <>
      <Box>
        {/* <Form> */}
        <Grid container spacing={2}>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <Box className="alltxfieldlg" mt={1}>
              <Controller
                name="name"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    placeholder="Name *"
                    variant="standard"
                    inputProps={{ maxLength: 50 }}
                    fullWidth
                  />
                )}
              />
            </Box>
          </Grid>

          <Grid item lg={3} md={4} sm={6} xs={12}>
            <Box className="alltxfieldlg" mt={1}>
              <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    placeholder="Email Id *"
                    variant="standard"
                    fullWidth
                  />
                )}
              />
            </Box>
          </Grid>

          <Grid item lg={3} md={4} sm={6} xs={12}>
            <Box className="alltxfieldlg" mt={1}>
              <Controller
                name="company"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    placeholder="Company Name *"
                    variant="standard"
                    inputProps={{ maxLength: 50 }}
                    fullWidth
                  />
                )}
              />
            </Box>
          </Grid>

          <Grid item lg={3} md={4} sm={6} xs={12}>
            <Box className="al_center" mt={1}>
              {loader ? (
                <LoadingButton loading={true} variant="outlined" disabled>
                  disabled
                </LoadingButton>
              ) : (
                <Button
                  onClick={handleSubmit(onSubmit)}
                  variant="contained"
                  className="dpabtn_2"
                  type="submit"
                >
                  Submit
                </Button>
              )}
              {/* <Button variant="contained" className="dpabtn_2" type="submit" 
              onClick={handleSubmit(onSubmit)}>
                Submit
              </Button> */}
            </Box>
          </Grid>
        </Grid>
        {/* </Form> */}
      </Box>
    </>
  );
}
