import { Container, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';

import Infoback from '../../../Component/Infoback'
import Writemessage3 from '../../../Component/Writemessage3';
import Aos from 'aos'
import taxipdf from '../../../asset/images/insight/pdf/taxi_aggregator_market.jpg'
import { Helmet } from "react-helmet";


export default function Taxi_aggregator() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>


<Helmet>
<meta charset="UTF-8"/>
<h1>Taxi Aggregator Market Infographics - Latest Trends and Insights" </h1>
<title>Taxi Aggregator Market Infographics | Decimal Point Analytics</title>
<meta name="description" content="Stay updated with trends in the Taxi Aggregator Market through our infographics. Get data-driven insights on market size, key players, and consumer behavior." />
<meta name="keywords" content="industry analysis
market share
market research reports
market share
market reports" />
<meta name="author" content="Decimal Point Analytics" />
<link rel="canonical" href="https://www.decimalpointanalytics.com/infographics/taxi-aggregator-market" />

<meta name="viewport" content="width=device-width, initial-scale=1.0"/>
</Helmet>



            <Box className='section sec_bg' component='section'>
                <Typography variant='h3' className='subhead skblue al_center'>
                    Infographic
                </Typography>
                <Typography variant='h1' className='blue mainhead al_center'>
                    Taxi Aggregator Market
                </Typography>
                <Container maxWidth='lg' className='bdr'>

                    <Writemessage3 pageVisited="Taxi Aggregator Market"/>

                    <Box className='whitebx'>

                        <Box className='al_center'>
                            <img src={taxipdf} className='topbanner' alt='Taxi PDF' />
                        </Box>

                        {/* <Box mt={5}>
                            <Subscribe />
                        </Box> */}

                    </Box>


                    <Infoback />


                </Container>
            </Box>

        </>
    );
}
