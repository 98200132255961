import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import NewsRoomBack from '../../../Component/NewsRoomBack';
import { Helmet } from 'react-helmet';
import ReactPlayer from 'react-player';

export default function ShapingNewGrowth() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    const [currentVideoUrl, setCurrentVideoUrl] = useState('');

    const handleVideoPlay = (url) => {
        setCurrentVideoUrl(url);
    };

    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title> Shailesh Dhuri on Driving Economic Growth: Yes Bank Summit </title>
                <meta name="description" content="Explore strategies for India's economic advancement with Shailesh Dhuri at the Yes Bank Summit. Join leaders to discuss adaptability and growth." />
                <meta name="keywords" content="Shailesh Dhuri, Yes Bank Growth Summit, economic growth, adaptability, India, CNBC TV18, policymaker, industry stalwart, startup visionary, academic luminary" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/shaping-a-new-growth-mindset-through-innovation-ideas-at-the-growth-summit" />

                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            <Box className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>


                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    News Room
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center'>
                                    Shaping a New Growth Mindset through Innovation & Ideas at The Growth Summit
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>

                    <Box>
                        <Grid container direction="row"
                            justifyContent="center"
                            alignItems="center">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='whitebx'>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Our CEO, <a href='https://www.linkedin.com/in/shaileshdhuri/' target='_blank' rel="noopener noreferrer">Shailesh Dhuri</a>, was invited to share his insights on driving economic growth at the recent Growth Summit event hosted by <a href='https://www.yesbank.in/' target='_blank' rel="noopener noreferrer">Yes Bank</a> and <a href='https://www.cnbctv18.com/' target='_blank' rel="noopener noreferrer">CNBC TV18</a>. His focus on adaptability as a key driver aligns with his commitment to navigating the evolving economic landscape. He shared inspiring insights on how adaptability is key to driving economic growth and gaining a competitive edge in today's rapidly evolving economic landscape. The summit brought together policymakers, industry stalwarts, startup visionaries, and academic luminaries to deliberate on critical aspects pivotal to India's economic advancement.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Click below to watch the session!
                                    </Typography>

                                    <Grid container spacing={0} alignItems="top" justifyContent="center">

                                        <Grid item lg={7} md={7} sm={12} xs={12}>
                                            <Box className="videback">
                                                <ReactPlayer
                                                    controls
                                                    url="https://www.youtube.com/watch?v=hDpQRwrFxVk"
                                                    className="player"
                                                    width="100%"
                                                    height="100%"
                                                    playing={currentVideoUrl === 'https://www.youtube.com/watch?v=hDpQRwrFxVk'}
                                                    onPlay={() => handleVideoPlay('https://www.youtube.com/watch?v=hDpQRwrFxVk')}
                                                    onPause={() => setCurrentVideoUrl('')}
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>

                                </Box>
                            </Grid>
                        </Grid>
                    </Box>


                    <Box mt={2}>
                        <NewsRoomBack />
                    </Box>

                </Container>
            </Box>





        </>
    );
}
