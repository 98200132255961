import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';

import privacy from '../asset/images/new_banner/privacy_banner.webp'
import { Helmet } from 'react-helmet';


export default function Policy() {



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);



    return (
        <>

            <Helmet>
                <title>Privacy Policy | Decimal Point Analytics</title>
                <meta name="description" content="Learn about Decimal Point Analytics' commitment to privacy and data protection. Read our Privacy Statement for details on data protection and usage." />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/policy" />
            </Helmet>

            {/* <Box className='mainsection' component='section'>
                <Container maxWidth='lg'>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item lg={5} md={6} sm={6} xs={12}>
                            <Box>
                                <Typography variant='h1' className='blue'>
                                    Privacy Policy
                                </Typography>


                            </Box>
                        </Grid>
                        <Grid item lg={7} md={6} sm={6} xs={12}>
                            <Box className='al_right'>
                                <img src={indexbanner} className='topbanner' alt='Index Banner' />
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box> */}


            <Box className="banner_bx">
                <Box style={{ backgroundImage: "url(" + privacy + ")" }} className="banner_img">
                    <Box className="img_over bl_overlay">
                        <Grid container spacing={0} alignItems="center" justifyContent='center'>
                            <Grid item lg={4} md={5} sm={6} xs={8}>
                                <Box className="al_center">
                                    <Typography className="white" variant="h1">Privacy Policy</Typography>
                                    {/* <Box mt={'10px'}>
                                        <Typography className="white" variant="h4">&ensp;</Typography>
                                    </Box> */}
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>


            <Box className='section2 sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>

                    {/* <Grid container spacing={1} alignItems="top">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='al_center'>
                                <Typography variant='h2' className='black'>
                                    Privacy Policy Statement
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid> */}


                    <Grid container direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box >

                                <Typography variant='h6' className='black jst'>
                                    Decimal Point Analytics Private Limited (Decimal Point) respects and is committed to protecting your privacy. We have created this Privacy Statement to disclose what happens with the personal information you provide to us on the Decimal Point web site <a href='http://decimalpointanalytics.com/' target="_blank" rel="noopener noreferrer"> www.decimalpointanalytics.com </a>
                                </Typography>

                                <Typography variant='h6' className='black jst'>
                                    You can view our web site without divulging any personal information about yourself. However certain content on our web site requires the submission and the transmittal of personal information such as your name, phone number, email address, mailing address, research requirements, company name, country and designation. Submitting your information on these online forms is purely optional and you may choose to do so at your discretion. In addition, Decimal Point may collect certain information that is not visibly entered, like your site usage pattern, routing and technical information to facilitate your usage of this site.
                                </Typography>

                                <Typography variant='h6' className='black jst'>
                                    From time to time, we may use customer information for new, unanticipated uses not previously disclosed in our privacy notice. However, at no time, however, will Decimal Point, sell, trade, and rent or distribute personal information to any outside organization.
                                </Typography>

                                <Typography variant='h6' className='black jst'>
                                    With respect to cookies, we do not set any cookies.
                                </Typography>

                                <Typography variant='h6' className='black jst'>
                                    Decimal Point uses your information to provide you with the services or information you have requested or we believe that might be useful to you. We limit access to personal information about you to employees who we believe reasonably need to come into contact with that information to provide products or services to you or in order to do their jobs. We have appropriate security measures in place in our physical facilities to protect against the loss, misuse or alteration of information that we have collected from you at our site.
                                </Typography>

                                <Typography variant='h6' className='black jst'>
                                    Decimal Point claims trademark rights to all the trade marks that may be listed by this website, also which may not be explicitly listed. THESE MARKS ARE NOT TO BE USED WITHOUT THE EXPRESS WRITTEN PERMISSION OF Decimal Point. Other trademarks and service marks are the property of their respective owners.
                                </Typography>

                                <Typography variant='h6' className='black jst'>
                                    Decimal Point may update this policy. We will notify you about significant changes in the way we treat personal information by placing a prominent notice on our site.
                                </Typography>

                                <Typography variant='h6' className='black jst'>
                                    Please see our <Link to='/terms' target="_blank" rel="noopener noreferrer" > Terms of Use</Link> to understand our policies on the usage of this web site.
                                </Typography>

                                <Typography variant='h6' className='black jst'>
                                    By using the Decimal Point Site, you agree to the collection of information submitted by you, and its transmittal and storage thereafter as per the terms described in this Privacy Policy.
                                </Typography>

                                <Typography variant='h6' className='black jst'>
                                    For questions about copyrights, terms of use, privacy policy or trademarks, please contact us at <a href="mailto:info@decimalpointanalytics.com" target="_blank" rel="noopener noreferrer">info@decimalpointanalytics.com</a>
                                </Typography>



                            </Box>

                        </Grid>
                    </Grid>
                </Container>
            </Box>





        </>
    );
}
