import { Container, Grid, Typography, Box, Button, Divider, Tabs, Tab, AppBar, TextField } from '@material-ui/core';
import React, { useEffect } from 'react';
import "aos/dist/aos.css"
import Writemessage3 from '../../Component/Writemessage3'
import Getintouch from '../../Component/Get_in_touch';

import ds1 from "../../asset/images/insight/esg/bxic1.svg"
import ds2 from "../../asset/images/insight/esg/bxic2.svg"
import ds3 from "../../asset/images/insight/esg/bxic3.svg"
import ds4 from "../../asset/images/insight/esg/bxic4.svg"
import RoundtableData from "./ESG_roundtable/ESG_roundtable_data";
import RoundtableDesign from "./ESG_roundtable/ESG_roundtable_design";

import Repo_Tool1 from "../../asset/images/insight/esg/carbon_reporting_1.webp";
import Repo_Tool2 from "../../asset/images/insight/esg/carbon_reporting_2.webp";
import Sample_Dash1 from "../../asset/images/insight/esg/dashboard_1.webp";
import Sample_Dash2 from "../../asset/images/insight/esg/dashboard_2.webp";
import { Helmet } from 'react-helmet';

import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";


import ESG_Blogs_data from './ESG_Insights/ESG_Insights_Tabs/Blogs/ESG_Blogs_data';
import Design from './ESG_Insights/ESG_Insights_Tabs/Blogs/ESG_Blogs_design';
import ESG_Newsletters_data from './ESG_Insights/ESG_Insights_Tabs/Newsletters/ESG_Newsletters_data';
import ESGNewslettersDesign from './ESG_Insights/ESG_Insights_Tabs/Newsletters/ESG_Newsletters_design';


import ESGNews from "../../asset/images/esg/esg_products/esg_news.webp"
import Equity from "../../asset/images/esg/esg_products/private_equity.webp"
import GreenInvest from "../../asset/images/esg/esg_products/green_invest.webp"

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { Autocomplete, ListItemButton } from "@mui/material";

const options = [
    { label: 'ESG', to: '/environmental-social-and-governance-for-financial-institutions-and-enterprise-businesses' },
    { label: 'ESG Insights', to: '/esg-insights/esg-whitepaper' },
    { label: 'ESG Roundtable', to: '/environmental-social-and-governance-for-financial-institutions-and-enterprise-businesses#esg-executable-roundtable' },
    { label: 'ESG Products', to: '/empowering-esg-investment-with-real-time-analytics' },
];

export default function ESG() {



    useEffect(() => {
        const { hash } = window.location;

        const scrollToHash = () => {
            if (hash) {
                const element = document.querySelector(hash);
                if (element) {
                    const offset = 150;
                    const elementPosition = element.getBoundingClientRect().top;
                    const offsetPosition = elementPosition - offset;
                    window.scrollBy({
                        top: offsetPosition,
                        behavior: "smooth",
                    });
                }
            }
        };
        const timeoutId = setTimeout(scrollToHash, 1000);
        return () => clearTimeout(timeoutId);
    }, []);


    const scrollToElement = (id) => {
        const element = document.getElementById(id);
        if (element) {
            const stickyItemsHeight = 170;
            const offset = element.getBoundingClientRect().top - stickyItemsHeight;


            setTimeout(() => {
                window.scrollBy({ top: offset, behavior: 'smooth' });
            }, 50);
        }
    };




    const responsiveOptions = {
        0: {
            items: 1,
        },
        600: {
            items: 3,
        },
        1000: {
            items: 3,
        },
    };

    // main tabs
    const [valueESG, setValueESG] = React.useState(0);

    const handleChangeESG = (event, newValue) => {
        window.scrollTo(0, 0);
        setValueESG(newValue);
    };

    const [value1, setValue1] = React.useState(options[0]);

    return (
        <>


            <Helmet>
                <meta charset="UTF-8" />
                <h1>ESG Enabler For The Asset Management Value Chain</h1>
                <title>ESG Data Research Services | ESG Solutions </title>
                <meta name="description" content="Decimal Point Analytics offers ESG data research and ESG reporting services, including audits and climate change solutions for investment managers, asset managers & financial institutions. " />
                <meta name="keywords" content="esg data, esg data management, esg investing, ESG Portfolio Alignment" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/environmental-social-and-governance-for-financial-institutions-and-enterprise-businesses" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>

            <Box id='esg'>
                <Box id="investor_relations_tabs" className="maintabbedsection stiky linkedtabs">
                    <Container maxWidth="lg" className="bdr">
                        <Grid container spacing={1} alignItems="top" justifyContent="center">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <AppBar position="static" className="tabbedbar dgrid_center">
                                    <Tabs value={valueESG} onChange={handleChangeESG} variant="scrollable">
                                        <Tab
                                            label="ESG"
                                            component={Link}
                                            to="/environmental-social-and-governance-for-financial-institutions-and-enterprise-businesses"
                                            value={0}
                                        />
                                        <Tab
                                            label="ESG Insights"
                                            component={Link}
                                            to="/esg-insights/esg-whitepaper"
                                            value={1}
                                        />
                                        <Tab
                                            label="ESG Roundtable"
                                            component={Link}
                                            to="/environmental-social-and-governance-for-financial-institutions-and-enterprise-businesses#esg-executable-roundtable"
                                            onClick={() => scrollToElement('esg-executable-roundtable')}
                                            value={2}
                                        />
                                        <Tab
                                            label="ESG Products"
                                            component={Link}
                                            to="/empowering-esg-investment-with-real-time-analytics"
                                            value={3}
                                        />
                                    </Tabs>
                                </AppBar>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>

                <Box id="investor_relations_tabs_mob" className="maintabbedsection stiky" mb={5}  >
                    <Container maxWidth="lg" className="bdr">
                        <Box className="alltxfieldlg">
                            <Autocomplete
                                disablePortal
                                options={options}
                                value={value1}
                                onChange={(event, newValue1) => setValue1(newValue1)}
                                fullWidth
                                getOptionLabel={(option) => option.label}
                                renderInput={(params) => <TextField {...params} />}
                                renderOption={(props, option) => (
                                    <Link to={option.to}>
                                        <ListItemButton {...props}>
                                            <Typography variant="h6" className="mb0 black fw5">{option.label}</Typography>
                                        </ListItemButton>
                                    </Link>
                                )}
                            />
                        </Box>
                    </Container>
                </Box>

                {/* ------------------------------------------------------------------------------------------------------ */}

                <Writemessage3 pageVisited="ESG Enabler for the Asset Management Value Chain" />

                {/* ------------------------------------------------------------------------------------------------------ */}


                <Box className='section sec_bg section_mob' component='section' style={{ paddingTop: '4%' }}>
                    <Container maxWidth='lg' className='bdr synopsic_bx'>

                        <Grid container spacing={1} alignItems="top">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='al_center'>
                                    <Typography variant='h2' className='black'>
                                        Empowering ESG Solutions <span className='lower'>for</span> Global finance
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>


                        <Grid container direction="row"
                            justifyContent="center"
                            alignItems="center">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box mt={2}>
                                    <Typography variant='h6' className='black jst'>
                                        Decimal Point Analytics is a leading provider of <strong>ESG data research and analytics solutions</strong>. Our comprehensive <strong>ESG solutions</strong> are designed to meet the varying requirements of investment managers, asset managers, sell-side firms, and financial data providers.
                                    </Typography>

                                    <Typography variant='h6' className='black jst'>
                                        We offer ready-made solutions for Portfolio Alignment tracking, TCFD reporting(Task Force on Climate-related Financial Disclosures) , and SFDR reporting (Sustainable Finance Disclosure Regulation) as a managed service. Our ML-driven <strong>ESG news tracking solution</strong> allows you to keep track of ESG news on your portfolio companies. We have also developed <strong>ESG scoring frameworks</strong> and produce company-level reports for some of our clients. Our granular data on Environmental, Social, and Governance <strong>(ESG)</strong> parameters are sourced directly from company filings, providing you with accurate and reliable information.
                                    </Typography>


                                    <Typography variant='h6' className='black jst'>
                                        At Decimal Point Analytics, we understand that some funds may be new to <strong>ESG</strong> and may need guidance to start their <strong>ESG journey</strong>. That's why we offer consulting services to help them get started. We also assist private equity firms in implementing <strong>ESG data collection</strong> processes in their portfolios and produce due diligence reports on the <strong>ESG readiness</strong> of their investee companies.
                                    </Typography>

                                    <Typography variant='h6' className='black jst'>
                                        Our rapidly growing <strong>ESG practice</strong> is driven by our commitment to providing comprehensive <strong>ESG solutions</strong> and consulting services to our clients. Contact us today to learn more about how we can help you achieve your ESG goals.
                                    </Typography>



                                </Box>

                            </Grid>
                        </Grid>
                    </Container>
                </Box>

                {/* ------------------------------------------------------------------------------------------------------ */}




                {/* ------------------------------------------------------------------------------------------------------ */}


                <Box className='section ourstrength ' component='section'>
                    <Box className='over'></Box>
                    <Container maxWidth='lg' className='bdr' style={{ position: 'relative', }}>
                        <Grid container spacing={1} alignItems="top">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='al_center'>
                                    <Typography variant='h2' className='black'>
                                        Our ESG Data Solutions : Driving Sustainable Growth & Impact
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>


                        <Box mt={3} className='mob_card'>
                            <Grid container spacing={1} alignItems="top">
                                <Grid item lg={3} md={3} sm={6} xs={12}>
                                    <Box className='str_bg mnhg5'>
                                        <Box mb={2}>
                                            <img src={ds1} className='bxicon' alt='DS1' />
                                        </Box>
                                        <Box mt={1}>
                                            <Typography className='blue' variant='h4'>ESG Reporting <span className='lower'>and</span> Assurance Services (REP) </Typography>
                                            <Typography variant='h6' className='black al_left'>
                                                <ul>
                                                    <li> Net Zero Alignment Research</li>
                                                    <li> Carbon Exposure Reporting</li>
                                                    <li> SFDR Compliance</li>
                                                    <li> Data Gathering Solution for Unlisted Companies </li>
                                                </ul>
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item lg={3} md={3} sm={6} xs={12}>
                                    <Box className='str_bg mnhg5'>
                                        <Box mb={2}>
                                            <img src={ds2} className='bxicon' alt='DS2' />
                                        </Box>
                                        <Box mt={1}>
                                            <Typography className='blue' variant='h4'>ESG Research And ESG Data Provision (RDP)</Typography>
                                            <Typography variant='h6' className='black al_left'>
                                                <ul>
                                                    <li> Automated ESG News Analytics Solution</li>
                                                    <li> Raw ESG Data collection  </li>
                                                    <li> Company ESG DATA Analysis Reports </li>
                                                    <li> Research Paper </li>
                                                </ul>
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item lg={3} md={3} sm={6} xs={12}>
                                    <Box className='str_bg mnhg5'>
                                        <Box mb={2}>
                                            <img src={ds3} className='bxicon' alt='DS3' />
                                        </Box>
                                        <Box mt={1}>
                                            <Typography className='blue' variant='h4'> Investment Consultancy (IC) </Typography>
                                            <Typography variant='h6' className='black al_left'>
                                                <ul>
                                                    <li>  ESG Policy and Process Consulting</li>
                                                    <li>  ESG Training </li>
                                                </ul>
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item lg={3} md={3} sm={6} xs={12}>
                                    <Box className='str_bg mnhg5'>
                                        <Box mb={2}>
                                            <img src={ds4} className='bxicon' alt='DS4' />
                                        </Box>
                                        <Box mt={1}>
                                            <Typography className='blue' variant='h4'> ESG Active Ownership services </Typography>
                                            <Typography variant='h6' className='black al_left'>
                                                <ul>
                                                    <li>  Engagement Research and Supportning</li>
                                                </ul>
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>

                    </Container>

                </Box>



                {/* ------------------------------------------------------------------------------------------------------ */}

                <Box className="sub_section sec_bg section_mob" component="section"  >
                    <Container maxWidth="lg" className="bdr">

                        <Grid container spacing={1} alignItems="top" justifyContent='center'>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className="al_center synopsic_bx">
                                    <Typography variant="h2" className="black" gutterBottom>
                                        Simplified Carbon Reporting <span className='lower'>with</span> Decimal Point Analytics
                                    </Typography>
                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Simplify carbon emissions reporting for your small to medium-sized business with Decimal Point Analytics carbon reporting tool. Our online carbon reporting tool guides businesses through a step-by-step process, making CO2 reporting easy and efficient. Achieve your sustainability goals with confidence and precision.
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>

                        <Box className="reportinfo">
                            <Grid container spacing={4} alignItems="top" justifyContent="center">
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <Box className="">
                                        <img src={Repo_Tool1} alt='sample Dashboard' className='sample_dash_img' />
                                    </Box>
                                </Grid>

                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <Box className="">
                                        <img src={Repo_Tool2} alt='sample Dashboard' className='sample_dash_img' />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>

                    </Container>
                </Box>


                {/* ------------------------------------------------------------------------------------------------------ */}


                <Box id='sample_dashboard' className="sub_section section_mob" component="section"   >
                    <Container maxWidth="lg" className="bdr">

                        <Grid container spacing={1} alignItems="top" justifyContent='center'>
                            <Grid item lg={10} md={10} sm={12} xs={12}>
                                <Box className="al_center">
                                    <Box>
                                        <Typography variant="h2" className="black" gutterBottom>
                                            Sample Dashboard
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>

                        <Box className="reportinfo">
                            <Grid container spacing={4} alignItems="top" justifyContent="center">
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <Box className="">
                                        <img src={Sample_Dash1} alt='sample Dashboard' className='sample_dash_img' />
                                    </Box>
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <Box className="">
                                        <img src={Sample_Dash2} alt='sample Dashboard' className='sample_dash_img' />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Container>
                </Box>


                {/* ------------------------------------------------------------------------------------------------------ */}


                <Box id='esg-products' className="section sec_bg section_mob" component="section" style={{ paddingTop: "3%" }} >
                    <Container maxWidth="lg" className="bdr">
                        <Grid container spacing={1} alignItems="top" justifyContent='center'>
                            <Grid item lg={10} md={10} sm={12} xs={12}>
                                <Box className="al_center">
                                    <Box>
                                        <Typography variant="h2" className="black" gutterBottom>
                                            ESG Products
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>

                        <Box className="reportinfo">
                            <Grid container spacing={2} alignItems="top" justifyContent="center">

                                <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <Box className="innovation_bx">
                                        <Box className="innovation_inner_bx">
                                            <Box style={{ backgroundImage: "url(" + ESGNews + ")" }} className="innovation_bg">
                                            </Box>
                                            <Box>
                                                <Box className="innovation_content_bx">
                                                    <Typography variant="h5" className="blue">ESG News Senti-Meter</Typography>
                                                    <Typography variant="h6" className="black thr">
                                                        Leverage our AI-driven tool for rapid news impact analysis on ESG ratings. Gain actionable insights.
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box mb={1} mt={1}>
                                            <Divider />
                                        </Box>
                                        <Box className="synopsic_bx">
                                            <Link to='/empowering-esg-investment-with-real-time-analytics'>
                                                <Button variant="contained" className='dpabtn_sm1' fullWidth>
                                                    Learn More
                                                </Button>
                                            </Link>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <Box className="innovation_bx">
                                        <Box className="innovation_inner_bx">
                                            <Box style={{ backgroundImage: "url(" + Equity + ")" }} className="innovation_bg">
                                            </Box>
                                            <Box>
                                                <Box className="innovation_content_bx">
                                                    <Typography variant="h5" className="blue">ESG for Private Equity</Typography>
                                                    <Typography variant="h6" className="black thr">
                                                        Streamline ESG integration with comprehensive data collection, dynamic dashboards, and robust compliance monitoring.
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box mb={1} mt={1}>
                                            <Divider />
                                        </Box>
                                        <Box className="synopsic_bx">
                                            <Link to='/elevating-private-equity-performance-with-strategic-esg-solutions'>
                                                <Button variant="contained" className='dpabtn_sm1' fullWidth>
                                                    Learn More
                                                </Button>
                                            </Link>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item lg={4} md={4} sm={6} xs={12}>
                                    <Box className="innovation_bx">
                                        <Box className="innovation_inner_bx">
                                            <Box style={{ backgroundImage: "url(" + GreenInvest + ")" }} className="innovation_bg">
                                            </Box>
                                            <Box>
                                                <Box className="innovation_content_bx">
                                                    <Typography variant="h5" className="blue">GreenInvest Pulse</Typography>
                                                    <Typography variant="h6" className="black thr">
                                                        Advanced sustainability tracking and compliance tools for asset managers to optimize portfolio performance.
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box mb={1} mt={1}>
                                            <Divider />
                                        </Box>
                                        <Box className="synopsic_bx">
                                            <Link to='/align-your-investments-with-global-sustainability-goals'>
                                                <Button variant="contained" className='dpabtn_sm1' fullWidth>
                                                    Learn More
                                                </Button>
                                            </Link>
                                        </Box>
                                    </Box>
                                </Grid>

                            </Grid>
                        </Box>
                    </Container>
                </Box>


                {/* ------------------------------------------------------------------------------------------------------ */}


                <Box id='esg-executable-roundtable' className="section section_mob" component="section" style={{ paddingTop: "3%" }} >
                    <Container maxWidth="lg" className="bdr">
                        <Grid container spacing={1} alignItems="top" justifyContent='center'>
                            <Grid item lg={10} md={10} sm={12} xs={12}>
                                <Box className="al_center" mt={1}>
                                    <Box >
                                        <Typography variant="h2" className="black" gutterBottom>
                                            Executive Round Table
                                        </Typography>
                                    </Box>
                                    <Box pt={1} pb={4}>
                                        <Typography variant='h6' className='black'>
                                            Our Executive Round Tables are focused on ESG and feature interactive sessions for experts to discuss topics related to sus-tainability, corporate responsibility, and ethical investment practices. These sessions aim to promote collaboration and knowledge sharing among professionals in the ESG field.
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>

                        <Box className="reportinfo">
                            <Grid container spacing={2} alignItems="top" justifyContent="center">
                                {RoundtableData.map((val, index) => (
                                    <Grid item lg={4} md={4} sm={6} xs={12} key={index}>
                                        <RoundtableDesign
                                            link={val.link}
                                            imgsrc={val.imgsrc}
                                            heading={val.heading}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    </Container>
                </Box>


                {/* ------------------------------------------------------------------------------------------------------ */}


                <Box id='esg-newsletter' className="section sec_bg section_mob" component="section" style={{ paddingTop: "3%" }} >
                    <Container maxWidth="lg" className="bdr">
                        <Grid container spacing={1} alignItems="top" justifyContent='center'>
                            <Grid item lg={10} md={10} sm={12} xs={12}>
                                <Box className="al_center">
                                    {/* <Box>
                                    <Typography variant="h2" className="black" gutterBottom>
                                        ESG Newsletter
                                    </Typography>
                                </Box> */}
                                    <Typography className="black" variant="h2"> ESG Newsletter
                                        <Typography variant="h2" className="black h2v2"> Building Analytical Solutions for a Sustainable Future </Typography>
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>

                        <Box className="reportinfo">
                            <Grid container spacing={2} alignItems="top" justifyContent="center">
                                {ESG_Newsletters_data.map((item, index) => (
                                    <Grid item lg={4} md={4} sm={6} xs={12} key={index}>
                                        <ESGNewslettersDesign
                                            type={item.type}
                                            link={item.link}
                                            imgsrc={item.imgsrc}
                                            heading={item.heading}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    </Container>
                </Box>


                {/* ---------------------------------------------------------------------------------- */}


                <Box className="section owldot2 section_mob" component="section" style={{ paddingTop: '3%' }}>
                    <Container maxWidth="lg" className="bdr">
                        <Grid container spacing={1} alignItems="top">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className="al_center">
                                    <Typography variant="h2" className="black">
                                        ESG Blogs
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid container spacing={0} alignItems="top">
                            <OwlCarousel className='owl-theme reportinfo' loop margin={10} nav dots={false} autoplay responsive={responsiveOptions}>
                                {/* All Articles Will Appear Here */}
                                {ESG_Blogs_data.map((val, index) => (
                                    <Box className='' key={index}>
                                        <Design
                                            link={val.link}
                                            imgsrc={val.imgsrc}
                                            heading={val.heading}
                                        />
                                    </Box>
                                ))}
                                {/* All Articles Will Appear Here */}
                            </OwlCarousel>
                        </Grid>
                    </Container>
                </Box>




                <Box className='sec_bg'>
                    <Getintouch />
                </Box>

            </Box>

        </>
    );
}
