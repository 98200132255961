import { IconButton, Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Eventback from '../../../Component/Eventback'
import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdfOutlined';
import { Helmet } from "react-helmet";



export default function ExecutiveRoundtableTheEvolutionOfESG() {


    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>The Evolution of ESG Reporting Implications for Fund Managers</title>
                <meta name="description" content="Learn about the importance of ESG reporting in evaluating companies' sustainability performance. Gain insights from leaders in the financial industry." />
                <meta name="keywords" content="ESG Reporting, Sustainability Performance, Environmental, Social, and Governance Considerations, Transparency and Accountability, Unified Approach for ESG Standards, Climate Change Considerations in ESG, Technology in ESG Practices, Sustainable Investing, Executive Roundtable on ESG Reporting, Financial Industry ESG Insights" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/executive-roundtable-the-evolution-of-esg-reporting-implications-for-fund-managers" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>



            <Box id='event-details' className='section sec_bg' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Events
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center'>
                                    Executive Roundtable- The Evolution of ESG Reporting Implications for Fund Managers
                                </Typography>

                            </Box>
                        </Grid>
                    </Grid>





                    <Box className='whitebx'>


                        <Typography variant='h6' className='black jst'>
                            ESG reporting has become increasingly critical in assessing companies' sustainability performance and their alignment with environmental, social, and governance considerations. As the demand for transparency and accountability continues to grow, it is essential for fund managers to understand the changing landscape and adapt their practices accordingly.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            During our recent executive roundtable, leaders from prominent financial institutions discussed the evolving ESG reporting landscape. Here are the key highlights:

                            <ol type='1'>
                                <li>
                                    <Typography variant='h6' className='black jst'>
                                        <strong> Rise in ESG Disclosures: </strong> ESG disclosures are witnessing a significant increase, enhancing transparency and accountability in the financial industry. </Typography> </li>

                                <li>
                                    <Typography variant='h6' className='black jst'>
                                        <strong> Unified Approach Needed: </strong>  The multitude of ESG standards in the market is causing confusion. A unified approach is necessary to provide clarity and comparability in ESG reporting. </Typography> </li>

                                <li>
                                    <Typography variant='h6' className='black jst'>
                                        <strong> Climate Change Focus: </strong>  Future ESG standards are expected to prioritize climate change considerations, reflecting the urgent global need to address climate-related challenges. </Typography> </li>

                                <li>
                                    <Typography variant='h6' className='black jst'>
                                        <strong> Technology as an Enabler: </strong>  Technology plays a crucial role in facilitating ESG practices, such as benchmarking and efficient analysis of ESG data. </Typography> </li>
                            </ol>
                        </Typography>



                        <Typography variant='h6' className='black jst'>
                            The insights shared during the roundtable provide a comprehensive view of the current state and future direction of ESG reporting. By staying informed and adapting to these changes, fund managers can effectively navigate the complexities of sustainable investing.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            Join our future executive roundtable discussions to engage with like-minded professionals and shape the future of ESG reporting in the financial industry.
                        </Typography>



                        <Typography variant='h6' className='black jst'>
                            Reach out to Sarath Nair at  <a href="mailto:sarath.nair@decimalpointanalytics.com" target="_blank" rel="noopener noreferrer"> sarath.nair@decimalpointanalytics.com </a>  to express your interest!
                        </Typography>



                        <Box className='al_center'>
                            <IconButton className='blue'>
                                <a href='https://objectstorage.ap-mumbai-1.oraclecloud.com/n/bmexgnkisxsv/b/creative_dpa/o/Media%2Fupload_doc%2Fevent%2Fround-table-the-evolution-of-the-esg-reporting-landscape-implications.pdf' target='_blank' rel="noopener noreferrer">
                                    <PictureAsPdfOutlinedIcon />
                                </a>
                            </IconButton>
                        </Box>

                        <Box className='al_center'>
                            <Typography className='blink_text'>Click Here to View the Presentation...</Typography>
                        </Box>


                    </Box>




                    <Eventback />



                </Container>
            </Box>



        </>
    );
}
