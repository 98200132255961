import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Helmet } from 'react-helmet';

import Casestudyback from '../../../../Component/Casestudyback'

import Banner from "../../../../asset/images/insight/case_studies/banner_crafting_dynamic.webp"
import Infographics from "../../../../asset/images/insight/case_studies/crafting a_dynamic_industr.webp"
import { Link } from 'react-router-dom';



export default function CraftingDynamicIndustry() {



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet><meta charset="UTF-8" />
                <meta charset="UTF-8" />
                <title>Industry Classification System for Financial Data Services - Insights</title>
                <meta name="description" content="Discover how our proprietary industry classification system optimizes financial data services, improves investment analysis tools, and ensures data standardization." />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/case-studies/crafting-a-dynamic-industry-classification-system-enabling-real-time-insights-for-investment-strategies" />
            </Helmet>

            <Box id="case-studies" className='section sec_bg new_cs' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={3} alignItems="top" justifyContent='center'>
                        <Grid item lg={9} md={9} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Success Stories
                                </Typography>
                                <Typography className='mainhead blue al_center'>
                                    Crafting <span className='lower'>a</span> Dynamic Industry Classification System, Enabling Real-Time Insights <span className='lower'>for</span> Investment Strategies
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Box >
                        <img src={Banner} className='w100' alt='Banner' />
                    </Box>


                    <Box className='whitebx'>
                        <Box>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Executive Summary
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                A prominent US-based financial data provider required a robust industry classification system to enhance investment analysis. Challenges included reliance on third-party data, discrepancies in financial data standardization, and delays in classification revisions. A proprietary methodology was developed to deliver timely and accurate classifications, improving investment insights.
                            </Typography>
                        </Box>


                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                The Challenge
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                Challenges faced included:
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Reliance on Third-party Data: </strong> This led to inflated operating costs and concerns over data reliability.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Standardization Discrepancies: </strong> Delays in company classification revisions hindered performance and analysis.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Limited Customization Flexibility: </strong> Processing times were extended due to rigidity in custom data solutions for finance.
                                        </Typography>
                                    </li>

                                </ul>
                            </Typography>
                        </Box>

                        <Box mt={3} className='mob_card'>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Solution: Redefining Industry Classification
                            </Typography>

                            <Grid container direction="row"
                                justifyContent="center"
                                alignItems="center">

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className="al_center" my={3}>
                                        <img src={Infographics} className="topbanner" alt="Solution: Redefining Industry Classification" />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box mt={1}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Results
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            Achieved rapid classification revisions within 24 hours of market action.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            Improved peer comparisons among companies for optimized investment analysis tools.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            Enhanced accuracy and reliability of investment analysis through tailored classifications.
                                        </Typography>
                                    </li>

                                </ul>
                            </Typography>
                        </Box>

                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Key Takeaways
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                        Proprietary methodologies enhance classification accuracy and flexibility. 
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                        Timely revisions align with market dynamics for improved insights.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                        Annual updates ensure the industry classification system remains relevant and effective.
                                        </Typography>
                                    </li>
                                </ul>
                                <Typography variant='h6' className='black jst'>
                                    <strong>Ready to redefine your industry classification system?</strong>
                                </Typography>

                            </Typography>

                            <Typography variant='h6' className='black jst' gutterBottom>
                                <Link to="/contact-us#contact_form" className="link">Let’s collaborate</Link> to create tailored solutions that enhance your investment analysis and adaptability to market dynamics!
                            </Typography>

                        </Box>

                    </Box>


                    <Casestudyback />

                </Container>
            </Box>


        </>
    );
}