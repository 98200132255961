import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Box, Grid, Container, } from '@material-ui/core';
import { Link } from 'react-router-dom';

import FAQ from "../../../asset/images/product/faq.webp";


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));


export default function SBALoanUnderwritingFAQ() {



  const classes = useStyles();
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <Container maxWidth='lg' className='bdr'>

        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
          <Grid item lg={4} md={4} sm={2} xs={12}>
            <Box className='faq-image' mt={4}>
              <img src={FAQ} alt='Frequently Asked Questions' className='topbanner2' />
            </Box>
          </Grid>

          <Grid item lg={8} md={8} sm={10} xs={12}>
            <Box className='al_left'>
              <Typography variant='h2' className='black'>
                <strong>Frequently Asked Questions (FAQ)</strong>
              </Typography>
            </Box>

            <Box id="faq-accordian" className={classes.root} mt={1}>
              <Box>

                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography variant="h4" className='accordian_head'> What documents are commonly required for SBA loans? </Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Typography variant="h6" className="black accordian_content">
                      The <Link to="/small-business-administration/step-by-step-guide-on-how-to-apply-for-an-sba-loan" className='link'>documentation required for SBA loans</Link> can vary depending on the type of loan and lender, but common documents include balance sheets, profit and loss statements, business plans, tax returns, and business debt schedules. For specific types of loans such as short-term loans, merchant cash advances, and equipment financing, additional documents like cash flow projections, business licenses, and credit reports may be required.
                    </Typography>
                  </AccordionDetails>
                </Accordion>


                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                  >
                    <Typography variant="h4" className='accordian_head'> How long does the SBA loan underwriting process take?</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="h6" className="black accordian_content">
                      The timeline for <Link to="/small-business-administration/sba-lender-service" className='link'>SBA loan underwriting</Link> can vary based on the complexity of the loan and the completeness of the documentation provided. Our team strives to streamline the process, ensuring an efficient and thorough review to minimize delays.
                    </Typography>
                  </AccordionDetails>
                </Accordion>


                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                  >
                    <Typography variant="h4" className='accordian_head'>How does Decimal Point Analytics ensure SBA compliance?</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="h6" className="black accordian_content">
                      Our underwriting team stays updated on all SBA procedural changes and ensures that all loans are reviewed in accordance with the latest standards. We provide ongoing support and guidance to help you navigate the regulatory environment effectively.
                    </Typography>
                  </AccordionDetails>
                </Accordion>


                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4bh-content"
                    id="panel4bh-header"
                  >
                    <Typography variant="h4" className='accordian_head'>Can Decimal Point Analytics customize services for my institution?</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="h6" className="black accordian_content">
                      Yes, we offer tailored solutions to meet the unique needs of your business. Our services are designed to align with your specific goals and requirements in SBA lending.
                    </Typography>
                  </AccordionDetails>
                </Accordion>

              </Box>
            </Box>
          </Grid>

        </Grid>

      </Container>
    </>
  );
}
