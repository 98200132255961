import { Divider, Container, Typography, Box, Grid, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import Insightback from '../../../Component/Insightback'

import Writemessage3 from '../../../Component/Writemessage3';
import { Helmet } from 'react-helmet';

export default function Assessing_quality_of_research() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <h1>Learn how to evaluate investment research reports effectively and make informed decisions. Read Decimal Point Analytics' article on "Assessing the Quality of Research" for insights on methodology, data sources, and analysis. Visit now." </h1>
                <title>MIFID II Assessing the Quality of Investment Research</title>
                <meta name="description" content="Assessing the Quality of Investment Research - Decimal Point Analytics" />
                <meta name="keywords" content="data quality assessment
mifid ii
mifid 2
mifid regulation
mifid ii research unbundling rules" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/articles/assessing-quality-of-research" />

                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>



            <Box id="article-page" className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center' >
                        <Grid item lg={10} md={12} sm={12} xs={12}>
                            <Typography variant='h3' className='subhead skblue al_center'>
                                Insight
                            </Typography>
                            <Typography variant='h1' className='blue mainhead al_center'>
                                MIFID II Assessing Quality of Research!
                            </Typography>
                        </Grid>
                    </Grid>


                    <Box className='whitebx'>



                        <Typography variant='h6' className='black jst'> With the forced unbundling of research costs, regulators have knowingly or unknowingly unleashed an environment of introspection. There will be an intense examination of whether the research budget is delivering value. One of the biggest positive impacts of MiFID II is an expected improvement in the quality of research. </Typography>


                        <Divider />


                        <Typography variant='h4' className='blue'>Evolution of Research Product Structure </Typography>

                        <Typography variant='h6' className='black jst'>Pricing models will result in structured research menus for investment managers. Portfolio managers will have to closely review the kind of research input, considering that they must pay for it now. So far, this decision was only about procuring data. Research is ultimately a combination of data, information, and insight. Data has already been commoditized, and information is also commonly available. The evolving research pricing structure is pointing in that direction. </Typography>

                        <Typography variant='h6' className='black jst'> Lowest in the hierarchy of such research is the general commentaries, market and news summaries, in most cases, this will be provided for free. Research providers produce research in the form of basic written reports covering equities, credit and interest rate views. Slightly above this in the value chain would be the financial model, and the highest being actual facetime/talk time with the analyst.   </Typography>

                        <Typography variant='h6' className='black jst'> The research pricing will settle down to a reasonable pricing for the basic research. However, premium services will be highly differentiated and will depend on the true perceived value of the analyst’s insight.  </Typography>


                        <Divider />


                        <Typography variant='h4' className='blue'> But how do you Determine the Right Price for Research? </Typography>

                        <Typography variant='h6' className='black jst'>The two key elements to consider is the value of research input and its price. And as financial market participants, we all know only too well, that most of the time there is a healthy disconnect between the two. There is a considerable effort being made to determine the value of research in a methodical manner because the value of research will determine the price for it. </Typography>


                        <Divider />


                        <Typography variant='h4' className='blue'> Quality and Value of a Research Input  </Typography>

                        <Typography variant='h6' className='black jst'>Interestingly, there is a very simple and methodical way through which investment managers can get a sense of the value of research. An important indicator of value and quality of a research piece is how many people actually read it and who those people are. In any investment organisation, research is consumed across various levels right from CIO, portfolio manager, senior analyst to the junior analyst. A knowledge management system that enables this in the least possible time will give clear and consistent results.  </Typography>

                        <Typography variant='h6' className='black jst'>While that may be the case for the basic written research report, what about analyst insights gained through a conversation on the phone? Emerging processes seem to indicate a methodology that involves rating by the user after an exchange. There is a methodology by which this could be deepened again by using a methodical knowledge management system.  </Typography>

                        <Typography variant='h6' className='black jst'> Again, this can have a methodical approach that will provide clear input on how these are evaluated, specifically having the portfolio manager/analyst file a call report, along with a rating. This call report can also be shared in the knowledge management platform so that it becomes available to everyone in the organisation. So, along with the rating, there are also data points showing how many people who read the call report. </Typography>


                        <Divider />


                        {/* <Box>
                            <Subscribe />

                        </Box> */}
                    </Box>


                    <Box className='pages'>
                        <Writemessage3 pageVisited="MIFID II Assessing Quality of Research" />
                    </Box>



                    <Insightback />


                </Container>
            </Box>

        </>
    );
}
