import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Eventback from '../../../Component/Eventback'
import { Helmet } from "react-helmet";


export default function HarvestingOpportunities() {


    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>Decimal Point Analytics at "Harvesting Opportunities" Event: Exploring GIFT City's Financial Potential</title>
                <meta name="description" content="Decimal Point Analytics attended the &quot;Harvesting Opportunities&quot; event, discussing GIFT City's growth as a global financial hub" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/harvesting-opportunities-funds-in-gift-city-scaling-new-heights" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>



            <Box id='event-details' className='section sec_bg' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={8} md={8} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Events
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center'>
                                    Harvesting Opportunities: Funds in GIFT City Scaling New Heights
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Box className='whitebx'>


                        <Typography variant='h6' className='black jst'>
                            Decimal Point Analytics recently attended the <strong>"Harvesting Opportunities: Funds in GIFT City Scaling New Heights"</strong> event on Oct 18, where key industry leaders came together to discuss the growing potential of GIFT City as a global financial hub.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            <strong>Paresh Sharma</strong>, Managing Partner, and <strong>Sameer Kulkarni</strong>, Senior Vice President of Information Technology, participated in engaging discussions surrounding the opportunities and challenges within GIFT City. The event highlighted the city's expanding role in the financial ecosystem and its increasing appeal to global investors.
                        </Typography>

                        <Typography variant='h6' className='black jst' gutterBottom>
                            The event provided an excellent opportunity for networking and gaining valuable insights into GIFT City's future prospects. We look forward to leveraging these insights as we continue to explore new opportunities in this dynamic financial environment.
                        </Typography>


                    </Box>
                    <Eventback />


                </Container>
            </Box>

        </>
    );
}
