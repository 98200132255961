import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import ET from '../../../asset/images/media/eco_time.webp';
import NewsRoomBack from '../../../Component/NewsRoomBack';
import { Helmet } from 'react-helmet';


export default function ESGDisclosureList() {



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);



    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>India's Leading Role in Global ESG Disclosures </title>
                <meta name="description" content="Discover how India leads in global ESG reporting with SEBI's initiatives. Explore transparency and sustainability efforts in India's ESG landscape." />

                <meta name="keywords" content="India, ESG, SEBI, BRSR, sustainability, global ESG reporting, environmental, social, governance, business responsibility report, business responsibility and sustainability report, regulatory framework, sustainable practices, digital tools, AI, MSMEs, greenwashing, corporate governance, disclosure requirements, compliance, data reliability, actionable insights, company boards, independent directors" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/india-has-an-enviable-place-in-global-esg-disclosure-list" />

                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            <Box className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>


                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    News Room
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center'>
                                    India has an enviable place in global ESG disclosure list
                                </Typography>

                            </Box>
                        </Grid>
                    </Grid>

                    <Box>
                        <Grid container direction="row"
                            justifyContent="center"
                            alignItems="center">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='whitebx'>

                                    <Typography variant='h6' className='black jst' gutterBottom> Environmental, social and governance (ESG) issues are so important today that a company’s management has to publicly declare its performance on these fronts. As the world is fast transitioning towards sustainability, many organisations are becoming transparent on managing ESG risks. Notably, investors take into account ESG factors when evaluating a company’s sustainability standing. </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Keeping in line with the global trend, India fares highly in ESG disclosures on a comparison scale with leading global economies. “Among the top listed companies in India, disclosure was over 50% in FY 2022-23. This is comparable to disclosures seen in the EU and US. Indian disclosures are improving rapidly, given the strong direction set by the market regulator SEBI. India could well become a trendsetter in the global ESG landscape,” says Shailesh Dhuri, CEO, Decimal Point Analytics. </Typography>


                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Throwing light on India’s ESG reporting regulatory regime, Dhuri says SEBI maintains an “aggressive stance” on ESG reporting. While the market regulator had introduced the Business Responsibility Report (BRR) framework in 2012 as a voluntary reporting framework, it introduced a more detailed framework, the Business Responsibility and Sustainability Report (BRSR), in 2021.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        BRSR was voluntary for the top 1,000 listed companies in FY 2021-22 and is mandatory for the top 1,000 listed companies in 2023. </Typography>


                                    <Typography variant='h6' className='black jst' gutterBottom> “The BRSR format is by any yardstick the most comprehensive reporting guideline globally. SEBI’s construction of BRSR is painstakingly detailed, not comparable to any other regulatory regime anywhere in the world,” says Dhuri. </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom> He adds that the US doesn’t even have a compulsory disclosure regime and in March 2022, the US SEC proposed certain rule changes that would require companies to include certain ESG disclosures on a mandatory basis from 2023.  </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom> This was expected to be made applicable from October 2022. “This deadline has long passed, there is no clarity of when the proposed rules would become final,” Dhuri states. </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>  “The EU has mandatory disclosure requirements, and its rollout plan is quite aggressive. We find SEBI leads in detail, EU leads in its coverage ambition.”  </Typography>

                                    <Typography variant='h4' className='blue jst' gutterBottom> Outlook for the ESG reporting </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom> Experts say while ESG is a global movement, it is important that we do not follow it blindly but build it in Indian ethos and localise it to suit the country’s requirements. </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>  “Increasing coverage of ESG reporting requirements is another trend we can expect. The reporting regime will need to extend beyond the top 1,000 companies, eventually leading to unlisted MSMEs. As coverage expands, compliance and affordability will need to be considered,” says Dhuri.  </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom> “The future will likely witness an increased use of digital tools and AI to not only manage and report ESG data but also to drive actionable insights for companies. These technologies promise to streamline the ESG reporting process, enhance accuracy, and help businesses stay ahead of regulatory requirements. However, the present system can improve in terms of data reliability, collection methodologies, and ensuring that disclosures are not just tick-box exercises but reflect genuine sustainable practices,” says Dhuri.
                                    </Typography>


                                    <Typography variant='h4' className='blue jst' gutterBottom> Company board and ESG directives </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom> Experts are of the view that for Indian company boards to adhere to ESG directives, a multi-faceted approach is needed. </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom> Companies will need to maintain a record of data used for disclosures, that can be audited and certified by third parties, say experts. “Globally, there have been cases where companies have been pulled up for greenwashing. Thankfully, no case of greenwashing has emerged in India as yet. However, boards of companies, particularly the independent directors, will need to be vigilant to ensure against greenwashing,” adds Dhuri. </Typography>

                                </Box>

                            </Grid>
                        </Grid>
                    </Box>

                    <Box>
                        <Box className='graybbx' mt={2}>
                            <Grid container spacing={0} alignItems="center" justifyContent='center'>

                                <Grid item lg={4} md={4} sm={4} xs={6}>
                                    <a href="https://economictimes.indiatimes.com/small-biz/sustainability/india-has-an-enviable-place-in-global-esg-disclosure-list/articleshow/105405591.cms" target="_blank" rel="noopener noreferrer">
                                        <Box
                                            className="awardsindexcircle"
                                            style={{ backgroundImage: "url(" + ET + ")" }}
                                        ></Box>
                                    </a>
                                </Grid>

                            </Grid>
                        </Box>
                    </Box>


                    <Box mt={2}>
                        <NewsRoomBack />
                    </Box>

                </Container>
            </Box>





        </>
    );
}
