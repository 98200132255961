import { Container, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';

import Infoback from '../../../Component/Infoback'
import Writemessage3 from '../../../Component/Writemessage3';
import Aos from 'aos'
import ATMpdf from '../../../asset/images/insight/pdf/white-label-min.jpg'
import { Helmet } from "react-helmet";


export default function White_label_ATM() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>


<Helmet>
<meta charset="UTF-8"/>
<h1>White Label ATMs Infographics - Key Insights and Trends" </h1>
<title>White Label ATMs Infographics | Decimal Point Analytics</title>
<meta name="description" content="Explore White Label ATMs with our visually appealing infographics. Get an overview of the industry, key players, market trends, and consumer behavior." />
<meta name="keywords" content="atm card
white label atm
atm card services
us bank atm near me
atm machine" />White Label ATMs Infographics
<meta name="author" content="Decimal Point Analytics" />
<link rel="canonical" href="https://www.decimalpointanalytics.com/infographics/white-label-atm" />

<meta name="viewport" content="width=device-width, initial-scale=1.0"/>
</Helmet>



            <Box className='section sec_bg' component='section'>
                <Typography variant='h3' className='subhead skblue al_center'>
                    Infographic
                </Typography>
                <Typography variant='h1' className='blue mainhead al_center'>
                    White Label ATMs
                </Typography>
                <Container maxWidth='lg' className='bdr'>

                    <Writemessage3 pageVisited="White Label ATMs"/>

                    <Box className='whitebx'>

                        <Box className='al_center'>
                            <img src={ATMpdf} className='topbanner' alt='ATM PDF' />
                        </Box>


                    </Box>


                    <Infoback />

                </Container>
            </Box>

        </>
    );
}
