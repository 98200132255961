import { Box, Container, Grid,  Typography } from '@material-ui/core'
import React from 'react';
import icon1 from '../../asset/images/crm/why_choose/certified_expertise.webp';
import icon2 from '../../asset/images/crm/why_choose/comprehensive_services.png';
import icon3 from '../../asset/images/crm/why_choose/adaptability.svg';
import icon4 from '../../asset/images/crm/why_choose/Seamless.svg';

import get_proposal_formbg from "../../asset/images/crm/get_proposal_formbg.webp";



export const WhyChoose = () => {
    return (
        <>
            <Box id="get_proposal_formbg" className='why_choose section2' style={{ backgroundImage: "url(" + get_proposal_formbg + ")" }}>
                <Container>
                    <Grid container spacing={2} alignItems="top" justifyContent='center'>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box>
                                <Typography variant="h2" className="black al_center fw5">
                                    <span className='blue'> Why Choose DPA </span>  for Salesforce CRM?
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <Box className="sbalenders_benefitbx">
                                <Box className='flx_center' mb={2}>
                                    <img src={icon1} alt='Immediate Team Setup' className='sba_icon' />
                                </Box>
                                <Box my={2}>
                                    <Typography variant="h4" className="blue al_center" gutterBottom>Certified Expertise</Typography>
                                </Box>
                                <Box>
                                    <Typography variant="h6" className="black al_center">Our team of Salesforce Certified professionals excels in delivering top-tier CRM solutions with proven success.</Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <Box className="sbalenders_benefitbx">
                                <Box className='flx_center' mb={2}>
                                    <img src={icon2} alt='Immediate Team Setup' className='sba_icon' />
                                </Box>
                                <Box my={2}>
                                    <Typography variant="h4" className="blue al_center" gutterBottom>Comprehensive Services</Typography>
                                </Box>
                                <Box>
                                    <Typography variant="h6" className="black al_center">We provide complete solutions from initial setup to process automation, tailored to your specific business needs.</Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <Box className="sbalenders_benefitbx">
                                <Box className='flx_center' mb={2}>
                                    <img src={icon3} alt='Immediate Team Setup' className='sba_icon' />
                                </Box>
                                <Box my={2}>
                                    <Typography variant="h4" className="blue al_center" gutterBottom>Cutting-Edge Adaptability</Typography>
                                </Box>
                                <Box>
                                    <Typography variant="h6" className="black al_center">We continuously innovate and adapt to the latest Salesforce developments, ensuring your CRM is always at peak performance.</Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <Box className="sbalenders_benefitbx">
                                <Box className='flx_center' mb={2}>
                                    <img src={icon4} alt='Immediate Team Setup' className='sba_icon' />
                                </Box>
                                <Box my={2}>
                                    <Typography variant="h4" className="blue al_center" gutterBottom>Seamless Integration XTRA</Typography>
                                </Box>
                                <Box>
                                    <Typography variant="h6" className="black al_center">Specializing in integrating Salesforce with key applications like PowerBI and Tableau, we enhance your data visualization and decision-making capabilities.</Typography>
                                </Box>
                            </Box>
                        </Grid>




                    </Grid>
                </Container>
            </Box>

        </>
    )
}

