import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Helmet } from 'react-helmet';

import Casestudyback from '../../../../Component/Casestudyback'

import Banner from "../../../../asset/images/insight/case_studies/banner_reducing_maintenanace.webp"
import Infographics from "../../../../asset/images/insight/case_studies/reducing_maintenanc.webp"
import { Link } from 'react-router-dom';



export default function ReducingMaintenance() {



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet><meta charset="UTF-8" />
                <meta charset="UTF-8" />
                <title>AI-Powered Maintenance Cuts Downtime by 90% - Learn the Secret</title>
                <meta name="description" content="Discover how AI-driven predictive maintenance transformed operations for an Indian government entity, reducing machine downtime by 90%." />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/case-studies/reducing-maintenance-downtime-by-90-with-ai-driven-solutions" />
            </Helmet>

            <Box id="case-studies" className='section sec_bg new_cs' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={3} alignItems="top" justifyContent='center'>
                        <Grid item lg={9} md={9} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Success Stories
                                </Typography>
                                <Typography className='mainhead blue al_center'>
                                    Reducing Maintenance Downtime <span className='lower'>by 90% with</span>  AI-Driven Solutions
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Box >
                        <img src={Banner} className='w100' alt='Banner' />
                    </Box>


                    <Box className='whitebx'>
                        <Box>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Executive Summary
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                An Indian government entity responsible for strategic operations faced challenges with predictive maintenance solutions due to unstructured data and manual tracking systems. By developing AI-ML predictive maintenance models, the organization significantly reduced downtime and enhanced operational efficiency.
                            </Typography>
                        </Box>


                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                The Challenge
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                The organization faced several key issues:
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Manual Data Capture: </strong> Reliance on manual systems for tracking machine faults led to inefficiencies.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Unstructured Data: </strong> Poor visibility due to data being captured in scanned PDFs hindered analysis.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Inability to Analyze Machine Lifecycles: </strong>  Lack of insights into machine lifecycles resulted in delayed maintenance actions.
                                        </Typography>
                                    </li>

                                </ul>
                            </Typography>
                        </Box>

                        <Box mt={3} className='mob_card'>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Solution: AI-Driven Predictive Maintenance
                            </Typography>

                            <Grid container direction="row"
                                justifyContent="center"
                                alignItems="center">

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className="al_center" my={3}>
                                        <img src={Infographics} className="topbanner" alt="Solution: AI-Driven Predictive Maintenance" />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box mt={1}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Results
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Reduced Downtime: </strong> Achieved a 90% reduction in machine downtime through proactive maintenance strategies.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Cost Savings: </strong>  Enhanced identification of faults resulted in significant cost reductions.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Optimized Inventory Management: </strong> Accurate spare parts management led to lean inventory practices.
                                        </Typography>
                                    </li>

                                </ul>
                            </Typography>
                        </Box>

                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Key Takeaways
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>  Predictive Maintenance Transforms Operations: </strong>   Implementing  <strong> AI-driven models</strong> improved machine reliability and operational efficiency.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Proactive Strategies Yield Savings: </strong> Early identification of maintenance needs minimized operational costs.
                                        </Typography>
                                    </li>
                                </ul>
                                <Typography variant='h6' className='black jst'>
                                    <strong>  Is your organization ready to embrace predictive maintenance?</strong>
                                </Typography>

                            </Typography>

                            <Typography variant='h6' className='black jst' gutterBottom>
                                <Link to="/contact-us#contact_form" className="link">Let’s discuss</Link> how AI-driven solutions can transform your operations and drive substantial savings!
                            </Typography>

                        </Box>

                    </Box>


                    <Casestudyback />

                </Container>
            </Box>


        </>
    );
}