
import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Writemessage3 from '../../../../../../Component/Writemessage3';
import ESGBlogsBack from '../../../../../../Component/BackButtons/ESGBlogsBack';
import { Helmet } from 'react-helmet';
import EmployeeProfile from '../../../../../../Component/EmployeeProfile';
import AJ from '../../../../../../asset/images/team/ajay_jindal.webp'




export default function Accelerate_decarbonisation() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);


  return (
    <>
      <Helmet>
        <title>Masterplan To Accelerate Decarbonisation Of Five Major Sectors Planned</title>
        <meta name="description" content="Discover the masterplan accelerating decarbonisation across major sectors. Explore insights at Decimal Point Analytics." />
        <link rel="canonical" href="https://www.decimalpointanalytics.com/esg-insights/esg-blogs/masterplan-to-accelerate-decarbonisation-of-five-major-sectors-planned" />
      </Helmet>


      <Box className='section sec_bg ' component='section'>

        <Container maxWidth='lg' className='bdr'>

          <Grid container spacing={3} alignItems="top">
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box className='al_center' mb={2}>
                <Typography variant='h3' className='subhead skblue al_center'>
                  ESG Insights
                </Typography>
                <Typography variant='h1' className='mainhead blue al_center'>
                  Masterplan to accelerate decarbonisation of five major sectors planned
                </Typography>
              </Box>
            </Grid>
          </Grid>


          <Writemessage3 pageVisited=" Masterplan to accelerate decarbonisation of five major sectors planned" />



          <Box className='whitebx' mt={1}  >

            <Box>

              <Typography variant='h4' className='blue'>
                Introduction
              </Typography>

              <Typography variant='h6' className='black jst'>
                Amongst the din and hustle of the latest ‘Conference of the Parties’ or COP, held in Egypt this year (COP27), one announcement that may have some real-world significance was a plan to address decarbonisation of five major polluting sectors. </Typography>

            </Box>


            <Box pt={1} pb={2}>

              <Typography variant='h6' className='black jst'>
                Countries representing more than 50% of global GDP set out sector-specific ‘Priority Actions’ to decarbonise power, transport, and steel, scale up low-emission hydrogen production and accelerate the shift to sustainable agriculture by COP28.
              </Typography>



              <Typography variant='h6' className='black jst'>
                The initiative is led by the G7, European Commission, India and Egypt, and supported by several leading international organizations. According to a press release by UNFCC (United Nations Framework Convention on Climate Change), the Priority Actions include agreements to:
              </Typography>


              <Typography variant='h6' className='black jst'>
                <ul>
                  <li>Develop common definitions for low-emission and near-zero emission steel, hydrogen and sustainable batteries to help direct billions of pounds in investment, procurement and trade to ensure credibility and transparency </li>
                  <li>  Ramp up the deployment of essential infrastructure projects including at least 50 large scale net-zero emission industrial plants, at least 100 hydrogen valleys and a package of major cross-border power grid infrastructure projects  </li>
                  <li>  Set a common target date to phase out polluting cars and vehicles, consistent with the Paris Agreement. Significant backing for the dates of 2040 globally and 2035 in leading markets will be announced by countries, businesses and cities on Solutions Day  </li>

                  <li> Use billions of pounds of private and public procurement and infrastructure spend to stimulate global demand for green industrial goods </li>

                  <li>  Systematically strengthen financial and technological assistance to developing countries and emerging markets to support their transitions backed up by a range of new financial measures, including the world’s first major dedicated industry transition programme under the Climate Investment Funds  </li>
                  <li> Drive investment in agriculture research, development & demonstration (RD&D) to generate solutions to address the challenges of food insecurity, climate change and environmental degradation </li>
                </ul>

              </Typography>



            </Box>



            {/*-------------------------------------------------------*/}

            <Box pt={1} pb={2}>

              <Typography variant='h6' className='black jst'>
                These priority actions are being supported and accelerated by developments across industry and government-led initiatives. These include:
              </Typography>



              <Typography variant='h6' className='black jst'>
                The initiative is led by the G7, European Commission, India and Egypt, and supported by several leading international organizations. According to a press release by UNFCC (United Nations Framework Convention on Climate Change), the Priority Actions include agreements to:
              </Typography>


              <Typography variant='h6' className='black jst'>
                <ul>
                  <li>  The First Movers Coalition, which launched at COP26 to help drive industrial decarbonisation, has expanded to 10 new corporate members including PepsiCo, General Motors, Rio Tinto and ETEX, to reach 65 in total with a combined market cap of about $8 trillion. Together they commit $12bn to commercialize zero-carbon technology to decarbonize the heavy industry and long-distance transport sectors, responsible for 30% of global emissions.  </li>
                  <li> The First Movers Coalition also launched its cement and concrete sector, with newly announced companies committing to purchase at least 10% near-zero carbon cement and concrete per year by 2030.  </li>
                  <li> A package of new financial announcements for industrial transitions: the launch of the Climate Investment Funds’ (CIFs) new Industry Transition Programme – the world’s first large scale dedicated finance programme for developing country industry transitions; a $410m green hydrogen investment in Egypt led by the EBRD; and World Bank plans to develop a $1.6bn green hydrogen global programme.  </li>

                  <li> This comes on top of recently confirmed plans to launch by COP28 the ‘CIFs Capital Market Mechanism’ – a new instrument that modeling has shown will generate billions of dollars a year in extra finance into sector transitions over the next 10 years.  </li>

                  <li>  SThe Mission Possible Partnership (MPP), an alliance of leading climate organizations, released 2030 Milestones for seven hard-to-abate industrial and transport sectors, following publication of transition strategies endorsed by more than 200 industrial companies.   </li>
                  <li>MPP will also provide advisory and technical support for accelerated decarbonisation projects in two industrial hubs in Houston and Los Angeles, United States, to scale first projects spanning multiple hard-to-abate industrial and transport sectors and inform the next wave of green industrial clusters.  </li>

                  <li> The Africa Net-Zero Concrete Group has launched and the first African Net-Zero Concrete National Roadmap has been set by Egypt.  </li>
                </ul>

              </Typography>



            </Box>

            {/*-------------------------------------------------------*/}
            <Box mt={1}>
              <EmployeeProfile imgSrc={AJ} name={" Mr. Ajay Jindal"} designation={"EVP,"} company={"Decimal Point Analytics Pvt. Ltd."} linkedinUrl={"https://www.linkedin.com/in/ajay-jindal-cfa-33aa961/"} />
            </Box>
            {/*-------------------------------------------------------*/}



          </Box>
          {/*-------------------------------------------------------*/}



          <Box className='graybbx' mt={1} >
            <Grid container spacing={1} alignItems="top" >
              <Grid item lg={12} md={12} sm={12} xs={12} >
                <Box className='al_left'>

                  <Typography variant='h6' className='black jst'>
                    <b>Category:</b>  Environment
                  </Typography>

                  <Typography variant='h6' className='black jst'>
                    <b>Tags:</b> Conference of the Parties, COP27, First Movers Coalition, Africa Net-Zero Concrete Group, Climate Investment Funds, CIFs Capital Market Mechanism, Mission Possible Partnership.
                  </Typography>


                </Box>
              </Grid>
            </Grid>
          </Box>



          <ESGBlogsBack />




        </Container>
      </Box>


    </>
  );
}

