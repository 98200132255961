import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Helmet } from 'react-helmet';

import Casestudyback from '../../../../Component/Casestudyback'

import Banner from "../../../../asset/images/insight/case_studies/banner_us_asset_management.webp"
import Infographics from "../../../../asset/images/insight/case_studies/us_asset_management.webp"
import { Link } from 'react-router-dom';



export default function USAssetManagementFirm() {



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet><meta charset="UTF-8" />
                <meta charset="UTF-8" />
                <title>US Asset Manager Achieves 30% Faster Operations with Zero Downtime</title>
                <meta name="description" content="Discover how a US-based asset manager upgraded their system to boost security and improve efficiency by 30% in this case study." />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/case-studies/us-asset-management-firm-boosts-security-with-30-faster-operations-and-zero-downtime" />
            </Helmet>

            <Box id="case-studies" className='section sec_bg new_cs' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={3} alignItems="top" justifyContent='center'>
                        <Grid item lg={9} md={9} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Success Stories
                                </Typography>
                                <Typography className='mainhead blue al_center'>
                                US Asset Management Firm Boosts Security <span className='lower'>with</span> 30% Faster Operations <span className='lower'>and</span> Zero Downtime 
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Box >
                        <img src={Banner} className='w100' alt='Banner' />
                    </Box>


                    <Box className='whitebx'>
                        <Box>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Executive Summary
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                            A US-based asset management firm with approximately $12 billion AUM sought to enhance the security and performance of its scheduling tool. With outdated systems posing security risks, Decimal Point Analytics facilitated a seamless cloud infrastructure migration to a more advanced platform. This migration resulted in a 30% increase in job execution speed, zero downtime during the transition, and improved security for sensitive communications.
                            </Typography>
                        </Box>


                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                The Challenge
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                            The client faced significant issues with their outdated scheduling system:
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Outdated JAMS Version:</strong> The JAMS 6.5 system lacked support for OAuth-based email transfers, increasing security vulnerabilities.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Manual Job Oversight:  </strong> PowerShell jobs were prone to errors and required manual intervention.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Migration Risks:  </strong> A potential risk of downtime during the system upgrade threatened operational continuity.
                                        </Typography>
                                    </li> 

                                </ul>
                            </Typography>
                        </Box>

                        <Box mt={3} className='mob_card'>
                            <Typography variant='h4' className='blue' gutterBottom>
                            Solution: Scheduling System Upgrade  
                            </Typography> 

                            <Grid container direction="row"
                                justifyContent="center"
                                alignItems="center">

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className="al_center" my={3}>
                                        <img src={Infographics} className="topbanner" alt="Solution: Scheduling System Upgrade " />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box mt={1}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Results
                            </Typography> 

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> 30% Faster Operations:  </strong> Job execution speed increased by 30%, reducing delays.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Zero Downtime:  </strong>Seamless migration ensured no disruption to business activities.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Improved Data Security:    </strong> Sensitive information is now securely transferred via OAuth-enabled emails.
                                        </Typography>
                                    </li> 

                                </ul>
                            </Typography>
                        </Box>

                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Key Takeaways
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Faster Job Execution: </strong> The upgraded system reduced operational delays by 30%.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Seamless System Transition:  </strong> The migration was completed with zero downtime, ensuring business continuity.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Enhanced Security: </strong> OAuth integration significantly improved data security.
                                        </Typography>
                                    </li>
                                </ul> 

                            </Typography>

                            <Typography variant='h6' className='black jst' gutterBottom>
                                <Link to="/contact-us#contact_form" className="link">Contact us</Link> today if you want to achieve 30% faster operations and zero downtime during a critical migration!
                            </Typography>

                        </Box>

                    </Box>


                    <Casestudyback />

                </Container>
            </Box>


        </>
    );
}