import { Button, Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';


import Aos from 'aos'
import { Link } from 'react-router-dom';

import paisa1 from '../../../asset/images/newsletter/paisapulse/paisapulse3.webp'

import ArrowBackIcon from '@material-ui/icons/ArrowBack';



export default function Paisapulse_c() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Box className='mainsection' component='section' id='top'>
                <Container maxWidth='lg'>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='skblue al_center'>
                                    Paisa Pulse
                                </Typography>
                                <Typography variant='h1' className='blue al_center'>
                                    Cost of currency V/S. cost of <br />digital transactions
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Box className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>
                    <Box className='whitebx'>
                        <Box className='al_center'>
                            <img src={paisa1} className='topbanner' alt='Paise1' />
                        </Box>
                        {/* <Box>
                            <Subscribe />
                        </Box> */}
                    </Box>

                    <Box className='al_center' mt={3}>
                        <Link to='/paisapulse'>
                            <Button variant="contained" className='dpabtn_gr' startIcon={<ArrowBackIcon />}>
                                Back to Paisa Pulse
                            </Button>
                        </Link>
                    </Box>

                </Container>
            </Box>


        </>
    );
}
