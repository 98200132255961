import { Container, Grid, Typography, Box, Tabs, Tab, AppBar } from "@material-ui/core";
import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import PropTypes from "prop-types";
import Press_Release_data from "./PressRelease/pressRelease_data";
import Design from "./PressRelease/pressRelease_design";


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
 

export default function PressRelease() {

    // main tabs
    const [value, setValue] = React.useState(1);

    const handleChange = (event, newValue) => {
        window.scrollTo(0, 0);
        setValue(newValue);
    };

    // sub tabs
    const [valuesub, setValuesub] = React.useState(2028);

    const handleChangesub = (event, newValuesub) => {
        window.scrollTo(0, 0);
        setValuesub(newValuesub);
    };



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);



    const year2028 = () => {
        return Press_Release_data.filter(item => item.year === 2028);
    };
    const year2027 = () => {
        return Press_Release_data.filter(item => item.year === 2027);
    };
    const year2026 = () => {
        return Press_Release_data.filter(item => item.year === 2026);
    };
    const year2025 = () => {
        return Press_Release_data.filter(item => item.year === 2025);
    };

    const data2028 = year2028();
    const data2027 = year2027();
    const data2026 = year2026();
    const data2025 = year2025();

    return (
        <>



            <Box className="maintabbedsection stiky linkedtabs">
                <Container maxWidth="lg" className="bdr">
                    <Grid container spacing={1} alignItems="top" justifyContent="center">
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <AppBar position="static" className="tabbedbar dgrid_center">
                                <Tabs value={value} onChange={handleChange} variant="scrollable">
                                    {/* <Tab label="Media Coverage" {...a11yProps(0)} />
                                    <Tab label="Press Release" {...a11yProps(1)} /> */}
                                    <Tab
                                        label="Media Coverage"
                                        component={Link}
                                        to="/newsroom/media-coverage"
                                        value={0}
                                    />
                                    <Tab
                                        label="Press Release"
                                        component={Link}
                                        to="/newsroom/press-release"
                                        value={1}
                                    />
                                </Tabs>
                            </AppBar>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <TabPanel value={value} index={1}>
                <Box id="newsroom" className="maintabbedsection stiky linkedtabs">
                    <Container maxWidth="lg" className="bdr">
                        <Grid container spacing={1} alignItems="top" justifyContent="center">
                            <Grid item lg={10} md={10} sm={12} xs={12}>
                                <AppBar position="relative" className="tabbedbar dgrid_center">
                                    <Tabs value={valuesub} onChange={handleChangesub} variant="scrollable">
                                        {/* <Tab label="2028" {...a11yProps(2028)} />
                                        <Tab label="2027" {...a11yProps(2027)} />
                                        <Tab label="2026" {...a11yProps(2026)} />
                                        <Tab label="2025" {...a11yProps(2025)} /> */}
                                        <Tab
                                            label="2028"
                                            component={Link}
                                            to="/newsroom/press-release"
                                            value={2028}
                                        />
                                        <Tab
                                            label="2027"
                                            component={Link}
                                            to="/newsroom/press-release"
                                            value={2027}
                                        />
                                        <Tab
                                            label="2026"
                                            component={Link}
                                            to="/newsroom/press-release"
                                            value={2026}
                                        />
                                        <Tab
                                            label="2025"
                                            component={Link}
                                            to="/newsroom/press-release"
                                            value={2025}
                                        />
                                    </Tabs>
                                </AppBar>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>

                <TabPanel value={valuesub} index={2028}>
                    <Box id="newsroom" className="section sec_bg section_mob" component="section">
                        <Container maxWidth="lg" className="bdr">

                            <Box className="reportinfo">
                                <Grid container spacing={1} alignItems="top" justifyContent="center">

                                    {data2028.map((item, index) => (
                                        <Grid item lg={4} md={4} sm={6} xs={12}>
                                            <Design
                                                key={index}
                                                type={item.type}
                                                link={item.link}
                                                date={item.date}
                                                imgsrc={item.imgsrc}
                                                heading={item.heading}
                                                subheading={item.subheading}
                                            />
                                        </Grid>
                                    ))}

                                </Grid>
                            </Box>

                        </Container>
                    </Box>
                </TabPanel>

                <TabPanel value={valuesub} index={2027}>
                    <Box id="newsroom" className="section sec_bg section_mob" component="section">
                        <Container maxWidth="lg" className="bdr">

                            <Box className="reportinfo">
                                <Grid container spacing={1} alignItems="top" justifyContent="center">

                                    {data2027.map((item, index) => (
                                        <Grid item lg={4} md={4} sm={6} xs={12}>
                                            <Design
                                                key={index}
                                                type={item.type}
                                                link={item.link}
                                                date={item.date}
                                                imgsrc={item.imgsrc}
                                                heading={item.heading}
                                                subheading={item.subheading}
                                            />
                                        </Grid>
                                    ))}

                                </Grid>
                            </Box>

                        </Container>
                    </Box>
                </TabPanel>

                <TabPanel value={valuesub} index={2026}>
                    <Box id="newsroom" className="section sec_bg section_mob" component="section">
                        <Container maxWidth="lg" className="bdr">

                            <Box className="reportinfo">
                                <Grid container spacing={1} alignItems="top" justifyContent="center">

                                    {data2026.map((item, index) => (
                                        <Grid item lg={4} md={4} sm={6} xs={12}>
                                            <Design
                                                key={index}
                                                type={item.type}
                                                link={item.link}
                                                date={item.date}
                                                imgsrc={item.imgsrc}
                                                heading={item.heading}
                                                subheading={item.subheading}
                                            />
                                        </Grid>
                                    ))}

                                </Grid>
                            </Box>

                        </Container>
                    </Box>
                </TabPanel>

                <TabPanel value={valuesub} index={2025}>
                    <Box id="newsroom" className="section sec_bg section_mob" component="section">
                        <Container maxWidth="lg" className="bdr">

                            <Box className="reportinfo">
                                <Grid container spacing={1} alignItems="top" justifyContent="center">

                                    {data2025.map((item, index) => (
                                        <Grid item lg={4} md={4} sm={6} xs={12}>
                                            <Design
                                                key={index}
                                                type={item.type}
                                                link={item.link}
                                                date={item.date}
                                                imgsrc={item.imgsrc}
                                                heading={item.heading}
                                                subheading={item.subheading}
                                            />
                                        </Grid>
                                    ))}

                                </Grid>
                            </Box>

                        </Container>
                    </Box>
                </TabPanel>
            </TabPanel>



        </>
    );
}