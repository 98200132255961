import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import CXO from '../../../asset/images/media/cxo.webp';

import NewsRoomBack from '../../../Component/NewsRoomBack';
import { Helmet } from 'react-helmet';


export default function RequiemforESG() {



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);



    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>Requiem for ESG? Not Quite. A Call for Reform, not Retirement.</title>
                <meta name="description" content="Despite criticisms, ESG principles remain valid. Learn about corporate disclosure reforms and the long-term benefits of ESG investing." />
                <meta name="keywords" content="ESG, backlash, green investing, underperformance, risk management, corporate governance, sustainability standards, ESG rating sellers, reform" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/is-it-time-to-write-esg-requiem-not-quite" />

                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            <Box className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>


                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    News Room
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center'>
                                    Is it time to write ESG’s Requiem? Not quite
                                </Typography>

                            </Box>
                        </Grid>
                    </Grid>

                    <Box>
                        <Grid container direction="row"
                            justifyContent="center"
                            alignItems="center">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='whitebx'>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        <strong>The backlash against ESG continues.</strong> Several opinion makers have called for an end to ESG in recent weeks and months. However, the underlying principles have merit and a place that will live, even if the form evolves.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        The backlash against ESG grows. Mainstream media is not just carrying anti-ESG articles; it is now giving a sensationalist spin. <strong>‘Billions flow out of ESG as investors turn their backs on green’</strong> investing, wrote an article in a major paper. Read the sub-text; the article mentions an outflow of £2.5 billion out of ESG funds in five months, which is negligible considering ESG focused funds are estimated to have £2.5 trillion of assets.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        <strong>‘ESG is beyond redemption: may it RIP’</strong> said the headline in a global business paper. Media seems to be working overtime to turn a push into a shove. To be sure, there are issues that need fixing. However, the concerns of the large asset owners like pension funds or sovereign funds or regulators are very different from the issues being voiced by armchair experts.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        In this article, we will unpeel the layers to show the genuine issues versus those voiced in some of the recent media articles with frivolous headlines. <strong>The ‘ESG fails investors’ stance comes from the underperformance of ESG focused funds in the last couple of years.</strong> The Ukraine war has led to fossil stocks and arms companies outperforming, while simultaneously stoking inflation that has dampened demand in normal business sectors and slowing down major economies.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        The only sectors truly outperforming are the ones shunned by ESG. This criticism reflects poor understanding of factor investing. It is well accepted that a factor strategy may not always outperform. For example, in a runaway bull market, value will underperform. In other words, mere short or medium-term underperformance is no reason to discredit a factor.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        A recent piece asserted that ESG fails corporates too; <strong>‘Higher ESG score can increase value smaller and serving niche markets, while decreasing value at other companies where it adds to costs while doing nothing for revenues’, said the article. We argue this is short-termism.</strong> Are these critics trying to suggest that companies should stop focusing on Net Zero? If so, they are denying climate change and The Paris Agreement, which is a legally binding agreement agreed to over 190 countries.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Trump had made the US exit the Paris Agreement, Biden got the US back in. Most other countries are firmly committed. How will the countries reach Net Zero if their corporates disregard it? ESG has social goals as well for corporates, like respect for local communities, and treating employees equitably. If corporates are encouraged to disregard these goals, then we will be back to cowboy capitalism.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        <strong>‘ESG lowers risk, so means lower returns’ is another favourite criticism.</strong> This is classic theoretical, armchair understanding of investing. The key is the sequence of events. Consider this: A fund buys a stock; the company works on improving ESG metrics – for example, better governance, better supplier relations, reduced energy consumption, better product governance – in other words, business risk reduces; the market recognizes this, bids the stock up. Will an investor underperform or outperform in such a scenario?
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Risk is not static; management action changes it. In fact, long investing is mainly a bet on the management’s ability to outperform in risk management. The purpose of ESG ‘engagements’ – investors discussing improvements with portfolio companies – is to reduce risk. This reduction in risk will not lower returns but will increase it.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        <strong>ESG also has the key G pillar, which most commentators ignore. The agency problem is a well-defined problem, and G addresses that between owners and managers.</strong> In short, most of the arguments made in recent media articles are off the mark, and likely biased with a political agenda.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        This is not to say the ESG market does not need change. Reforms are needed at various levels: corporate disclosure frameworks and in the way rating sellers work and are regulated. <strong>On November 17th, 57 organizations, representing over EUR 8.5 trillion in assets, released an open letter for the European Union to act on corporate ESG disclosure standards.</strong>
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        “Preparers and consumers of environmental, social and governance (ESG) data all over the world are still struggling with a profusion of reporting frameworks and standards like that do not enable consistent and comparable disclosures. We, therefore, strongly support the initiative of the International Financial Reporting Standards (IFRS) Foundation to create an International Sustainability Standards Board (ISSB) to develop globally accepted standards for sustainability reporting that can be adopted worldwide,” said the letter.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        ESG ratings sellers have created a mess that needs to be cleaned up; large institutional investors have been asking for greater regulation of rating sellers. One of the recent calls for change came from the Norges Bank Investment Management <strong>(NBIM) that wrote an open letter to the European Commission (EC) in August this year asking for changes to the rating provider (ESG Seller) ecosystem.</strong>
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        In the letter, NBIM said, “We concur with the European Commission’s assessment that the ESG rating market suffers from deficiencies which could undermine investors and rated entities’ confidence in ratings.” NBIM urged the EC to require used ESG Sellers to improve the transparency on the characteristics of ESG ratings, their methodologies, and data sources.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        It must be noted that while it asks for reforms, NBIM is totally supportive of ESG. “As a long-term and global investor, we consider our return to be dependent on sustainable development in economic, environmental and social terms” wrote NBIM in its letter. In other words, the real players in the market are working to strengthen ESG practices; they are not calling for an end to ESG. For a sustainable future, that is what is needed.
                                    </Typography>

                                </Box>

                            </Grid>
                        </Grid>
                    </Box>

                    <Box>
                        <Box className='graybbx' mt={2}>
                            <Grid container spacing={0} alignItems="center" justifyContent='center'>


                                <Grid item lg={4} md={4} sm={4} xs={6}>
                                    <a href="https://cxotoday.com/story/is-it-time-to-write-esgs-requiem-not-quite/" target="_blank" rel="noopener noreferrer">
                                        <Box
                                            className="awardsindexcircle"
                                            style={{ backgroundImage: "url(" + CXO + ")" }}
                                        ></Box>
                                    </a>
                                </Grid>

                            </Grid>
                        </Box>
                    </Box>

                    <Box mt={2}>
                        <NewsRoomBack />
                    </Box>

                </Container>
            </Box>





        </>
    );
}
