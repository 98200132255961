import { Box, Container, Grid, Typography } from '@material-ui/core'
import React from 'react';
import icon1 from '../../asset/images/outsourcesba/team.png';
import icon2 from '../../asset/images/outsourcesba/expert_under.png';
import icon3 from '../../asset/images/outsourcesba/solution.png';
import icon4 from '../../asset/images/outsourcesba/profitability.png';
import icon5 from '../../asset/images/outsourcesba/track.png';
import icon6 from '../../asset/images/outsourcesba/Offshore.png';


export const SBALendersBenefit = () => {
    return (
        <>
           <Box className='section2'>
           <Container>
                <Grid container spacing={4} alignItems="top" justifyContent='center'>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                       <Box>
                       <Typography variant="h2" className="black al_center fw5">
                            How SBA Lenders Benefit from Decimal Point Analytics
                        </Typography>
                       </Box>
                    </Grid>

                    <Grid item lg={4} md={4} sm={6} xs={12}>
                        <Box className="sbalenders_benefitbx">
                            <Box className='sba_iconbx'>
                                <img src={icon1} alt='Immediate Team Setup' className='sba_icon' />
                            </Box>
                            <Box mt={1} mb={2}>
                                <Typography variant="h4" className="black al_left" gutterBottom>Immediate Team Setup:</Typography>
                            </Box>
                            <Typography variant="h6" className="black al_left jst">We can set up your SBA loan underwriting team immediately, reducing the headache of team formation and ensuring you have the right experts on board from day one.</Typography>
                        </Box>
                    </Grid>

                    <Grid item lg={4} md={4} sm={6} xs={12}>
                        <Box className="sbalenders_benefitbx">
                            <Box className='sba_iconbx'>
                                <img src={icon2} alt='Expert Underwriters' className='sba_icon' />
                            </Box>
                            <Box mt={1} mb={2}>
                                <Typography variant="h4" className="black al_left" gutterBottom>Expert Underwriters:</Typography>
                            </Box>
                            <Typography variant="h6" className="black al_left jst">Our trained staff are well-versed in SBA guidelines and policies, ensuring compliance and quality at every step.</Typography>
                        </Box>
                    </Grid>

                    <Grid item lg={4} md={4} sm={6} xs={12}>
                        <Box className="sbalenders_benefitbx">
                            <Box className='sba_iconbx'>
                                <img src={icon3} alt='Cost-Effective Solutions' className='sba_icon' />
                            </Box>
                            <Box mt={1} mb={2}>
                                <Typography variant="h4" className="black al_left" gutterBottom>Cost-Effective Solutions:</Typography>
                            </Box>
                            <Typography variant="h6" className="black al_left jst">Outsourcing to Decimal Point Analytics is cost-effective, increasing your profitability by reducing overhead costs.</Typography>
                        </Box>
                    </Grid>

                    <Grid item lg={4} md={4} sm={6} xs={12}>
                        <Box className="sbalenders_benefitbx">
                            <Box className='sba_iconbx'>
                                <img src={icon4} alt='Enhanced Profitability' className='sba_icon' />
                            </Box>
                            <Box mt={1} mb={2}>
                                <Typography variant="h4" className="black al_left" gutterBottom>Enhanced Profitability:</Typography>
                            </Box>
                            <Typography variant="h6" className="black al_left jst"> With our efficient processes and expert team, you can expect enhanced profitability through streamlined operations.</Typography>
                        </Box>
                    </Grid>

                    <Grid item lg={4} md={4} sm={6} xs={12}>
                        <Box className="sbalenders_benefitbx">
                            <Box className='sba_iconbx'>
                                <img src={icon5} alt='Proven Track Record' className='sba_icon' />
                            </Box>
                            <Box mt={1} mb={2}>
                                <Typography variant="h4" className="black al_left" gutterBottom>Proven Track Record:</Typography>
                            </Box>
                            <Typography variant="h6" className="black al_left jst">We have a proven track record in managing SBA 7(a) loans, ensuring successful loan processing and approval.</Typography>
                        </Box>
                    </Grid>

                    <Grid item lg={4} md={4} sm={6} xs={12}>
                        <Box className="sbalenders_benefitbx mob_card">
                            <Box className='sba_iconbx'>
                                <img src={icon6} alt='Offshore Team Setup' className='sba_icon' />
                            </Box>
                            <Box mt={1} mb={2}>
                                <Typography variant="h4" className="black al_left" gutterBottom>Offshore Team Setup:</Typography>
                            <Typography variant="h6" className="black al_left jst">We help you set up offshore teams for SBA loan underwriting, providing a scalable solution that meets your needs</Typography>
                            </Box>
                        </Box>
                    </Grid>

                </Grid>
            </Container>
           </Box>

        </>
    )
}

