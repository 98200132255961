import React, { useState } from "react";

import {
  Box,
  Button, 
  Container, 
  Grid,
  TextField,
  Typography,
  
} from "@material-ui/core";
import flags from "react-phone-number-input/flags";
import Autocomplete from "@mui/material/Autocomplete";
import { Formik, Field, Form } from "formik";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

import LoadingButton from "@mui/lab/LoadingButton";
import bgimg from "../../asset/images/outsourcesba/contact-us.webp";
import CustomePhoneNumber from "../../Component/CustomePhoneNumber_Not_Mandatory";
import { sbaFormApi } from "../../Component/api/api";

export const SBAContactUs = () => {
  localStorage.setItem("isOpen", 1);
  const [phoneValue1, setPhoneValue1] = React.useState();
  const [loader, setLoader] = React.useState(false);
  const [checkboxFlag, setCheckboxFlag] = React.useState(false);
  const [phoneValueError, setPhoneValueError] = React.useState();
  const [checkboxError, setCheckboxError] = React.useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");

  const nameRegexExp = /^[A-Za-z\s]+$/;
  const indiaPhoneRegex = /^[6-9]\d{9}$/;
  const emailreg =
    /^[a-zA-Z0-9_]+(?:[-._][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+(?:[-.][a-zA-Z0-9_]+)*\.[a-zA-Z]{2,}$/;
  const organizationRegex = /^[a-zA-Z0-9 ,-./!@#$%^&*~]*$/;

  const history = useHistory();
  const initialValues = {
    firstname: "",
    lastname: "",
    services: "",
    email: "",
    organization: "",
    message: "",
    phonevalue1: "",
    tandc: true,
  };

  const services = [
    { label: "Full SBA Service LSP", value: "Full SBA Service LSP" },
    { label: "SBA Loan Underwriting", value: "SBA Loan Underwriting" },
    { label: "Loan Servicing", value: "Loan Servicing" },
    { label: "Pre Qualification", value: "Pre Qualification" },
  ];

  const validationSchema = yup.object().shape({
    firstname: yup
      .string()
      .required("First name is required")
      .typeError("Required field")
      .matches(nameRegexExp, "Enter valid first name"),
    lastname: yup
      .string()
      .required("Last name is required")
      .typeError("Required field")
      .matches(nameRegexExp, "Enter valid last name"),
    organization: yup
      .string()
      .required("Company Name is required")
      .typeError("Required field")
      .matches(organizationRegex, "Enter valid company name"),
    email: yup
    .string()
    .typeError("Required field")
    .required("Email is required")
    .matches(emailreg, "Please enter a valid email address")
    // .test('valid-email', 'Please enter a valid email address', function(value) {
    //   if (!value) return true;
    //   const parts = value.split('@');
    //   if (parts.length !== 2) return false;
      
    //   const domainParts = parts[1].split('.');
    //   if (domainParts.length > 2) {
    //     const lastPart = domainParts[domainParts.length - 1];
    //     const secondLastPart = domainParts[domainParts.length - 2];
    //     const commonTLDs = ['com', 'org', 'net', 'edu', 'gov', 'co', 'io', 'ai', 'app'];
    //     if (commonTLDs.includes(lastPart) && commonTLDs.includes(secondLastPart)) {
    //       return false;
    //     }
    //   }
    //   return true;
    // }),
    ,
    services: yup
      .string()
      .required("Service selection is required")
      .typeError("Required field"),
    phonevalue1: yup.string(),
    tandc: yup
      .bool()
      .oneOf([true], "You must accept the terms and conditions")
      .nullable(),
  });

  const handleKeyUp = (event, fieldName) => {
    const value = event.target.value;
    if (/\d+/.test(value)) {
      if (fieldName === "firstname") {
        setFirstNameError("First name should not contain numbers");
      } else if (fieldName === "lastname") {
        setLastNameError("Last name should not contain numbers");
      }
    } else {
      if (fieldName === "firstname") {
        setFirstNameError("");
      } else if (fieldName === "lastname") {
        setLastNameError("");
      }
    }
  };

  const onSubmitt = (values) => {
    console.log(values);
    isPhoneValid();
    if (phoneValueError) {
      return;
    }
    console.log("Form Values:", values);

    const formData = {
      first_name: values.firstname,
      last_name: values.lastname,
      email: values.email,
      contact_no: values.phonevalue1,
      message: values.message,
      company: values.organization,
      //   obtain_email: values.tandc,
      service_needed: values.services,
    };
    setCheckboxError("");
    handleFormSubmit(formData);
  };

  const handleFormSubmit = (values) => {
    console.log(values);
    post(values);
  };

  const isPhoneValid = () => {
    if (!phoneValue1) {
      setPhoneValueError("");
      return;
    }

    var phoneValue2 = phoneValue1.includes("+91") ? phoneValue1.substring(3) : phoneValue1;
    if (phoneValue2.trim() === "") {
      setPhoneValueError("Enter phone number");
      return;
    }

    if (isValidPhoneNumber(phoneValue1)) {
      if (phoneValue1.includes("+91")) {
        if (!indiaPhoneRegex.test(phoneValue2)) {
          setPhoneValueError("Invalid phone number");
          return;  
        }
      }
      setPhoneValue1(phoneValue1);
      setPhoneValueError("");
    } else {
      setPhoneValueError("Invalid phone number");
    }
  };

  const onSucccessCall = () => {
    //localStorage.setItem("isOpen", 0);
    setCheckboxFlag(false);
    // handleClose();
    history.push("/sba-form-submit");
  };

  const post = async (formData) => {
    setLoader(true);
    const message = "Oops, something went wrong, Please try again leter.";
    try {
      const { data, status } = await sbaFormApi(formData);
      if (status === 200) {
        if (data.success === true) {
          onSucccessCall();
        } else {
          toast.error(data.message, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
    } catch (error) {
      toast.error(message, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    setLoader(false);
  };

  React.useEffect(() => {
    const { hash } = window.location;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        const offset = 170;
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition - offset;
        window.scrollBy({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    }
  }, []);

  return (
    <>
      <Container maxWidth="lg">
        <Box
          id="sbacontactus"
          component="section"
          className="whitebx"
          mt={2}
          mb={5}
        >
          <Box pb={2}>
            <Typography variant="h2" className="black al_center fw5">
              Contact Us
            </Typography>
          </Box>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmitt}
            validateOnChange={true}
            enableReinitialize={true}
          >
            {({
              values,
              handleSubmit,
              handleChange,
              handleBlur,
              setFieldValue,
              touched,
              errors,
            }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Box className="">
                      <img
                        src={bgimg}
                        alt="contact us"
                        className="topbanner2"
                      />
                    </Box>
                  </Grid>

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Grid container spacing={2}>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Box className="alltxfieldlg ">
                          <Field
                            as={TextField}
                            id="filled-basic1"
                            label="First Name *"
                            variant="standard"
                            fullWidth
                            inputProps={{ maxLength: 100 }}
                            name="firstname"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            onKeyUp={(event) => handleKeyUp(event, "firstname")}
                            error={
                              touched.firstname && Boolean(errors.firstname)
                            }
                            helperText={
                              touched.firstname && errors.firstname
                                ? errors.firstname
                                : firstNameError
                            }
                            FormHelperTextProps={{ style: { color: "red" } }}
                          />
                        </Box>
                      </Grid>

                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Box className="alltxfieldlg ">
                          <Field
                            as={TextField}
                            id="filled-basic2"
                            label="Last Name *"
                            variant="standard"
                            fullWidth
                            inputProps={{ maxLength: 100 }}
                            name="lastname"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            onKeyUp={(event) => handleKeyUp(event, "lastname")}
                            error={touched.lastname && Boolean(errors.lastname)}
                            helperText={
                              touched.lastname && errors.lastname
                                ? errors.lastname
                                : lastNameError
                            }
                            FormHelperTextProps={{ style: { color: "red" } }}
                          />
                        </Box>
                      </Grid>

                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box className="alltxfieldlg">
                          <Field name="services">
                            {({ field }) => (
                              <Autocomplete
                                disablePortal
                                fullWidth
                                disableClearable
                                freeSolo={false}
                                options={services}
                                getOptionLabel={(option) => option.label}
                                isOptionEqualToValue={(option, value) =>
                                  option.value === value.value
                                }
                                onChange={(event, value) => {
                                  console.log(value.value);
                                  setFieldValue("services", value?.value);
                                }}
                                error={
                                  touched.services && Boolean(errors.services)
                                }
                                helperText={touched.services && errors.services}
                                renderInput={(params) => (
                                  <TextField
                                    error={
                                      touched.services &&
                                      Boolean(errors.services)
                                    }
                                    helperText={
                                      touched.services && errors.services
                                    }
                                    {...params}
                                    label="Service Needed *"
                                    variant="standard"
                                    fullWidth
                                    InputProps={{
                                      ...params.InputProps,
                                      readOnly: true,
                                    }}
                                  />
                                )}
                              />
                            )}
                          </Field>
                        </Box>
                      </Grid>

                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box className="alltxfieldlg ">
                          <Field
                            as={TextField}
                            id="filled-basic4"
                            label="Company Name *"
                            variant="standard"
                            fullWidth
                            name="organization"
                            error={
                              touched.organization &&
                              Boolean(errors.organization)
                            }
                            helperText={
                              touched.organization && errors.organization
                            }
                            FormHelperTextProps={{ style: { color: "red" } }}
                          />
                        </Box>
                      </Grid>

                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Box className="alltxfieldlg ">
                          <Field
                            as={TextField}
                            id="filled-basic3"
                            label="Email Address *"
                            variant="standard"
                            name="email"
                            inputProps={{ maxLength: 100 }}
                            error={touched.email && Boolean(errors.email)}
                            helperText={touched.email && errors.email}
                            FormHelperTextProps={{ style: { color: "red" } }}
                            fullWidth
                          />
                        </Box>
                      </Grid>

                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Box className="alltxfieldlg " mt={2}>
                          <Field
                            as={PhoneInput}
                            flags={flags}
                            international
                            name="phonevalue1"
                            // defaultCountry="IN"
                            value={values.phonevalue1}
                            inputComponent={CustomePhoneNumber}
                            placeholder="Enter phone number"

                            onChange={(value) => {
                              handleChange({
                                target: { name: "phonevalue1", value: value },
                              });
                              setPhoneValue1(value);
                            }}
                            onBlur={() => {
                              handleBlur("phonevalue1");
                              isPhoneValid();
                            }}
                            error={
                              touched.PhoneValue1 && Boolean(errors.PhoneValue1)
                            }
                            helperText={
                              touched.PhoneValue1 && errors.PhoneValue1
                                ? errors.PhoneValue1
                                : phoneValueError
                            }
                            FormHelperTextProps={{ style: { color: "red" } }}

                          />
                        </Box>
                      </Grid>

                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box className="alltxfieldlg ">
                          <Field
                            as={TextField}
                            id="filled-basic5"
                            label="How can we help you?"
                            variant="standard"
                            fullWidth
                            name="message"
                            multiline
                            rows={4}
                          />
                        </Box>
                      </Grid>

                      {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box className="alltxfieldlg chkbx">
                          <FormControlLabel
                            control={
                              <>
                                <Field
                                  defaultChecked={true}
                                  as={Checkbox}
                                  name="tandc"
                                  color="primary"
                                  size="small"
                                />
                              </>
                            }
                            label={
                              <Typography className="label2">
                                By clicking submit, I agree to Decimal Point
                                Analytics <Link to="/terms">Terms of Use</Link>{" "}
                                and <Link to="/policy">Privacy Policy</Link>
                              </Typography>
                            }
                          />
                        </Box>
                        <Box className="checkbx-error">
                          {touched.tandc && errors.tandc && (
                            <MuiAlert severity="error">{errors.tandc}</MuiAlert>
                          )}
                        </Box>
                      </Grid> */}
                    </Grid>
                    <Box className="al_center" mt={2}>
                      {/* <Link> */}
                      {loader ? (
                        <LoadingButton
                          loading={true}
                          variant="outlined"
                          disabled
                        >
                          disabled
                        </LoadingButton>
                      ) : (
                        <Button
                          type="submit"
                          onClick={() => {
                            console.log(errors);
                          }}
                          variant="contained"
                          className="dpabtn_1 mobbtn"
                        >
                          Send Message
                        </Button>
                      )}
                      {/* </Link> */}
                    </Box>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Box>
      </Container>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};
