import { Divider, Container, Typography, Box, Grid, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Helmet } from 'react-helmet';

import Insightback from '../../../Component/Insightback'

import Writemessage3 from '../../../Component/Writemessage3';


export default function India_leverage() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (



        <>





            <Helmet>
                <meta charset="UTF-8" />
                <h1>Explore how India can benefit from rising crude oil prices to boost the domestic economy. Our article provides insights into the current market trends, challenges, and opportunities for growth." </h1>
                <title>Leveraging Rising Crude Oil Prices for India's Economy</title>
                <meta name="description" content="How Can India Leverage Rising Crude Oil Prices To Help The Domestic Economy?" />
                <meta name="keywords" content="oil price
crude oil price
current oil prices
crude oil price today
crude oil price today in india" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/articles/india-leverage" />

                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            <Box id="article-page" className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center' >
                        <Grid item lg={10} md={12} sm={12} xs={12}>
                            <Typography variant='h3' className='subhead skblue al_center'>
                                Insight
                            </Typography>
                            <Typography variant='h1' className='blue mainhead al_center'>
                                How can India leverage rising crude oil prices  to help the domestic economy?
                            </Typography>
                        </Grid>
                    </Grid>


                    <Box className='whitebx'>

                        <Typography variant='h6' className='black jst'>At the current juncture, mainly due to geopolitical reasons and artificial supply constraints, the international crude oil prices are near four-year highs at mid $70s per barrel, in spite of rising shale oil production in the USA. Correspondingly, the RBOB Gasoline (gasoline) near-term futures are at $2.08 per gallon in international markets. In India, Indian Oil Corporation on 23rd April 2018 charged Rs. 35.58 per liter of petrol (gasoline) to the dealers before adding excise duty and VAT. </Typography>

                        <Typography variant='h6' className='black jst'> Firstly, India is a very large importer of crude oil and the imports are increasing rapidly to support its fast-growing domestic economy. UnfortThis needs to be juxtaposed with the 5 percent ethanol blending norm in India for petrol, for which the oil marketing companies procure ethanol at Rs. 40.85 per liter. Obviously, even at elevated crude oil prices, domestic ethanol is costlier than imported petrol by Rs. 5.3 per liter approx. </Typography>

                        <Typography variant='h6' className='black jst'>However, as the crude oil price has risen, the negative carry on ethanol has progressively reduced. At the same time, there is a clamor for the government to reduce the Excise duty and VAT burden on motor fuels to offer respite to the common man. </Typography>

                        <Typography variant='h6' className='black jst'> The government can use both the above factors by smartly creating a new class of petrol with 20 percent blend of ethanol, where the excise duty is Rs. 5 lower than excise duty on petrol with 5 percent blend. This will make, other things being equal, 20 percent ethanol petrol Rs. 4 per liter cheaper than 5 percent blend, giving enough incentives for both the consumers and the retailers to actively build supplies of ethanol using both 1G and 2G refinery techniques. This will quickly build income for Indian farmers, reduce imports and reduce inflation (as compared to do-nothing scenario) at the same time. In the past, subsidies by the Indian government on motor fuels have directly gone to benefit OPEC producers. However, now, by smartly directing the reduction in excise duty, India can support its local economy while helping motorists at the same time. </Typography>

                        <Divider />




                        {/* <Box>
                            <Subscribe />

                        </Box> */}
                    </Box>


                    <Box className='pages'>
                        <Writemessage3 pageVisited="How can India leverage rising crude oil prices to help the domestic economy" />
                    </Box>

                    <Insightback />


                </Container>
            </Box>

        </>
    );
}
