import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import NewsRoomBack from '../../../Component/NewsRoomBack';
import { Helmet } from 'react-helmet';

import Bitcoin from "../../../asset/images/media/press_release/crypto_currency.webp"

import Banner from '../../../asset/images/media/press_release/banner_crypto_currency.webp'

export default function RollercoasterRideofBitcoin() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>Insights on Bitcoin's Volatility and Future Prospects by Shailesh Dhuri</title>
                <meta name="description" content="Explore Shailesh Dhuri's analysis of Bitcoin's price fluctuations, regulatory developments, and upcoming events like supply reduction mechanisms and ETF approvals." />
                <meta name="keywords" content="Bitcoin volatility, cryptocurrency trends, Shailesh Dhuri insights" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/rollercoaster-ride-of-bitcoin-what-lies-ahead" />

                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            <Box className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>


                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    News Room
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center'>
                                Rollercoaster Ride Of Bitcoin: What Lies Ahead?
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>

                    <Box >
                        <img src={Banner} className='w100' alt='Banner' />
                    </Box>

                    <Box>
                        <Grid container direction="row"
                            justifyContent="center"
                            alignItems="center">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='whitebx'>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                    Our CEO, Shailesh Dhuri of Decimal Point Analytics Pvt Ltd, discusses Bitcoin's turbulent price history, regulatory impacts, and upcoming events such as the supply reduction mechanism and ETF approvals. He emphasizes increasing investor confidence amid market changes but urges caution due to Bitcoin's inherent volatility and speculative nature linked to its restricted supply dynamics and scarcity
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        <a href='https://business.outlookindia.com/cryptocurrency/rollercoaster-ride-of-bitcoin-what-lies-ahead' target='_blank' rel="noopener noreferrer">Click here</a> to Read the full coverage
                                    </Typography>

                                    <Grid container direction="row" justifyContent="center" spacing={2} alignItems="top">
                                        <Grid item lg={7} md={8} sm={10} xs={12}>
                                            <Box pt={3} pb={3}>
                                                <img src={Bitcoin} className='fullimg' alt='Quote' />
                                            </Box>
                                        </Grid>
                                    </Grid>

                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                 
                    <Box mt={2}>
                        <NewsRoomBack />
                    </Box>

                </Container>
            </Box>





        </>
    );
}
