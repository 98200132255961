import {  Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Eventback from '../../../Component/Eventback'
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';


export default function OracleCloudWorld() {


    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>Dilip Sah at Oracle Cloud World: Advancements in Autonomous Database</title>
                <meta name="description" content=" Highlights from CTO Dilip Sah at Oracle Cloud World, discussing advancements in Oracle Autonomous Database and AI-powered data management solutions." />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/oracle-cloud-world-ai-powered-data-management-with-dilip-sah" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>



            <Box id='event-details' className='section sec_bg' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Events
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center'>
                                    Oracle Cloud World: AI-Powered Data Management with Dilip Sah
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>

                    <Box className='whitebx'>

                        <Typography variant='h6' className='black jst'>
                            Our <strong>CTO, Dilip Sah</strong>, participated in an enlightening session at <strong>Oracle Cloud World</strong> focused on the latest advancements in Oracle Autonomous Database. During this presentation, Dilip explored the innovative concepts of Relational Duality Views and the MongoDB API, showcasing how these technologies can transform data management strategies.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            The session provided a unique opportunity for attendees to learn from an industry expert and gain insights into leveraging AI-powered solutions for enhanced data management. Participants discovered how these advancements can optimize operations and drive efficiency within their organizations.
                        </Typography>

                        <Typography variant='h6' className='black jst'> To learn more about how <strong>Decimal Point Analytics</strong> can support your data management needs,   <Link to="/contact-us#contact_form" className='link'>reach out to us!</Link>
                        </Typography>



                    </Box>

                    <Eventback />

                </Container>
            </Box>



        </>
    );
}
