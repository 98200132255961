
import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Writemessage3 from '../../../Component/Writemessage3';

import Casestudyback from '../../../Component/Casestudyback'
import Persifoni_Inside1 from '../../../asset/images/insight/case_studies/Perifoni_Inside1.webp';
import Persifoni_Inside2 from '../../../asset/images/insight/case_studies/Perifoni_Inside2.webp';



export default function GHG_Emmission_Persifoni() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);


  return (
    <>

      <Helmet>


        <meta charset="UTF-8" />
        <title>Extraction Of GHG Emission Data From ESG Disclosure Reports</title>
        <meta name="description" content="Learn how DPA extracted GHG emissions data from ESG reports. Make strategic ESG investment decisions with accurate data." />
        <meta name="keywords" content="Equity research solutions provider,
esg data,
esg data management,
esg investing,
ESG Portfolio Alignment,
Private companies ESG data provider
" />
        <meta name="author" content="Decimal Point Analytics" />
        <link rel="canonical" href="https://www.decimalpointanalytics.com/case-studies/extraction-of-ghg-emission-data-from-esg-disclosure-reports" />

        <meta name="viewport" content="width=device-width, initial-scale=1.0" />

      </Helmet>


      <Box id="case-studies" className='section sec_bg' component='section'>

        <Container maxWidth='lg' className='bdr'>

          <Grid container spacing={3} alignItems="top" justifyContent='center'>
            <Grid item lg={10} md={10} sm={12} xs={12}>
              <Box>
                <Typography variant='h3' className='subhead skblue al_center'>
                  Success Stories
                </Typography>
                <Typography variant='h1' className='mainhead blue al_center mb0'>

                  {/* ----------------Enter Title Below------------------- */}
                  Extraction of GHG Emission Data from ESG Disclosure Reports
                </Typography>

                <Typography className=' mainsmhead black al_center '>
                  {/* ----------------Enter SubTitle Below------------------- */}
                  Team Decimal Point Analytics Assisted a US-based leading climate management and accounting platform corporation by slithering over 5500 business websites and extracting the necessary data based on predefined categories to yield fruitful insights.
                </Typography>
              </Box>
            </Grid>
          </Grid>


          <Writemessage3 pageVisited="Extraction of GHG Emission Data from ESG Disclosure Reports" />

          <Box className='whitebx'>
            <Box>
              {/* <Typography variant='h4' className='blue' >Summary </Typography> */}
              <Typography variant='h6' className='black jst'>
                Decimal Point Analytics extracted and managed the scope of 1, 2 and 3 GHG Emission data for ~5,500 Companies, for a leading Climate Management and Accounting Platform organization. The company’s SaaS Platform Enables Clients to Meet Stakeholder and Regulatory Climate Disclosure Requirements.  </Typography>
              <Typography variant='h6' className='black jst'>
                We created structural formatting for their database by combing through volumes of data using a variety of methodologies to find anomalies, structures, and correlations within them and excerpting out valuable insights for them in return. </Typography>
              <Typography variant='h6' className='black jst'>
                Our extracted output assisted the company in finding information fragments that enabled them to solve issues, extrapolate data, plan for the future, make informed decisions, reduce risks, and capitalize on new growth prospects.
              </Typography>
            </Box>
          </Box>



          <Box className='whitebx' mt={5}>
            <Box>
              <Grid container spacing={3} alignItems="top">
                <Grid item lg={4} md={4} sm={4} xs={12}>
                  <Box className='al_center'>
                    <Box className='casestudy_bg' style={{ backgroundImage: 'url(' + Persifoni_Inside1 + ')', }}>

                    </Box>
                  </Box>
                </Grid>

                <Grid item lg={8} md={8} sm={8} xs={12}>
                  <Typography variant='h4' className='blue'>
                    The Challenges
                  </Typography>

                  <Typography variant='h6' className='black jst'>

                    <ul>
                      <li><b>Lack of Extensive Data Coverage Across the Globe:</b> Data Coverage accommodated only a few countries that were into GHG emission, not all of them. Furthermore, the list also included companies that were not involved with GHG emissions, as well. </li>
                    </ul>
                  </Typography>

                  <Typography variant='h6' className='black jst'>
                    <ul>
                      <li><b>Data Scattered Across Multiple Sources:</b> The data was not synchronized. Decimal Point Analytics gathered the relevant set of data from multiple sources and harmonized it. </li>
                    </ul>
                  </Typography>

                  <Typography variant='h6' className='black jst'>
                    <ul>
                      <li><b>Dealing with Gaps, Non-standardized & Inconsistent Data:</b> The data had been pulled out randomly from the web; hence it was inconsistent and non-standardized. </li>
                    </ul>
                  </Typography>

                </Grid>
              </Grid>
            </Box>
          </Box>

          <Box className='whitebx' mt={1}>
            <Box>
              <Typography variant='h4' className='blue' >
                The Solution</Typography>

              <Typography variant='h6' className='black jst'>
                <ul>
                  <li><b>Extracted and Managed Scope 1,2 and 3 GHG Emission Data for ~5,500 Companies:</b> Out of 10,000 companies, it was determined that many companies were not into GHG emission or they were not fully compliant in their reporting. The data was analyzed and a list of 5,500 companies was extracted.</li>
                </ul>
              </Typography>

              <Typography variant='h6' className='black jst'>
                <ul>
                  <li><b>Thorough Quality Checks with Data Validation & SOPs for Quality Assurance:</b> The company’s websites were thoroughly checked for data validation. Only when we were assured about the quality of that source of data, it was validated and listed under the list of the extracted data with its set of predefined categories.</li>
                </ul>
              </Typography>

              <Typography variant='h6' className='black jst'>
                <ul>
                  <li><b>Delivery of a Repository Data File with Quality Output:</b> After rechecking the quality of the data created twice and being sure of the accuracy of the output, the repository file was submitted to the client.</li>
                </ul>
              </Typography>



            </Box>
          </Box>

          <Box className='whitebx' mt={1}>
            <Box>

              <Typography variant='h4' className='blue' > The Approach </Typography>

              <Typography variant='h6' className='black jst'>
                <ul>
                  <li><b>Data Available in Difficult Formats like HTML & Multiple Units:</b>Skimming through the data content was a tedious task as various data available had difficult formats like HTML and multiple units. Hence, the team had to bring in experts to decode the data into understandable formats.</li>
                </ul>
              </Typography>


              <Typography variant='h6' className='black jst'>
                <ul>
                  <li><b>Management of Data in Languages Other than English:</b>As many companies did not have their data in English, the team had to further translate it into English and proceed further with other quality inhibitions.</li>
                </ul>
              </Typography>


              <Typography variant='h6' className='black jst'>
                <ul>
                  <li><b>Data Gaps Due to Lack of Stringent Disclosures Norms in Some Countries and Non-disclosure by Some Companies:</b>The client wanted the entire list of companies who were into GHG emissions. The problem was that some companies had not listed themselves due to the lack of stringent norms in their countries. Therefore, we had to research deeper to find out these hidden companies and include them in our database.</li>
                </ul>
              </Typography>

              <Typography variant='h6' className='black jst'>
                <ul>
                  <li><b>Background Calculations Required for Scope 3 Data as Information is Available in Parts:</b>As the data was available in parts, calculations of background information required us to synchronize Scope-3 data with utter efficiency and accuracy.
                  </li>
                </ul>
              </Typography>

            </Box>
          </Box>

          <Box className='whitebx' mt={1}>
            <Box>
              <Grid container spacing={3} alignItems="top">
                <Grid item lg={8} md={8} sm={8} xs={12}>
                  <Typography variant='h4' className='blue' >
                    The Result</Typography>
                  <Typography variant='h6' className='black jst'>
                    <ul>
                      <li><b>Auditable Data with Source Reference:</b>The final list of data we submitted to the client was with complete source references so that they could back their calculations with proper attestations.
                      </li></ul>
                  </Typography>

                  <Typography variant='h6' className='black jst'>
                    <ul>
                      <li><b>Dedicated Team for Client Support:</b> The team who had worked on the project was clear and thorough with their findings, and in case the client had any difficulties, team DPA were ‘hands-on’ for their support.</li>
                    </ul>
                  </Typography>

                  <Typography variant='h6' className='black jst'>
                    <ul>
                      <li><b>Free up Client’s Resources to Concentrate on Core Activities:</b>We took over their tedious task of handling and bifurcating, which helped our clients in managing other core activities of their business.</li>
                    </ul>
                  </Typography>

                  <Typography variant='h6' className='black jst'>Our technical expertise identified hidden insights from the massive quantum of data and synchronized them for easier analysis. Using our previous experience with successful data management cases, we created a tailored solution that streamlined our client's database so that they could get the required inputs with just a few clicks.</Typography>
                  <Typography variant='h6' className='black jst'> Decimal Point Analytics can help you extract the most from data and your other applications. If you want to know how to make your data work for you, <Link to="/contact-us" className='link'>contact us</Link> today to schedule a consultation call.</Typography>

                </Grid>

                <Grid item lg={4} md={4} sm={4} xs={12}>
                  <Box className='al_center'>
                    <Box className='casestudy_bg' style={{ backgroundImage: 'url(' + Persifoni_Inside2 + ')', }}>

                    </Box>
                  </Box>
                </Grid>


              </Grid>
            </Box>
          </Box>



          <Box className='graybbx' mt={1} >
            <Grid container spacing={1} alignItems="top" >
              <Grid item lg={12} md={12} sm={12} xs={12} >
                <Box className='al_left'>
                  <Typography variant='h4' className='black'>
                    Explore More:
                  </Typography>

                  <Link to='/case-studies/delivering-comprehensive-data-solutions-for-a-leading-financial-data-aggregator-and-provider' className='link'>
                    <Typography variant='h6' className='black jst' style={{ marginBottom: '0px' }}>
                      <b>Case Study:</b> Delivering Comprehensive Data Solutions For A Leading Financial Data Aggregator And Provider.
                    </Typography>
                  </Link>

                  <Link to='/articles/regaining-inr-rightful-position-in-regional-trade' className='link'>
                    <Typography variant='h6' className='black jst'>
                      <b> Insights:</b> Regaining INR's Rightful Position in Regional Trade.
                    </Typography>
                  </Link>

                </Box>
              </Grid>
            </Grid>

            {/* <Box mt={5}>
                        <Subscribe />
                    </Box> */}


          </Box>


          <Casestudyback />




        </Container>
      </Box>


    </>
  );
}

