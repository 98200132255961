import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Helmet } from 'react-helmet';

import Casestudyback from '../../../../Component/Casestudyback'

import Banner from "../../../../asset/images/insight/case_studies/banner_streamlining_operations.webp"
import Infographics from "../../../../asset/images/insight/case_studies/streamlining_oracle_apex.webp"
import { Link } from 'react-router-dom';



export default function StreamliningOperations() {



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet><meta charset="UTF-8" />
                <meta charset="UTF-8" />
                <title>Canada Innovator Enhances Inventory Management Efficiency | Case Study</title>
                <meta name="description" content="Learn how a Canada innovator cut costs and improved inventory management software with low-code application development in our detailed case study." />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/case-studies/streamlining-operations-with-oracle-apex-and-40-cost-reduction-in-inventory-management" />
            </Helmet>

            <Box id="case-studies" className='section sec_bg new_cs' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={3} alignItems="top" justifyContent='center'>
                        <Grid item lg={9} md={9} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Success Stories
                                </Typography>
                                <Typography className='mainhead blue al_center'>
                                    Streamlining Operations <span className='lower'>with</span> Oracle APEX <span className='lower'>and 40%</span> Cost Reduction <span className='lower'>in</span> Inventory Management
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Box >
                        <img src={Banner} className='w100' alt='Banner' />
                    </Box>


                    <Box className='whitebx'>
                        <Box>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Executive Summary
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                A Canada-based innovator specializing in industrial imaging for field machines faced significant challenges in managing their inventory management software and data analysis. By partnering with Decimal Point Analytics, the firm streamlined their front-end development processes, reduced operational costs, and enhanced the scalability of their inventory management system.
                            </Typography>
                        </Box>


                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                The Challenge
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                The firm encountered multiple challenges while managing its extensive portfolio of field machines:
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Prolonged Development Cycles:</strong> Lengthy front-end development timelines hindered the efficiency of inventory management.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Complex Data Management and Visualization: </strong> The management of inventory data across various components and subsystems with limited scalability and adaptability.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> High Coding Demands:  </strong> Each customization and update required extensive coding, complicated maintenance and increasing development time.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Cost Overruns:  </strong> Resource and operational costs related to front-end development consistently exceeded budget forecasts.
                                        </Typography>
                                    </li>

                                </ul>
                            </Typography>
                        </Box>

                        <Box mt={3} className='mob_card'>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Streamlined Inventory Management <span>with</span> Oracle APEX
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                The solutions implemented included:
                            </Typography>

                            <Grid container direction="row"
                                justifyContent="center"
                                alignItems="center">

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className="al_center" my={3}>
                                        <img src={Infographics} className="topbanner" alt="Streamlined Inventory Management with Oracle APEX " />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box mt={1}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Results
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                The collaboration with Decimal Point Analytics led to:
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Faster Development Cycles:  </strong> Achieved a 40% reduction in portal development time, enhancing overall efficiency.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Reduced Development Costs:  </strong> Optimized resource allocation resulted in a 40% decrease in development costs and complexity.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Improved Inventory Tracking:   </strong> Advanced data management and visualization improved inventory tracking and decision-making processes.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Enhanced Operational Oversight:   </strong> Detailed audit trials provided comprehensive oversight of the inventory management process.
                                        </Typography>
                                    </li>

                                </ul>
                            </Typography>
                        </Box>

                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Key Takeaways
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Accelerated Development: </strong> Streamlined front-end processes enabled quicker deployment and updates.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Cost Efficiency:  </strong>  Significant reductions in development costs improved the firm’s operational budget.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Enhanced Scalability: </strong> The new system allowed for adaptive and scalable inventory management.
                                        </Typography>
                                    </li>
                                </ul>
                                <Typography variant='h6' className='black jst'>
                                    Learn more about their journey to streamlined operations and enhanced efficiency in our detailed case study!
                                </Typography>

                            </Typography>

                            <Typography variant='h6' className='black jst' gutterBottom>
                                <Link to="/contact-us#contact_form" className="link">Contact us</Link> to discuss how we can help your organization achieve similar results!
                            </Typography>

                        </Box>

                    </Box>


                    <Casestudyback />

                </Container>
            </Box>


        </>
    );
}