import { Divider, Container, Typography, Box, Grid, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Helmet } from 'react-helmet';

import Insightback from '../../../Component/Insightback'

import Writemessage3 from '../../../Component/Writemessage3';


export default function India_on_currency() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <h1>Leveraging India's Position on the USA's Currency Watchlist for Advantage" </h1>
                <title>How Can India Benefit From Being On The Currency Watchlist Of USA</title>
                <meta name="description" content="Explore how India can leverage its status on the USA currency watchlist for economic advantages and stronger international partnerships." />
                <meta name="keywords" content="dollar to inr
usd to inr
foreign exchange rates
currency market
market india
indian currency
exchange rate india" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/articles/how-can-india-benefit-from-being-on-the-currency-watchlist-of-usa" />

                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>

            <Box id="article-page" className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center' >
                        <Grid item lg={10} md={12} sm={12} xs={12}>
                            <Typography variant='h3' className='subhead skblue al_center'>
                                Insight
                            </Typography>
                            <Typography variant='h1' className='blue mainhead al_center'>
                                How can India benefit from being on the currency watchlist of USA?
                            </Typography>
                        </Grid>
                    </Grid>

                    <Box className='whitebx'>

                        <Typography variant='h6' className='black jst'>Last week India made a surprising entry in the currency watchlist of USA, as a potential currency manipulator. Although, many in India rightly feel that the charge levied is unfair; this unexpected development presents India with an unprecedented opportunity to correct historical imbalances in its trade using two unique approaches. These two approaches will help India extinguish its trade surplus with the USA and also aid India’s desire to grow rapidly simultaneously. </Typography>

                        <Typography variant='h6' className='black jst'>Firstly, India is a very large importer of crude oil and the imports are increasing rapidly to support its fast-growing domestic economy. Unfortunately, historically India’s oil imports made her critically dependent on certain nations where the strategic security interests of India did not match with her trade requirement. On the other hand, USA is experiencing a boom in its crude oil production, with daily production rising by around a million barrels in last twelve months; and the pace of increase in production is most likely will be maintained due to the position of DUC wells. If India determines that it should import a million barrels a day from the USA in next twelve months, the India-USA trade will flip to be in a surplus for the USA rather than from being in a deficit. This will also help India diversify its sources and lower its import costs as the WTI trades at a significant discount to Brent. Of course, this will entail significant investment in refinery modifications to ingest the light sweet crude, however, those investments would be justified on the grounds of diversification, strategic interests and aligning to future supply sources. </Typography>

                        <Typography variant='h6' className='black jst'>Secondly, US has put a certain restriction on export of high tech items to India. Although in Jan 2017 those restrictions were relaxed a wee bit, the procedure is still cumbersome and the delays are significant – for example, although the original understanding was reached in 2010, a part of that understanding was implemented for the first time in 2017. Hence, it would not be out of place for India to point to the USA that India wants to import more from the USA to support its own ambitions but the USA is putting export barriers, hurting job creation in the USA. A quick resolution and removal of an export ban in its entirety will help USA jobs and Indian growth simultaneously. </Typography>


                        <Divider />


                        {/* <Box>
                            <Subscribe />

                        </Box> */}
                    </Box>

                    <Box className='pages'>
                        <Writemessage3 pageVisited="How can India benefit from being on the currency watchlist of USA" />
                    </Box>

                    <Insightback />


                </Container>
            </Box>


        </>
    );
}
