import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Box, Grid, Container, } from '@material-ui/core';


import FAQ from "../asset/images/product/faq.webp";


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));


export default function DataExtractionFAQ() {



  const classes = useStyles();
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>


      <Container maxWidth='lg' className='bdr'>


        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
          <Grid item lg={4} md={4} sm={2} xs={12}>
            <Box className='faq-image' mt={1}>
              <img src={FAQ} alt='Frequently Asked Questions' className='topbanner2' />
            </Box>
          </Grid>

          <Grid item lg={8} md={8} sm={10} xs={12}>
            <Box id="faq-accordian" className={classes.root} mt={1}>
              <Box>

                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography variant="h4" className='accordian_head'> What is data extraction in ETL? </Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Typography variant="h6" className="black accordian_content">
                      Data extraction in <strong>ETL</strong> involves retrieving data from various sources, which is then cleaned, transformed, and loaded into a central repository for analysis.
                    </Typography>
                  </AccordionDetails>
                </Accordion>


                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                  >
                    <Typography variant="h4" className='accordian_head'> How does DPA ensure the accuracy of extracted data? </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="h6" className="black accordian_content">
                      We use advanced algorithms that not only extract data but also validate and cross-verify to ensure the highest accuracy.
                    </Typography>
                  </AccordionDetails>
                </Accordion>


                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                  >
                    <Typography variant="h4" className='accordian_head'> Can DPA handle data extraction from highly complex documents? </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="h6" className="black accordian_content">
                      Yes, our solutions are designed to handle a wide range of documents, including those with complex layouts and multiple data formats.
                    </Typography>
                  </AccordionDetails>
                </Accordion>


              </Box>
            </Box>
          </Grid>
        </Grid>




      </Container>




    </>


  );
}
