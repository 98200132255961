import { Box, Button, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

export const Pentagon = () => {
  return (
    <>
      <Box classNameName="height"> 
        <section className="mil-banner mil-dark-bg">
          <Box className="mi-invert-fix">
            <Box className="mil-animation-frame">
              <Box
                className="mil-animation mil-position-1 mil-scale"
                style={{
                  transform: 'translate3d(0px, 0px, 0px) scale(2.6353)'
                }}
                data-value-1="7"
                data-value-2="1.6"
              >
                <Box className="mil-dodecahedron">
                  <Box className="mil-pentagon">
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                  </Box>
                  <Box className="mil-pentagon">
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                  </Box>
                  <Box className="mil-pentagon">
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                  </Box>
                  <Box className="mil-pentagon">
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                  </Box>
                  <Box className="mil-pentagon">
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                  </Box>
                  <Box className="mil-pentagon">
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                  </Box>
                  <Box className="mil-pentagon">
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                  </Box>
                  <Box className="mil-pentagon">
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                  </Box>
                  <Box className="mil-pentagon">
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                  </Box>
                  <Box className="mil-pentagon">
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                  </Box>
                  <Box className="mil-pentagon">
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                  </Box>
                  <Box className="mil-pentagon">
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                  </Box>
                </Box>
              </Box>
              <Box
                className="mil-animation mil-position-2 mil-scale"
                style={{
                  transform: 'translate3d(0px, 0px, 0px) scale(1.4953)'
                }}
                data-value-1="6"
                data-value-2="1.5"
              >
                <Box className="mil-dodecahedron">
                  <Box className="mil-pentagon">
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                  </Box>
                  <Box className="mil-pentagon">
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                  </Box>
                  <Box className="mil-pentagon">
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                  </Box>
                  <Box className="mil-pentagon">
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                  </Box>
                  <Box className="mil-pentagon">
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                  </Box>
                  <Box className="mil-pentagon">
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                  </Box>
                  <Box className="mil-pentagon">
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                  </Box>
                  <Box className="mil-pentagon">
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                  </Box>
                  <Box className="mil-pentagon">
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                  </Box>
                  <Box className="mil-pentagon">
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                  </Box>
                  <Box className="mil-pentagon">
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                  </Box>
                  <Box className="mil-pentagon">
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                  </Box>
                </Box>
              </Box>
              <Box
                className="mil-animation mil-position-3 mil-scale"
                style={{
                  transform: 'translate3d(0px, 0px, 0px) scale(0.96994)'
                }}
                data-value-1="1.9"
                data-value-2=".1"
              >
                <Box className="mil-dodecahedron">
                  <Box className="mil-pentagon">
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                  </Box>
                  <Box className="mil-pentagon">
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                  </Box>
                  <Box className="mil-pentagon">
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                  </Box>
                  <Box className="mil-pentagon">
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                  </Box>
                  <Box className="mil-pentagon">
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                  </Box>
                  <Box className="mil-pentagon">
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                  </Box>
                  <Box className="mil-pentagon">
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                  </Box>
                  <Box className="mil-pentagon">
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                  </Box>
                  <Box className="mil-pentagon">
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                  </Box>
                  <Box className="mil-pentagon">
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                  </Box>
                  <Box className="mil-pentagon">
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                  </Box>
                  <Box className="mil-pentagon">
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                    <Box></Box>
                  </Box>
                </Box>
              </Box>

            </Box>

            <Box className="landingbannerbg1">
              <Container maxWidth="lg">
                <Grid container spacing={1} alignItems="center" justifyContent='center'>
                  <Grid item lg={12} md={12} sm={11} xs={10}>
                  <Box className="text_animated">
                      <Typography variant="h2" className="blue fw6 mb0" gutterBottom>
                        Generative AI:
                      </Typography>

                      <Box>
                        <Typography variant="h2" className="black3" gutterBottom>
                          Empower Your Enterprise with Data-Driven Decisions
                        </Typography>
                      </Box>

                      <Box mt={2}>
                        <Link to="/artificial-intelligence-solutions">
                          <Button variant="contained" className='dpabtn_sm1'>
                            Learn More
                          </Button>
                        </Link>
                      </Box>

                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Box>


          </Box>
        </section>
      </Box>
    </>
  )
}

export default Pentagon