import { forwardRef } from 'react'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
const useStyles = makeStyles(theme => ({
  input: {
    backgroundColor: '#fff'
  }
}))

const PhoneInput = (props, ref) => {
  const classes = useStyles()
  return (

    <TextField
      id="filled-basic1"
      {...props}
      InputProps={{
        className: classes.input
      }}
      inputRef={ref}
      inputProps={{ maxLength: "18" }}
      fullWidth
      label="Phone Number"
      size='small'
      variant="outlined"
      name='phone'
    />
  )
}
export default forwardRef(PhoneInput)