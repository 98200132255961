import { Container, Grid, Typography, Box, } from "@material-ui/core";
import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import NOV_2024 from "../../asset/images/newsletter/nov_2024.webp"
import OCT_2024 from "../../asset/images/newsletter/oct_2024.webp"
import SEP_2024 from "../../asset/images/newsletter/sep_2024.webp"
import AUG_2024 from "../../asset/images/newsletter/aug_2024.webp"
import JUL_2024 from "../../asset/images/newsletter/jul_2024.webp"
import JUNE_2024 from "../../asset/images/newsletter/june_2024.webp"
import MAY_2024 from "../../asset/images/newsletter/may_2024.webp"
import APR_2024 from "../../asset/images/newsletter/apr_2024.webp"
import MAR_2024 from "../../asset/images/newsletter/mar_2024.webp"
import FEB_2024 from "../../asset/images/newsletter/feb_2024.webp"
import JAN_2024 from "../../asset/images/newsletter/jan_2024.webp"

import DEC from "../../asset/images/newsletter/dec_2023.webp"
import NOV from "../../asset/images/newsletter/nov_2023.webp"
import OCT from "../../asset/images/newsletter/oct_2023.webp"
import SEP from "../../asset/images/newsletter/sep_2023.webp"
import AUG from "../../asset/images/newsletter/aug_2023.webp";
import JUL from "../../asset/images/newsletter/july_2023.webp";
import JUN from "../../asset/images/newsletter/jun_2023.webp";
import MAY from "../../asset/images/newsletter/may_2023.webp";
import APR from "../../asset/images/newsletter/apr_2023.webp";
import MAR from "../../asset/images/newsletter/mar_2023.webp";
import FEB from "../../asset/images/newsletter/feb_2023.webp";
import JAN from "../../asset/images/newsletter/jan_2023.webp";
import DEC_2022 from "../../asset/images/newsletter/dec_2022.webp";
import NOV_2022 from "../../asset/images/newsletter/nov_2022.webp";
import OCT_2022 from "../../asset/images/newsletter/oct_2022.webp";
import SEP_2022 from "../../asset/images/newsletter/sep_2022.webp";

import { Helmet } from "react-helmet";
import Subscribe from "../../Component/Subscribe";
import { Link } from 'react-router-dom';

export default function NewsletterNew() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <>


      <Helmet>
        <meta charset="UTF-8" />
        <title>Newsletter Updates | Decimal Point Analytics</title>
        <meta name="description" content="Read the latest newsletter from Decimal Point Analytics. Get valuable insights into the world of business and technology. " />
        <meta name="keywords" content="ESG Disclosures, Data Science, AI, Banking Operations, Financial Ratings Firm, Data management, Research" />
        <meta name="author" content="Newsletter by Decimal Point Analytics" />
        <link rel="canonical" href="https://www.decimalpointanalytics.com/newsletter" />
      </Helmet>


      <Box
        id="newsletter"
        className="section sec_bg"
        component="section"
        style={{ paddingTop: "3%" }}
      >
        <Container maxWidth="lg" className="bdr">
          <Grid container spacing={1} alignItems="top">
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box className="al_center" mt={5}>
                <Typography variant="h1" className="black mb0 newh1">
                  Newsletter
                </Typography>
                <Typography variant="h6" className="black mb0">
                  Stay upto date about the latest happenings in the Financial Industry.
                </Typography>
                <Box mt={'-10px'} mb={1}>
                  <Subscribe />
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Box className="reportinfo">
            <Grid container spacing={2} alignItems="top">

            <Grid item lg={4} md={4} sm={6} xs={12}>
                <Link to="/newsletter/monthly-newsletter-nov-2024.html" target="_blank" rel="noopener noreferrer">
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + NOV_2024 + ")" }}
                    ></Box>

                    <Box mt={2} mb={1}>
                      <Typography className="reporttitle one">
                        Newsletter | November, 2024
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </Link>
              </Grid>
              {/* --------------- */}

            <Grid item lg={4} md={4} sm={6} xs={12}>
                <Link to="/newsletter/monthly-newsletter-oct-2024.html" target="_blank" rel="noopener noreferrer">
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + OCT_2024 + ")" }}
                    ></Box>

                    <Box mt={2} mb={1}>
                      <Typography className="reporttitle one">
                        Newsletter | October, 2024
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </Link>
              </Grid>
              {/* --------------- */}

            <Grid item lg={4} md={4} sm={6} xs={12}>
                <Link to="/newsletter/monthly-newsletter-sep-2024.html" target="_blank" rel="noopener noreferrer">
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + SEP_2024 + ")" }}
                    ></Box>

                    <Box mt={2} mb={1}>
                      <Typography className="reporttitle one">
                        Newsletter | September, 2024
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </Link>
              </Grid>
              {/* --------------- */}

            <Grid item lg={4} md={4} sm={6} xs={12}>
                <Link to="/newsletter/monthly-newsletter-aug-2024.html" target="_blank" rel="noopener noreferrer">
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + AUG_2024 + ")" }}
                    ></Box>

                    <Box mt={2} mb={1}>
                      <Typography className="reporttitle one">
                        Newsletter | August, 2024
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </Link>
              </Grid>
              {/* --------------- */}

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Link to="/newsletter/monthly-newsletter-jul-2024.html" target="_blank" rel="noopener noreferrer">
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + JUL_2024 + ")" }}
                    ></Box>

                    <Box mt={2} mb={1}>
                      <Typography className="reporttitle one">
                        Newsletter | July, 2024
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </Link>
              </Grid>
              {/* --------------- */}

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Link to="/newsletter/monthly-newsletter-jun-2024.html" target="_blank" rel="noopener noreferrer">
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + JUNE_2024 + ")" }}
                    ></Box>

                    <Box mt={2} mb={1}>
                      <Typography className="reporttitle one">
                        Newsletter | June, 2024
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </Link>
              </Grid>
              {/* --------------- */}

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Link to="/newsletter/monthly-newsletter-may-2024.html" target="_blank" rel="noopener noreferrer">
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + MAY_2024 + ")" }}
                    ></Box>

                    <Box mt={2} mb={1}>
                      <Typography className="reporttitle one">
                        Newsletter | May, 2024
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </Link>
              </Grid>
              {/* --------------- */}

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Link to="/newsletter/monthly-newsletter-apr-2024.html" target="_blank" rel="noopener noreferrer">
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + APR_2024 + ")" }}
                    ></Box>

                    <Box mt={2} mb={1}>
                      <Typography className="reporttitle one">
                        Newsletter | April, 2024
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </Link>
              </Grid>
              {/* --------------- */}

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Link to="/newsletter/monthly-newsletter-mar-2024.html" target="_blank" rel="noopener noreferrer">
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + MAR_2024 + ")" }}
                    ></Box>

                    <Box mt={2} mb={1}>
                      <Typography className="reporttitle one">
                        Newsletter | March, 2024
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </Link>
              </Grid>
              {/* --------------- */}

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Link to="/newsletter/monthly-newsletter-feb-2024.html" target="_blank" rel="noopener noreferrer">
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + FEB_2024 + ")" }}
                    ></Box>

                    <Box mt={2} mb={1}>
                      <Typography className="reporttitle one">
                        Newsletter | February, 2024
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </Link>
              </Grid>
              {/* --------------- */}

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Link to="/newsletter/monthly-newsletter-jan-2024.html" target="_blank" rel="noopener noreferrer">
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + JAN_2024 + ")" }}
                    ></Box>

                    <Box mt={2} mb={1}>
                      <Typography className="reporttitle one">
                        Newsletter | January, 2024
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </Link>
              </Grid>
              {/* --------------- */}

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Link to="/newsletter/monthly-newsletter-dec-2023.html" target="_blank" rel="noopener noreferrer">
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + DEC + ")" }}
                    ></Box>

                    <Box mt={2} mb={1}>
                      <Typography className="reporttitle one">
                        Newsletter | December, 2023
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </Link>
              </Grid>
              {/* --------------- */}

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Link to="/newsletter/monthly-newsletter-nov-2023.html" target="_blank" rel="noopener noreferrer">
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + NOV + ")" }}
                    ></Box>

                    <Box mt={2} mb={1}>
                      <Typography className="reporttitle one">
                        Newsletter | November, 2023
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </Link>
              </Grid>
              {/* --------------- */}

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Link to="/newsletter/monthly-newsletter-oct-2023.html" target="_blank" rel="noopener noreferrer">
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + OCT + ")" }}
                    ></Box>

                    <Box mt={2} mb={1}>
                      <Typography className="reporttitle one">
                        Newsletter | October, 2023
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </Link>
              </Grid>
              {/* --------------- */}

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Link to="/newsletter/monthly-newsletter-sep-2023.html" target="_blank" rel="noopener noreferrer">
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + SEP + ")" }}
                    ></Box>

                    <Box mt={2} mb={1}>
                      <Typography className="reporttitle one">
                        Newsletter | September, 2023
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </Link>
              </Grid>
              {/* --------------- */}

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Link to="/newsletter/monthly-newsletter-aug-2023.html" target="_blank" rel="noopener noreferrer">
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + AUG + ")" }}
                    ></Box>

                    <Box mt={2} mb={1}>
                      <Typography className="reporttitle one">
                        Newsletter | August, 2023
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </Link>
              </Grid>
              {/* --------------- */}


              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Link to="/newsletter/monthly-newsletter-jul-2023.html" target="_blank" rel="noopener noreferrer">
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + JUL + ")" }}
                    ></Box>

                    <Box mt={2} mb={1}>
                      <Typography className="reporttitle one">
                        Newsletter | July, 2023
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </Link>
              </Grid>
              {/* --------------- */}


              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Link to="/newsletter/monthly-newsletter-jun-2023.html" target="_blank" rel="noopener noreferrer">
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + JUN + ")" }}
                    ></Box>

                    <Box mt={2} mb={1}>
                      <Typography className="reporttitle one">
                        Newsletter | June, 2023
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </Link>
              </Grid>
              {/* --------------- */}


              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Link to="/newsletter/monthly-newsletter-may-2023.html" target="_blank" rel="noopener noreferrer">
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + MAY + ")" }}
                    ></Box>

                    <Box mt={2} mb={1}>
                      <Typography className="reporttitle one">
                        Newsletter | May, 2023
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </Link>
              </Grid>
              {/* --------------- */}


              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Link to="/newsletter/monthly-newsletter-apr-2023.html" target="_blank" rel="noopener noreferrer">
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + APR + ")" }}
                    ></Box>

                    <Box mt={2} mb={1}>
                      <Typography className="reporttitle one">
                        Newsletter | April, 2023
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </Link>
              </Grid>
              {/* --------------- */}



              {/* -------------------------------- */}

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Link to="/newsletter/monthly-newsletter-mar-2023.html" target="_blank" rel="noopener noreferrer">
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + MAR + ")" }}
                    ></Box>

                    <Box mt={2} mb={1}>
                      <Typography className="reporttitle one">
                        Newsletter | March, 2023
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </Link>
              </Grid>

              {/* -------------------------------- */}

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Link to="/newsletter/monthly-newsletter-feb-2023.html" target="_blank" rel="noopener noreferrer">
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + FEB + ")" }}
                    ></Box>

                    <Box mt={2} mb={1}>
                      <Typography className="reporttitle one">
                        Newsletter | February, 2023
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </Link>
              </Grid>


              {/* -------------------------------- */}


              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Link to="/newsletter/monthly-newsletter-jan-2023.html" target="_blank" rel="noopener noreferrer">
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + JAN + ")" }}
                    ></Box>

                    <Box mt={2} mb={1}>
                      <Typography className="reporttitle one">
                        Newsletter | January, 2023
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </Link>
              </Grid>


              {/* -------------------------------- */}



              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Link to="/newsletter/monthly-newsletter-dec-2022.html" target="_blank" rel="noopener noreferrer">
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + DEC_2022 + ")" }}
                    ></Box>

                    <Box mt={2} mb={1}>
                      <Typography className="reporttitle one">
                        Newsletter | December, 2022
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </Link>
              </Grid>
              {/* --------------- */}




              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Link to="/newsletter/monthly-newsletter-nov-2022.html" target="_blank" rel="noopener noreferrer">
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + NOV_2022 + ")" }}
                    ></Box>

                    <Box mt={2} mb={1}>
                      <Typography className="reporttitle one">
                        Newsletter | November, 2022
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </Link>
              </Grid>
              {/* --------------- */}



              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Link to="/newsletter/monthly-newsletter-oct-2022.html" target="_blank" rel="noopener noreferrer">
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + OCT_2022 + ")" }}
                    ></Box>

                    <Box mt={2} mb={1}>
                      <Typography className="reporttitle one">
                        Newsletter | October, 2022
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </Link>
              </Grid>
              {/* --------------- */}



              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Link to="/newsletter/monthly-newsletter-sep-2022.html" target="_blank" rel="noopener noreferrer">
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + SEP_2022 + ")" }}
                    ></Box>

                    <Box mt={2} mb={1}>
                      <Typography className="reporttitle one">
                        Newsletter | September, 2022
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </Link>
              </Grid>
              {/* --------------- */}


            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
}
