import { Container, Typography, Box, Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import Writemessage3 from '../../../Component/Writemessage3';
import { Helmet } from 'react-helmet';
import EmployeeProfile from '../../../Component/EmployeeProfile';
import RS from '../../../asset/images/team/ramesh_s.webp';
import SBAback from '../../../Component/SBAback';



export default function BuildingProcessEfficiency() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>


            <Helmet>
                <meta charset="UTF-8" />
                <h1>Building process efficiency for US Lenders to offer ERC/SBA loans </h1>
                <title>Building process efficiency for US Lenders to offer ERC/SBA loans</title>
                <meta name="description" content="Explore how US lenders can improve process efficiency for ERC/SBA loans. Optimize workflows with Decimal Point Analytics' expertise." />
                <meta name="keywords" content="building process efficiency, US lenders, ERC loans, SBA loans, COVID-19 pandemic, loan approval process, automation, analytical resources, Decimal Point Analytics, market presence, financial support, loan application volumes, workflow optimization." />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/small-business-administration/building-process-efficiency" />

                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>



            <Box id="blog-page" className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Typography variant='h3' className='subhead skblue al_center'>
                                Blog
                            </Typography>
                            <Typography variant='h1' className='blue mainhead al_center mb0'>
                                Building process efficiency for US Lenders  to offer ERC/SBA loans
                            </Typography>
                            <Typography variant='h4' className='blue al_center'>
                                February 24, 2023
                            </Typography>
                        </Grid>
                    </Grid>

                    <Box className='whitebx'>



                        <Typography variant='h6' className='black jst' > The COVID-19 pandemic has been a game-changer on many fronts. It has led to a transformation in lifestyles across the globe, a shift in mindsets towards health awareness, and a new working style across industries. Work from home (WFH) has become the norm during this period, signaling a strong shift in employer-employee dynamics, along with the intervening period of the ‘Great Resignation’. Small and medium businesses that were dependent on face-to-face customer connects were decimated; the ones that survived had an online presence or were able to create one in a short period of time. </Typography>

                        <Typography variant='h6' className='black jst' >  In the midst of this turmoil, the US government released the ERC (Employee Retention Credit) loans and SBA (Small Business Administration) loans programs, which were formalized under the Coronavirus Aid, Relief, and Economic Security Act or the CARES Act (2020), and later modified under the 2021 and 2022 legislation. This Act aimed to support businesses that were having a hard time due to the pandemic, and were unable to meet their payroll costs.  </Typography>



                        <Typography variant='h6' className='black jst' > ERC loans were intended to support businesses that were able to demonstrate an adverse impact on their functioning from the first quarter of 2020 onwards, either due to lockdown restrictions or due to federal or state government orders restraining the normal functioning of their businesses. Companies that continued with their headcount during this period of uncertainty and were meeting their payroll commitments on a quarter-by-quarter basis were eligible for the ERC loan.</Typography>




                        <Typography variant='h6' className='black jst'> In order to make the ERC loan process effective and extend reach to a large number of impacted businesses, the US government awarded lending licenses to non-banking and banking entities within a strict framework of defined parameters for them to provide these loans. Lenders with an online process benefited from being able to source applications from many businesses that were spread across North America. The next challenge was for them to service these loan applications and revert to loan applicants with a decision in a time-bound manner with a “Yes” or “No” decision.   </Typography>



                        <Typography variant='h6' className='black jst' > SBA loans are a well-thought out program with a vast reach through their local chapters in the US.  Many of these loans are offered with the option of using an empaneled mentor, who has multiple years of business experience and can provide effective advice to the business entrepreneur. A similar concept is well-embedded in Canada’s Future-preneur program, where using a mentor is mandatory in their scheme of things.  </Typography>


                        <Typography variant='h6' className='black jst' > Under the CARES Act, SBA loans in the USA have been focused on supporting businesses that provide employment, where the US government is willing to guarantee upto 75% of the loan amount in case of a default or bankruptcy filing by the borrower. This opens up the field for multiple banking and non-banking lenders that have a huge potential market to service, who end up lending under this program with the backup of the US federal government, subject to certain terms and conditions of the underlying loan (size, business sector, past credit history, liquidity profile etc.).    </Typography>

                        <Typography variant='h6' className='black jst' >  Since the ERC loans directly impact employee salaries, lenders need to make the application process as simple and easy as possible, make applications available online, and respond very quickly on the loan approval status. Automation of these processes enables lenders to optimize their reach and speed up TAT, in line with that required by businesses. </Typography>


                        <Typography variant='h6' className='black jst' > Many of these lenders have reached out to Decimal Point Analytics (DPA), to arrive at two possible options for them to build a sustainable business model:
                            <ul>
                                <li> <Typography variant='h6' className='black jst' > Decimal Point Analytics to provide a new technology solution that automates the workflow process defined to capture structured information provided by potential borrowers, evaluate the information based on the internal screening criteria of the lender, and arrive at an in-principle “Go / No Go” decision, then communicate the same to the borrowers. In case of a “Go” decision, the workflow is to be configured in line with the internal requirements of loan underwriting, including online approvals from credit approvers and an audit trail to track all changes being made to the loan documents at various stages in the approval process. The final loan approval letter will be generated online in a secure environment using the most up to date tools and technologies. </Typography>
                                </li>

                                <li> <Typography variant='h6' className='black jst' > Decimal Point Analytics to work within the constraints of the existing technology portal already available to the lender and provide trained analytical resources based out of India who would capture the credit scores of the borrower and financial information of the business in a defined format to generate a credit memo. The credit memo would then be used by the lender to generate a final credit approval letter (with terms and conditions) which is to be shared with the borrower. Based on lender comfort, DPA could be used only for initial screening, or additionally for credit memo collation and/or loan underwriting process or post-closure activities. DPA would be bound by SLAs with respect to timeliness and quality standards, as well as productivity norms, similar to the onshore analytical resources that a lender would be using in a typical business process. </Typography>
                                </li>
                            </ul>
                        </Typography>


                        <Typography variant='h6' className='black jst' >
                            For many of the lenders who disburse ERC (Employee Retention Credit) and SBA (Small Business Administration) loans, the benefits of using Decimal Point Analytics (DPA) are compelling.
                        </Typography>

                        <Typography variant='h6' className='black jst' >
                            DPA leverages remote working practices which have been well tested and tried out in COVID times. The time sensitivity of this process makes the time differential between the US and Indian markets attractive, leading to faster turnaround times in competitive US markets. The is substantial confidence in adherence to internal credit guidelines that need to be followed by the offshore DPA team in India. Additionally, lenders continue to maintain full control on the analytical output through periodic calls and feedback sessions with the analysts as well as the top management at DPA.
                        </Typography>

                        <Typography variant='h6' className='black jst' >
                            The end result is a cost-effective solution that can significantly enhance the market presence of active US lenders, who are currently deluged with large ERC and SBA loan application volumes from across various US geographies, and who may not have the analytical resources to respond within acceptable timelines.
                        </Typography>



                        <Box>
                            <Box mt={1}>
                                <EmployeeProfile imgSrc={RS} name={"Mr. Ramesh Seshadri"} designation={"EVP, Data Science"} company={"Decimal Point Analytics Pvt. Ltd."} linkedinUrl={"https://www.linkedin.com/in/ramesh-ps-2100287"} />
                            </Box>
                        </Box>



                    </Box>

                    <Box className='pages'>
                        <Writemessage3 pageVisited="Building process efficiency for US Lenders to offer ERC/SBA loans" />
                    </Box>

                    <SBAback />


                </Container>
            </Box>


        </>
    );
}
