import {  Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Eventback from '../../../Component/Eventback'
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';


export default function DPAatALTSNY() {


    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>Decimal Point Analytics at ALTSNY June 2024: Driving Innovation in Alternative Investments</title>
                <meta name="description" content="Nilanjan Das represented Decimal Point Analytics at ALTSNY June 2024, discussing key trends in alternative investments. Explore our data-driven solutions to optimize your investment strategies." />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/decimal-point-analytics-at-altsny-june-2024" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>



            <Box id='event-details' className='section sec_bg' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Events
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center'>
                                    Decimal Point Analytics at ALTSNY June 2024
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>

                    <Box className='whitebx'>

                        <Typography variant='h6' className='black jst'>
                            <strong>Nilanjan Das</strong>, our Executive Vice President of Business Development, recently represented <strong>Decimal Point Analytics at ALTSNY June 2024</strong>. This premier alternative investment conference brought together leaders from across the investor community to explore key trends and challenges shaping the future of the industry.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            During the event, Nilanjan engaged in valuable discussions with industry experts, highlighting how Decimal Point Analytics' data-driven solutions can support and optimize alternative investment strategies. Our participation at <strong>ALTSNY</strong> reflected our commitment to driving innovation in the alternative investment space.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            To learn more about how <strong>Decimal Point Analytics</strong> can help you achieve your investment goals, <Link to="/data-solution-in-us" className='link'>explore our offerings here</Link>.
                        </Typography>

                    </Box>

                    <Eventback />

                </Container>
            </Box>



        </>
    );
}
