import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import Eventback from '../../../Component/Eventback'
import YoutubeEmbed from '../../../Component/YoutubeEmbed';
import { Helmet } from 'react-helmet';

export default function Event_30_Nov() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet>
                <title>Convergence of AI & IoT for Safer and Equal Society</title>
                <meta name="description" content="Discover insights from Decimal Point Analytics CEO, Shailesh Dhuri, on the convergence of AI and IoT for safer and equal society." />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/convergence-of-ai-and-iot" />
            </Helmet>

            <Box id='event-details' className='section sec_bg' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Events
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center mb0'>
                                    Convergence of AI & IoT for a safer and equal society
                                </Typography>
                                <Typography className=' mainsmhead black al_center mb0'>
                                    Nov, 2022
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>





                    <Box className='whitebx'>


                        <Typography variant='h6' className='black jst'>
                            Our CEO Shailesh Dhuri was a guest speaker at IIM Kashipur’s “Tejas the leadership talk” series. He spoke about the importance of the “Convergence of AI and IoT for a safer and equal society.” He presented some interesting insights on how IoT is the next big evolution in artificial intelligence to help society in numerous ways. He shared his analysis of the application of IoT in various use cases where it could be beneficial to different industrial sectors.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            Check out the whole video to know more.
                        </Typography>
                        <br></br>


                        <Box className='al_center'>
                            <YoutubeEmbed embedId="n__M0Kx9CNo" />
                            <br></br>
                            <br></br>
                            <br></br>
                        </Box>


                    </Box>




                    <Eventback />




                </Container>
            </Box>






        </>
    );
}
