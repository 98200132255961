import { Container, Grid, Typography, Box, Tabs, Tab, AppBar } from "@material-ui/core";
import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import PropTypes from "prop-types";
import Media_Coverage_data from "./MediaCoverage/mediaCoverage_data";
import Design from "./MediaCoverage/mediaCoverage_design";


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
 

export default function MediaCoverage() {

    // main tabs
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        window.scrollTo(0, 0);
        setValue(newValue);
    };

    // sub tabs
    const [valuesub, setValuesub] = React.useState(2024);

    const handleChangesub = (event, newValuesub) => {
        window.scrollTo(0, 0);
        setValuesub(newValuesub);
    };



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);



    const year2024 = () => {
        return Media_Coverage_data.filter(item => item.year === 2024);
    };
    const year2023 = () => {
        return Media_Coverage_data.filter(item => item.year === 2023);
    };
    const year2022 = () => {
        return Media_Coverage_data.filter(item => item.year === 2022);
    };
    const year2021 = () => {
        return Media_Coverage_data.filter(item => item.year === 2021);
    };

    const data2024 = year2024();
    const data2023 = year2023();
    const data2022 = year2022();
    const data2021 = year2021();

    return (
        <>



            <Box className="maintabbedsection stiky linkedtabs">
                <Container maxWidth="lg" className="bdr">
                    <Grid container spacing={1} alignItems="top" justifyContent="center">
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <AppBar position="static" className="tabbedbar dgrid_center">
                                <Tabs value={value} onChange={handleChange} variant="scrollable">
                                    {/* <Tab label="Media Coverage" {...a11yProps(0)} />
                                    <Tab label="Press Release" {...a11yProps(1)} /> */}
                                    <Tab
                                        label="Media Coverage"
                                        component={Link}
                                        to="/newsroom/media-coverage"
                                        value={0}
                                    />
                                    <Tab
                                        label="Press Release"
                                        component={Link}
                                        to="/newsroom/press-release"
                                        value={1}
                                    />
                                </Tabs>
                            </AppBar>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <TabPanel value={value} index={0}>
                <Box id="newsroom" className="maintabbedsection stiky linkedtabs">
                    <Container maxWidth="lg" className="bdr">
                        <Grid container spacing={1} alignItems="top" justifyContent="center">
                            <Grid item lg={10} md={10} sm={12} xs={12}>
                                <AppBar position="relative" className="tabbedbar dgrid_center">
                                    <Tabs value={valuesub} onChange={handleChangesub} variant="scrollable">
                                        {/* <Tab label="2024" {...a11yProps(2024)} />
                                        <Tab label="2023" {...a11yProps(2023)} />
                                        <Tab label="2022" {...a11yProps(2022)} />
                                        <Tab label="2021" {...a11yProps(2021)} /> */}
                                        <Tab
                                            label="2024"
                                            component={Link}
                                            to="/newsroom/media-coverage"
                                            value={2024}
                                        />
                                        <Tab
                                            label="2023"
                                            component={Link}
                                            to="/newsroom/media-coverage"
                                            value={2023}
                                        />
                                        <Tab
                                            label="2022"
                                            component={Link}
                                            to="/newsroom/media-coverage"
                                            value={2022}
                                        />
                                        <Tab
                                            label="2021"
                                            component={Link}
                                            to="/newsroom/media-coverage"
                                            value={2021}
                                        />
                                    </Tabs>
                                </AppBar>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>

                <TabPanel value={valuesub} index={2024}>
                    <Box id="newsroom" className="section sec_bg section_mob" component="section">
                        <Container maxWidth="lg" className="bdr">

                            <Box className="reportinfo">
                                <Grid container spacing={1} alignItems="top" justifyContent="center">

                                    {data2024.map((item, index) => (
                                        <Grid item lg={4} md={4} sm={6} xs={12}>
                                            <Design
                                                key={index}
                                                type={item.type}
                                                link={item.link}
                                                date={item.date}
                                                imgsrc={item.imgsrc}
                                                heading={item.heading}
                                                subheading={item.subheading}
                                            />
                                        </Grid>
                                    ))}

                                </Grid>
                            </Box>

                        </Container>
                    </Box>
                </TabPanel>

                <TabPanel value={valuesub} index={2023}>
                    <Box id="newsroom" className="section sec_bg section_mob" component="section">
                        <Container maxWidth="lg" className="bdr">

                            <Box className="reportinfo">
                                <Grid container spacing={1} alignItems="top" justifyContent="center">

                                    {data2023.map((item, index) => (
                                        <Grid item lg={4} md={4} sm={6} xs={12}>
                                            <Design
                                                key={index}
                                                type={item.type}
                                                link={item.link}
                                                date={item.date}
                                                imgsrc={item.imgsrc}
                                                heading={item.heading}
                                                subheading={item.subheading}
                                            />
                                        </Grid>
                                    ))}

                                </Grid>
                            </Box>

                        </Container>
                    </Box>
                </TabPanel>


                <TabPanel value={valuesub} index={2022}>
                    <Box  id="newsroom" className="section sec_bg section_mob" component="section">
                        <Container maxWidth="lg" className="bdr">

                            <Box className="reportinfo">
                                <Grid container spacing={1} alignItems="top" justifyContent="center">

                                    {data2022.map((item, index) => (
                                        <Grid item lg={4} md={4} sm={6} xs={12}>
                                            <Design
                                                key={index}
                                                type={item.type}
                                                link={item.link}
                                                date={item.date}
                                                imgsrc={item.imgsrc}
                                                heading={item.heading}
                                                subheading={item.subheading}
                                            />
                                        </Grid>
                                    ))}

                                </Grid>
                            </Box>

                        </Container>
                    </Box>
                </TabPanel>

                <TabPanel value={valuesub} index={2021}>
                    <Box  id="newsroom" className="section sec_bg section_mob" component="section">
                        <Container maxWidth="lg" className="bdr">

                            <Box className="reportinfo">
                                <Grid container spacing={1} alignItems="top" justifyContent="center">

                                    {data2021.map((item, index) => (
                                        <Grid item lg={4} md={4} sm={6} xs={12}>
                                            <Design
                                                key={index}
                                                type={item.type}
                                                link={item.link}
                                                date={item.date}
                                                imgsrc={item.imgsrc}
                                                heading={item.heading}
                                                subheading={item.subheading}
                                            />
                                        </Grid>
                                    ))}

                                </Grid>
                            </Box>

                        </Container>
                    </Box>
                </TabPanel>

            </TabPanel>



        </>
    );
}