// JobDetail.js
import React, { useEffect, useState } from 'react';
import {useLocation } from 'react-router-dom';
import { Container, Grid, Typography, Box, Button } from '@material-ui/core';
import { Link } from "react-router-dom/cjs/react-router-dom";
import CandidateRegistration from '../../../Component/forms/CandidateRegistration';
import CurrentOpeningsBack from '../../../Component/CurrentOpeningsBack';

import "aos/dist/aos.css";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const JobDetail = () => {
    // const { designation, jobId } = useParams();
    const [jobData, setJobData] = useState(null);
    const location = useLocation();

    useEffect(() => {
        if (location.state && location.state.jobData) {
            setJobData(location.state.jobData);
        }
    }, [location]);

    console.log(jobData);

    return (
        <>
            <Box className="banner_bx">
                <Box style={{ backgroundImage: "url(" + + ")" }} className="banner_img">
                    <Box className="img_over bl_overlay">
                        <Grid container spacing={0} alignItems="center" justifyContent='center'>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className="al_center">
                                    <Typography className="white" variant="h1">{jobData && jobData.designation}</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>

            <Box className='section2' component='section'>
                <Container maxWidth='lg' className='bdr'>
                    <Grid container spacing={3} justifyContent="center" alignItems="top">
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Box className="al_left">
                                <Typography variant='h6' className='black jst'>
                                    <span className='blue fw6'>Location: </span> {jobData && jobData.job_location}
                                </Typography>
                                <Typography variant='h6' className='black jst'>
                                    <span className='blue fw6'>Experience: </span> {jobData && jobData.experience}
                                </Typography>
                                <Typography variant='h6' className='black jst'>
                                    <span className='blue fw6'>Education: </span> {jobData && jobData.education}
                                </Typography>
                            </Box>

                            <Box className="al_left">
                                <Typography variant='h6' className='black'>
                                    <span className='blue fw6'>Key Responsibilities:</span>
                                    <ul>
                                        {jobData && jobData.key_responsibilities.split('|').map((resp, index) => (
                                            <li key={index}>{resp}</li>
                                        ))}
                                    </ul>
                                </Typography>
                            </Box>

                            <Box className="al_left">
                                <Typography variant='h6' className='black'>
                                    <span className='blue fw6'>Skills:</span>
                                    <ul>
                                        {jobData && jobData.skills.split('|').map((skill, index) => (
                                            <li key={index}>{skill}</li>
                                        ))}
                                    </ul>
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <CandidateRegistration jobData={jobData} />
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box className='section2 sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>
                    <Grid container spacing={3} justifyContent="center" alignItems="top">
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Box className="al_center mb0" pt={3}>
                                <Typography className="black" variant="h2">Want to Join Our Successful Team?</Typography>
                            </Box>

                            <Grid container spacing={0} alignItems="center" justifyContent='center'>
                                <Grid item lg={5} md={6} sm={7} xs={10}>
                                    <Box className="al_center">
                                        <Typography variant='h6' className='black'>
                                            We are always interested to meet potential team members to join our fast-growing, dynamic team of professionals.
                                        </Typography>
                                    </Box>
                                </Grid>

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className="al_center" pt={2}>
                                        <Link to="/contact-us#contact_form">
                                            <Button
                                                variant="contained"
                                                className="dpabtn_1 mobbtn"
                                            >
                                                Get in touch now!
                                            </Button>
                                        </Link>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box mb={3}>
                <CurrentOpeningsBack />
            </Box>
        </>
    );
};

export default JobDetail;
