import {
  Container,
  Grid,
  Typography,
  Box,
} from "@material-ui/core";
import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";

import Blog_Data from "./Blog_Data";
import { Helmet } from "react-helmet";


export default function Blogs(props) {

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <>

      <Helmet>
        <title>Decimal Point Analytics Blogs - Technology for Finance Industry </title>
        <meta name="description" content="Stay informed with Decimal Point Analytics' insightful blog posts. Explore diverse topics, industry trends, and expert opinions to enrich your knowledge." />
        <link rel="canonical" href="https://www.decimalpointanalytics.com/blog" />
      </Helmet>

      <Box
        id="blogs-tiles"
        className="section sec_bg"
        component="section"
        style={{ paddingTop: "3%" }}
      >
        <Container maxWidth="lg" className="bdr">
          <Box>
            <Grid container spacing={1} alignItems="top">
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box className="al_center" mt={5}>
                  <Typography variant="h1" className="black newh1">
                    Blogs
                  </Typography>
                </Box>
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box className="al_center synopsic_bx" mb={3}>
                  <Typography variant="h6" className="black jst" gutterBottom>
                    Embark on an enlightening journey into the dynamic realms of Generative AI, Data Analytics, Machine Learning, Research, and ESG with Decimal Point Analytics, your trusted guide to transformative technologies.
                  </Typography>

                  <Typography variant="h6" className="black jst">
                    Curated by a team of esteemed experts, our blog unveils a comprehensive and nuanced perspective on these ever-evolving fields, offering invaluable insights for both seasoned professionals and industry enthusiasts. Explore our carefully crafted articles and uncover the latest trends, cutting-edge advancements, and practical applications that are shaping the future of these domains.
                  </Typography>

                  <Typography variant="h6" className="black jst">
                    Each meticulously crafted blog is designed to elevate your understanding and empower you with actionable knowledge, propelling you to the forefront of industry innovation.
                  </Typography>
                </Box>
              </Grid>


            </Grid>
          </Box>
        </Container>



        <Box className="reportinfo">
          <Container maxWidth="lg" className="bdr">
            <Grid container spacing={1} alignItems="top">
              {Blog_Data.map((item, index) => (
                <Grid item lg={4} md={4} sm={6} xs={12} key={index}>
                  <Link to={item.link} >
                    <Box className="reportm_bg">
                      <Box
                        className="reportimg_bg"
                        style={{
                          backgroundImage: `url(${item.imgsrc})`,
                        }}
                      ></Box>
                      <Box className="reporttitle_box">
                        <Typography className="reporttitle two" title={item.heading}>
                          {item.heading}
                        </Typography>
                      </Box>

                      <Box className="line"></Box>
                    </Box>
                  </Link>
                </Grid>
              ))}


            </Grid>
          </Container>
        </Box>

      </Box>
    </>
  );
}
