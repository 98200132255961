import { Divider, Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Helmet } from 'react-helmet';

import Insightback from '../../../Component/Insightback'

import Writemessage3 from '../../../Component/Writemessage3';

import trumps_table1 from "../../../asset/images/insight/insight_pages/donald_trumps_pre/table01.svg";
import trumps_table2 from "../../../asset/images/insight/insight_pages/donald_trumps_pre/table02.svg";

import donald_t1 from "../../../asset/images/insight/insight_pages/donald_trumps_pre/donald_t_1.webp";
import donald_t2 from "../../../asset/images/insight/insight_pages/donald_trumps_pre/donald_t_2.webp";


export default function Trumps_presidency() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    return (
        <>
            <Helmet>
                <meta charset="UTF-8" />
                <h1>Our article provides an in-depth analysis of Donald Trump's presidency, including its impact on the United States and the rest of the world. Discover the latest trends, controversies, and achievements of the 45th president of the United States." </h1>
                <title>Donald Trump's Presidency: Impact and Analysis</title>
                <meta name="description" content="Donald Trump's Presidency: Impact and Analysis - Decimal Point Analytics" />
                <meta name="keywords" content="
news donald
the white house washington dc
white house washington dc
white house president
45th president of the united states
45th president of usa
45th president
president united states" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/articles/trumps-presidency" />

                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>




            <Box id="article-page" className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center' >
                        <Grid item lg={10} md={12} sm={12} xs={12}>
                            <Typography variant='h3' className='subhead skblue al_center'>
                                Insight
                            </Typography>
                            <Typography variant='h1' className='blue mainhead al_center'>
                                Donald Trump'S Presidency
                            </Typography>
                        </Grid>
                    </Grid>

                    <Box className='whitebx'>


                        <Grid container spacing={3} alignItems="center">
                            <Grid item lg={4} md={4} sm={4} xs={12}>
                                <Box className='al_center'>
                                    <Box className=''>
                                        {/* style={{ backgroundImage: 'url(' + trumps_table1 + ')', }} */}
                                        <img src={trumps_table1} width="100%" alt='Trumps Table'/>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item lg={8} md={8} sm={8} xs={12}>
                                <Box>
                                    <Typography variant='h6' className='black jst'> Republican candidate, Donald Trump was sworn in as the US President on 20th January 2017. He has repeatedly spoken about his policy trifecta since coming to power. It comprises of tax cuts, deregulation, and infrastructure spending. Global financial markets jubilantly vaulted higher and welcomed the new White House resident with great enthusiasm. The S&P 500 registered a rise of 4.5% and the Dow Jones Industrial Average gained 5.3% during the first 50 days since Trump got sworn in, the best ever for a GOP President. To top that, robust jobs report and firm wage growth for February gives a clear indication that US economic growth is on its way up.  </Typography>

                                </Box>
                            </Grid>
                        </Grid>

                        <Divider />
                        <br />

                        <Grid container spacing={3} alignItems="top" >
                            <Grid item lg={4} md={4} sm={4} xs={12}>
                                <Box className='al_center'>
                                    <Box className='casestudy_bg' style={{ backgroundImage: 'url(' + donald_t1 + ')', }}></Box>
                                </Box>
                            </Grid>

                            <Grid item lg={8} md={8} sm={8} xs={12}>
                                <Box>

                                    <Typography variant='h4' className='blue jst'> Travel Ban </Typography>

                                    <Typography variant='h6' className='black jst'>The most controversial Trump initiative has been suspending immigration from seven Muslim-majority nations while indefinitely barring Syrian refugees. This gave rise to chaos at airports around the world as authorities struggled to implement the new policy. However, not everything went as Trump planned. His first order on immigration and refugees had a clumsy rollout and was struck down by a Washington state US district judge. It was an early lesson for Trump that executive power comes with limits. Major tech giants in the US protested against the move, as immigrants have traditionally comprised a healthy proportion of Silicon Valley's lucrative start-up scene and it could risk disrupting the engine of innovation. </Typography>
                                </Box>
                            </Grid>
                        </Grid>

                        <br />
                        <Divider />
                        <br />

                        <Grid container spacing={3} alignItems="top" >
                            <Box>
                                <Typography variant='h4' className='blue jst'> Repeal and Replace Obamacare Act  </Typography>

                                <Typography variant='h6' className='black jst'> President Donald Trump’s efforts to overhaul the US healthcare system hit a bump, after it faced resistance from a conservative GOP faction, the House Freedom Caucus. Failure to pass the healthcare bill raised questions not only on Trump’s ability to get other key parts of his agenda, including tax cuts and infrastructure spending, but also his capacity to govern effectively.  </Typography>
                            </Box>

                        </Grid>

                        <br />
                        <Divider />
                        <br />

                        <Grid container spacing={3} alignItems="top">
                            <Grid item lg={4} md={4} sm={4} xs={12}>
                                <Box className='al_center'>
                                    <Box className='casestudy_bg' style={{ backgroundImage: 'url(' + donald_t2 + ')', }}></Box>
                                </Box>
                            </Grid>

                            <Grid item lg={8} md={8} sm={8} xs={12}>
                                <Box>

                                    <Typography variant='h4' className='blue jst'> Rolling back Obama-era Environmental Policies  </Typography>

                                    <Typography variant='h6' className='black jst'> Donald Trump has started demolishing a wide array of Obama-era policies. On his second full working day, Trump signed orders to advance construction of two controversial pipelines - the Keystone XL and Dakota Access. Trump signaled his support for these two projects, which critics state will have an adverse environmental impact. He also signed an executive order on energy independence and to review and potentially repeal President Obama’s anti-energy policies that were instituted under the Climate Action Plan and Clean Power Plan. These involve a framework for reducing carbon dioxide emissions from fossil-fuel-fired electricity plants. Further, he has promised to put an end to Obama's moratorium on issuing new leases for coal mining on government administered property.  </Typography>
                                </Box>
                            </Grid>
                        </Grid>

                        <br />
                        <Divider />
                        <br />

                        <Grid container spacing={3} alignItems="center">
                            <Grid item lg={4} md={4} sm={4} xs={12}>
                                <Box className='al_center'>
                                    {/* <Box className='casestudy_bg' style={{ backgroundImage: 'url(' + trumps_table2 + ')', }}></Box> */}
                                    <Box>
                                        <img src={trumps_table2} width="100%" alt='Trumps Table 2'/>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item lg={8} md={8} sm={8} xs={12}>
                                <Box>

                                    <Typography variant='h4' className='blue jst'> Protecting American Workers  </Typography>


                                    <Typography variant='h6' className='black jst'>Protecting the jobs of American workers was a central focus of Trump’s campaign. He has signed an order to withdrawn from the Trans-Pacific Partnership, which was a critical part of Barack Obama's international trade policy. He has also pledged to renegotiate the North American Free Trade Agreement. However, there hasn’t been an executive order to that effect yet. Moreover, Trump also targeted the coveted H-1B work visa, which is the main pathway for highly skilled foreigners to work at US companies for up to six years. The US has suspended expedited processing of H-1B visas for six months starting from 3rd April, 2017.  </Typography>
                                </Box>
                            </Grid>
                        </Grid>

                        <br />
                        <Divider />

                        {/* <Box>
                            <Subscribe />

                        </Box> */}
                    </Box>


                    <Box className='pages'>
                        <Writemessage3 pageVisited="Donald Trump'S Presidency" />
                    </Box>



                    <Insightback />


                </Container>
            </Box>

        </>
    );
}
