import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Helmet } from 'react-helmet';

import Casestudyback from '../../../../Component/Casestudyback'

import Banner from "../../../../asset/images/insight/case_studies/banner_streamlining_loan.webp"
import Infographics from "../../../../asset/images/insight/case_studies/streamlining_loan_pool_info.webp"
import { Link } from 'react-router-dom';



export default function StreamliningLoanPool() {



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet><meta charset="UTF-8" />
                <meta charset="UTF-8" />
                <title>Accelerating Loan Pool Acquisition for US Asset Management</title>
                <meta name="description" content="Discover how a US firm cut loan pool acquisition from 8 months to 4 weeks. Explore the process of re-engineering that led to faster deployment and cost savings" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/case-studies/cutting-loan-pool-acquisition-time-by-for-a-leading-us-asset-management-firm" />
            </Helmet>

            <Box id="case-studies" className='section sec_bg new_cs' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={3} alignItems="top" justifyContent='center'>
                        <Grid item lg={11} md={12} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Success Stories
                                </Typography>
                                <Typography className='mainhead blue al_center'> 
                                    Cutting Loan Pool Acquisition Time <span className='lower'>by</span>  75% <span className='lower'>for a</span> Leading US Asset Management Firm
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Box >
                        <img src={Banner} className='w100' alt='Banner' />
                    </Box>


                    <Box className='whitebx'>
                        <Box>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Executive Summary
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                A leading US-based asset management firm specializing in real estate loans faced significant delays in their loan pool acquisition process, with a timeline stretching over eight months. This slow turnaround impacted their ability to deploy investments efficiently and maintain market competitiveness. Through loan management automation and process re-engineering, they reduced acquisition time to just four weeks, accelerating investment deployment and achieving cost savings.
                            </Typography>
                        </Box>


                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                The Challenge
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                The client faced several key obstacles:
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Document Management System Challenges:  </strong> Handling multiple documents across platforms complicated the due diligence for loan portfolios.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Lengthy Communication Loops: </strong> Repeated back-and-forth communication with sellers extended query resolution times.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Fragmented Processes: </strong> Without a centralized document management system for inventory checks and tracking, process delays accumulated.
                                        </Typography>
                                    </li>
                                </ul>
                            </Typography>
                        </Box>

                        <Box mt={3} className='mob_card'>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Solution: Process Re-engineering <span className='lower'>and </span> Centralized Document Management
                            </Typography>

                            <Grid container direction="row"
                                justifyContent="center"
                                alignItems="center">

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className="al_center" my={3}>
                                    <img src={Infographics} className="w100" alt="Solution: Process Re-engineering and Centralized Document Management" />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box mt={1}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Results
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                The strategic process overhaul delivered measurable improvements:
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>  Time Savings: </strong> Reduced loan pool acquisition time from 8 months to 4 weeks.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Faster Investment Deployment: </strong> Enabled quicker access to opportunities, improving market competitiveness.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Cost Savings: </strong> Automated processes resulted in lower operational costs and enhanced productivity.
                                        </Typography>
                                    </li>
                                </ul>
                            </Typography>
                        </Box>

                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Key Takeaways
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Centralized Document Management System: </strong> Ensured smoother handling of critical documents and data.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>  Loan Portfolio Optimization:  </strong>  Improved processes reduced delays and enabled faster transactions.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Investment Deployment Acceleration: </strong> Streamlined communication protocols speed up acquisitions, improving overall efficiency.
                                        </Typography>
                                    </li>
                                </ul>
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                By transforming their loan pool acquisition process, the asset management firm made more agile investment decisions and strengthened their market position. This collaboration demonstrates how re-engineering processes and <strong>loan management automation</strong> unlock substantial time savings and operational efficiency.
                            </Typography>

                            <Typography variant='h6' className='black jst' gutterBottom>
                                <Link to="/contact-us#contact_form" className="link">Contact us</Link>  today to learn how we can help you achieve similar success.
                            </Typography> 
                             
                        </Box>

                    </Box>


                    <Casestudyback />

                </Container>
            </Box>


        </>
    );
}