import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Helmet } from 'react-helmet';

import Casestudyback from '../../../../Component/Casestudyback'

import Banner from "../../../../asset/images/insight/case_studies/banner_saving_overheads.webp"
import Infographics from "../../../../asset/images/insight/case_studies/optimized_cost_model.webp"
import GetInTouch from '../../../../Component/Get_in_touch';



export default function SavinginOverheads() {



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet><meta charset="UTF-8" />
                <meta charset="UTF-8" />
                <title>Reducing Overheads by 60% while Enhancing Loan Quality</title>
                <meta name="description" content="Discover how a finance firm cut operational costs by 60% while maintaining loan quality through strategic outsourcing and automation, driving better growth and efficiency." />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/case-studies/saving-60-in-overheads-while-enhancing-sba-loan-quality-and-speed-by-40" />
            </Helmet>

            <Box id="case-studies" className='section sec_bg new_cs' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={3} alignItems="top" justifyContent='center'>
                        <Grid item lg={9} md={9} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Success Stories
                                </Typography>
                                <Typography className='mainhead blue al_center'>
                                    Saving 60% <span className='lower'>in</span> Overheads While Enhancing SBA Loan Quality <span className='lower'>and </span> Speed <span className='lower'>by</span> 40%
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Box >
                        <img src={Banner} className='w100' alt='Banner' />
                    </Box>


                    <Box className='whitebx'>
                        <Box>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Executive Summary
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                Escalating costs and compliance challenges demanded a strategic shift for a multi-strategy finance firm relying on onshore underwriting. Balancing costs while maintaining processing accuracy was vital. By adopting cost-effective outsourcing, loan automation, and refined quality measures, overheads were reduced by 60%, accuracy reached 99%, and turnaround times improved by 40%, driving both financial efficiency and growth.
                            </Typography>
                        </Box>


                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                The Challenge
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                The company encountered several challenges in its operations:
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>High Operational Costs: </strong>   Onshore underwriting teams increased expenses, limiting profitability and growth potential.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Compliance Strain: </strong>  Balancing cost pressures with the need for accuracy in SBA loan servicing and regulatory compliance was a constant challenge.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Resource Constraints: </strong>  Limited capacity for growth due to resource strain, resulting in operational inefficiencies and slower SBA loan approvals.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Limited Scalability: </strong>  Manual processes and high costs impeded the ability to scale operations efficiently.
                                        </Typography>
                                    </li>
                                </ul>
                            </Typography>
                        </Box>

                        <Box mt={3} className='mob_card'>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Solutions: Optimized Cost Model
                            </Typography>

                            <Grid container direction="row"
                                justifyContent="center"
                                alignItems="center">

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className="al_center" my={3}>
                                        <img src={Infographics} className="w100" alt="Solutions: Optimized Cost Model" />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Results
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> 60% Reduction in Overheads: </strong>  Achieved significant cost savings, improving operational margins.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> 40% Faster Turnaround Times: </strong>  Automated workflows enabled quicker loan approvals, improving client engagement.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> 50% Error Rate Reduction: </strong>  Enhanced quality control minimized errors, reducing rework and compliance risks.
                                        </Typography>
                                    </li>
                                </ul>
                            </Typography>


                        </Box>

                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Key Takeaways
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Cost Efficiency: </strong>  Outsourcing reduced operational costs by 60%, enabling reinvestment in growth.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Improved Accuracy: </strong>  Automated processes-maintained compliance and accuracy while reducing manual errors.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Strategic Growth: </strong> Freed resources for strategic initiatives and expanded capacity for SBA loan processing.
                                        </Typography>
                                    </li>
                                </ul>

                            </Typography>
                        </Box>

                        <Typography variant='h6' className='black jst' gutterBottom>
                            Reducing operational costs while improving loan quality is a strategic win for sustainable growth. By integrating cost-effective outsourcing solutions and automating processes, you can achieve both financial efficiency and operational excellence.
                        </Typography>

                        <Typography variant='h6' className='black jst' gutterBottom>
                            Looking to cut costs and improve accuracy? Explore cost-effective outsourcing and loan automation solutions to streamline your SBA loan operations and scale efficiently.
                        </Typography>
                    </Box>

                    <Box id="sba_success_stories">
                        <GetInTouch />
                    </Box>

                    <Casestudyback />

                </Container>
            </Box>


        </>
    );
}