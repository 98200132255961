
import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Writemessage3 from '../../../Component/Writemessage3';

import Casestudyback from '../../../Component/Casestudyback'
import quotemediadash1 from '../../../asset/images/insight/case_studies/quotemedia-dash1.webp';
import quotemediadash2 from '../../../asset/images/insight/case_studies/quotemedia-dash2.webp';


export default function Quotemedia() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);


  return (
    <>

      <Helmet>

        <meta charset="UTF-8" />
        <title>Data Solutions for Financial Data Aggregator and Provider</title>
        <meta name="description" content="Read the case study on how Decimal Point Analytics delivered comprehensive data solutions for a leading financial data aggregator." />
        <meta name="keywords" content="
AI in data analytics,
artificial intelligence solutions company,
business intelligence analytics,
Corporate Data Providers,
market research company in US,
market research solutions,
Sector research provider " />
        <meta name="author" content="Decimal Point Analytics" />
        <link rel="canonical" href="https://www.decimalpointanalytics.com/case-studies/delivering-comprehensive-data-solutions-for-a-leading-financial-data-aggregator-and-provider" />

        <meta name="viewport" content="width=device-width, initial-scale=1.0" />


      </Helmet>


      <Box id="case-studies" className='section sec_bg' component='section'>

        <Container maxWidth='lg' className='bdr'>

          <Grid container spacing={3} alignItems="top" justifyContent='center'>
            <Grid item lg={10} md={10} sm={12} xs={12}>
              <Box>
                <Typography variant='h3' className='subhead skblue al_center'>
                  Success Stories
                </Typography>
                <Typography variant='h1' className='mainhead blue al_center mb0'>
                  Delivering Comprehensive Data Solutions for a Leading Financial Data Aggregator and Provider
                </Typography>

                <Typography className=' mainsmhead black al_center '>
                  A Paramount Canada-Based Financial Data Provider and Aggregator Associated with Decimal Point Analytics for A Well-Structured Solution To Collate Region-Wise Data For US And Canadian Market.
                </Typography>
              </Box>
            </Grid>
          </Grid>


          <Writemessage3 pageVisited="Delivering Comprehensive Data Solutions for a Leading Financial Data Aggregator and Provider" />



          <Box className='whitebx'>
            <Box>
              {/* <Typography variant='h4' className='blue' >Summary </Typography> */}
              <Typography variant='h6' className='black jst'>
                Decimal Point Analytics partnered with a Financial Data Aggregator & Provider firm based in Canada. The client’s company processes involved extensive data dealing, including mutual funds. The methods initiated from data collection through large-scale financial data providers and redistribution of data to banks, asset managers, and other institutional investors in a customized way. The business was overall centered on data collection and processing.
              </Typography>
            </Box>
          </Box>


          <Box className='whitebx' mt={1}  >
            <Grid container spacing={3} alignItems="top">
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <Box className='al_center'>
                  <Box className='casestudy_bg' style={{ backgroundImage: 'url(' + quotemediadash1 + ')', }}></Box>
                </Box>
              </Grid>
              <Grid item lg={8} md={8} sm={8} xs={12}>
                <Box>



                  <Typography variant='h4' className='blue'>PROBLEM STATEMENT</Typography>

                  <Typography variant='h6' className='black jst'>
                    Our client’s data providers asked for a higher stake each time our client landed a new customer. Unfortunately, placing a condition like this meant that our client had to spend more each time they acquired a new client. As a result, our client approached us for a concrete and well-structured solution to the problem - a solution that would save costs and eliminate dependency on external data providers.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>





          <Box className='whitebx' mt={1}  >
            <Grid container spacing={3} alignItems="center">
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <Box className='al_center'>
                  <Box className='casestudy_bg' style={{ backgroundImage: 'url(' + quotemediadash2 + ')', }}></Box>
                </Box>
              </Grid>
              <Grid item lg={8} md={8} sm={8} xs={12}>
                <Box>

                  {/* <Box mb={2}>
                    <Link to='/analystpulse'>
                      <Typography variant='h4' className='blue' >Know More About Our Product on Analytics – AnalystPulse </Typography>
                    </Link>
                  </Box> */}

                  <Typography variant='h4' className='blue'>
                    AN OVERVIEW OF CLIENT REQUIREMENTS
                  </Typography>
                  <Typography variant='h6' className='black jst'>
                    The client wished to replace an external data provider and develop an in-house mutual funds database for the US and Canadian markets.</Typography>
                  <Typography variant='h6' className='black jst'>
                    The client also required data sourcing, collation, and cleaning, which must be uploaded on more than 400 data fields related to Mutual Funds characteristics, NAV performance, ratios, and statistics for 96,000+ Mutual Fund Share Classes.</Typography>
                  <Typography variant='h6' className='black jst'>
                    The requirement also included designing a front-end framework to access and update data.


                  </Typography>

                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box className='whitebx' mt={1}>
            <Box>
              <Typography variant='h4' className='blue' >
                CHALLENGES POSED BY THE CLIENT</Typography>

              <Typography variant='h6' className='black jst'>
                The opportunity had scope to create detailed datasets that would help the client gain autonomy over their current processes.
              </Typography>
              <Typography variant='h6' className='black jst'>
                However, there were a few challenges in line. These were:
                <ul>
                  <li>Multiple Sources for Input Data: Collating the input data is a cumbersome process due to numerous collection sources.</li>
                  <li>Time of Availability of Information based on Different Regions: The data collection on such a wider scale required our team to deal with the time of data availability, depending on multiple regions.</li>
                  <li>Collating, Checking, and Uploading Data on a Real-Time/Daily Basis: The most challenging task was to tackle the data in real-time. In addition, the job required our team to analyze whether it was an inverse derivative product, hedge fund, or other fund.</li>
                </ul>
              </Typography>
              <Typography variant='h6' className='black jst'>
                Keeping track of day-to-day changes in key fund data took time. The best example of this is the NAV
                portfolio. It is dynamic and evolves daily.
              </Typography>

            </Box>
          </Box>

          <Box className='whitebx' mt={1}>
            <Box>

              <Typography variant='h4' className='blue' > Our Approach </Typography>


              <Typography variant='h6' className='black jst'>
                The team carefully inspected and analysed the challenges before devising practical solutions. After fully understanding the project requirement, the team divided the solutions into two segments:
                <ul>
                  <li>Functional Front: The team must build a database to collect and stockpile the data. The second step was data aggregation through the proper identification, collection, and cleansing of information to prepare for data transformation and processing.</li>
                  <li>Technical Front: The technical front had a unique funnel that started from designing the wireframe. The next step was to test the wireframe. Once the wireframe was functional, the team began with a refining process. Finally, the last step of the funnel was user interface development. </li>

                </ul>
              </Typography>
              <Typography variant='h6' className='black jst'>The final database update was the resulting action before client delivery </Typography>
            </Box>
          </Box>





          <Box className='whitebx' mt={1}>
            <Box>
              <Typography variant='h4' className='blue' >
                PROCESSING THE SOLUTION EFFECTIVELY</Typography>
              <Typography variant='h6' className='black jst'>
                Our team collated the data from dependable sources at multiple intervals creating the following:
                <ul>
                  <li>Annual report  </li>
                  <li>Quarterly Commentary</li>
                  <li>Fact sheets</li>
                  <li>Regulatory filings</li>
                </ul>
              </Typography>

              <Typography variant='h6' className='black jst'>The process involved dealing with highly agile data. So the team bifurcated data collection into four
                segments - single-time, daily basis, monthly, and event-based data collection.
              </Typography>
            </Box>
          </Box>

          <Box className='whitebx' mt={1}>
            <Box>
              <Typography variant='h4' className='blue' >THE TECHNICAL REQUIREMENTS</Typography>
              <Typography variant='h6' className='black jst'>
                The solution-centric approach of Decimal also consisted of handling the below-listed technical
                requirements.

                <ul>
                  <li>UI Framework: This project involved multiple streams for data collation. It was crucial to do a quality check of data to ensure uniformity. For this purpose, we developed a UI framework - the Django framework. </li>
                  <li>Programming Language: The team used Python for Data collection and calculations throughout the project. Python fast-tracked the processes and aided in the timely delivery of results.</li>
                  <li>Data insertion, Storage & Calculation: Pentaho Data Integration (for data extraction, transformation & Loading) and My SQL (a relational database that ensures data redundancy elimination) were two important tools for this step.</li>
                  <li>Data Delivery: Data from My SQL was finally transferred to MongoDB. It allowed us to deliver highly-optimized and productive solutions.</li>
                  <li>API Framework: The team adopted Fast API to ensure that Data is delivered to the client in the shortest period compared to the other providers in the market.</li>
                </ul>
              </Typography>

            </Box>
          </Box>

          <Box className='whitebx' mt={1}>
            <Box>
              <Typography variant='h4' className='blue' >END-TO-END QUALITY CONTROL</Typography>
              <Typography variant='h6' className='black jst'>
                Our team worked on creating reliable, smooth, and fast data processes for our client. Therefore, it was essential to implement the following quality control layers to produce the desired results:

                <ul>
                  <li>Comprehensive SOPs and checklists to ensure Data consistency and accuracy.</li>
                  <li>Automated quality control tests that use machine learning (ML) to find anomalous data points and inaccuracies.</li>
                  <li>Statistical quality control tests applying algorithms to find outliers.</li>
                  <li>Manual quality control and a maker-checker technique were used to find unintended faults or any divergence from established methods.</li>
                </ul>
              </Typography>

            </Box>
          </Box>

          <Box className='whitebx' mt={1}>
            <Box>
              <Typography variant='h4' className='blue' >DELIVERING SYSTEMATIC & VALUE-ADD SOLUTIONS</Typography>
              <Typography variant='h6' className='black jst'>
                The solutions formulated by team Decimal enabled our client to gain the flexibility to add data fields in the same mandate within a reasonable time and cost.</Typography>
              <Typography variant='h6' className='black jst'>
                The team worked on developing an efficient Delivery Channel and reducing the turnaround time, ascertaining faster results within the stipulated time.</Typography>
              <Typography variant='h6' className='black jst'>
                The team engaged with the client through recurring interactions and feedback sessions at different project intervals. The process proved to be pivotal for Database Scalability. Our solutions helped the client to effectively productize the database further to provide ace services to their diverse pool of clients.</Typography>
              <Typography variant='h6' className='black jst'>
                Our approach combined many measures and processes, ultimately leading to successful project delivery. Partnering with us gave our client a vantage point from a finance and data perspective. As a result, our client moved beyond their earlier restrictive approach towards a holistic and growth-centric approach through databases created by us.</Typography>
              <Typography variant='h6' className='black jst'>
                If you want to know how to make your data work for you, contact us to book your 30-minute complimentary

              </Typography>

              <Link to='/contact-us' className='link'>
                <Typography variant='h6' className='blue jst'>
                  Consultation Now !
                </Typography>
              </Link>

            </Box>
          </Box>



          {/* <Box className='whitebx' mt={1}>
            <Box>
              <Typography variant='h4' className='blue' >Enable better decisions with Decimal Point Analytics</Typography>
              <Typography variant='h6' className='black jst'>
                In the age of digital transformation, data automation and machine learning are the keys to tomorrow's business. The advent of technology has altered how businesses gather, store, and analyze information. Organizations all across the world are now acknowledging the significance of gaining insights from unstructured data and making data-driven choices. This means that if you want to win over your competition and keep abreast of your business, getting meaningful insights from data is no longer an option, it is a necessity.
              </Typography>
              <Typography variant='h6' className='black jst'>
                We streamlined and automated all the sources for our clients so that they could get the required inputs with just a few clicks. Thus, helping them expand the market and implement cost-efficient routes to scale up processing and analyze crucial data.
              </Typography>
              <Typography variant='h6' className='black jst'>
                If you would also want your complex data in a simplified structure that utilizes an enhanced data-driven decision-making procedure, then we would love to have a word with you.
              </Typography>



            </Box>
          </Box> */}



          <Box className='graybbx' mt={1} >
            <Grid container spacing={1} alignItems="top" >
              <Grid item lg={12} md={12} sm={12} xs={12} >
                <Box className='al_left'>
                  <Typography variant='h4' className='black'>
                    Explore More:
                  </Typography>

                  <Link to='/case-studies/tailoring-process-automation-to-provide-quality-data-in-salesforce-crm' className='link'>
                    <Typography variant='h6' className='black jst' style={{ marginBottom: '0px' }}>
                      <b>Next Case Study:</b>Tailoring Process Automation Solutions to Provide Modernised Quality Data in Salesforce CRM.

                    </Typography>
                  </Link>

                  <Link to='/articles/how-can-asset-managers-engage-with-businesses-to-accelerate-drive-towards-net-zero' className='link'>
                    <Typography variant='h6' className='black jst'>
                      <b>Insights:</b> How can Asset managers engage with businesses to accelerate drive towards Net Zero.
                    </Typography>
                  </Link>

                </Box>
              </Grid>
            </Grid>

            {/* <Box mt={5}>
                        <Subscribe />
                    </Box> */}


          </Box>


          <Casestudyback />




        </Container>
      </Box>


    </>
  );
}

