import React, { useEffect, useState } from "react";

import {
  TextField,
  Button,
  Container,
  Grid,
  Typography,
  Box,
} from "@material-ui/core";
import PropTypes from "prop-types";
import Aos from "aos";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useHistory } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import LoadingButton from "@mui/lab/LoadingButton";
import flags from "react-phone-number-input/flags";
import { crmFormApi } from "../api/api";
import CustomePhoneNumber from "../CMR_CustomePhoneNumber_Not_Mandatory";

import "aos/dist/aos.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "../../Style.css";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-number-input/style.css";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const usePlaceholderStyles = makeStyles((theme) => ({
  placeholder: {
    color: "#aaa",
  },
}));

const Placeholder = ({ children }) => {
  const classes = usePlaceholderStyles();
  return <div className={classes.placeholder}>{children}</div>;
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const validationSchema = yup.object({
  fullname: yup
    .string()
    .required("Name is required")
    .matches(/^[A-Za-z\s'-]+$/, "Enter valid name"),
  organisation: yup.string().required("Organization is required"),
  email: yup
    .string()
    .required("Email is required")
    .matches(
      /^[a-zA-Z0-9_]+(?:[-._][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+(?:[-.][a-zA-Z0-9_]+)*\.[a-zA-Z]{2,}$/,
      "Email is not valid"
    ),
});

export default function CRMHeroForm() {
  const [phoneValueError, setPhoneValueError] = useState("");
  const [phoneValue1, setPhoneValue1] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    console.log("phoneValue1", phoneValue1);
    if (phoneValue1 && !isValidPhoneNumber(phoneValue1)) {
      setPhoneValueError("Enter a valid phone number");
      return;
    }
    setLoading(true);
    try {
      const payload = {
        name: data.fullname,
        email: data.email,
        contact_no: phoneValue1,
        job_title: data.jobtitle,
        organization: data.organisation,
        message: data.message,
        form_type: "Schedule Demo",
      };
      await crmFormApi(payload);
      history.push("/crm-form-submit");
    } catch (error) {
      toast.error("Failed to submit the form");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <Box id="crmcontact_form" component="section">
      <Container maxWidth="lg" className="bdr">
        <Box className="whitebx">
          <Box className="borderbx">
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box className="al_left">
                  <Typography variant="h3" className="d-blue">
                    Schedule Demo
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      if (phoneValue1 && !isValidPhoneNumber(phoneValue1)) {
                        setPhoneValueError("Enter a valid phone number");
                      }
                      handleSubmit(onSubmit)(e);
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box className="alltxfieldlg textfield1">
                          <TextField
                            label="Name*"
                            variant="outlined"
                            fullWidth
                            {...register("fullname")}
                            error={!!errors.fullname}
                            helperText={<span style={{ color: "#f00" }}>{errors.fullname?.message}</span>}
                          />
                        </Box>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box className="alltxfieldlg textfield1">
                          <TextField
                            label="Email Address*"
                            variant="outlined"
                            fullWidth
                            {...register("email")}
                            error={!!errors.email}
                            helperText={<span style={{ color: "#f00" }}>{errors.email?.message}</span>}
                          />
                        </Box>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box className="alltxfieldlg textfield1">
                          <PhoneInput
                            flags={flags}
                            international
                            placeholder="Enter phone number"
                            value={phoneValue1}
                            onChange={(value) => {
                              setPhoneValue1(value);
                              if (value && !isValidPhoneNumber(value)) {
                                setPhoneValueError(
                                  "Enter a valid phone number"
                                );
                              } else {
                                setPhoneValueError("");
                              }
                            }}
                            inputComponent={CustomePhoneNumber}
                          />
                        </Box>
                        {phoneValueError && (
                          <Typography
                            style={{ color: "#f00", fontSize: "12px" }}
                          >
                            {phoneValueError}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Box className="alltxfieldlg textfield1">
                          <TextField
                            label="Organization Name*"
                            variant="outlined"
                            fullWidth
                            {...register("organisation")}
                            error={!!errors.organisation}
                            helperText={<span style={{ color: "#f00" }}>{errors.organisation?.message}</span>}
                          />
                        </Box>
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Box className="alltxfieldlg textfield1">
                          <TextField
                            label="Job Title"
                            variant="outlined"
                            fullWidth
                            {...register("jobtitle")}
                          />
                        </Box>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box className="alltxfieldlg textfield1 requirement">
                          <TextField
                            label="Message"
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={3}
                            {...register("message")}
                          />
                        </Box>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box className="al_center">
                          {loading ? (
                            <LoadingButton
                              loading={loading}
                              variant="outlined"
                              disabled
                            >
                              disabled
                            </LoadingButton>
                          ) : (
                            <Button
                              endIcon={<ChevronRightIcon />}
                              variant="contained"
                              className="dpabtn_1"
                              type="submit"
                            >
                              Submit
                            </Button>
                          )}
                        </Box>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Typography variant="h6" className="label2">By clicking on "Submit", you agree to our  <Link to="/terms" className="link">terms  </Link>  and you acknowledge having read our <Link to="/policy" className="link">privacy policy</Link>
                        </Typography>
                      </Grid>
                    </Grid>
                  </form>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
