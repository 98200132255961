import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Eventback from '../../../Component/Eventback'
import { Helmet } from "react-helmet";


export default function GIFTIFSCConference2024() {


    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>Decimal Point Analytics at IFSCA San Francisco Conference 2024: Shaping the Future of Finance</title>
                <meta name="description" content="Decimal Point Analytics joined the IFSCA San Francisco Conference 2024, with David Riedel discussing key finance trends and data-driven solutions." />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/decimal-point-analytics-at-the-gift-ifsc-conference-2024-in-new-york" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>



            <Box id='event-details' className='section sec_bg' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={8} md={8} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Events
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center'>
                                    Decimal Point Analytics at the GIFT IFSC Conference 2024 in New York
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Box className='whitebx'>


                        <Typography variant='h6' className='black jst'>
                            We were pleased to participate in the <strong>GIFT IFSC Conference 2024</strong> in New York on <strong>October 22</strong>, hosted by <strong>IFSCA Official</strong>. This premier event gathered finance leaders and industry experts to explore key developments in <strong>Capital Markets</strong> and <strong>Fund Management</strong>, driving valuable discussions and strategic insights for the financial sector.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            <strong>Representing Decimal Point Analytics</strong> at the conference was <strong>Nilanjan Das, Executive Vice President</strong>, who engaged with industry peers on emerging trends and innovative approaches in finance. Our participation emphasized Decimal Point Analytics’ commitment to advancing growth and innovation through data-driven insights and AI-enabled solutions.
                        </Typography>

                        <Typography variant='h6' className='black jst' gutterBottom>
                            It was an impactful event filled with thought leadership, networking, and fresh perspectives to help shape the future of finance.
                        </Typography>


                    </Box>
                    <Eventback />


                </Container>
            </Box>

        </>
    );
}
