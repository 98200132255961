
import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Writemessage3 from "../../../../../../Component/Writemessage3"
import EmployeeProfile from '../../../../../../Component/EmployeeProfile';
import AJ from '../../../../../../asset/images/team/ajay_jindal.webp'
import ESGBlogsBack from '../../../../../../Component/BackButtons/ESGBlogsBack';
import { Helmet } from 'react-helmet';



export default function UNEP() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);


  return (
    <>
      <Helmet>
        <title>UNEP Emissions Gap Report 2022 | Decimal Point Analytics</title>
        <meta name="description" content="Explore the sobering findings of the UNEP Emissions Gap Report 2022, revealing the urgent need for accelerated climate action." />
        <link rel="canonical" href="https://www.decimalpointanalytics.com/esg-insights/esg-blogs/unep-emissions-gap-report-for-2022-paints-a-grim-picture" />
      </Helmet>


      <Box className='section sec_bg' component='section'>

        <Container maxWidth='lg' className='bdr'>

          <Grid container spacing={3} alignItems="top">
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box className='al_center' mb={2}>
                <Typography variant='h3' className='subhead skblue al_center'>
                  ESG Insights
                </Typography>
                <Typography variant='h1' className='mainhead blue al_center'>
                  UNEP Emissions Gap Report for 2022 paints a grim picture
                </Typography>
              </Box>
            </Grid>
          </Grid>


          <Writemessage3 pageVisited="UNEP Emissions Gap Report for 2022 paints a grim picture" />



          <Box className='whitebx' mt={1}  >

            <Box>

              <Typography variant='h4' className='blue'>
                Introduction
              </Typography>

              <Typography variant='h6' className='black jst'>
                For all the talk on climate commitments and net zero focus, progress remains painfully slow so far. The world is on a 2.8°C pathway, far away from the 1.5°C goal set in the Paris Agreement
              </Typography>

            </Box>


            <Box pt={1} pb={2}>

              <Typography variant='h6' className='black jst'>
                UN Environment Programme's (UNEP) annual Emissions Gap report released last week once again shows that the on-ground progress in aligning to Paris Agreement remains sharply off trajectory.
              </Typography>



              <Typography variant='h6' className='black jst'>
                The world is still falling short of the Paris climate goals, with no credible pathway to 1.5°C in place, says the document. The Paris Agreement is a legally binding international treaty on climate change. It was adopted by 196 Parties at COP* 21 in Paris, on 12 December 2015 and entered into force on 4 November 2016. Its goal is to limit global warming to well below 2, preferably to 1.5 degrees Celsius, compared to pre-industrial levels. </Typography>

              <Typography variant='h6' className='black jst'>
                <em> (*COP stands for Conference of Parties) </em>
              </Typography>


              <Typography variant='h6' className='black jst'>
                Nationally Determined Contributions (NDCs) submitted since COP26 take only 0.5 gigatonnes of CO2 equivalent greenhouse gas emissions (GtCO2e), less than one per cent, off projected global emissions in 2030. Governments were given a window of one year to review and strengthen their NDCs ahead of COP27. However, when the deadline passed last month, just 23 of the nearly 200 countries which signed the Glasgow Climate Pact had done so, according to a statement from CDP.  </Typography>


              <Typography variant='h6' className='black jst'>
                The current NDCs are estimated to reduce global emissions in 2030 by 5 and 10 per cent respectively, says the UNEP report, while to limit global warming to 2°C and 1.5°C, these percentages must reach 30 per cent and 45 per cent respectively. </Typography>


              <Typography variant='h6' className='black jst'>
                While the Paris Agreement talked about aligning to 1.5°C, the policies currently in place, without further strengthening, suggest the world is on a 2.8°C pathway. This difference is not trivial. </Typography>


              <Typography variant='h6' className='black jst'>
                The 2021 report Climate Change 2021: the Physical Science Basis, by Intergovernmental Panel on Climate Change (IPCC) demonstrated that every half degree warming causes more heatwaves, droughts and floods.</Typography>


              <Typography variant='h6' className='black jst'>
                Even for For 1.5°C of global warming, there will be increasing heat waves, longer warm seasons and shorter cold seasons. At 2°C of global warming, heat extremes would more often reach critical tolerance thresholds for agriculture and health, the report shows. Anything above 2°C is unsustainable and may change life as we know it on earth. </Typography>


              <Typography variant='h6' className='black jst'>
                The report showed that emissions of greenhouse gases from human activities are responsible for approximately 1.1°C of warming since 1850-1900, and finds that averaged over the next 20 years, or roughly by 2040, global temperature is expected to reach or exceed 1.5°C of warming. After that, the world could really get into rough territory unless the current emission reduction progress dramatically improves.</Typography>


              <Typography variant='h6' className='black jst'>
                Most expert commentary on the UNEP report agrees with the report’s finding that the progress since COP26 has been woeful.
              </Typography>


            </Box>



            {/*-------------------------------------------------------*/}
            <Box mt={1}>
              <EmployeeProfile imgSrc={AJ} name={" Mr. Ajay Jindal"} designation={"EVP,"} company={"Decimal Point Analytics Pvt. Ltd."} linkedinUrl={"https://www.linkedin.com/in/ajay-jindal-cfa-33aa961/"} />
            </Box>
            {/*-------------------------------------------------------*/}




          </Box>
          {/*-------------------------------------------------------*/}



          <Box className='graybbx' mt={1} >
            <Grid container spacing={1} alignItems="top" >
              <Grid item lg={12} md={12} sm={12} xs={12} >
                <Box className='al_left'>

                  <Typography variant='h6' className='black jst'>
                    <b>Category:</b>  Environment
                  </Typography>


                  <Typography variant='h6' className='black jst'>
                    <b>Tags:</b> UN Environment Programme, UNEP, Emissions Gap Report 2022, Nationally Determined Contributions (NDCs), Glasgow Climate Pact, gigatonnes, CO2 equivalent, GtCO2e, Climate Change 2021, Intergovernmental Panel on Climate Change, IPCC
                  </Typography>


                </Box>
              </Grid>
            </Grid>
          </Box>



          <ESGBlogsBack />




        </Container>
      </Box>


    </>
  );
}

