import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Helmet } from 'react-helmet';

import Casestudyback from '../../../../Component/Casestudyback'

import Banner from "../../../../asset/images/insight/case_studies/banner_real_estate_finance.webp"
import Infographics from "../../../../asset/images/insight/case_studies/how_automation_doubled_loan.webp"
import { Link } from 'react-router-dom';
import GetInTouch from '../../../../Component/Get_in_touch';



export default function RealEstateFinanceFirms() {



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet><meta charset="UTF-8" />
                <meta charset="UTF-8" />
                <title>How Automation Transformed Loan Processing: Doubling Speed and Cutting Errors by 50% for Real Estate Finance Firms</title>
                <meta name="description" content="Discover how advanced automation helped a real estate finance company double productivity, cut processing times by 40%, and reduce error rates by 50%, creating faster, reliable loan approvals and boosting client satisfaction." />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/case-studies/how-automation-doubled-loan-processing-speed-and-cut-errors-by-50-for-real-estate-finance-firms" />
            </Helmet>

            <Box id="case-studies" className='section sec_bg new_cs' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={3} alignItems="top" justifyContent='center'>
                        <Grid item lg={9} md={9} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Success Stories
                                </Typography>
                                <Typography className='mainhead blue al_center'>
                                    How Automation Doubled Loan Processing Speed  <span className='lower'>and</span> Cut Errors  <span className='lower'>by 50% for</span> Real Estate Finance Firms
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Box >
                        <img src={Banner} className='w100' alt='Banner' />
                    </Box>


                    <Box className='whitebx'>
                        <Box>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Executive Summary
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                A multi-strategy real estate finance company faced significant challenges, including operational bottlenecks, slow loan processing times, and inconsistent quality control. These issues were limiting client satisfaction and market competitiveness. By implementing advanced automation, stringent quality control measures, and scalable processes, the company achieved a 2x boost in productivity, a 40% improvement in processing speed, and a 50% reduction in error rates—creating a faster, more reliable lending environment.
                            </Typography>
                        </Box>


                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                The Challenge
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                The company's SBA loan operations faced critical challenges:
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Bottlenecks in Workflow: </strong>  Complex, manually intensive processes slowed down loan approvals and led to backlogs.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>High Error Rates: </strong>  Inconsistent data accuracy and limited quality checks resulted in compliance issues and delayed underwriting.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>  Limited Capacity: </strong> The workflows were not optimized for handling high volumes, creating a strain on resources and impacting client trust.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Extended Processing Times: </strong>  Slow processing hurt borrower satisfaction, limiting the company's competitive edge.
                                        </Typography>
                                    </li>

                                </ul>
                            </Typography>
                        </Box>

                        <Box mt={3} className='mob_card'>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Solution:
                            </Typography>

                            <Typography variant='h6' className='black jst' gutterBottom>
                                To address these challenges, Decimal Point Analytics implemented three key solutions:
                            </Typography>

                            <Grid container direction="row"
                                justifyContent="center"
                                alignItems="center">

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className="al_center" my={3}>
                                        <img src={Infographics} className="topbanner" alt="Solution " />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box mt={1}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Results
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>40% Faster Processing Times: </strong>  Reduced processing times from 20 to 13 hours per loan application.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>2x Increase in Loan Capacity: </strong>  The scalable process enabled the company to manage twice the volume of loan applications efficiently.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>50% Error Reduction: </strong>  Enhanced quality measures led to fewer errors and ensured compliance with regulatory standards.
                                        </Typography>
                                    </li>

                                </ul>
                            </Typography>
                        </Box>

                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Key Takeaways
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Operational Efficiency: </strong>  Automated workflows doubled productivity, with a 40% reduction in processing times.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Enhanced Compliance: </strong>  Stringent quality measures boosted data accuracy, compliance, and trust.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Improved Client Satisfaction: </strong>  Faster processing and accurate data reinforced client trust and engagement.
                                        </Typography>
                                    </li>
                                </ul>

                            </Typography>
                        </Box>

                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Conclusion
                            </Typography>

                            <Typography variant='h6' className='black jst' gutterBottom>
                                Achieving 2x productivity with fewer errors means more than just speed; it’s about reliability and client trust. With intelligent automation and robust quality controls, this company transformed its loan processing operations, setting a new standard for speed, accuracy, and customer-centric service.
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <strong> Ready to Elevate Your Loan Processing Efficiency?  </strong>
                            </Typography>


                            <Typography variant='h6' className='black jst' gutterBottom>
                                Discover how our tailored solutions can streamline your SBA loan processes, enhance accuracy, and improve client satisfaction.  <Link to="/contact-us#contact_form" className="link">Connect with us</Link> today to unlock faster, more reliable loan approvals and drive competitive success.
                            </Typography>

                        </Box>

                    </Box>

                    <Box id="sba_success_stories">
                        <GetInTouch />
                    </Box>


                    <Casestudyback />

                </Container>
            </Box>


        </>
    );
}