import { Container, Typography, Box, Grid, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


import Writemessage3 from '../../../Component/Writemessage3';

import Blogback from '../../../Component/Blogback'
import { Helmet } from 'react-helmet';

export default function Guiding_policy_making() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>


            <Helmet>
                <meta charset="UTF-8" />
                <h1>Guiding Policymaking through the Fog of Covid19 induced Lockdowns </h1>
                <title>Guiding Policymaking through the Fog of Covid19 induced Lockdowns</title>
                <meta name="description" content="Explore the application of the Taylor rule in guiding monetary policy during COVID-19. Analyze inflation and GDP growth impacts on policy decisions." />
                <meta name="keywords" content="Guiding policymaking, Covid-19-induced lockdowns, Taylor rule, monetary policy, Indian GDP, growth rate, supply disruptions, inflation, deflation, rate cuts, real rate, targeted inflation, GDP deviation, targeted GDP growth, inflation assumption, monetary policy decisions, economic impact." />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/blog/guiding-policy-making" />

                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>



            <Box id="blog-page" className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Typography variant='h3' className='subhead skblue al_center'>
                                Blog
                            </Typography>
                            <Typography variant='h1' className='blue mainhead al_center mb0'>
                                Guiding Policymaking through the Fog of  Covid19 induced Lockdowns
                            </Typography>
                            <Typography variant='h4' className='blue al_center'>
                                September 01, 2020
                            </Typography>
                        </Grid>
                    </Grid>

                    <Box className='whitebx'>



                        <Typography variant='h6' className='black jst'>Recently, Indian GDP for June 2020 Quarter printed -23.9% growth. Interestingly, just a few weeks before this print, RBI MPC announced that it was holding rates steady because CPI inflation was at 6.93%, outside the target range of 2% to 6%.</Typography>

                        <Typography variant='h6' className='black jst'>Is it appropriate to hold rates steady, when we have strong degrowth and supply disrupted (and not demand driven) inflation? There is a simple guide available. It is called Taylor rule of monetary policy.</Typography>

                        <Typography variant='h4' className='blue jst'>The Rule is as follows: </Typography>

                        <Typography variant='h6' className='black jst'>i=π+r+aπ (π-π* )+ay (y-y*)</Typography>

                        <Typography variant='h6' className='black jst'>Where
                            <Typography><strong>i  </strong>is the policy rate</Typography>
                            <Typography><strong>π  </strong>is the current inflation</Typography>
                            <Typography><strong>r  </strong>is the real rate that the economy can afford</Typography>
                            <Typography><strong>aπ </strong> is the relative importance of inflation deviation</Typography>
                            <Typography><strong>π* </strong> is the targeted inflation rate</Typography>
                            <Typography><strong>y  </strong>is the real GDP growth rate</Typography>
                            <Typography><strong>ay </strong> is the relative importance of GDP deviation</Typography>
                            <Typography><strong>y* </strong> is the targeted GDP growth rate</Typography>
                        </Typography>

                        <br />
                        <br />

                        <Typography variant='h6' className='black jst'>So, if we assume the following for March 2021
                            <Typography><strong>π </strong> is 7%</Typography>
                            <Typography><strong>r</strong>  , the real rate that the economy can afford, is 1%</Typography>
                            <Typography><strong>aπ </strong> is 0.5</Typography>
                            <Typography><strong>π* </strong>  is 4%</Typography>
                            <Typography><strong>y</strong>  is -10%</Typography>
                            <Typography><strong>ay </strong>   is 0.5</Typography>
                            <Typography><strong>y*</strong>   is 6%</Typography>
                        </Typography>

                        <Typography variant='h4' className='blue jst'>So, we get policy rate in March 2021 should be at</Typography>

                        <Typography variant='h6' className='black jst'><strong>i=7%+1%+0.5(7%-4%)+0.5(-10%-6%)=1.5%</strong> </Typography>

                        <Typography variant='h6' className='black jst'>
                            In other words, even if we assume that current supply chain disruptions continue causing high inflation, and at the same time oxymoronically assume that GDP growth rate for remainder of the year improves to -5.33% from latest reading of -24% (giving FY21 print at -10%); India will need policy rate of 1.5% rather than current 4.0%. Thus, we have room to cut rates by 250 basis points in next seven months.</Typography>

                        <Typography variant='h6' className='black jst'>Of course, I cannot imagine how inflation can stay at 7% when Keynesian multiplier is below 1.0 and when, in latest five months of pandemic, contrary to popular perceptions, RBI has not funded any central govt borrowings on net basis, and when commercial banks are refusing to lend to borrowers in spite of adequate deposit growth. However, we will go with strong inflation assumption and still find that India needs rate cuts of 250 basis points in next seven months.</Typography>

                        {/* <Box mt={5}>
                            <Subscribe />
                        </Box> */}

                    </Box>


                    <Box className='pages'>
                        <Writemessage3 pageVisited="Guiding Policymaking through the Fog of Covid19 induced Lockdowns" />
                    </Box>



                    <Blogback />


                </Container>
            </Box>

        </>
    );
}
