import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Helmet } from 'react-helmet';

import Casestudyback from '../../../../Component/Casestudyback'

import Banner from "../../../../asset/images/insight/case_studies/banner_streamlining_invoice.webp"
import Infographics from "../../../../asset/images/insight/case_studies/streamlining_invoice_processing85.webp"
import { Link } from 'react-router-dom';



export default function StreamliningInvoice() {



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet><meta charset="UTF-8" />
                <meta charset="UTF-8" />
                <title>Investment Firm Streamlines Invoice Processing by 85% - Find Out How</title>
                <meta name="description" content="See how a US investment management firm automated its invoice processing, achieving 85% reduction in time and operational costs." />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/case-studies/streamlining-invoice-processing-by-85-for-efficient-transaction-management" />
            </Helmet>

            <Box id="case-studies" className='section sec_bg new_cs' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={3} alignItems="top" justifyContent='center'>
                        <Grid item lg={9} md={9} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Success Stories
                                </Typography>
                                <Typography className='mainhead blue al_center'>
                                    Streamlining Invoice Processing <span className='lower'>by 85% for</span> Efficient Transaction Management
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Box >
                        <img src={Banner} className='w100' alt='Banner' />
                    </Box>


                    <Box className='whitebx'>
                        <Box>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Executive Summary
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                A US-based investment management firm specializing in fixed income alternative investments faced significant challenges with manual invoice processing. By automating data entry and invoice processing, the firm streamlined its workflows, dramatically reducing processing time and errors.
                            </Typography>
                        </Box>


                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                The Challenge
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                The firm encountered multiple issues:
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Time-consuming Manual Processes: </strong>  Manual data entry for invoices was labor-intensive and error prone.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Limited Transaction Visibility: </strong>  Inadequate reporting led to transparency issues in  <strong>invoice management</strong>.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> High Operational Costs: </strong> Cumbersome processes resulted in increased operational expenses.
                                        </Typography>
                                    </li>

                                </ul>
                            </Typography>
                        </Box>

                        <Box mt={3} className='mob_card'>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Solution: Enhanced Invoice Management Automation
                            </Typography>

                            <Grid container direction="row"
                                justifyContent="center"
                                alignItems="center">

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className="al_center" my={3}>
                                        <img src={Infographics} className="topbanner" alt="Solution: Enhanced Invoice Management Automation" />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box mt={1}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Results
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Time Efficiency: </strong> Reduced invoice processing time by 85%, freeing up resources for strategic tasks.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Improved Accuracy: </strong>  Enhanced accuracy in data sourcing and reporting reduced operational risks.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Increased Transaction Transparency: </strong> Improved visibility into transactions led to better financial oversight.
                                        </Typography>
                                    </li>

                                </ul>
                            </Typography>
                        </Box>

                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Key Takeaways
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>  Automation Drives Efficienc: </strong> Streamlining invoice processes through automation significantly improved operational efficiency.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Enhanced Financial Management: </strong>  Improved accuracy and visibility facilitated better financial decision-making.
                                        </Typography>
                                    </li>
                                </ul>
                                <Typography variant='h6' className='black jst'>
                                    <strong> Want to enhance your transaction management?</strong>
                                </Typography>

                            </Typography>

                            <Typography variant='h6' className='black jst' gutterBottom>
                                <Link to="/contact-us#contact_form" className="link">Let’s explore</Link> how automation can revolutionize your invoice processing and drive efficiency in your organization!
                            </Typography>

                        </Box>

                    </Box>


                    <Casestudyback />

                </Container>
            </Box>


        </>
    );
}