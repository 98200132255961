import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Helmet } from 'react-helmet';

import Casestudyback from '../../../../Component/Casestudyback'

import Banner from "../../../../asset/images/insight/case_studies/banner_enhanced_risk.webp"
import Infographics from "../../../../asset/images/insight/case_studies/enhanced_risk_management_solution_info.webp"
import { Link } from 'react-router-dom';


export default function EnhancedRiskManagement() {



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet><meta charset="UTF-8" />
                <meta charset="UTF-8" />
                <title>Risk Management for US Asset Managers | Decimal Point Analytics</title>
                <meta name="description" content="Transforming risk management for a leading US asset management firm with 50% time savings, accurate data, and effective hedging strategies. Learn more." />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/case-studies/enhanced-risk-management-capabilities-for-a-leading-us-based-asset-management-firm" />
            </Helmet>

            <Box id="case-studies" className='section sec_bg new_cs' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={3} alignItems="top" justifyContent='center'>
                        <Grid item lg={8} md={12} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Success Stories
                                </Typography>
                                <Typography className='mainhead blue al_center'>
                                    Enhanced Risk Management Capabilities <span className='lower'>for a</span> Leading US-based Asset Management Firm
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Box >
                        <img src={Banner} className='w100' alt='Banner' />
                    </Box>


                    <Box className='whitebx'>
                        <Box>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Executive Summary
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                In the competitive landscape of asset management, a leading US-based firm sought to enhance its risk management for asset management processes for better data accuracy and strategic insights. The firm faced challenges such as unstructured data management, maintaining investment categorization, and optimizing hedging strategies. Partnering with Decimal Point Analytics (DPA), they streamlined their operations by streamlining manual processes in financial risk management, improved data consistency, and achieved more effective risk management, enabling them to make better-informed decisions.
                            </Typography>
                        </Box>


                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                The Challenge
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                The asset management firm encountered several key challenges:
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Data Inconsistencies: </strong> Unstructured data management and varied categorization complicated risk model assessments.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Visibility Gaps:  </strong> Limited tracking of hedging spreads led to less transparency in investment positions.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Manual Processes:  </strong> Reliance on <strong>manual processes in financial risk management</strong> introduced inefficiencies and increased the potential for errors.
                                        </Typography>
                                    </li>
                                </ul>
                            </Typography>
                        </Box>

                        <Box mt={3} className='mob_card'>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Solution: Comprehensive Risk Management <span className='lower'>and </span> Data Optimization
                            </Typography>

                            <Grid container direction="row"
                                justifyContent="center"
                                alignItems="center">

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className="al_center" my={3}>
                                    <img src={Infographics} className="w100" alt=" Solution: Comprehensive Risk Management and Data Optimization" />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box mt={1}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Results
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                DPA's strategic approach provided measurable improvements:
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> 50% Time Savings:  </strong> Reduced time spent on data processing, enabling quicker decision-making.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Improved Risk Management:  </strong> Enhanced accuracy in benchmarking holdings with market indices.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Strategic Elevation:  </strong> Positioned the client’s risk department as a key contributor to the organization’s success.
                                        </Typography>
                                    </li>
                                </ul>
                            </Typography>
                        </Box>

                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Key Takeaways
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Effective Risk Structuring: </strong> Streamlined data management minimized errors and improved accuracy.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Data-Driven Insights: </strong> Leveraging historical data empowered more precise risk assessments.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Collaboration and Continuity: </strong> Consistent support ensured seamless operations during critical transitions.
                                        </Typography>
                                    </li>
                                </ul>
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                By optimizing their risk management for asset management processes and enabling a more data-driven approach, DPA helped the firm transform its operational capabilities. This collaboration not only enhanced their ability to manage risk but also positioned them to navigate market challenges with greater agility. As a result, the firm is now better equipped to focus on strategic opportunities, drive growth, and maintain a competitive edge in the market.
                            </Typography>

                            <Typography variant='h6' className='black jst' gutterBottom>
                                <Link to="/contact-us#contact_form" className="link">Contact us</Link> today to learn how we can help you achieve similar success.
                            </Typography>
                        </Box>

                    </Box>


                    <Casestudyback />

                </Container>
            </Box>


        </>
    );
}