
import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';

import Writemessage3 from '../../../Component/Writemessage3';

import Casestudyback from '../../../Component/Casestudyback'
import automateddatawarehouse1 from '../../../asset/images/insight/case_studies/automateddatawarehouse1.webp';
import automateddatawarehouse2 from '../../../asset/images/insight/case_studies/automateddatawarehouse2.webp';
import { Helmet } from 'react-helmet';

export default function Automated_data_warehouse() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);


  return (
    <>
      <Helmet>
        <meta charset="UTF-8" />
        <title>Automated Data Warehouse & Back-Testing Solutions </title>
        <meta name="description" content="Discover how Decimal Point Analytics created automated data warehouse and back-testing solutions for a global index provider." />
        <meta name="keywords" content="AI in data analytics,
artificial intelligence solutions company,
Corporate Data Providers,
Data Automation company" />
        <meta name="author" content="Decimal Point Analytics" />
        <link rel="canonical" href="https://www.decimalpointanalytics.com/case-studies/automated-data-warehouse-and-back-testing-solutions-for-global-index-provider-to-achieve-financial-benefits" />

        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>


      <Box id="case-studies" className='section sec_bg' component='section'>

        <Container maxWidth='lg' className='bdr'>

          <Grid container spacing={3} alignItems="top" justifyContent='center'>
            <Grid item lg={10} md={10} sm={12} xs={12}>
              <Box>
                <Typography variant='h3' className='subhead skblue al_center'>
                  Success Stories
                </Typography>
                <Typography variant='h1' className='mainhead blue al_center mb0'>
                  Automated Data Warehouse & Back-Testing Solutions That Helped a Global Index Provider Achieve Financial Benefits
                </Typography>

                <Typography className=' mainsmhead black al_center '>
                  How we saved time and effort for our clients by automating the process of maintaining existing indices, creating new indices, back-testing, and auditing of indices, and preparing various index reports.
                </Typography>
              </Box>
            </Grid>
          </Grid>


          <Writemessage3 pageVisited="Automated Data Warehouse & Back-Testing Solutions That Helped a Global Index Provider Achieve Financial Benefits" />


          <Box className='whitebx'>
            <Box>
              {/* <Typography variant='h4' className='blue' >Summary </Typography> */}
              <Typography variant='h6' className='black jst'>
                Our Client, a Global publisher of indices and a provider of index solutions to investment institutions, accosted us for assistance with Data Management and Report Generation for all their stakeholders. They joined forces with us to benefit from our expertise in data management, analytics, and automation, to help make better business decisions and enhance their Product and Data Delivery.
              </Typography>
            </Box>
          </Box>

          <Box className='whitebx' mt={1}>
            <Box>
              {/* <Typography variant='h4' className='blue' >Summary </Typography> */}

              <Typography variant='h6' className='black jst'>
                The Client wanted Decimal to design a centralized data warehouse and develop an Online Automated Tool with capabilities to manage the vast amount of data and generate multiple reports to provide auditable information to their stakeholders.
              </Typography>

              <Typography variant='h6' className='black jst'>
                <b>Automated Tool needed to be on:</b>
              </Typography>

              <Typography variant='h6' className='black jst'>
                <ul>
                  <li>
                    Extracting data from multiple sources, cleaning the data and creating a centralized data warehouse.
                  </li>
                </ul>
              </Typography>

              <Typography variant='h6' className='black jst'>
                <ul>
                  <li>
                    Selecting index constituents based on multiple criteria with the flexibility to fix a base value for the index.
                  </li>
                </ul>
              </Typography>

              <Typography variant='h6' className='black jst'>
                <ul>
                  <li>
                    Back-testing indices based on set rules and methodology.
                  </li>
                </ul>
              </Typography>

              <Typography variant='h6' className='black jst'>
                <ul>
                  <li>
                    Daily review of corporate actions impacting stock prices of the index constituents.
                  </li>
                </ul>
              </Typography>

              <Typography variant='h6' className='black jst'>
                <ul>
                  <li>
                    Daily adjustments of index divisor based on defined rules and calculations.
                  </li>
                </ul>
              </Typography>

              <Typography variant='h6' className='black jst'>
                <ul>
                  <li>
                    Automating the process of index reconstitution on quarterly and annual basis.
                  </li>
                </ul>
              </Typography>

              <Typography variant='h6' className='black jst'>
                <ul>
                  <li>
                    Generating monthly and quarterly index factsheets with charts, commentaries and latest news.
                  </li>
                </ul>
              </Typography>


            </Box>
          </Box>



          <Box className='whitebx' mt={1}  >
            <Grid container spacing={3} alignItems="top">
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <Box className='al_center'>
                  <Box className='casestudy_bg' style={{ backgroundImage: 'url(' + automateddatawarehouse1 + ')', }}></Box>
                </Box>
              </Grid>
              <Grid item lg={8} md={8} sm={8} xs={12}>
                <Box>



                  <Typography variant='h4' className='blue'>Challenges Faced by the Client</Typography>

                  <Typography variant='h6' className='black jst'>
                    <ul>
                      <li>
                        Before approaching Decimal, the Client had to manually design excel templates with calculations for back-testing multiple indices which were not flexible enough for the set criteria on index methodology and starting date.
                      </li>
                    </ul>



                    <ul>
                      <li>
                        The process of collation and validation of index constituents and time series data was tedious and inefficient.
                      </li>
                    </ul>



                    <ul>
                      <li>
                        Further, the Client had to manually check for Corporate Action news and undertake necessary adjustments in the index divisor.
                      </li>
                    </ul>

                    <ul>
                      <li>
                        Updating data and preparing charts for generating monthly and quarterly factsheets for 50+ indices was mostly manual using excel, and was thus error-prone and time-consuming.
                      </li>
                    </ul>

                    <ul>
                      <li>
                        Sourcing of earning releases for around 600+ index constituents and commentary writing for top gainers and losers were manual.
                      </li>
                    </ul>
                  </Typography>

                </Box>
              </Grid>
            </Grid>
          </Box>
          {/*-------------------------------------------------------*/}
          <Box className='whitebx' mt={1}  >
            <Grid container spacing={3} alignItems="center">
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <Box className='al_center'>
                  <Box className='casestudy_bg' style={{ backgroundImage: 'url(' + automateddatawarehouse2 + ')', }}></Box>
                </Box>
              </Grid>
              <Grid item lg={8} md={8} sm={8} xs={12}>
                <Box>

                  {/* <Box mb={2}>
                    <Link to='/analystpulse'>
                      <Typography variant='h4' className='blue' >Know More About Our Product on Analytics – AnalystPulse </Typography>
                    </Link>
                  </Box> */}

                  <Typography variant='h4' className='blue'>
                    The Solution
                  </Typography>

                  <Typography variant='h6' className='black jst'>
                    Our team comprising of multiple Data Analysts and Programmers set about implementing the solution with the aid of Machine Learning and Artificial Intelligence in a phased manner. We effectively integrated all Client data into a single platform by combining automation with statistics and manual insights to derive value out of it.
                  </Typography>

                  <Typography variant='h6' className='black jst'>
                    <ul>
                      <li>
                        The team created a detailed scoping document outlining the various phases of implementation, along with expected deadlines and, SOPs and checklists.
                      </li>
                    </ul>

                    <ul>
                      <li>
                        Automated processes were set to extract and validate data from various sources. This data included historical values of stock prices, shares outstanding, and relevant corporate actions.
                      </li>
                    </ul>

                    <ul>
                      <li>
                        A centralized data warehouse was built, and clean and standardized data was made accessible to the Client via APIs. Additionally, we created an interactive user interface linked to programs, which enabled the Client to conduct in-depth data analysis.
                      </li>
                    </ul>

                    <ul>
                      <li>
                        Application was deployed to back-test indices and calculate daily indices values based on the inputs provided on the interactive user interface.
                      </li>
                    </ul>

                    <ul>
                      <li>
                        Our Data Science team leveraged Natural Language Processing to read corporate action reports and incorporate relevant inputs in calculating index divisors.
                      </li>
                    </ul>

                    <ul>
                      <li>
                        Machine Learning algorithms were deployed to automatically read and collate relevant keywords from Company Earnings updates and draft commentaries on index constituents.
                      </li>
                    </ul>

                    <ul>
                      <li>
                        Finally, the team curated automated templates to generate monthly and quarterly index factsheets with charts and commentaries.
                      </li>
                    </ul>
                  </Typography>

                </Box>




              </Grid>
            </Grid>
          </Box>




          <Box className='whitebx' mt={1}  >

            <Box>

              <Typography variant='h4' className='blue'>
                The Result
              </Typography>

              <Typography variant='h6' className='black jst'>
                <ul>
                  <li>
                    The Client was able to access structured, cleaned and validated data from multiple vendors through the online data warehouse.
                  </li>
                </ul>

                <ul>
                  <li>
                    The interactive user interface tool enabled the Client to simplify the process of building customized indices, thereby saving substantial time and efforts and eliminating errors.
                  </li>
                </ul>

                <ul>
                  <li>
                    The Client was able to generate error-free Reconstitution reports and Index factsheets in a fraction of time than earlier.
                  </li>
                </ul>

                <ul>
                  <li>
                    The well-designed and informative factsheets with charts, tables and commentaries were positively received by our Client’s customers.
                  </li>
                </ul>

                <ul>
                  <li>
                    Additionally, the Decimal team created detailed process documents in the form of SOPs for the Automation tool.
                  </li>
                </ul>


              </Typography>

            </Box>



          </Box>

          <Box className='whitebx' mt={1}>
            <Box>
              {/* <Typography variant='h4' className='blue' >Summary </Typography> */}
              <Typography variant='h6' className='black jst'>
                Our solution helped in saving substantial efforts, time and costs for our Client while also aiding in strategic decision-making, thereby benefitting the overall business. We strive to deliver the Best for our Clients, and we are proud of achieving optimum, effective and dynamic results at a highly competitive price.
              </Typography>

              <Typography variant='h6' className='black jst'>
                If you want to know how to make your data work for you.
              </Typography>

              <Link to='/contact-us' className='link'>
                <Typography variant='h6' className='blue jst'>
                  Contact us now!
                </Typography>
              </Link>
            </Box>
          </Box>



          <Box className='graybbx' mt={1} >
            <Grid container spacing={1} alignItems="top" >
              <Grid item lg={12} md={12} sm={12} xs={12} >
                <Box className='al_left'>
                  <Typography variant='h4' className='black'>
                    Explore More:
                  </Typography>

                  <Link to='/case-studies/extraction-of-ghg-emission-data-from-esg-disclosure-reports' className='link'>
                    <Typography variant='h6' className='black jst' style={{ marginBottom: '0px' }}>
                      <b>Next Case Study:</b> Extraction of GHG Emission Data from ESG Disclosure Reports.

                    </Typography>
                  </Link>

                  <Link to='/articles/tokenization-of-assets-a-new-paradigm-for-businesses' className='link'>
                    <Typography variant='h6' className='black jst'>
                      <b>Insights:</b> Tokenization of assets a new paradigm for businesses.
                    </Typography>
                  </Link>

                </Box>
              </Grid>
            </Grid>

            {/* <Box mt={5}>
                        <Subscribe />
                    </Box> */}


          </Box>


          <Casestudyback />




        </Container>
      </Box>


    </>
  );
}

