import { Container, Grid, Typography, Box, Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";


import { Helmet } from "react-helmet";

import CarouselMulti from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import emp1 from "../asset/images/landing_page/emp1.webp"
import emp2 from "../asset/images/landing_page/emp2.webp"

import { IconButton } from "@mui/material";
import GetInTouch from "../Component/Get_in_touch";
import ModalVideo from "react-modal-video";
import HomeServices from "./LandingSections/HomeServices";
import HomeResources from "./LandingSections/HomeResources";
import HomeAwards from "./LandingSections/HomeAwards";
import HomeOurStrength from "./LandingSections/HomeOurStrength";



import AI from "../asset/images/landing_page/banners/aibanner.webp";
import HomeInnovationSecond from "./LandingSections/HomeInnovationSecond";
import SBA from "../asset/images/landing_page/banners/sba_banner.webp"
import CAT from "../asset/images/landing_page/banners/catastrophe_modelling.webp"
import LLM from "../asset/images/landing_page/banners/llm.webp"
import Doller from "../asset/images/landing_page/banners/turning_data_into_doller.webp"


export default function Index_new() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);


  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };



  const [isOpenAbt, setIsOpenAbt] = useState(false);
  const openModalAbt = () => {
    setIsOpenAbt(true);
  };


  return (
    <>


      <Helmet>
        <meta charset="UTF-8" />
        <h1>Decimal Point Analytics | Data Analytics | AI Consulting | Market Research</h1>
        <title>Decimal Point Analytics | Data Analytics | AI Consulting | Market Research </title>
        <meta name="description" content="Transform your business with Decimal Point Analytics. Our data analytics, AI consulting, and market research provide actionable insights for superior, data-driven decisions" />
        <meta name="keywords" content="data analytics, AI consulting, market research, data-driven decisions, customized solutions, actionable insights, Decimal Point Analytics" />
        <meta name="author" content="Decimal Point Analytics" />
        <link rel="canonical" href="https://www.decimalpointanalytics.com/" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />

        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org/",
              "@type": "WebSite",
              "name": "Decimal Point Analytics",
              "url": "https://www.decimalpointanalytics.com/",
              "potentialAction": {
                "@type": "SearchAction",
                "target": "https://www.decimalpointanalytics.com/search?q={search_term_string}https://www.decimalpointanalytics.com/insight",
                "query-input": "required name=search_term_string"
              }
            }
          `}
        </script>
      </Helmet>




      <Box id="new_landing_carousel">
        <CarouselMulti
          responsive={responsive}
          arrows={false}
          swipeable={true}
          draggable={true}
          showDots={true}
          infinite={true}
          autoPlay={true}
          pauseOnHover={false}
          keyBoardControl={true}
          // transitionDuration={1500}
          customTransition="opacity 200ms ease-in-out"
          autoPlaySpeed={9500}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-0-px"
        >

          {/* <>
            <Box className="mainsection1 web_banner" component="section" style={{ backgroundImage: "url(" + Bg_Webinar + ")" }} pb={2}>
              <Container maxWidth="lg">
                <Grid container spacing={1} alignItems="center" justifyContent='center'>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Box className="text_animated">

                      <Typography variant="h3" className="skblue al_c">
                        Webinar
                      </Typography>

                      <Box mt={2}>
                        <Typography variant="h2" className="black3" gutterBottom>
                          The Pivotal Role of
                        </Typography>
                      </Box>

                      <Typography variant="h2" className="blue fw6 mb0" gutterBottom>
                        ESG Factors in Private Equity Investing
                      </Typography>

                      <Box mt={2} className="desktop_only_timer">
                        <Grid container spacing={1} alignItems="center" justifyContent='center'>
                          <Grid item lg={4} md={4} sm={4} xs={12}>
                            <List>
                              <ListItem>
                                <ListItemAvatar className='main-icon'>
                                  <Avatar>
                                    <CalendarMonthOutlinedIcon />
                                  </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={<>
                                  <Typography variant='h6' className='skblue fw5 mb0 sm_text'>
                                    Date
                                  </Typography>
                                </>}
                                  secondary={<>
                                    <Typography variant='h6' className='blue fw5 mb0'>
                                      18<sup>th</sup> July 2024
                                    </Typography>
                                  </>} />
                              </ListItem>
                            </List>
                          </Grid>

                          <Grid item lg={4} md={4} sm={4} xs={12}>
                            <List className="left-brd">
                              <ListItem>
                                <ListItemAvatar className='main-icon'>
                                  <Avatar>
                                    <WatchLaterOutlinedIcon />
                                  </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={<>
                                  <Typography variant='h6' className='skblue fw5 mb0 sm_text'>
                                    Time
                                  </Typography>
                                </>}
                                  secondary={<>
                                    <Typography variant='h6' className='blue fw5 mb0'>
                                      10:00 AM EST
                                    </Typography>
                                  </>} />
                              </ListItem>
                            </List>
                          </Grid>


                        </Grid>
                      </Box>

                      <Box className="mobile_only_timer" my={1}>
                        <Typography variant="h6" className="countdown"> 18<sup>th</sup> July 2024 </Typography>
                        <Typography variant="h6" className="countdown"> | </Typography>
                        <Typography variant="h6" className="countdown"> 10:00 AM EST </Typography>
                      </Box>


                      <Box my={2} className="speaker">
                        <Grid container spacing={1} alignItems="center" justifyContent='center'>
                          <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Box>
                              <Box className="al_c">
                                <Box className='teama' style={{ backgroundImage: 'url(' + PN + ')', }}></Box>
                              </Box>
                              <Box className="al_c" mt={1}>
                                <Typography variant="h6" className='blue fw6 mb0 al_c'>
                                  Prasad Nawathe
                                </Typography>
                              </Box>
                              <Box className="al_c">
                                <Typography variant='h6' className='al_c sm_text'>
                                  Senior VP of ESG, <br /> Research & Data Operations
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>

                          <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Box>
                              <Box className="al_c">
                                <Box className='teama' style={{ backgroundImage: 'url(' + AJ + ')', }}></Box>
                              </Box>
                              <Box className="al_c" mt={1}>
                                <Typography variant="h6" className='blue fw6 mb0 al_c'>
                                  Ajay Jindal
                                </Typography>
                              </Box>
                              <Box className="al_c">
                                <Typography variant='h6' className='al_c sm_text'>
                                  Strategic Partner <br />& ESG Lead
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>

                      <Box mt={1}>
                        <Link to="/webinar">
                          <Button variant="contained" className='dpabtn_sm1'>
                            Register Now
                          </Button>
                        </Link>
                      </Box>
                    </Box>
                  </Grid>

                </Grid>
              </Container>
            </Box>
          </> */}

          <>
            <Box className="mainsection1" component="section" style={{ backgroundImage: "url(" + Doller + ")" }} pb={2}>
              <Container maxWidth="lg">
                <Grid container spacing={1} alignItems="center" justifyContent='center'>
                  <Grid item lg={12} md={12} sm={11} xs={10}>
                    <Box className="text_animated">
                      <Typography variant="h2" className="blue fw6 mb0" gutterBottom>
                        Turning Data into Dollars
                      </Typography>

                      <Box>
                        <Typography variant="h2" className="black3" gutterBottom>
                          Combining Advanced Analytics and Tech to Unlock Intel for Hedge Funds
                        </Typography>
                      </Box>

                      <Box mt={2}>
                        <Link to="/whitepapers/turning-data-into-dollars-combining-advanced-analytics-and-tech-to-unlock-intel-for-hedge-funds">
                          <Button variant="contained" className='dpabtn_sm1'>
                            Download the Whitepaper
                          </Button>
                        </Link>
                      </Box>
                    </Box>
                  </Grid>

                </Grid>
              </Container>
            </Box>
          </>

          <>
            <Box className="mainsection1" component="section" style={{ backgroundImage: "url(" + SBA + ")" }} pb={2}>
              <Container maxWidth="lg">
                <Grid container spacing={1} alignItems="center" justifyContent='center'>
                  <Grid item lg={12} md={12} sm={11} xs={10}>
                    <Box className="text_animated">
                      <Typography variant="h2" className="blue fw6 mb0" gutterBottom>
                        Streamline the SBA loan process:
                      </Typography>

                      <Box>
                        <Typography variant="h2" className="black3" gutterBottom>
                          Achieve Efficiency And Accuracy
                        </Typography>
                      </Box>

                      <Box mt={2}>
                        <Link to="/small-business-administration/the-2025-guide-to-scaling-sba-loan-processing-without-increasing-overheads">
                          <Button variant="contained" className='dpabtn_sm1'>
                            Learn More
                          </Button>
                        </Link>
                      </Box>
                    </Box>
                  </Grid>

                </Grid>
              </Container>
            </Box>
          </>

          <>
            <Box className="mainsection1" component="section" style={{ backgroundImage: "url(" + LLM + ")" }} pb={2}>
              <Container maxWidth="lg">
                <Grid container spacing={1} alignItems="center" justifyContent='center'>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Box className="text_animated">
                      <Typography variant="h2" className="blue fw6 mb0" gutterBottom>
                        Leveraging LLMs
                      </Typography>

                      <Box>
                        <Typography variant="h2" className="black3" gutterBottom>
                          to Minimize Technical Debt
                        </Typography>
                      </Box>

                      <Box mt={2}>
                        <Link to="/whitepapers/leveraging-large-language-models-to-minimize-technical-debt">
                          <Button variant="contained" className='dpabtn_sm1'>
                            Download the Whitepaper
                          </Button>
                        </Link>
                      </Box>
                    </Box>
                  </Grid>

                </Grid>
              </Container>
            </Box>
          </>

          <>
            <Box className="mainsection1" component="section" style={{ backgroundImage: "url(" + CAT + ")" }} pb={2}>
              <Container maxWidth="lg">
                <Grid container spacing={1} alignItems="center" justifyContent='center'>
                  <Grid item lg={12} md={12} sm={11} xs={10}>
                    <Box className="text_animated">

                      <Typography variant="h2" className="black3 mb0" gutterBottom>
                        Catastrophe Modeling Services
                      </Typography>

                      <Box>
                        <Typography variant="h2" className="blue fw6" gutterBottom>
                          for General Insurance & Reinsurance
                        </Typography>
                      </Box>

                      <Box mt={2}>
                        <Link to="/catastrophe-modeling-services">
                          <Button variant="contained" className='dpabtn_sm1'>
                            Learn More
                          </Button>
                        </Link>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Box>
          </>



          <>
            <Box className="mainsection1" component="section" style={{ backgroundImage: "url(" + AI + ")" }} pb={2}>
              <Container maxWidth="lg">
                <Grid container spacing={1} alignItems="center" justifyContent='center'>
                  <Grid item lg={12} md={12} sm={11} xs={10}>
                    <Box className="text_animated">
                      <Typography variant="h2" className="blue fw6 mb0" gutterBottom>
                        Generative AI:
                      </Typography>

                      <Box>
                        <Typography variant="h2" className="black3" gutterBottom>
                          Empower Your Enterprise with Data-Driven Decisions
                        </Typography>
                      </Box>

                      <Box mt={2}>
                        <Link to="/artificial-intelligence-solutions">
                          <Button variant="contained" className='dpabtn_sm1'>
                            Learn More
                          </Button>
                        </Link>
                      </Box>

                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Box>
          </>



        </CarouselMulti>
      </Box>
      <Box id="mainpg">

        <Box id="emp" className="section" component="section">
          <Container maxWidth="lg" className="bdr">
            <Grid container spacing={2} alignItems="top">
              <Grid item lg={6} md={6} sm={4} xs={12} >
                {/* <Box className="mainimg">
                <img src={emp1} className="emp1_img" alt="EMP_Innovation"></img>
              </Box>
              <Box className="img2">
                <img src={emp2} className="emp2_img" alt="EMP_Innovation"></img>
              </Box> */}

                <Box className="mainbox">
                  <img src={emp1} className="fold21img" alt="Dash1" />

                  <Box className="image-container">
                    <img src={emp2} className="slideimg childimg" alt="Dash2" />
                    <IconButton className="pulse-button playbtn" onClick={openModalAbt}></IconButton>
                  </Box>
                </Box>

              </Grid>

              <Grid item lg={6} md={6} sm={8} xs={12} >
                <Box className="content">
                  <Typography variant="h2" className="black">
                    Empowering Financial Success Through Advanced Analytics And Innovation
                  </Typography>
                  <Typography variant="h6" className="black jst">
                    Welcome to Decimal Point Analytics, your trusted partner in innovative data analytics, AI, and technology solutions. Since 2003, we have been at the forefront of driving client success on a global scale. Our strength lies in crafting unique solutions that seamlessly integrate AI, Machine Learning, and Automation, ensuring an efficient and effective journey through diverse landscapes.
                  </Typography>
                  <Typography variant="h6" className="black jst">
                    With a broad range of services – from full range of data management to detailed business intelligence dashboards, advanced data models to predictive analytics – we consistently anticipate and navigate market dynamics. Our commitment is to catalyze your enduring success by transforming challenges into opportunities through continuous innovation.
                  </Typography>
                  <Link to='/contact-us'>
                    <Typography variant="h6" className="blue jst fw5">
                      Discover how Decimal Point Analytics can fuel your financial trajectory today!
                    </Typography>
                  </Link>
                  <Box className="line"></Box>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>





        <Box className='section2 section_mob sec_bg' component='section' mt={4}>
          <Container maxWidth='lg' className='bdr'>

            <Grid container spacing={1} alignItems="top">
              <Grid item lg={12} md={12} sm={12} xs={12} data-aos="faddde-up">

                <Box className='al_center'>
                  <Typography variant='h4' className='skblue'>
                    DPA Mantras
                  </Typography>
                </Box>

                <Box className='al_center' mt={1}>
                  <Typography variant='h5' className='blue fw6'>
                    D<span className='lblue fw5'>eliver</span>P<span className='lblue fw5'>erformance</span>A<span className='lblue fw5'>dvantage</span>
                  </Typography>
                </Box>

                <Box className='al_center' mt={3}>
                  <Link to='/decimal-sutras'>
                    <Button variant="contained" className='dpabtn_sm1'>
                      Learn More
                    </Button>
                  </Link>
                </Box>


              </Grid>
            </Grid>
          </Container>
        </Box>



        <HomeServices />





        {/* old design innovation section */}
        {/* <HomeInnovation /> */}

        {/* New design innovation section */}
        <HomeInnovationSecond />
        {/* New design innovation section */}

        <HomeResources />

        <HomeAwards />

        <HomeOurStrength />

        <Box component="section" >
          <GetInTouch />
        </Box>


        {/* <FooterNew /> */}


      </Box>
      {/* video modals started */}

      <ModalVideo
        channel="youtube"
        isOpen={isOpenAbt}
        videoId="bUs3X9dJNbo"
        onClose={() => setIsOpenAbt(false)}
      />
    </>
  );
}
