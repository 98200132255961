import {  Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Eventback from '../../../Component/Eventback'
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';


export default function ExploringAiImpact() {


    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>Decimal Point Analytics at MENA InsurTech Summit 2024: AI in Insurance</title>
                <meta name="description" content="Discover how Decimal Point Analytics is shaping the insurance sector with AI insights from the MENA InsurTech Summit 2024. Explore our innovative solutions today!" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/exploring-ais-impact-on-the-insurance-sector-insights-from-the-mena-insurTech-association-summit-2024" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>



            <Box id='event-details' className='section sec_bg' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Events
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center'>
                                    Exploring AI's Impact on the Insurance Sector: Insights from the MENA InsurTech Association Summit 2024
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>

                    <Box className='whitebx'>

                        <Typography variant='h6' className='black jst'>
                            Our <strong>Chief Revenue Officer, Gautam Naidu</strong>, and  <strong>Senior Vice President of Business Development,Premal Dave</strong>, represented us at the<strong> MENA InsurTech Association Summit 2024</strong>, where the focus was on <strong>"AI Impact on the Insurance Value Chain 2024."</strong> This year’s summit brought together industry leaders and innovators to explore how AI is revolutionizing the insurance sector, from automating processes and improving risk assessment to enhancing customer experiences.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            Gautam and Premal engaged in discussions with key stakeholders, sharing our expertise and exchanging perspectives on the transformative role of AI across the insurance landscape. Their participation reinforced our commitment to leveraging cutting-edge technology to drive innovation and add value to the insurance ecosystem.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            To learn more about <strong>Decimal Point Analytics'</strong> offerings in Artificial Intelligence, <Link to="/artificial-intelligence-solutions" className='link'>explore our solutions here</Link>.
                        </Typography>

                    </Box>

                    <Eventback />

                </Container>
            </Box>



        </>
    );
}
