import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Writemessage3 from '../../../Component/Writemessage3';

import Casestudyback from '../../../Component/Casestudyback'

import Nav_cal1 from "../../../asset/images/insight/case_studies/nav_calculations_1.webp";
import Nav_cal2 from "../../../asset/images/insight/case_studies/nav_calculations_2.webp";

export default function Nav_calculations() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet>

                <meta charset="UTF-8" />
                <title>NAV Calculations For Portfolio Management | Decimal Point Analytics</title>
                <meta name="description" content="Case study on how Decimal Point Analytics with its automated portfolio management tools helped insurance company in NAV Calculations for Portfolio Management. " />
                <meta name="keywords" content="Data Automation company,
Data Cleaning Support,
portfolio alignment tool,
Portfolio monitoring solutions,
Research company,
Sector research provider" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/case-studies/nav-calculations-for-portfolio-management" />

                <meta name="viewport" content="width=device-width, initial-scale=1.0" />

            </Helmet>


            <Box id="case-studies" className='section sec_bg' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={3} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Success Stories
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center mb0'>
                                    NAV Calculations for Portfolio Management
                                </Typography>

                                <Typography className=' mainsmhead black al_center'>
                                    How we helped an Insurance Company in NAV Calculations for Portfolio Management
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Writemessage3 pageVisited="NAV Calculations for Portfolio Management" />



                    <Box className='whitebx'>
                        <Grid container spacing={3} alignItems="top">
                            <Grid item lg={4} md={4} sm={4} xs={12}>
                                <Box className='al_center'>
                                    <Box className='casestudy_bg' style={{ backgroundImage: 'url(' + Nav_cal1 + ')', }}></Box>
                                </Box>
                            </Grid>

                            <Grid item lg={8} md={8} sm={8} xs={12}>
                                <Box>
                                    <Typography variant='h6' className='black jst'>
                                        On a bright Monday morning in March 2018, John Harris, the senior fund manager of one of the largest insurance companies in Qatar, was discussing their process of evaluating portfolio performance with his team. John’s team used to examine thousands of transactions for several portfolios held by the company daily. The Net Asset Value (NAV) calculations involved in the process required weeks or even months to complete when done manually. The lack of a simple and structured process for NAV calculations made it difficult for John’s team to manage the portfolios. Jane, one of his fund managers, shared with the team details of the portfolio management solutions provided by DPA, which she had come across on LinkedIn. Observing the interest of his team members, John decided to consult DPA for a suitable solution that could mitigate his team’s challenges.
                                    </Typography>

                                    <Typography variant='h6' className='black jst'>On discussions with John, we scrutinized his situation and crafted an automation solution for him through which his team could avoid the cumbersome work of handling portfolio data manually and focus on their core responsibilities - launching new funds and increasing their client base. </Typography>


                                </Box>
                            </Grid>
                        </Grid>
                    </Box>


                    <Box className='whitebx' mt={1}  >

                        <Grid container spacing={3} alignItems="top">

                            <Grid item lg={4} md={4} sm={4} xs={12}>
                                <Box className='al_center'>
                                    <Box className='casestudy_bg' style={{ backgroundImage: 'url(' + Nav_cal2 + ')', }}></Box>
                                </Box>
                            </Grid>

                            <Grid item lg={8} md={8} sm={8} xs={12}>
                                <Box>
                                    <Box mb={2}>
                                        <Link to='/Datasolution' className='link'>
                                            <Typography variant='h4' className='blue' >Learn More About DPA’s Data Solutions</Typography>
                                        </Link>
                                    </Box>
                                    <Typography variant='h6' className='black jst'>
                                        The solution offered by us benefited John in numerous ways. At the outset, we helped John develop an automated process to manage the equity and fixed income portfolios. Further, we provided him a consolidated model for all the portfolios which gives a gist of all the investments, such as the thresholds, performance of the fund, the targets set, etc. The end report for the equity portfolio contained historical records of the trades made and the NAV summary to date. For the fixed income portfolios, we provided factsheets, which displayed the performance as compared to its benchmark and also included credit rating details, sector allocation, and risk ratios.
                                    </Typography>

                                    <Typography variant='h6' className='black jst'>The automation solution reduced the time taken by John and his team for NAV calculations to between 3 and 24 hours depending on the portfolio. The systematic and standardized files gave John and his team a lucid vision of the holdings they carry which helps them in better portfolio evaluation and decision making. Also, they presented the analysis provided by us to their potential investors and have been able to attract more investments. Now John’s team handles twice the number of portfolios than before and have been successful in increasing their client base. John commended our team’s efforts, and we are proud to have another happy client with us. </Typography>


                                </Box>
                            </Grid>
                        </Grid>
                    </Box>


                    <Box className='graybbx' mt={1} >
                        <Grid container spacing={1} alignItems="top" >
                            <Grid item lg={12} md={12} sm={12} xs={12} >
                                <Box className='al_left'>
                                    <Typography variant='h4' className='black'>
                                        Explore More:
                                    </Typography>

                                    <Link to='/case-studies/intelligent-document-processing-of-rent-rolls-for-an-asset-manager' className='link'>
                                        <Typography variant='h6' className='black jst' style={{ marginBottom: '0px' }}>
                                            <b>Next Case Study: </b>Intelligent document processing of Rent Rolls for an Asset Manager.
                                        </Typography>
                                    </Link>

                                    <Link to='/articles/big-data-and-analytics-the-way-forward-for-asset-managers' className='link'>
                                        <Typography variant='h6' className='black jst'>
                                            <b>Insights: </b>Big Data and Analytics, the way forward for Asset Managers!
                                        </Typography>
                                    </Link>

                                </Box>
                            </Grid>
                        </Grid>

                        {/* <Box mt={5}>
                            <Subscribe />
                        </Box> */}


                    </Box>


                    <Casestudyback />




                </Container>
            </Box>


        </>
    );
}
