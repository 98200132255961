import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import NewsRoomBack from '../../../Component/NewsRoomBack';
import { Helmet } from 'react-helmet';

import Quote from "../../../asset/images/media/press_release/quote_7mar_2024.webp"

export default function GlobalTransformations() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title> AI Trends Impacting Global Transformations: Insights by Shailesh Dhuri </title>
                <meta name="description" content="Explore Shailesh Dhuri's insights on AI's influence across industries like defense, agriculture, healthcare, and smart cities" />
                <meta name="keywords" content="AI trends, global transformations, defense, agriculture, healthcare, smart cities, ethical concerns, affordable AI adoption, skill development, global equity" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/global-transformations-the-impact-of-ai-trends-across-industries" />

                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            <Box className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>


                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    News Room
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center'>
                                Global Transformations: The Impact <span className='lower'>o</span>f AI Trends Across Industries
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>

                    <Box>
                        <Grid container direction="row"
                            justifyContent="center"
                            alignItems="center">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='whitebx'>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Our CEO,  <a href='https://www.linkedin.com/in/shaileshdhuri/' target='_blank' rel="noopener noreferrer">Shailesh Dhuri</a>,  has recently shared a quote on the title "Global Transformations: The Impact of AI Trends Across Industries." Shailesh emphasizes AI's influence on defense, agriculture, healthcare, and smart cities, accentuating the importance of addressing ethical concerns for its full potential. He highlights the need for affordable, accessible AI adoption and skill development to promote global equity.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        <a href='https://insidebigdata.com/2024/03/07/heard-on-the-street-3-7-2024' target='_blank' rel="noopener noreferrer">Click here</a> to read the full coverage
                                    </Typography>

                                    <Grid container direction="row" justifyContent="center" spacing={2} alignItems="top">
                                        <Grid item lg={7} md={8} sm={10} xs={12}>
                                            <Box pt={3} pb={3}>
                                                <img src={Quote} className='fullimg' alt='Quote' />
                                            </Box>
                                        </Grid>
                                    </Grid>

                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                    {/* <Box>
                        <Box className='graybbx' mt={2}>
                            <Grid container spacing={0} alignItems="center" justifyContent='center'>

                                <Grid item lg={4} md={4} sm={4} xs={6}>
                                    <a href="https://content.techgig.com/it-security/cybersecurity-in-fintech-ensuring-data-protection-and-privacy-in-a-world-of-connectivity/articleshow/107081925.cms" target="_blank" rel="noopener noreferrer">
                                        <Box
                                            className="awardsindexcircle"
                                            style={{ backgroundImage: "url(" + TG + ")" }}
                                        ></Box>
                                    </a>
                                </Grid>

                            </Grid>
                        </Box>
                    </Box> */}


                    <Box mt={2}>
                        <NewsRoomBack />
                    </Box>

                </Container>
            </Box>





        </>
    );
}
