import React, { useEffect, useState } from "react";

import {
  TextField,
  Button,
  Container,
  Grid,
  Typography,
  Box,
} from "@material-ui/core";
import Aos from "aos";
import axios from 'axios';

import "aos/dist/aos.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "../../Style.css";
import "react-toastify/dist/ReactToastify.css";
import 'react-phone-number-input/style.css';
import footerbg from "../../asset/images/crm/footer_form_bg.webp";

import flags from 'react-phone-number-input/flags';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import CustomePhoneNumber from "../CMR_CustomePhoneNumberFooter_Not_Mandatory";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { crmFormApi } from "../api/api";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

const validationSchema = yup.object({
  fullname: yup
    .string()
    .required("Name is required")
    .matches(/^[A-Za-z\s'-]+$/, "Enter valid name"),
  email: yup
    .string()
    .required("Email is required")
    .matches(
      /^[a-zA-Z0-9_]+(?:[-._][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+(?:[-.][a-zA-Z0-9_]+)*\.[a-zA-Z]{2,}$/,
      "Email is not valid"
    ),
  organization: yup.string().required("Organization is required"),
});

export default function CRMFooterForm() {

  const [phoneValueError, setPhoneValueError] = useState("");
  const [loading, setLoading] = useState(false);
  const [phoneValue, setPhoneValue] = useState("");
  const history = useHistory();

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const onSubmit = async (data) => {
    if (phoneValue && !isValidPhoneNumber(phoneValue)) {
      setPhoneValueError("Enter a valid phone number");
      return;
    }
    setLoading(true);
    try {
      const payload = {
        name: data.fullname,
        email: data.email,
        contact_no: phoneValue,
        organization: data.organization,
        requirements: data.requirements,
        form_type: "Get Proposal Request",
      };
      await crmFormApi(payload);
      history.push("/crm-form-submit");
    } catch (error) {
      toast.error("Failed to submit the form");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>


      <Box>
        <Box id="crmfooterform" className='section2 crmfooterform footersection' style={{ backgroundImage: "url(" + footerbg + ")" }}>
          <Container maxWidth="lg">
            <Box className='al_center'>
              <Typography variant="h2" className=" fw5 white mb0" >
                Streamline Your Business Operations Efficiently
              </Typography>
              <Typography variant="h4" className="fw4 white">
                Drop us a note and we will get back to you within 24 hrs
              </Typography>
            </Box>

            <Grid container spacing={2} alignItems='center' justifyContent="center">
              <Grid item lg={8} md={9} sm={10} xs={10}>

                <Box mt={3}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2} alignItems='flex-start'>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Box className="alltxfieldlg2 textfield1">
                          <TextField
                            id="filled-basic1"
                            label="Name*"
                            variant="outlined"
                            fullWidth
                            name="fullname"
                            {...register("fullname")}
                            error={!!errors.fullname}
                            // helperText={<span className="footer-error0">{errors.fullname?.message}</span>}
                            helperText={
                              errors.fullname ? (
                                <span>{errors.fullname?.message}</span>
                              ) : null
                            }
                          />
                        </Box>
                      </Grid>

                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Box className="alltxfieldlg2 textfield1">
                          <TextField
                            id="filled-basic1"
                            label="Business Email*"
                            variant="outlined"
                            fullWidth
                            name="email"
                            {...register("email")}
                            error={!!errors.email}
                            helperText={
                              errors.email ? (
                                <span>{errors.email?.message}</span>
                              ) : null
                            }
                          />
                        </Box>
                      </Grid>

                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Box className="alltxfieldlg2 textfield1">
                          <TextField
                            id="filled-basic1"
                            label="Organization*"
                            variant="outlined"
                            fullWidth
                            name="organization"
                            {...register("organization")}
                            error={!!errors.organization}
                            // helperText={<span className="footer-error">{errors.organization?.message}</span>}
                            helperText={
                              errors.organization ? (
                                <span>{errors.organization?.message}</span>
                              ) : null
                            }
                          />
                        </Box>
                      </Grid>

                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Box className="alltxfieldlg2 textfield1 ">

                          <PhoneInput
                            flags={flags}
                            international
                            name="phoneInput"
                            value={phoneValue}
                            onChange={setPhoneValue}
                            inputComponent={CustomePhoneNumber}
                          />

                        </Box>
                        {/* <Typography style={{ color: "#ffcccb", fontSize: "13px" }}>{phoneValueError}</Typography> */}
                        {phoneValueError && (
                          <Typography className="phone-error" style={{ marginTop: "3px" }}>
                            {phoneValueError}
                          </Typography>
                        )}
                      </Grid>

                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box className="alltxfieldlg2 textfield1 requirement">
                          <TextField
                            id="filled-basic1"
                            label="Your Requirements"
                            variant="outlined"
                            fullWidth
                            name="requirements"
                            multiline
                            rows={3}
                            {...register("requirements")}
                          />
                        </Box>
                      </Grid>

                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box className="al_center" mb={2}>
                          <Button
                            variant="contained"
                            className="dpabtn_3 d-blue"
                            type="submit"
                            disabled={loading}
                          >
                            {loading ? "Submitting..." : "Get Proposal"}
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </form>
                </Box>


              </Grid>
            </Grid>


          </Container>
        </Box>

      </Box>
    </>
  );
}
