import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import PropTypes from "prop-types";
import MumbaiTable from './EnergyEfficiencyTables/Mumbai_table';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import NashikTable from './EnergyEfficiencyTables/Nashik_table';
import GiftCityTable from './EnergyEfficiencyTables/GiftCity_table';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import energy from "../../asset/images/new_banner/energy_effi_banner.webp"


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
 

export default function Energy_efficiency() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <h1>Sustainable Business Practices at Decimal Point Analytics</h1>
                <title>Decimal Point Analytics: Leading the Way in Sustainable Business Practices</title>
                <meta name="description" content="Learn about Decimal Point Analytics' commitment to sustainability with 100% renewable energy and energy-efficient practices at our centers." />
                <meta name="keywords" content="Sustainability, Environmental Responsibility, Renewable Energy, Zero Emissions, Energy Conservation, Carbon Footprint Reduction, Energy Efficiency, Corporate Citizenship, India's Energy Landscape, Green Practices" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/energy-efficiency" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>

            <Box className="banner_bx">
                <Box style={{ backgroundImage: "url(" + energy + ")" }} className="banner_img">
                    <Box className="img_over bl_overlay">
                        <Grid container spacing={0} alignItems="center" justifyContent='center'>
                            <Grid item lg={4} md={5} sm={6} xs={8}>
                                <Box className="al_center">
                                    <Typography className="white" variant="h1">Energy Efficiency</Typography>
                                    <Box mt={'10px'}>
                                        <Typography className="white" variant="h4">Energy Efficiency of Delivery Operations</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>


            <Box className='section2 sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>


                    <Grid container direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className="al_center synopsic_bx">
                                <Typography variant='h6' className='black jst'>
                                    At <Link to="/" className='link'>Decimal Point Analytics</Link>, our commitment to sustainability and environmental responsibility extends beyond rhetoric; it is deeply embedded in our operational ethos. Recognizing the pressing global concern of rising energy consumption levels, we have taken concrete steps to mitigate our impact on the environment.
                                </Typography>

                                <Typography variant='h6' className='black jst'>
                                    Operating exclusively on 100% renewable energy sources, our Nashik Analytics Center, Gujarat GIFT City Center, and Mumbai corporate office stand as beacons of environmentally conscious practices. By ensuring zero emissions, we aim to set a precedent for responsible corporate citizenship.
                                </Typography>

                                <Typography variant='h6' className='black jst'>
                                    In a country like India, where conventional energy sources, such as coal, dominate the electricity generation landscape, the need for energy conservation is paramount. Decimal Point Analytics acknowledges this responsibility and actively works towards reducing our carbon footprint. India's commercial buildings contribute over 30% to the country's net electricity usage, and we understand the urgency of addressing this issue.
                                </Typography>

                                <Typography variant='h6' className='black jst'>
                                    Our strategic approach involves implementing energy-efficient and cost-effective techniques. From utilizing LED-based lighting systems to incorporating energy-saving appliances, we conscientiously make choices that align with our commitment to sustainability. Additionally, we run programs to encourage our employees to actively participate in saving energy on our office premises.
                                </Typography>

                                <Typography variant='h6' className='black jst'>
                                    The results of our efforts speak volumes – our energy consumption per employee has significantly decreased over the past few years. This downward trend underscores our organization's unwavering dedication to fostering a greener and cleaner environment.
                                </Typography>

                                <Typography variant='h6' className='black jst'>
                                    At Decimal Point Analytics, every small step we take brings us closer to achieving our larger sustainability goals, and we take pride in contributing to a more sustainable and responsible future.
                                </Typography>

                            </Box>

                        </Grid>
                    </Grid>
                </Container>
            </Box>



            <Box className='section' component='section'>
                <Container maxWidth="lg">

                    <Box id='energy_tab'>

                        <Box>
                            <Typography variant='h2' className='black al_center'>
                                Electricity Consumption Index
                            </Typography>
                        </Box>

                        <Grid container spacing={3}>
                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                <Box mb={1} id='table_title'>
                                    <Typography variant='h4' className='skblue align_c'>
                                        <LocationOnIcon sx={{ marginRight: '4px', fontSize: '18px' }} /> MUMBAI
                                    </Typography>
                                </Box>
                                <Box className='flx_center'>
                                    <MumbaiTable />
                                </Box>
                            </Grid>


                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                <Box mb={1} id='table_title'>
                                    <Typography variant='h4' className='skblue align_c'>
                                        <LocationOnIcon sx={{ marginRight: '4px', fontSize: '18px' }} /> NASHIK
                                    </Typography>
                                </Box>
                                <Box>
                                    <NashikTable />
                                </Box>
                            </Grid>

                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                <Box mb={1} id='table_title'>
                                    <Typography variant='h4' className='skblue align_c'>
                                        <LocationOnIcon sx={{ marginRight: '4px', fontSize: '18px' }} /> GIFT CITY
                                    </Typography>
                                </Box>
                                <Box>
                                    <GiftCityTable />
                                </Box>
                            </Grid>
                        </Grid>
                        <Box mt={1}>
                            <Typography variant='h6' className='skblue align_c table_text fw5'>
                                <FiberManualRecordIcon sx={{ marginRight: '4px', fontSize: '12px' }} />  Electricity Consumption Unit
                            </Typography>
                        </Box>
                    </Box>
                </Container>

            </Box>
        </>
    );
}
