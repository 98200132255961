import { Container, Grid, Typography, Box, Button } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';


import clickupbanner from "../../asset/images/clickup/bg_clickup.webp";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import solution from "../../asset/images/clickup/solution.webp"

import feature from "../../asset/images/clickup/feature.webp";

import success1 from "../../asset/images/clickup/success/seccess1.png";
import success2 from "../../asset/images/clickup/success/seccess2.png";
import success3 from "../../asset/images/clickup/success/seccess3.png";

import ClickupContactUs from '../../Component/forms/ClickupContactUs';

import solution1 from "../../asset/images/clickup/solutions/solution1.png";
import solution2 from "../../asset/images/clickup/solutions/solution2.png";
import solution3 from "../../asset/images/clickup/solutions/solution3.png";
import solution4 from "../../asset/images/clickup/solutions/solution4.png";
import solution5 from "../../asset/images/clickup/solutions/solution5.png";


export default function Clickup() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    // Scroll to form started
    const scrollToElement = (id) => {
        const element = document.getElementById(id);
        if (element) {
            const stickyItemsHeight = 30;
            const offset = element.getBoundingClientRect().top - stickyItemsHeight;


            setTimeout(() => {
                window.scrollBy({ top: offset, behavior: 'smooth' });
            }, 50);
        }
    };

    useEffect(() => {
        const { hash } = window.location;
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                const offset = 50;
                const elementPosition = element.getBoundingClientRect().top;
                const offsetPosition = elementPosition - offset;
                window.scrollBy({
                    top: offsetPosition,
                    behavior: "smooth",
                });
            }
        }
    }, []);
    // Scroll to form ended 

    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>Decimal Point Analytics - ClickUp Partner for Workflow Automation & Tracking</title>
                <meta name="description" content="Partner with Decimal Point Analytics for ClickUp solutions. Boost productivity with AI-powered project management, workflow automation, and custom dashboards." />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/clickup-partnership" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            <Box id="new_landing_carousel" className='ibmbanner'>
                <Box className="mainsection1" component="section" style={{ backgroundImage: "url(" + clickupbanner + ")" }} pb={2}>
                    <Container maxWidth="lg">
                        <Grid container spacing={1} alignItems="center" justifyContent='center'>
                            <Grid item lg={10} md={10} sm={11} xs={10}>
                                <Box className="text_animated mb0">
                                    <Typography variant="h2" className="black3" gutterBottom>
                                        Ready for a Smarter Approach to Project Management?
                                    </Typography>

                                    <Box>
                                        <Typography variant="h2" className="blue fw6 mb0" gutterBottom>
                                            Discover the Power of Decimal Point Analytics & <span className='dblack '>ClickUp</span> Together!
                                        </Typography>
                                    </Box>

                                    <Box mt={2}>
                                        <Link to="/clickup-partnership#clickup_contactus" onClick={() => scrollToElement('clickup_contactus')}>
                                            <Button variant="contained" className='dpabtn_sm1'>
                                                Learn More
                                            </Button>
                                        </Link>
                                    </Box>

                                </Box>
                            </Grid>

                        </Grid>
                    </Container>
                </Box>
            </Box>

            <Box className='section2' component='section'>
                <Container maxWidth='lg' className='bdr'>
                    <Grid container direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className="al_center">

                                <Typography variant='h2' className='black'>
                                    Decimal Point Analytics & <span className='dblack fw6'>ClickUp</span>: <br />Empowering Your Business <span className='lower'>with</span> Seamless Project Management
                                </Typography>

                                <Typography variant='h6' className='black al_center' gutterBottom>
                                    At Decimal Point Analytics, our partnership with ClickUp delivers unparalleled centralized project management platform and collaboration solutions tailored to your business. We enable your team to work smarter, faster, and with more visibility than ever before.
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box id='' className='section mob_card sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>


                    <Grid container spacing={1} alignItems="top">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='al_center' mb={1}>
                                <Typography variant='h2' className='black al_center'>
                                    Why Partner with Decimal Point Analytics <span className='lower'>for</span> <span className='dblack fw6'>ClickUp</span> Solutions?
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} alignItems="center" justifyContent='center'>
                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Box className='boxbg mnhg3'>
                                <Box className='al_center'>
                                    <img src={solution1} className='allic' alt='Certified ClickUp Expertise' />
                                </Box>
                                <Box>
                                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                                        <Grid item lg={12} md={7} sm={12} xs={12}>
                                            <Box className='cat_m'>
                                                <Typography variant='h3' className='blue al_center fw6'>
                                                    Certified ClickUp Expertise
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Box>
                                        <Typography variant='h6' className='black al_center'>
                                            Our team of certified ClickUp specialists ensures that every feature of this platform is optimized to enhance your performance with tools like task management with time tracking for better efficiency.
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Box className='boxbg mnhg3'>
                                <Box className='al_center'>
                                    <img src={solution2} className='allic' alt='Tailored to Your Business Needs' />
                                </Box>
                                <Box>
                                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                                        <Grid item lg={12} md={9} sm={12} xs={12}>
                                            <Box className='cat_m'>
                                                <Typography variant='h3' className='blue al_center fw6'>
                                                    Tailored to Your Business Needs
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Box>
                                        <Typography variant='h6' className='black al_center'>
                                            We customize ClickUp’s features, including workflow automation solutions and custom dashboards, to align perfectly with your specific industry requirements, driving seamless integration and long-term productivity gains.
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Box className='boxbg mnhg3'>
                                <Box className='al_center'>
                                    <img src={solution3} className='allic' alt='A Trusted, Experienced Partner' />
                                </Box>
                                <Box>
                                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                                        <Grid item lg={12} md={9} sm={12} xs={12}>
                                            <Box className='cat_m'>
                                                <Typography variant='h3' className='blue al_center fw6'>
                                                    A Trusted, Experienced Partner
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Box>
                                        <Typography variant='h6' className='black al_center'>
                                            As one of the largest users of ClickUp globally, we’ve successfully implemented the platform organization-wide, gaining deep insights into its full potential.
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Box className='boxbg mnhg3'>
                                <Box className='al_center'>
                                    <img src={solution4} className='allic' alt='Innovative Solutions' />
                                </Box>
                                <Box>
                                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                                        <Grid item lg={12} md={9} sm={12} xs={12}>
                                            <Box className='cat_m'>
                                                <Typography variant='h3' className='blue al_center fw6'>
                                                    Innovative Solutions
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Box>
                                        <Typography variant='h6' className='black al_center'>
                                            We’ve introduced several innovations to enhance internal operations, such as AI-powered project management tools for smarter workflows and better project tracking.
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Box className='boxbg mnhg3'>
                                <Box className='al_center'>
                                    <img src={solution5} className='allic' alt='Proven, Measurable Impact' />
                                </Box>
                                <Box>
                                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                                        <Grid item lg={12} md={9} sm={12} xs={12}>
                                            <Box className='cat_m'>
                                                <Typography variant='h3' className='blue al_center fw6'>
                                                    Proven, Measurable Impact
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Box>
                                        <Typography variant='h6' className='black al_center'>
                                            Our ClickUp solutions deliver real results—helping businesses reduce operational inefficiencies, foster real-time collaboration, and achieve measurable gains in productivity.
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>

                </Container>
            </Box>

            <Box id='' className='section mob_card' component='section'>
                <Container maxWidth='lg' className='bdr'>
                    <Grid container spacing={2} alignItems="center" justifyContent='center'>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className="al_center">
                                <Typography className="black" variant="h2">
                                    <span className='dblack fw6'>ClickUp</span> Solutions: Driving Results, Delivering Performance
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Box className='al_center'>
                                <img src={solution} className='w100' alt='ClickUp Solutions: Driving Results, Delivering Performance' />
                            </Box>
                        </Grid>

                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Box>
                                <Typography className="blue" variant="h6">
                                    <span className='black'>Our partnership with ClickUp offers:</span>
                                    <ul>
                                        <li>
                                            <Typography variant="h6" className="black jst" gutterbottom>
                                                <strong>Custom Ticket Management: </strong> Streamline ticket creation across channels, automate prioritization, and integrate email, forms, and APIs to improve response times and efficiency.
                                            </Typography>
                                        </li>

                                        <li>
                                            <Typography variant="h6" className="black jst" gutterbottom>
                                                <strong>Project Health Monitoring: </strong> Track critical metrics with real-time insights, manage bottlenecks, and receive automated alerts to ensure timely project delivery and resource optimization.
                                            </Typography>
                                        </li>

                                        <li>
                                            <Typography variant="h6" className="black jst" gutterbottom>
                                                <strong>Resource & Skillset Matrix: </strong> Optimize resource allocation and track capabilities with an integrated skill matrix, improving team composition and productivity.
                                            </Typography>
                                        </li>

                                        <li>
                                            <Typography variant="h6" className="black jst" gutterbottom>
                                                <strong>Organization-Wide Fever Chart: </strong> Gain a holistic view of project health, enabling faster decision-making and better risk management.
                                            </Typography>
                                        </li>

                                        <li>
                                            <Typography variant="h6" className="black jst" gutterbottom>
                                                <strong>Offshore Collaboration: </strong> Enhance team communication across time zones with automated workflows and custom notifications for seamless project handoffs.
                                            </Typography>
                                        </li>
                                    </ul>
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box id='' className='section sec_bg clickup_features' component='section'>
                <Container maxWidth='lg' className='bdr'>
                    <Grid container direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Grid item lg={10} md={10} sm={10} xs={10}>
                            <Box className="al_center className='ibm_textbx'">
                                <Typography variant='h2' className='black ibmrelative'>
                                    Features <span className='lower'>of</span> <span className='dblack fw6'>ClickUp</span>
                                </Typography>

                                <Typography variant='h6' className='black al_center ibmrelative' gutterBottom>
                                    ClickUp offers powerful tools that empower your teams, and we ensure these features are tailored for your success:
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className="al_center desktop-only">
                                <img src={feature} className="w100" alt="Features of ClickUp" />
                            </Box>

                            <Typography className="blue mobile-only" variant="h6">
                                <ul>
                                    <li>
                                        <Typography variant="h6" className="black jst" gutterbottom>
                                            Project Management & Task Tracking
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant="h6" className="black jst" gutterbottom>
                                            AI-Powered Assistance for Smarter Workflows
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant="h6" className="black jst" gutterbottom>
                                            Customizable Views (Gantt, Board, List, Calendar)
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant="h6" className="black jst" gutterbottom>
                                            Automated Reports & Dashboards
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant="h6" className="black jst" gutterbottom>
                                            Collaboration Tools: Whiteboards, Docs, and More
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant="h6" className="black jst" gutterbottom>
                                            Time Tracking & Goal Management
                                        </Typography>
                                    </li>
                                </ul>
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box id='' className='section mob_card clickup_page' component='section'>
                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='al_center'>
                                <Typography variant='h2' className='black'>
                                    How We Ensure Success <span className='lower'>with</span> <span className='dblack fw6'>ClickUp</span>
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} alignItems="top" justifyContent='center'>

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Box className="ibm_experties boxbg mnhg4">
                                <Box className='ora_iconbx al_left'>
                                    <img src={success1} className='allic' alt='Expert Implementation' />
                                </Box>
                                <Box my={2}>
                                    <Typography variant="h4" className="black al_left" gutterBottom> Expert Implementation </Typography>
                                    <Typography variant="h6" className="black al_left mb0">
                                        We manage ClickUp deployment from initial setup to full integration, ensuring a smooth transition into project planning and tracking software.
                                    </Typography>
                                </Box>

                            </Box>
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Box className="ibm_experties boxbg">
                                <Box className='ora_iconbx al_left'>
                                    <img src={success2} className='allic' alt='In-Depth Training & Support' />
                                </Box>
                                <Box my={2}>
                                    <Typography variant="h4" className="black al_left" gutterBottom> In-Depth Training & Support  </Typography>
                                    <Typography variant="h6" className="black al_left mb0">
                                        Our tailored programs help your team quickly master ClickUp’s capabilities. From day one, they will learn to navigate features like task management with time tracking to improve efficiency.
                                    </Typography>
                                </Box>

                            </Box>
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Box className="ibm_experties boxbg">
                                <Box className='ora_iconbx al_left'>
                                    <img src={success3} className='allic' alt='Continuous Innovation & Updates' />
                                </Box>
                                <Box my={2}>
                                    <Typography variant="h4" className="black al_left" gutterBottom> Continuous Innovation & Updates  </Typography>
                                    <Typography variant="h6" className="black al_left mb0">
                                        Stay ahead with the latest ClickUp enhancements. We provide proactive support to ensure your team stays productive and ready to leverage AI-powered project management tools.
                                    </Typography>
                                </Box>

                            </Box>
                        </Grid>

                    </Grid>
                </Container>
            </Box>

            <Box id='' className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='al_center' mb={1}>
                                <Typography variant='h2' className='black'>
                                    Success Story: <br />Transforming Project Management <span className='lower'> at</span> Decimal Point Analytics  <span className='lower'> with</span> <span className='dblack fw6'>ClickUp</span>
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid container direction="row" justifyContent="center" alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className="al_center className='ibm_textbx'">
                                <Typography variant='h6' className='black jst' gutterBottom>
                                    Decimal Point Analytics faced challenges with manual task tracking, limited visibility across teams, and inefficient resource allocation. To overcome these, DPA partnered with ClickUp, implementing custom project dashboards, automation tools, and real-time collaboration software. This transformation streamlined processes, enhanced communication, and optimized project workflows across the organization.
                                </Typography>

                                <Box className='al_left'>
                                    <Typography variant='h4' className='blue'>
                                        Solutions Overview
                                    </Typography>

                                    <Typography className="blue" variant="h6">
                                        <ul>
                                            <li>
                                                <Typography variant="h6" className="black jst" gutterbottom>
                                                    <strong>Custom Ticket Management System: </strong> Streamlined ticket creation and routing across multiple channels for faster response times.
                                                </Typography>
                                            </li>

                                            <li>
                                                <Typography variant="h6" className="black jst" gutterbottom>
                                                    <strong>Project Portfolio Health Monitoring: </strong> Real-time insights and alerts ensure proactive issue management and timely project delivery.
                                                </Typography>
                                            </li>

                                            <li>
                                                <Typography variant="h6" className="black jst" gutterbottom>
                                                    <strong>Resource and Skillset Matrix: </strong> Automated skill-based task assignments improve resource allocation and development.
                                                </Typography>
                                            </li>

                                            <li>
                                                <Typography variant="h6" className="black jst" gutterbottom>
                                                    <strong>Organization-Wide Fever Chart: </strong> Comprehensive project health visualization enables informed decision-making and risk management.
                                                </Typography>
                                            </li>

                                            <li>
                                                <Typography variant="h6" className="black jst" gutterbottom>
                                                    <strong>Real-Time Offshore Collaboration: </strong> Tailored communication protocols maintain project momentum across time zones.
                                                </Typography>
                                            </li>
                                        </ul>
                                    </Typography>
                                </Box>

                                <Box className='al_left' pt={1}>
                                    <Typography variant='h4' className='blue'>
                                        Results & Impact
                                    </Typography>

                                    <Typography className="blue" variant="h6">
                                        <ul>
                                            <li>
                                                <Typography variant="h6" className="black jst" gutterbottom>
                                                    <strong>35%</strong> reduction in project completion times through enhanced automation and quicker decision-making.
                                                </Typography>
                                            </li>

                                            <li>
                                                <Typography variant="h6" className="black jst" gutterbottom>
                                                    <strong>30%</strong> boost in productivity by focusing on high-impact tasks, driven by ClickUp’s automation and collaborative features.
                                                </Typography>
                                            </li>

                                            <li>
                                                <Typography variant="h6" className="black jst" gutterbottom>
                                                    Smarter resource allocation via real-time dashboards and health monitoring, minimizing bottlenecks and ensuring efficiency across teams.
                                                </Typography>
                                            </li>
                                        </ul>
                                    </Typography>
                                </Box>

                                <Box>
                                    <Link to='/case-studies/dpas-implementation-expertise-enhances-clickups-full-potential'>
                                        <Button variant="contained" className='dpabtn_sm1' endIcon={<NavigateNextIcon className='wh' />}>
                                            Read More
                                        </Button>
                                    </Link>
                                </Box>

                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box className='section' component='section'>
                {/* <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='al_center' mb={1}>
                                <Typography variant='h2' className='black'>
                                    Transform Your Workflow Today
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid container direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Box className="al_center className='ibm_textbx'">


                                <Typography variant='h6' className='black al_center' gutterBottom>
                                    Ready to unlock the full potential of ClickUp? Partner with Decimal Point Analytics and experience seamless project management time tracking and workflow automation like never before.
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className="al_center" mt={2}>
                                <Typography className="blue h2v2 fw5" variant="h2">
                                    Success Stories
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Box className='reportinfo'>
                                <Link to='/case-studies/dpas-implementation-expertise-enhances-clickups-full-potential'>
                                    <Box className="reportm_bg">
                                        <Box
                                            className="reportimg_bg"
                                            style={{
                                                backgroundImage: 'url(' + EnhancesClickUp + ')',
                                            }}
                                        >
                                        </Box>

                                        <Box className="reporttitle_box">
                                            <Typography className="reporttitle two">
                                                DPA’s Implementation Expertise Enhances ClickUp’s Full Potential
                                            </Typography>
                                        </Box>
                                        <Box className="line"></Box>
                                    </Box>
                                </Link>
                            </Box>
                        </Grid>
                    </Grid>

                </Container> */}

                <Box id='clickup_contactus' pt={3}>
                    <ClickupContactUs />
                </Box>
            </Box>



        </>
    );
}
