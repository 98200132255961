import { Divider, Container, Typography, Box, Grid, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Insightback from '../../../Component/Insightback'

import Writemessage3 from '../../../Component/Writemessage3';
import { Helmet } from 'react-helmet';


export default function How_can_asset_managers() {


    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);



    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <h1>How Can Asset Managers Engage With Businesses To Accelerate Drive Towards Net Zero" </h1>
                <title>Driving Toward Net Zero: Engaging Asset Managers for Sustainable Impact</title>
                <meta name="description" content="Discover how Asset Managers co-ordinating with their Invest Managers to drive businesses towards a Net Zero future & how ESG will impact investment strategies" />
                <meta name="keywords" content="net zero
net zero asset managers initiative
net zero strategy
net zero transition
net zero sustainability
esg meaning in business" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/articles/how-can-asset-managers-engage-with-businesses-to-accelerate-drive-towards-net-zero" />

                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            <Box id="article-page" className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center' >
                        <Grid item lg={10} md={12} sm={12} xs={12}>
                            <Typography variant='h3' className='subhead skblue al_center'>
                                Insight
                            </Typography>
                            <Typography variant='h1' className='blue mainhead al_center'>
                                How can Asset managers  engage with businesses  to accelerate drive towards Net Zero
                            </Typography>
                        </Grid>
                    </Grid>



                    <Box className='whitebx'>


                        <Typography variant='h4' className='blue mainsmhead'>Introduction:</Typography>

                        <Typography variant='h6' className='black jst'>Investment Managers (IM) are expected to be key drivers in monitoring the Environmental, Social, and Governance (ESG) performance of the assets they invest in. Their close watch is expected to exert pressure on companies to improve their ESG compliance. Asset owners want their IMs take an active stance in nudging companies forward in their ESG performance. While IMs take the lead, let us analyse how IMs ensure that their investor firms are driving in the right path towards their Net Zero targets.</Typography>

                        <Divider />

                        <br />

                        <Typography variant='h4' className='blue'>Ways in which IMs influence industries in their drive towards Net Zero targets:</Typography>
                        <br />


                        <Typography variant='h4' className='black'>1. Rise in active engagement with stakeholders of underlying companies </Typography>


                        <Typography variant='h6' className='black jst'>Actively engaging with underlying firms, and/or, governments, is one of the key tools leveraged by IMs to drive the Net Zero agenda. Such IMs discuss and have a say on potential decisions of such underlying firms related to transparency, improved behaviours and reduced uncertainty risk with regards to ESG compliance. For example, as Rob Walker, Global Co-Head of Asset Stewarship of State Street states , since 2014, they have engaged with more than 600 companies in multiple industries regarding climate change issues. In 2020, per <a href='https://i2.res.24o.it/pdf2010/Editrice/ILSOLE24ORE/ILSOLE24ORE/Online/_Oggetti_Embedded/Documenti/2020/11/01/2020-%20ESG-%20Manager-%20Survey-%20Results_EMEA.pdf' target='_blank' rel="noreferrer"> the survey results from Russell Investments</a> , the trend of rising engagement between IMs and firms was visible not only with equity managers but also among fixed income managers, where 92% of fixed income managers regularly engaged with underlying companies they invested, in 2020. The survey adds that only 10% of IMs across asset classes who responded, cited that they don’t engage with companies on ESG related terms. </Typography>

                        <Typography variant='h6' className='black jst'>The key takeaway is that many IMs are already increasingly vocal and active in engaging and the number will likely increase as they intend to hold issuers that are perceived to be laggards in climate transition efforts.</Typography>

                        <Divider />

                        <br />

                        <Typography variant='h4' className='black'>2. Using proxy voting strategies to assess and effect major changes in a company’s strategy</Typography>

                        <Typography variant='h6' className='black jst'>Asset managers also assert their stewardship efforts using proxy voting. It is one of the critical tools in hands of IMs to gain a firm’s attention and implement the changes that the IMs seek. IMs can vote against boards with governance deficiencies such as long-tenured directors, over-boarded directors or less-diverse boards. The IMs also ensure that there are voting guidelines to ensure they follow best practices in global stewardship while taking into account local market sensitivities. Such guidelines are updated frequently by IMs through thorough research into characteristics of well-governed companies.  </Typography>

                        <Typography variant='h6' className='black jst'>To explain the power of such proxy voting with an example, in 2020–2021 proxy year, Blackrock stated it voted against 255 directors in the 12 months ended June 2021, up from 55 directors in the year before that. The Asset Manager also increased its engagement levels to 2,300 companies on climate and natural capital in the mentioned period, up from 1,200. Similarly, State Street also highlighted that the bank did not hesitate to vote against re-electing executives if the bank’s recommended changes and concerns were not adhered to. </Typography>


                        <Divider />

                        <br />

                        <Typography variant='h4' className='black'>3. Engaging with regulators closely</Typography>

                        <Typography variant='h6' className='black jst'>In addition to working with clients and firms, IMs also work closely with global regulators and share opinions or inputs on how they can help in improve governance and sustainability measures for a market. Many IMs share their comments in public to regulatory bodies like the US Securities and Exchange Commission (SEC) on issues including, but not limited to, adopting new metrics, modifying disclosure requirements, independence of proxy advisors, etc.</Typography>

                        <Divider />

                        <br />

                        <Typography variant='h4' className='black'>4. Collaboration with other stakeholders</Typography>

                        <Typography variant='h6' className='black jst'>IMs, in their part, have collaborated with their like-minded peers to intensify their voices for ESG measures and garnered support. They have increasingly highlighted challenges posed by climate change and their “calls for action” have intensified in recent years. In mid-2021, more than 450 investors representing Assets Under Management (“AuM”) of over $41 trillion signed a “Global Investor Statement” urging Governments to tackle climate crisis. Similarly, the Glasgow Financial Alliance for Net Zero (“GFANZ”) launched by Mark Carney, in October 2021, laid out a scheme of policy requests to G20 governments to act on strategies to control climate change. </Typography>


                        <Divider />

                        <br />

                        <Typography variant='h4' className='black'>5. Offering more ESG related offerings to the market</Typography>

                        <Typography variant='h6' className='black jst'>IMs have also done their part by accelerating ESG-related product expansion across asset classes, as higher proportion of assets have gone into ESG and responsible investing strategy offerings in the last couple of years. Per the survey by Russell Investments, 36% of total ESG asset base in such IMs is US$1 billion total, vis-à-vis 43% in 2020 and 48% in 2019, reflecting the fact that the assets under purview of ESG strategies have grown in size and crossed about a billion dollars for most IMs.</Typography>


                        <Divider />

                        <br />

                        <Typography variant='h4' className='blue'>Solving the Engagement problem for asset managers </Typography>

                        <Typography variant='h6' className='black jst'>As IMs strive to steer the global ESG agenda, they also face challenges in their journey. IMs, particularly those with smaller teams, tend to avoid Engagement. They are content with doing the bare minimum on ESG: subscribe to a rating provider, keep the weighted average ESG score of their funds above a basic threshold. p</Typography>

                        <Typography variant='h6' className='black jst'>The reason many small to mid-size funds avoid Engagement is because it needs specific effort; they need to look at specific data on ESG parameters, have clarity on what data points they need to pick up and discuss with their folio companies. In other words, IMs not only need granular of their folio companies, they also need a ‘view’ on the data. Small and medium sized funds don’t have the manpower to do this.</Typography>

                        <Typography variant='h6' className='black jst'>Decimal Point Analytics is helping address some of these fund houses in engaging with their folio companies. Our structured database platform captures granular data on the securities in the folios of our clients. Having structured data makes subsequent analytics easy. We are able to pinpoint which ESG dimensions a company may be underperforming its peers on. We guide our clients to take up these issues in their Engagement discussions. Our database also provides time series of granular ESG data, helping a IM keep track of progress on their chosen ESG dimensions. </Typography>

                        <Divider />


                        {/* <Box>
                            <Subscribe />

                        </Box> */}
                    </Box>


                    <Box className='pages'>
                        <Writemessage3 pageVisited="How can Asset managers  engage with businesses to accelerate drive towards Net Zero" />
                    </Box>


                    <Insightback />


                </Container>
            </Box>

        </>
    );
}
