import { IconButton, Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Eventback from '../../../Component/Eventback'
import { Helmet } from "react-helmet";
import { withStyles } from '@material-ui/core/styles';

import Carousel from "react-elastic-carousel";

import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined';

import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';

import Thakur1 from "../../../asset/images/media/event/thakur_school/thakur1.webp"
import Thakur2 from "../../../asset/images/media/event/thakur_school/thakur2.webp"
import Thakur3 from "../../../asset/images/media/event/thakur_school/thakur3.webp"
import Thakur4 from "../../../asset/images/media/event/thakur_school/thakur4.webp"



const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);


export default function ThakurGlobalBusinessSchool() {


    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };


    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>Shailesh Dhuri at Thakur Global Business School: Insights on Emerging Markets</title>
                <meta name="description" content=" Discover highlights from CEO Shailesh Dhuri's talk at Thakur Global Business School on Competing and Managing in Emerging Markets, sharing key strategies for success." />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/shailesh-dhuris-talk-at-thakur-global-business-school-navigating-emerging-markets" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>



            <Box id='event-details' className='section sec_bg' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={8} md={8} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Events
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center'>
                                    Shailesh Dhuri's Talk at Thakur Global Business School: Navigating Emerging Markets
                                </Typography>

                            </Box>
                        </Grid>
                    </Grid>


                    <Box className='whitebx'>


                        <Typography variant='h6' className='black jst'>
                            Our CEO, <strong>Shailesh Dhuri</strong>, spoke at <strong>Thakur Global Business School (TGBS)</strong> on <strong>"Competing and Managing in Emerging Markets."</strong> His talk provided students with valuable insights, merging academic concepts with real-world applications.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            Shailesh’s session focused on equipping students with practical tools and strategies essential for navigating today’s competitive business environment.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            Here are some highlights from this informative session!
                        </Typography>

                        <Box className='al_center'>
                            <IconButton className='blue' onClick={handleClickOpen}>
                                <CameraAltOutlinedIcon />
                            </IconButton>
                        </Box>

                        <Box className='al_center'>
                            <Typography className='blink_text'>Click Here...</Typography>
                        </Box>


                    </Box>
                    <Eventback />


                </Container>
            </Box>

            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} className="b_modal teammodal">
                <DialogTitle className="feedname" id="customized-dialog-title" onClose={handleClose}>
                </DialogTitle>
                <DialogContent>
                    <Box>
                        <Grid container spacing={2}>
                            <Carousel itemsToShow={1} animateOut="slideOutDown"
                                animateIn="flipInX">
                                <Box className="boxcontent" style={{ padding: '0px' }}>
                                    <Box className='eventbg' style={{ backgroundImage: 'url(' + Thakur1 + ')', }}></Box>
                                </Box>

                                <Box className="boxcontent" style={{ padding: '0px' }}>
                                    <Box className='eventbg' style={{ backgroundImage: 'url(' + Thakur2 + ')', }}></Box>
                                </Box>

                                <Box className="boxcontent" style={{ padding: '0px' }}>
                                    <Box className='eventbg' style={{ backgroundImage: 'url(' + Thakur3 + ')', }}></Box>
                                </Box>

                                <Box className="boxcontent" style={{ padding: '0px' }}>
                                    <Box className='eventbg' style={{ backgroundImage: 'url(' + Thakur4 + ')', }}></Box>
                                </Box>
                            </Carousel>
                        </Grid>
                    </Box>
                </DialogContent>
            </Dialog>


        </>
    );
}
