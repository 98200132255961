import React from "react";

import { TextField, Button, Grid, Box } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { esgFormApi } from "../api/api";

import "react-toastify/dist/ReactToastify.css";

export default function SBADownloadForm() {
  const history = useHistory();
  const emailreg = /^[a-zA-Z0-9_]+(?:[-._][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+(?:[-.][a-zA-Z0-9_]+)*\.[a-zA-Z]{2,}$/;
  const nameValidation = /^[A-Za-z\s]+$/;
  const organizationReg = /^[a-zA-Z0-9 ,-./!@#$%^&*~]*$/;

  const { handleSubmit, control } = useForm();

  const onSubmit = async (data) => {
    let isValid = true;
    const newErrors = {};

    if (!data.name || data.name.trim() === "") {
      newErrors.name = "Name is required";
      isValid = false;
    } else if (!nameValidation.test(data.name)) {
      newErrors.name = "Name is not valid";
      isValid = false;
    }
    else if (!data.email || data.email.trim() === "") {
      newErrors.email = "Email is required";
      isValid = false;
    } 
    else if (!emailreg.test(data.email)) {
      newErrors.email = "Email is not valid";
      isValid = false;
    }
    else if (!data.company || data.company.trim() === "") {
      newErrors.company = "Company name is required";
      isValid = false;
    } 
    else if (!organizationReg.test(data.company)) {
      newErrors.company = "Company name is not valid";
      isValid = false;
    }

    if (!isValid) {
      Object.values(newErrors).forEach((error) => {
        toast.info(error, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
      return;
    }

    const payload = {
      email: data.email,
      report_type: 'SBA'
    };

    if (data.name.trim() !== "") {
      payload.name = data.name;
    }

    if (data.company.trim() !== "") {
      payload.company = data.company;
    }

    const d1 = await esgFormApi(payload);

    if (d1.data.status === true) {
      history.push("/sba-form-submit");
    } else {
      toast.error("Please Enter Valid details", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <>
      <Grid
        container
        spacing={1}
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Box className="sbapgeform">
            <Controller
              name="name"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  placeholder="Full Name"
                  variant="standard"
                  className="sbabanner_textfieldl"
                  inputProps={{ maxLength: 50 }}
                  fullWidth
                />
              )}
            />
          </Box>
        </Grid>

        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Box className="sbapgeform">
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  placeholder="Email ID"
                  variant="standard"
                  className="sbabanner_textfield"
                  fullWidth
                />
              )}
            />
          </Box>
        </Grid>

        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Box className="sbapgeform">
            <Controller
              name="company"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  placeholder="Company Name"
                  className="sbabanner_textfield"
                  variant="standard"
                  inputProps={{ maxLength: 50 }}
                  fullWidth
                />
              )}
            />
          </Box>
        </Grid>

        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Box className="sbapgeform">
            <Button
              variant="contained"
              fullWidth
              className="sbabannerbtn"
              type="submit"
              onClick={handleSubmit(onSubmit)}
            >
              Submit
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
