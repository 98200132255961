import { Divider, Container, Typography, Box, Grid, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Helmet } from 'react-helmet';

import Insightback from '../../../Component/Insightback'

import Writemessage3 from '../../../Component/Writemessage3';

export default function Independant_research() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <h1>Our analysis explores the recent reversal of JPMorgan's assessment on the Indonesian stock market, highlighting the importance of independent third-party research providers in making informed investment decisions." </h1>
                <title>JPMorgan Reverses Indonesian Stock Market Assessment: Analysis</title>
                <meta name="description" content="Discover the impact of JPMorgan reversing its Indonesian stock market assessment. Get insights with Decimal Point Analytics." />
                <meta name="keywords" content="jpmorgan market
news business
jp morgan chase bank
jp morgan stock" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/articles/independant-research" />

                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>



            <Box id="article-page" className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center' >
                        <Grid item lg={10} md={12} sm={12} xs={12}>
                            <Typography variant='h3' className='subhead skblue al_center'>
                                Insight
                            </Typography>
                            <Typography variant='h1' className='blue mainhead al_center'>
                                Here is our analysis of #JPMorgan reversing its assessment of #Indonesian stock market  following row, highlighting the advantage of an independent third-party research provider
                            </Typography>
                        </Grid>
                    </Grid>


                    <Box className='whitebx'>


                        <Typography variant='h6' className='black jst'>JPMorgan Chase & Co. reversing and upgrading its investment recommendation on the Indonesian stock market has again brought forth the issue of arm twisting of the investment banks by the emerging economies’ government. On January 16th 2017, the analysts at the investment bank raised their “tactical” view of the Indonesian equities by one level to “Neutral” in a research report, citing subsiding volatility in emerging-market bonds after the US presidential elections in November. The report indicated that “Indonesia’s macro fundamentals are strong, with high potential growth rate and low debt-to-GDP with economic reform.” However, it remained cautious about the volatility in the first half of 2017.</Typography>

                        <Typography variant='h6' className='black jst'>In November last year, JPMorgan had slashed its recommendation on the nation’s equity by two levels to “Underweight” from “Overweight”. The upgrade came just weeks after Indonesia's government decided to terminate the bank's services as a primary dealer for domestic sovereign bonds and as an underwriter for bonds sold to global markets.</Typography>

                        <Typography variant='h6' className='black jst'>JPMorgan denied any link between Indonesia’s decision to penalize the bank and the change in its current outlook on the country. The bank stated that all its research is based on extensive and objective analysis. Meanwhile, other research analysts from various other institutions have also expressed support for their peer as often Governments in Asia have tried to pressure banks. Earlier too, investment banks have revoked their negative stance, lost lucrative deals or bid analysts a “goodbye” as their negative views provoked controversies. Back in August 2015, Indonesia’s then Finance Minister Bambang Brodjonegoro had announced that they have “sanctioned” JPMorgan for a negative report recommending smaller exposure to Indonesian government bonds. Another such incident took place in October 2006, where Morgan Stanley chief Asia-Pacific economist Andy Xie had to leave abruptly. This was following the leak of his internal email, where he ridiculed Singapore as a “money laundering centre” for Indonesia, and the ASEAN group of nations as a failure. In July 2014, Banco Santander fired an analyst and other employees involved in the publication of a critical note, which suggested that the then Brazilian President Dilma Rousseff’s policies would hurt the country’s economy.</Typography>

                        <Typography variant='h6' className='black jst'>These incidents also highlight the porous wall between the investment banking deal-making function and the research function within the banking groups. It seems that research is somewhat biased to favour the some of the deal making side of the business which generates most of the revenues in fees.</Typography>

                        <Typography variant='h6' className='black jst'>This brings in focus the advantage of engaging with a neutral, unbiased & independent third-party research provider which is not influenced by any such action and the findings are entirely based on the fundamentals and the audited figures </Typography>

                        <Divider />



                        {/* <Box>
                            <Subscribe />

                        </Box> */}
                    </Box>

                    <Box className='pages'>
                        <Writemessage3 pageVisited="Here is our analysis of JPMorgan reversing its assessment of Indonesian stock market following row highlighting the advantage of an independent third-party research provider" />
                    </Box>

                    <Insightback />


                </Container>
            </Box>

        </>
    );
}
