import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import N18 from "../../../asset/images/media/news18.webp"
import NewsRoomBack from '../../../Component/NewsRoomBack';
import { Helmet } from 'react-helmet';

export default function DigitalAssetsFuture() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title> Digital Assets Outlook 2024: Opportunities and Growth Trends </title>
                <meta name="description" content="Discover the optimistic outlook for digital assets in 2024, including real-world asset tokens, institutional interest, and regulatory challenges." />
                <meta name="keywords" content="digital assets, cryptocurrency, blockchain, real-world assets, RWA, institutional interest, financial markets, regulatory challenges, asset tokenization" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/digital-assets-can-look-forward-to-a-brighter-future-in-2024" />

                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            <Box className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>


                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    News Room
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center'>
                                    Digital Assets Can Look Forward <span className='lower'>to</span> A Brighter Future <span className='lower'>in</span> 2024
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>

                    <Box>
                        <Grid container direction="row"
                            justifyContent="center"
                            alignItems="center">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='whitebx'>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        <em>While the spillover of 2022 continued into 2023, the cryptocurrency and the broader digital asset world have had a much better year; the situation is vastly different now </em>
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        After a turbulent 2022, digital assets had a much better year in 2023, and have ended the year with a bunch of positives: Institutional interest is on the upswing, real-world asset tokens are an emerging category that could see considerable action.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        While the initial few months of 2023 showed a residual impact of the torrid 2022; on the balance, the year has turned out to be quite positive for the digital asset world. As we head into 2024, the mood appears distinctly upbeat.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        While the spillover of 2022 continued into 2023, the cryptocurrency and the broader digital asset world have had a much better year. The first quarter of 2023 had some rocky moments, led by the collapse of Silicon Valley Bank (SVB), Silvergate and Signature Bank, all of which had closed ties to the crypto world. These simultaneous bank failures temporarily rocked the digital world, with stablecoins like USD Coin (USDC), Tether (USDT), Terra (UST) and DAI losing their dollar pegs by varying degrees in the turbulent March-July 2023 phase.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Cut to now, and the situation is vastly different. The weakness in the traditional banking system, plus the cleansing from the system of rogue elements like FTX, became a blessing for bitcoin and other cryptocurrencies. The first application of a new technology attracts nefarious elements and the 2022-23 phase helped take out some of these actors from the market. It was heartening to see that investors could focus on the underlying strength of DLTs and ignore the noise.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Bitcoin, and several other cryptos had a banner year, ignoring the residual crisis of 2023. Bitcoin gained over 150 per cent in 2023. The broader crypto market did well, increasing 90 per cent in size, from $840 billion to $1.6 trillion from January to December of 2023, according to The Block. The stablecoins have also recovered their pegs; the total market cap of stablecoins finally rose in October 2023 after nearly 19 months of consecutive outflows.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Going into 2024, among the likely big events was the expected arrival of Spot BTC ETFs in January 2024. This did come through as expected, on January 10 the SEC approved 11 applications, including from BlackRock, Fidelity, Invesco, and VanEck, among others. This could be a watershed moment for the crypto market, increasing both retail and institutional participation.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        The trend we are most excited about is the increasing interest in the broader ‘digital assets’ class, particularly tradable tokens based on real-world assets (RWAs). While the concept existed as far back as 2017, RWAs did not quite take off till 2023, when they seem to have finally caught investor interest, and rightly so, given the significant benefits they offer.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Tokens are programmable, permissionless, trustless, and transparent. Their underlying protocols contain smart contract codes that automate the actions required in an agreement or contract, typically stored on a blockchain and run when predetermined conditions are met. They can automatically issue tokens when certain conditions are met, thus reducing the need for traditional intermediaries, arbitration costs, and fraud losses. They are ideally suited for real-world areas like real estate, stocks and bonds, commodities, and lending.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        In 2023, there was a significant rise in the RWA assets. According to CCdata, the Total Value Locked (TVL) in RWA protocols experienced a 700 per cent increase in 2023, nearing a level of USD 1B (not counting stablecoins). While this is still minuscule, the potential being talked about is immense.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Boston Consulting Group estimates that tokenised security assets could represent approximately 10 per cent of the global GDP by 2030. A report by 21.co estimates the market value for tokenised assets will be between $3.5 trillion in the bear-case scenario and $10 trillion in the bull case by 2030.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        These estimates represent a few orders of magnitude increase from the current size. This time, it appears that the uptick in RWA interest represents a fundamental change, particularly given the institutional interest in this space.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Let’s look deeper into what’s happening in the real-world digital asset world. Tokenised treasures have emerged as instruments with rising traction. Franklin OnChain U.S. Government Money Fund, launched in 2021, is the leader in this segment, with assets of over USD 330m. Several other issuers like Ondo, Mountain Protocol, Maple, Backed Finance, Open Eden have offerings that are steadily gaining in size.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        RWAs are ideally suited for institutional participation, given that most of them promise regular yield. As a recent report ‘RWA Tokenization, The Next Generation of Capital Markets’ by DigiFT and HashKey Capital says, “RWA assets bridge the gap between the real world, particularly for the traditional financial sector, and the digital assets world. From the traditional financial industry, RWAs offer reduced time and cost of financial transaction settlements, transparency and traceability, enabling overall cost-efficiency. For the digital assets industry, RWAs offer stable returns, better liquidity, alternative yield sources, and portfolio and collateral diversification.”
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        Leading traditional financial firms like JPMorgan, Goldman Sachs, UBS, HSBC and fund houses like Fidelity and Blackrock are developing programs to tap into digital assets. The key challenge to rapid adoption will be (lack of) regulation, particularly where participants span more than one jurisdiction.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        RWAs are expected to rapidly move into other asset classes like private credit, real estate, municipal bonds, structured credit, carbon credits, commodities and even areas like art. The report by 21.co lists 9 asset classes (including stablecoins) where RWA already exist. Among the asset classes it lists are commodities, G-secs, asset backed finance, real estate, corporate bonds, private equity, public equity, and private funds.
                                    </Typography>

                                    <Typography variant='h6' className='black jst' gutterBottom>
                                        These are exciting developments. We can expect some developments on the Indian front as well, with local offerings of RWA for global investors.
                                    </Typography>

                                </Box>

                            </Grid>
                        </Grid>
                    </Box>

                    <Box>
                        <Box className='graybbx' mt={2}>
                            <Grid container spacing={0} alignItems="center" justifyContent='center'>


                                <Grid item lg={4} md={4} sm={4} xs={6}>
                                    <a href="https://www.news18.com/business/digital-assets-can-look-forward-to-a-brighter-future-in-2024-8828728.html" target="_blank" rel="noopener noreferrer">
                                        <Box
                                            className="awardsindexcircle"
                                            style={{ backgroundImage: "url(" + N18 + ")" }}
                                        ></Box>
                                    </a>
                                </Grid>

                            </Grid>
                        </Box>
                    </Box>


                    <Box mt={2}>
                        <NewsRoomBack />
                    </Box>

                </Container>
            </Box>





        </>
    );
}
