import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Eventback from '../../../Component/Eventback'
import { Helmet } from "react-helmet";


export default function IFSCATalentVista2024() {


    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>Decimal Point Analytics at IFSCA Official Talent Vista 2024 | GIFT City</title>
                <meta name="description" content="Shailesh Dhuri (CEO) and Arun Singh (CPO) from Decimal Point Analytics participated in the IFSCA Official Talent Vista 2024 at GIFT City, exploring finance and tech innovations" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/decimal-point-analytics-at-ifsca-official-talent-vista-2024" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>



            <Box id='event-details' className='section sec_bg' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={8} md={8} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Events
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center'>
                                    Decimal Point Analytics at IFSCA Official Talent Vista 2024
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Box className='whitebx'>

                        <Typography variant='h6' className='black jst'>
                            On November 15, 2024, Shailesh Dhuri, CEO, and Arun Singh, CPO, represented <strong>Decimal Point Analytics</strong> at the prestigious IFSCA Official Talent Vista 2024 held at GIFT City.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            The event brought together thought leaders, innovators, and industry experts to discuss transformative ideas shaping the future of finance and technology.
                        </Typography>

                        <Typography variant='h6' className='black jst' gutterBottom>
                            It was a valuable opportunity to engage with peers, share insights, and explore collaborations, furthering our commitment to innovation in data analytics and AI.
                        </Typography>

                    </Box>

                    <Eventback />

                </Container>

            </Box>

        </>
    );
}
