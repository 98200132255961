import { Divider, Container, Typography, Box, Grid, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Helmet } from 'react-helmet';

import Insightback from '../../../Component/Insightback'

import Writemessage3 from '../../../Component/Writemessage3';

export default function Data_visualization() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <h1>Power of Visual Communication: Data Visualization for Compelling Reporting" </h1>
                <title>Data Visualization & The Art Of Effective Storytelling</title>
                <meta name="description" content="Data Visualization: An Important aspect for financial & enterprise businesses. Transform existing reporting platform into effective visualization engines." />
                <meta name="keywords" content="visual analytics
data visualization tools
data visualization examples
data analytics and visualization
tableau data visualization" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/articles/data-visualization-and-the-art-of-effective-storytelling" />

                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            <Box id="article-page" className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center' >
                        <Grid item lg={10} md={12} sm={12} xs={12}>
                            <Typography variant='h3' className='subhead skblue al_center'>
                                Insight
                            </Typography>
                            <Typography variant='h1' className='blue mainhead al_center'>
                                Data Visualization and the Art of  Effective Storytelling
                            </Typography>
                        </Grid>
                    </Grid>

                    <Box className='whitebx'>

                        <Typography variant='h6' className='black jst'>Mr. Omkar Shah is the CEO of a well-known retail chain in India, one of the fiercely competitive retail markets in the world. Although the company had been doing well, Mr. Shah was stressed of late due to slowing profitability and below-par growth in many new branches. He was not able to pin-point the exact reasons for this decline, despite having all the data and wisdom of the best minds in the sector at his disposal. Enter business intelligence and data visualization. We will get back to Mr. Shah a little later.</Typography>

                        <Typography variant='h6' className='black jst'>Organizations today, have access to massive in-depth data. However, possessing data and using it effectively are two entirely different things - the latter is equally, if not more important. This is where data visualization comes into play. The concept of ‘a picture is worth a thousand words’ comes in handy when making a point that could take a lot longer without the benefit of visuals. A good visualization has the power to turn static data into a cohesive story that can be understood by everyone.</Typography>

                        <Divider />
                        <br />

                        <Typography variant='h4' className='blue'>Data Visualization: Key Trends</Typography>

                        <Typography variant='h6' className='black jst'>Data visualization tools and techniques are continuing to evolve at a rapid pace and below, we highlight some of the key trends in the space that are likely to explode in the near-term.</Typography>

                        <Divider />
                        <br />


                        <Typography variant='h4' className='blue'>Ø Geospatial Mapping and Analytics </Typography>

                        <Typography variant='h6' className='black jst'> Visualization tools are fast evolving into geo-analytic powered real-time dashboards and have become more and more integrated and interactive. This does not entail just a map with eye-catching imagery but includes the ability to select geographic attributes and view the analytical effects across all metrics within a dashboard. Today, interactive maps are a must-have to view financial, product, and customer data across geographic locations.</Typography>

                        <Divider />
                        <br />


                        <Typography variant='h4' className='blue'>Ø Mobile-Friendly Tools</Typography>

                        <Typography variant='h6' className='black jst'>Another exciting trend that has arisen due to rising mobile usage is the need for visualizations to be optimized for mobile devices. In today’s hyper-connected digital world, visualizations need to be dynamic and mobile-friendly to benefit traveling executives. This has resulted in the incorporation of mobile-friendly layouts into almost all data visualization platforms available in the market today.</Typography>

                        <Typography variant='h6' className='black jst'>On this front, Stakeboat Capital, an Indian private equity firm, uses dashboards modeled and customized specifically to be viewed on handheld devices such as iPads, and other tablets. These dashboards enable portfolio managers to get a higher-level view of their portfolio companies and if needed, seek further details from analysts and create contingencies to overcome any surprises.</Typography>

                        <Divider />
                        <br />

                        <Typography variant='h4' className='blue'>Ø AI-ML in Visualization</Typography>

                        <Typography variant='h6' className='black jst'>Artificial intelligence (AI) and machine learning (ML) are increasingly being employed to help users detect patterns in large and complex datasets. Salesforce Einstein, a tool from Salesforce.com, can be used to apply advanced AI-ML algorithms to discover insights about customer behavior.</Typography>

                        <Divider />
                        <br />

                        <Typography variant='h4' className='blue'>Ø Natural Language Processing</Typography>

                        <Typography variant='h6' className='black jst'>Natural language processing (NLP) helps in capturing current trends and mood for a topic or product, and even answer context-dependent questions. The rapid development of NLP techniques over the last few years have provided both individuals and organizations access to powerful, consistent, and scalable textual analytics tools.</Typography>

                        <Typography variant='h6' className='black jst'> Edmond de Rothschild REIM, the real estate investment management platform of the Edmond de Rothschild Group, has been developing a digital dashboard system to track tenants across its commercial properties. The system presents a 360° view of tenants vis-à-vis a sentiment analytics module combining social media feeds, company, sector, and macroeconomic news as well as tenant financials. The technology used in this engine system ranges from the automated collection of information, NLP, ML techniques, model building, and visualization tools.</Typography>

                        <Divider />
                        <br />

                        <Typography variant='h4' className='blue'>What the (not so distant) Future Holds </Typography>

                        <Typography variant='h6' className='black jst'> Back to where we began, Mr. Shah was able to turn around things at his company by employing data visualization tools to identify problem areas impeding his company’s growth and make better decisions. Here are some of the sample visualization dashboards which helped Mr. Shah gain a 360° view of his organization enabling better decision-making.</Typography>

                        <Typography variant='h6' className='black jst'> Organizations that can position themselves and harness data visualization in unique and highly profitable ways, will likely see significant business opportunities and avenues for growth going forward.</Typography>

                        <Typography variant='h6' className='black jst'> The Data and Business Intelligence team at Decimal Point Analytics has helped several financial and non-financial institutions transform their existing reporting platforms into easy to comprehend and simple yet effective visualization engines.</Typography>

                        <Divider />

                        {/* <Box>
                            <Subscribe />

                        </Box> */}
                    </Box>

                    <Box className='pages'>
                        <Writemessage3 pageVisited="Data Visualization and the Art of Effective Storytelling" />
                    </Box>

                    <Insightback />


                </Container>
            </Box>

        </>
    );
}
