import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';

import Casestudyback from '../../../Component/Casestudyback'
import { Helmet } from 'react-helmet';
import Writemessage3 from '../../../Component/Writemessage3';

import Int_doc1 from "../../../asset/images/insight/case_studies/intelligent_document_processing1.webp";
import Int_doc2 from "../../../asset/images/insight/case_studies/intelligent_document_processing2.webp";


export default function Intelligent_doc_processing() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet>

                <meta charset="UTF-8" />
                <h1>Intelligent Document Processing Of Rent Rolls For An Asset Manager</h1>
                <title>Intelligent Document Processing of Rent Rolls for Asset Manager</title>
                <meta name="description" content="A walk through on How Decimal Point Analytics with its Intelligent Document Processing (IDP) Solution helped leading US based firm in solving their problem. " />
                <meta name="keywords" content="AI in data analytics,
business intelligence analytics,
Corporate Data Providers,
Sector research provider
" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/case-studies/intelligent-document-processing-of-rent-rolls-for-an-asset-manager" />

                <meta name="viewport" content="width=device-width, initial-scale=1.0" />

            </Helmet>


            <Box id="case-studies" className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={3} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Box className='al_center'>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Success Stories
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center mb0'>
                                    Intelligent document processing of Rent Rolls for an Asset Manager
                                </Typography>
                                <Typography variant='h2' className='mainsmhead black'>
                                    Read about our Intelligent Document Processing (IDP) solution to create a proprietary database from Rent Roll Receipts for a US-based Asset Management Company
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>

                    <Writemessage3 pageVisited="Intelligent document processing of Rent Rolls for an Asset Manager" />





                    <Box className='whitebx'>
                        <Grid container spacing={3} alignItems="top">
                            <Grid item lg={4} md={4} sm={4} xs={12}>
                                <Box className='al_center'>
                                    <Box className='casestudy_bg' style={{ backgroundImage: 'url(' + Int_doc1 + ')', }}></Box>
                                </Box>
                            </Grid>

                            <Grid item lg={8} md={8} sm={8} xs={12}>
                                <Box>
                                    <Typography variant='h6' className='black jst'>
                                        On an unusually sunny afternoon in November 2018, Mr. Roy, the surveillance team manager of a US-based asset management company, called for an urgent meeting with his team in his office. Roy’s company deals in structured credit, asset-backed securities and loan investments across multiple sectors and his team was responsible for analyzing hundreds and thousands of rent roll receipts received on a periodic basis. Rent rolls are property rental receipts containing attributes such as building ID, tenant names, leased area, etc. This information helps Roy’s team to assess the risk profile of their investments and make informed decisions.
                                    </Typography>

                                </Box>
                            </Grid>
                        </Grid>
                    </Box>


                    <Box className='whitebx' mt={1}  >

                        <Grid container spacing={3} alignItems="top">

                            <Grid item lg={4} md={4} sm={4} xs={12}>
                                <Box className='al_center'>
                                    <Box className='casestudy_bg' style={{ backgroundImage: 'url(' + Int_doc2 + ')', }}></Box>
                                </Box>
                            </Grid>

                            <Grid item lg={8} md={8} sm={8} xs={12}>
                                <Box>

                                    <Box mb={2}>
                                        <Link to='/Businessintelligence' className='link'>
                                            <Typography variant='h4' className='blue' >Learn More About DPA’s Busines Intelligence Solution</Typography>
                                        </Link>
                                    </Box>

                                    <Typography variant='h6' className='black jst'>
                                        Roy had called for the meeting to discuss the process of gathering data from these rent rolls. One of the biggest challenges that the team was facing was that these rent rolls were in multiple formats such as text PDFs, scanned copies of handwritten documents, and encrypted documents which were difficult to copy. The lack of specific file formats and complex manual work made it impossible to gather all requisite data. Roy was stressed and desperately wanted to explore any alternative solution that would reduce the burden on his team and help them better analyse data. Roy approached DPA after he learnt about our data management solutions from one of his team members, who had previously worked with us.
                                    </Typography>


                                </Box>
                            </Grid>
                        </Grid>
                    </Box>


                    <Box className='whitebx' mt={1} >
                        <Typography variant='h6' className='black jst'> We provided a demo of our platform to ease some of Roy’s apprehensions and he was convinced that the solution would be a perfect fit for him and his team. He further suggested few specific requirements which the DPA team promised to incorporate in the solution. The project took-off and the data provided by us got uploaded on the analytics platform at Roy’s company which enabled detailed analysis by his team.  </Typography>

                        <Typography variant='h6' className='black jst'> Roy and his team were free from the cumbersome manual work of acquiring and processing data and were able to focus on strategic aspects such as tracking performance of the underlying assets to assess risk. The process speeded-up significantly and resulted in time and cost efficiencies for Roy and he was applauded by the senior management for this remarkable turnaround.  </Typography>
                    </Box>

                    <Box className='graybbx' mt={1} >
                        <Grid container spacing={1} alignItems="top" >
                            <Grid item lg={12} md={12} sm={12} xs={12} >
                                <Box className='al_left'>
                                    <Typography variant='h4' className='black'>
                                        Explore More:
                                    </Typography>

                                    <Link to='/case-studies/data-managment-for-efficient-crm-usage' className='link'>
                                        <Typography variant='h6' className='black jst' style={{ marginBottom: '0px' }}>
                                            <b>Next Case Study: </b>Data Management for Efficient CRM Usage.
                                        </Typography>
                                    </Link>

                                    <Link to='/articles/disrupting-investment-research-process' className='link'>
                                        <Typography variant='h6' className='black jst'>
                                            <b>Insights: </b>Disrupting Investment Research Process.
                                        </Typography>
                                    </Link>

                                </Box>
                            </Grid>
                        </Grid>

                        {/* <Box mt={5}>
                            <Subscribe />
                        </Box> */}


                    </Box>


                    <Casestudyback />



                </Container>
            </Box>


        </>
    );
}
