import { IconButton, Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Eventback from '../../../Component/Eventback'
import { Helmet } from "react-helmet";
import { withStyles } from '@material-ui/core/styles';

import Carousel from "react-elastic-carousel";

import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined';

import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';

import fiensteinevent1 from "../../../asset/images/media/event/fienstein_event/fiensteinevent1.webp"
import fiensteinevent2 from "../../../asset/images/media/event/fienstein_event/fiensteinevent2.webp"
import fiensteinevent3 from "../../../asset/images/media/event/fienstein_event/fiensteinevent3.webp"
import fiensteinevent4 from "../../../asset/images/media/event/fienstein_event/fiensteinevent4.webp"


const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);


export default function FiensteinEvent() {


    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };


    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>Decimal Point Analytics at the 19th Annual Feinstein Summer Concert</title>
                <meta name="description" content="Discover Decimal Point Analytics' support for the 19th Annual Feinstein Summer Concert featuring Gwen Stefani and our commitment to advancing medical research." />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/decimal-point-analytics-proudly-supported-the-19th-annual-feinstein-summer-concert-featuring-gwen-stefani" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>



            <Box id='event-details' className='section sec_bg' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Events
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center'>
                                    Decimal Point Analytics Proudly Supported the 19th Annual Feinstein Summer Concert Featuring Gwen Stefani
                                </Typography>

                            </Box>
                        </Grid>
                    </Grid>


                    <Box className='whitebx'>


                        <Typography variant='h6' className='black jst'>
                        <strong>Decimal Point Analytics</strong> proudly supported <strong>the 19th Annual Feinstein Summer Concert</strong>, which featured the extraordinary talent, <strong>Gwen Stefani</strong>. This remarkable event not only showcased exceptional musical performances but also advanced vital medical research initiatives.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            Our <strong>Managing Partner, Gaurav Gupta</strong>, and <strong>Executive Vice President, Nilanjan Das</strong>, represented <strong>Decimal Point Analytics</strong>, reinforcing our commitment to making a positive impact in the healthcare sector. Their presence at the concert highlighted our dedication to fostering collaboration and community engagement in support of innovative medical research.
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            Explore the highlights from this unforgettable event and learn how we contribute to advancing medical research through our initiatives!
                        </Typography>

                        <Box className='al_center'>
                            <IconButton className='blue' onClick={handleClickOpen}>
                                <CameraAltOutlinedIcon />
                            </IconButton>
                        </Box>

                        <Box className='al_center'>
                            <Typography className='blink_text'>Click Here...</Typography>
                        </Box>


                    </Box>
                    <Eventback />


                </Container>
            </Box>

            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} className="b_modal teammodal">
                <DialogTitle className="feedname" id="customized-dialog-title" onClose={handleClose}>
                </DialogTitle>
                <DialogContent>
                    <Box>
                        <Grid container spacing={2}>
                            <Carousel itemsToShow={1} animateOut="slideOutDown"
                                animateIn="flipInX">
                                <Box className="boxcontent" style={{ padding: '0px' }}>
                                    <Box className='eventbg' style={{ backgroundImage: 'url(' + fiensteinevent1 + ')', }}></Box>
                                </Box>

                                <Box className="boxcontent" style={{ padding: '0px' }}>
                                    <Box className='eventbg' style={{ backgroundImage: 'url(' + fiensteinevent2 + ')', }}></Box>
                                </Box>

                                <Box className="boxcontent" style={{ padding: '0px' }}>
                                    <Box className='eventbg' style={{ backgroundImage: 'url(' + fiensteinevent3 + ')', }}></Box>
                                </Box>

                                <Box className="boxcontent" style={{ padding: '0px' }}>
                                    <Box className='eventbg' style={{ backgroundImage: 'url(' + fiensteinevent4 + ')', }}></Box>
                                </Box>
                            </Carousel>
                        </Grid>
                    </Box>
                </DialogContent>
            </Dialog>


        </>
    );
}
