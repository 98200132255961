import { Button, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
 

export default function Infoback() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <> 
        
            <Box className='al_center' mt={3}>
                <Link to='/infographics'>
                    <Button variant="contained" className='dpabtn_gr' startIcon={<ArrowBackIcon />}>
                        Back to Infographic
                    </Button>
                </Link>
            </Box>


        </>
    );
}
