import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Helmet } from 'react-helmet';

import Casestudyback from '../../../../Component/Casestudyback'

import Banner from "../../../../asset/images/insight/case_studies/banner_enhancing_sba_loan.webp"
import Infographics from "../../../../asset/images/insight/case_studies/enhancing_sba_loan_approval.webp"
import { Link } from 'react-router-dom';
import GetInTouch from '../../../../Component/Get_in_touch';



export default function EnhancingSBALoan() {



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet><meta charset="UTF-8" />
                <meta charset="UTF-8" />
                <title>Optimize Your SBA Loan Process for Maximum Success </title>
                <meta name="description" content="Learn how to navigate the complex SBA loan process with confidence and achieve faster approvals. " />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/case-studies/enhancing-sba-loan-approval-rates-through-precise-documentation-and-ensuring-sba-compliance" />
            </Helmet>

            <Box id="case-studies" className='section sec_bg new_cs' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={3} alignItems="top" justifyContent='center'>
                        <Grid item lg={9} md={9} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Success Stories
                                </Typography>
                                <Typography className='mainhead blue al_center'>
                                    Enhancing SBA Loan Approval Rates Through Precise Documentation <span className='lower'>and</span> Ensuring SBA Compliance
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Box >
                        <img src={Banner} className='w100' alt='Banner' />
                    </Box>


                    <Box className='whitebx'>
                        <Box>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Client Profile
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                Our client is a multi-strategy real estate finance company that originates, acquires, finances, and services small to medium-sized balance SBA loans. They empower businesses nationwide with fast, flexible funding to fuel their growth.
                            </Typography>
                        </Box>


                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Challenges
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                The client faced several challenges that hindered their loan approval process:
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Non-compliance with SBA guidelines: </strong>  Failure to adhere to SBA regulations resulted in delays and rejections.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Frequent loan rejections due to minor documentation errors: </strong>   Minor mistakes in loan documentation led to unnecessary rejections and delays.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Inaccurate filings: </strong>  Errors in filings caused loan delays and rejections, impacting the client's efficiency and reputation.
                                        </Typography>
                                    </li>

                                </ul>
                            </Typography>
                        </Box>

                        <Box mt={3} className='mob_card'>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Solutions
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                To address these challenges and improve their SBA loan approval process, DPA implemented the following solutions:
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Detailed checklist for SBA compliance: </strong>  DPA created a comprehensive checklist to ensure adherence to SBA guidelines, reducing the risk of non-compliance and delays.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>  Proactive tracking of SBA policy changes: </strong> Subject Matter Experts closely monitored SBA policy changes to keep the client updated and ensure compliance with the latest regulations.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> High accuracy in loan packages with robust quality control: </strong>  DPA implemented a rigorous quality control process to identify and rectify any errors in loan packages, reducing the chances of rejections.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Streamlined filing process: </strong>  DPA streamlined the filing process, ensuring smoother and error-free loan submissions.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>  Comprehensive compliance procedures:  </strong> DPA established comprehensive compliance procedures to mitigate audit risks and maintain compliance with SBA regulations.
                                        </Typography>
                                    </li>

                                </ul>
                            </Typography>
                        </Box>

                        <Box mt={1}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Revamped Experience
                            </Typography>

                            <Typography variant='h6' className='black jst' gutterBottom>
                                As a result of these solutions, the client experienced a significant improvement in their SBA loan approval process:
                            </Typography>

                            <Grid container direction="row"
                                justifyContent="center"
                                alignItems="center">

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className="al_center" my={3}>
                                        <img src={Infographics} className="topbanner" alt="Solution: Streamlined Public Float Calculation" />
                                    </Box>
                                </Grid>
                            </Grid>

                        </Box>

                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Success Metrics
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>99% Accuracy Rate: </strong>  The implementation of robust quality control measures resulted in a 99% accuracy rate in loan submissions.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Reduced Loan Rejections by 30%: </strong>  The detailed checklist and proactive tracking of SBA policy changes led to a 30% reduction in loan rejections.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>  Increase in loan approval rate: </strong>  The overall improvements in the loan approval process resulted in an increase in the overall loan approval rate.
                                        </Typography>
                                    </li>
                                </ul>

                            </Typography>
                        </Box>

                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Conclusion
                            </Typography>

                            <Typography variant='h6' className='black jst' gutterBottom>
                                By implementing a detailed checklist, proactive tracking of SBA policy changes, robust quality control measures, a streamlined filing process, and comprehensive compliance procedures, DPA successfully addressed the client's challenges and significantly improved their SBA loan approval process. This resulted in reduced rejections, faster loan processing, increased accuracy, and ultimately, an increase in the overall loan approval rate.
                            </Typography>

                            <Typography variant='h6' className='black jst' gutterBottom>
                                <strong> Simplify your SBA loan process with expert guidance.</strong> Ensure compliance, reduce rejections, and accelerate your funding.     <Link to="/contact-us#contact_form" className="link">Contact us</Link>  <strong>today to get started.</strong>
                            </Typography>

                        </Box>

                    </Box>

                    <Box id="sba_success_stories">
                        <GetInTouch />
                    </Box>


                    <Casestudyback />

                </Container>
            </Box>


        </>
    );
}