import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Helmet } from 'react-helmet';

import Casestudyback from '../../../../Component/Casestudyback'

import Banner from "../../../../asset/images/insight/case_studies/banner_product_portfolio.webp"
import Infographics from "../../../../asset/images/insight/case_studies/driving_monthly_profit.webp"
import { Link } from 'react-router-dom';



export default function ProductPortfolio() {



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet><meta charset="UTF-8" />
                <meta charset="UTF-8" />
                <title>MENA FMCG Profit Surge with Portfolio Automation</title>
                <meta name="description" content="Discover how a leading MENA FMCG increased profitability by $1.5M monthly through automated reporting and product optimization." />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/case-studies/driving-1.5m-monthly-profit-increase-with-product-portfolio-optimization-for-a-mena-fmcg" />
            </Helmet>

            <Box id="case-studies" className='section sec_bg new_cs' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={3} alignItems="top" justifyContent='center'>
                        <Grid item lg={9} md={9} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Success Stories
                                </Typography>
                                <Typography className='mainhead blue al_center'>
                                    Driving $1.5M Monthly Profit Increase <span className='lower'> with</span>  Product Portfolio Optimization <span className='lower'>for a</span> MENA FMCG
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Box >
                        <img src={Banner} className='w100' alt='Banner' />
                    </Box>


                    <Box className='whitebx'>
                        <Box>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Executive Summary
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                A major FMCG company in the Middle East and North Africa (MENA) region, managing over 1,200 outlets, sought to optimize their product portfolio and streamline their reporting process. Manual calculations and data handling were time-consuming, impacting their ability to make timely, informed decisions. By implementing an automated framework, the company improved its profitability by $1.5 million per month and reduced processing time by 75%.
                            </Typography>
                        </Box>


                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                The Challenge
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                The FMCG company faced several operational challenges:
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>  Manual Processes:</strong>  KPI calculations and reporting were done manually, leading to inefficiencies and errors.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Complex Product Portfolio: </strong> Managing multiple levels of product data made it difficult to gain clear insights into performance.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Delayed Decision-Making:   </strong> Time-consuming processes delayed access to important insights for decision-making.
                                        </Typography>
                                    </li>
                                </ul>
                            </Typography>
                        </Box>

                        <Box mt={3} className='mob_card'>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Solution: Advanced Catastrophe Modelling <span className='lower'> and </span> Risk Assessment
                            </Typography>

                            <Grid container direction="row"
                                justifyContent="center"
                                alignItems="center">

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className="al_center" my={3}>
                                        <img src={Infographics} className="w100" alt="Solution: Advanced Catastrophe Modelling and Risk Assessment " />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box mt={1}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Results
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                The implementation of an automated framework delivered significant improvements:
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>  $1.5M Monthly Profit Increase:  </strong> Optimized the product portfolio, resulting in increased EBITDA by $1.5 million per month.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> 75% Reduction in Processing Time:  </strong> Automated reporting and KPI tracking reduced processing time from 2 days to under half a day.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Improved Decision-Making:  </strong>  Faster access to accurate performance data allowed for more strategic and timely decisions.
                                        </Typography>
                                    </li>
                                </ul>
                            </Typography>
                        </Box>

                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Key Takeaways
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Automated Efficiency: </strong>  The automated framework eliminated manual errors and drastically reduced reporting time.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Profit Optimization:  </strong> Data-driven insights from the product portfolio optimization directly improved profitability.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Granular Analysis: </strong> Drill-down capabilities allowed the company to make better-informed decisions across product lines and geographies. By automating their reporting and optimizing their product portfolio, the FMCG company unlocked new levels of profitability and operational efficiency.
                                        </Typography>
                                    </li>
                                </ul>

                            </Typography>

                            <Typography variant='h6' className='black jst' gutterBottom>
                                <Link to="/contact-us#contact_form" className="link">Reach out</Link> today to discover how our automation solutions can optimize your product portfolio and boost your profitability!
                            </Typography>

                        </Box>

                    </Box>


                    <Casestudyback />

                </Container>
            </Box>


        </>
    );
}