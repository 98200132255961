import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Writemessage3 from '../../Component/Writemessage3'

import solution1 from "../../asset/images/services/research_solutions/solution1.png";
import solution2 from "../../asset/images/services/research_solutions/solution2.png";
import solution3 from "../../asset/images/services/research_solutions/solution3.png";
import solution4 from "../../asset/images/services/research_solutions/solution4.png";
import solution5 from "../../asset/images/services/research_solutions/solution5.png";
import solution6 from "../../asset/images/services/research_solutions/solution6.png";

import whyCP from "../../asset/images/product/creditpulse/why_credit_pulse.webp"

import { Helmet } from 'react-helmet';

import ResearchSolution from "../../asset/images/new_banner/research_solution_banner.webp"
import { Link } from 'react-router-dom/cjs/react-router-dom';
import GetInTouch from '../../Component/Get_in_touch';

export default function Researchsolution() {



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);





    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>Research Services | Equity, Credit, Industry, and Macroeconomic Analysis | Decimal Point Analytics</title>
                <meta name="description" content="Explore comprehensive research services at Decimal Point Analytics. We offer expert insights in equity & credit research, industry analysis, thematic reports, macroeconomic forecasting, M&A advisory, and financial modeling to support informed decision-making and business growth." />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/leading-research-solution-company" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            <Box className="banner_bx" mb={"2px"}>
                <Box style={{ backgroundImage: "url(" + ResearchSolution + ")" }} className="banner_img">
                    <Box className="img_over bl_overlay">
                        <Grid container spacing={0} alignItems="center" justifyContent='center'>
                            <Grid item lg={3} md={4} sm={6} xs={10}>
                                <Box className="al_center">
                                    <Typography className="white" variant="h1">Research Solution</Typography>
                                    <Box mt={'10px'}>
                                        <Typography className="white" variant="h4">Augment Your Resources, Bespoke Research For All Your Needs</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>

            <Writemessage3 pageVisited="Research Solution" />

            <Box className='section2 sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} direction="row" justifyContent="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='al_center'>
                                <Typography variant='h2' className='black'>
                                    Research Services
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box mt={1} className='synopsic_bx'>
                                <Typography variant='h6' className='black jst' gutterBottom>
                                    At Decimal Point Analytics, we provide comprehensive research services to support informed decision-making and drive business success. Our deep expertise spans across equity, credit, industry, and macroeconomic research, delivering insightful and actionable reports that help organizations stay ahead in today's dynamic market landscape.
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>


            <Box className='section mob_card' component='section'>
                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} direction="row" justifyContent="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='al_center'>
                                <Typography variant='h2' className='black'>
                                    Our Research Offerings
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} alignItems="center">
                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Box className='boxbg mnhg5'>
                                <Box className='al_center'>
                                    <img src={solution1} className='allic' alt='Equity & Credit Research' />
                                </Box>
                                <Box>
                                    <Grid container spacing={3} alignItems="center" justifyContent='center'>
                                        <Grid item lg={6} md={7} sm={6} xs={12}>
                                            <Box>
                                                <Typography variant='h4' className='blue al_center'>
                                                    Equity & Credit Research
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Typography variant='h6' className='black jst'>
                                        We offer in-depth equity and credit research, empowering investors and financial institutions with valuable insights into market trends, risk assessments, and opportunities. Our analyses provide clarity on potential investment decisions, backed by robust data and market intelligence.
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Box className='boxbg mnhg5'>
                                <Box className='al_center'>
                                    <img src={solution2} className='allic' alt='Industry / Sector / Company Research' />
                                </Box>
                                <Box>
                                    <Grid container spacing={3} alignItems="center" justifyContent='center'>
                                        <Grid item lg={7} md={9} sm={9} xs={12}>
                                            <Box>
                                                <Typography variant='h4' className='blue al_center'>
                                                    Industry / Sector / Company Research
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Typography variant='h6' className='black jst'>
                                        Our industry, sector, and company research is tailored to help businesses understand market dynamics, competitive landscapes, and growth drivers. With detailed reports and actionable insights, we assist clients in making strategic decisions for sustained growth and operational success.
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Box className='boxbg mnhg5'>
                                <Box className='al_center'>
                                    <img src={solution3} className='allic' alt='Thematic / Event-Based Research Reports' />
                                </Box>
                                <Box>
                                    <Grid container spacing={3} alignItems="center" justifyContent='center'>
                                        <Grid item lg={8} md={11} sm={10} xs={12}>
                                            <Box>
                                                <Typography variant='h4' className='blue al_center'>
                                                    Thematic / Event-Based Research Reports
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Typography variant='h6' className='black jst'>
                                        We provide thematic and event-based research reports, analyzing emerging trends, key events, and their potential impact on industries, markets, and economies. Our reports offer forward-looking perspectives, enabling clients to capitalize on market opportunities and mitigate risks.
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Box className='boxbg mnhg5'>
                                <Box className='al_center'>
                                    <img src={solution4} className='allic' alt='Macroeconomic Analysis Reports' />
                                </Box>
                                <Box>
                                    <Grid container spacing={3} alignItems="center" justifyContent='center'>
                                        <Grid item lg={8} md={10} sm={9} xs={12}>
                                            <Box>
                                                <Typography variant='h4' className='blue al_center'>
                                                    Macroeconomic Analysis Reports
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Typography variant='h6' className='black jst'>
                                        Our macroeconomic analysis reports provide a clear understanding of global and regional economic conditions, including GDP growth, inflation, monetary policy, and geopolitical factors. This research helps businesses anticipate market shifts and make strategic decisions based on reliable economic forecasts.
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Box className='boxbg mnhg5'>
                                <Box className='al_center'>
                                    <img src={solution5} className='allic' alt='M&A Advisory Services' />
                                </Box>
                                <Box>
                                    <Grid container spacing={3} alignItems="center" justifyContent='center'>
                                        <Grid item lg={6} md={7} sm={6} xs={12}>
                                            <Box>
                                                <Typography variant='h4' className='blue al_center'>
                                                    M&A Advisory Services
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Typography variant='h6' className='black jst'>
                                        Our M&A advisory services deliver comprehensive insights and analysis for mergers and acquisitions, helping clients navigate complex transactions. From target identification and due diligence to post-merger integration, we provide expert guidance at every stage of the M&A process.
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Box className='boxbg mnhg5'>
                                <Box className='al_center'>
                                    <img src={solution6} className='allic' alt='Financial Modelling and Valuation' />
                                </Box>
                                <Box>
                                    <Grid container spacing={3} alignItems="center" justifyContent='center'>
                                        <Grid item lg={7} md={9} sm={8} xs={12}>
                                            <Box>
                                                <Typography variant='h4' className='blue al_center'>
                                                    Financial Modelling and Valuation
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Typography variant='h6' className='black jst'>
                                        Our financial modelling and valuation services offer businesses a precise and data-driven approach to assess investment opportunities, business performance, and potential transactions. We create customized financial models that support strategic planning, valuations, and decision-making processes.
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>

                    <Box mt={5}>
                        <Typography variant='h4' className='black al_center'>
                            Unlock <span className='lower'>the true value of your investments</span>. <Link className='link' to='/contact-us#contact_form'>Get Started Today</Link>
                        </Typography>
                    </Box>

                </Container>
            </Box>

            <Box className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr' style={{ position: 'relative', }}>

                    <Box className='mob_card' mt={3}>
                        <Grid container spacing={1} alignItems="top">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className="al_center">
                                    <Typography className="black" variant="h2">
                                        Why Choose Decimal Point Analytics?
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item lg={5} md={5} sm={6} xs={12}>
                                <Box className='al_center'>
                                    <img src={whyCP} className='topbanner5' alt='Why Outsource CAT Modeling Services for Insurance? ' />
                                </Box>
                            </Grid>

                            <Grid item lg={7} md={7} sm={6} xs={12}>
                                <Box>
                                    <Typography className="blue" variant="h6">
                                        <ul>
                                            <li>
                                                <Typography variant="h6" className="black jst" gutterbottom>
                                                    <strong>Expertise:</strong> Our team comprises seasoned professionals with deep industry knowledge and analytical skills.
                                                </Typography>
                                            </li>

                                            <li>
                                                <Typography variant="h6" className="black jst" gutterbottom>
                                                    <strong> Data-Driven Insights:</strong> We leverage advanced data analytics and AI to provide actionable, evidence-based recommendations.
                                                </Typography>
                                            </li>

                                            <li>
                                                <Typography variant="h6" className="black jst" gutterbottom>
                                                    <strong>Customized Solutions:</strong> We tailor our research to meet the unique needs of each client, delivering personalized insights that drive growth.
                                                </Typography>
                                            </li>

                                            <li>
                                                <Typography variant="h6" className="black jst" gutterbottom>
                                                    <strong> Global Reach:</strong> Our research covers global markets, ensuring our clients stay informed about worldwide trends and opportunities.
                                                </Typography>
                                            </li>
                                        </ul>
                                    </Typography>
                                </Box>
                            </Grid>

                            
                        </Grid>
                    </Box>

                </Container>
            </Box>

            <Box className='section mob_card' component='section'>
                <Container maxWidth='lg' className='bdr'>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box className="synopsic_bx al_center">
                            <Typography className="black" variant="h4">
                                Partner with Decimal Point Analytics to unlock the power of data-driven insights and make informed, strategic decisions.
                            </Typography>

                            <Typography className="black" variant="h6">
                                <Link className='link' to='/contact-us#contact_form'>Contact Us Today</Link> to learn more about our research offerings and how we can support your business goals.
                            </Typography>
                        </Box>
                    </Grid>
                </Container>
            </Box>

            <Box className='sec_bg'>
                <GetInTouch />
            </Box>


        </>
    );
}
