import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Helmet } from 'react-helmet';

import Casestudyback from '../../../../Component/Casestudyback'

import Banner from "../../../../asset/images/insight/case_studies/banner_cost_effective_excellence.webp"
import Infographics from "../../../../asset/images/insight/case_studies/cost_effective_excellence_saving.webp"
import { Link } from 'react-router-dom';
import GetInTouch from '../../../../Component/Get_in_touch';



export default function CostEffectiveExcellence() {



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet><meta charset="UTF-8" />
                <meta charset="UTF-8" />
                <title>How Automation and Outsourcing Saved 60% in Overheads for SBA Loan Processing</title>
                <meta name="description" content="Discover how a real estate finance firm used automation and outsourcing to reduce operational costs by 60%, cut error rates by 50%, and improve SBA loan processing speed." />
                <meta name="keywords" content="SBA loan automation, loan processing cost reduction, real estate finance solutions, outsourcing loan processing, improving SBA loan quality, financial process automation, efficiency in loan approvals, error reduction in finance, Decimal Point Analytics." />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/case-studies/cost-effective-excellence–saving-lenders-60-in-overheads-while-enhancing-sba-loan-quality" />
            </Helmet>

            <Box id="case-studies" className='section sec_bg new_cs' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={3} alignItems="top" justifyContent='center'>
                        <Grid item lg={9} md={9} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Success Stories
                                </Typography>
                                <Typography className='mainhead blue al_center'>
                                    Cost-Effective Excellence – Saving Lenders 60%  <span className='lower'>in</span> Overheads While Enhancing SBA Loan Quality
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Box >
                        <img src={Banner} className='w100' alt='Banner' />
                    </Box>


                    <Box className='whitebx'>
                        <Box>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Client Overview
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                Our client is a multi-strategy real estate finance company that originates, acquires, finances, and services SBA loans for small to medium-sized businesses. Their goal is to empower businesses nationwide with fast, flexible funding solutions to support growth.
                            </Typography>
                        </Box>


                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                The Challenge
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                The client was facing several critical challenges impacting their operational efficiency and cost structure, including:
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>High Operational Costs: </strong>   Maintaining an onshore underwriting team was driving up operational expenses.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Cost-Quality Balance: </strong> Ensuring compliance and processing accuracy while controlling costs was becoming increasingly difficult.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Resource Strain and Growth Constraints: </strong>  Rising overheads were limiting the client’s ability to meet expansion goals, leading to resource constraints.
                                        </Typography>
                                    </li>

                                </ul>
                            </Typography>
                        </Box>

                        <Box mt={3} className='mob_card'>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Solutions
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                Decimal Point Analytics implemented a series of strategic solutions designed to address these pain points effectively:
                                <ol>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> End-to-End Loan Processing: </strong>  Reduced dependence on the onshore team by streamlining the loan processing workflow, ensuring faster and more efficient operations.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Cost-Effective Outsourcing Model: </strong> Enabled scalable operations by outsourcing certain functions, allowing the client to adjust capacity in response to demand fluctuations.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Quality Control and Consistency: </strong> Established stringent quality checks at each loan processing stage to maintain compliance and minimize errors.
                                        </Typography>
                                    </li>
                                </ol>
                            </Typography>

                        </Box>

                        <Box mt={1}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Results and Key Metrics
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <strong>Revamped Experience and Tangible Benefits:</strong>
                            </Typography>

                            <Grid container direction="row"
                                justifyContent="center"
                                alignItems="center">

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className="al_center" my={3}>
                                        <img src={Infographics} className="topbanner" alt="Solution: Streamlined Public Float Calculation" />
                                    </Box>
                                </Grid>
                            </Grid>

                        </Box>

                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Success Metrics
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Cost Reduction: </strong> Approximately 60%
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Error Rate Reduction: </strong> Approximately 50%, leading to minimized rework
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Efficiency Improvement: </strong>  Approximately 40% faster processing times
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong> Approval Throughput: </strong> Doubled capacity in handling loan applications
                                        </Typography>
                                    </li>
                                </ul>

                            </Typography>
                        </Box>

                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Conclusion
                            </Typography>

                            <Typography variant='h6' className='black jst' gutterBottom>
                                By implementing a comprehensive automation and outsourcing strategy, our client was able to cut costs by 60%, improve efficiency by 40%, and halve error rates, setting a new standard in SBA loan processing. These improvements have not only enhanced operational efficiency but have also bolstered client satisfaction and market competitiveness.
                            </Typography>

                            <Typography variant='h6' className='black jst' gutterBottom>
                                <strong>Ready to Transform Your SBA Loan Processing? </strong>
                            </Typography>

                            <Typography variant='h6' className='black jst' gutterBottom>
                                Discover how <Link to="/" className='link'>Decimal Point Analytics</Link> can help optimize your loan processing with cost-effective, high-quality solutions tailored to the unique demands of SBA loans.  <Link to="/contact-us#contact_form" className="link">Connect with us</Link> today to streamline your operations and improve client satisfaction.
                            </Typography>

                        </Box>

                    </Box>

                    <Box id="sba_success_stories">
                        <GetInTouch />
                    </Box>

                    <Casestudyback />

                </Container>
            </Box>


        </>
    );
}