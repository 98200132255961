import { Box, Container, Grid, IconButton, Typography } from '@material-ui/core'
import React from 'react';
import icon1 from '../../asset/images/crm/crm_solutions/implementation.svg';
import icon2 from '../../asset/images/crm/crm_solutions/data_management.svg';
import icon3 from '../../asset/images/crm/crm_solutions/automation.svg';
import icon4 from '../../asset/images/crm/crm_solutions/support.png';
import icon5 from '../../asset/images/crm/crm_solutions/service.png';

import CheckIcon from '@mui/icons-material/Check';


export const CRMComprehensive = () => {
    return (
        <>
            <Box id="comprehensive" className='section' >
                <Container>
                    <Grid container spacing={4} alignItems="top" justifyContent='center'>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box>
                                <Grid container direction="row"
                                    justifyContent="center"
                                    alignItems="center" spacing="0">
                                    <Grid item lg={6} md={8} sm={10} xs={12} >
                                        <Typography variant="h2" className="black al_center fw5">
                                            <span className='blue'> Comprehensive CRM Solutions</span>  for Financial Services
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>

                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Box className="sbalenders_benefitbx">
                                <Box className='mob_flex'>
                                    <Box>
                                        <img src={icon1} alt='Immediate Team Setup' className='sba_icon' />
                                    </Box>
                                    <Box my={2}>
                                        <Typography variant="h4" className="blue al_left two " gutterBottom>CRM Implementation & Integration</Typography>
                                    </Box>
                                </Box>
                                <Box className='flx_fs' pt={1}>
                                    <IconButton disableRipple><CheckIcon fontSize='small' className='black  ' /></IconButton>
                                    <Box>
                                        <Typography variant="h6" className="black jst mb0 subpoints_sba" gutterbottom> Seamlessly integrate existing data with Salesforce and external databases, enhancing CRM Platform Integration.</Typography>
                                    </Box>
                                </Box>

                                <Box className='flx_fs' pt={1}>
                                    <IconButton><CheckIcon fontSize='small' className='black  ' /></IconButton>
                                    <Box>
                                        <Typography variant="h6" className="black jst mb0 subpoints_sba" gutterbottom> Enable smooth email and external API integrations, critical for CRM Reporting and Analytics.</Typography>
                                    </Box>
                                </Box>

                                <Box className='flx_fs' pt={1}>
                                    <IconButton disableRipple><CheckIcon fontSize='small' className='black  ' /></IconButton>
                                    <Box>
                                        <Typography variant="h6" className="black jst mb0 subpoints_sba" gutterbottom> Customize and integrate PowerBI & Tableau dashboards for enhanced data visualization, crucial for CRM Data Analytics.</Typography>
                                    </Box>
                                </Box>

                            </Box>
                        </Grid>

                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Box className="sbalenders_benefitbx">
                                <Box className='mob_flex'>
                                    <Box>
                                        <img src={icon2} alt='Immediate Team Setup' className='sba_icon' />
                                    </Box>
                                    <Box my={2}>
                                        <Typography variant="h4" className="blue al_left two" gutterBottom>CRM Data Management</Typography>
                                    </Box>
                                </Box>
                                <Box className='flx_fs' pt={1}>
                                    <IconButton disableRipple><CheckIcon fontSize='small' className='black  ' /></IconButton>
                                    <Box>
                                        <Typography variant="h6" className="black jst mb0 subpoints_sba" gutterbottom>  Standardize and eliminate duplicate data for consistency, focusing on CRM Data Cleansing and Data Cleansing for CRM.</Typography>
                                    </Box>
                                </Box>

                                <Box className='flx_fs' pt={1}>
                                    <IconButton disableRipple><CheckIcon fontSize='small' className='black  ' /></IconButton>
                                    <Box>
                                        <Typography variant="h6" className="black jst mb0 subpoints_sba" gutterbottom> Perform bulk data uploads, updates, and maintain overall data quality, pivotal for CRM Data Management.</Typography>
                                    </Box>
                                </Box>

                                <Box className='flx_fs' pt={1}>
                                    <IconButton ><CheckIcon fontSize='small' className='black  ' /></IconButton>
                                    <Box>
                                        <Typography variant="h6" className="black jst mb0 subpoints_sba" gutterbottom> Set up pipelines and KPI reports to provide actionable insights, enhancing CRM Reporting Technology.</Typography>
                                    </Box>
                                </Box>

                            </Box>
                        </Grid>

                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Box className="sbalenders_benefitbx">
                                <Box className='mob_flex'>
                                    <Box>
                                        <img src={icon3} alt='Immediate Team Setup' className='sba_icon' />
                                    </Box>
                                    <Box my={2}>
                                        <Typography variant="h4" className="blue al_left two" gutterBottom>CRM Process Automation</Typography>
                                    </Box>
                                </Box>
                                <Box className='flx_fs' pt={1}>
                                    <IconButton disableRipple><CheckIcon fontSize='small' className='black  ' /></IconButton>
                                    <Box>
                                        <Typography variant="h6" className="black jst mb0 subpoints_sba" gutterbottom> Design and deploy custom Salesforce applications tailored to your financial services needs.</Typography>
                                    </Box>
                                </Box>

                                <Box className='flx_fs' pt={1}>
                                    <IconButton disableRipple><CheckIcon fontSize='small' className='black  ' /></IconButton>
                                    <Box>
                                        <Typography variant="h6" className="black jst mb0 subpoints_sba" gutterbottom> Automate tasks using SOQL, APIs, and Flow Builder for improved efficiency, emphasizing CRM Automation for Asset Managers and Private Equity Firms.</Typography>
                                    </Box>
                                </Box>

                                <Box className='flx_fs' pt={1}>
                                    <IconButton disableRipple><CheckIcon fontSize='small' className='black  ' /></IconButton>
                                    <Box>
                                        <Typography variant="h6" className="black jst mb0 subpoints_sba" gutterbottom> Create custom objects, formula fields, and macros to streamline processes, important for CRM Data Reporting.</Typography>
                                    </Box>
                                </Box>

                            </Box>
                        </Grid>

                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Box className="sbalenders_benefitbx">
                                <Box className='mob_flex'>
                                    <Box>
                                        <img src={icon4} alt='Immediate Team Setup' className='sba_icon' />
                                    </Box>
                                    <Box my={2}>
                                        <Grid container spacing={2} alignItems="top" justifyContent='left'>
                                            <Grid item lg={9} md={10} sm={12} xs={12}>
                                                <Typography variant="h4" className="blue al_left two" gutterBottom>Ongoing CRM Maintenance Support</Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                                <Box className='flx_fs' pt={1}>
                                    <IconButton disableRipple><CheckIcon fontSize='small' className='black  ' /></IconButton>
                                    <Box>
                                        <Typography variant="h6" className="black jst mb0 subpoints_sba" gutterbottom> Manage change requests, custom objects, and triggers effectively.</Typography>
                                    </Box>
                                </Box>

                                <Box className='flx_fs' pt={1}>
                                    <IconButton disableRipple><CheckIcon fontSize='small' className='black  ' /></IconButton>
                                    <Box>
                                        <Typography variant="h6" className="black jst mb0 subpoints_sba" gutterbottom> Offer continuous support for Salesforce updates and newly released features.</Typography>
                                    </Box>
                                </Box>

                                <Box className='flx_fs' pt={1}>
                                    <IconButton disableRipple><CheckIcon fontSize='small' className='black  ' /></IconButton>
                                    <Box>
                                        <Typography variant="h6" className="black jst mb0 subpoints_sba" gutterbottom> Implement automated alerts, updates, and synchronization with tools like Outlook, crucial for CRM Services for Investment Banking Companies.</Typography>
                                    </Box>
                                </Box>

                            </Box>
                        </Grid>

                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Box className="sbalenders_benefitbx">
                                <Box className='mob_flex'>
                                    <Box>
                                        <img src={icon5} alt='Immediate Team Setup' className='sba_icon' />
                                    </Box>
                                    <Box my={2}>
                                        <Typography variant="h4" className="blue al_left two" gutterBottom>Customization & Value-added Services</Typography>
                                    </Box>
                                </Box>
                                <Box className='flx_fs' pt={1}>
                                    <IconButton disableRipple><CheckIcon fontSize='small' className='black  ' /></IconButton>
                                    <Box>
                                        <Typography variant="h6" className="black jst mb0 subpoints_sba" gutterbottom> Customize user interfaces using Visualforce pages and Lightning Web Components tailored for financial services.</Typography>
                                    </Box>
                                </Box>

                                <Box className='flx_fs' pt={1}>
                                    <IconButton disableRipple><CheckIcon fontSize='small' className='black  ' /></IconButton>
                                    <Box>
                                        <Typography variant="h6" className="black jst mb0 subpoints_sba" gutterbottom> Develop custom forms and input fields to manage client interactions seamlessly.</Typography>
                                    </Box>
                                </Box>

                                <Box className='flx_fs' pt={1}>
                                    <IconButton disableRipple><CheckIcon fontSize='small' className='black  ' /></IconButton>
                                    <Box>
                                        <Typography variant="h6" className="black jst mb0 subpoints_sba" gutterbottom> Build interactive dashboards and set up automated meeting alerts for improved productivity, vital for CRM Interactive Dashboard and CRM Services for Venture Capital Firms.</Typography>
                                    </Box>
                                </Box>

                            </Box>
                        </Grid>

                    </Grid>
                </Container>
            </Box>

        </>
    )
}

