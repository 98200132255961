import React, { Component } from "react";
import { Button, Container, Grid, Typography, Box, } from '@material-ui/core';
import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import web2 from "../../../asset/images/media/event/webinar2.webp";

export class VideoModal extends Component {

  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }


  render() {
    return (
      <>

        <Box className='mainsection' component='section' id='top'>
          <Container maxWidth='lg'>
            <Grid container spacing={1} alignItems="center">
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box>
                  <Typography variant='h3' className='skblue al_center'>
                    Webinar : Vol 2
                  </Typography>
                  <Typography variant='h1' className='blue al_center'>
                    Emerging Trends in Data Analytics and Visual Dashboards
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>



        <Box className='section sec_bg' component='section'>
          <Container maxWidth='lg' className='bdr'>
            <Grid container spacing={3} alignItems="center">
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Box>
                  <Typography variant='h6' className='black jst'>
                    In this webinar, we have covered how General Partners / Private Equity's and Family Offices can use Data Analytics and Visualisation to
                  </Typography>

                  <Typography variant='h6' className='black jst'>
                    <ul>
                      <li>Streamline their existing databases</li>
                      <li>Monitor their existing portfolio performance</li>
                      <li>Improve decision timeline on go-no-go decisions about new opportunities</li>
                      <li>It also includes examples of how these technologies have been successfully used in the Industry. </li>
                    </ul>
                  </Typography>
                </Box>
                <Box className='al_left' mt={3}>
                  <Link to='/webinar'>
                    <Button variant="contained" className='dpabtn_gr' startIcon={<ArrowBackIcon />}>
                      Back to Webinar
                    </Button>
                  </Link>
                </Box>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Box className='al_center' style={{ position: 'relative', }}>
                  <Box className='videobg_gr'></Box>
                  <Box className='videobga' style={{ backgroundImage: 'url(' + web2 + ')', }}>
                    <button onClick={this.openModal} class="pulse-button"></button>
                  </Box>
                </Box>

                <ModalVideo
                  channel="youtube"
                  isOpen={this.state.isOpen}
                  videoId="tH3-SIUo0Vs"
                  onClose={() => this.setState({ isOpen: false })}
                />


              </Grid>
            </Grid>
          </Container>
        </Box>

      </>
    );
  }
}

export default VideoModal;
