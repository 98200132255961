import { Container, Typography, Box, Grid, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Helmet } from 'react-helmet';
import Writemessage3 from '../../../Component/Writemessage3';
import Blogback from '../../../Component/Blogback'
import Banner from '../../../asset/images/insight/blog_pages/banner_navigate_the_future.webp'

import { Link } from 'react-router-dom';
import EmployeeProfile from '../../../Component/EmployeeProfile';

import ND from '../../../asset/images/team/nilanjan_das.webp'


export default function NavigatethefutureofSBA() {


    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>Navigate the future of SBA lending with a data led strategy</title>
                <meta name="description" content=" Explore the impact of SBA reforms, data analytics, and their benefits for small businesses and lenders in the evolving SBA lending landscape." />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/blog/navigate-the-future-of-sba-lending-with-a-data-led-strategy" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            <Box id="blog-page" className='section sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Typography variant='h3' className='subhead skblue al_center'>
                                Blog
                            </Typography>
                            <Typography variant='h1' className='blue mainhead al_center'>
                                Navigate the future of SBA lending with a data led strategy
                            </Typography>
                            <Typography variant='h4' className='black mainsmhead al_center'>
                                Why a data-led lending strategy makes sense
                            </Typography>
                        </Grid>
                    </Grid>

                    <Box >
                        <img src={Banner} className='w100' alt='Banner' />
                    </Box>

                    <Box className='whitebx' mt={1}>

                        <Typography variant='h6' className='black jst' gutterBottom>
                            Small businesses are the mainstay of the economy. The United States is home to more than <a href="https://advocacy.sba.gov/wp-content/uploads/2023/11/2023-Small-Business-Economic-Profile-US.pdf" target="_blank" rel="noopener noreferrer"> 33 million small businesses</a> accounting for 99.9% of all businesses, more than 40% of the economic output, and about two-thirds of overall job creation in the country.
                        </Typography>

                        <Typography variant='h6' className='black jst' gutterBottom>
                            The U.S. Small Business Administration (SBA) qualifies a small business as “a firm with revenue ranging from $1 million to over $40 million and an employee workforce of under 500.” In 2024 alone, over <a href="https://www.whitehouse.gov/briefing-room/statements-releases/2024/10/24/fact-sheet-vice-president-harris-announces-record-lending-to-small-businesses-in-2024-and-new-actions-to-cut-red-tape-and-expand-contracting-opportunities/" target="_blank" rel="noopener noreferrer">17,000 small businesses</a> made it to SBA’s certified list, recording nearly a 40% jump from the previous year.
                        </Typography>

                        <Typography variant='h6' className='black jst' gutterBottom>
                            In addition to a distinctive business vision activated by a proper roadmap, these businesses need ready and affordable capital inflows to survive and thrive.
                        </Typography>

                        <Typography variant='h6' className='black jst' gutterBottom>
                            The SBA financially assists small businesses through partially guaranteed loans and grants. Small business loans – 7(a) or 504 or microloans – are popular among small businesses seeking funds for their low interest rates and long repayment periods. Typically, these loans are meant to cover their working capital, long-term fixed asset purchases, hiring, business expansion and innovation costs etc.
                        </Typography>

                        <Typography variant='h6' className='black jst' gutterBottom>
                            It’s evident that encouraging small businesses and enabling financial support to entrepreneurship will directly bolster the US economy. Banks and non-banking entities have an important responsibility in SBA lending to extend their support to the small business community, which is integral to economic growth.
                        </Typography>

                        <Typography variant='h6' className='black jst' gutterBottom>
                            Using the SBA’s areas of focus and guidelines as the compass, SBA lenders must strengthen their lending strategy and benefit the right small businesses. In doing so, they can discover more avenues leading to growth within their small business portfolio.
                        </Typography>

                        <Box pt={1}>
                            <Typography variant='h4' className='blue jst'>The  <span className='lower'>health of the small business landscape </span> </Typography>
                        </Box>

                        <Typography variant='h6' className='black jst' gutterBottom>
                            More than 65% of small businesses in the country are said to be generating money, as reported by the U.S. Chamber of Commerce. A significant proportion <a href="https://www.uschamber.com/sbindex/summary" target="_blank" rel="noopener noreferrer">(73%) of small businesses</a> predict revenue growth while 44% of them anticipate expanding their workforce in 2025.
                        </Typography>

                        <Typography variant='h6' className='black jst' gutterBottom>
                            On the flipside, the U.S. Bureau of Labor Statistics says that 18% of start-ups are unlikely to make it beyond 12 months of their existence. Studies point out the shortfall of funds or inability to access capital as the main culprit behind businesses shutting shop. Volatile market and inflationary conditions and persistent supply chain challenges have also made it tougher to sustain themselves.
                        </Typography>

                        <Typography variant='h6' className='black jst' gutterBottom>
                            While start-ups enjoy a conducive funding environment in the Sunbelt, Mountain, and Great Lakes states, they have found a comparatively narrow band of survival and growth opportunities in states like New Hampshire and New Mexico.
                        </Typography>

                        <Box pt={1}>
                            <Typography variant='h4' className='blue jst'>Steering <span className='lower'>small business loans in the right direction</span> </Typography>
                        </Box>

                        <Typography variant='h6' className='black jst' gutterBottom>
                            Over the years, the SBA has made concerted efforts to prop up small business growth and communities hit by disasters. In the fiscal year 2024, new <a href="https://www.sba.gov/document/report-sba-2024-capital-impact-report" target="_blank" rel="noopener noreferrer">SBA-backed financings were extended to 100,000-plus businesses</a> – a 16-year-high as capital impact climbed to $56 billion. For the fiscal year 2024, the <a href="https://www.bankrate.com/loans/small-business/what-is-the-sba-weekly-lending-report/" target="_blank" rel="noopener noreferrer">average loan size</a> for SBA 7(a) loans was $422,772 (as per the SBA weekly lending report).
                        </Typography>

                        <Typography variant='h6' className='black jst' gutterBottom>
                            Notably, by streamlining lending criteria and expanding the lenders’ pool in the 7(a) program, the agency has achieved a higher number of small dollar loans.
                        </Typography>

                        <Typography variant='h6' className='black jst' gutterBottom>
                            In 2024, the sectoral focus for SBA grants has been on those involved in pioneering tech advances, rural business activity, and economic growth areas. In the near term, the agency will encourage and enable scale for those fueling green energy initiatives in line with federal sustainability initiatives.
                        </Typography>

                        <Box pt={1}>
                            <Typography variant='h4' className='blue jst'>Key  <span className='lower'> changes in  </span> SBA <span className='lower'>guidelines and the lending landscape</span> </Typography>
                        </Box>

                        <Typography variant='h6' className='black jst' gutterBottom>
                            Recent reforms in the SBA regulations aim to widen the access to affordable capital for underserved and minority-owned small business communities. Towards this, the SBA has also announced a lowering of interest rates while showing a more favorable stance towards non-banking lenders with more flexible lending criteria and simpler underwriting processes.
                        </Typography>

                        <Typography variant='h6' className='black jst' gutterBottom>
                            Meanwhile, another major guideline change impacting lenders is the CFPB rule that they must now provide <a href="https://www.consumerfinance.gov/about-us/newsroom/cfpb-finalizes-rule-to-create-a-new-data-set-on-small-business-lending-in-america/" target="_blank" rel="noopener noreferrer">demographic data on every SBA loan applicant</a>.
                        </Typography>

                        <Typography variant='h6' className='black jst' gutterBottom>
                            These changes further complicate the SBA loans process for lenders – managing the volume of SBA loan applications and assessing each applicant, collating and reporting in compliance with the SBA guidelines, is bound to take up extra resources and time. They must improve their operational efficiencies.
                        </Typography>

                        <Typography variant='h6' className='black jst' gutterBottom>
                            They already face multiple challenges in the SBA lending journey. Like small businesses, lenders too, must deal with the impact of rising inflation and interest rates etc. They are also under pressure to enhance their debtor due diligence, assessment and management of life-of-loan risks to strengthen their SBA lending decisions.
                        </Typography>

                        <Typography variant='h6' className='black jst' gutterBottom>
                            Financial institutions also bear the onus of managing complexities of UCC filings and monitoring to rule out possible/accidental lapses. Clients expect their bankers to keep ready updated records of UCC filings that can be used to perfect their security interests, particularly, when large financing deals are finalized. <a href="https://www.wolterskluwer.com/en/expert-insights/the-courts-are-clear-ucc-1s-must-be-authorized-to-be-effective" target="_blank" rel="noopener noreferrer">Recent events</a> have also brought up the need for lenders to double check on the financing statements of prospective borrowers coming up in their UCC search and verify if they are actually authorized.
                        </Typography>

                        <Box pt={1}>
                            <Typography variant='h4' className='blue jst'>Improving <span className='lower'>the</span> SBA  <span className='lower'> lending journey – a win-win for lenders and small businesses   </span></Typography>
                        </Box>

                        <Typography variant='h6' className='black jst' gutterBottom>
                            SBA lenders can address these pain-points by making a strategic shift in their approach to supporting small business. Rather than being deterred by the perceived high risks and costs of small business loans, they could study the key growth areas in these businesses and the direction set by SBA’s priorities. They can then turn these insights into opportunities to grow their small business portfolio while overcoming their risk exposure better.
                        </Typography>

                        <Typography variant='h6' className='black jst' gutterBottom>
                            Gaining better insights on the priorities and growth areas for small businesses, tech-wise and opportunity-wise, lenders will be able to allocate the right resources for due diligence searches and reporting, product innovation, and risk management. For this, they must revisit their data and analytics strategy to align better with the borrower’s key priorities and needs – a strong and robust data and analytics set up is essential.
                        </Typography>

                        <Box pt={1}>
                            <Typography variant='h4' className='blue jst'>Conclusion</Typography>
                        </Box>

                        <Typography variant='h6' className='black jst' gutterBottom>
                            Outsourcing data and analytics services will ease the lending journey at various pain-points include processing time, risk analysis, adherence to guidelines, monitoring of UCC filings, and more. In the bargain, more deserving small businesses will benefit from government-backed funding.
                        </Typography>

                        <Typography variant='h6' className='black jst' gutterBottom>
                            A partner like Decimal Point Analytics will streamline SBA lending services with data-driven decisions and better risk management and develop flexible lending strategies.
                        </Typography>

                        <Box pt={1}>
                            <Typography variant='h4' className='blue jst'>Decimal Point Analytics <span className='lower'>as your  </span>  SBA <span className='lower'> lenders service provider </span>(LSP)</Typography>
                        </Box>

                        <Typography variant='h6' className='black jst' gutterBottom>
                            As an established LSP, Decimal Point Analytics offers comprehensive solutions that enhance your lending capabilities, ensure compliance, and streamline your SBA loan operations.
                        </Typography>

                        <Typography variant='h6' className='black jst' gutterBottom>
                            Decimal Point Analytics has a robust business process designed to simplify and streamline SBA loan servicing. Our process begins with Loan Prequalification, where our experts meticulously follow the SBA Standard Operating Procedure (SOP) to ensure all requirements are met. Our team of expert SBA loan underwriters, equipped with strong subject knowledge, ensures the creditworthiness and all critical aspects of each loan application are thoroughly checked. By partnering with Decimal Point Analytics, you essentially create your own dedicated team of SBA professionals, easing the operational burden and allowing you to focus on business growth. This approach is not only cost-efficient but also significantly reduces the stress associated with managing loan processes.
                        </Typography>

                        <Typography variant='h6' className='black jst' gutterBottom>
                            Look us up  <Link to="/small-business-administration/sba-lender-service" className='link'>here</Link> to find out how partnering with an SBA Lender Service Provider like Decimal Point Analytics can transform your SBA lending capabilities.
                        </Typography>

                        <Box mt={1}>
                            <EmployeeProfile imgSrc={ND} name={"Mr. Nilanjan Das"} designation={"E.V.P. Business Development,"} company={"Decimal Point Analytics Pvt. Ltd."} linkedinUrl={"https://www.linkedin.com/in/nilanjan-das-cfa-284ab64/"} />
                        </Box>
                    </Box>


                    <Box className='pages'>
                        <Writemessage3 pageVisited="Navigate the future of SBA lending with a data led strategy" />
                    </Box>

                    <Blogback />


                </Container >
            </Box >

        </>
    )
}