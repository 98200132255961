import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Helmet } from 'react-helmet';

import Casestudyback from '../../../../Component/Casestudyback'

import Banner from "../../../../asset/images/insight/case_studies/banner_reducing_penalty.png"
import Infographics from "../../../../asset/images/insight/case_studies/streamlined_workflow.webp"
import GetInTouch from '../../../../Component/Get_in_touch';



export default function ReducingPenaltyPayments() {



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet><meta charset="UTF-8" />
                <meta charset="UTF-8" />
                <title>Reducing Penalties by 80-90% with Efficient SBA Reporting Workflows</title>
                <meta name="description" content="Learn how a finance firm minimized penalties and improved compliance through streamlined workflows and effective task prioritization." />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/case-studies/reducing-penalty-payments-by-80-90-with-efficient-sba-reporting" />
            </Helmet>

            <Box id="case-studies" className='section sec_bg new_cs' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={3} alignItems="top" justifyContent='center'>
                        <Grid item lg={9} md={9} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Success Stories
                                </Typography>
                                <Typography className='mainhead blue al_center'>
                                    Reducing Penalty Payments <span className='lower'> by 80-90% with</span> Efficient SBA Reporting
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Box >
                        <img src={Banner} className='w100' alt='Banner' />
                    </Box>


                    <Box className='whitebx'>
                        <Box>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Executive Summary
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                Missed SBA deadlines and inefficient processes led to significant late payment penalties, diverting valuable resources away from core operations. By identifying bottlenecks, streamlining workflows, and focusing on compliance tasks, the finance firm achieved an 80-90% reduction in penalties, consistently met reporting deadlines, and improved operational efficiency, allowing resources to be redirected to growth initiatives.
                            </Typography>
                        </Box>


                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                The Challenge
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                The finance firm faced several challenges in its SBA reporting process:
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Substantial Late Payment Penalties: </strong>   Inefficient processes led to missed deadlines, incurring significant penalties.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Delayed SBA Reporting: </strong>  Workflow inefficiencies resulted in costly delays for SBA compliance reporting.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Resource Diversion: </strong>  Reporting inefficiencies diverted critical resources from core operational activities.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Increased Audit Risks: </strong>  Reporting delays heightened compliance risks and audit vulnerabilities.
                                        </Typography>
                                    </li>
                                </ul>
                            </Typography>
                        </Box>

                        <Box mt={3} className='mob_card'>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Solutions: Streamlined Workflow Efficiency
                            </Typography>

                            <Grid container direction="row"
                                justifyContent="center"
                                alignItems="center">

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className="al_center" my={3}>
                                        <img src={Infographics} className="w100" alt="Solutions: Streamlined Workflow Efficiency" />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Results
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>80-90% Reduction in Penalties: </strong>  Streamlined processes and task prioritization minimized late payments.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Timely SBA Reporting: </strong>  Consistent adherence to deadlines improved compliance.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Improved Operational Focus: </strong>  Freed resources allowed a stronger focus on strategic initiatives.
                                        </Typography>
                                    </li>
                                </ul>
                            </Typography>


                        </Box>

                        <Box mt={3}>
                            <Typography variant='h4' className='blue' gutterBottom>
                                Key Takeaways
                            </Typography>

                            <Typography variant='h6' className='black jst'>
                                <ul>
                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Cost Efficiency: </strong>  Reducing late penalties by 80-90% improved margins and operational efficiency.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Compliance Consistency: </strong>  Timely reporting ensured better compliance and reduced audit risks.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography variant='h6' className='black jst'>
                                            <strong>Operational Focus: </strong>  Improved workflows allowed for a stronger focus on core business strategies.
                                        </Typography>
                                    </li>
                                </ul>

                            </Typography>
                        </Box>

                        <Typography variant='h6' className='black jst' gutterBottom>
                            Reducing penalties through streamlined reporting and task prioritization unlocks significant cost savings and enhances compliance. Ensure your reporting processes are timely and aligned with broader operational goals for sustainable growth.
                        </Typography>

                        <Typography variant='h6' className='black jst' gutterBottom>
                            Want to reduce penalties and enhance workflow efficiency? Discover tailored solutions to optimize SBA reporting and strengthen compliance today.
                        </Typography>
                    </Box>

                    <Box id="sba_success_stories">
                        <GetInTouch />
                    </Box>

                    <Casestudyback />

                </Container>
            </Box>


        </>
    );
}