import ND from "../../asset/images/insight/podcast_new/nilanjan_das.webp"
import DP from "../../asset/images/insight/podcast_new/darshan_potade.webp"

const SBA_Podcast = [
    {
        link: "https://www.youtube.com/watch?v=MKdenjzeWk0&list=PLG-WwrL93I2_GuoPBNWMSazmqtCrYIjIB&index=2",
        imgsrc: ND,
        heading: "Maximing succes in SBA Lending:Comprehensive strategic insights",
        date: "Aug 09, 2024",
        subheading: "Nilanjan discusses SBA lending, strategic support for lenders, and the growth opportunities in this competitive market.",
    },
    {
        link: "https://www.youtube.com/watch?v=X4DCAKyLuEY",
        imgsrc: DP,
        heading: "SBA Loan Processing: Unlocking Efficiency through Streamlined Operations for Increased Productivity",
        date: "Apr 24, 2024",
        subheading: "Darshan discusses enhancing SBA loan processing efficiency for US clients, reducing costs and improving turnaround.",
    },
]

export default SBA_Podcast;