import {
  Container,
  Grid,
  Typography,
  Box,
} from "@material-ui/core";
import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";

import info1 from "../../asset/images/insight/info1.webp";
import info2 from "../../asset/images/insight/info2.webp";
import info4 from "../../asset/images/insight/info4.webp";
import info5 from "../../asset/images/insight/info5.webp";

import { Helmet } from "react-helmet";



export default function Infographics() {


  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);


  return (
    <>

      <Helmet>
        <meta charset="UTF-8" />
        <title>Data Analytics and Financial Research Infographics </title>
        <meta name="description" content="Discover data-driven infographics from Decimal Point Analytics. Empower your business decisions with comprehensive insights. Visit us now for more." />
        <meta name="keywords" content="infographics
info graphic
timeline infographic
infographic creator
best infographics" />
        <meta name="author" content="Decimal Point Analytics" />
        <link rel="canonical" href="https://www.decimalpointanalytics.com/infographics" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>





      <Box
        className="section sec_bg"
        component="section"
        style={{ paddingTop: "3%" }}
      >
        <Container maxWidth="lg" className="bdr">

          <Grid container spacing={1} alignItems="top">
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box className="al_center" mt={5}>
                <Typography variant="h1" className="black newh1">
                  Infographic
                </Typography>
              </Box>
            </Grid>
          </Grid>



          <Box className="reportinfo">



            <Grid container spacing={1} alignItems="top">
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Link to="/infographics/china-auto-finance-market">
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + info1 + ")" }}
                    ></Box>
                    <Box className="reporttitle_box">
                      <Typography className="reporttitle two ">
                        China Auto Finance Market
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </Link>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Link to="/infographics/taxi-aggregator-market">
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + info2 + ")" }}
                    ></Box>
                    <Box className="reporttitle_box">
                      <Typography className="reporttitle two ">
                        Taxi Aggregator Market
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </Link>
              </Grid>

              {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                <Link to="/infographics/the-indian-tea-industry">
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + info3 + ")" }}
                    ></Box>
                    <Box className="reporttitle_box">
                      <Typography className="reporttitle two ">
                        The Indian Tea Industry
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </Link>
              </Grid> */}

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Link to="/infographics/laser-therapy">
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + info4 + ")" }}
                    ></Box>
                    <Box className="reporttitle_box">
                      <Typography className="reporttitle two ">
                        Laser Therapy
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </Link>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Link to="/infographics/white-label-atm">
                  <Box className="reportm_bg">
                    <Box
                      className="reportimg_bg"
                      style={{ backgroundImage: "url(" + info5 + ")" }}
                    ></Box>
                    <Box className="reporttitle_box">
                      <Typography className="reporttitle two ">
                        White Label ATMs
                      </Typography>
                    </Box>
                    <Box className="line"></Box>
                  </Box>
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
}
