import { Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import Writemessage3 from '../../../../../../Component/Writemessage3';
import ESGBlogsBack from '../../../../../../Component/BackButtons/ESGBlogsBack';
import { Helmet } from 'react-helmet';
import EmployeeProfile from '../../../../../../Component/EmployeeProfile';
import AJ from '../../../../../../asset/images/team/ajay_jindal.webp'



export default function Denmark() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);


  return (
    <>

      <Helmet>
        <title>Denmark Sets a Precedent on Loss and Damage Funding | DPA</title>
        <meta name="description" content="Learn about Denmark's groundbreaking initiative to provide compensation for loss and damage due to climate change. Explore the implications for COP27." />
        <link rel="canonical" href="https://www.decimalpointanalytics.com/esg-insights/esg-blogs/denmark-sets-a-precedent-on-loss-and-damage-funding" />
      </Helmet>

      <Box className='section sec_bg' component='section'>

        <Container maxWidth='lg' className='bdr'>

          <Grid container spacing={3} alignItems="top">
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box className='al_center' mb={2}>
                <Typography variant='h3' className='subhead skblue al_center'>
                  ESG Insights
                </Typography>
                <Typography variant='h1' className='mainhead blue al_center'>
                  Denmark sets a precedent on loss and damage funding
                </Typography>
              </Box>
            </Grid>
          </Grid>


          <Writemessage3 pageVisited="Denmark sets a precedent on loss and damage funding" />

          <Box className='whitebx' mt={1}  >

            <Box>

              <Typography variant='h4' className='blue'>
                Introduction
              </Typography>

              <Typography variant='h6' className='black jst'>
                Loss and Damage compensation likely to be a key theme at the forthcoming climate conference COP27, being hosted by Egypt in November
              </Typography>

            </Box>


            <Box pt={1} pb={2}>

              <Typography variant='h6' className='black jst'>
                Last Tuesday, Denmark announced an intent to pay 100 million Danish crowns (€13.4 million) to developing nations damaged by climate change. It became the first country to offer “loss and damage” compensation for those in the most climate vulnerable regions of the world.
              </Typography>



              <Typography variant='h6' className='black jst'>
                Loss and Damage compensation is likely to be a big theme at the forthcoming Cop27 in November. Earlier on Tuesday, the UN secretary-general Antonio Guterres asked member states to tax the windfall profits of fossil fuel companies and redirect the money to countries suffering from loss and damage. </Typography>

              <Typography variant='h6' className='black jst'>
                Climate Action Network, a global network of over 1,300 environmental non-governmental organisations in over 130 countries, has been running a campaign on this. Earlier this month, it announced that 400 of its members had already signed a letter addressed to UN climate change Heads of Delegations calling on them to ensure that Loss and Damage finance is on the formal agenda for COP27.
              </Typography>


              <Typography variant='h6' className='black jst'>
                Loss and Damage refers to those climate change impacts which are felt when people cannot adapt to climate change any further or the limits of adaptation have been reached. It differs from mitigation and adaptation, in that it tackles how to help people after they have experienced climate-related impacts, while mitigation works on preventing it and adaptation on minimizing it. </Typography>


              <Typography variant='h6' className='black jst'>
                Quoting a Climate Action Network article: Developed countries are failing to phase out fossil fuels fast enough resulting in escalating and more intensive climate disasters. By 2050 the economic cost of Loss and Damage in developing countries is conservatively estimated to be between USD 1 to 1.8 trillion, and this does not include non-economic losses such as loss of life, culture, territory amongst others.</Typography>


              <Typography variant='h6' className='black jst'>
                The COP27 is being hosted by Egypt. After the landmark COP26 at Glasgow last year, expectations are running high ahead of the annual COP meeting. It does appear the Loss and Damage is on the agenda of this COP. Says an article: “The agenda for COP27 includes four main items to be discussed: climate finance, adaptation, loss and damage, and increased ambition”. </Typography>


            </Box>



            {/*-------------------------------------------------------*/}
            <Box mt={1}>
              <EmployeeProfile imgSrc={AJ} name={" Mr. Ajay Jindal"} designation={"EVP,"} company={"Decimal Point Analytics Pvt. Ltd."} linkedinUrl={"https://www.linkedin.com/in/ajay-jindal-cfa-33aa961/"} />
            </Box>
            {/*-------------------------------------------------------*/}

          </Box>
          {/*-------------------------------------------------------*/}



          <Box className='graybbx' mt={1} >
            <Grid container spacing={1} alignItems="top" >
              <Grid item lg={12} md={12} sm={12} xs={12} >
                <Box className='al_left'>

                  <Typography variant='h6' className='black jst'>
                    <b>Category:</b>  Environment
                  </Typography>

                  <Typography variant='h6' className='black jst'>
                    <b>Tags:</b>  Loss and Damage, Climate Action Network, COP27, Denmark, UN climate change Heads of Delegations, Egypt
                  </Typography>

                </Box>
              </Grid>
            </Grid>
          </Box>



          <ESGBlogsBack />




        </Container>
      </Box>


    </>
  );
}

