import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Divider, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { IMAGES } from './TabIcon';



const Submenu = ({ LinkTo, SubMenuName, onClose }) => {
    return (
        <Link to={LinkTo} onClick={onClose}>
            <Typography variant='h6' className="menus"> {SubMenuName} </Typography>
        </Link>
    )
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box px={2}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

export default function WhatWeDo({ onClose }) {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (

        <>
            <Box
                sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}
            >
                <Grid container spacing={1} alignItems="top" justifyContent="flex-start">
                    <Grid item lg={3} md={4} sm={5} xs={5}>
                        <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={value}
                            onChange={handleChange}
                            aria-label="Vertical tabs example"
                            className='megamenu-tabs'
                        >
                            <Tab label={<><Box className='flx_fs'>
                                {/* <PollIcon fontSize='small' /> */}
                                <img src={IMAGES.Research1} className='bico'  alt='PollIcon'/>
                                <img src={IMAGES.Research2} className='wico' alt='PollIcon'/>
                                <Typography variant='h6' className='tab-label'>Research and Consultative Services</Typography></Box> </>} disableRipple {...a11yProps(0)} />
                            <Tab label={<><Box className='flx_fs'>
                                <img src={IMAGES.FundMangement1} className='bico' alt='PollIcon'/>
                                <img src={IMAGES.FundMangement2} className='wico' alt='PollIcon'/>
                                <Typography variant='h6' className='tab-label'>Fund Mangement & Administrative Services </Typography></Box> </>} disableRipple {...a11yProps(1)} />
                            <Tab label={<><Box className='flx_fs'>
                                <img src={IMAGES.BI1} className='bico' alt='PollIcon'/>
                                <img src={IMAGES.BI2} className='wico' alt='PollIcon'/>
                                <Typography variant='h6' className='tab-label'>Bi Services and Data Analytics </Typography></Box> </>} disableRipple {...a11yProps(2)} />
                            <Tab label={<><Box className='flx_fs'>
                                <img src={IMAGES.Datascience1} className='bico' alt='PollIcon'/>
                                <img src={IMAGES.Datascience2} className='wico' alt='PollIcon'/>
                                <Typography variant='h6' className='tab-label'>Data Science </Typography></Box> </>} disableRipple {...a11yProps(3)} />
                            <Tab label={<><Box className='flx_fs'>
                                <img src={IMAGES.Technology1} className='bico' alt='PollIcon'/>
                                <img src={IMAGES.Technology2} className='wico' alt='PollIcon'/>
                                <Typography variant='h6' className='tab-label'>Technology And Automation </Typography></Box> </>} disableRipple {...a11yProps(4)} />
                            <Tab label={<><Box className='flx_fs'>
                                <img src={IMAGES.ESG1} className='bico' alt='PollIcon'/>
                                <img src={IMAGES.ESG2} className='wico' alt='PollIcon'/>
                                <Typography variant='h6' className='tab-label'>ESG Product and Services </Typography> </Box> </>} disableRipple {...a11yProps(5)} />
                            <Tab label={<><Box className='flx_fs'>
                                <img src={IMAGES.CRM1} className='bico' alt='PollIcon'/>
                                <img src={IMAGES.CRM2} className='wico' alt='PollIcon'/>
                                <Typography variant='h6' className='tab-label'>CRM Support Services </Typography></Box> </>} disableRipple {...a11yProps(6)} />
                            <Tab label={<><Box className='flx_fs'>
                                <img src={IMAGES.Product1} className='bico' alt='PollIcon'/>
                                <img src={IMAGES.Product2} className='wico' alt='PollIcon'/>
                                <Typography variant='h6' className='tab-label'>Product Development and Design </Typography></Box> </>} disableRipple {...a11yProps(7)} />

                        </Tabs>
                    </Grid>
                    <Grid item lg={9} md={8} sm={7} xs={7}>

                        <Box>
                            <Typography variant='h6' className="tabsheading menuheading"> What We Do </Typography>
                        </Box>

                        <TabPanel value={value} index={0}>
                            <Grid container spacing={1} alignItems="top" justifyContent="flex-start">
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className=''>
                                        <Typography variant='h4' className=" blue">Research and Consultative Services</Typography>
                                    </Box>
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Divider component="li" className='w100' />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="M&A Advisory" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Risk Management" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Asset Allocation Strategies" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Equity Research" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Financial Modelling" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Sector and Company Coverage" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Fixed Income Research" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Credit Research" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Macroeconomic Research" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Pre and Post-Deal Assistance" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Due Diligence Support" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Exit Strategy" onClose={onClose} />
                                </Grid>



                            </Grid>
                        </TabPanel>

                        <TabPanel value={value} index={1}>
                            <Grid container spacing={1} alignItems="top" justifyContent="flex-start">
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className=''>
                                        <Typography variant='h4' className=" blue">Fund Mangement & Administrative Services</Typography>
                                    </Box>
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Divider component="li" className='w100' />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Fund Administration" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Fund Accounting" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Performance Monitoring and Attribution Analysis" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Reconciliation" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="NAV Calculation" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Automated Facesheet Generation" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Investor Reporting" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="RFP Response Management" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Deal Sourcing and Valuation" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Index Back-testing and Calculations" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Index Performance Reporting" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Index Operations" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Trade Finance" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Insurance Mid and Back-office Support" onClose={onClose} />
                                </Grid>

                            </Grid>
                        </TabPanel>

                        <TabPanel value={value} index={2}>
                            <Grid container spacing={1} alignItems="top" justifyContent="flex-start">
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className=''>
                                        <Typography variant='h4' className=" blue">Bi Services and Data Analytics</Typography>
                                    </Box>
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Divider component="li" className='w100' />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Data Acquisition" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Web Data Extration" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Data Warehousing and Data Lakes" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Data Quality Management/Data Governance" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Visualization Dashboards" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Predictive Analysis" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Trend Analysis" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="BI Consulting" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Bi Support and Maintenance" onClose={onClose} />
                                </Grid>

                            </Grid>
                        </TabPanel>

                        <TabPanel value={value} index={3}>
                            <Grid container spacing={1} alignItems="top" justifyContent="flex-start">
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className=''>
                                        <Typography variant='h4' className=" blue">Data Science</Typography>
                                    </Box>
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Divider component="li" className='w100' />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Generative AI" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="NLP for Textual Analytics" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Sentiment Analysis" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Social Media Analytics" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Fraud Analytics" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Early Warning Signals" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Text Classification and Summarizer" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Automated News Generation and Analytics" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Noise Classification" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Facial Recongnition" onClose={onClose} />
                                </Grid>
                            </Grid>

                        </TabPanel>

                        <TabPanel value={value} index={4}>
                            <Grid container spacing={1} alignItems="top" justifyContent="flex-start">
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className=''>
                                        <Typography variant='h4' className=" blue">Technology And Automation </Typography>
                                    </Box>
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Divider component="li" className='w100' />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Front-end Development" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="API Development" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Website and Mobile App Development" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="DevOps" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Cloud Infra" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Software Quality Assurance" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Intelligent Document Processing (IDP)" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Robotic Process Automation" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Distributed Ledger Technology" onClose={onClose} />
                                </Grid>

                            </Grid>
                        </TabPanel>

                        <TabPanel value={value} index={5}>
                            <Grid container spacing={1} alignItems="top" justifyContent="flex-start">
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className=''>
                                        <Typography variant='h4' className=" blue">ESG Product and Services </Typography>
                                    </Box>
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Divider component="li" className='w100' />
                                </Grid>

                                <Grid container spacing={5} alignItems="top" justifyContent="flex-start">
                                    <Grid item lg={6} md={6} sm={12} xs={12}>

                                        <Box className='vertical_divider'>
                                            <Grid container spacing={1} alignItems="top" justifyContent="flex-start">
                                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                                    <Typography variant='h6' className="menuheading"> <strong>ESG Products</strong> </Typography>
                                                </Grid>

                                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                                    <Link to="#" onClick={onClose}>
                                                        <Typography variant='h6' className="menus "> ESG Key Issue Assessment  </Typography>
                                                    </Link>
                                                </Grid>

                                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                                    <Link to="#" onClick={onClose}>
                                                        <Typography variant='h6' className="menus ">ESG News Analytics  </Typography>
                                                    </Link>
                                                </Grid>

                                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                                    <Link to="#" onClick={onClose}>
                                                        <Typography variant='h6' className="menus "> ESG Reporting for PE Funds  </Typography>
                                                    </Link>
                                                </Grid>

                                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                                    <Link to="#" onClick={onClose}>
                                                        <Typography variant='h6' className="menus "> Portfolio Alignment Tool for TCFD and SFDR Reporting  </Typography>
                                                    </Link>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>

                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <Grid container spacing={1} alignItems="top" justifyContent="flex-start">
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <Box className=''>
                                                    <Typography variant='h6' className="menuheading"> <strong>ESG Services</strong> </Typography>
                                                </Box>
                                            </Grid>

                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <Link to="#" onClick={onClose}>
                                                    <Typography variant='h6' className="menus ">ESG Research Support  </Typography>
                                                </Link>
                                            </Grid>

                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <Link to="#" onClick={onClose}>
                                                    <Typography variant='h6' className="menus ">ESG Data Provision Services  </Typography>
                                                </Link>
                                            </Grid>

                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <Link to="#" onClick={onClose}>
                                                    <Typography variant='h6' className="menus ">ESG Policy and Process Consulting</Typography>
                                                </Link>
                                            </Grid>

                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <Link to="#" onClick={onClose}>
                                                    <Typography variant='h6' className="menus ">ESG Training</Typography>
                                                </Link>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </TabPanel>

                        <TabPanel value={value} index={6}>
                            <Grid container spacing={1} alignItems="top" justifyContent="flex-start">
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className=''>
                                        <Typography variant='h4' className=" blue">CRM Support Services </Typography>
                                    </Box>
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Divider component="li" className='w100' />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Workflow Automation" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="CRM Contact Management" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Email Integration and Automation​" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="RFP (DDQ) Support" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Data Analysis via Dashboards​" onClose={onClose} />
                                </Grid>

                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Submenu LinkTo="#" SubMenuName="Maintenance Support" onClose={onClose} />
                                </Grid>

                            </Grid>
                        </TabPanel>

                        <TabPanel value={value} index={7}>
                            <Grid container spacing={1} alignItems="top" justifyContent="flex-start">
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className=''>
                                        <Typography variant='h4' className=" blue">Product Development and Design </Typography>
                                    </Box>
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Divider component="li" className='w100' />
                                </Grid>

                                <Grid container spacing={5} alignItems="top" justifyContent="flex-start">
                                    <Grid item lg={6} md={6} sm={12} xs={12}>

                                        <Box className='vertical_divider'>
                                            <Grid container spacing={1} alignItems="top" justifyContent="flex-start">
                                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                                    <Box className=''>
                                                        <Typography variant='h6' className="menuheading"> <strong>Product Development</strong> </Typography>
                                                    </Box>

                                                </Grid>

                                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                                    <Link to="#" onClick={onClose}>
                                                        <Typography variant='h6' className="menus "> Product Ideation and Conceptualization </Typography>
                                                    </Link>
                                                </Grid>

                                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                                    <Link to="#" onClick={onClose}>
                                                        <Typography variant='h6' className="menus ">Product Documentation </Typography>
                                                    </Link>
                                                </Grid>

                                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                                    <Link to="#" onClick={onClose}>
                                                        <Typography variant='h6' className="menus "> Platform Development </Typography>
                                                    </Link>
                                                </Grid>

                                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                                    <Link to="#" onClick={onClose}>
                                                        <Typography variant='h6' className="menus "> User Acceptance Testing  </Typography>
                                                    </Link>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>

                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <Grid container spacing={1} alignItems="top" justifyContent="flex-start">
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <Box className=''>
                                                    <Typography variant='h6' className="menuheading"> <strong>UI/UX</strong> </Typography>
                                                </Box>
                                            </Grid>

                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <Link to="#" onClick={onClose}>
                                                    <Typography variant='h6' className="menus ">Website and Mobile App Design</Typography>
                                                </Link>
                                            </Grid>

                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <Link to="#" onClick={onClose}>
                                                    <Typography variant='h6' className="menus ">Interactive Dashboards </Typography>
                                                </Link>
                                            </Grid>

                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <Link to="#" onClick={onClose}>
                                                    <Typography variant='h6' className="menus ">PowerPoint Presentation Templates</Typography>
                                                </Link>
                                            </Grid>

                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <Link to="#" onClick={onClose}>
                                                    <Typography variant='h6' className="menus ">Brochure and Report Design</Typography>
                                                </Link>
                                            </Grid>

                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <Link to="#" onClick={onClose}>
                                                    <Typography variant='h6' className="menus ">Infographic Design</Typography>
                                                </Link>
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </TabPanel>

                    </Grid>
                </Grid>

            </Box>
        </>

    );
}