import { IconButton, Container, Grid, Typography, Box, } from '@material-ui/core';
import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import Carousel from "react-elastic-carousel";


import Eventback from '../../../Component/Eventback'
import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined';

import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';

import C1 from "../../../asset/images/media/event/22feb_c1.webp"
import C2 from "../../../asset/images/media/event/22feb_c2.webp"
import C3 from "../../../asset/images/media/event/22feb_c3.webp"
import { Helmet } from 'react-helmet';


const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);



export default function Event_22_Feb() {



    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };



    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return (
        <>

            <Helmet>
                <title>NIPM - IES MCRC Convention | Decimal Point Analytics </title>
                <meta name="description" content="Discover insights from Mr. Siddharth Taterh's participation in NIPM - IES MCRC Convention. Explore applications of Analytics for digital transformation." />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/mipm-ies-mcrc-convention" />
            </Helmet>

            <Box id='event-details' className='section sec_bg' component='section'>

                <Container maxWidth='lg' className='bdr'>

                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Box>
                                <Typography variant='h3' className='subhead skblue al_center'>
                                    Events
                                </Typography>
                                <Typography variant='h1' className='mainhead blue al_center mb0'>
                                    NIPM - IES MCRC Convention
                                </Typography>
                                <Typography className=' mainsmhead black al_center mb0'>
                                    Feb, 2019
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>





                    <Box className='whitebx'>


                        <Typography variant='h6' className='black jst'>
                            Mr. Siddharth Taterh attended the NIPM - IES MCRC Convention, held on 22nd February, 2019 which bought together industry practitioners and academic researchers to exchange notes on the applications of Analytics to <strong> Redefine the New Normal of Digital Transformation across Business functions. </strong>
                        </Typography>

                        <Typography variant='h6' className='black jst'>
                            Siddharth also spoke on the topic of “Harnessing the power of Data through application of AI and ML”
                        </Typography>


                        <Typography variant='h6' className='black jst'>
                            <strong> The guest for the evening included  </strong>
                            <ol>
                                <li>  Mr.VS Parthasarathy (Group CFO and Group CIO, Mahindra Rise) </li>
                                <li>Dr. Pramod Sadarjoshi (Founder & CEO, Talentsmith)</li>
                                <li> Mr. Tridib Bhattacharjee (Founder and Chief Advisor, Astramind Consulting) </li>
                                <li>Mr. Prithvi Chadrasekhar (President,Risk Management & Analytics, InCred Finance)</li>
                            </ol>
                        </Typography>


                        <Box className='al_center'>
                            <IconButton className='blue' onClick={handleClickOpen}>
                                <CameraAltOutlinedIcon />
                            </IconButton>
                        </Box>

                        <Box className='al_center'>
                            <Typography className='blink_text'>Click Here...</Typography>
                        </Box>



                        {/* <Subscribe /> */}

                    </Box>




                    <Eventback />




                </Container>
            </Box>




            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} className="b_modal teammodal">
                <DialogTitle className="feedname" id="customized-dialog-title" onClose={handleClose}>
                </DialogTitle>
                <DialogContent>
                    <Box>
                        <Grid container spacing={2}>
                            <Carousel itemsToShow={1} animateOut="slideOutDown"
                                animateIn="flipInX">

                                <Box className="boxcontent" style={{ padding: '0px' }}>
                                    <Box className='eventbg' style={{ backgroundImage: 'url(' + C2 + ')', }}></Box>
                                </Box>
                                <Box className="boxcontent" style={{ padding: '0px' }}>
                                    <Box className='eventbg' style={{ backgroundImage: 'url(' + C1 + ')', }}></Box>
                                </Box>


                                <Box className="boxcontent" style={{ padding: '0px' }}>
                                    <Box className='eventbg' style={{ backgroundImage: 'url(' + C3 + ')', }}></Box>
                                </Box>
                            </Carousel>
                        </Grid>
                    </Box>
                </DialogContent>
            </Dialog>


        </>
    );
}
